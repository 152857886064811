import {
  Box,
  CircularProgress,
  createTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { counter } from "../../../helpers/count";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { FormattedMessage } from "react-intl";
import { Colors } from "../../../shared/constants/themes/Colors";
import { AnswerBody } from "./AnswerBody";
import { FC, useEffect, useRef, useState } from "react";
import { ICorrespondenceResponse } from "../../../interfaces/Correspondence";
import { getCorrespondenceAnswers } from "../../../services/firebase/correspondence";
import { TablePaginationAct } from "../../custom/TablePaginationAct";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});

interface Props {
  component?: string;
}

export const AnswerTable: FC<Props> = ({ component = "OTHER" }) => {
  const unSubRef = useRef<any>(null);
  const classes = useStyles();

  const { data, value } = useSelector((state: AppState) => state.search);
  const { user } = useSelector((state: AppState) => state.auth);
  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const [answers, setAnswers] = useState<ICorrespondenceResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  // Paginación
  /*  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  ); */

  useEffect(() => {
    const fetchAnswers = async () => {
      if (user && user.idEntity && activeCorrespondence) {
        setLoading(true);

        unSubRef.current = await getCorrespondenceAnswers(
          user.idEntity,
          activeCorrespondence.id,
          (resp: ICorrespondenceResponse[] | null) => {
            if (resp) {
              setAnswers(resp);
            }

            setLoading(false);
          }
        );

        //setUnsubscriber(unSubscriber)
      }
    };
    fetchAnswers();
    return () => {
      //CANCELAR SNAPSHOT
      unSubRef?.current?.();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const count = counter(value, answers, data);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, answers.length - page * rowsPerPage);
  if (loading) {
    return (
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        {answers.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            <FormattedMessage id={"NoAnswers"} />
          </Box>
        ) : (
          <Table
            className={classes.table}
            size="small"
            style={{ tableLayout: "auto" }}
          >
            <TableHead className={classes.titleTable}>
              <TableRow>
                <TableCell
                  width={"15%"}
                  align="center"
                  className={classes.titleTable}
                >
                  <FormattedMessage id="TypeOfAction" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Settled" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Date" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="User" />{" "}
                </TableCell>
                <TableCell
                  width={"20%"}
                  className={classes.titleTable}
                  align="center"
                >
                  <FormattedMessage id="Actions" />{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                    ? answers.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : answers
                  ).map((ans) => (
                    <AnswerBody
                      key={
                        ans.officer.id + "-" + ans.submitDate.toDate().getTime()
                      }
                      answer={ans}
                      component={component}
                    />
                  ))
               
               }

              {emptyRows > 0 && (
                <TableRow style={{ height: 13 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {/* --------- Footer para paginación -------- */}
            <TableFooter style={{ padding: 0, margin: 0 }}>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5]}
                  colSpan={9}
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationAct}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

import {
  ICorresAnnotations,
  IDeposits,
  IDocumentsFuid,
} from "./../types/types";
import {
  Types,
  IFileModuleState,
  IFileModuleAction,
  ITransfer,
  IFuid,
  ISendCopyExpedient,
} from "../types/types";

const initialState: IFileModuleState = {
  activeDeposit: null,
  activeTransfer: null,
  Deposits: [],
  transfers: [],
  expedients: [],
  activeExpedientFileModule: null,
  documentsDownload: [],
  loans: [],
  activeLoan: null,
  fuidAnnotations: [],
  activeFuidAnnotation: null,
};

export const fileModuleReducer = (
  state = initialState,
  action: IFileModuleAction
): IFileModuleState => {
  const { type, payload } = action;
  switch (type) {
    case Types.loadFileModuleTransfers:
      return {
        ...state,
        transfers: [...(payload as ITransfer[])],
      };
    case Types.loadTransferExpedients:
      return {
        ...state,
        expedients: [...(payload as IFuid[])],
      };

    case Types.setActiveFuidFileModule:
      return {
        ...state,
        activeExpedientFileModule: action.payload as IFuid,
      };
    //COPY
    case Types.sendCopyFiles:
      const { idExpedient, users } = payload as ISendCopyExpedient;
      const allExpedients = [...state.expedients];
      const indexExpedient = allExpedients.findIndex(
        (cor) => cor.id === idExpedient
      );
      if (indexExpedient !== -1) {
        let copyToIds = allExpedients[indexExpedient].copyToIds;
        if (copyToIds) {
          const addIds = users.map((user) => user.id);
          copyToIds = [...addIds];
        }

        allExpedients[indexExpedient].copyTo = users;
        allExpedients[indexExpedient].copyToIds = copyToIds;
      }
      return {
        ...state,
        expedients: allExpedients,
      };

    case Types.updateExpedientFileModule:
      return {
        ...state,
        expedients: state.expedients.map((expedient) =>
          expedient.id === (payload as IFuid).id
            ? (payload as IFuid)
            : expedient
        ),
      };

    case Types.addDocumentsFuid:
      return {
        ...state,
        documentsDownload: [
          payload as IDocumentsFuid,
          ...state.documentsDownload,
        ],
      };

    case Types.deleteDocumentsFuid:
      return {
        ...state,
        documentsDownload: state.documentsDownload.filter(
          (documentsDownload) => documentsDownload.url !== payload
        ),
      };
    case Types.setActiveFileModuleTransfer:
      return {
        ...state,
        activeTransfer: payload as any,
      };

    // PAYLOAD ELIMINAR TRANSFERENCIA DE REDUX
    case Types.deleteTransferFuid:
      return {
        ...state,
        transfers: state.transfers.filter(
          (transfers) => transfers.idTransfer !== action.payload
        ),
      };
    case Types.loadDepositsFiles:
      return {
        ...state,
        Deposits: [...(payload as IDeposits[])],
      };
    case Types.addDepositFiles:
      return {
        ...state,
        Deposits: [payload as IDeposits, ...state.Deposits],
      };
    // PAYLOAD ELIMINAR EXPEDIENTE DE REDUX
    case Types.deleteDepositFiles:
      return {
        ...state,
        Deposits: state.Deposits.filter(
          (deposits) => deposits.id !== action.payload
        ),
      };
    case Types.loadLoans:
      return {
        ...state,
        loans: [...(payload as any)],
      };
    case Types.setActiveLoan:
      return {
        ...state,
        activeLoan: payload as any,
      };

    case Types.addFuidAnnotation:
      return {
        ...state,
        fuidAnnotations: [
          payload as ICorresAnnotations,
          ...state.fuidAnnotations,
        ],
      };

    case Types.loadAllFuidAnnotations:
      return {
        ...state,
        fuidAnnotations: [...(payload as ICorresAnnotations[])],
      };

    case Types.setActiveFuidAnnotation:
      return {
        ...state,
        activeFuidAnnotation: payload as ICorresAnnotations,
      };

    case Types.deleteFuidAnnotation:
      return {
        ...state,
        fuidAnnotations: state.fuidAnnotations.filter(
          (annotation) => annotation.id !== payload
        ),
      };

    default:
      return state;
  }
};

import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useState } from "react";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { useStyles } from "../../../shared/styles/useStyles";
import { LabelsSize } from "../../../enums/enums";
import { ILabelSize } from "../../../redux/types/types";
import { startSetlabelSize } from "../../../redux/actions/correspondenceActions";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";

export const ConfigureLabels = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {labelSize:labelSizeBd} = useSelector((state:AppState) => state.correspondence);
  const [labelSize, setLabelSize] = useState(labelSizeBd?labelSizeBd:LabelsSize.SIZE_89MM);
  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLabelSize(Number((event.target as HTMLInputElement).value));
  };

  const handleConfigureSize = async () => {
    if (labelSize !== 2) {
      setLoading(true);
      const objParam: ILabelSize = {
        idEntity: "",
        labelSize: labelSize,
      };
      await dispatch(startSetlabelSize(objParam));
      setLoading(false);
    }
  };

  return (
    <>
      <Box width={1} ml={3} mt={3} className={classes.title}>
        <label className="form-text" style={{}}>
          <FormattedMessage id="selectSizeLabel" />*
        </label>
      </Box>
      <RadioGroup name="labelSize" value={labelSize} onChange={handleChange}>
        <Box
          mt={3}
          ml={2}
          display="flex"
          style={{
            fontSize: 12,
            fontFamily: "Poppins",
          }}
          justifyContent="space-around"
        >
          <FormControlLabel
            value={LabelsSize.SIZE_89MM}
            control={<Radio color="primary" />}
            label={"89mm x 28mm"}
          />
          <FormControlLabel
            value={LabelsSize.SIZE_10CM}
            control={<Radio color="primary" />}
            label={"10cm x 2,5cm"}
          />
        </Box>
      </RadioGroup>
      <Box
        mt={16}
        display="flex"
        alignItems="center"
        flexDirection="column-reverse"
      >
        {!loading ? (
          <Button
            className={clsx(classes.btn, classes.save)}
            autoFocus
            type="button"
            disabled={loading}
            onClick={handleConfigureSize}
          >
            <FormattedMessage id="Save" />
          </Button>
        ) : (
          <Button
            className={clsx(classes.btn, classes.save)}
            autoFocus
            type="button"
            disabled={true}
          >
            <CircularProgress className={classes.btnLoading} />
          </Button>
        )}
      </Box>
    </>
  );
};

import React from "react";
import {
  createTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import { useStyles } from "../../../shared/styles/useStyles";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { RecipientsBody } from "./RecipientsBody";
const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});
export const RecipientsTable = () => {
  const classes = useStyles();
  const { activeAnnotation } = useSelector(
    (state: AppState) => state.correspondence
  );

  return (
    <ThemeProvider theme={theme}>
      {activeAnnotation && (
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            style={{ tableLayout: "auto" }}
            aria-label="a dense table"
          >
            <TableHead className={classes.titleTable}>
              <TableRow>
                <TableCell
                  width={"35%"}
                  align="center"
                  className={classes.titleTable}
                >
                  <FormattedMessage id="Recipient" />
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="SubmitedDate" />
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="ReadedAnnotationDate" />
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="ConfirmatedDate" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activeAnnotation.recipientsData.map((rec) => (
                <RecipientsBody key={rec.recipient} recipient={rec} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </ThemeProvider>
  );
};

import clsx from "clsx";
import { Form, Formik } from "formik";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
} from "@material-ui/core";

import { MyTextField } from "../../custom/MyTextField";
import {
  CommunicationChannels,
  CorrespondenceInputType,
} from "../../../enums/enums";
import { IUserCorrespondence } from "../../../interfaces/Correspondence";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";

interface Props {
  onSearch(data: any): void;
}

const initialValues = {
  dependency: "",
  inputType: "",
  startDate: new Date().toLocaleDateString("en-CA"),
  endDate: new Date().toLocaleDateString("en-CA"),
  channel: "",
};

export const CorrespondenceReceivedForm: FC<Props> = ({ onSearch }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { productionOffices } = useSelector((state: AppState) => state.trd);
  useState<IUserCorrespondence | null>(null);

  const validationSchema = yup.object({
    dependency: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    inputType: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    startDate: yup
      .date()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`)
      .max(
        yup.ref("endDate"),
        `${intl.formatMessage({ id: "GreaterStartDate" })}`
      ),
    endDate: yup
      .date()
      .min(
        yup.ref("startDate"),
        `${intl.formatMessage({ id: "LowerEndDate" })}`
      )
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    channel: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  return (
    <Box mt={1} mb={3}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          onSearch(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box display="flex" justifyContent="space-between">
                  <label className="form-text" style={{ marginTop: 12 }}>
                    <FormattedMessage id="Dependency" />
                  </label>
                  <Box width={"65%"}>
                    <MyTextField
                      name="dependency"
                      variant="outlined"
                      select
                      className={classes.myTextFieldRoot}
                    >
                      <MenuItem value="Todos">Todas</MenuItem>
                      {productionOffices.map((productionOffice) => (
                        <MenuItem
                          key={productionOffice.idProductionOffice}
                          value={productionOffice.idProductionOffice}
                        >
                          {productionOffice.name}
                        </MenuItem>
                      ))}
                    </MyTextField>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box display="flex" justifyContent="space-between">
                  <label className="form-text" style={{ marginTop: 12 }}>
                    <FormattedMessage id="InputType" />
                  </label>

                  <Box width={"60%"}>
                    <MyTextField
                      name="inputType"
                      variant="outlined"
                      select
                      className={classes.myTextFieldRoot}
                    >
                      <MenuItem value="Todos">Todas</MenuItem>
                      <MenuItem value={CorrespondenceInputType.ER}>
                        <FormattedMessage id="ExternalReceived" />
                      </MenuItem>
                      <MenuItem value={CorrespondenceInputType.IR}>
                        <FormattedMessage id="InternalReceived" />
                      </MenuItem>
                      <MenuItem value={CorrespondenceInputType.OR}>
                        <FormattedMessage id="OtherShipmentsReceived" />
                      </MenuItem>

                      {/* <MenuItem value={CorrespondenceInputType.EP}>
                        <FormattedMessage id="ExternalProduced" />
                      </MenuItem>
                      <MenuItem value={CorrespondenceInputType.IP}>
                        <FormattedMessage id="InternalProduced" />
                      </MenuItem>
                      <MenuItem value={CorrespondenceInputType.OP}>
                        <FormattedMessage id="OtherShipmentsProduced" />
                      </MenuItem> */}
                    </MyTextField>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box display="flex" justifyContent="space-between">
                  <label className="form-text" style={{ marginTop: 12 }}>
                    <FormattedMessage id="From" />
                  </label>
                  <Box width={"80%"}>
                    <MyTextField
                      name="startDate"
                      variant="outlined"
                      type="date"
                      className={classes.myTextFieldRoot}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box display="flex" justifyContent="space-between">
                  <label className="form-text" style={{ marginTop: 12 }}>
                    <FormattedMessage id="To" />
                  </label>
                  <Box width={"80%"}>
                    <MyTextField
                      name="endDate"
                      variant="outlined"
                      type="date"
                      className={classes.myTextFieldRoot}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box display="flex" justifyContent="space-between">
                  <label className="form-text" style={{ marginTop: 12 }}>
                    <FormattedMessage id="Channel" />
                  </label>

                  <Box width={"80%"}>
                    <MyTextField
                      name="channel"
                      variant="outlined"
                      select
                      className={classes.myTextFieldRoot}
                    >
                      <MenuItem value="Todos">Todos</MenuItem>
                      <MenuItem value={CommunicationChannels.SINGLE_WINDOW}>
                        <FormattedMessage id="SingleWindow" />
                      </MenuItem>
                      <MenuItem value={CommunicationChannels.EMAIL}>
                        <FormattedMessage id="EmailAddress" />
                      </MenuItem>
                      <MenuItem value={CommunicationChannels.SAC}>SAC</MenuItem>
                      <MenuItem
                        value={CommunicationChannels.VERBAL_COMMUNICATION}
                      >
                        <FormattedMessage id="VerbalCommunication" />
                      </MenuItem>
                      <MenuItem value={CommunicationChannels.FACEBOOK}>
                        <FormattedMessage id="Facebook" />
                      </MenuItem>
                      <MenuItem value={CommunicationChannels.WHATSAPP}>
                        <FormattedMessage id="Whatsapp" />
                      </MenuItem>
                      <MenuItem value={CommunicationChannels.CALL}>
                        <FormattedMessage id="ByTelephone" />
                      </MenuItem>
                      <MenuItem
                        value={CommunicationChannels.INSTITUTIONAL_PAGE}
                      >
                        <FormattedMessage id="InstitutionalPage" />
                      </MenuItem>
                      <MenuItem value={CommunicationChannels.FAX}>
                        <FormattedMessage id="Fax" />
                      </MenuItem>
                    </MyTextField>
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                xs={4}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {!isSubmitting ? (
                  <Button
                    className={clsx(classes.btnAction, classes.save)}
                    autoFocus
                    type="submit"
                    disabled={isSubmitting}
                    style={{ marginTop: "8px", width: "40%" }}
                  >
                    <FormattedMessage id="Download" />
                  </Button>
                ) : (
                  <Button
                    className={clsx(classes.btnAction, classes.save)}
                    autoFocus
                    type="button"
                    disabled={true}
                    style={{ marginTop: "8px", width: "40%" }}
                  >
                    <CircularProgress className={classes.btnLoading} />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

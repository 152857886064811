import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import clsx from "clsx";
import { useIntl, FormattedMessage } from "react-intl";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { MyTextField } from "../../custom/MyTextField";
import {
  uiCloseAlertExists,
  uiCloseModalAdd,
  uiCloseModalEdit,
} from "../../../redux/actions/uiActions";
import { useStyles } from "../../../shared/styles/useStyles";
import { Office } from "../../../interfaces/OrganizationChart";
import {
  startNewOrganizationChart,
  startEditOffice,
  officeCleanActive,
} from "../../../redux/actions/organizationChartActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { MyAlert } from "../../custom/MyAlert";
import { ITrdVersion } from "../../../redux/types/types";

export const FormAddOrganizationChart = () => {
  const { activeOffice } = useSelector(
    (state: AppState) => state.organizationChart
  );
  const office: Office | null = activeOffice;

  const initialValues: Office = {
    idVersion: office ? office.idVersion : "",
    idProductionOffice: office ? office.idProductionOffice : "",
    name: office ? office.name : "",
    productionOffice: office ? office.productionOffice : "",
    idOffice: office ? office.idOffice : "",
  };

  const intl = useIntl();
  const classes = useStyles();

  const dispatch = useDispatch();

  const { productionOffices }: any = useSelector(
    (state: AppState) => state.trd
  );

  const { versions }: any = useSelector((state: AppState) => state.trd);
  const { alertExists }: any = useSelector((state: AppState) => state.ui);

  const validationSchema = yup.object({
    idVersion: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    idProductionOffice: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    name: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  const onClose = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    dispatch(officeCleanActive());
  };

  const closeAlertExist = () => {
    if (office) {
      dispatch(uiCloseAlertExists());
    } else {
      dispatch(uiCloseAlertExists());
      dispatch(officeCleanActive());
    }
  };

  return (
    <Box m={1}>
      <Formik
        enableReinitialize
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          let indexProductionOffice = productionOffices.findIndex(
            (el: any) => el.idProductionOffice === values.idProductionOffice
          );
          if (office) {
            if (indexProductionOffice !== -1) {
              await dispatch(startEditOffice(values));
            }
          } else {
            if (indexProductionOffice !== -1) {
              await dispatch(
                startNewOrganizationChart(
                  values,
                  productionOffices[indexProductionOffice].name,
                  productionOffices[indexProductionOffice].idProductionOffice
                )
              );
            }
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Version" />*
                </label>
                <MyTextField
                  name="idVersion"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                  disabled={!!office}
                >
                  {versions.map((version: ITrdVersion) => {
                    return (
                      <MenuItem
                        key={version.idVersion}
                        value={version.idVersion}
                      >
                        {version.name}
                      </MenuItem>
                    );
                  })}
                </MyTextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="ProductionOffice" />*
                </label>
                <MyTextField
                  name="idProductionOffice"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                  disabled={!!office}
                >
                  {productionOffices.map((office: Office) => {
                    return (
                      <MenuItem
                        key={office.idProductionOffice}
                        value={office.idProductionOffice}
                      >
                        {office.name}
                      </MenuItem>
                    );
                  })}
                </MyTextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="OfficeName" />*
                </label>
                <MyTextField
                  name="name"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={12}>
                <Box mt={2} display="flex" flexDirection="row-reverse">
                  {!isSubmitting ? (
                    <Button
                      className={clsx(classes.btn, classes.save)}
                      autoFocus
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <FormattedMessage id="Save" />
                    </Button>
                  ) : (
                    <Button
                      className={clsx(classes.btn, classes.save)}
                      autoFocus
                      type="button"
                      disabled={true}
                    >
                      <CircularProgress className={classes.btnLoading} />
                    </Button>
                  )}
                  <Button
                    className={clsx(classes.btn, classes.cancel)}
                    onClick={onClose}
                  >
                    <FormattedMessage id="Cancel" />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <MyAlert
        open={alertExists}
        typeAlert="error"
        message="ExistsOffice"
        time={2000}
        handleClose={closeAlertExist}
      />
    </Box>
  );
};

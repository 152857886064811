import {
  Box,
  createTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { counter } from "../../../helpers/count";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { FormattedMessage } from "react-intl";
import { Colors } from "../../../shared/constants/themes/Colors";
import { CustomizedTableFooter } from "../../custom/CustomizedTableFooter";
import { CorresAnnotationsBody } from "./AnnotationsBody";
import { FC } from "react";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});
interface props {
  readonly?: boolean;
  component?: string;
}

export const AnnotationsTab: FC<props> = ({
  readonly = false,
  component = "OTHER",
}) => {
  const classes = useStyles();
  const { data, value } = useSelector((state: AppState) => state.search);
  //const [loading, setLoading] = useState(false);
  /*  const { user } = useSelector((state: AppState) => state.auth);
  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.documentManagment
  ); */
  const { corresAnnotations } = useSelector(
    (state: AppState) => state.documentManagment
  );
  //const dispatch = useDispatch();

  /*  useEffect(() => {
    const fetchCorresAnnotations = async () => {
      if (user && user.idEntity && activeCorrespondence) {
        setLoading(true);

        const resp = await loadCorresAnnotationsDB(
          user.idEntity,
          activeCorrespondence.id
        );
        dispatch(loadCorresAnnotations(resp));
        setLoading(false);
      }
    };
    fetchCorresAnnotations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); */

  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );
  const count = counter(value, corresAnnotations, data);
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, corresAnnotations.length - page * rowsPerPage);

  /* if (loading) {
    return (
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  } */
  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper} style={{ paddingTop: "10px" }}>
        {corresAnnotations.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            <FormattedMessage id={"NoCreatedCommunication"} />
          </Box>
        ) : (
          <Table
            className={classes.table}
            size="small"
            style={{ tableLayout: "auto" }}
            aria-label="a dense table"
          >
            <TableHead className={classes.titleTable}>
              <TableRow>
                <TableCell
                  width={"15%"}
                  align="center"
                  className={classes.titleTable}
                >
                  <FormattedMessage id="DocumentName" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Annotation" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="User" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Date" />{" "}
                </TableCell>
                {!readonly && (
                  <TableCell
                    width={"20%"}
                    className={classes.titleTable}
                    align="center"
                  >
                    <FormattedMessage id="Actions" />{" "}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!value.trim()
                ? (rowsPerPage > 0
                    ? corresAnnotations.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : corresAnnotations
                  ).map((procedure) => (
                    <CorresAnnotationsBody
                      key={procedure.id}
                      settle={procedure}
                      readOnly={readonly}
                      component={component}
                    />
                  ))
                : data &&
                  (rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((procedure) => (
                    <CorresAnnotationsBody
                      key={procedure.id}
                      settle={procedure}
                      readOnly={readonly}
                      component={component}
                    />
                  ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 13 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {/* --------- Footer para paginación -------- */}
            <CustomizedTableFooter count={count} />
          </Table>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

import { db } from "../../config/firebase/firebase-config";
import { TypeUser } from "../../enums/enums";
import { User } from "../../interfaces/Users";

// Obtener usuario por documento
export const getUserByDocument = async (document: number, idEntity: string) => {
  const usersSnap = await db
    .collection("Users")
    .where("rol", "==", TypeUser.OFFICER)
    .where("document", "==", document)
    .where("idEntity", "==", idEntity)
    .get();
  const users: any[] = [];

  usersSnap.forEach((snap) => {
    users.push({
      ...snap.data(),
    });
  });
  return users;
};

// Obtener usuario por documento
export const getUserByEmail = async (email: string, idEntity: string) => {
  const usersSnap = await db
    .collection("Users")
    // .where("rol", "==", TypeUser.OFFICER)
    .where("email", "==", email)
    // .where("idEntity", "==", idEntity)
    .get();
  const users: any[] = [];

  usersSnap.forEach((snap) => {
    users.push({
      ...snap.data(),
    });
  });
  return users;
};

// Obtener usuario por ID
export const getUserById = async (id: string) => {
  const usersSnap = await db.collection("Users").doc(id).get();
  //const users: any[] = [];

  /* usersSnap.forEach((snap) => {
    users.push({
      ...snap.data(),
    });
  }); */
  return usersSnap.data();
};

// Agregar usuario a la colección de usuarios
export const addUser = async (user: User) => {
  await db.collection("Users").doc(`${user.document}`).set(user);
};

// Get usuarios por entidad admin
export const getUsersByEntity = async (idEntity: string) => {
  const usersSnap = await db
    .collection("Users")
    .where("rol", "==", TypeUser.OFFICER)
    .where("idEntity", "==", idEntity)
    .get();
  const users: User[] = [];

  usersSnap.forEach((snap: any) => {
    users.push({
      ...(snap.data() as User),
    });
  });

  return users;
};

// Editar usuario en colección Users
export const editUser = async (user: User) => {
  await db.collection("Users").doc(`${user.id}`).set(user, { merge: true });
};

// Asignar módulos
export const assignModulesUser = async (user: User, modules: string[]) => {
  await db
    .collection("Users")
    .doc(`${user.document}`)
    .set({ modules }, { merge: true });
};

// Asignar oficinas
export const assignOfficesUser = async (idUser: string, offices: string[]) => {
  await db.collection("Users").doc(idUser).set({ offices }, { merge: true });
};

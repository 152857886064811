import axios from "axios";

export const downloadFileFirebase = (url: string, fileName: string) => {
  return axios
    .get(url, {
      responseType: "blob",
    })
    .then((response) => {
      return response.data;
    })
    .then((file) => {
      return new File([file], fileName, { type: file.type });
    })
    .catch(() => {
      return undefined;
    });
};

import clsx from "clsx";
import { Form, Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import { Box, Button, CircularProgress, Grid } from "@material-ui/core";
import { MyTextField } from "../../custom/MyTextField";
import { useStyles } from "../../../shared/styles/useStyles";
import {
  uiCloseModalAdd,
  uiCloseModalEdit,
} from "../../../redux/actions/uiActions";
import {
  startSetTender,
  setActiveTender,
} from "../../../redux/actions/correspondenceActions";
import { ITenders } from "../../../interfaces/Correspondence";
import { AppState } from "../../../redux/reducers/rootReducer";
import { firebase } from "../../../config/firebase/firebase-config";

export const TendersForm = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { activeTender } = useSelector(
    (state: AppState) => state.correspondence
  );

  let dateForm = activeTender
    ? new Date(
        moment(
          (activeTender?.closingDate as firebase.firestore.Timestamp).toDate()
        ).format("YYYY-MM-DDTHH:mm:ss")
      )
    : new Date();
  dateForm.setMinutes(dateForm.getMinutes() - dateForm.getTimezoneOffset());
  const valueDate = dateForm.toISOString().slice(0, 16);

  const initialValues: ITenders = {
    id: activeTender ? activeTender.id : "",
    closingDate: valueDate,
    object: activeTender ? activeTender.object : "",
    proponents: activeTender ? activeTender.proponents : [],
  };

  const validationSchema = yup.object({
    id: yup.string().required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    closingDate: yup
      .date()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    object: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  const onClose = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    dispatch(setActiveTender(null));
  };

  return (
    <Box m={2} mt={-1} mb={2}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          await dispatch(startSetTender(data, false));
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <label className="form-text">
                  <FormattedMessage id="ProcessNumber" />*
                </label>
                <Box width={"50%"}>
                  <MyTextField
                    name="id"
                    variant="outlined"
                    className={classes.myTextFieldRoot}
                    disabled={!!activeTender}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="ClosingDateAndTime" />*
                </label>
                <Box width={"50%"}>
                  <MyTextField
                    name="closingDate"
                    variant="outlined"
                    type="datetime-local"
                    className={classes.myTextFieldRoot}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Object" />*
                </label>
                <MyTextField
                  name="object"
                  variant="outlined"
                  multiline
                  minRows={4}
                  maxRows={8}
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Box mt={2} display="flex" flexDirection="row-reverse" width={1}>
                {!isSubmitting ? (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id={activeTender ? "Accept" : "Settle"} />
                  </Button>
                ) : (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="button"
                    disabled={true}
                  >
                    <CircularProgress className={classes.btnLoading} />
                  </Button>
                )}
                <Button
                  className={clsx(classes.btn, classes.cancel)}
                  onClick={onClose}
                >
                  <FormattedMessage id="Cancel" />
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

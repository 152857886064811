import { Box } from "@material-ui/core";
import React, {  } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";

import { TransferDetailsTabs } from "./TransferDetailsTabs";

export const TransferDetails = () => {
  const { activeTransfer } = useSelector(
    (state: AppState) => state.documentManagment
  );

  

  return (
    <Box m={2} mt={1} mb={2}>
      {activeTransfer && (
        <TransferDetailsTabs/>
        
      )}
    </Box>
  );
};

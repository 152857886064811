import React, { FC, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";
import { useStyles } from "../../shared/styles/useStyles";
import { useDispatch } from "react-redux";
import { startLoadMilestones } from "../../redux/actions/correspondenceActions";
import moment from "moment";

interface props {
  idSettled: string;
  idEntity: string | undefined;
}

export const MilestoneCorrespondence: FC<props> = ({ idSettled, idEntity }) => {
  const dispatch = useDispatch();
  // CARGAR LOS MILESTONES
  useEffect(() => {
    const dispatchMilestones = async () => {
      await dispatch(startLoadMilestones(idEntity, idSettled));
    };
    if (idSettled) {
      dispatchMilestones();
    }
  }, [idEntity, idSettled, dispatch]);

  const classes = useStyles();
  const { milestones } = useSelector((state: AppState) => state.correspondence);

  return (
    <div>
      <table className={classes.tableMilestone}>
        <thead>
          <tr>
            <th className={classes.fieldsMilestoneTable}>
              <FormattedMessage id="DateHour" />
            </th>
            <th className={classes.fieldsMilestoneTable}>
              <FormattedMessage id="Process" />
            </th>
          </tr>
        </thead>
        <tbody>
          {milestones.map((item, index) => {
            return (
              <tr key={index}>
                <td className={classes.fieldsMilestoneTable}>
                  {moment(item.createAt.toDate()).format("YYYY-MM-DD hh:mm a")}
                </td>
                <td className={classes.fieldsMilestoneTable}>
                  {item.processName}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

import clsx from "clsx";
import { Form, Formik } from "formik";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  MenuItem,
} from "@material-ui/core";
import {
  uiCloseModalAdd,
  uiCloseModalEdit,
} from "../../../../redux/actions/uiActions";
import { MyTextField } from "../../../custom/MyTextField";
import { useStyles } from "../../../../shared/styles/useStyles";
import {
  startAddOrEditSubserie,
  setActiveSubserie,
} from "../../../../redux/actions/trdActions";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { ITrdSubSerie } from "../../../../redux/types/types";
import { MyAlert } from "../../../custom/MyAlert";
import { uiCloseErrorAlert, setSuccessMsg } from '../../../../redux/actions/uiActions';

export const SubseriesForm = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    versions,
    activeSubSerie: active,
    productionOffices,
    series,

  } = useSelector((state: AppState) => state.trd);

  const { errorAlert, successMsg } = useSelector(
    (state: AppState) => state.ui
  );

  const validationSchema = yup.object({
    idVersion: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    idSerie: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    idProductionOffice: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    subSerieCode: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    name: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    managementFile: yup.string(),
    centralFile: yup.string(),
    provision: yup.object({
      E: yup.boolean(),
      S: yup.boolean(),
      CT: yup.boolean(),
    }),
    MD: yup.boolean(),
    process: yup.string(),
  });

  // TODO: CREAR INTERFAZ PARA VERSIÓN
  let initialValues: ITrdSubSerie = {
    idVersion: active ? active.idVersion : "",
    idProductionOffice: active ? active.idProductionOffice : "",
    idSerie: active ? active.idSerie : "",
    subSerieCode: active ? active.subSerieCode : "",
    name: active ? active.name : "",
    managementFile: active ? active.managementFile : "",
    centralFile: active ? active.centralFile : "",
    provision: {
      E: active ? active.provision.E : false,
      S: active ? active.provision.S : false,
      CT: active ? active.provision.CT : false,
      MD: active ? active.provision.MD : false,
    },
    process: active ? active.process : "",
    idSubserie:active ? active.idSubserie: "",
  };

  const onClose = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    dispatch(setActiveSubserie(null));
  };

  const onCloseAlert = () => {
    dispatch(uiCloseErrorAlert());
    dispatch(setSuccessMsg(null));
  }

  return (
    <Box m={1}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          dispatch(startAddOrEditSubserie(data));
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, handleChange }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Version" />*
                </label>
                <MyTextField
                  name="idVersion"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                  disabled={!!active}
                >
                  {versions.map((ver) => (
                    <MenuItem key={ver.idVersion} value={ver.idVersion}>
                      {ver.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="ProductionOffice" />*
                </label>
                <MyTextField
                  name="idProductionOffice"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                  disabled={!!active}
                >
                  {productionOffices.map((pOffice) => (
                    <MenuItem
                      key={pOffice.idProductionOffice}
                      value={pOffice.idProductionOffice}
                    >
                      {pOffice.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Series" />*
                </label>
                <MyTextField
                  name="idSerie"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                  disabled={!!active}
                >
                  {series.map((ser) => (
                    <MenuItem key={ser.idSerie} value={ser.idSerie}>
                      {ser.seriesName}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="SubseriesCode" />*
                </label>
                <MyTextField
                  name="subSerieCode"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  disabled={!!active}
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="SubseriesName" />*
                </label>
                <MyTextField
                  name="name"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Retention" />
                </label>
                <Box mt={1} display="flex">
                  <label className="form-text">
                    <FormattedMessage id="ManagementFile" />:
                  </label>
                  <Box ml={2} mt={-2} mr={2} width={"20%"}>
                    <MyTextField
                      name="managementFile"
                      variant="outlined"
                      className={classes.myTextFieldRoot}
                    />
                  </Box>

                  <label className="form-text">
                    <FormattedMessage id="FileModule" />:
                  </label>
                  <Box ml={2} mt={-2} width={"20%"}>
                    <MyTextField
                      name="centralFile"
                      variant="outlined"
                      className={classes.myTextFieldRoot}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Provision" />
                </label>
                <Box display="flex">
                  <Box width={"15%"}>
                    <Checkbox
                      checked={values.provision.E}
                      name="provision.E"
                      onChange={handleChange}
                      color="default"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <label
                      className="form-text"
                      style={{ marginTop: "10px", marginLeft: "-5px" }}
                    >
                      E
                    </label>
                  </Box>

                  <Box width={"15%"}>
                    <Checkbox
                      checked={values.provision.S}
                      name="provision.S"
                      onChange={handleChange}
                      color="default"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <label
                      className="form-text"
                      style={{ marginTop: "10px", marginLeft: "-5px" }}
                    >
                      S
                    </label>
                  </Box>

                  <Box width={"15%"}>
                    <Checkbox
                      checked={values.provision.CT}
                      name="provision.CT"
                      onChange={handleChange}
                      color="default"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <label
                      className="form-text"
                      style={{ marginTop: "10px", marginLeft: "-5px" }}
                    >
                      CT
                    </label>
                  </Box>

                  <Box width={"15%"}>
                    <Checkbox
                      checked={values.provision.MD}
                      name="provision.MD"
                      onChange={handleChange}
                      color="default"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <label
                      className="form-text"
                      style={{ marginTop: "10px", marginLeft: "-5px" }}
                    >
                      M/D
                    </label>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Procedure" />
                </label>
                <MyTextField
                  name="process"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>

            <Box mt={2} display="flex" flexDirection="row-reverse">
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="Save" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {successMsg && (
        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={successMsg}
          time={2000}
          handleClose={onCloseAlert}
        />
      )}
    </Box>
  );
};

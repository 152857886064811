import axios from "axios";
import { db } from "../../config/firebase/firebase-config";
import { firebaseApiResponse } from "../../interfaces/firebase";
import { IUserLoggedIn } from "../../redux/types/types";

export const getUserData = (email: string): Promise<IUserLoggedIn | null> => {
  return db
    .collection("Users")
    .where("email", "==", email)
    .limit(1)
    .get()
    .then((snap) => {
      let response: IUserLoggedIn | null = null;
      snap.forEach((doc) => {
        response = doc.data() as IUserLoggedIn;
      });
      return response;
    });
};


export const registerWithEmailPassword = async (
  email: string,
  password: string
): Promise<firebaseApiResponse> => {
  //CREAR EL USUARIO MEDIANTE EL API

  return axios
    .post(
      `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=${process.env.REACT_APP_FIREBASE_SERVER_KEY}`,
      { email, password, returnSecureToken: true }
    )
    .then((resp: any) => {
      const { data } = resp;
      return data as firebaseApiResponse;
    });
};



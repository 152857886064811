import Axios from "axios";
import { IStateData, ICountryData, ICityData } from "../interfaces/Apis";

export const getTokenApi = async () => {
  try {
    //obtener auth token
    const response = await Axios.get(
      "https://www.universal-tutorial.com/api/getaccesstoken",
      {
        headers: {
          Accept: "application/json",
          "api-token":
            "YzDgxWedn4ChtdDYADAmE7swHAWpPcM9wwC8uipVHtxxka9_73hlghotMZGJ7XXSiw0",
          "user-email": "desarrollos.appsus@gmail.com",
        },
      }
    );

    return response.data.auth_token;
  } catch (error) {
    console.log(error);
  }
};

export const getCountries = async (token: string) => {
  try {
    const response = await Axios.get(
      "https://www.universal-tutorial.com/api/countries/",
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
        },
      }
    );
    return response.data as ICountryData[];
  } catch (error) {
    console.log(error);
  }
};

export const getDepartments = async (country: string, token: string) => {
  try {
    const response = await Axios.get(
      `https://www.universal-tutorial.com/api/states/${country}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
        },
      }
    );
    return response.data as IStateData[];
  } catch (error) {
    console.log(error);
  }
};

export const getCities = async (department: string, token: string) => {
  try {
    const response = await Axios.get(
      `https://www.universal-tutorial.com/api/cities/${department}`,
      {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
        },
      }
    );
    return response.data as ICityData[];
  } catch (error) {
    console.log(error);
  }
};

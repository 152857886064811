import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import { ICorrespondenceResponse } from "../../../interfaces/Correspondence";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
import { checkEmailStatus, theme } from "../../../utils/utils";

export const EmailStatusHistory = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { activeAnswer: active } = useSelector(
    (state: AppState) => state.documentManagment
  );

  const answer = active as ICorrespondenceResponse;

  const getStatusColor = (status: string = "") => {
    if (checkEmailStatus(status)) {
      const styleClasses = classes as unknown as any;
      return styleClasses[`${status}BackgroundColor`];
    }

    return status;
  };

  const getStatusMessage = (status: string = "") => {
    if (checkEmailStatus(status)) {
      return intl.formatMessage({ id: `${status}StatusMessage` });
    }
    return status;
  };

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          size="small"
          style={{ tableLayout: "auto" }}
        >
          <TableHead
            className={`${classes.titleTable} ${classes["mailHistoryTableHeader"]}`}
          >
            <TableRow>
              <TableCell className={classes.titleTable}>Acción</TableCell>
              <TableCell className={classes.titleTable}>Fecha</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {answer?.status?.map((status, index) => {
              return (
                <TableRow key={index}>
                  <TableCell className={classes["statusCircleEmail"]}>
                    <div className="statusCircleEmailContent">
                      <span className={getStatusColor(status.status)}></span>
                      <span>{getStatusMessage(status.status)}</span>
                    </div>
                  </TableCell>
                  <TableCell>{status.date}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

import { User } from "../../interfaces/Users";
import { Types, UsersAction, IUser, IExternalSender } from "../types/types";

const initialState: IUser = {
  users: [],
  activeUser: null,
  assignedOffices: [],
  externalSenders: [],
  searchExternalSenders: [],
  searchExternalAddressee: [],
  activeExternalSender: null,
};

export const usersReducer = (state = initialState, action: UsersAction) => {
  switch (action.type) {
    case Types.userAddNew:
      return {
        ...state,
        users: [...state.users, action.payload],
      };

    case Types.usersLoad:
      return {
        ...state,
        users: [...action.payload],
      };

    case Types.userActive:
      return {
        ...state,
        activeUser: {
          ...action.payload,
        },
      };

    case Types.userCleanActive:
      return {
        ...state,
        activeUser: null,
      };

    case Types.userUpdated:
      return {
        ...state,
        users: state.users.map((user: User) =>
          user.id === action.payload.id ? action.payload : user
        ),
      };

    case Types.userLoadAssignedOffices:
      return {
        ...state,
        assignedOffices: [...action.payload],
      };

    case Types.userAddAssignedOffice:
      return {
        ...state,
        assignedOffices: [...state.assignedOffices, action.payload],
      };
    case Types.loadParamsExternalSender:
      return {
        ...state,
        externalSenders: [...action.payload],
      };
    case Types.addExternalSender:
      return {
        ...state,
        externalSenders: [...state.externalSenders, action.payload],
      };
    case Types.searchExternalSenders:
      return {
        ...state,
        searchExternalSenders: [...action.payload],
      };
    case Types.searchExternalAddressees:
      return {
        ...state,
        searchExternalAddressee: [...action.payload],
      };
    case Types.updateExternalSender:
      return {
        ...state,
        externalSenders: state.externalSenders.map((sender: IExternalSender) =>
          sender.idDoc === action.payload.idDoc ? action.payload : sender
        ),
      };
    case Types.userDeleteAssignedOffice:
      return {
        ...state,
        assignedOffices: state.assignedOffices.filter(
          (office) => office.idOffice !== action.payload
        ),
      };

    case Types.setActiveExternalSender:
      return {
        ...state,
        activeExternalSender: action.payload,
      };

    default:
      return state;
  }
};

import {
  Box,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  createTheme,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { FormattedMessage } from "react-intl";
import { Colors } from "../../../shared/constants/themes/Colors";
import { useStyles } from "../../../shared/styles/useStyles";
import { CustomizedTableFooter } from "../../custom/CustomizedTableFooter";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { counter } from "../../../helpers/count";
import { TendersBody } from "./TendersBody";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});

export const TendersTable = () => {
  const classes = useStyles();

  const { data, value } = useSelector((state: AppState) => state.search);
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );
  const { tenders } = useSelector((state: AppState) => state.correspondence);
  const count = counter(value, tenders, data);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, tenders.length - page * rowsPerPage);

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        {tenders.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            <FormattedMessage id={"NoCreatedTenders"} />
          </Box>
        ) : (
          <Table
            className={classes.table}
            size="small"
            style={{ tableLayout: "auto" }}
            aria-label="a dense table"
          >
            <TableHead className={classes.titleTable}>
              <TableRow>
                <TableCell
                  width={"25%"}
                  align="center"
                  className={classes.titleTable}
                >
                  <FormattedMessage id="ProcessNumber" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="ClosingDateAndTime" />{" "}
                </TableCell>
                <TableCell
                  width={"30%"}
                  align="center"
                  className={classes.titleTable}
                >
                  <FormattedMessage id="Object" />{" "}
                </TableCell>
                <TableCell
                  width={"20%"}
                  className={classes.titleTable}
                  align="center"
                >
                  <FormattedMessage id="Actions" />{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!value.trim()
                ? (rowsPerPage > 0
                    ? tenders.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : tenders
                  ).map((row) => <TendersBody process={row} key={row.id} />)
                : data &&
                  (rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((row) => <TendersBody process={row} key={row.id} />)}

              {emptyRows > 0 && (
                <TableRow style={{ height: 13 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {/* --------- Footer para paginación -------- */}
            <CustomizedTableFooter count={count} />
          </Table>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

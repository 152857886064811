import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Divider, Paper, Box, Grid, Button } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { useStyles } from "../../../../shared/styles/useStyles";
import {
  uiOpenModalAdd,
  uiCloseModalAdd,
  uiCloseSuccessAlert,
  uiCloseErrorAlert,
  uiCloseModalEdit,
  uiCloseModalDelete,
  uiCloseModalAttachDocuments,
} from "../../../../redux/actions/uiActions";
import { CustomizedSearch } from "../../../custom/CustomizedSearch";
import CustomizedDialog from "../../../custom/CustomizedDialog";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { VersionTable } from "./VersionTable";
import { VersionForm } from "./VersionForm";
import { MyAlert } from "../../../custom/MyAlert";
import { setActiveTrdVersion } from "../../../../redux/actions/trdActions";
import { ConfirmDelete } from "../../../ui/shared/ConfirmDelete";
import { AttachDocuments } from "./AttachDocuments";

export const VersionTab = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    modalAddOpen,
    successAlert,
    errorAlert,
    modalEditOpen,
    modalDeleteOpen,
    successMsg,
    modalAtachDocumentsOpen,
  } = useSelector((state: AppState) => state.ui);
  const { activeVersion, versions } = useSelector(
    (state: AppState) => state.trd
  );
  const onAdd = () => {
    dispatch(uiOpenModalAdd());
  };
  const onDenyAdd = () => {
    dispatch(uiCloseModalAdd());
  };
  const onDenyEdit = () => {
    dispatch(setActiveTrdVersion(null));
    dispatch(uiCloseModalEdit());
  };
  const onDenyDelete = () => {
    dispatch(setActiveTrdVersion(null));
    dispatch(uiCloseModalDelete());
  };
  const onDenyAtach = () => {
    dispatch(setActiveTrdVersion(null));
    dispatch(uiCloseModalAttachDocuments());
  };

  return (
    <>
      <Divider variant="fullWidth" />
      <Paper square className={classes.paper}>
        <Box>
          <Box style={{ height: "5px" }}></Box>
          <Box m={2}>
            <Grid container style={{ marginBottom: "20px" }}>
              <Grid item xs={7}>
                <Button
                  style={{ fontSize: 13 }}
                  size="large"
                  variant="contained"
                  color="secondary"
                  className={classes.btnAdd}
                  type="button"
                  onClick={onAdd}
                >
                  <AddOutlinedIcon />
                  <FormattedMessage id="AddNewVersion" />
                </Button>
              </Grid>
              <Grid item xs={5}>
                <CustomizedSearch data={versions} />
              </Grid>
            </Grid>
            <VersionTable />

            <CustomizedDialog
              open={modalAddOpen}
              cancelBtn={true}
              onDeny={onDenyAdd}
              title={`${intl.formatMessage({ id: "NewVersionTRD" })}`}
              content={<VersionForm />}
              textButton="Accept"
            />
            <CustomizedDialog
              open={modalEditOpen}
              cancelBtn={true}
              onDeny={onDenyEdit}
              title={`${intl.formatMessage({ id: "EditTrdVersion" })}`}
              content={<VersionForm edit />}
              textButton="Accept"
            />
            <CustomizedDialog
              open={modalAtachDocumentsOpen}
              cancelBtn={true}
              onDeny={onDenyAtach}
              title={`${intl.formatMessage({ id: "AttachSupportDocuments" })}`}
              content={<AttachDocuments />}
              textButton="Accept"
            />
            {activeVersion && (
              <CustomizedDialog
                open={modalDeleteOpen}
                cancelBtn={true}
                onDeny={onDenyDelete}
                title={`${intl.formatMessage({ id: "DeleteTrdVersion" })}`}
                content={
                  <ConfirmDelete
                    title={`${intl.formatMessage({
                      id: "QuestionDeleteVersion",
                    })}`}
                  />
                }
                textButton="Accept"
              />
            )}
          </Box>
          {successMsg && (
            <MyAlert
              open={successAlert}
              typeAlert="success"
              message={successMsg}
              time={2000}
              handleClose={() => dispatch(uiCloseSuccessAlert())}
            />
          )}
          <MyAlert
            open={errorAlert}
            typeAlert="error"
            message="VersionDeletedError"
            time={2000}
            handleClose={() => dispatch(uiCloseErrorAlert())}
          />
        </Box>
      </Paper>
    </>
  );
};

import { FormattedMessage } from "react-intl";

import { Box, CircularProgress, Typography } from "@material-ui/core";

import { useStyles } from "../../../shared/styles/useStyles";
import { CorrespondenceStatisticsForm } from "./CorrespondenceStatisticsForm";
import { CorrespondenceStatisticsTable } from "./CorrespondenceStatisticsTable";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useState } from "react";
import { ICorrespondence } from "../../../interfaces/Correspondence";
import { getCorrespondenceStadistics } from "../../../services/firebase/correspondence";
import moment from "moment";

export const CorrespondenceStatistics = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state: AppState) => state.auth);
  const [results, setResults] = useState<ICorrespondence[]>([]);

  const onSearch = async (data: any) => {
    try {
      setLoading(true);
      if (user && user.idEntity) {
        const endDate = moment(data.endDate).add(1, "day").format("YYYY-MM-DD");
        const response = await getCorrespondenceStadistics(
          user.idEntity,
          data.channel,
          data.idProductionOffice,
          endDate,
          data.officer,
          data.startDate
        );
        setResults(response);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      throw new Error("Error al consultar datos");
    }
  };

  return (
    <Box p={2}>
      <Typography variant="body1" className={classes.title}>
        <FormattedMessage id="CorrespondenceStatistics" />
      </Typography>

      <CorrespondenceStatisticsForm onSearch={onSearch} />

      {loading ? (
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <CircularProgress style={{ display: "flex", alignSelf: "center" }} />
        </div>
      ) : (
        <CorrespondenceStatisticsTable correspondences={results} />
      )}
    </Box>
  );
};

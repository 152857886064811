import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import {
  Box,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  ThemeProvider,
  TableRow,
  createTheme,
  TableBody,
} from "@material-ui/core";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { CustomizedTableFooter } from "../../custom/CustomizedTableFooter";
import { counter } from "../../../helpers/count";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
import { Colors } from "../../../shared/constants/themes/Colors";
import { TransfersBody } from "./TransfersBody";
import { ITransfer } from "../../../redux/types/types";
import clsx from "clsx";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      height: "40px",
    },
  })
)(TableCell);

interface props {
  readOnly?: boolean;
  isFileModule?: true;
  transfers: ITransfer[];
}

export const TransfersTable: FC<props> = ({
  readOnly = false,
  transfers,
  isFileModule,
}) => {
  const classes = useStyles();

  const { data, value } = useSelector((state: AppState) => state.search);

  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );
  const count = counter(value, transfers, data);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, transfers.length - page * rowsPerPage);

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper} className={classes.heightTable}>
        {transfers.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            <FormattedMessage id={"NoCreatedDocumentaryTransfers"} />
          </Box>
        ) : (
          <Table
            className={classes.table}
            size="small"
            style={{ width: "auto", tableLayout: "auto" }}
            aria-label="a dense table"
          >
            <TableHead className={classes.titleTable}>
              <TableRow>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="SenderEntity" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="ProducingEntity" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="AdministrativeUnit" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="ProductionOffice" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Object" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="TransferDate" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="TransferNumberAbbreviation" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="TransferSender" />{" "}
                </StyledTableCell>
                {!readOnly && (
                  <StyledTableCell
                    align="center"
                    className={clsx(classes.titleTable, classes.fixedColumn)}
                  >
                    <FormattedMessage id="Actions" />
                  </StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!value.trim()
                ? (rowsPerPage > 0
                    ? transfers.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : transfers
                  ).map((row) => (
                    <TransfersBody
                      transfers={row}
                      key={row.idTransfer}
                      isFileModule={isFileModule}
                    />
                  ))
                : data &&
                  (rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((row) => (
                    <TransfersBody
                      transfers={row}
                      key={row.idTransfer}
                      isFileModule={isFileModule}
                    />
                  ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 13 * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
            {/* --------- Footer para paginación -------- */}
            <CustomizedTableFooter count={count} />
          </Table>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

import { IUi, Types, UiAction } from "../types/types";

const initialState: IUi = {
  modalAddOpen: false,
  modalEditOpen: false,
  modalDeleteOpen: false,
  modalInfoOpen: false,
  modalAnswerOpen: false,
  modalAnswerBroadcastOpen: false,
  modalAnswerBroadcastOpenEdit: false,
  modalEmailStatusHistory: false,
  modalEmailSent: false,
  modalAssignModulesOpen: false,
  modalAssignOfficesOpen: false,
  modalSeeOpen: false,
  modalActionOpen: false,
  modalRejectOpen: false,
  alertExists: false,
  successAlert: false,
  errorAlert: false,
  successChange: false,
  successMsg: null,
  errorMsg: null,
  modalAtachDocumentsOpen: false,
  loading: false,
  questionMsg: null,
  titleMsg: null,
  openAnswerModal: false,
};

export const uiReducer = (state: IUi = initialState, action: UiAction): IUi => {
  switch (action.type) {
    case Types.uiOpenModalAdd:
      return {
        ...state,
        modalAddOpen: true,
      };

    case Types.uiCloseModalAdd:
      return {
        ...state,
        modalAddOpen: false,
      };

    case Types.uiOpenModalEdit:
      return {
        ...state,
        modalEditOpen: true,
      };

    case Types.uiCloseModalEdit:
      return {
        ...state,
        modalEditOpen: false,
      };

    case Types.uiOpenModalDelete:
      return {
        ...state,
        modalDeleteOpen: true,
      };

    case Types.uiCloseModalDelete:
      return {
        ...state,
        modalDeleteOpen: false,
      };

    case Types.uiOpenModalAssignModules:
      return {
        ...state,
        modalAssignModulesOpen: true,
      };

    case Types.uiCloseModalAssignModules:
      return {
        ...state,
        modalAssignModulesOpen: false,
      };

    case Types.uiOpenModalAssignOffices:
      return {
        ...state,
        modalAssignOfficesOpen: true,
      };

    case Types.uiCloseModalAssignOffices:
      return {
        ...state,
        modalAssignOfficesOpen: false,
      };

    case Types.uiOpenAlertExists:
      return {
        ...state,
        alertExists: true,
      };

    case Types.uiCloseAlertExists:
      return {
        ...state,
        alertExists: false,
      };

    case Types.uiOpenSuccessAlert:
      return {
        ...state,
        successAlert: true,
      };

    case Types.uiCloseSuccessAlert:
      return {
        ...state,
        successAlert: false,
      };

    case Types.uiOpenErrorAlert:
      return {
        ...state,
        errorAlert: true,
      };

    case Types.uiCloseErrorAlert:
      return {
        ...state,
        errorAlert: false,
        errorMsg: null,
      };

    case Types.uiOpenSuccessChange:
      return {
        ...state,
        successChange: true,
      };

    case Types.uiCloseSuccessChange:
      return {
        ...state,
        successChange: false,
      };
    case Types.uiSetSuccesMsg:
      return {
        ...state,
        successMsg: action.payload as any,
      };
    case Types.uiSetErrorMsg:
      return {
        ...state,
        errorMsg: action.payload as any,
      };
    case Types.uiOpenModalAttachDocuments:
      return {
        ...state,
        modalAtachDocumentsOpen: true,
      };
    case Types.uiCloseModalAttachDocuments:
      return {
        ...state,
        modalAtachDocumentsOpen: false,
      };
    case Types.uiOpenModalSee:
      return {
        ...state,
        modalSeeOpen: true,
      };
    case Types.uiCloseModalSee:
      return {
        ...state,
        modalSeeOpen: false,
      };

    case Types.uiStartLoading:
      return {
        ...state,
        loading: true,
      };

    case Types.uiFinishLoading:
      return {
        ...state,
        loading: false,
      };

    case Types.uiOpenModalAction:
      return {
        ...state,
        modalActionOpen: true,
      };

    case Types.uiOpenModalInfo:
      return {
        ...state,
        modalInfoOpen: true,
      };

    case Types.uiOpenModalAnswer:
      return {
        ...state,
        modalAnswerOpen: true,
      };

    case Types.uiOpenModalAnswerBroadcast:
      return {
        ...state,
        modalAnswerBroadcastOpen: true,
      };

    case Types.uiOpenModalAnswerBroadcastEdit:
      return {
        ...state,
        modalAnswerBroadcastOpenEdit: true,
      };

    case Types.uiOpenModalEmailStatusHistory:
      return {
        ...state,
        modalEmailStatusHistory: true,
      };

    case Types.uiCloseModalEmailStatusHistory:
      return {
        ...state,
        modalEmailStatusHistory: false,
      };

    case Types.uiOpenModalEmailSent:
      return {
        ...state,
        modalEmailSent: true,
      };

    case Types.uiCloseModalEmailSent:
      return {
        ...state,
        modalEmailSent: false,
      };

    case Types.uiCloseModalAction:
      return {
        ...state,
        modalActionOpen: false,
      };

    case Types.uiCloseModalInfo:
      return {
        ...state,
        modalInfoOpen: false,
      };

    case Types.uiCloseModalAnswer:
      return {
        ...state,
        modalAnswerOpen: false,
      };

    case Types.uiCloseAnswerModal:
      return {
        ...state,
        openAnswerModal: false,
      };
    case Types.uiCloseModalAnswerBroadcast:
      return {
        ...state,
        modalAnswerBroadcastOpen: false,
      };

    case Types.uiCloseModalAnswerBroadcastEdit:
      return {
        ...state,
        modalAnswerBroadcastOpenEdit: false,
      };
    case Types.uiOpenModalReject:
      return {
        ...state,
        modalRejectOpen: true,
      };
    case Types.uiCloseModalReject:
      return {
        ...state,
        modalRejectOpen: false,
      };

    case Types.uiSetQuestionMsg:
      return {
        ...state,
        questionMsg: action.payload as any,
      };
    case Types.uiSetTitleMsg:
      return {
        ...state,
        titleMsg: action.payload as any,
      };
    case Types.uiOpenModalAnswerCorrespondence:
      return {
        ...state,
        openAnswerModal: !state.openAnswerModal,
      };

    default:
      return state;
  }
};

import clsx from "clsx";
import * as yup from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import { Form, Formik } from "formik";
import {
  Box,
  Divider,
  FormControlLabel,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import { useStyles } from "../../../shared/styles/useStyles";
import { MyTextField } from "../../custom/MyTextField";
import { IParamResponseTime } from "../../../redux/types/types";
import { TypeDays } from "../../../enums/enums";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { updateResponseTimesForm } from "../../../redux/actions/correspondenceActions";
import { MyAlert } from "../../custom/MyAlert";
import {
  setSuccessMsg,
  uiCloseErrorAlert,
  uiCloseSuccessAlert,
} from "../../../redux/actions/uiActions";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 16,
  },
});

export const ResponseTimesTab = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { paramResponseTime } = useSelector(
    (state: AppState) => state.correspondence
  );

  const { successAlert, errorAlert, successMsg, errorMsg } = useSelector(
    (state: AppState) => state.ui
  );

  const onCloseSuccessAlert = () => {
    dispatch(uiCloseSuccessAlert());
    dispatch(setSuccessMsg(null));
  };

  const validationSchema = yup.object({
    RightOfPetitionDays: yup
      .number()
      .min(1, `${intl.formatMessage({ id: "PositiveNumber" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    RightOfPetitionTypeDays: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    ComplaintDays: yup
      .number()
      .min(1, `${intl.formatMessage({ id: "PositiveNumber" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    ComplaintTypeDays: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    ClaimDays: yup
      .number()
      .min(1, `${intl.formatMessage({ id: "PositiveNumber" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    ClaimTypeDays: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    SuggestionDays: yup
      .number()
      .min(1)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    SuggestionTypeDays: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    GuardianshipDays: yup
      .number()
      .min(1)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    GuardianshipTypeDays: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    RequestForInformationDays: yup
      .number()
      .min(1, `${intl.formatMessage({ id: "PositiveNumber" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    RequestForInformationTypeDays: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    PetitionDays: yup
      .number()
      .min(1, `${intl.formatMessage({ id: "PositiveNumber" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    PetitionTypeDays: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  let initialValues: IParamResponseTime = {
    RightOfPetitionDays: paramResponseTime.RightOfPetitionDays,
    RightOfPetitionTypeDays: paramResponseTime.RightOfPetitionTypeDays,
    ComplaintDays: paramResponseTime.ComplaintDays,
    ComplaintTypeDays: paramResponseTime.ComplaintTypeDays,
    ClaimDays: paramResponseTime.ClaimDays,
    ClaimTypeDays: paramResponseTime.ClaimTypeDays,
    SuggestionDays: paramResponseTime.SuggestionDays,
    SuggestionTypeDays: paramResponseTime.SuggestionTypeDays,
    GuardianshipDays: paramResponseTime.GuardianshipDays,
    GuardianshipTypeDays: paramResponseTime.GuardianshipTypeDays,
    RequestForInformationDays: paramResponseTime.RequestForInformationDays,
    RequestForInformationTypeDays:
      paramResponseTime.RequestForInformationTypeDays,
    PetitionDays: paramResponseTime.PetitionDays,
    PetitionTypeDays: paramResponseTime.PetitionTypeDays,
  };

  return (
    <ThemeProvider theme={theme}>
      <Divider variant="fullWidth" />
      <Paper square className={classes.paper}>
        <Box m={1} ml={10}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (data, { setSubmitting }) => {
              setSubmitting(true);
              await dispatch(updateResponseTimesForm(data));
              setSubmitting(false);
            }}
          >
            {({ isSubmitting, values, handleChange }) => (
              <Form className={classes.input}>
                <br />
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <h1 className={classes.title}>
                      <FormattedMessage id="TypeCorrespondence" />
                    </h1>
                  </Grid>

                  <Grid item xs={4} className={classes.title}>
                    <h1 className={classes.title}>
                      <FormattedMessage id="DaysM" />
                    </h1>
                  </Grid>

                  <Grid item xs={4}></Grid>

                  {/* //////////////////////////  1  derecho de petición  ///////////////////////////////// */}

                  <Grid item xs={4} className={classes.titleCenter}>
                    <FormattedMessage id="RightOfPetition" />
                  </Grid>

                  <Grid item xs={1}>
                    <MyTextField
                      name="RightOfPetitionDays"
                      variant="outlined"
                      size="small"
                      type="number"
                      className={classes.myTextFieldRoot}
                    ></MyTextField>
                  </Grid>

                  <Grid item xs={5} className={classes.botton_between}>
                    <Box ml={5}>
                      <RadioGroup
                        className={classes.botton_between}
                        row
                        aria-label="position"
                        name="RightOfPetitionTypeDays"
                        value={values.RightOfPetitionTypeDays}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={TypeDays.BUSINESSDAYS}
                          control={<Radio color="primary" />}
                          label={`${intl.formatMessage({
                            id: "BusinessDays",
                          })}`}
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                        />
                        <FormControlLabel
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                          value={TypeDays.CALENDAR}
                          control={<Radio color="primary" />}
                          label={`${intl.formatMessage({ id: "Calendar" })}`}
                        />
                      </RadioGroup>
                    </Box>
                  </Grid>

                  {/* //////////////////////////  2  quejas  ///////////////////////////////// */}

                  <Grid item xs={4} className={classes.titleCenter}>
                    <FormattedMessage id="Complaints" />
                  </Grid>

                  <Grid item xs={1}>
                    <MyTextField
                      name="ComplaintDays"
                      variant="outlined"
                      size="small"
                      type="number"
                      className={classes.myTextFieldRoot}
                    ></MyTextField>
                  </Grid>

                  <Grid item xs={5} className={classes.botton_between}>
                    <Box ml={5}>
                      <RadioGroup
                        className={classes.botton_between}
                        row
                        aria-label="position"
                        name="ComplaintTypeDays"
                        value={values.ComplaintTypeDays}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={TypeDays.BUSINESSDAYS}
                          control={<Radio color="primary" />}
                          label={`${intl.formatMessage({
                            id: "BusinessDays",
                          })}`}
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                        />
                        <FormControlLabel
                          value={TypeDays.CALENDAR}
                          control={<Radio color="primary" />}
                          label={`${intl.formatMessage({ id: "Calendar" })}`}
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                        />
                      </RadioGroup>
                    </Box>
                  </Grid>

                  {/* //////////////////////////  3 reclamos  ///////////////////////////////// */}

                  <Grid item xs={4} className={classes.titleCenter}>
                    <FormattedMessage id="Claims" />
                  </Grid>

                  <Grid item xs={1}>
                    <MyTextField
                      name="ClaimDays"
                      variant="outlined"
                      size="small"
                      type="number"
                      className={classes.myTextFieldRoot}
                    ></MyTextField>
                  </Grid>

                  <Grid item xs={5} className={classes.botton_between}>
                    <Box ml={5}>
                      <RadioGroup
                        className={classes.botton_between}
                        row
                        aria-label="position"
                        name="ClaimTypeDays"
                        value={values.ClaimTypeDays}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={TypeDays.BUSINESSDAYS}
                          control={<Radio color="primary" />}
                          label={`${intl.formatMessage({
                            id: "BusinessDays",
                          })}`}
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                        />
                        <FormControlLabel
                          value={TypeDays.CALENDAR}
                          control={<Radio color="primary" />}
                          label={`${intl.formatMessage({ id: "Calendar" })}`}
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                        />
                      </RadioGroup>
                    </Box>
                  </Grid>

                  {/* //////////////////////////  4 sugerencias  ///////////////////////////////// */}

                  <Grid item xs={4} className={classes.titleCenter}>
                    <FormattedMessage id="Suggestions" />
                  </Grid>

                  <Grid item xs={1}>
                    <MyTextField
                      name="SuggestionDays"
                      variant="outlined"
                      size="small"
                      type="number"
                      className={classes.myTextFieldRoot}
                    ></MyTextField>
                  </Grid>

                  <Grid item xs={5} className={classes.botton_between}>
                    <Box ml={5}>
                      <RadioGroup
                        className={classes.botton_between}
                        row
                        aria-label="position"
                        name="SuggestionTypeDays"
                        value={values.SuggestionTypeDays}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={TypeDays.BUSINESSDAYS}
                          control={<Radio color="primary" />}
                          label={`${intl.formatMessage({
                            id: "BusinessDays",
                          })}`}
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                        />
                        <FormControlLabel
                          value={TypeDays.CALENDAR}
                          control={<Radio color="primary" />}
                          label={`${intl.formatMessage({ id: "Calendar" })}`}
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                        />
                      </RadioGroup>
                    </Box>
                  </Grid>

                  {/* //////////////////////////  5 tutelas  ///////////////////////////////// */}

                  <Grid item xs={4} className={classes.titleCenter}>
                    <FormattedMessage id="Guardianships" />
                  </Grid>

                  <Grid item xs={1}>
                    <MyTextField
                      name="GuardianshipDays"
                      variant="outlined"
                      size="small"
                      type="number"
                      className={classes.myTextFieldRoot}
                    ></MyTextField>
                  </Grid>

                  <Grid item xs={5} className={classes.botton_between}>
                    <Box ml={5}>
                      <RadioGroup
                        className={classes.botton_between}
                        row
                        aria-label="position"
                        name="GuardianshipTypeDays"
                        value={values.GuardianshipTypeDays}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={TypeDays.BUSINESSDAYS}
                          control={<Radio color="primary" />}
                          label={`${intl.formatMessage({
                            id: "BusinessDays",
                          })}`}
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                        />
                        <FormControlLabel
                          value={TypeDays.CALENDAR}
                          control={<Radio color="primary" />}
                          label={`${intl.formatMessage({ id: "Calendar" })}`}
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                        />
                      </RadioGroup>
                    </Box>
                  </Grid>

                  {/* //////////////////////////  6 solicitudes de información  ///////////////////////////////// */}

                  <Grid item xs={4} className={classes.titleCenter}>
                    <FormattedMessage id="InformationRequests" />
                  </Grid>

                  <Grid item xs={1}>
                    <MyTextField
                      name="RequestForInformationDays"
                      variant="outlined"
                      size="small"
                      type="number"
                      className={classes.myTextFieldRoot}
                    ></MyTextField>
                  </Grid>

                  <Grid item xs={5} className={classes.botton_between}>
                    <Box ml={5}>
                      <RadioGroup
                        className={classes.botton_between}
                        row
                        aria-label="position"
                        name="RequestForInformationTypeDays"
                        value={values.RequestForInformationTypeDays}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={TypeDays.BUSINESSDAYS}
                          control={<Radio color="primary" />}
                          label={`${intl.formatMessage({
                            id: "BusinessDays",
                          })}`}
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                        />
                        <FormControlLabel
                          value={TypeDays.CALENDAR}
                          control={<Radio color="primary" />}
                          label={`${intl.formatMessage({ id: "Calendar" })}`}
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                        />
                      </RadioGroup>
                    </Box>
                  </Grid>

                  {/* //////////////////////////  7 consultas  ///////////////////////////////// */}

                  <Grid item xs={4} className={classes.titleCenter}>
                    <FormattedMessage id="Petition" />
                  </Grid>

                  <Grid item xs={1}>
                    <MyTextField
                      name="PetitionDays"
                      variant="outlined"
                      size="small"
                      type="number"
                      className={classes.myTextFieldRoot}
                    ></MyTextField>
                  </Grid>

                  <Grid item xs={5} className={classes.botton_between}>
                    <Box ml={5}>
                      <RadioGroup
                        className={classes.botton_between}
                        row
                        aria-label="position"
                        name="PetitionTypeDays"
                        value={values.PetitionTypeDays}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={TypeDays.BUSINESSDAYS}
                          control={<Radio color="primary" />}
                          label={`${intl.formatMessage({
                            id: "BusinessDays",
                          })}`}
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                        />
                        <FormControlLabel
                          value={TypeDays.CALENDAR}
                          control={<Radio color="primary" />}
                          label={`${intl.formatMessage({ id: "Calendar" })}`}
                          classes={{
                            label: classes.checkboxLabel,
                          }}
                        />
                      </RadioGroup>
                    </Box>
                  </Grid>
                </Grid>

                <Box
                  mt={5}
                  ml={30}
                  display="flex"
                  alignItems="center"
                  flexDirection="column-reverse"
                >
                  {!isSubmitting ? (
                    <Button
                      className={clsx(classes.btn, classes.save)}
                      autoFocus
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <FormattedMessage id="Save" />
                    </Button>
                  ) : (
                    <Button
                      className={clsx(classes.btn, classes.save)}
                      autoFocus
                      type="button"
                      disabled={true}
                    >
                      <CircularProgress className={classes.btnLoading} />
                    </Button>
                  )}
                </Box>

                <br />
                {successMsg && (
                  <MyAlert
                    open={successAlert}
                    typeAlert="success"
                    message={successMsg}
                    time={2000}
                    handleClose={onCloseSuccessAlert}
                  />
                )}
                {errorMsg && (
                  <MyAlert
                    open={errorAlert}
                    typeAlert="error"
                    message={errorMsg}
                    time={3000}
                    handleClose={() => dispatch(uiCloseErrorAlert())}
                  />
                )}
              </Form>
            )}
          </Formik>
        </Box>
      </Paper>
    </ThemeProvider>
  );
};

import {
  IFileModuleAction,
  ITrdProductionOffices,
  IUserLoggedIn,
} from "./../types/types";
import { deleteFile, uploadFileAsync } from "../../helpers/UploadFile";
import {
  ICorrespondence,
  ICommunicationType,
  IReassingForm,
  IUserCorrespondence,
  IRequestProjection,
  ICreateAnnotationForm,
  ICorrespondenceResponse,
  IRequestApproval,
  IReviewers,
  IRequestAddicionalInformationForm,
  IFilesCorrespondence,
  IRequestExtencionForm,
  ISignature,
} from "../../interfaces/Correspondence";
import {
  FuidAction,
  IAppendDocsSend,
  ICorrespondenceActions,
  IDeleteCopy,
  IDeposits,
  IFiles,
  IFuid,
  IProjectionRedux,
  ISendCopy,
  ITransfer,
  Types,
} from "../types/types";
import {
  formatDate,
  getCopyArrayOrObject,
  getCurrentDateAndHourColombia,
} from "../../utils/utils";
import {
  addAttachedDocumentCorrespondenceDb,
  addCorresAnnotationsDb,
  addMilestoneDb,
  addCorrespondenceResponse,
  setRequestProjection,
  setSendCorrespondenceCopy,
  updateCorrespondenceDB,
  deleteFileCorrespondence,
  loadDepositsDb,
  addNewDepositDb,
  addAddicionalInformationDb,
  submitFinalAnswer,
  addNewFuid,
  addMilestoneExpedientManagment,
  sendEmailNotification,
  updateEmailCorrespondece,
} from "../../services/firebase/correspondence";
import {
  finishLoading,
  setErrorMsg,
  startLoading,
  uiCloseModalAdd,
  uiCloseModalAnswer,
  uiCloseModalAnswerBroadcast,
  uiCloseModalAssignModules,
  uiCloseModalEdit,
  uiCloseModalInfo,
  uiCloseModalSee,
  uiOpenErrorAlert,
} from "./uiActions";
import {
  setSuccessMsg,
  uiOpenSuccessAlert,
  uiCloseModalAction,
} from "./uiActions";
import { AppState } from "../reducers/rootReducer";
import { firebase } from "../../config/firebase/firebase-config";
import {
  ActionSettled,
  CorrespondenceInputType,
  CorrespondenceStatus,
  Enum_Notification_Type,
  TypeArchive,
} from "../../enums/enums";
import {
  ICorresAnnotations,
  IDocsExpedientsSend,
  IDocumentsFuid,
} from "../types/types";
import {
  addSignatureDb,
  addTransferDb,
  deleteCorresAnnotationDB,
  deleteCorresAnnotationDBDoc,
  deleteDepositDb,
  deleteSignatureDb,
  deleteTransferDb,
  loadSignatureDb,
  startListenerTransfersByEntity,
} from "../../services/firebase/documentManagment";
import {
  setCorrespondence,
  startSetCorrespondence,
} from "./correspondenceActions";
import {
  addTransferFileModule,
  setExpedientFileModule,
} from "../../services/firebase/fileModule";
import {
  IExpedientCorrespondence,
  IAppendDocs,
} from "../../interfaces/Correspondence";
import {
  setActiveFuidFileModule,
  updateExpedientFileModule,
} from "./fileModuleActions";
import { setActiveFuid } from "./fuidActions";
import {
  setEditExpedientDb,
  addAttachedDocumentExpedientDb,
} from "../../services/firebase/fuid";
import { addMilestoneDb as addMilestoneExpedient } from "../../services/firebase/centralArchive";
import Swal from "sweetalert2";
import moment from "moment";
import { InternalCorrespondenceStatus } from "../../enums/enums";
import {
  IEmailNotificationParams,
  IEmailParamDataEp,
  IEmailParamDataFa,
  IEmailParamDataSi,
  IEmailRequestExtension,
  IFormSendEmail,
  IInputDataEmail,
  IEmailStatus,
  IFile,
} from "../../interfaces/emailNotification";
import {
  getEntityById,
  getDataFromEntities,
} from "../../services/firebase/entities";
import { ICheckFiles } from "../../components/document-managment/correspondence/ClassifyTrdForm";
import { addOneBarcode } from "../../helpers/addBarcode";
import { downloadFileFirebase } from "../../helpers/downloadFileFirebase";
import { Entity } from "../../interfaces/Users";

export const loadCorrespondenceManagment = (
  correspondence: ICorrespondence[]
): ICorrespondenceActions => ({
  type: Types.loadCorrespondenceDocumentManagment,
  payload: correspondence,
});

export const setActiveCorrespondenceDocManagment = (
  correspondence: ICorrespondence | null
): ICorrespondenceActions => ({
  type: Types.setActiveCorrespondenceDocumentManagment,
  payload: correspondence,
});

export const setTitleAction = (title: string) => ({
  type: Types.setTitleAction,
  payload: title,
});

// Reclasificar radicado
export const startReclassifySettled = (
  communicationType: ICommunicationType,
  intl: any
) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity, username, id } = getState().auth.user;
    const { activeCorrespondence: active } = getState().documentManagment;
    const copyActive: ICorrespondence = getCopyArrayOrObject(active);

    if (copyActive.communicationType?.option === communicationType.option) {
      dispatch(setErrorMsg("DifferentCommunicationType"));
      dispatch(uiOpenErrorAlert());
    } else {
      active.communicationType = communicationType;
      const { ok } = await updateCorrespondenceDB(active, idEntity);

      if (ok) {
        //AGREGAR AL MILESTONE

        await addMilestoneDb(
          idEntity,
          copyActive.id,
          firebase.firestore.Timestamp.now(),
          `Correspondencia reclasificada a ${intl.formatMessage({
            id: `${communicationType.option}`,
          })}`,
          parseInt(id),
          username
        );
        dispatch(setActiveCorrespondenceDocManagment(active));
        dispatch(updateCorrespondence(active));
        dispatch(uiCloseModalAction());
        dispatch(setSuccessMsg("SettledReclassified"));
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(setErrorMsg("ErrorReclassifyingSettled"));
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};

// reasignar radicado
export const startReassingSettled = (valuesForm: IReassingForm) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity, username, id } = getState().auth.user;
    const { activeCorrespondence: active } = getState().documentManagment;
    const copyActive: ICorrespondence = getCopyArrayOrObject(active);
    active.receivingDependency = valuesForm.receivingDependency;
    if (
      active.inputType === CorrespondenceInputType.EP ||
      active.inputType === CorrespondenceInputType.IP
    ) {
      active.externalSender = valuesForm.addressee;
    } else {
      active.addressee = valuesForm.addressee;
    }
    active.isGiveBack = false;
    const { ok } = await updateCorrespondenceDB(active, idEntity);

    if (ok) {
      //AGREGAR AL MILESTONE

      await addMilestoneDb(
        idEntity,
        copyActive.id,
        firebase.firestore.Timestamp.now(),
        `Reasignado a ${valuesForm.addressee.name}`,
        parseInt(id),
        username
      );

      dispatch(setActiveCorrespondenceDocManagment(active));
      dispatch(updateCorrespondence(active));
      dispatch(uiCloseModalAction());
      dispatch(uiCloseModalSee());
      dispatch(setSuccessMsg("SettledReassigned"));
      dispatch(uiOpenSuccessAlert());
    } else {
      dispatch(setErrorMsg("ErrorReassignSettled"));
      dispatch(uiOpenErrorAlert());
    }
  };
};

export const updateCorrespondence = (correspondence: ICorrespondence) => ({
  type: Types.updateCorrespondence,
  payload: correspondence,
});

export const startsendCorrespondenceCopy = (
  copy: ISendCopy,
  currentUserSend: IUserCorrespondence
) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;
    const { ok } = await setSendCorrespondenceCopy(
      idEntity,
      copy.idCorrespondence,
      copy.users
    );
    if (ok) {
      //AGREGAR AL MILESTONE
      await addMilestoneDb(
        idEntity,
        copy.idCorrespondence,
        firebase.firestore.Timestamp.now(),
        `Envío de copia a ${currentUserSend.name}`,
        parseInt(currentUserSend.id),
        currentUserSend.name
      );
      dispatch(sendCorrespondenceCopy(copy));
      dispatch(uiCloseModalAction());
      dispatch(setSuccessMsg("CopySend"));
      dispatch(uiOpenSuccessAlert());
    } else {
      dispatch(uiOpenErrorAlert());
      dispatch(setErrorMsg("ErrorCopySend"));
    }
  };
};
const sendCorrespondenceCopy = (copy: ISendCopy): ICorrespondenceActions => ({
  type: Types.sendCorrespondenceCopy,
  payload: copy,
});

export const startDeleteCorrespondenceCopy = (idUser: string) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;
    const { activeCorrespondence } = (getState() as AppState).documentManagment;
    if (activeCorrespondence && activeCorrespondence.copyTo) {
      const newUsers = activeCorrespondence.copyTo.filter(
        (user) => user.id !== idUser
      );
      const { ok } = await setSendCorrespondenceCopy(
        idEntity,
        activeCorrespondence.id,
        newUsers
      );

      if (ok) {
        dispatch(
          deleteCorrespondenceCopy({
            idCorrespondence: activeCorrespondence.id,
            idUser,
          })
        );
        dispatch(uiCloseModalSee());
        dispatch(uiOpenSuccessAlert());
        dispatch(setSuccessMsg("CopyDeleted"));
      } else {
        dispatch(uiCloseModalSee());
        dispatch(uiOpenErrorAlert());

        dispatch(setErrorMsg("ErrorCopyDelete"));
      }
    }
  };
};
const deleteCorrespondenceCopy = (
  data: IDeleteCopy
): ICorrespondenceActions => ({
  type: Types.deleteCorrespondenceCopy,
  payload: data,
});

export const startScreeningRequest = (
  idCorrespondence: string,
  data: IRequestProjection
) => {
  return async (dispatch: Function, getState: Function) => {
    try {
      const { idEntity } = getState().auth.user;
      const { activeCorrespondence } = (getState() as AppState)
        .documentManagment;

      if (activeCorrespondence) {
        const dataProjection = activeCorrespondence.screeningRequest
          ? [...activeCorrespondence.screeningRequest, data]
          : [data];
        const { ok } = await setRequestProjection(
          idEntity,
          idCorrespondence,
          dataProjection
        );
        activeCorrespondence.screeningRequest = dataProjection;
        const now = firebase.firestore.Timestamp.now();
        const objAnswer: ICorrespondenceResponse = {
          subject: ActionSettled.REQUEST_FOR_PROJECTION,
          submitDate: now,
          officer: {
            id: data.officerId,
            name: data.officer,
          },
          isScreeningAnswer: false,
          description: data.comment,
        };
        const { ok: ok2 } = await addCorrespondenceResponse(
          idEntity,
          activeCorrespondence.id,
          objAnswer
        );
        if (ok && ok2) {
          dispatch(requestProjection({ projection: data, idCorrespondence }));
          dispatch(setActiveCorrespondenceDocManagment(activeCorrespondence));
          //AGREGAR AL MILESTONE

          await addMilestoneDb(
            idEntity,
            idCorrespondence,
            now,
            `Solicitud de proyección a ${data.officer}`,
            parseInt(data.officerId),
            data.officer
          );
          dispatch(uiCloseModalAction());
          dispatch(uiOpenSuccessAlert());
          dispatch(setSuccessMsg("ScreeningRequested"));
        }
      }
    } catch (error) {
      dispatch(uiCloseModalAction());
      dispatch(uiOpenErrorAlert());
      dispatch(setErrorMsg("ScreeningError"));
    }
  };
};
const requestProjection = (
  projection: IProjectionRedux
): ICorrespondenceActions => ({
  type: Types.requestProjection,
  payload: projection,
});

// FUNCION PARA AGREGAR DOCUMENTO ADJUNTO
export const addAttachedDocumentCorrespondence = (
  objsAppendDocs: IAppendDocsSend | IDocsExpedientsSend,
  file: File,
  expedient?: boolean,
  fromModule?: boolean,
  isExpedient?: boolean,
  idExpedientCorrespondence?: string
) => {
  return async (dispatch: any, getState: Function) => {
    const { activeExpedient: expedientFuid } = (getState() as AppState).fuid;
    const { activeExpedientFileModule: expedientFileModule } = (
      getState() as AppState
    ).fileModule;
    const { user } = (getState() as AppState).auth;
    const { activeCorrespondence } = (getState() as AppState).documentManagment;
    if (
      user &&
      user.idEntity &&
      (activeCorrespondence || expedientFuid || expedientFileModule)
    ) {
      for (let i = 0; i < objsAppendDocs.appendDocs.length; i++) {
        if (objsAppendDocs.appendDocs[i].url === "#") {
          let addedFile = file;
          const route = !!expedient
            ? `expedients/${user.idEntity}/${
                (objsAppendDocs as IDocsExpedientsSend).appendDocs[i].fileName
              }`
            : `correspondence/${user.idEntity}/${
                (objsAppendDocs as IAppendDocsSend).appendDocs[i].filename
              }`;

          if (
            addedFile.type === "application/pdf" &&
            activeCorrespondence?.id
          ) {
            addedFile = await addOneBarcode(addedFile, activeCorrespondence.id);
          }

          const urlFile = await uploadFileAsync(addedFile, route);
          objsAppendDocs.appendDocs[i].url = urlFile;
        }
      }

      const idDoc = !!expedient
        ? (objsAppendDocs as IDocsExpedientsSend).idExpedient
        : (objsAppendDocs as IAppendDocsSend).idCorrespondence;
      const module = fromModule ? "FileModule" : "Correspondence";
      // AGREGAR DOCUMENTOS SI HAY O NO EXPEDIENTE ASIGANDO A LA CORRESPONDENCIA
      if (isExpedient && idExpedientCorrespondence) {
        const { ok } = await addAttachedDocumentExpedientDb(
          module,
          idExpedientCorrespondence,
          objsAppendDocs.appendDocs as IDocumentsFuid[],
          user.idEntity
        );
        if (ok) {
          expedientFuid!.documents = (
            objsAppendDocs as IDocsExpedientsSend
          ).appendDocs;
          dispatch(setActiveFuid(expedientFuid));
        }
      }
      const { ok } = !!expedient
        ? await addAttachedDocumentExpedientDb(
            module,
            idDoc!,
            objsAppendDocs.appendDocs as IDocumentsFuid[],
            user.idEntity
          )
        : await addAttachedDocumentCorrespondenceDb(
            idDoc!,
            objsAppendDocs.appendDocs as IAppendDocs[],
            user.idEntity
          );
      if (ok) {
        if (!!expedient) {
          fromModule
            ? (expedientFileModule!.documents = (
                objsAppendDocs as IDocsExpedientsSend
              ).appendDocs)
            : (expedientFuid!.documents = (
                objsAppendDocs as IDocsExpedientsSend
              ).appendDocs);
          fromModule
            ? dispatch(setActiveFuidFileModule(expedientFileModule))
            : dispatch(setActiveFuid(expedientFuid));
        } else {
          activeCorrespondence &&
            (activeCorrespondence.appendDocs = (
              objsAppendDocs as IAppendDocsSend
            ).appendDocs);
          activeCorrespondence &&
            dispatch(setActiveCorrespondenceDocManagment(activeCorrespondence));
        }
        dispatch(uiCloseModalAction());
        dispatch(setSuccessMsg("AttachedDocument"));
        dispatch(uiOpenSuccessAlert());
      }
    }
  };
};

// ****************   CORRESPONDENCIA - Anotaciones    *******************

export const startUploadAnnotations = (values: ICreateAnnotationForm) => {
  return async (dispatch: Function, getState: Function) => {
    const closeErrorAnnotation = async () => {
      dispatch(setErrorMsg("ErrorToCreateAnnotation"));
      dispatch(uiCloseModalAction());
      dispatch(uiOpenErrorAlert());
    };

    const { user } = (getState() as AppState).auth;
    const { activeCorrespondence } = (getState() as AppState).documentManagment;
    const id = Date.now();
    const date = firebase.firestore.Timestamp.now();

    if (user && user?.idEntity && user.username && activeCorrespondence) {
      let corresAnnotatiosFiles: IFiles[] = [];
      for (const file of values.attachedDocuments) {
        if (file.file) {
          let currentFile = file.file;

          if (currentFile.type === "application/pdf") {
            currentFile = await addOneBarcode(
              currentFile,
              activeCorrespondence.id
            );
          }

          const resp = await uploadFileAsync(
            currentFile,
            `/CorresAnnotations/${user.idEntity}/${file.fileName}`
          );
          const obj = { fileName: file.fileName, url: resp };
          corresAnnotatiosFiles.push(obj);
        }
        file.url !== "#" && corresAnnotatiosFiles.push(file);
      }

      const selectUser: IUserCorrespondence = {
        id: user.id.toString(),
        name: user.username,
      };
      const addCorresAnnotationNew: ICorresAnnotations = {
        attachedFiles: corresAnnotatiosFiles,
        description: values.description,
        id: id.toString() + "_" + user.id.toString(),
        user: selectUser,
        createDate: date,
      };
      const { ok } = await addCorresAnnotationsDb(
        user.idEntity,
        activeCorrespondence.id,
        addCorresAnnotationNew
      );
      if (ok) {
        dispatch(addCorresAnnotation(addCorresAnnotationNew));
        dispatch(setSuccessMsg("CreatedCommunication"));
        dispatch(uiCloseModalAction());
        dispatch(uiOpenSuccessAlert());
      } else {
        closeErrorAnnotation();
      }
    } else {
      closeErrorAnnotation();
    }
  };
};

export const addCorresAnnotation = (corresAnnotarion: ICorresAnnotations) => ({
  type: Types.addCorresAnnotation,
  payload: corresAnnotarion,
});

export const loadCorresAnnotations = (
  corresAnnotations: ICorresAnnotations[]
) => ({
  type: Types.loadAllCorresAnnotations,
  payload: corresAnnotations,
});

export const deleteCorresAnnotation = (
  idEntity: string,
  CorrespondenceDMId: string,
  CorresAnnotationId: string
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    const { activeCorrespondence, activeCorresAnnotation } = (
      getState() as AppState
    ).documentManagment;
    if (user && activeCorrespondence) {
      const { ok } = await deleteCorresAnnotationDB(
        idEntity,
        CorrespondenceDMId,
        CorresAnnotationId
      );
      if (activeCorresAnnotation?.attachedFiles[0] !== undefined) {
        await deleteCorresAnnotationDBDoc(
          idEntity,
          activeCorresAnnotation?.attachedFiles[0].fileName
        );
      }
      if (ok) {
        await dispatch(setSuccessMsg("DeletedAnnotation"));
        if (activeCorresAnnotation) {
          await dispatch(
            deleteCorresAnnotationReducer(activeCorresAnnotation.id)
          );
        }
        await dispatch(uiCloseModalAction());
        await dispatch(uiOpenSuccessAlert());
      } else {
        await dispatch(setErrorMsg("DeletingError"));
        await dispatch(uiCloseModalAction());
        await dispatch(uiOpenErrorAlert());
      }
    }
  };
};

export const deleteCorresAnnotationReducer = (corresAnntotationId: string) => ({
  type: Types.deleteCorresAnnotation,
  payload: corresAnntotationId,
});
export const startDeleteFileAnswer = (idAnswer: string, fileName: string) => {
  return async (dispatch: Function, getState: Function) => {
    const { activeAnswer, activeCorrespondence } = (getState() as AppState)
      .documentManagment;
    const { user } = (getState() as AppState).auth;

    if (
      activeAnswer &&
      activeAnswer.idAnswer &&
      user &&
      user.idEntity &&
      activeCorrespondence
    ) {
      const activeRef = { ...activeAnswer };
      activeRef.attachedDocuments = activeRef.attachedDocuments?.filter(
        (el: any) => el.fileName !== fileName
      );
      const { ok: okDeleteFile } = await deleteFile(
        `correspondence/${user.idEntity}/${fileName}`
      );
      const { ok } = await addCorrespondenceResponse(
        user.idEntity,
        activeCorrespondence.id,
        activeRef
      );
      if (ok && okDeleteFile) {
        dispatch(uiCloseModalAnswerBroadcast());
        dispatch(setSuccessMsg("FileDeleted"));
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(uiCloseModalAnswerBroadcast());
        dispatch(setErrorMsg("ErrorDeleteFile"));
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};

const deleteDuplicateFilename = (fileNames: IFiles[]) => {
  if (fileNames.length >= 1) {
    return fileNames.filter(
      (obj, index, self) =>
        index ===
        self.findIndex(
          (file) => file.fileName === obj.fileName && file.url === obj.url
        )
    );
  }
  return fileNames;
};

export const startIssueResponseCorrespondence = (
  response: ICorrespondenceResponse,
  idCorrespondence: string,
  edit: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    const { activeAnswer } = (getState() as AppState).documentManagment;
    if (user && user.idEntity && user.username) {
      const objResponse: ICorrespondenceResponse = {
        ...response,
        isScreeningAnswer: true,
        officer: { id: user.id.toString(), name: user.username },
      };
      let attachedDocuments: IFiles[] = [];

      if (response.attachedDocuments) {
        for (const file of response.attachedDocuments) {
          if (file.file) {
            const urlFile = await uploadFileAsync(
              file.file,
              `correspondence/${user.idEntity}/${file.fileName}`
            );
            const obj = { fileName: file.fileName, url: urlFile };

            attachedDocuments.push(obj);
          } else {
            attachedDocuments.push(file);
          }
          file.url !== "#" && attachedDocuments.push(file);
        }
      }

      objResponse.attachedDocuments =
        deleteDuplicateFilename(attachedDocuments);

      const { ok } = await addCorrespondenceResponse(
        user.idEntity,
        idCorrespondence,
        objResponse
      );
      if (ok) {
        //AGREGAR AL MILESTONE
        await addMilestoneDb(
          user.idEntity,
          idCorrespondence,
          firebase.firestore.Timestamp.now(),
          !!activeAnswer ? "Edición de respuesta" : "Emisión de respuesta",
          user.id,
          user.username
        );

        await dispatch(uiCloseModalAnswerBroadcast());
        await dispatch(uiCloseModalAction());
        dispatch(setSuccessMsg("IssueResponseSended"));
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(uiCloseModalAnswerBroadcast());
        await dispatch(uiCloseModalAction());
        dispatch(setErrorMsg("IssueResponseError"));
        dispatch(uiOpenErrorAlert());
      }
    }
    dispatch(setActiveAnswer(null));
  };
};

// Solicitud de visto bueno
export const startRequestApprovalCorrespondence = (data: IRequestApproval) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity, id, username } = getState().auth.user;
    const { activeCorrespondence: active } = getState().documentManagment;
    const { receivingDependency, addressee, comment } = data;
    const copyActive: ICorrespondence = getCopyArrayOrObject(active);
    const lastReviewers = copyActive.reviewers;
    const lastReviewersIds = copyActive.reviewersIds;
    const submitDate = firebase.firestore.Timestamp.now();

    const objAnswer: ICorrespondenceResponse = {
      subject: ActionSettled.APPROVED,
      submitDate,
      officer: addressee,
      isScreeningAnswer: false,
    };

    if (lastReviewersIds && lastReviewersIds.includes(addressee.id)) {
      dispatch(setErrorMsg("ApprovalRequestAlreadyExists"));
      dispatch(uiOpenErrorAlert());
    } else {
      const newReviewer = {
        id: addressee.id,
        isOk: active.isOk,
        comments: comment,
        dependency: receivingDependency,
      };

      const finalReviewers = lastReviewers
        ? [...lastReviewers, newReviewer]
        : [newReviewer];

      const finalReviewersIds = finalReviewers.map((user) => user.id);

      active.reviewers = finalReviewers;
      active.reviewersIds = finalReviewersIds;

      const { ok: ok1 } = await updateCorrespondenceDB(active, idEntity);
      const { ok: ok2 } = await addCorrespondenceResponse(
        idEntity,
        active.id,
        objAnswer
      );
      const { ok: ok3 } = await addMilestoneDb(
        idEntity,
        active.id,
        submitDate,
        ActionSettled.APPROVED,
        id,
        username
      );

      if (ok1 && ok2 && ok3) {
        dispatch(setActiveCorrespondenceDocManagment(active));
        dispatch(updateCorrespondence(active));
        dispatch(uiCloseModalAction());
        dispatch(setSuccessMsg("ApprovalRequestSent"));
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(setErrorMsg("ErrorApprovalRequest"));
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};

export const deleteDocumentCorrespondence = (
  fileName: string,
  copyActiveCorrespondende: ICorrespondence,
  intl: any
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    if (user && user.idEntity) {
      const { ok } = await deleteFileCorrespondence(user.idEntity, fileName);
      if (ok) {
        await dispatch(
          startSetCorrespondence(copyActiveCorrespondende, true, true)
        );
      }
      Swal.fire({
        title: ok
          ? `${intl.formatMessage({ id: "DocumentDeleted" })}`
          : `${intl.formatMessage({ id: "ErrorDocumentDelete" })}`,
        icon: ok ? "success" : "error",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "swal-container-z-index",
        },
      });
    }
  };
};

export const setActiveCorresAnnotation = (
  active: ICorresAnnotations | null
): ICorrespondenceActions => ({
  type: Types.setActiveCorrespondenceAnnotation,
  payload: active,
});

//SOLICITUD DE PRORROGA
export const startRequestExtension = (data: IRequestExtencionForm) => {
  return async (dispatch: Function, getState: Function) => {
    const { id, idEntity, username } = getState().auth.user;
    const { users, externalSenders } = (getState() as AppState).users;
    const allUsers = users.concat(externalSenders as any);
    const { activeCorrespondence: active } = (getState() as AppState)
      .documentManagment;
    const { paramResponseTime }: any = (getState() as AppState).correspondence;
    if (active) {
      const submitDate = firebase.firestore.Timestamp.now();
      //idAnswer generado desde firebase
      const objAnswer: ICorrespondenceResponse = {
        subject: data.subject,
        isScreeningAnswer: false,
        officer: { id, name: username },
        submitDate,
        //idAnswer:
        requestedDays: data.days,
        comment: data.comment,
        isExtension: true,
      };

      active.isInExtension = true;
      //CALCULAR LA FECHA FINAL Y SUMARLE LOS DIAS DE PRORROGA

      const daysAvailableDocument =
        paramResponseTime[active.communicationType!.option + "Days"];
      const typeDays =
        paramResponseTime[active.communicationType!.option + "TypeDays"];

      const responseDateParam =
        typeDays === "BUSINESSDAYS"
          ? moment(active.createAt.toDate()).businessAdd(
              daysAvailableDocument,
              "days"
            )
          : moment(active.createAt.toDate()).add(daysAvailableDocument, "days");

      const finalResponseDate = responseDateParam.add(data.days, "days");

      //MANDAR FINALRESPONSEDATE A LA CORRESPONDENCIA
      active.finalExtensionDate = firebase.firestore.Timestamp.fromDate(
        finalResponseDate.toDate()
      );

      const { ok } = await addCorrespondenceResponse(
        idEntity,
        active.id,
        objAnswer
      );
      const { ok: ok1 } = await updateCorrespondenceDB(active, idEntity);
      const { ok: ok2 } = await addMilestoneDb(
        idEntity,
        active.id,
        submitDate,
        `Solicitud de prórroga de ${data.days} días`,
        id,
        username
      );
      //MANDAR UN CORREO AL REMITENTE
      //ASUNTO: SOLICITUD DE PRORROGA
      //CONTENIDO: COMENTARIO REALIZADO POR EL USUARIO
      const dataEmail: IEmailRequestExtension = {
        comment: data.comment,
        settled: active.id,
      };
      const senderFind = getUserFromAllUsers(
        active.externalSender.id.toString(),
        allUsers
      );

      const entity = await getEntityById(active.idEntity);

      if (senderFind) {
        const totalParams: IEmailNotificationParams = {
          type: Enum_Notification_Type.EXTENSION,
          data: dataEmail,
          email: senderFind.email,
          emailSender: entity.emailSender,
          entityName: entity.businessName,
        };
        await sendEmailNotification(totalParams);
      }

      if (ok && ok1 && ok2) {
        dispatch(setActiveCorrespondenceDocManagment(active));
        dispatch(updateCorrespondence(active));
        dispatch(uiCloseModalAction());
        dispatch(setSuccessMsg("ExtensionRequested"));
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(setErrorMsg("ErrorExtensionRequested"));
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};

// Emisión de visto bueno
export const startIssueVoBo = (isOk: boolean, comment: string) => {
  return async (dispatch: Function, getState: Function) => {
    const { activeCorrespondence: active } = getState().documentManagment;
    const { id, idEntity, username } = getState().auth.user;
    const copyActive: ICorrespondence = getCopyArrayOrObject(active);
    const submitDate = firebase.firestore.Timestamp.now();
    const action = isOk
      ? ActionSettled.VO_BO_APPROVED
      : ActionSettled.VO_BO_REJECTED;

    const objAnswer: ICorrespondenceResponse = {
      subject: action,
      submitDate,
      officer: { id, name: username },
      emitComment: comment,
      isOk,
      isScreeningAnswer: false,
    };

    const newReviewers = copyActive.reviewers?.map((data: IReviewers) => {
      if (data.id === id.toString()) {
        data.isOk = isOk;
        data.emitComments = comment;
        data.submitDate = firebase.firestore.Timestamp.now();
      }
      return data;
    });
    active.isOk = isOk;
    active.reviewers = newReviewers;

    const { ok: ok1 } = await updateCorrespondenceDB(active, idEntity);
    const { ok: ok2 } = await addCorrespondenceResponse(
      idEntity,
      active.id,
      objAnswer
    );
    const { ok: ok3 } = await addMilestoneDb(
      idEntity,
      active.id,
      submitDate,
      action,
      id,
      username
    );

    if (ok1 && ok2 && ok3) {
      dispatch(setActiveCorrespondenceDocManagment(active));
      dispatch(updateCorrespondence(active));
      dispatch(uiCloseModalAction());
      dispatch(setSuccessMsg("ApprovalIssued"));
      dispatch(uiOpenSuccessAlert());
    } else {
      dispatch(setErrorMsg("ErrorIssuingApproval"));
      dispatch(uiOpenErrorAlert());
    }
  };
};

export const startLoadDeposits = (idEntity: string, idProductionOf: string) => {
  return async (dispatch: Function) => {
    const deposits = await loadDepositsDb(idEntity, idProductionOf);
    dispatch(loadDeposits(deposits));
  };
};
const loadDeposits = (deposits: IDeposits[]): ICorrespondenceActions => ({
  type: Types.loadDeposits,
  payload: deposits,
});

export const startAddNewDeposits = (idEntity: string, deposit: IDeposits) => {
  return async (dispatch: Function) => {
    const { ok } = await addNewDepositDb(idEntity, deposit);
    if (ok) {
      dispatch(addNewDeposit(deposit));
    }
  };
};
const addNewDeposit = (deposit: IDeposits): ICorrespondenceActions => ({
  type: Types.addDeposit,
  payload: deposit,
});

export const startSubmitFinalAnswer = (
  answer: ICorrespondenceResponse,
  howToSendEmail: string
) => {
  return async (dispatch: Function, getState: Function) => {
    const { users, externalSenders } = (getState() as AppState).users;
    const { productionOffices } = (getState() as AppState).trd;

    const allUsers = users.concat(externalSenders as any);
    const { activeCorrespondence: active } = (getState() as AppState)
      .documentManagment;
    const { paramResponseTime }: any = (getState() as AppState).correspondence;

    const { paramsProcedure }: any = (getState() as AppState).correspondence;

    const { user } = (getState() as AppState).auth;

    if (active && user && user.idEntity && user.offices && user.username) {
      const activeCopy = { ...active };
      const idDoc = answer.submitDate.toDate().getTime().toString();
      const arrayTemp: IFiles[] = [];

      if (answer?.attachedDocuments) {
        console.log(answer?.attachedDocuments);
        for (let index = 0; index < answer.attachedDocuments.length; index++) {
          const element = answer.attachedDocuments[index];
          if (element.url !== "#") {
            const file = await downloadFileFirebase(
              element.url,
              element.fileName
            );
            arrayTemp.push({ file, fileName: file?.name ?? "", url: "#" });
          } else {
            arrayTemp.push(element);
          }
        }
      }

      answer.attachedDocuments = [...arrayTemp];

      activeCopy.answer = {
        idAnswer: idDoc,
        subject: answer.subject,
        description: answer.description ? answer.description : "",
        attachedDocuments: [],
      };
      const answerCopy = { ...answer };

      activeCopy.answeredDate = answerCopy.submitDate;
      // ***************** status ****************
      activeCopy.status = CorrespondenceStatus.RESOLVED;

      // ****************** InternalState ***************************

      let daysAvailableDocument = null;
      let typeDays = null;

      if (
        active.communicationType?.option === "CommunicationOfControlEntities" ||
        active.communicationType?.option === "SummonsToJudicialProceedings"
      ) {
        daysAvailableDocument = active.responseTimeDays;
        typeDays = "BUSINESSDAYS";
      } else {
        daysAvailableDocument =
          paramResponseTime[active.communicationType!.option + "Days"];
        typeDays =
          paramResponseTime[active.communicationType!.option + "TypeDays"];

        if (
          daysAvailableDocument === null ||
          daysAvailableDocument === undefined ||
          typeDays === null ||
          typeDays === undefined
        ) {
          for (const param of paramsProcedure) {
            if (param.procedureName === active.communicationType!.option) {
              daysAvailableDocument = param.responseTime;
              typeDays = param.typeDays;
              break;
            }
          }
        }
      }

      let createDateDocument = activeCopy.createAt.toDate() as Date;
      let answerDateDocument = answerCopy.submitDate.toDate() as Date;
      const day = 1000 * 60 * 60 * 24;

      const differenceBusinessDays = moment(createDateDocument).businessDiff(
        moment(answerDateDocument)
      );
      const differenceCalendarDays = Math.floor(
        moment(answerDateDocument).diff(moment(createDateDocument)) / day
      );

      const percentageBusinessDays =
        (100 * differenceBusinessDays) / daysAvailableDocument;
      const percentageCalendarDays =
        (100 * differenceCalendarDays) / daysAvailableDocument;

      if (
        typeDays === "BUSINESSDAYS" &&
        activeCopy.communicationType?.isParam
      ) {
        activeCopy.internalState =
          percentageBusinessDays < 100
            ? InternalCorrespondenceStatus.RESPONSE_ISSUED_ONTIME
            : InternalCorrespondenceStatus.EXTEMPORANEOUS_RESPONSE;
      } else if (
        typeDays === "CALENDAR" &&
        activeCopy.communicationType?.isParam
      ) {
        activeCopy.internalState =
          percentageCalendarDays < 100
            ? InternalCorrespondenceStatus.RESPONSE_ISSUED_ONTIME
            : InternalCorrespondenceStatus.EXTEMPORANEOUS_RESPONSE;
      }
      // ********************************************************************

      //GENERAR RESPUESTA FINAL DE LA CORRESPONDENCIA

      //CREAR UNA CORRESPONDENCIA EP PARA VINCULAR LA RESPUESTA DE LA CORRESPONDENCIA
      //CANAL MANDADO DESDE EL FORM
      //REMITENTE ES EL FUNCIONARIO
      //DESTINATARIO ES EL CIUDADANO
      //CONTENIDO VIENE DEL FORMULARIO DE RESPUESTA
      //TIPO DE COMUNICACION "RESPUESTA"
      //MANDAR PETICION A CLOUD FUNCTION PARA ENVIAR CORREO CON LA RESPUESTA DE LA CORRESPONDENCIA
      const newCorrespondence: ICorrespondence = {
        annexesPages: 0,
        answer: {
          attachedDocuments: [],
          description: answerCopy.description ? answerCopy.description : "",
          subject: answerCopy.subject,
          idAnswer: answer.submitDate.toDate().getTime().toString(),
        },
        inputType:
          answerCopy.inputType === CorrespondenceInputType.IPR
            ? CorrespondenceInputType.IPR
            : CorrespondenceInputType.EP,
        addressee: {
          id: answer.addresseeId ? answer.addresseeId : "",
          name: answer.addresseeName ? answer.addresseeName : "",
        },
        answeredDate: answerCopy.submitDate,
        communicationChannel: answerCopy.communicationChannel
          ? answerCopy.communicationChannel
          : "",
        communicationPages: 0,
        communicationType: {
          isParam: false,
          option: "Answer",
          responseTime: 0,
        },
        copyPages: 0,
        createAt: null,
        description: answerCopy.description ? answerCopy.description : "",
        digitalSupport: answer.attachedDocuments,
        emailChannel: "",
        externalSender: active.addressee,
        guide: "",
        id: "",
        idEntity: active.idEntity,
        importanceType: {
          confidential: false,
          urgentCommunication: false,
        },
        internalState: activeCopy.internalState,
        isGiveBack: false,
        isOk: true,
        isReaded: false,
        isReadedVobo: false,
        pendingDate: null,
        postalAgency: "",
        producerDependency:
          answerCopy.inputType === CorrespondenceInputType.IPR
            ? active.receivingDependency
            : active.producerDependency
            ? active.producerDependency
            : "",
        receivingDependency:
          answerCopy.inputType === CorrespondenceInputType.IPR &&
          active.producerDependency
            ? active.producerDependency
            : active.receivingDependency,
        status: CorrespondenceStatus.RESOLVED,
        subject: `Respuesta a correspondencia con radicado No. ${active.id}`,
        settledAnswerId: active.id,
        emailForCommunications: answer.emailForCommunications,
      };
      const correspondenceCreated: ICorrespondence = await dispatch(
        startSetCorrespondence(newCorrespondence, false, false, false, true)
      );
      activeCopy.answer.attachedDocuments =
        correspondenceCreated.digitalSupport;
      answerCopy.attachedDocuments = correspondenceCreated.digitalSupport;
      const { ok } = await submitFinalAnswer(
        user.idEntity,
        active.id,
        answerCopy,
        activeCopy.status,
        activeCopy.internalState
      );
      if (ok) {
        //enviar correo al usuario
        //el remitente pasa a ser el destinatario
        if (
          answerCopy.inputType !== CorrespondenceInputType.IPR &&
          answer.addresseeEmail &&
          answer.description &&
          howToSendEmail === "1"
        ) {
          const senderFind = getUserFromAllUsers(
            active.addressee.id.toString(),
            allUsers
          );

          if (senderFind && answer.addresseeName) {
            const entity = await getEntityById(user.idEntity);

            const officeFind = productionOffices.find((office) =>
              //@ts-ignore
              user.offices[0].includes(office.idProductionOffice)
            );

            if (officeFind) {
              buildBodyMail(
                user,
                correspondenceCreated,
                answer,
                entity,
                senderFind,
                officeFind,
                active,
                dispatch
              );
            }
          }
        }
        dispatch(setCorrespondence(activeCopy));
        dispatch(uiCloseModalSee());
        dispatch(uiCloseModalAnswer());
        dispatch(setSuccessMsg("AnswerEmited"));
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(setErrorMsg("AnswerSenError"));
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};

const buildBodyMail = async (
  user: IUserLoggedIn,
  correspondenceCreated: ICorrespondence,
  answer: ICorrespondenceResponse,
  entity: any,
  senderFind: any,
  officeFind: ITrdProductionOffices,
  active: ICorrespondence,
  dispatch: Function
) => {
  const emailParamsFa: IEmailParamDataFa = {
    addresse: {
      name: answer.addresseeName ?? "",
      address: answer.addresseeDirection,
      city: answer.city,
      phone: answer.addresseePhone,
      id: answer.addresseeId,
    },
    answerSettled: correspondenceCreated.id,
    description: answer.description ?? "",
    entity: {
      name: entity.businessName,
      address: entity.address,
      phone: entity.phone,
      city: entity.town,
      id: entity.id,
    },
    files: correspondenceCreated.digitalSupport,
    responseDate: formatDate(answer.submitDate),
    sender: {
      name: senderFind.username ? senderFind.username : senderFind.name,
      office: officeFind.name,
    },
    settled: active.id,
    subject: answer.subject,
    email: answer.emailForCommunications ?? answer.addresseeEmail ?? "",
  };

  const responseEmail = (await sendMail(
    emailParamsFa,
    answer.addresseeEmail ?? "",
    entity.emailSender
  )) as unknown as any;

  const { ok: emailSent } = responseEmail;
  if (emailSent) {
    if (
      correspondenceCreated.inputType === CorrespondenceInputType.EP &&
      emailSent
    ) {
      const messageIdEmail = getMessageId(responseEmail);

      const iFormSendEmail: IFormSendEmail = {
        addressee: {
          id: answer.addresseeId ?? "",
          email: answer.addresseeEmail ?? "",
        },
        copyTo: [],
        files: correspondenceCreated.digitalSupport,
      };

      const { emailSender: emailSenderEntity } = await getDataFromEntities(
        entity.id.toString()
      );
      //Enviar al historial
      const emailStatus: IEmailStatus[] = initEmailState();
      await saveSentMailData(
        answer.addresseeEmail ?? "",
        "",
        correspondenceCreated.id,
        entity.id.toString(),
        user?.id.toString() ?? "",
        user?.username ?? "",
        iFormSendEmail,
        messageIdEmail,
        emailStatus,
        emailSenderEntity
      );
    }
  }
  dispatchEvents(emailSent, dispatch);
};

const sendMail = async (
  data: IEmailParamDataFa,
  emailRecipient: string,
  emailSender: string,
  isCopy?: boolean,
  emailAnswerParentId?: string
) => {
  const totalParams: IEmailNotificationParams = {
    type: Enum_Notification_Type.FA,
    data,
    email: emailRecipient, //enviar correo al destinatario
    emailSender,
    isCopy,
    emailAnswerParentId,
    entityName: data.entity.name,
  };
  return await sendEmailNotification(totalParams);
};

const initEmailState = () => {
  const emailStatus: IEmailStatus[] = [
    {
      date: getCurrentDateAndHourColombia(),
      status: "sent",
    },
  ];
  return emailStatus;
};

const getUserFromAllUsers = (id: string, allUsers: any[]) => {
  return allUsers.find(
    (user) => user.id?.toString() === id || user.idDoc?.toString() === id
  );
};

export const startSendEmailResponseEp = (
  correspondence: ICorrespondence,
  formValues: IFormSendEmail
) => {
  return async (dispatch: Function, getState: Function) => {
    const { users, externalSenders } = (getState() as AppState).users;
    const allUsers = users.concat(externalSenders as any);
    const { user } = (getState() as AppState).auth;

    const addressee = getUserFromAllUsers(
      correspondence.addressee.id.toString(),
      allUsers
    );

    const sender = getUserFromAllUsers(
      correspondence.externalSender.id.toString(),
      allUsers
    );

    const queryResponse = await getEntityById(correspondence.idEntity);
    const { emailSender: emailSenderEntity } = await getDataFromEntities(
      correspondence.idEntity
    );
    const entity = queryResponse as Entity;

    const { productionOffices } = (getState() as AppState).trd;

    const officeFind = productionOffices.find(
      (office) =>
        correspondence.receivingDependency === office.idProductionOffice
    );

    const emailParamsFa: IEmailParamDataFa = buildEmailParamsFaResponseEP(
      officeFind?.name ?? "",
      emailSenderEntity,
      correspondence,
      addressee,
      sender,
      entity,
      formValues.files
    );

    //Envía correo principal
    const responseEmail = (await sendMail(
      emailParamsFa,
      formValues.addressee.email,
      entity.emailSender
    )) as unknown as any;

    const { ok: emailSent } = responseEmail;

    if (emailSent) {
      const messageIdEmail = getMessageId(responseEmail);
      const emailStatus: IEmailStatus[] = initEmailState();

      const { ok, data: responseData } = await saveSentMailData(
        formValues.addressee.email,
        formValues.copyTo.toString(),
        correspondence.id,
        user?.idEntity ?? "",
        user?.id.toString() ?? "",
        user?.username ?? "",
        formValues,
        messageIdEmail,
        emailStatus,
        emailSenderEntity
      );

      //Envía correos como copias.
      if (ok) {
        sendCopyEmailResponseEP(
          formValues,
          emailParamsFa,
          entity,
          responseData,
          correspondence.id
        );
      }
    }

    dispatchEvents(emailSent, dispatch);
  };
};

const buildEmailParamsFaResponseEP = (
  officeName: string,
  emailSenderEntity: string,
  correspondence: ICorrespondence,
  addressee: any,
  sender: any,
  entity: Entity,
  files: IFile[]
) => {
  const emailParamsFa: IEmailParamDataFa = {
    addresse: {
      name: correspondence.addressee.name,
      address: addressee?.address ?? "",
      city: addressee.city,
      phone: addressee?.phone ?? "",
      id: correspondence.addressee.id,
    },
    answerSettled: correspondence.id,
    description: correspondence.answer?.description ?? "",
    entity: {
      name: entity.businessName,
      address: entity.address,
      phone: entity.phone,
      city: entity.town,
      id: entity.id,
    },
    files: files,
    responseDate: formatDate(correspondence.answeredDate),
    sender: {
      name: sender.username ? sender.username : sender.name,
      office: officeName,
    },
    settled: correspondence.settledAnswerId ?? "",
    subject: correspondence.answer?.subject ?? "",
    email: emailSenderEntity ?? addressee.email ?? "",
  };

  return emailParamsFa;
};

const sendCopyEmailResponseEP = (
  formValues: IFormSendEmail,
  emailParamsFa: IEmailParamDataFa,
  entity: any,
  responseData: any,
  correspondenceId: string
) => {
  Promise.all(
    formValues.copyTo.map(async (email: string) => {
      const response = (await sendMail(
        emailParamsFa,
        email,
        entity.emailSender,
        true,
        responseData.answerId
      )) as unknown as any;

      if (response.ok) {
        const messageId =
          response?.data?.body?.Messages[0]?.To[0]?.MessageUUID ?? "";

        const emailCopyStatus: IEmailStatus[] = initEmailState();

        await saveSentMailDataCopy(
          email,
          correspondenceId,
          entity.id.toString(),
          messageId,
          responseData.answerId,
          emailCopyStatus
        );
      }
    })
  );
};

export const startSetFuid = (
  values: IFuid,
  edit: boolean,
  fromCentralFile?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;

    if (user && user.idEntity) {
      const { ok } = fromCentralFile
        ? await addNewFuid(user.idEntity, values, true)
        : await addNewFuid(user.idEntity, values);

      if (ok) {
        fromCentralFile
          ? dispatch(setActiveFuidFileModule(values))
          : dispatch(setActiveFuid(values));

        dispatch(setSuccessMsg(edit ? "UpdateExpedient" : "CreatedExpedient"));

        dispatch(uiCloseModalInfo());

        dispatch(uiOpenSuccessAlert());
      } else {
        if (edit) {
          dispatch(setErrorMsg("ErrorToEditExpedient"));
        } else {
          dispatch(setErrorMsg("ErrorToCreateExpedient"));
        }

        dispatch(uiCloseModalInfo());

        dispatch(uiOpenErrorAlert());
      }
    }
  };
};

export const startUploadFuid = (
  values: IFuid,
  edit: boolean,
  module?: TypeArchive
) => {
  return async (dispatch: Function, getState: Function) => {
    const closeErrorExpediente = () => {
      if (edit) {
        dispatch(setErrorMsg("ErrorToEditExpedient"));
      } else {
        dispatch(setErrorMsg("ErrorToCreateExpedient"));
      }
      dispatch(uiCloseModalAction());
      dispatch(uiOpenErrorAlert());
    };
    const { user } = (getState() as AppState).auth;
    const copyValues: IFuid = { ...values };

    const extremeInitialDate = new Date(copyValues.extremeInitialDate);
    const extremeFinalDate = new Date(copyValues.extremeFinalDate);
    copyValues.extremeInitialDate =
      firebase.firestore.Timestamp.fromDate(extremeInitialDate);
    copyValues.extremeFinalDate =
      firebase.firestore.Timestamp.fromDate(extremeFinalDate);

    if (user && user.idEntity && user.username) {
      copyValues.owner = module
        ? {
            id: module,
            name: module,
          }
        : {
            id: user.id.toString(),
            name: user.username,
          };

      const { ok } = module
        ? await addNewFuid(user.idEntity, copyValues, true)
        : await addNewFuid(user.idEntity, copyValues);

      module
        ? await addMilestoneExpedient(
            user.idEntity,
            copyValues.id,
            firebase.firestore.Timestamp.now(),
            `${edit ? "Editado" : "Creado"} por ${user.username}`,
            user.id,
            user.username,
            !!module
          )
        : await addMilestoneExpedientManagment(
            user.idEntity,
            copyValues.id,
            firebase.firestore.Timestamp.now(),
            `${edit ? "Editado" : "Creado"}por ${user.username}`,
            user.id,
            user.username
          );

      if (ok) {
        //dispatch(addNewFuidState(copyValues));
        module
          ? dispatch(setActiveFuidFileModule(copyValues))
          : dispatch(setActiveFuid(copyValues));

        dispatch(setSuccessMsg(edit ? "UpdateExpedient" : "CreatedExpedient"));

        dispatch(uiCloseModalAdd());
        dispatch(uiCloseModalEdit());
        dispatch(uiCloseModalAction());

        dispatch(uiOpenSuccessAlert());
      } else {
        closeErrorExpediente();
      }
    } else {
      closeErrorExpediente();
    }
  };
};

// EJECUTAR EL DISPATCH PARA GUARDAR LOS EXPEDIENTES DEL REDUX
export const addNewFuidState = (fuid: IFuid): FuidAction => ({
  type: Types.addFuid,
  payload: fuid,
});

// **********   CORRESPONDENCIA - información adicional    **************

export const startUploadAddicionalInformation = (
  values: IRequestAddicionalInformationForm
) => {
  return async (dispatch: Function, getState: Function) => {
    const closeErrorAddicionalInformation = async () => {
      await dispatch(setErrorMsg("ErrorAdditionalInformationSent"));
      await dispatch(uiCloseModalAction());
      await dispatch(uiOpenErrorAlert());
    };

    const { user } = (getState() as AppState).auth;
    const { users, externalSenders } = (getState() as AppState).users;
    const allUsers = users.concat(externalSenders as any);
    const { activeCorrespondence } = (getState() as AppState).documentManagment;
    const id = Date.now();
    const date = firebase.firestore.Timestamp.now();
    if (user?.idEntity && activeCorrespondence) {
      let documents: IFiles[] = [];
      for (const file of values.attachedDocuments) {
        if (file.file) {
          const resp = await uploadFileAsync(
            file.file,
            `/Answers/${user.idEntity}/${file.fileName}`
          );
          const obj = { fileName: file.fileName, url: resp };
          documents.push(obj);
        }
        file.url !== "#" && documents.push(file);
      }

      let name = "";
      if (user.username) {
        name = user.username;
      }
      const officer: IUserCorrespondence = {
        id: user.id.toString(),
        name,
      };
      const isScreeningAnswer = false;
      //mandar notificacion al email del remitente
      const { externalSender } = activeCorrespondence;

      const senderFind = getUserFromAllUsers(
        externalSender.id.toString(),
        allUsers
      );

      if (senderFind.email === "") {
        dispatch(setErrorMsg("NotEmailSender"));
        dispatch(uiOpenErrorAlert());

        return dispatch(uiCloseModalAction());
      }

      const { ok } = await addAddicionalInformationDb(
        user.idEntity,
        activeCorrespondence.id,
        documents,
        values.annotation,
        values.subjectAnnotation,
        isScreeningAnswer,
        id.toString(),
        officer,
        date
      );

      if (ok) {
        if (senderFind) {
          const emailParamsSi: IEmailParamDataSi = {
            user: {
              email: senderFind.email,
              phone:
                senderFind.phone || senderFind.phone === ""
                  ? senderFind.phone
                  : senderFind.mobilePhone,
              userName: senderFind.username
                ? senderFind.username
                : senderFind.name,
            },
            description: values.annotation,
            files: documents,
            settled: activeCorrespondence.id,
            subject: values.subjectAnnotation,
          };

          const entity = await getEntityById(user.idEntity);

          const totalParams: IEmailNotificationParams = {
            type: Enum_Notification_Type.SI,
            data: emailParamsSi,
            email: senderFind.email,
            emailSender: entity.emailSender,
            entityName: entity.businessName,
          };

          await sendEmailNotification(totalParams);
          await dispatch(setSuccessMsg("AdditionalInformationSent"));
          await dispatch(uiCloseModalAction());
          await dispatch(uiOpenSuccessAlert());
        }
      } else {
        await closeErrorAddicionalInformation();
      }
    } else {
      await closeErrorAddicionalInformation();
    }
  };
};

// ACTION PARA GUARDAR LA TRANSFERENCIA EN BD Y REDUX
export const startUploadTransfer = (
  values: ITransfer,
  edit: boolean,
  fromCentralFile?: boolean
) => {
  return async (dispatch: any, getState: Function) => {
    const closeErrorTransfer = () => {
      if (edit) {
        dispatch(setErrorMsg("ErrorEditTransfer"));
      } else {
        dispatch(setErrorMsg("ErrorToCreateTransfer"));
      }
      dispatch(uiCloseModalAction());
      dispatch(uiOpenErrorAlert());
    };

    const { user } = (getState() as AppState).auth;
    if (user && user.idEntity) {
      if (fromCentralFile) {
        values.submitDate = values.submitDate
          ? values.submitDate
          : firebase.firestore.Timestamp.now();
      }
      const { ok } = await addTransferDb(
        user.idEntity,
        values,
        fromCentralFile
      );
      if (ok) {
        //dispatch(addTransferManagment(values));
        dispatch(
          setSuccessMsg(edit ? "UpdateTransferSuccess" : "CreatedTransfer")
        );
        dispatch(uiCloseModalAction());
        dispatch(uiCloseModalAssignModules());
        dispatch(uiCloseModalEdit());
        dispatch(uiOpenSuccessAlert());
      } else {
        closeErrorTransfer();
      }
    }
  };
};

// INICIAR LISTENER PARA ORGANIZAR TRASNFERENCIAS EN REDUX
export const startListenertTransfer = (idEntity: any) => {
  return async (dispatch: any) => {
    startListenerTransfersByEntity(idEntity, dispatch);
  };
};

// PAYLOADS PARA CARGAR Y AGREGAR AL REDUX LAS TRANSFERENCIAS
export const loadTransferManagment = (transfer: ITransfer[]) => ({
  type: Types.loadTransfers,
  payload: transfer,
});

export const addTransferManagment = (transfer: ITransfer) => ({
  type: Types.addTransfer,
  payload: transfer,
});
export const setActiveAnswer = (active: any) => ({
  type: Types.setActiveAnswer,
  payload: active,
});
export const setActiveTransfer = (active: any): ICorrespondenceActions => ({
  type: Types.setActiveTransfer,
  payload: active,
});
export const startSendTransfer = (transfer: ITransfer) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    if (user && user.idEntity) {
      const transferCopy = { ...transfer };
      transferCopy.isRejected = false;
      transferCopy.rejectComment = null;
      transferCopy.rejectedDate = null;
      transferCopy.submitDate = firebase.firestore.Timestamp.now();
      dispatch(startLoading());
      const { ok } = await addTransferDb(user.idEntity, transferCopy);
      const { ok: okFile } = await addTransferFileModule(
        user.idEntity,
        transferCopy
      );
      if (ok && okFile) {
        dispatch(setActiveTransfer(transfer));

        dispatch(setSuccessMsg("SendedTransfer"));

        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(setErrorMsg("ErrorToSendTransfer"));

        dispatch(uiOpenErrorAlert());
      }
      dispatch(finishLoading());
      dispatch(setActiveTransfer(null));
    }
  };
};

export const setActiveCorresExpedient = (
  active: IExpedientCorrespondence | null
): ICorrespondenceActions => ({
  type: Types.setActiveExpedientDocManagment,
  payload: active,
});

export const startDeleteExpedientCorres = (idExpedient: string) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;
    const { activeCorrespondence: active } = (getState() as AppState)
      .documentManagment;
    const { expedients: expedientsFuid } = (getState() as AppState)
      .documentManagment;
    const { expedients: expedientsFileModule } = (getState() as AppState)
      .fileModule;

    let findExpedientFuid = expedientsFuid.find((el) => el.id === idExpedient);
    let findExpedientFileModule = expedientsFileModule.find(
      (el) => el.id === idExpedient
    );

    const newExpedients = active?.expedients
      ? active.expedients.filter((el) => el.id !== idExpedient)
      : [];
    active!.expedients = newExpedients;

    const { ok } = await updateCorrespondenceDB(active!, idEntity);

    if (ok) {
      if (findExpedientFuid) {
        findExpedientFuid.documents = findExpedientFuid.documents?.filter(
          (el) => el.idCorrespondence !== active?.id
        );

        const { ok: ok1 } = await setEditExpedientDb(
          findExpedientFuid,
          idEntity
        );
        ok1 && dispatch(addNewFuidState(findExpedientFuid!));
      } else if (findExpedientFileModule) {
        findExpedientFileModule.documents =
          findExpedientFileModule.documents?.filter(
            (el) => el.idCorrespondence !== active?.id
          );
        const { ok: ok2 } = await setExpedientFileModule(
          findExpedientFileModule,
          idEntity
        );
        ok2 && dispatch(updateExpedientFileModule(findExpedientFileModule));
      }
      dispatch(updateCorrespondence(active!));
    }
  };
};

//BORRAR UN DEPOSITO DE LA BASE DE DATOS Y DEL REDUX
export const startDeleteDeposit = (idDeposit: string) => {
  return async (dispatch: Function, getState: Function) => {
    const closeErrorFuid = async () => {
      dispatch(setErrorMsg("ErrorDeletedDeposit"));
      dispatch(uiCloseModalAction());
      dispatch(uiOpenErrorAlert());
    };
    const { user } = (getState() as AppState).auth;
    if (user && user.idEntity) {
      const { ok } = await deleteDepositDb(user.idEntity, idDeposit);
      if (ok) {
        dispatch(deleteDeposit(idDeposit));
        dispatch(setSuccessMsg("DeletedDeposit"));
        dispatch(uiCloseModalAction());
        dispatch(uiOpenSuccessAlert());
      } else {
        await closeErrorFuid();
      }
    } else {
      await closeErrorFuid();
    }
  };
};

//EJECUTAR EL DISPTACH PARA ELIMINAR ALGUN  DEPOSITO DEL REDUX
export const deleteDeposit = (idDeposit: string): ICorrespondenceActions => ({
  type: Types.deleteDepositDocumentManagement,
  payload: idDeposit,
});

// CAMBIAR DEPOSTIO A NULL EN EXPEDIENTE
export const changeDepositExpedient = (expedients: IFuid[]) => {
  return async (dispatch: Function, getState: Function) => {
    const closeErrorFuid = async () => {
      dispatch(setErrorMsg("ErrorToEditExpedient"));
      dispatch(uiCloseModalAction());
      dispatch(uiOpenErrorAlert());
    };
    const { user } = (getState() as AppState).auth;

    // RECORRER EL ARRAY EXPEDIENTES PARA CAMBIAR UNO A UNO SU DEPOSITO A NULLL
    expedients.forEach(async (expedient) => {
      if (user && user.idEntity) {
        expedient.deposit = null;
        const { ok } = await addNewFuid(user.idEntity, expedient); // CONTIENE TANTO LA FUNCION PARA AGREGAR CPOM O EL MERGE PARA EDITAR
        if (ok) {
          dispatch(addNewFuidState(expedient)); // MERGE PARA REDUX HAGA EL UPDATE AL EXPEDIENTE
          dispatch(setSuccessMsg("UpdateExpedient"));
          dispatch(uiCloseModalAction());
          dispatch(uiOpenSuccessAlert());
        } else {
          await closeErrorFuid();
        }
      } else {
        await closeErrorFuid();
      }
    });
  };
};

// Eliminar expediente de una transferencia
export const startDeleteFileOfTransfer = (idFile: string, intl: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;
    const { activeTransfer } = (getState() as AppState).documentManagment;

    if (activeTransfer) {
      const newExpedients = activeTransfer.expedients
        ? activeTransfer.expedients.filter((exp) => exp !== idFile)
        : [];
      activeTransfer.expedients = newExpedients;

      const { ok } = await addTransferDb(idEntity, activeTransfer);

      if (ok) {
        dispatch(setActiveTransfer(activeTransfer));
      }
      Swal.fire({
        title: ok
          ? `${intl.formatMessage({ id: "DeletedFuid" })}`
          : `${intl.formatMessage({ id: "ErrorDeletedExpedient" })}`,
        icon: ok ? "success" : "error",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "swal-container-z-index",
        },
      });
    }
  };
};

//BORRAR TRANSFERENCIA DE LA BASE DE DATOS Y DEL REDUX
export const startDeleteTransfer = (
  idTransfer: string,
  isFileModule: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const closeErrorFuid = async () => {
      dispatch(setErrorMsg("ErrorDeletedTransfer"));
      dispatch(uiCloseModalAction());
      dispatch(uiOpenErrorAlert());
    };
    const { user } = (getState() as AppState).auth;
    if (user && user.idEntity) {
      const { ok } = await deleteTransferDb(
        user.idEntity,
        idTransfer,
        isFileModule
      );
      if (ok) {
        isFileModule
          ? dispatch(deleteTransferFileModule(idTransfer))
          : dispatch(deleteTransfer(idTransfer));
        dispatch(setSuccessMsg("TransferDeleted"));
        dispatch(uiCloseModalAction());
        dispatch(uiOpenSuccessAlert());
      } else {
        await closeErrorFuid();
      }
    } else {
      await closeErrorFuid();
    }
  };
};

//EJECUTAR EL DISPTACH PARA ELIMINAR TRANSFERENCIA DEL REDUX DOCUMENT MANAGEMENT
export const deleteTransfer = (idTransfer: string): ICorrespondenceActions => ({
  type: Types.deleteTransferDocumentManagement,
  payload: idTransfer,
});

//EJECUTAR EL DISPTACH PARA ELIMINAR TRANSFERENCIA DEL REDUX FUID
export const deleteTransferFileModule = (
  idTransfer: string
): IFileModuleAction => ({
  type: Types.deleteTransferFuid,
  payload: idTransfer,
});
export const startSetAnnotationTrd = (
  files: ICheckFiles[],
  documentalType: string,
  trdCode: string
) => {
  return async (dispatch: Function, getState: Function) => {
    const { corresAnnotations } = (getState() as AppState).documentManagment;
    const { user } = (getState() as AppState).auth;
    const { activeCorrespondence } = (getState() as AppState).documentManagment;
    //Agrupar los archivos pertenecientes a un mismo id
    if (user && user.idEntity && activeCorrespondence) {
      const results = files.reduce(function (results: any, file) {
        (results[file.annotationId as any] =
          results[file.annotationId as any] || []).push(file);
        return results;
      }, {});

      for (const key in results) {
        if (results.hasOwnProperty(key)) {
          const id = key;
          const files = results[key];

          const annotationFinded = corresAnnotations.find(
            (annotation) => annotation.id === id
          );

          if (annotationFinded) {
            //@ts-ignore
            for (const file of files) {
              if (file.checked) {
                const fileObj: IFilesCorrespondence = {
                  url: file.url,
                  fileName: file.fileName,
                  documentalType: documentalType,
                  trdVersion: trdCode,
                };
                const indexFile = annotationFinded.attachedFiles.findIndex(
                  (fil) => fil.fileName === file.fileName
                );
                if (indexFile >= 0) {
                  annotationFinded.attachedFiles[indexFile] = fileObj;
                }
              }
            }
            const { ok } = await addCorresAnnotationsDb(
              user.idEntity,
              activeCorrespondence.id,
              annotationFinded
            );
            if (!ok) {
              break;
            }
          }
        }
      }
    }
  };
};

/* AGREGAR ARCHIVO PARA FIRMA DIGITAL*/
export const startUploadSignature = (files: any) => {
  return async (dispatch: Function, getState: Function) => {
    const closeErrorSignature = async () => {
      dispatch(setErrorMsg("ErrorToAddSignature"));
      dispatch(uiCloseModalAction());
      dispatch(uiOpenErrorAlert());
    };

    const { user } = (getState() as AppState).auth;
    if (user && user.id && user.idEntity && user.username) {
      let signaturesFiles: IFiles[] = [];
      for (const file of files) {
        if (file.file) {
          const resp = await uploadFileAsync(
            file.file,
            `/signatures/${user.idEntity}/${user.id}_${file.fileName}`
          );
          const obj = { fileName: file.fileName, url: resp };
          signaturesFiles.push(obj);
        }
        file.url !== "#" && signaturesFiles.push(file);
      }

      const signatureObj: ISignature = {
        idUser: user.id.toString(),
        signature: signaturesFiles,
      };
      const { ok } = await addSignatureDb(
        user.id.toString(),
        user.idEntity,
        signatureObj
      );
      if (ok) {
        dispatch(addSignatureRedux(signatureObj));
        dispatch(setSuccessMsg("AddedSignature"));
        dispatch(uiOpenSuccessAlert());
      } else {
        closeErrorSignature();
      }
    } else {
      closeErrorSignature();
    }
  };
};

export const addSignatureRedux = (signature: ISignature | null) => ({
  type: Types.addSignature,
  payload: signature,
});

export const startLoadSignature = (idEntity: string) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    if (user && user.id && user.idEntity) {
      const signature = await loadSignatureDb(user.id.toString(), idEntity);
      dispatch(addSignatureRedux(signature));
    }
  };
};

/* DELETE SIGNATURE */
export const startDeleteSignature = (fileName: string) => {
  return async (dispatch: Function, getState: Function) => {
    const closeErrorSignature = async () => {
      dispatch(setErrorMsg("ErrorToDeleteSignature"));
      dispatch(uiCloseModalAction());
      dispatch(uiOpenErrorAlert());
    };
    const { user } = (getState() as AppState).auth;
    if (user && user.idEntity) {
      const { ok } = await deleteSignatureDb(
        user.idEntity,
        user.id.toString(),
        fileName
      );
      if (ok) {
        dispatch(deleteSignatureRedux(null));
        dispatch(setSuccessMsg("DeletedSignature"));
        dispatch(uiOpenSuccessAlert());
      } else {
        closeErrorSignature();
      }
    }
  };
};

export const deleteSignatureRedux = (signature: null) => ({
  type: Types.deleteSignature,
  payload: signature,
});

export const startSendEmailEp = (data: IFormSendEmail) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    const { users, externalSenders } = (getState() as AppState).users;
    const { productionOffices } = (getState() as AppState).trd;

    const allUsers = users.concat(externalSenders as any);
    const { activeCorrespondence: active } = (getState() as AppState)
      .documentManagment;
    if (user && user.idEntity && user.username && active) {
      const {
        addressee: { id: idAddrsse, email },
        copyTo,
        files,
      } = data;

      const senderFind = getUserFromAllUsers(
        active.externalSender.id.toString(),
        allUsers
      );

      const addresseFind = getUserFromAllUsers(idAddrsse.toString(), allUsers);

      const officeFind = productionOffices.find((office) =>
        senderFind.offices
          ? senderFind.offices[0].includes(office.idProductionOffice)
          : addresseFind.offices[0].includes(office.idProductionOffice)
      );

      if (senderFind && officeFind) {
        const queryResponse = (await getEntityById(
          senderFind.idEntity
        )) as Entity;
        const entity = queryResponse as Entity;
        const emailSender = await getDataFromEntities(senderFind.idEntity);

        const { address } = addresseFind;

        //mandar el email al destinatario principal

        //MANDAR EN EL ADDRESE LOS DATOS DE DIRECCION
        const emailParamsEp: IEmailParamDataEp = {
          addresse: {
            name: addresseFind.username
              ? addresseFind.username
              : addresseFind.name,
            address,
            city: addresseFind.city,
            phone: addresseFind.mobilePhone
              ? addresseFind.mobilePhone
              : addresseFind.phone,
            id: addresseFind.username
              ? addresseFind.id.toString()
              : addresseFind.id,
          },
          createAt: formatDate(active.createAt),
          description: active.description,
          entity: {
            name: entity.businessName,
            address: entity.address,
            phone: entity.phone,
            city: entity.town,
            id: entity.id,
          },
          files,
          sender: {
            name: senderFind.username ? senderFind.username : senderFind.name,
            office: officeFind.name,
          },
          settled: active.id,
          subject: active.subject,
          email: emailSender.emailSender,
        };

        const totalParams: IEmailNotificationParams = {
          type: Enum_Notification_Type.EP,
          data: emailParamsEp,
          email, //enviar correo al destinatario
          emailSender: entity.emailSender,
          entityName: entity.businessName,
        };

        const responseEmail = (await sendEmailNotification(
          totalParams
        )) as unknown as any;

        const { ok: emailSent } = responseEmail;

        if (emailSent) {
          const messageIdEmail = getMessageId(responseEmail);
          const { emailSender: emailSenderEntity } = await getDataFromEntities(
            user.idEntity.toString()
          );
          //recorrer el arr con los correos a los que hay que mandarles la copia del correo
          const emailStatus: IEmailStatus[] = initEmailState();

          //mandar al historial
          const { ok, data: responseData } = await saveSentMailData(
            email,
            copyTo.toString(),
            active.id,
            user.idEntity,
            user.id.toString(),
            user.username,
            data,
            messageIdEmail,
            emailStatus,
            emailSenderEntity
          );

          if (ok) {
            sendCopyEmailEp(
              copyTo,
              emailParamsEp,
              entity,
              responseData,
              active.id
            );
          }
        }
        dispatchEvents(emailSent, dispatch);
      }
    }
  };
};

const sendCopyEmailEp = (
  copyTo: string[],
  emailParamsEp: IEmailParamDataEp,
  entity: Entity,
  responseData: any,
  correspondenceId: string
) => {
  Promise.all(
    copyTo.map(async (email) => {
      const totalParamsCopy: IEmailNotificationParams = {
        type: Enum_Notification_Type.EP,
        data: emailParamsEp,
        email, //enviar correo al destinatario
        emailSender: entity.emailSender,
        isCopy: true,
        emailAnswerParentId: responseData.answerId,
        entityName: entity.businessName,
      };
      const response = (await sendEmailNotification(
        totalParamsCopy
      )) as unknown as any;

      if (response.ok) {
        const messageId =
          response?.data?.body?.Messages[0]?.To[0]?.MessageUUID ?? "";

        const emailCopyStatus: IEmailStatus[] = initEmailState();

        await saveSentMailDataCopy(
          email,
          correspondenceId,
          entity.id.toString(),
          messageId,
          responseData?.answerId ?? "",
          emailCopyStatus
        );
      }
    })
  );
};

const getMessageId = (responseEmail: any) => {
  return responseEmail?.data?.body?.Messages[0]?.To[0]?.MessageUUID ?? "";
};

const saveSentMailDataCopy = async (
  email: string,
  correspondenceId: string,
  entityId: string,
  messageId: string,
  emailAnswerParentId: string,
  emailCopyStatus: IEmailStatus[]
) => {
  const inputDataEmail: IInputDataEmail = {
    email,
    correspondenceId,
    entityId,
    messageId,
    emailAnswerParentId,
    emailCopyStatus,
  };

  await updateEmailCorrespondece(inputDataEmail);
};

const saveSentMailData = async (
  email: string,
  copyTo: string,
  correspondenceId: string,
  entityId: string,
  userId: string,
  username: string,
  data: IFormSendEmail,
  messageId: string,
  status: IEmailStatus[],
  emailSenderEntity: string
) => {
  const answerMsg =
    "Enviado al correo electrónico " +
    email +
    (copyTo === "" ? "" : " con copia a " + copyTo.toString());

  const objAnswer: ICorrespondenceResponse = {
    subject: answerMsg,
    submitDate: firebase.firestore.Timestamp.now(),
    officer: { id: userId, name: username },
    isScreeningAnswer: false,
    isSendEmailResponse: true,
    emailData: data,
    status,
    messageId,
    emailSenderEntity,
  };

  const responseData = await addCorrespondenceResponse(
    entityId,
    correspondenceId,
    objAnswer
  );

  return responseData;
};

export const dispatchEvents = (ok: boolean, dispatch: Function) => {
  if (!ok) {
    dispatch(uiOpenErrorAlert());
    dispatch(setErrorMsg("ErrorSendEmail"));
    return "";
  }
  dispatch(uiCloseModalAction());
  dispatch(setSuccessMsg("EmailSended"));
  dispatch(uiOpenSuccessAlert());
};

import moment from "moment";
import { useIntl } from "react-intl";
import {
  createTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import { useStyles } from "../../../shared/styles/useStyles";

interface Props {
  data: any;
}

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "4px 12px 4px 12px",
      },
    },
  },
});

export const CorrespondenceReportTable = ({ data }: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <ThemeProvider theme={theme}>
      <TableContainer>
        <Table aria-label="caption table">
          <TableHead>
            <TableRow style={{ fontSize: 12 }}>
              <TableCell className={classes.tableBorder} align="left">
                # {`${intl.formatMessage({ id: "Settled" }).toUpperCase()} `}
              </TableCell>
              <TableCell className={classes.tableBorder} align="left">
                T.C
              </TableCell>
              <TableCell className={classes.tableBorder} align="left">
                {`${intl.formatMessage({ id: "Subject" }).toUpperCase()} `}
              </TableCell>
              <TableCell className={classes.tableBorder} align="left">
                {`${intl.formatMessage({ id: "Sender" }).toUpperCase()} `}
              </TableCell>
              <TableCell className={classes.tableBorder} align="left">
                {`${intl.formatMessage({ id: "Addressee" }).toUpperCase()} `}
              </TableCell>
              <TableCell className={classes.tableBorder} align="left">
                {`${intl.formatMessage({ id: "Date" }).toUpperCase()} `}
              </TableCell>
              <TableCell className={classes.tableBorder} align="left">
                {`${intl.formatMessage({ id: "Filed" }).toUpperCase()} `}
              </TableCell>
              <TableCell className={classes.tableBorder} align="left">
                {`${intl.formatMessage({ id: "Signature" }).toUpperCase()} `}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: any) => (
              <TableRow key={row.id}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableBorder}
                  width={"5%"}
                >
                  {row.id}
                </TableCell>
                <TableCell
                  className={classes.tableBorder}
                  align="left"
                  width={"10%"}
                >
                  {row.communicationType.responseTime === 0 ||
                  !row.communicationType.responseTime
                    ? `${intl.formatMessage({
                        id: `${row.communicationType.option}`,
                      })}`
                    : `${row.communicationType.option}`}
                </TableCell>
                <TableCell
                  className={classes.tableBorder}
                  align="left"
                  width={"30%"}
                >
                  {row.subject}
                </TableCell>
                <TableCell
                  className={classes.tableBorder}
                  align="left"
                  width={"10%"}
                >
                  {row.externalSender.name}
                </TableCell>
                <TableCell
                  className={classes.tableBorder}
                  align="left"
                  width={"10%"}
                >
                  {row.addressee.name}
                </TableCell>
                <TableCell
                  className={classes.tableBorder}
                  align="left"
                  width={"5%"}
                >
                  {moment(row.createAt.toDate()).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell
                  className={classes.tableBorder}
                  align="left"
                  width={"5%"}
                >
                  {row.owner.name}
                </TableCell>
                <TableCell
                  width={"20%"}
                  className={classes.tableBorder}
                  align="left"
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

import { Redirect, Route, Switch } from "react-router";
import { TRDTab } from "../components/admin/TRD/trd/TRDTab";
import { ModulesType } from "../enums/enums";
import { ReportsScreen } from "../pages/correspondenceModule/ReportsScreen";
import { AnnotationsManagmentScreen } from "../pages/documentManagmentModule/AnnotationsManagmentScreen";
import { CopiesScreen } from "../pages/documentManagmentModule/CopiesScreen";
import { CorrespondenceManagmentScreen } from "../pages/documentManagmentModule/CorrespondenceManagmentScreen";
import { EntitiesControlScreen } from "../pages/documentManagmentModule/EntitiesControlScreen";
import { FuidManagmentScreen } from "../pages/documentManagmentModule/FuidManagmentScreen";
import { OthersScreen } from "../pages/documentManagmentModule/OthersScreen";
import { ParameterizationManagmentScreen } from "../pages/documentManagmentModule/ParameterizationManagmentScreen";
import { PqrsManagmentScreen } from "../pages/documentManagmentModule/PqrsManagmentScreen";
import { ProceduresManagmentScreen } from "../pages/documentManagmentModule/ProceduresManagment";
//import { ReportsManagmentScreen } from "../pages/documentManagmentModule/ReportsManagmentScreen";
import { ScreeningScreen } from "../pages/documentManagmentModule/ScreeningScreen";
import { TransferManagmentScreen } from "../pages/documentManagmentModule/TransferManagmentScreen";
import { VoBoScreen } from "../pages/documentManagmentModule/VoBoScreen";

export const DocumentManagmentRouter = () => {
  return (
    <div>
      <Switch>
        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/reports`}
          component={ReportsScreen}
        />
        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/correspondence`}
          component={CorrespondenceManagmentScreen}
        />
        {/* SUBRUTAS CORRESPONDENCIA */}

        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/procedures`}
          component={ProceduresManagmentScreen}
        />
        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/entities-control`}
          component={EntitiesControlScreen}
        />
        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/others`}
          component={OthersScreen}
        />
        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/copies`}
          component={CopiesScreen}
        />
        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/vobo`}
          component={VoBoScreen}
        />

        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/screening`}
          component={ScreeningScreen}
        />
        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/pqrs`}
          component={PqrsManagmentScreen}
        />
        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/annotations`}
          component={AnnotationsManagmentScreen}
        />

        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/annotationsSend`}
          component={AnnotationsManagmentScreen}
        />

        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/annotationsReceived`}
          component={AnnotationsManagmentScreen}
        />

        <Route
          exact
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/fuid`}
          component={FuidManagmentScreen}
        />
        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/fuid/copies`}
        >
          <FuidManagmentScreen fromCopies />
        </Route>
        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/transfers`}
          exact
          component={TransferManagmentScreen}
        />
        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/transfers/sended`}
          exact
          component={TransferManagmentScreen}
        />

        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/transfers/reject`}
          exact
        >
          <TransferManagmentScreen fromRejected />
        </Route>
        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/trd`}
          component={TRDTab}
        />
        <Route
          path={`/${ModulesType.DocumentManagment.toLowerCase()}/parameterization`}
          component={ParameterizationManagmentScreen}
        />
        {/* --------------- DEMAS RUTAS -------------- */}
        <Redirect
          to={`/${ModulesType.DocumentManagment.toLowerCase()}/reports`}
        />
      </Switch>
    </div>
  );
};

import { db } from "../../config/firebase/firebase-config";
import { Office } from '../../interfaces/OrganizationChart';
import { loadProductionOffices } from "./trd";

// Agregar Oficina
export const addOffice = async (
  office: Office,
  idEntity: string,
  idProductionOffice: string
) => {

  await db
    .collection("Entities")
    .doc(idEntity)
    .collection("ProductionOffices")
    .doc(idProductionOffice)
    .collection("Offices")
    .doc(`${office.idOffice}`)
    .set({ ...office }, { merge: true });

};

// Cargar Oficinas de la oficina productoras
export const loadOffices = async (idEntity: string) => {
  const docRef = db
    .collection(`Entities`)
    .doc(idEntity)
    .collection("ProductionOffices");
  const offices = await loadProductionOffices(idEntity);

  // hacer consulta interna de cada oficina Data
  if (offices.length > 0) {
    const officesARR: any = [];
    for (let values of offices) {
      let officeSnap = await docRef.doc(values.id).collection("Offices").get();
      officeSnap.forEach((snapChild) => {
        officesARR.push({
          id: snapChild.id,
          ...snapChild.data(),
        });
      });
    }
    return officesARR;
  }

  return null;
};

// Editar Oficina en colección Offices
export const editOffice = async (office: Office, idEntity: string) => {
  await db
    .collection("Entities")
    .doc(`${idEntity}`)
    .collection("ProductionOffices")
    .doc(`${office.idProductionOffice}`)
    .collection("Offices")
    .doc(`${office.idOffice}`)
    .set(office, { merge: true });
};

// Borrar Oficina
export const deleteOfficeQuery = async (office: Office, idEntity: string) => {
  return db
    .collection("Entities")
    .doc(`${idEntity}`)
    .collection("ProductionOffices")
    .doc(`${office.idProductionOffice}`)
    .collection("Offices")
    .doc(`${office.idOffice}`)
    .delete();
};

import clsx from "clsx";
import memoize from "fast-memoize";
import { Form, Formik, useFormikContext } from "formik";
import { useState, useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";

import { MyTextField } from "../../custom/MyTextField";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  uiCloseErrorAlert,
  uiCloseModalAction,
} from "../../../redux/actions/uiActions";
import { useStyles } from "../../../shared/styles/useStyles";
import { Autocomplete } from "@material-ui/lab";
import { IUserCorrespondence } from "../../../interfaces/Correspondence";

import { User } from "../../../interfaces/Users";
import { startReassingSettled } from "../../../redux/actions/documentManagmentActions";
import { MyAlert } from "../../custom/MyAlert";
import { TypeArchive } from "../../../enums/enums";
import { startReassingSettledFuid } from "../../../redux/actions/fileModuleActions";
interface Props {
  fromModule?: TypeArchive;
  filesModule?: boolean;
}
export const ReassignForm = ({ fromModule, filesModule = false }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.documentManagment
  );

  const { productionOffices } = useSelector((state: AppState) => state.trd);
  const { users } = useSelector((state: AppState) => state.users);
  const [addresseeSelected, setAddresseeSelected] =
    useState<IUserCorrespondence | null>(null);
  const [errorAddressee, setErrorAddressee] = useState(false);
  const { errorAlert, errorMsg, successAlert, successMsg } = useSelector(
    (state: AppState) => state.ui
  );

  const initialValues = {
    receivingDependency: activeCorrespondence
      ? activeCorrespondence.receivingDependency
      : "",
    addressee: { id: "", name: "" },
  };

  const [dependency, setDependency] = useState(
    initialValues.receivingDependency
  );
  const [usersDependency, setUsersDependency] = useState<IUserCorrespondence[]>(
    []
  );

  const validationSchema = yup.object({
    receivingDependency: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    addressee: yup.object(),
  });

  const onClose = () => {
    dispatch(uiCloseModalAction());
  };

  const handleSelectAddressees = (value: any) => {
    !value ? setErrorAddressee(true) : setErrorAddressee(false);
    value ? setAddresseeSelected(value) : setAddresseeSelected(null);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUsers = useCallback(
    memoize((dependency: string) => {
      const filteredUsers: User[] = (users as User[]).filter((el) =>
        el.offices.toString().includes(dependency)
      );
      const arrayUsers: IUserCorrespondence[] = [];
      filteredUsers.forEach((user: User) => {
        arrayUsers.push({
          id: user.id.toString(),
          name:
            user.firstName +
            " " +
            user.secondName +
            " " +
            user.firstLastName +
            " " +
            user.secondLastName,
        });
      });

      return arrayUsers;
    }),
    []
  );

  const FormObserver: React.FC = () => {
    const { values } = useFormikContext<any>();

    useEffect(() => {
      if (usersDependency.length === 0) {
        setUsersDependency(getUsers(values.receivingDependency));
      }

      if (values.receivingDependency !== dependency) {
        setDependency(values.receivingDependency);
        setAddresseeSelected(null);
        setUsersDependency(getUsers(values.receivingDependency));
      }
    }, [values]);
    return null;
  };

  return (
    <Box m={2} mt={-1} mb={2}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (!addresseeSelected) {
            setErrorAddressee(true);
            return;
          }

          if (!errorAddressee) {
            values.addressee = addresseeSelected as IUserCorrespondence;
            setSubmitting(true);
            // CONDICIONAL PARA VER DE QUE MODULO SE VIENE Y ASÍ REALIZAR LA REASIGNACIÓN
            if (!activeCorrespondence) {
              await dispatch(
                startReassingSettledFuid(values, fromModule ? true : false)
              );
            } else {
              await dispatch(startReassingSettled(values));
            }
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values }) => (
          <Form className={classes.input}>
            <FormObserver></FormObserver>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="SelectDependency" />:
                </label>
                <MyTextField
                  name="receivingDependency"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                >
                  {productionOffices.map((productionOffice) => (
                    <MenuItem
                      key={productionOffice.idProductionOffice}
                      value={productionOffice.idProductionOffice}
                    >
                      {productionOffice.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="SelectOfficerReassigned" />:
                </label>
                <Autocomplete
                  id="addressee"
                  value={addresseeSelected}
                  options={usersDependency}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderOption={(option) => <>{option.name}</>}
                  onChange={(event: any, value: IUserCorrespondence | null) =>
                    handleSelectAddressees(value)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      name="addressee"
                      className={classes.myTextFieldRoot}
                      helperText={
                        errorAddressee &&
                        `${intl.formatMessage({ id: "RequiredFile" })}`
                      }
                      error={errorAddressee}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                    />
                  )}
                />
              </Grid>

              <Box mt={2} display="flex" flexDirection="row-reverse" width={1}>
                {!isSubmitting ? (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id="Save" />
                  </Button>
                ) : (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="button"
                    disabled={true}
                  >
                    <CircularProgress className={classes.btnLoading} />
                  </Button>
                )}
                <Button
                  className={clsx(classes.btn, classes.cancel)}
                  onClick={onClose}
                >
                  <FormattedMessage id="Cancel" />
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>

      {successMsg && (
        <MyAlert
          open={successAlert}
          typeAlert="error"
          message={successMsg}
          time={2000}
          handleClose={() => dispatch(uiCloseErrorAlert())}
        />
      )}

      {errorMsg && (
        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={errorMsg}
          time={2000}
          handleClose={() => dispatch(uiCloseErrorAlert())}
        />
      )}
    </Box>
  );
};

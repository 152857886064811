import { firebase } from "../config/firebase/firebase-config";
import { IUserCorrespondence } from "./Correspondence";
export enum Enum_LoanState {
  Returned = "Returned",
  WithoutReturning = "WithoutReturning",
}
export interface ILoan {
  id: string;

  idExpedient: string;

  description: string;
  
  phone: string;

  dependency: string;

  officer: IUserCorrespondence;

  createAt: firebase.firestore.Timestamp;

  returnDate: firebase.firestore.Timestamp;

  state: Enum_LoanState;

  isDigitalLoan: boolean;
}

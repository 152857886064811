import { useIntl } from "react-intl";
import { IconButton, Tooltip } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import HomeWorkOutlinedIcon from "@material-ui/icons/HomeWorkOutlined";
import PrintRoundedIcon from "@material-ui/icons/PrintRounded";
import FormatListBulletedRoundedIcon from "@material-ui/icons/FormatListBulletedRounded";
import SendIcon from "@material-ui/icons/Send";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import GetAppIcon from "@material-ui/icons/GetApp";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import EmailIcon from "@material-ui/icons/Email";
import Warning from "../../assets/images/warning.svg";

interface Icons {
  seeIcon?: true;
  addFilesIcon?: true;
  returnIcon?: true;
  editIcon?: true;
  downloadTransferFormatIcon?: true;
  deleteIcon?: true;
  assignModulesIcon?: true;
  assignOfficeIcon?: true;
  attachIcon?: true;
  printIcon?: true;
  proponentsIcon?: true;
  listIcon?: true;
  sendIcon?: true;
  approveTransferIcon?: true;
  rejectIcon?: true;
  commentsIcon?: true;
  isTransfer?: boolean;
  historyIcon?: true;
  emailIcon?: true;
  alertAttachments?: boolean;
  alertEmail?: boolean;
  onSee?: () => void;
  onAddFiles?: () => void;
  onReturn?: () => void;
  onEdit?: () => void;
  onDownloadTransferFormat?: () => void;
  onDelete?: () => void;
  onAssignModules?: () => void;
  onAssignOffice?: () => void;
  onAttach?: () => void;
  onPrint?: () => void;
  onAddProponents?: () => void;
  onApproveTransfer?: () => void;
  onList?: () => void;
  onSend?: () => void;
  onReject?: () => void;
  onComments?: () => void;
  onHistory?: () => void;
  onEmailSent?: () => void;
}

export const CustomizedIcons = (props: Icons) => {
  const {
    seeIcon,
    addFilesIcon,
    returnIcon,
    editIcon,
    approveTransferIcon,
    downloadTransferFormatIcon,
    deleteIcon,
    assignModulesIcon,
    assignOfficeIcon,
    attachIcon,
    printIcon,
    proponentsIcon,
    listIcon,
    sendIcon,
    isTransfer = false,
    rejectIcon,
    commentsIcon,
    historyIcon,
    emailIcon,
    alertAttachments,
    alertEmail,
    onSee,
    onReturn,
    onAddFiles,
    onEdit,
    onDownloadTransferFormat,
    onDelete,
    onAssignModules,
    onAssignOffice,
    onAttach,
    onPrint,
    onAddProponents,
    onList,
    onSend,
    onApproveTransfer,
    onReject,
    onComments,
    onHistory,
    onEmailSent,
  } = props;
  const intl = useIntl();

  return (
    <>
      {alertEmail && (
        <Tooltip title={`${intl.formatMessage({ id: "Emails" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onComments}>
            <img src={Warning} alt="warning" height="18px" width="18px" />
          </IconButton>
        </Tooltip>
      )}
      {alertAttachments && (
        <Tooltip title={`${intl.formatMessage({ id: "Attachments" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onComments}>
            <img src={Warning} alt="warning" height="18px" width="18px" />
          </IconButton>
        </Tooltip>
      )}
      {commentsIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "Comment" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onComments}>
            {" "}
            <AnnouncementIcon />{" "}
          </IconButton>
        </Tooltip>
      )}
      {seeIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "See" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onSee}>
            {" "}
            <VisibilityOutlinedIcon />{" "}
          </IconButton>
        </Tooltip>
      )}
      {addFilesIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "AddFiles" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onAddFiles}>
            {" "}
            <FormatListBulletedIcon />{" "}
          </IconButton>
        </Tooltip>
      )}
      {returnIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "Return" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onReturn}>
            {" "}
            <CheckCircleRoundedIcon />{" "}
          </IconButton>
        </Tooltip>
      )}
      {editIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "Edit" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onEdit}>
            {" "}
            <EditOutlinedIcon />{" "}
          </IconButton>
        </Tooltip>
      )}
      {printIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "Print" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onPrint}>
            <PrintRoundedIcon />
          </IconButton>
        </Tooltip>
      )}
      {proponentsIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "AddProponents" })}`}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={onAddProponents}
          >
            {" "}
            <FormatListBulletedRoundedIcon />{" "}
          </IconButton>
        </Tooltip>
      )}
      {approveTransferIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "ApproveTransfer" })}`}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={onApproveTransfer}
          >
            <CheckCircleIcon />
          </IconButton>
        </Tooltip>
      )}
      {rejectIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "Reject" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onReject}>
            <CancelIcon />
          </IconButton>
        </Tooltip>
      )}
      {downloadTransferFormatIcon && (
        <Tooltip
          title={`${intl.formatMessage({ id: "DownloadTransferFormat" })}`}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={onDownloadTransferFormat}
          >
            <GetAppIcon />
          </IconButton>
        </Tooltip>
      )}
      {deleteIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "Delete" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onDelete}>
            {" "}
            <DeleteOutlineOutlinedIcon />{" "}
          </IconButton>
        </Tooltip>
      )}
      {assignModulesIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "AssignModules" })}`}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={onAssignModules}
          >
            {" "}
            <PersonAddOutlinedIcon />{" "}
          </IconButton>
        </Tooltip>
      )}
      {assignOfficeIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "AssignOffice" })}`}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={onAssignOffice}
          >
            <HomeWorkOutlinedIcon />
          </IconButton>
        </Tooltip>
      )}
      {attachIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "Attach" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onAttach}>
            <AttachFileIcon />
          </IconButton>
        </Tooltip>
      )}
      {listIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "List" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onList}>
            <FormatListNumberedIcon />
          </IconButton>
        </Tooltip>
      )}
      {sendIcon && (
        <Tooltip
          title={
            !isTransfer
              ? `${intl.formatMessage({ id: "SendFinalAnswer" })}`
              : `${intl.formatMessage({ id: "SendTransfer" })}`
          }
        >
          <IconButton aria-label="expand row" size="small" onClick={onSend}>
            <SendIcon />
          </IconButton>
        </Tooltip>
      )}
      {historyIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "History" })}`}>
          <IconButton aria-label="expand row" size="small" onClick={onHistory}>
            <DoneAllIcon></DoneAllIcon>
          </IconButton>
        </Tooltip>
      )}
      {emailIcon && (
        <Tooltip title={`${intl.formatMessage({ id: "EmailSent" })}`}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={onEmailSent}
          >
            <EmailIcon></EmailIcon>
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

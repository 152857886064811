import {
  Box,
  createTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { counter } from "../../../helpers/count";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { FormattedMessage } from "react-intl";
import { Colors } from "../../../shared/constants/themes/Colors";
import { CustomizedTableFooter } from "../../custom/CustomizedTableFooter";
import { IDocumentsFuid } from "../../../redux/types/types";
import { DocumentsFuidBody } from "./DocumentsFuidBody";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import {
  setErrorMsg,
  uiOpenErrorAlert,
} from "../../../redux/actions/uiActions";
import { FC } from "react";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});
interface Props {
  documents: IDocumentsFuid[];
  readOnly?: boolean;
  isCopy?: boolean;
}

export const DocumentsFuidTab: FC<Props> = ({
  documents,
  readOnly = false,
  isCopy = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, value } = useSelector((state: AppState) => state.search);

  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );

  const { documentsDownload } = useSelector(
    (state: AppState) => state.fileModule
  );

  const count = counter(value, documents, data);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, documents.length - page * rowsPerPage);

  const downloadDocuments = () => {
    if (documentsDownload.length > 0) {
      documentsDownload.forEach((item) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = (event) => {
          const blob = xhr.response;
          const downloadUrl = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = downloadUrl;
          link.download = item.fileName!;
          document.body.appendChild(link);
          link.click();
          link.remove();
        };
        xhr.open("GET", item.url);
        xhr.send();
      });
    } else {
      dispatch(setErrorMsg("NotDocumentsExpedients"));
      dispatch(uiOpenErrorAlert());
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper} style={{ paddingTop: "10px" }}>
        {documents.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            <FormattedMessage id={"NoCreatedDocuments"} />
          </Box>
        ) : (
          <Box display={"flex"} flexDirection={"column"}>
            {!readOnly && (
              <Box alignSelf={"flex-end"} m={1} mr={2}>
                <Button
                  className={clsx(classes.btn, classes.cancel)}
                  style={{ width: "auto" }}
                  onClick={downloadDocuments}
                  autoFocus
                  type="button"
                >
                  <GetAppRoundedIcon />
                  <FormattedMessage id="DownloadDocuments" />
                </Button>
              </Box>
            )}
            <Table
              className={classes.table}
              size="small"
              style={{ tableLayout: "auto" }}
              aria-label="a dense table"
            >
              <TableHead className={classes.titleTable}>
                <TableRow>
                  <TableCell
                    width={"15%"}
                    align="center"
                    className={classes.titleTable}
                  >
                    <FormattedMessage id="DocumentName" />{" "}
                  </TableCell>
                  <TableCell align="center" className={classes.titleTable}>
                    <FormattedMessage id="DocumentType" />{" "}
                  </TableCell>
                  <TableCell align="center" className={classes.titleTable}>
                    <FormattedMessage id="User" />{" "}
                  </TableCell>
                  <TableCell align="center" className={classes.titleTable}>
                    <FormattedMessage id="Date" />{" "}
                  </TableCell>
                  {!readOnly && (
                    <TableCell
                      width={"20%"}
                      className={classes.titleTable}
                      align="center"
                    >
                      <FormattedMessage id="Actions" />{" "}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {!value.trim()
                  ? (rowsPerPage > 0
                      ? documents.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : documents
                    ).map((document, index) => (
                      <DocumentsFuidBody
                        readonly={readOnly}
                        key={index}
                        documentFuid={document}
                        isCopy={isCopy}
                      />
                    ))
                  : data &&
                    (rowsPerPage > 0
                      ? data.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : data
                    ).map((document, index) => (
                      <DocumentsFuidBody
                        readonly={readOnly}
                        key={index}
                        documentFuid={document}
                        isCopy={isCopy}
                      />
                    ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 13 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {/* --------- Footer para paginación -------- */}
              <CustomizedTableFooter count={count} />
            </Table>
          </Box>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { Delete, FileCopy } from "@material-ui/icons";
import { FormattedMessage, useIntl } from "react-intl";
import { IFiles } from "../../../redux/types/types";
import { useStyles } from "../../../shared/styles/useStyles";
import clsx from "clsx";
import { MyTextField } from "../../custom/MyTextField";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { uiCloseModalAction } from "../../../redux/actions/uiActions";
import { ICorrespondenceResponse } from "../../../interfaces/Correspondence";
import {
  setActiveAnswer,
  startDeleteFileAnswer,
  startIssueResponseCorrespondence,
} from "../../../redux/actions/documentManagmentActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { ActionSettled, Enum_FileSize } from "../../../enums/enums";
import { getCorrespondenceIScreeningAnswer } from "../../../services/firebase/correspondence";
interface Props {
  edit: boolean;
}
export const IssuanceResponseForm: FC<Props> = ({ edit }) => {
  const [labelImage, setLabelImage] = useState("");
  const { activeCorrespondence, activeAnswer: active } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const [fileNames, setFileNames] = useState<IFiles[]>(
    active && active.attachedDocuments ? active.attachedDocuments : []
  );
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state: AppState) => state.auth);

  useEffect(() => {
    const fetchAnswers = async () => {
      if (user && user.idEntity && activeCorrespondence) {
        setLoading(true);

        const response = await getCorrespondenceIScreeningAnswer(
          user.idEntity,
          activeCorrespondence.id
        );

        if (response.length > 0) {
          dispatch(setActiveAnswer(response[0]));
          setFileNames(
            response && response[0].attachedDocuments
              ? response[0].attachedDocuments
              : []
          );
        }

        setLoading(false);
      }
    };
    fetchAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [noValid, setNoValid] = useState(false);
  const [noValidSize, setNoValidSize] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();
  const ActionSettledArr = [
    ActionSettled.APPROVED,
    ActionSettled.ANNOTATION,
    ActionSettled.ASSIGN_TO_FILE,
    ActionSettled.ISSUANCE_OF_RESPONSE,
    ActionSettled.ISSUANCE_VO_BO,
    ActionSettled.REQUEST_ADDITIONAL_INFORMATION,
    ActionSettled.REQUEST_FOR_PROJECTION,
    ActionSettled.VO_BO_APPROVED,
    ActionSettled.VO_BO_REJECTED,
  ];
  const initialValues: Partial<ICorrespondenceResponse> = {
    subject: active
      ? ActionSettledArr.includes(active.subject as any)
        ? intl.formatMessage({ id: active.subject })
        : active.subject
      : "",
    description: active && active.description ? active.description : "",
    attachedDocuments: null,
  };

  const validationSchema = yup.object({
    subject: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    description: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  const handleSelectFile = (e: any) => {
    const SUPPORTED_FORMATS = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/png",
      "application/msword",
      "text/csv",
      "application/pdf",
      "application/zip",
      "application/x-zip-compressed",
      "application/vnd.rar",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.oasis.opendocument.text",
      "zip",
      "rar",
      "odt",
      "pptx",
      "ppt"
    ];
    const file = e.target.files[0] as File;
    const now = new Date().getTime().toString();
    if (file) {
      if (file.size > Enum_FileSize.maxAllowedSize) {
        setNoValidSize(true);
      } else {

        let fileType = file.type;

        if(fileType.length === 0){
          const dataFileName = file.name.split(".");
          fileType = dataFileName.length > 0 ? dataFileName[dataFileName.length - 1]: "";
        }
        if (SUPPORTED_FORMATS.includes(fileType)) {
          setNoValid(false);

          setFileNames((prevState) => [
            { url: "#", fileName: now + "_" + file.name, file },
            ...prevState,
          ]);
          setLabelImage(e.target.files[0].name);
        } else {
          setNoValid(true);
        }
      }
    } else {
      setNoValidSize(false)
      setNoValid(false);
      setLabelImage("");
    }
  };
  const onClose = () => {
    dispatch(uiCloseModalAction());
    dispatch(setActiveAnswer(null));
  };
  const handleDeleteFile = async (fileName: string, db: boolean) => {
    if (active && active.idAnswer) {
      if (db) {
        setIsLoading(true);
        await dispatch(startDeleteFileAnswer(active.idAnswer, fileName));
        setIsLoading(false);
      }
      setFileNames((prevState) =>
        prevState.filter((fil) => fil.fileName !== fileName)
      );
    }
  };

  // ARCHIVOS SOPORTADOS PARA INPUT TYPE=FILE
  const fileSupported = {
    accept:
      `
        application/pdf, 
        image/jpeg, 
        image/png, 
        application/msword, 
        application/vnd.openxmlformats-officedocument.wordprocessingml.document, 
        application/vnd.ms-excel, 
        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
        text/csv,
        application/zip,
        application/x-zip-compressed,
        application/vnd.rar,
        application/vnd.ms-powerpoint,
        application/vnd.openxmlformats-officedocument.presentationml.presentation,
        application/vnd.oasis.opendocument.text
      `,
  };

  if (loading) {
    return (
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Formik
      validateOnChange={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        if (!noValid && activeCorrespondence) {
          values.attachedDocuments = fileNames;
          values.idAnswer = active ? active.idAnswer : undefined;
          values.submitDate = active ? active.submitDate : undefined;
          await dispatch(
            startIssueResponseCorrespondence(
              values as ICorrespondenceResponse,
              activeCorrespondence.id,
              edit
            )
          );
        }

        setSubmitting(false);
      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label className="form-text">
                <FormattedMessage id="CorrespondenceRespondeAnswer" />
              </label>
            </Grid>

            <Grid item xs={12}>
              <label className="form-text">
                <FormattedMessage id="Subject" />*
              </label>
              <MyTextField
                name="subject"
                variant="outlined"
                className={classes.myTextFieldRoot}
              />
            </Grid>

            <Grid item xs={12}>
              <label className="form-text">
                <FormattedMessage id="Description" />*
              </label>
              <MyTextField
                name="description"
                variant="outlined"
                className={classes.myTextFieldRoot}
                multiline
                rows={8}
              />
            </Grid>

            <Grid item xs={6}>
              <label className="form-text">
                <FormattedMessage id="AttachDocument" />
              </label>
              <TextField
                size="small"
                type="file"
                inputProps={fileSupported}
                onChange={handleSelectFile}
                id="icon-button-file"
                autoComplete="off"
                style={{ display: "none" }}
              />

              <MyTextField
                disabled={true}
                variant="outlined"
                style={{ color: "black" }}
                value={labelImage}
                name="file"
                className={classes.myTextFieldRoot}
              />
            </Grid>

            <Grid item xs={6}>
              <Box mt={3} ml={-2}>
                <Tooltip
                  title={`${intl.formatMessage({ id: "SearchDocument" })}`}
                >
                  <label htmlFor="icon-button-file">
                    <IconButton component="span">
                      <FileCopy />
                    </IconButton>
                  </label>
                </Tooltip>
              </Box>
            </Grid>
            {noValid && (
              <Grid item xs={12}>
                <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                  <FormattedMessage id="ValidDocumentalFiles" />
                </Box>
              </Grid>
            )}
            {noValidSize && (
              <Grid item xs={12}>
                <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                  <FormattedMessage id="ValidDocumentalFilesSize" />
                </Box>
              </Grid>
            )}
          </Grid>
          {fileNames.map(({ fileName, url }) => {
            return (
              <Grid container key={fileName}>
                <Grid item xs={6} key={fileName}>
                  <Box style={{ fontSize: 12, color: "blue" }}>
                    <a href={url} target="__blank">
                      {fileName}
                    </a>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip title={`${intl.formatMessage({ id: "Delete" })}`}>
                    <IconButton
                      onClick={() => handleDeleteFile(fileName, url !== "#")}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            );
          })}

          <Box mt={2} display="flex" flexDirection="row-reverse" width={1}>
            {!isSubmitting && !isLoading ? (
              <Button
                className={clsx(classes.btn, classes.save)}
                autoFocus
                type="submit"
                disabled={isSubmitting}
              >
                <FormattedMessage id="Save" />
              </Button>
            ) : (
              <Button
                className={clsx(classes.btn, classes.save)}
                autoFocus
                type="button"
                disabled={true}
              >
                <CircularProgress className={classes.btnLoading} />
              </Button>
            )}
            <Button
              className={clsx(classes.btn, classes.cancel)}
              onClick={onClose}
            >
              <FormattedMessage id="Cancel" />
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

import { Box } from "@material-ui/core";
import { ParameterizationTabs } from "../../components/correspondence-module/parameterization/ParameterizationTabs";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";

export const ParameterizationScreen = () => {
  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box>
        <ParameterizationTabs />
      </Box>
    </AppAnimate>
  );
};

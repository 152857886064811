import moment from "moment";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { TableCell, TableRow } from "@material-ui/core";

import { CustomizedIcons } from "../../../components/custom/CustomizedIcons";
import {
  setTitleMsg,
  setQuestionMsg,
  uiOpenModalDelete,
} from "../../../redux/actions/uiActions";
import { ICorresAnnotations } from "../../../redux/types/types";
import { setActiveFuidAnnotation } from "../../../redux/actions/fileModuleActions";
import { useStyles } from "../../../shared/styles/useStyles";

interface props {
  annotation: ICorresAnnotations;
  readOnly?: boolean;
}

export const AnnotationsFuidBody: FC<props> = ({
  annotation,
  readOnly = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onDeleteAnnotation = async () => {
    await dispatch(setActiveFuidAnnotation(annotation));
    dispatch(setTitleMsg("DeleteAnnotation"));
    dispatch(setQuestionMsg("DeleteAnnotationQuestion"));
    dispatch(uiOpenModalDelete());
  };

  return (
    <TableRow key={annotation.id}>
      {annotation.attachedFiles && annotation.attachedFiles.length > 0 ? (
        <TableCell
          component="th"
          scope="row"
          align="center"
          style={{ cursor: "pointer" }}
        >
          {annotation.attachedFiles.map((doc) => (
            <a key={doc.fileName} href={doc.url} target="__blank">
              {doc.fileName}
            </a>
          ))}
        </TableCell>
      ) : (
        <TableCell component="th" scope="row" align="center">
          <FormattedMessage id="WithoutDocument" />
        </TableCell>
      )}
      <TableCell align="center" className={classes.capitalize}>
        {annotation.description}
      </TableCell>
      <TableCell align="center" style={{ textTransform: "lowercase" }}>
        {annotation.user.name}
      </TableCell>
      <TableCell align="center" style={{ textTransform: "lowercase" }}>
        {moment(annotation.createDate.toDate()).format("YYYY/MM/DD")}
      </TableCell>
      {!readOnly && (
        <TableCell align="center">
          <CustomizedIcons deleteIcon onDelete={onDeleteAnnotation} />
        </TableCell>
      )}
    </TableRow>
  );
};

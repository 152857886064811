import { TableCell, TableRow } from '@material-ui/core'
import moment from 'moment'
import React, { FC } from 'react'
import { IRecipients } from '../../../redux/types/types'
import { useStyles } from '../../../shared/styles/useStyles'
interface props{
    recipient:IRecipients
}
export const RecipientsBody:FC<props> = ({recipient}) => {
    const classes = useStyles()
    return (
        <TableRow >
        <TableCell component="th" scope="row" align="center">
          {recipient.recipient}
        </TableCell>
        <TableCell align="center" className={classes.capitalize}>
        {moment(recipient.submittedDate.toDate()).format("YYYY/MM/DD")}

        </TableCell>
        <TableCell align="center">
          {recipient.readedDate ? moment(recipient.readedDate.toDate()).format("YYYY/MM/DD"):"" }
        </TableCell>
        <TableCell align="center">
        {recipient.confirmationDate ? moment(recipient.confirmationDate.toDate()).format("YYYY/MM/DD"):"" }
        
        </TableCell>
      </TableRow>
    )
}

import { useDispatch, useSelector } from "react-redux";

import { TableCell, TableRow } from "@material-ui/core";
import { CustomizedIcons } from "../../../custom/CustomizedIcons";
import { uiOpenModalDelete, uiOpenModalEdit } from "../../../../redux/actions/uiActions";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { setActiveSubserie } from '../../../../redux/actions/trdActions';
import { FC } from "react";
// import { useStyles } from "../../../../shared/styles/useStyles";
import { ITrdSubSerie } from '../../../../redux/types/types';

interface props{
  subserie: ITrdSubSerie
  readonly?: boolean
}

export const SubseriesBody:  FC<props> = ({
  subserie,
  readonly = false
}) => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const { series, productionOffices } = useSelector(
    (state: AppState) => state.trd
  );

  const filterSerie= series.filter( (serie) => serie.idSerie === subserie.idSerie );
  const serieChoose:any = filterSerie[0];

  const filterProductionOffice = productionOffices.filter( (productionOffice) => productionOffice.idProductionOffice === subserie.idProductionOffice );
  const productionOfficeChoose:any = filterProductionOffice[0];

  const onEditSubSerie = () => {
    dispatch(setActiveSubserie(subserie));
    dispatch(uiOpenModalEdit());
  };

  const onDeleteSubserie = () => {
    dispatch(uiOpenModalDelete());
    dispatch(setActiveSubserie(subserie));
  };

  return (
    <TableRow key={subserie.subSerieCode}>
      <TableCell component="th" scope="row" align="center">
        {productionOfficeChoose.code}
      </TableCell>
      <TableCell align="center">{productionOfficeChoose.name}</TableCell>
      <TableCell align="center">{serieChoose.serieCode}</TableCell>
      <TableCell align="center">{serieChoose.seriesName}</TableCell>
      <TableCell align="center">{subserie.subSerieCode}</TableCell>
      <TableCell align="center">{subserie.name}</TableCell>
      {!readonly &&
      <TableCell align="center">
        <CustomizedIcons
          editIcon
          deleteIcon
          onEdit={onEditSubSerie}
          onDelete={onDeleteSubserie}
        />
      </TableCell>
      }
    </TableRow>
  );
};

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  IFiles,
  IFuid,
  IDocumentsFuid,
  IDocsExpedientsSend,
} from "../../../redux/types/types";
import { useStyles } from "../../../shared/styles/useStyles";
import clsx from "clsx";
import { FileCopy } from "@material-ui/icons";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { MyTextField } from "../../custom/MyTextField";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { uiCloseModalAction } from "../../../redux/actions/uiActions";
import {
  IAppendDocs,
  ICorrespondence,
} from "../../../interfaces/Correspondence";
import { FC } from "react";
import { addAttachedDocumentCorrespondence } from "../../../redux/actions/documentManagmentActions";
import { IAppendDocsSend } from "../../../redux/types/types";
import { firebase } from "../../../config/firebase/firebase-config";
import { Enum_FileSize } from "../../../enums/enums";
interface props {
  correspondence?: ICorrespondence;
  expedient?: IFuid;
  fromModule?: boolean;
}

export const UploadDocumentForm: FC<props> = ({
  correspondence,
  expedient,
  fromModule = false,
}) => {
  const [fileNames, setFileNames] = useState<IFiles>({
    fileName: "",
    url: "#",
  });
  const [noValid, setNoValid] = useState(false);
  const [noValidSize, setNoValidSize] = useState(false);
  const [selected, setSelected] = useState({
    productionOffice: "",
    series: "",
    subseries: "",
    documentalType: "",
  });
  const { user } = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();

  const intl = useIntl();
  const classes = useStyles();
  const {
    series: allSeries,
    productionOffices,
    subSeries: allSubseries,
    documentalType: allDocumentalType,
    versions,
  } = useSelector((state: AppState) => state.trd);
  const [series, setSeries] = useState(allSeries);
  const [subSeries, setSubSeries] = useState(allSubseries);
  const [documentalType, setDocumentalType] = useState(allDocumentalType);

  const handleSelectFile = (e: any) => {
    const SUPPORTED_FORMATS = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/png",
      "application/msword",
      "text/csv",
      "application/pdf",
      "application/zip",
      "application/x-zip-compressed",
      "application/vnd.rar",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.oasis.opendocument.text",
      "zip",
      "rar",
      "odt",
      "pptx",
      "ppt",
    ];
    const file = e.target.files[0] as File;
    const now = new Date().getTime().toString();
    if (file) {
      if (file.size > Enum_FileSize.maxAllowedSize) {
        setNoValidSize(true);
      } else {
        setNoValidSize(false);

        let fileType = file.type;

        if (fileType.length === 0) {
          const dataFileName = file.name.split(".");
          fileType =
            dataFileName.length > 0
              ? dataFileName[dataFileName.length - 1]
              : "";
        }

        if (SUPPORTED_FORMATS.includes(fileType)) {
          setNoValid(false);

          setFileNames({ url: "#", fileName: now + "_" + file.name, file });
        } else {
          setNoValid(true);
        }
      }
    } else {
      setNoValid(false);
      setNoValidSize(false);
    }
  };
  let now = new Date();
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
  const valueDate = now.toISOString().slice(0, 16);
  const initialValues = {
    attachedDocument: null,
    description: "",
    documentalType: "",
    uploadDate: valueDate,
  };

  const validationSchema = yup.object({
    description: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    uploadDate: yup
      .date()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });
  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected({ ...selected, [event.target.name]: event.target.value });
  };
  const onClose = () => {
    dispatch(uiCloseModalAction());
  };

  useEffect(() => {
    //FILTRAR LOS SELECTS
    const seriesFiltered = allSeries.filter(
      (ser) => ser.idProductionOffice === selected.productionOffice
    );
    const subSeriesFiltered = allSubseries.filter(
      (subSer) => subSer.idSerie === selected.series
    );
    const documentalTypeFilter = allDocumentalType.filter(
      (docType) =>
        docType.idProductionOffice === selected.productionOffice &&
        docType.idSerie === selected.series &&
        docType.idSubSerie === selected.subseries
    );
    setSeries(seriesFiltered);
    setSubSeries(subSeriesFiltered);
    setDocumentalType(documentalTypeFilter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  // ARCHIVOS SOPORTADOS PARA INPUT TYPE=FILE
  const fileSupported = {
    accept:
      `
        application/pdf, 
        image/jpeg, 
        image/png, 
        application/msword, 
        application/vnd.openxmlformats-officedocument.wordprocessingml.document, 
        application/vnd.ms-excel, 
        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
        text/csv,
        application/zip,
        application/x-zip-compressed,
        application/vnd.rar,
        application/vnd.ms-powerpoint,
        application/vnd.openxmlformats-officedocument.presentationml.presentation,
        application/vnd.oasis.opendocument.text,
      `
  };

  return (
    <Formik
      validateOnChange={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        const trdVersion = versions.find((trd) =>
          trd.documentalType?.includes(selected.documentalType)
        );

        if (fileNames.file && user && user.username) {
          if (correspondence || expedient) {
            const lastAppendDocs = correspondence
              ? correspondence.appendDocs
              : expedient && expedient.documents;
            let objAppenDoc: IAppendDocs | IDocumentsFuid;
            let finalAppendDocs: IAppendDocs[] | IDocumentsFuid[];
            let objsAppendDocs: IAppendDocsSend | IDocsExpedientsSend;

            const file = fileNames.file;

            if (correspondence) {
              objAppenDoc = {
                url: fileNames.url,
                filename: fileNames.fileName,
                description: values.description,
                documentalType:
                  selected.documentalType === ""
                    ? null
                    : selected.documentalType,
                officer: {
                  id: user.id.toString(),
                  name: user.username,
                },
                trdCode: trdVersion ? trdVersion.code : null,
                uploadDate: firebase.firestore.Timestamp.fromDate(
                  new Date(values.uploadDate)
                ),
              };

              finalAppendDocs = lastAppendDocs
                ? [...lastAppendDocs, objAppenDoc]
                : [objAppenDoc];

              objsAppendDocs = {
                idCorrespondence: correspondence.id,
                appendDocs: finalAppendDocs as IAppendDocs[],
              };
              // VERIFICAR SI HAY EXPEDIENTES EN LA CORRESPONDENCIA
              if (
                correspondence &&
                correspondence.expedients &&
                correspondence.expedients.length >= 0
              ) {
                const isExpedient = true;
                const idExpedientCorrespondence =
                  correspondence.expedients[0].id;
                await dispatch(
                  addAttachedDocumentCorrespondence(
                    objsAppendDocs,
                    file,
                    false,
                    false,
                    isExpedient,
                    idExpedientCorrespondence
                  )
                );
              } else {
                await dispatch(
                  addAttachedDocumentCorrespondence(objsAppendDocs, file)
                );
              }
            } else if (expedient) {
              objAppenDoc = {
                url: fileNames.url,
                fileName: fileNames.fileName,
                description: values.description,
                documentalType:
                  selected.documentalType === ""
                    ? null
                    : selected.documentalType,
                officer: {
                  id: user.id.toString(),
                  name: user.username,
                },
                trdCode: trdVersion ? trdVersion.code : null,
                uploadDate: firebase.firestore.Timestamp.fromDate(
                  new Date(values.uploadDate)
                ),
              };

              finalAppendDocs = lastAppendDocs
                ? [...lastAppendDocs, objAppenDoc]
                : [objAppenDoc];

              objsAppendDocs = {
                idExpedient: expedient.id,
                appendDocs: finalAppendDocs as IDocumentsFuid[],
              };

              await dispatch(
                addAttachedDocumentCorrespondence(
                  objsAppendDocs,
                  file,
                  !!expedient,
                  fromModule
                )
              );
            }
          }
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ marginBottom: 12 }}>
              <Box display="flex" justifyContent="space-between">
                <Box width={"35%"} mt={1}>
                  <label className="form-text">
                    <FormattedMessage id="AttachFile" />:
                  </label>
                </Box>

                <Box width={"45%"}>
                  <TextField
                    size="small"
                    type="file"
                    inputProps={fileSupported}
                    onChange={handleSelectFile}
                    id="icon-button-file"
                    autoComplete="off"
                    style={{ display: "none" }}
                  />

                  <TextField
                    size="small"
                    disabled={true}
                    variant="outlined"
                    style={{ color: "black" }}
                    name="file"
                    value={fileNames.fileName}
                    className={classes.myTextFieldRoot}
                  />
                </Box>

                <Box mt={1} width={"20%"}>
                  <Tooltip title={`${intl.formatMessage({ id: "Attach" })}`}>
                    <label htmlFor="icon-button-file">
                      <Button
                        className={clsx(classes.btnAction, classes.save)}
                        component="span"
                      >
                        <FileCopy /> <FormattedMessage id="Attach" />
                      </Button>
                    </label>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
            {noValid && (
              <Grid item xs={12}>
                <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                  <FormattedMessage id="ValidDocumentalFiles" />
                </Box>
              </Grid>
            )}
            {noValidSize && (
              <Grid item xs={12}>
                <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                  <FormattedMessage id="ValidDocumentalFilesSize" />
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <label className="form-text">
                <FormattedMessage id="ContentDescription" />:
              </label>
              <MyTextField
                name="description"
                variant="outlined"
                className={classes.myTextFieldRoot}
                multiline
                rows={3}
                //disabled={details}
              />
            </Grid>
            <Grid item xs={12}>
              <label className="form-text">
                <FormattedMessage id="Date" />:
              </label>
              <Box width={"50%"}>
                <MyTextField
                  name="uploadDate"
                  variant="outlined"
                  type="datetime-local"
                  className={classes.myTextFieldRoot}
                />
              </Box>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 12 }}>
              <label className="form-text">
                <FormattedMessage id="ClassificationAcordingTRD" />
              </label>
            </Grid>

            <Grid item xs={6}>
              <FormattedMessage id="ProductionOffice" />:
              <Box width={"100%"}>
                <TextField
                  name="productionOffice"
                  variant="outlined"
                  select
                  size="small"
                  value={selected.productionOffice}
                  className={classes.myTextFieldRoot}
                  onChange={handleSelect}
                  //   disabled={ active ? true : false}
                >
                  {productionOffices.map((productionOffice) => (
                    <MenuItem
                      key={productionOffice.idProductionOffice}
                      value={productionOffice.idProductionOffice}
                    >
                      {productionOffice.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <FormattedMessage id="Series" />:
              <Box width={"100%"}>
                <TextField
                  name="series"
                  variant="outlined"
                  select
                  size="small"
                  value={selected.series}
                  className={classes.myTextFieldRoot}
                  onChange={handleSelect}
                  //   disabled={ active ? true : false}
                >
                  {series.map((serie) => (
                    <MenuItem key={serie.idSerie} value={serie.idSerie}>
                      {serie.seriesName}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <FormattedMessage id="Subseries" />:
              <Box width={"100%"}>
                <TextField
                  name="subseries"
                  variant="outlined"
                  select
                  size="small"
                  value={selected.subseries}
                  className={classes.myTextFieldRoot}
                  onChange={handleSelect}
                  //   disabled={ active ? true : false}
                >
                  {subSeries.map((subSer) => (
                    <MenuItem key={subSer.idSubserie} value={subSer.idSubserie}>
                      {subSer.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <FormattedMessage id="DocumentaryType" />:
              <Box width={"100%"}>
                <TextField
                  name="documentalType"
                  variant="outlined"
                  select
                  size="small"
                  value={selected.documentalType}
                  className={classes.myTextFieldRoot}
                  onChange={handleSelect}
                  //   disabled={ active ? true : false}
                >
                  {documentalType.map((doc) => (
                    <MenuItem
                      key={doc.idDocumentalType}
                      value={doc.idDocumentalType}
                    >
                      {doc.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>

            <Box mt={2} display="flex" flexDirection="row-reverse" width={1}>
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="Save" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

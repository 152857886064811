import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { Link, Redirect, useParams } from "react-router-dom";
import { useStyles } from "../../shared/styles/useStyles";
import { getSettledStatus } from "../../services/firebase/correspondence";
import { ICorrespondence } from "../../interfaces/Correspondence";
import moment from "moment";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { FormattedMessage } from "react-intl";
interface params {
  idSettled: string;
}
export const ProcedureStatus = () => {
  const classes = useStyles();

  const { idSettled } = useParams() as params;
  const [settledStatus, setSettledStatus] = useState<ICorrespondence | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchDetailsSettled = async (idSettled: string) => {
      setIsLoading(true);
      const resp = await getSettledStatus(idSettled);
      if (resp) {
        const parsePendingDate = resp.pendingDate
          ? new Date(resp.pendingDate._seconds * 1000)
          : null;
        const parseCreateAtDate = resp.createAt
          ? new Date(resp.createAt._seconds * 1000)
          : null;
        const parseAnsweredDate = resp.answeredDate
          ? new Date(resp.answeredDate._seconds * 1000)
          : null;

        resp.answeredDate = parseAnsweredDate;
        resp.createAt = parseCreateAtDate;
        resp.pendingDate = parsePendingDate;
        setSettledStatus(resp);
      }
      setIsLoading(false);
    };
    if (idSettled) {
      fetchDetailsSettled(idSettled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!idSettled) {
    return <Redirect to="/" />;
  }

  return (
    <Box className={classes.modulesContainer}>
      <Link to={"/"} style={{ position: "absolute", top: 50, left: 70 }}>
        <IconButton aria-label="toggle logout">
          <ArrowBack fontSize="large" style={{ color: "#fff" }} />
        </IconButton>
      </Link>
      {isLoading ? (
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          <CircularProgress className={classes.btnLoading} />
        </div>
      ) : settledStatus ? (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            style={{
              backgroundColor: "#fff",
              borderRadius: 47,
              padding: "1.7rem",
            }}
          >
            <Typography
              variant="h5"
              style={{ marginBottom: "3vh", fontWeight: "bold" }}
            >
              <FormattedMessage id="Settled" /> No. {idSettled}
            </Typography>
            <Stepper
              orientation="vertical"
              style={{
                backgroundColor: "transparent",
                padding: 0,
                margin: 0,
              }}
            >
              <Step active>
                <StepLabel>
                  <FormattedMessage id="SettledDate" />
                </StepLabel>
                <StepContent>
                  <Typography variant="h5" style={{ fontSize: "1.2em" }}>
                    {moment(settledStatus.createAt).format(
                      "YYYY/MM/DD h:mm:ss a"
                    )}
                  </Typography>
                </StepContent>
              </Step>

              <Step active={!!settledStatus.pendingDate}>
                <StepLabel>
                  <FormattedMessage id="PendingDate" />
                </StepLabel>
                <StepContent>
                  <Typography variant="h5" style={{ fontSize: "1.2em" }}>
                    {settledStatus.pendingDate ? (
                      moment(settledStatus.pendingDate).format(
                        "YYYY/MM/DD h:mm:ss a"
                      )
                    ) : (
                      <FormattedMessage id="InProcess" />
                    )}
                  </Typography>
                </StepContent>
              </Step>
              <Step active={!!settledStatus.answeredDate}>
                <StepLabel>
                  <FormattedMessage id="AnsweredDate" />
                </StepLabel>
                <StepContent>
                  <Typography variant="h5" style={{ fontSize: "1.2em" }}>
                    {settledStatus.answeredDate ? (
                      moment(settledStatus.answeredDate).format(
                        "YYYY/MM/DD h:mm:ss a"
                      )
                    ) : (
                      <FormattedMessage id="InProcess" />
                    )}
                  </Typography>
                </StepContent>
              </Step>
            </Stepper>
          </Grid>
        </Grid>
      ) : (
        <Typography variant="h5">No hay ningun registro.</Typography>
      )}
    </Box>
  );
};

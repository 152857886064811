import { IFuid, ISendCopyExpedient } from "./../types/types";
import {
  addMilestoneDb as addMilestoneExpedient,
  addNewDepositDb,
  deleteDepositDb,
  loadDepositsDb,
  setSendExpedientCopy,
} from "../../services/firebase/centralArchive";
import { addMilestoneExpedientManagment } from "../../services/firebase/correspondence";
import { ICentralArchiveAction, IDeposits, Types } from "../types/types";
import {
  setErrorMsg,
  setSuccessMsg,
  uiCloseModalAction,
  uiCloseModalSee,
  uiOpenErrorAlert,
  uiOpenSuccessAlert,
} from "./uiActions";
import { AppState } from "../reducers/rootReducer";
import { addNewFuid } from "../../services/firebase/correspondence";
import { addNewFuidState } from "./documentManagmentActions";
import { IUserCorrespondence } from "../../interfaces/Correspondence";
import firebase from "firebase";
import { setActiveFuid } from "./fuidActions";
import { setActiveFuidFileModule } from "./fileModuleActions";
import Swal from "sweetalert2";


export const startLoadDepositsFiles = (
  idEntity: string,
  idProductionOf: string
) => {
  return async (dispatch: Function) => {
    const deposits = await loadDepositsDb(idEntity, idProductionOf);
    dispatch(loadDeposits(deposits));
  };
};
const loadDeposits = (deposits: IDeposits[]): ICentralArchiveAction => ({
  type: Types.loadDepositsFiles,
  payload: deposits,
});

export const startAddNewDeposits = (idEntity: string, deposit: IDeposits) => {
  return async (dispatch: Function) => {
    const { ok } = await addNewDepositDb(idEntity, deposit);
    if (ok) {
      dispatch(setSuccessMsg("DepositSuccess"));
      dispatch(uiOpenSuccessAlert());
      dispatch(addNewDepositRedux(deposit));
    } else {
      dispatch(setErrorMsg("ErrorAddDeposit"));
      dispatch(uiOpenErrorAlert());
    }
  };
};

const addNewDepositRedux = (deposit: IDeposits): ICentralArchiveAction => ({
  type: Types.addDepositFiles,
  payload: deposit,
});

//BORRAR UN DEPOSITO DE LA BASE DE DATOS Y DEL REDUX
export const startDeleteDeposit = (idDeposit: string) => {
  return async (dispatch: Function, getState: Function) => {
    const closeErrorFuid = async () => {
      dispatch(setErrorMsg("ErrorDeletedDeposit"));
      dispatch(uiCloseModalAction());
      dispatch(uiOpenErrorAlert());
    };
    const { user } = (getState() as AppState).auth;
    if (user && user.idEntity) {
      const { ok } = await deleteDepositDb(user.idEntity, idDeposit);
      if (ok) {
        dispatch(deleteDeposit(idDeposit));
        dispatch(setSuccessMsg("DeletedDeposit"));
        dispatch(uiCloseModalAction());
        dispatch(uiOpenSuccessAlert());
      } else {
        await closeErrorFuid();
      }
    } else {
      await closeErrorFuid();
    }
  };
};

//EJECUTAR EL DISPTACH PARA ELIMINAR ALGUN  DEPOSITO DEL REDUX
export const deleteDeposit = (idDeposit: string): ICentralArchiveAction => ({
  type: Types.deleteDepositFiles,
  payload: idDeposit,
});

// CAMBIAR DEPOSTIO A NULL EN EXPEDIENTE
export const changeDepositExpedient = (expedients: IFuid[]) => {
  return async (dispatch: Function, getState: Function) => {
    const closeErrorFuid = async () => {
      dispatch(setErrorMsg("ErrorToEditExpedient"));
      dispatch(uiCloseModalAction());
      dispatch(uiOpenErrorAlert());
    };
    const { user } = (getState() as AppState).auth;

    // RECORRER EL ARRAY EXPEDIENTES PARA CAMBIAR UNO A UNO SU DEPOSITO A NULLL
    expedients.forEach(async (expedient) => {
      if (user && user.idEntity) {
        expedient.deposit = null;
        const { ok } = await addNewFuid(user.idEntity, expedient, true); // CONTIENE TANTO LA FUNCION PARA AGREGAR CPOM O EL MERGE PARA EDITAR
        if (ok) {
          dispatch(addNewFuidState(expedient)); // MERGE PARA REDUX HAGA EL UPDATE AL EXPEDIENTE
          dispatch(setSuccessMsg("UpdateExpedient"));
          dispatch(uiCloseModalAction());
          dispatch(uiOpenSuccessAlert());
        } else {
          await closeErrorFuid();
        }
      } else {
        await closeErrorFuid();
      }
    });
  };
};

// AGREGAR COPIA A EXPEDIENTE
export const startsendExpedientCopy = (
  copy: ISendCopyExpedient,
  currentUserSend: IUserCorrespondence,
  fromFileModule: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;
    const { ok } = await setSendExpedientCopy(
      idEntity,
      copy.idExpedient,
      copy.users,
      fromFileModule
    );
    if (ok) {
      //AGREGAR AL MILESTONE
      await addMilestoneExpedient(
        idEntity,
        copy.idExpedient,
        firebase.firestore.Timestamp.now(),
        `Envío de copia a ${currentUserSend.name}`,
        parseInt(currentUserSend.id),
        currentUserSend.name,
        fromFileModule
      );
      dispatch(sendCopyFiles(copy));
      dispatch(uiCloseModalSee());
      dispatch(uiCloseModalAction());
      dispatch(uiOpenSuccessAlert());
      dispatch(setSuccessMsg("CopySend"));
    } else {
      dispatch(uiOpenErrorAlert());
      dispatch(setErrorMsg("ErrorCopySend"));
    }
  };
};
//AGREGAR AL REDUX
const sendCopyFiles = (copy: ISendCopyExpedient): ICentralArchiveAction => ({
  type: Types.sendCopyFiles,
  payload: copy,
});
export const startDeleteFuidCopy = (idUser: string,userName:string, intl: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { activeExpedient: activeManagment } = (getState() as AppState).fuid;
    const { activeExpedientFileModule } = (getState() as AppState).fileModule;
    const { user } = (getState() as AppState).auth;

    if (user && user.idEntity) {
      //HACER CAMBIOS EN EL FUID DE GESTION DOCUMENTAL
      const copyActiveManag = { ...activeManagment };
      copyActiveManag.copyTo = copyActiveManag.copyTo?.filter(
        (el) => el.id !== idUser
      );
      copyActiveManag.copyToIds = copyActiveManag.copyToIds?.filter(
        (el) => el !== idUser
      );
      //CAMBIOS EN ARCHIVO CENTRAL
      const copyActiveCentralFile = { ...activeExpedientFileModule };
      copyActiveCentralFile.copyTo = copyActiveCentralFile.copyTo?.filter(
        (el) => el.id !== idUser
      );
      copyActiveCentralFile.copyToIds = copyActiveCentralFile.copyToIds?.filter(
        (el) => el !== idUser
      );
      const { ok } =
        activeManagment && copyActiveManag
          ? await addNewFuid(user.idEntity, copyActiveManag as any, false)
          : await addNewFuid(user.idEntity, copyActiveCentralFile as any, true);
      if (ok) {
        activeManagment && copyActiveManag?
        
        await addMilestoneExpedientManagment(
          user.idEntity,
          activeManagment.id,
          firebase.firestore.Timestamp.now(),
          `Copia enviada a ${userName} eliminada por ${user.username}`,
          user.id,
          user.username
        )
        :
        await addMilestoneExpedient(
          user.idEntity,
          activeExpedientFileModule ?activeExpedientFileModule.id:"",
          firebase.firestore.Timestamp.now(),
          `Copia enviada a ${userName} eliminada por ${user.username}`,
          user.id,
          user.username,
          !!activeExpedientFileModule

        )
        


        activeManagment && copyActiveManag
          ? dispatch(setActiveFuid(copyActiveManag as any))
          : dispatch(setActiveFuidFileModule(copyActiveCentralFile as any));
      }

      Swal.fire({
        title: ok
          ? `${intl.formatMessage({ id: "CopyDeleted" })}`
          : `${intl.formatMessage({ id: "ErrorCopyDelete" })}`,
        icon: ok ? "success" : "error",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "swal-container-z-index",
        },
      });
    }
  };
};

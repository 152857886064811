import React from "react";
import { Box } from "@material-ui/core";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import { ParameterizationFilesTabs } from "../../components/file-module/parameterization/ParameterizationFilesTabs";

export const ParameterizationFilesScreen = () => {
  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box>
        <ParameterizationFilesTabs />
      </Box>
    </AppAnimate>
  );
};

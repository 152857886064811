import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import FindReplaceOutlinedIcon from "@material-ui/icons/FindReplaceOutlined";
import AssignmentIndRoundedIcon from "@material-ui/icons/AssignmentIndRounded";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import RestoreOutlinedIcon from "@material-ui/icons/RestoreOutlined";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import AlarmAddIcon from "@material-ui/icons/AlarmAdd";
import EmailIcon from "@material-ui/icons/Email";

import {
  CorrespondenceInputType,
  CommunicationChannels,
  ActionSettled,
} from "../../../enums/enums";
import { useStyles } from "../../../shared/styles/useStyles";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Colors } from "../../../shared/constants/themes/Colors";
import {
  uiCloseModalAction,
  uiOpenModalAction,
  uiCloseModalDelete,
  uiOpenModalInfo,
  uiCloseModalInfo,
  uiCloseErrorAlert,
} from "../../../redux/actions/uiActions";
import CustomizedDialog from "../../custom/CustomizedDialog";
import { SettleDetailsTabs } from "./SettleDetailsTabs";
import {
  setActiveCorrespondenceDocManagment,
  setTitleAction,
  updateCorrespondence,
} from "../../../redux/actions/documentManagmentActions";
import { ReclassifyForm } from "./ReclassifyForm";
import { ReassignForm } from "./ReassignForm";
import { SendCopyForm } from "./SendCopyForm";
import { ReturnForm } from "./ReturnForm";
import { ApprovedForm } from "./ApprovedForm";
import { RequestProjectionForm } from "./RequestProjectionForm";
import { ClassifyTrdForm } from "./ClassifyTrdForm";
import { AssignFileForm } from "./AssignFileForm";
import { CreateAnnotationForm } from "./CreateAnnotationForm";
import { UploadDocumentForm } from "./UploadDocumentForm";
import { RequestAdditionalInformationForm } from "./RequestAdditionalInformationForm";
import { IssuanceResponseForm } from "./IssuanceResponseForm";
import { InfoModal } from "../../infoModal/InfoModal";
import { MyAlert } from "../../custom/MyAlert";
import {
  uiCloseSuccessAlert,
  setSuccessMsg,
  setErrorMsg,
} from "../../../redux/actions/uiActions";
import { FC } from "react";
import { IssuanceVoBoForm } from "./IssuanceVoBoForm";
import { ConfirmDelete } from "../../ui/shared/ConfirmDelete";
import { itWasRead } from "../../../services/firebase/documentManagment";
import {
  setActiveCorresAnnotation,
  setActiveCorresExpedient,
} from "../../../redux/actions/documentManagmentActions";
import { setActiveCorrespondence } from "../../../redux/actions/correspondenceActions";
import { setActiveAnswer } from "../../../redux/actions/documentManagmentActions";
import { ExtensionRequestForm } from "./ExtensionRequestForm";
import { SendEmail } from "./SendEmail";
interface props {
  component?: string;
  readonly?: boolean;
}

export const SettledDetails: FC<props> = ({
  component = "OTHER",
  readonly = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const intl = useIntl();
  const { activeCorrespondence, titleAction } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { users, externalSenders } = useSelector(
    (state: AppState) => state.users
  );

  const allUsers = users.concat(externalSenders as any);

  const { user } = useSelector((state: AppState) => state.auth);
  const { productionOffices } = useSelector((state: AppState) => state.trd);
  const {
    modalActionOpen,
    modalInfoOpen,
    modalDeleteOpen,
    successAlert,
    successMsg,
    errorAlert,
    errorMsg,
    questionMsg,
    titleMsg,
  } = useSelector((state: AppState) => state.ui);
  const [content, setContent] = useState(<p></p>);
  const [idUserInfo, setIdUserInfo] = useState("");

  useEffect(() => {
    dispatch(uiCloseModalAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const channel =
    activeCorrespondence?.communicationChannel === CommunicationChannels.EMAIL
      ? intl.formatMessage({
          id: "EmailAddress",
        })
      : activeCorrespondence?.communicationChannel ===
        CommunicationChannels.FACEBOOK
      ? intl.formatMessage({
          id: "Facebook",
        })
      : activeCorrespondence?.communicationChannel ===
        CommunicationChannels.WHATSAPP
      ? intl.formatMessage({
          id: "Whatsapp",
        })
      : activeCorrespondence?.communicationChannel ===
        CommunicationChannels.CALL
      ? intl.formatMessage({
          id: "ByTelephone",
        })
      : activeCorrespondence?.communicationChannel ===
        CommunicationChannels.INSTITUTIONAL_PAGE
      ? intl.formatMessage({
          id: "InstitutionalPage",
        })
      : activeCorrespondence?.communicationChannel === CommunicationChannels.FAX
      ? intl.formatMessage({
          id: "Fax",
        })
      : intl.formatMessage({
          id: "SingleWindow",
        });

  const getOfficeName = (idProductionOffice: string) => {
    const findOffice = productionOffices.find(
      (el) => el.idProductionOffice === idProductionOffice
    );
    if (findOffice) {
      return findOffice.name;
    }
    return "";
  };
  const getUserInfo = (idUser: string) => {
    const userFind = allUsers.find(
      (user) =>
        user.id.toString() === idUser ||
        user.idDoc === idUser ||
        user.uid === idUser
    );
    return userFind;
  };

  const form = {
    subject: activeCorrespondence ? activeCorrespondence?.subject : "",
    description: activeCorrespondence ? activeCorrespondence?.description : "",
    inputType: activeCorrespondence ? activeCorrespondence?.inputType : "",
    communicationType:
      activeCorrespondence && activeCorrespondence.communicationType
        ? intl.formatMessage({
            id: `${activeCorrespondence.communicationType.option}`,
          })
        : "",
    communicationChannel:
      activeCorrespondence && activeCorrespondence.emailChannel
        ? `${channel} - ${activeCorrespondence.emailChannel}`
        : channel,
    producerDependency:
      activeCorrespondence &&
      (activeCorrespondence.inputType === CorrespondenceInputType.ER ||
        activeCorrespondence.inputType === CorrespondenceInputType.OR)
        ? `${intl.formatMessage({ id: "None" })}`
        : activeCorrespondence &&
          activeCorrespondence.producerDependency &&
          getOfficeName(activeCorrespondence.producerDependency),
    receivingDependency:
      activeCorrespondence && activeCorrespondence.receivingDependency
        ? getOfficeName(activeCorrespondence.receivingDependency)
        : "",
    sender: activeCorrespondence
      ? getUserInfo(activeCorrespondence.externalSender.id).username
        ? getUserInfo(activeCorrespondence.externalSender.id).username
        : getUserInfo(activeCorrespondence.externalSender.id).name
      : "",

    addressee: activeCorrespondence ? activeCorrespondence.addressee.name : "",
    communicationPages: activeCorrespondence
      ? activeCorrespondence.communicationPages
      : "",
    annexesPages: activeCorrespondence ? activeCorrespondence.annexesPages : "",
    copyPages: activeCorrespondence ? activeCorrespondence.copyPages : "",
    postalAgency: activeCorrespondence ? activeCorrespondence.postalAgency : "",
    guide: activeCorrespondence ? activeCorrespondence.guide : "",
  };

  const handleOpenAction = (action: string) => {
    setContent(<></>);
    dispatch(setTitleAction(action));

    action === ActionSettled.RECLASSIFY && setContent(<ReclassifyForm />);
    action === ActionSettled.REASSIGN && setContent(<ReassignForm />);
    action === ActionSettled.SEND_COPY && setContent(<SendCopyForm />);
    action === ActionSettled.EXTENSION_REQUEST &&
      setContent(<ExtensionRequestForm />);
    if (activeCorrespondence) {
      action === ActionSettled.RETURN &&
        setContent(<ReturnForm correspondence={activeCorrespondence} />);
    }
    action === ActionSettled.APPROVED && setContent(<ApprovedForm />);
    action === ActionSettled.REQUEST_FOR_PROJECTION &&
      setContent(<RequestProjectionForm />);
    action === ActionSettled.CLASSIFY_WITH_TRD &&
      setContent(<ClassifyTrdForm />);
    if (activeCorrespondence) {
      action === ActionSettled.ASSIGN_TO_FILE &&
        setContent(<AssignFileForm correspondence={activeCorrespondence} />);
    }
    action === ActionSettled.ANNOTATION && setContent(<CreateAnnotationForm />);
    if (activeCorrespondence) {
      action === ActionSettled.UPLOAD_DOCUMENT &&
        setContent(
          <UploadDocumentForm correspondence={activeCorrespondence} />
        );
    }
    action === ActionSettled.REQUEST_ADDITIONAL_INFORMATION &&
      setContent(<RequestAdditionalInformationForm />);
    action === ActionSettled.ISSUANCE_OF_RESPONSE &&
      setContent(<IssuanceResponseForm edit={false} />);
    action === ActionSettled.ISSUANCE_VO_BO && setContent(<IssuanceVoBoForm />);

    action === ActionSettled.SEND_EMAIL && setContent(<SendEmail />);

    dispatch(uiOpenModalAction());
  };

  const onDeny = () => {
    dispatch(uiCloseModalAction());
    dispatch(uiCloseModalInfo());
    dispatch(uiCloseModalDelete());
    dispatch(setActiveCorresAnnotation(null));
    dispatch(setActiveCorresExpedient(null));
    dispatch(setActiveCorrespondence(null));
    dispatch(setActiveAnswer(null));
  };

  const closeErrorAndSuccessAlert = () => {
    dispatch(uiCloseErrorAlert());
    dispatch(uiCloseSuccessAlert());
    dispatch(setSuccessMsg(null));
    dispatch(setErrorMsg(null));
  };

  const readAction = async () => {
    if (component === "VoBo") {
      activeCorrespondence!.isReadedVobo = false;
    } else {
      activeCorrespondence!.isReaded = false;
      activeCorrespondence!.readedDate = null;
    }
    try {
      await itWasRead(activeCorrespondence!, user!);
      dispatch(setActiveCorrespondenceDocManagment(activeCorrespondence));
      dispatch(updateCorrespondence(activeCorrespondence!));
    } catch (error: any) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    const documentReadedVobo = async () => {
      if (component === "VoBo" && !activeCorrespondence!.isReadedVobo) {
        activeCorrespondence!.isReadedVobo = true;
        try {
          await itWasRead(activeCorrespondence!, user!);
          dispatch(updateCorrespondence(activeCorrespondence!));
        } catch (error: any) {
          throw new Error(error);
        }
      } else if (
        component === "PROJECTION" &&
        !activeCorrespondence!.isReadedVobo
      ) {
        activeCorrespondence!.isReadedProjection = true;
        try {
          await itWasRead(activeCorrespondence!, user!);
          dispatch(updateCorrespondence(activeCorrespondence!));
        } catch (error: any) {
          throw new Error(error);
        }
      }
    };
    documentReadedVobo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModalInfoUser = (idUser: string) => {
    setIdUserInfo(idUser);
    dispatch(uiOpenModalInfo());
  };

  const ReclassifyIcon = () => {
    return (
      <Tooltip
        title={`${intl.formatMessage({
          id: "Reclassify",
        })}`}
      >
        <IconButton onClick={() => handleOpenAction(ActionSettled.RECLASSIFY)}>
          <FindReplaceOutlinedIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    );
  };

  const ClassifyWithTRDIcon = () => {
    return (
      <Tooltip
        title={`${intl.formatMessage({
          id: "ClassifyWithTRD",
        })}`}
      >
        <IconButton
          onClick={() => handleOpenAction(ActionSettled.CLASSIFY_WITH_TRD)}
        >
          <TableChartOutlinedIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    );
  };

  const AssignToFileIcon = () => {
    return (
      <Tooltip
        title={`${intl.formatMessage({
          id: "AssignToFile",
        })}`}
      >
        <IconButton
          onClick={() => handleOpenAction(ActionSettled.ASSIGN_TO_FILE)}
        >
          <PostAddOutlinedIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    );
  };

  const ReassignIcon = () => {
    return (
      <Tooltip title={`${intl.formatMessage({ id: "Reassign" })}`}>
        <IconButton onClick={() => handleOpenAction(ActionSettled.REASSIGN)}>
          <AssignmentIndRoundedIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    );
  };

  const SendCopyIcon = () => {
    return (
      <Tooltip title={`${intl.formatMessage({ id: "SendCopy" })}`}>
        <IconButton onClick={() => handleOpenAction(ActionSettled.SEND_COPY)}>
          <FileCopyOutlinedIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    );
  };

  const AnnotationIcon = () => {
    return (
      <Tooltip title={`${intl.formatMessage({ id: "Annotation" })}`}>
        <IconButton onClick={() => handleOpenAction(ActionSettled.ANNOTATION)}>
          <AssignmentOutlinedIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    );
  };

  const UploadDocumentIcon = () => {
    return (
      <Tooltip
        title={`${intl.formatMessage({
          id: "UploadDocument",
        })}`}
      >
        <IconButton
          onClick={() => handleOpenAction(ActionSettled.UPLOAD_DOCUMENT)}
        >
          <BackupOutlinedIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    );
  };

  const SendToEmailIcon = () => {
    return (
      <Tooltip
        title={`${intl.formatMessage({
          id: "SendToEmail",
        })}`}
      >
        <IconButton onClick={() => handleOpenAction(ActionSettled.SEND_EMAIL)}>
          <EmailIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    );
  };

  const RequestForProjection = () => {
    return (
      <Tooltip
        title={`${intl.formatMessage({
          id: "RequestForProjection",
        })}`}
      >
        <IconButton
          onClick={() => handleOpenAction(ActionSettled.REQUEST_FOR_PROJECTION)}
        >
          <TrendingUpOutlinedIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    );
  };
  return (
    <Box m={6} mt={3}>
      <Grid container spacing={1}>
        {!readonly && (
          <>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Box display={"flex"} justifyContent={"flex-end"} mr={5}>
                {component !== "PROJECTION" && (
                  <button
                    style={{ marginRight: "8rem", fontSize: 13, width: "30%" }}
                    onClick={readAction}
                  >
                    <FormattedMessage id="MarkasUnread" />
                  </button>
                )}

                <label className="form-text">
                  <FormattedMessage id="Actions" />: &nbsp;
                </label>
                <label
                  style={{
                    color: Colors.SECONDARY_TEXT,
                    fontSize: 13,
                    marginTop: "2px",
                  }}
                >
                  {activeCorrespondence?.answeredDate ? (
                    <FormattedMessage id="AnswerEmited" />
                  ) : (
                    <FormattedMessage id="ActionOnTheSettled" />
                  )}
                </label>
              </Box>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              {activeCorrespondence?.answeredDate && (
                <>
                  {component === "OTHER" && (
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      {activeCorrespondence.settledAnswerId ? (
                        <>
                          <ReclassifyIcon />
                          <ClassifyWithTRDIcon />
                          <AssignToFileIcon />
                          {activeCorrespondence.inputType ===
                            CorrespondenceInputType.EP && (
                            <>
                              <ReassignIcon />
                              <SendCopyIcon />
                              <AnnotationIcon />
                              <UploadDocumentIcon />
                              <SendToEmailIcon />
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {activeCorrespondence.expedients?.length === 0 && (
                            <AssignToFileIcon />
                          )}
                          <ReassignIcon />
                          <SendCopyIcon />
                        </>
                      )}
                    </Box>
                  )}
                </>
              )}

              {activeCorrespondence?.answeredDate === null && (
                <>
                  {component === "OTHER" && (
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <ReclassifyIcon />
                      <ReassignIcon />
                      <SendCopyIcon />
                      {activeCorrespondence.communicationType &&
                        activeCorrespondence.communicationType.isParam &&
                        activeCorrespondence.inputType !==
                          CorrespondenceInputType.EP && (
                          <Tooltip
                            title={`${intl.formatMessage({
                              id: "extensionRequest",
                            })}`}
                          >
                            <IconButton
                              onClick={() =>
                                handleOpenAction(
                                  ActionSettled.EXTENSION_REQUEST
                                )
                              }
                            >
                              <AlarmAddIcon fontSize="large" />
                            </IconButton>
                          </Tooltip>
                        )}
                      {activeCorrespondence.inputType !==
                        CorrespondenceInputType.EP && (
                        <>
                          <Tooltip
                            title={`${intl.formatMessage({ id: "Return" })}`}
                          >
                            <IconButton
                              onClick={() =>
                                handleOpenAction(ActionSettled.RETURN)
                              }
                            >
                              <RestoreOutlinedIcon fontSize="large" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={`${intl.formatMessage({ id: "Approved" })}`}
                          >
                            <IconButton
                              onClick={() =>
                                handleOpenAction(ActionSettled.APPROVED)
                              }
                            >
                              <ThumbUpOutlinedIcon fontSize="large" />
                            </IconButton>
                          </Tooltip>
                          <RequestForProjection />
                        </>
                      )}

                      <ClassifyWithTRDIcon />
                      <AssignToFileIcon />
                      <AnnotationIcon />
                      <UploadDocumentIcon />
                      {activeCorrespondence.inputType !==
                        CorrespondenceInputType.EP && (
                        <Tooltip
                          title={`${intl.formatMessage({
                            id: "RequestAdditionalInformation",
                          })}`}
                        >
                          <IconButton
                            onClick={() =>
                              handleOpenAction(
                                ActionSettled.REQUEST_ADDITIONAL_INFORMATION
                              )
                            }
                          >
                            <ContactSupportOutlinedIcon fontSize="large" />
                          </IconButton>
                        </Tooltip>
                      )}
                      {activeCorrespondence &&
                        user &&
                        (activeCorrespondence.addressee.id ===
                          user.uid.toString() ||
                          activeCorrespondence.addressee.id ===
                            user.id.toString()) && (
                          <Tooltip
                            title={`${intl.formatMessage({
                              id: "IssuanceOfResponse",
                            })}`}
                          >
                            <IconButton
                              onClick={() =>
                                handleOpenAction(
                                  ActionSettled.ISSUANCE_OF_RESPONSE
                                )
                              }
                            >
                              <MessageOutlinedIcon fontSize="large" />
                            </IconButton>
                          </Tooltip>
                        )}
                      {activeCorrespondence.inputType ===
                        CorrespondenceInputType.EP && <SendToEmailIcon />}
                    </Box>
                  )}
                  {component === "COPY" && (
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <AnnotationIcon />
                    </Box>
                  )}
                  {component === "VoBo" && (
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <Tooltip
                        title={`${intl.formatMessage({ id: "IssueApproval" })}`}
                      >
                        <IconButton
                          onClick={() =>
                            handleOpenAction(ActionSettled.ISSUANCE_VO_BO)
                          }
                        >
                          <AssignmentTurnedInOutlinedIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  {component === "PROJECTION" && (
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <Tooltip
                        title={`${intl.formatMessage({
                          id: "IssuanceOfResponse",
                        })}`}
                      >
                        <IconButton
                          onClick={() =>
                            handleOpenAction(ActionSettled.ISSUANCE_OF_RESPONSE)
                          }
                        >
                          <MessageOutlinedIcon fontSize="large" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  {component === "RETURNED" && (
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <Tooltip
                        title={`${intl.formatMessage({
                          id: "IssuanceOfResponse",
                        })}`}
                      >
                        <Tooltip
                          title={`${intl.formatMessage({ id: "Reassign" })}`}
                        >
                          <IconButton
                            onClick={() =>
                              handleOpenAction(ActionSettled.REASSIGN)
                            }
                          >
                            <AssignmentIndRoundedIcon fontSize="large" />
                          </IconButton>
                        </Tooltip>
                      </Tooltip>
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <label className="form-text">
            <FormattedMessage id="Subject" />:
          </label>
          <TextField
            name="subject"
            variant="outlined"
            size="small"
            value={form.subject}
            className={classes.myTextFieldRoot}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <label className="form-text">
            <FormattedMessage id="ContentDescription" />:
          </label>
          <TextField
            name="description"
            variant="outlined"
            className={classes.myTextFieldRoot}
            value={form.description}
            multiline
            minRows={2}
            maxRows={5}
            disabled
          />
        </Grid>

        <Grid item xs={3}>
          <label className="form-text">
            <FormattedMessage id="InputType" />:
          </label>

          <TextField
            name="inputType"
            variant="outlined"
            select
            value={form.inputType}
            className={classes.myTextFieldRoot}
            size="small"
            disabled
          >
            <MenuItem value={CorrespondenceInputType.EP}>
              <FormattedMessage id="ExternalProduced" />
            </MenuItem>
            <MenuItem value={CorrespondenceInputType.ER}>
              <FormattedMessage id="ExternalReceived" />
            </MenuItem>
            <MenuItem value={CorrespondenceInputType.IP}>
              <FormattedMessage id="InternalProduced" />
            </MenuItem>
            <MenuItem value={CorrespondenceInputType.IR}>
              <FormattedMessage id="InternalReceived" />
            </MenuItem>

            <MenuItem value={CorrespondenceInputType.OR}>
              <FormattedMessage id="OtherShipmentsReceived" />
            </MenuItem>
            <MenuItem value={CorrespondenceInputType.OP}>
              <FormattedMessage id="OtherShipmentsProduced" />
            </MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={3}>
          <label className="form-text">
            <FormattedMessage id="CommunicationType" />:
          </label>
          <TextField
            name="communicationType"
            variant="outlined"
            size="small"
            value={form.communicationType}
            className={classes.myTextFieldRoot}
            disabled
          />
        </Grid>

        <Grid item xs={6}>
          <label className="form-text">
            <FormattedMessage id="Channel" />:
          </label>
          <TextField
            name="communicationChannel"
            variant="outlined"
            value={form.communicationChannel}
            size="small"
            className={classes.myTextFieldRoot}
            disabled
          />
        </Grid>

        <Grid item xs={3}>
          <label className="form-text">
            <FormattedMessage id="SettledDependency" />:
          </label>
          <TextField
            name="producerDependency"
            variant="outlined"
            value={form.producerDependency}
            size="small"
            className={classes.myTextFieldRoot}
            disabled
          />
        </Grid>
        <Grid
          item
          className={classes.producerUserStyle}
          xs={3}
          onClick={() =>
            activeCorrespondence &&
            handleOpenModalInfoUser(activeCorrespondence.externalSender.id)
          }
        >
          <label className="form-text">
            <FormattedMessage id="SettledUser" />:
          </label>
          <TextField
            style={{ cursor: "pointer" }}
            name="producerUser"
            variant="outlined"
            value={form.sender}
            size="small"
            className={classes.myTextFieldRoot}
            disabled
          />
        </Grid>

        <Grid item xs={3}>
          <label className="form-text">
            <FormattedMessage id="ReceivingDependency" />:
          </label>
          <TextField
            name="receivingDependency"
            variant="outlined"
            value={form.receivingDependency}
            size="small"
            className={classes.myTextFieldRoot}
            disabled
          />
        </Grid>

        <Grid
          item
          xs={3}
          className={classes.producerUserStyle}
          onClick={() =>
            activeCorrespondence &&
            handleOpenModalInfoUser(activeCorrespondence.addressee.id)
          }
        >
          <label className="form-text">
            <FormattedMessage id="ReceivingUser" />:
          </label>
          <TextField
            name="addressee"
            variant="outlined"
            value={form.addressee}
            size="small"
            className={classes.myTextFieldRoot}
            disabled
          />
        </Grid>

        <Grid item xs={3}>
          <label className="form-text">
            <FormattedMessage id="Pages" />{" "}
            <FormattedMessage id="Communication" />:
          </label>
          <TextField
            name="communicationPages"
            variant="outlined"
            size="small"
            className={classes.myTextFieldRoot}
            disabled
            value={form.communicationPages}
          />
        </Grid>

        <Grid item xs={2}>
          <label className="form-text">
            <FormattedMessage id="Pages" /> <FormattedMessage id="Annexes" />:
          </label>
          <TextField
            name="annexesPages"
            variant="outlined"
            size="small"
            className={classes.myTextFieldRoot}
            disabled
            value={form.annexesPages}
          />
        </Grid>

        <Grid item xs={2}>
          <label className="form-text">
            <FormattedMessage id="Pages" /> <FormattedMessage id="Copies" />:
          </label>
          <TextField
            name="copyPages"
            variant="outlined"
            size="small"
            className={classes.myTextFieldRoot}
            disabled
            value={form.copyPages}
          />
        </Grid>

        <Grid item xs={2}>
          <label className="form-text">
            <FormattedMessage id="PostalAgency" />:
          </label>
          <TextField
            name="postalAgency"
            variant="outlined"
            className={classes.myTextFieldRoot}
            disabled
            size="small"
            value={form.postalAgency}
          />
        </Grid>

        <Grid item xs={3}>
          <label className="form-text">
            <FormattedMessage id="Guide" />:
          </label>
          <TextField
            name="guide"
            variant="outlined"
            className={classes.myTextFieldRoot}
            disabled
            size="small"
            value={form.guide}
          />
        </Grid>
      </Grid>

      <CustomizedDialog
        open={modalActionOpen}
        cancelBtn={true}
        onDeny={onDeny}
        title={`${intl.formatMessage({
          id: `${titleAction}`,
        })}`}
        content={content}
        textButton="Accept"
      />

      {questionMsg && titleMsg && (
        <CustomizedDialog
          open={modalDeleteOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: `${titleMsg}` })}`}
          content={
            <ConfirmDelete
              title={`${intl.formatMessage({
                id: `${questionMsg}`,
              })}`}
            />
          }
          textButton="Accept"
        />
      )}
      {activeCorrespondence && (
        <CustomizedDialog
          open={modalInfoOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "UserInformation" })}`}
          content={<InfoModal id_user={idUserInfo} />}
          //content={<SenderForm/>}
          textButton="Accept"
        />
      )}
      <br />
      {activeCorrespondence && (
        <SettleDetailsTabs
          correspondence={activeCorrespondence}
          documentDate={activeCorrespondence?.createAt.toDate()}
          paramResponse={activeCorrespondence?.communicationType}
          readonly={readonly}
          component={component}
        />
      )}
      {successMsg && (
        <MyAlert
          open={successAlert}
          typeAlert="success"
          message={successMsg}
          time={2000}
          handleClose={closeErrorAndSuccessAlert}
        />
      )}

      {errorMsg && (
        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={errorMsg}
          time={2000}
          handleClose={closeErrorAndSuccessAlert}
        />
      )}
    </Box>
  );
};

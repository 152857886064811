import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import {
  createTheme,
  ThemeProvider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { CustomizedTableFooter } from "../../../custom/CustomizedTableFooter";
import { useStyles } from "../../../../shared/styles/useStyles";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { SubseriesBody } from "./SubseriesBody";
import { ITrdSubSerie } from "../../../../redux/types/types";
import { Colors } from "../../../../shared/constants/themes/Colors";
import { FC } from "react";
import { counter } from "../../../../helpers/count";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});

interface props {
  subseries: ITrdSubSerie[];
}

export const SubseriesTable: FC<props> = ({ subseries }) => {
  const classes = useStyles();

  // const { subSeries } = useSelector((state: AppState) => state.trd);
  // const list: ITrdSubSerie[] = subSeries;

  const { data, value } = useSelector((state: AppState) => state.search);
  const count = counter( value, subseries, data );

  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, subseries.length - page * rowsPerPage);

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper}>
      {subseries.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            <FormattedMessage id={"NoCreatedSubseries"} />
          </Box>
        ) : (
        <Table
          className={classes.table}
          size="small"
          style={{ tableLayout: "auto" }}
          aria-label="a dense table"
        >
          <TableHead className={classes.titleTable}>
            <TableRow>
              <TableCell
                width={"10%"}
                align="center"
                className={classes.titleTable}
              >
                <FormattedMessage id="CodeOP" />{" "}
              </TableCell>
              <TableCell
                width={"20%"}
                align="center"
                className={classes.titleTable}
              >
                <FormattedMessage id="ProductionOffice" />{" "}
              </TableCell>
              <TableCell
                width={"10%"}
                align="center"
                className={classes.titleTable}
              >
                <FormattedMessage id="SeriesCode" />{" "}
              </TableCell>
              <TableCell
                width={"20%"}
                align="center"
                className={classes.titleTable}
              >
                <FormattedMessage id="SeriesName" />{" "}
              </TableCell>
              <TableCell
                width={"10%"}
                align="center"
                className={classes.titleTable}
              >
                <FormattedMessage id="SubseriesCode" />{" "}
              </TableCell>
              <TableCell
                width={"20%"}
                align="center"
                className={classes.titleTable}
              >
                <FormattedMessage id="SubseriesName" />{" "}
              </TableCell>
              <TableCell
                width={"10%"}
                className={classes.titleTable}
                align="center"
              >
                <FormattedMessage id="Actions" />{" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!value.trim()
              ? (rowsPerPage > 0
                  ? subseries.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : subseries
                ).map((subSerie) => (
                  <SubseriesBody subserie={subSerie} key={subSerie.subSerieCode} />
                ))
              : data &&
                (rowsPerPage > 0
                  ? data.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data
                ).map((subSerie) => (
                  <SubseriesBody subserie={subSerie} key={subSerie.subSerieCode} />
                ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 13 * emptyRows }}>
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
          {/* --------- Footer para paginación -------- */}
          <CustomizedTableFooter count={count} />
        </Table>
      )}
      </TableContainer>
    </ThemeProvider>
  );
};

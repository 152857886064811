import { firebase } from "../../config/firebase/firebase-config";
import {
  SupportType,
  TransferType,
  TypeDays,
  TypeUser,
} from "../../enums/enums";
import {
  ICorrespondence,
  ICorrespondenceInfo,
  IRequestProjection,
  IUserCopy,
  IUserCorrespondence,
  IAppendDocs,
  ITenderCorrespondence,
  IUserByEntity,
  ICorrespondenceResponse,
  IExpedientCorrespondence,
  ITenders,
  IFilesCorrespondence,
  ISignature,
} from "../../interfaces/Correspondence";
import { ILoan } from "../../interfaces/Loans";
import { Office } from "../../interfaces/OrganizationChart";
import { Entity, User } from "../../interfaces/Users";

export enum Types {
  //auth
  login = "[Auth] Login",
  logout = "[Auth] Logut",
  getUserData = "[Auth] get user data",

  //pagination
  setPage = "[Pagination] Set page",
  setRowsPerPage = "[Pagination] Set rows per page",

  // ui
  uiOpenModalAdd = "[ui] Open modal add",
  uiCloseModalAdd = "[ui] Close modal add",
  uiOpenModalEdit = "[ui] Open modal edit",
  uiCloseModalEdit = "[ui] Close modal edit",
  uiOpenModalDelete = "[ui] Open modal delete",
  uiOpenModalInfo = "[ui] Open modal user information",
  uiCloseModalInfo = "[ui] Close modal user information",
  uiCloseModalDelete = "[ui] Close modal delete",
  uiOpenModalAssignModules = "[ui] Open modal assign modules",
  uiCloseModalAssignModules = "[ui] Close modal assign modules",
  uiOpenModalAssignOffices = "[ui] Open modal assign offices",
  uiCloseModalAssignOffices = "[ui] Close modal assign offices",
  uiOpenModalAttachDocuments = "[ui] Open modal attach documents",
  uiCloseModalAttachDocuments = "[ui] close modal attach documents",
  uiOpenModalSee = "[ui] open modal see",
  uiCloseModalSee = "[ui] close modal see",
  uiOpenModalAction = "[ui] Open modal action",
  uiCloseModalAction = "[ui] Close modal action",
  uiOpenModalAnswer = "[ui] Open modal answer",
  uiCloseModalAnswer = "[ui] Close modal answer",
  uiCloseAnswerModal = "[ui] Close answer modal",
  uiOpenModalAnswerBroadcast = "[ui] Open modal answer broadcast",
  uiCloseModalAnswerBroadcast = "[ui] Close modal answer broadcast",
  uiOpenModalAnswerBroadcastEdit = "[ui] Open modal answer broadcast edit",
  uiCloseModalAnswerBroadcastEdit = "[ui] Close modal answer broadcast edit",
  uiOpenAlertExists = "[ui] Open alert exists",
  uiCloseAlertExists = "[ui] Close alert exists",
  uiOpenSuccessAlert = "[ui] Open success alert",
  uiCloseSuccessAlert = "[ui] Close success alert",
  uiOpenErrorAlert = "[ui] Open error alert",
  uiCloseErrorAlert = "[ui] Close error alert",
  uiOpenSuccessChange = "[ui] Open success change",
  uiCloseSuccessChange = "[ui] Close success change",
  uiSetSuccesMsg = "[ui] set success msg",
  uiSetErrorMsg = "[ui] set error msg",
  uiStartLoading = "[ui] Start loading",
  uiFinishLoading = "[ui] Finish loading",
  uiOpenModalReject = "[ui] open modal reject",
  uiCloseModalReject = "[ui] close modal reject",
  uiSetQuestionMsg = "[ui] set question msg",
  uiSetTitleMsg = "[ui] set title msg",
  uiOpenModalAnswerCorrespondence = "[ui] se open modal answer",
  uiOpenModalEmailStatusHistory = "[ui open modal email status history]",
  uiCloseModalEmailStatusHistory = "[ui close modal email status history]",
  uiOpenModalEmailSent = "[ui open modal email sent]",
  uiCloseModalEmailSent = "[ui close modal email sent]",

  // Users
  userAddNew = "[user] New user",
  userActive = "[user] Set active user",
  userCleanActive = "[user] Clean active user",
  usersLoad = "[user] Load users",
  userUpdated = "[user] Updated user",
  userLoadAssignedOffices = "[user] Load assigned offices",
  loadParamsExternalSender = "[user] Load external senders",
  addExternalSender = "[user] add external sender ",
  updateExternalSender = "[user] update external sender ",
  userAddAssignedOffice = "[user] Add assigned office",
  userDeleteAssignedOffice = "[user] Delete assigned office",
  setActiveExternalSender = "[user] Set active external sender",
  searchExternalSenders = "[user] search external senders",
  searchExternalAddressees = "[user] search external addressees",

  //ENTITIES
  entitiesLoad = "[entities] load all entities",
  setActiveEntity = "[entities] set active entity",
  addEntity = "[entities] add new entity",
  deleteEntity = "[entities] delete entity ",
  editEntity = "[entities] edit entity",
  purgeEntities = "[entities] purge entities",
  purgeActiveEntity = "[entities] purge active entity",
  addInitialSettled = " [entities] add initial settled",

  // setting
  TOGGLE_NAV_COLLAPSED = "TOGGLE_NAV_COLLAPSED",
  SET_INITIAL_PATH = "SET_INITIAL_PATH",
  ROUTE_CHANGE = "@@router/LOCATION_CHANGE",

  // organizatioChart
  OrganizationChartAddNew = "[OrganizationChart] New Office",
  OrganizationCharActive = "[OrganizationChart] Active Office",
  OrganizationChartLoad = "[OrganizationChart] Load Offices",
  OrganizationChartUpdated = "[OrganizationChart] Updated Office",
  OrganizationCharDelete = "[OrganizationChart] Delete Office",
  OrganizationCharCleaning = "[OrganizationChart] Cleaning office",

  //Search
  arraySearch = "[search] Array search",
  valueSearched = "[search] Value searched",

  // TRD
  loadVersions = "[Trd] load all trd versions",
  addOrEditTrdVersion = "[Trd] add trd versino",
  updateVersion = "[Trd] update trd version",
  deleteVersion = "[Trd] delete trd version",
  setActiveTrdVersion = "[Trd] set active trd version",
  loadProductionOffices = "[Trd] load all production offices",
  addTrdProductionOffice = "[Trd] add production office",
  updateTrdProductionOffice = "[Trd] update production office",
  setActiveProductionOffice = "[Trd] Set active production office",
  deleteProductionOffice = "[Trd] delete production office",
  addSupportDocuments = "[Trd] add Support Documents",
  deleteSupportDocument = "[Trd] delete Support Document",
  loadSeries = "[Trd] load all series",
  purgeSeries = "[Trd] purge series",
  setTrdSerie = "[Trd] set trd serie",
  setActiveSerie = "[Trd] set active serie",
  deleteSerie = "[Trd] delete serie ",
  loadTrdDocumentalTypes = "[Trd] load all documental types",
  setTrdDocumentalType = "[Trd] set documental type",
  deleteTrdDocumentalType = "[Trd] delete documental type",
  setActiveDocumentalType = "[Trd] set active documental type",

  loadSubseries = "[Trd] load all Subseries",
  addSubserie = "[Trd] add Subserie",
  setActiveSubserie = "[Trd] Set active Subserie",
  updateTrdSubserie = "[Trd] update subSerie",
  deleteSubserie = "[Trd] delete subSerie",

  //CORRESPONCENCE
  loadAnnotations = "[Correspondence] load all annotations",
  setAnnotation = "[Correspondence] set annotation",
  deleteAnnotation = "[Correspondence] delete annotation",
  setActiveAnnotation = "[Correspondence] set active annotation",
  loadParamsProcedure = "[Correspondence] load all procedures",
  addProcedure = "[Correspondence] add new procedure",
  updateProcedure = "[Correspondence] update procedure",
  setActiveProcedure = "[Correspondence] Set active procedure",
  deleteProcedure = "[Correspondence] delete procedure",
  loadParamsResponseTime = "[Correspondence] load all response times",
  purgeCorrespondence = "[Correspondence] purge correspondence",
  setCorrespondence = "[Correspondence] set correspondence",
  deleteCorrespondence = "[Correspondence] delete correspondence",
  setActiveCorrespondence = "[Correspondence] set active correspondence",
  loadCorrespondences = "[Correspondence] start load correspondences",
  loadCorrespondeceInbox = "[Correspondence] start load correspondence inbox",
  setActiveCorrespondenceInbox = "[Correspondence] set active correspondence inbox",
  loadMilestones = "[Correspondence] load all milestones",
  loadContractingOffice = "[Correspondence] load all contracting offices",
  setLabelSize = "[Correspondence] add or update label size to correspondence entity",

  loadTenders = "[Correspondence] load all Tender",
  setTender = "[Correspondence] set Tender",
  deleteTender = "[Correspondence] delete Tender",
  setActiveTender = "[Correspondence] set active Tender",
  setProductionOfficeParam = "[Correspondence] set param production office",

  //Document managment
  loadCorrespondenceDocumentManagment = "[DocumentManagment] load correspondence document managmnet",
  setActiveCorrespondenceDocumentManagment = "[DocumentManagment] set active correspondence document managmnet",
  setTitleAction = "[DocumentManagment] set title action",
  updateCorrespondence = "[DocumentManagment] update correspondence",
  sendCorrespondenceCopy = "[DocumentManagment] send correspondence copy",
  deleteCorrespondenceCopy = "[DocumentManagment] delete correspondence copy",
  attachedDocumentCorrespondence = "[DocumentManagment] add attached document to correspondence ",
  requestProjection = "[DocumentManagment] request projection",
  issueResponse = "[DocumentManagment] issue response correspondence",
  loadDeposits = "[DocumentManagment] load all deposits",
  addDeposit = "[DocumentManagment] add new deposits",
  deleteDeposit = "[DocumentManagment] delete deposits",
  loadTransfers = "[DocumentManagment] load transfers",
  addTransfer = "[DocumentManagment] add new transfer",
  setActiveTransfer = "[DocumentManagment] set active transfer",
  setActiveAnswer = "[DocumentManagment] set active answer",
  setActiveExpedientDocManagment = "[DocumentManagment] set active expedient",
  deleteDepositDocumentManagement = "[DocumentManagment] delete deposit",
  setCorresAnswer = "[DocumentManagment] set answer",
  deleteTransferDocumentManagement = "[DocumentManagment] delete transfer",
  addFuidDocumentManagementAnnotation = "[DocumentManagment] add FuidAnnotations",
  addSignature = "[DocumentManagment] add signature",
  deleteSignature = "[DocumentManagment] delete signature",

  //Fuid
  addFuid = "[fuid] save new expedient",
  loadFuid = "[fuid] load all expedients",
  deleteFuid = "[fuid] delete expedient",
  setActiveDocument = "[fuid] set active document",

  //CorresAnnotations
  loadAllCorresAnnotations = "[DocumentManagment] load All CorresAnnotations",
  addCorresAnnotation = "[DocumentManagment] load CorresAnnotations",
  deleteCorresAnnotation = "[DocumentManagment] delete CorresAnnotation",
  setActiveCorrespondenceAnnotation = "[DocumentManagment] set CorresAnnotation",
  setActiveFuid = "[fuid] set active expedient",

  //CENTRAL ARCHIVE
  loadDepositsFiles = "[CentralArchive] load all deposits",
  addDepositFiles = "[CentralArchive] add new deposits",
  deleteDepositFiles = "[CentralArchive] delete deposit",

  // FILE MODULE
  loadFileModuleTransfers = "[FileModule] load file module transfers",
  setActiveFileModuleTransfer = "[FileModule] set active file module transfers",
  setFileModuleTransfer = "[FileModule] set file module transfers",
  loadTransferExpedients = "[FileModule] load transfer expedients",
  setActiveFuidFileModule = "[FileModule] set active expedient",
  sendCopyFiles = "[CentralArchive] send correspondence copy",
  updateExpedientFileModule = "[FileModule] upload expedient",
  addDocumentsFuid = "[FileModule] add expedient document download",
  deleteDocumentsFuid = "[FileModule] delete expedient document download",
  deleteTransferFuid = "[FileModule] delete transfer",
  loadLoans = "[FileModule] load all loans",
  setActiveLoan = "[FileModule] set active loan",
  addFuidAnnotation = "[FileModule] add FuidAnnotations",
  loadAllFuidAnnotations = "[FileModule] load All FuidAnnotations",
  setActiveFuidAnnotation = "[FileModule] set FuidAnnotation",
  deleteFuidAnnotation = "[FileModule] delete FuidAnnotation",
}
export interface IReduxType {
  type: Types;
}
export interface IUserLoggedIn {
  active: boolean;
  email: string;
  id: number;
  idEntity?: string;
  modules?: string[];
  names: string;
  rol: TypeUser;
  surnames: string;
  businessName?: string;
  firstName?: string;
  secondName?: string;
  firstLastName?: string;
  secondLastName?: string;
  username?: string;
  offices?: string[];
  country: string;
  department: string;
  uid: string;
}
export interface IAuthState {
  user: IUserLoggedIn | null;
}
export interface IAuthActions extends IReduxType {
  payload?: IUserLoggedIn;
}

// Ui
export interface IUi {
  modalAddOpen: boolean;
  modalEditOpen: boolean;
  modalDeleteOpen: boolean;
  modalInfoOpen: boolean;
  modalAnswerOpen: boolean;
  modalAnswerBroadcastOpen: boolean;
  modalAnswerBroadcastOpenEdit: boolean;
  modalEmailStatusHistory: boolean;
  modalEmailSent: boolean;
  modalAssignModulesOpen: boolean;
  modalAssignOfficesOpen: boolean;
  modalAtachDocumentsOpen: boolean;
  modalSeeOpen: boolean;
  modalActionOpen: boolean;
  modalRejectOpen: boolean;
  alertExists: boolean;
  successAlert: boolean;
  errorAlert: boolean;
  successChange: boolean;
  successMsg: string | null;
  errorMsg: string | null;
  loading: boolean;
  questionMsg: string | null;
  titleMsg: string | null;
  openAnswerModal: boolean;
}

export type UiAction = {
  type: string;
  payload: IUi;
};

// Pagination
export interface IPagination {
  page: number;
  rowsPerPage: number;
}

export type PaginationAction = {
  type: string;
  payload: any;
};

// Users

export interface IUserAnnotations extends User {
  selected: boolean;
  labelName: string;
}
export interface IExternalSender {
  address: string | null;
  city: string | null;
  country: string | null;
  department: string;
  email: string | null;
  id: string;
  idDoc?: string;
  name: string;
  phone: string;
}
export interface IUser {
  users: User[] | IUserAnnotations[];
  activeUser: User | null;
  assignedOffices: Office[];
  externalSenders: IExternalSender[];
  searchExternalSenders: IExternalSender[];
  searchExternalAddressee: IExternalSender[];
  activeExternalSender: IExternalSender | null;
}

export type UsersAction = {
  type: string;
  payload: any;
};

//ENTITIES
export interface IEntitiesAction extends IReduxType {
  payload?: Entity | Entity[] | IInitialSettled | string | any;
}
export interface IEntitiesState {
  entities: Entity[];
  activeEntity: Entity | null;
  initialSettled: string | null;
}
// Search
export interface ISearch {
  data: any[];
  value: string;
}

export type SearchAction = {
  type: Types;
  payload: any;
};

export interface IFuidState {
  activeExpedient: IFuid | null;
  activeDocument: IDocumentsFuid | null;
}

export type FuidAction = {
  type: Types;
  payload: any;
};

// Settings
export interface ToggleNavCollapsedAction {
  type: typeof Types.TOGGLE_NAV_COLLAPSED;
  initialPath: string | undefined;
}

export interface SetInitialPathAction {
  type: typeof Types.SET_INITIAL_PATH;
  initialPath: string | undefined;
}

export interface RouteChangeAction {
  type: typeof Types.ROUTE_CHANGE;
}

export type SettingsActionTypes =
  | ToggleNavCollapsedAction
  | SetInitialPathAction
  | RouteChangeAction;

// TRD
export interface IFiles {
  fileName: string;
  url: string;
  file?: File;
  owner?: IUserByEntity;
}
export interface ITrdVersion {
  approvalDate: firebase.firestore.Timestamp | Date | string;
  code: string;
  documentalType?: string[] | null;
  productionOffices?: string[] | null;
  series?: string[] | null;
  subseries?: string[] | null;
  idVersion: string;
  name: string;
  supportDocs?: IFiles[] | null;
}
export interface ITrdProductionOffices {
  idVersion: string;
  abbreviation: string;
  code: string;
  idProductionOffice: string;
  name: string;
}
export interface ITrdSeries {
  idProductionOffice: string;
  idSerie: string;
  idVersion: string;
  seriesName: string;
  serieCode: string;
  codeOp: string;
  nameOp: string;
}

export interface Provision {
  CT: boolean;
  E: boolean;
  MD: boolean;
  S: boolean;
}
export interface ITrdSubSerie {
  centralFile: string;
  idProductionOffice: string;
  idSerie: string;
  idVersion: string;
  idSubserie: string;
  managementFile: string;
  name: string;
  process: string;
  provision: Provision;
  subSerieCode: string;
}
export interface ITrdDocumentalType {
  code: string;
  codeOp: string;
  idDocumentalType: string;
  idProductionOffice: string;
  idSerie: string;
  idSubSerie: string;
  idVersion: string;
  name: string;
  supportPaper: boolean;
  supportElectronic: boolean;
  serieCode: string;
  subSerieCode: string;
  template: IFiles;
}
export interface ITrdAction extends IReduxType {
  payload?:
    | ITrdVersion
    | ITrdVersion[]
    | ITrdSeries
    | ITrdSeries[]
    | ITrdSubSerie
    | ITrdSubSerie[]
    | ITrdProductionOffices
    | ITrdProductionOffices[]
    | ITrdDocumentalType
    | ITrdDocumentalType[]
    | string
    | null;
}
export interface ITrdState {
  versions: ITrdVersion[];
  productionOffices: ITrdProductionOffices[];
  series: ITrdSeries[];
  subSeries: ITrdSubSerie[];
  documentalType: ITrdDocumentalType[];
  activeVersion: ITrdVersion | null;
  activeProductionOffice: ITrdProductionOffices | null;
  activeSerie: ITrdSeries | null;
  activeSubSerie: ITrdSubSerie | null;
  activeDocumentalType: ITrdDocumentalType | null;
}

// Offices

export interface IOfficesState {
  offices: Office[];
  activeOffice: Office | null;
}

//TRD TABLE
export interface ITrdTable {
  dependency: string | null;
  series: string | null;
  subseries: string | null;
  documentarySeries: string | null;
  documentarySubseries: string | null;
  documentaryType: string | null;
  managment: string | null;
  central: string | null;
  P: boolean | null;
  EL: boolean | null;
  E: boolean | null;
  S: boolean | null;
  CT: boolean | null;
  MD: boolean | null;
  procedure: string | null;
  template: IFiles | null;
}

// CORRESPONDENCE MODULE
// LOS IDS PARA GUARDAR EN LA BD LOS VAMOS A GENERAR CON UN RANDOM DE 1 A 1 MILLON + "_"+ DATE.now()
export interface IParamProcedure {
  id: string;
  procedureName: string;
  responseTime: number;
  typeDays: TypeDays;
}
export interface IParamResponseTime {
  ClaimDays: number; //RECLAMOS
  ClaimTypeDays: TypeDays;
  ComplaintDays: number; //QUEJAS
  ComplaintTypeDays: TypeDays;
  GuardianshipDays: number; //TUTELAS
  GuardianshipTypeDays: TypeDays;
  id?: string;
  RequestForInformationDays: number; //SOLICITUD DE INFORMACION
  RequestForInformationTypeDays: TypeDays;
  RightOfPetitionDays: number; //Derechos de peticion
  RightOfPetitionTypeDays: TypeDays;
  PetitionDays: number; //consultas
  PetitionTypeDays: TypeDays;
  SuggestionDays: number; //Sugerencias
  SuggestionTypeDays: TypeDays;
}

export interface IRecipients {
  id: string;
  confirmationDate: firebase.firestore.Timestamp | null;
  readedDate: firebase.firestore.Timestamp | null;
  recipient: string; //nombreOficina - nombre funcionario
  submittedDate: firebase.firestore.Timestamp;
  isReaded?: boolean;
}

export interface IAddressee {
  id: string;
  name: string;
}
export interface IAnnotation {
  annotation: string;
  attachedDocument: IFiles | null;
  createdAt: firebase.firestore.Timestamp;
  id: string;
  recipientsData: IRecipients[];
  recipients: string[];
  subjectAnnotation: string;
  sender: IAddressee;
  isReaded?: boolean;
}

// INTERFAZ MILESTONES

export interface IMilestones {
  owner: IUserCorrespondence;
  createAt: firebase.firestore.Timestamp;
  processName: string;
  // id:string;
}

export interface ICorresAnnotations {
  user: IUserCorrespondence;
  description: string;
  id: string;
  createDate: firebase.firestore.Timestamp;
  attachedFiles: IFilesCorrespondence[];
}

export interface attachedFiles {
  fileName: string;
  url: string;
}

export interface IAnswers {
  description: string;
  id: string;
  isScreeingAnswer: boolean;
  subject: string;
  officer: IUserCorrespondence;
  attachedFiles: attachedFiles[];
  createDate: firebase.firestore.Timestamp;
}
//CONTRACTING OFFICE
export interface IContractingOffice {
  idEntity: string;
  productionOffice: string;
  responsibleUser: IUserCorrespondence;
}

export interface ICorrespondenceState {
  annotations: IAnnotation[];
  milestones: IMilestones[];
  contractingOffice: IContractingOffice | null;
  activeAnnotation: IAnnotation | null;
  paramsProcedure: IParamProcedure[];
  activeParamProcedure: IParamProcedure | null;
  paramResponseTime: IParamResponseTime;
  correspondence: ICorrespondence[];
  activeCorrespondence: ICorrespondence | null;
  tenders: ITenders[];
  activeTender: ITenders | null;
  corresAnnotations: ICorresAnnotations[];
  answers: IAnswers[];
  productionOfficeParam: string | null;
  labelSize: number | null;
}
export interface ISendCopy {
  idCorrespondence: string;
  users: IUserCopy[];
}
export interface ISendCopyExpedient {
  idExpedient: string;
  users: IUserCopy[];
}
export interface IDeleteCopy {
  idUser: string;
  idCorrespondence: string;
}
export interface IProjectionRedux {
  projection: IRequestProjection;
  idCorrespondence: string;
}
export interface ICorrespondenceActions extends IReduxType {
  payload?:
    | string
    | IAnnotation
    | IAnnotation[]
    | IContractingOffice
    | IContractingOffice[]
    | IParamProcedure[]
    | IParamProcedure
    | IParamResponseTime
    | null
    | ICorrespondence
    | ICorrespondence[]
    | ICorrespondenceInfo[]
    | IMilestones
    | IMilestones[]
    | ITenders
    | ITenders[]
    | ISendCopy
    | IDeleteCopy
    | IAppendDocsSend
    | IProjectionRedux
    | ICorresAnnotations
    | ICorresAnnotations[]
    | IDeposits
    | IDeposits[]
    | IAnswers
    | IAnswers[]
    | IFuid
    | IFuid[]
    | ITenderCorrespondence
    | ITransfer
    | ITransfer[]
    | ICorrespondenceResponse
    | IExpedientCorrespondence
    | number
    | ISignature;
}
export interface IDocumentManagmentState {
  correspondence: ICorrespondence[];
  activeCorrespondence: ICorrespondence | null;
  titleAction: string;
  Deposits: IDeposits[];
  expedients: IFuid[];
  corresAnnotations: ICorresAnnotations[];
  fuidAnnotations: ICorresAnnotations[];
  activeCorresAnnotation: ICorresAnnotations | null;
  transfers: ITransfer[];
  activeAnswer: ICorrespondenceResponse | null | any;
  activeTransfer: ITransfer | null;
  activeExpedient: IExpedientCorrespondence | null;
  signature: ISignature | null;
}
export interface IDeposits {
  id: string;
  name: string;
  productionOffice: string;
  createDate: firebase.firestore.Timestamp;
  owner: IUserCorrespondence;
}

export interface IAppendDocsSend {
  idCorrespondence?: string;
  appendDocs: IAppendDocs[];
}
export interface IDocsExpedientsSend {
  idExpedient?: string;
  appendDocs: IDocumentsFuid[];
}

export interface IFuid {
  id: string;
  fileNumber: string | number;
  fileName: string;
  description: string;
  extremeInitialDate: firebase.firestore.Timestamp | any;
  extremeFinalDate: firebase.firestore.Timestamp | any;
  pagesOf: string;
  pagesTo: string;
  volumeNumber: string;
  totalFolderOrVolume: string;
  fileType: string;
  deposit: string | null;
  shelf: string;
  box: string;
  folder: string;
  trdOrTvd: string;
  productionOffice: string;
  series: string;
  subseries: ITrdSubSerie;
  documents?: IDocumentsFuid[];
  owner: IUserCorrespondence;
  orderNumber: number;
  barCode: string;
  serieName: string;
  subSerieName: string;
  other: string;
  invoice: string;
  support: SupportType;
  transferType?: TransferType;
  createDate?: firebase.firestore.Timestamp;
  copyToIds?: string[];
  copyTo?: IUserCopy[];
  receivingDependency?: string;
}

export interface IDocumentsFuid {
  url: string;
  fileName?: string;
  documentalType?: string | null;
  idCorrespondence?: string;
  uploadDate: firebase.firestore.Timestamp;
  officer: IUserCorrespondence;
  description?: string;
  trdCode?: string | null;
  check?: boolean;
}

export interface ITransfer {
  idTransfer: string;
  isComplete: boolean;
  isRejected?: boolean;
  transferType: string;
  sendingEntity: string;
  producingEntity: string;
  adminUnit: string;
  productionOffice: string;
  object: string;
  transferDate: firebase.firestore.Timestamp;
  transferNumber: string;
  senderTransferUser: IUserByEntity | null;
  expedients: string[] | null;
  createDate: firebase.firestore.Timestamp;
  submitDate: firebase.firestore.Timestamp | null;
  aprovalDate: firebase.firestore.Timestamp | null;
  rejectComment?: string | null;
  rejectedDate?: firebase.firestore.Timestamp | null;
}

///////////////////////////// FILE MODULE
export interface IFileModuleState {
  Deposits: IDeposits[];
  transfers: ITransfer[];
  activeTransfer: ITransfer | null;
  activeDeposit: IDeposits | null;
  expedients: IFuid[];
  activeExpedientFileModule: IFuid | null;
  documentsDownload: IDocumentsFuid[];
  loans: ILoan[];
  activeLoan: ILoan | null;
  fuidAnnotations: ICorresAnnotations[];
  activeFuidAnnotation: ICorresAnnotations | null;
}

export interface IFileModuleAction extends IReduxType {
  payload?:
    | string
    | null
    | IDeposits
    | IDeposits[]
    | ITransfer
    | ITransfer[]
    | IFuid[]
    | IFuid
    | ISendCopyExpedient
    | IDocumentsFuid[]
    | IDocumentsFuid
    | ILoan[]
    | ILoan
    | ICorresAnnotations
    | ICorresAnnotations[];
}

export interface ICentralArchiveActions extends IReduxType {
  payload?: string | null | IDeposits | IDeposits[];
}

export type ICentralArchiveAction = {
  type: Types;
  payload: any;
};

export interface ICentralArchiveState {
  Deposits: IDeposits[];
}

export interface ILabelSize {
  idEntity: string;
  labelSize: number;
}

export interface IInitialSettled {
  initialSettled: string;
}

import { db, firebase } from "../../config/firebase/firebase-config";
import { IUserCopy } from "../../interfaces/Correspondence";
import { IResponseFirebase } from "../../interfaces/firebase";
import { ICorresAnnotations, IDeposits } from "../../redux/types/types";

export const loadDepositsDb = async (
  idEntity: string,
  productionOf: string
): Promise<IDeposits[]> => {
  const snapShot = await db
    .collection("FileModule")
    .doc(idEntity)
    .collection("Deposits")
    .where("productionOffice", "==", productionOf)
    .get();
  const resp: IDeposits[] = [];
  snapShot.forEach((doc) => {
    resp.push(doc.data() as IDeposits);
  });
  return resp;
};

export const addNewDepositDb = async (
  idEntity: string,
  deposit: IDeposits
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection("FileModule")
      .doc(idEntity)
      .collection("Deposits")

      .doc(deposit.id)
      .set({ ...deposit });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: true,
      data: error,
    };
  }
};

//  ELIMINAR DEPOSITO DE LA BD
export const deleteDepositDb = async (
  idEntity: string,
  idDeposit: string
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection("FileModule")
      .doc(idEntity)
      .collection("Deposits")
      .doc(idDeposit)
      .delete();
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// AGREGAR CAMPOS DE COPIA A EXPEDIENTE
export const setSendExpedientCopy = async (
  idEntity: string,
  idExpedient: string,
  to: IUserCopy[],
  fromFilesModule: boolean
): Promise<IResponseFirebase> => {
  try {
    const usersIds = to.map((user) => user.id);
    await db
      .collection(fromFilesModule ? "FileModule" : "Correspondence")
      .doc(idEntity)
      .collection("Expedients")
      .doc(idExpedient)
      .set({ copyTo: to, copyToIds: usersIds }, { merge: true });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

//AGREGAR COLLECTION MILESTONE
export const addMilestoneDb = async (
  idEntity: string,
  expedientId: string,
  date: firebase.firestore.Timestamp,
  process: string,
  idUser: number,
  namesUser: string | undefined,
  fromFilesModule?: boolean
) => {
  try {
    await db
      .collection(fromFilesModule ? "FileModule" : "Correspondence")
      .doc(idEntity)
      .collection("Expedients")
      .doc(expedientId)
      .collection("Milestones")
      .add({
        owner: { name: namesUser, id: idUser },
        createAt: date,
        processName: process,
      });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};
export const deleteTransferDb = async (
  idEntity:string,
  idTransfer: string,
  fromFileModule:boolean
) => {
  try {
    await db
      .collection(fromFileModule?"FileModule":"Correspondence")
      .doc(idEntity)
      .collection("Transfers")
      .doc(idTransfer)
      .delete()
      
     
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// AGREGAR ANOTACION DE FUID A BASE DE DATOS
export const addFuidAnnotationsDb = async (
  idEntity: string,
  fuidId: string,
  annotation: ICorresAnnotations,
  fromFileModule:boolean
) => {
  try {
    const { id } = annotation;
    await db
      .collection(fromFileModule?"FileModule":"Correspondence")
      .doc(idEntity)
      .collection("Expedients")
      .doc(fuidId)
      .collection("FuidAnnotations")
      .doc(id)
      .set({
        ...annotation,
      });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

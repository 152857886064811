import {
  ICorresAnnotations,
  IDocumentsFuid,
  IFiles,
  ISendCopyExpedient,
} from "./../types/types";
import { firebase } from "../../config/firebase/firebase-config";
import { addTransferDb } from "../../services/firebase/documentManagment";
import {
  addTransferFileModule,
  deleteFuidAnnotationDBDoc,
  deleteLoan,
  setExpedientFileModule,
  setLoan,
  startListenerLoansByEntity,
  startListenerTransferExpedientsByEntity,
  startListenerTransfersByEntity,
} from "../../services/firebase/fileModule";
import { AppState } from "../reducers/rootReducer";
import { IFileModuleAction, IFuid, ITransfer, Types } from "../types/types";
import {
  setErrorMsg,
  setSuccessMsg,
  uiCloseModalAction,
  uiCloseModalAdd,
  uiCloseModalEdit,
  uiCloseModalInfo,
  uiCloseModalReject,
  uiCloseModalSee,
  uiOpenErrorAlert,
  uiOpenSuccessAlert,
} from "./uiActions";
import {
  addAttachedDocumentExpedientDb,
  deleteFuidDb,
  setEditExpedientDb,
} from "../../services/firebase/fuid";

import { TransferType, TypeArchive } from "../../enums/enums";
import {
  ICreateAnnotationForm,
  IReassingForm,
  IUserCorrespondence,
} from "../../interfaces/Correspondence";
import { getCopyArrayOrObject } from "../../utils/utils";
import { setActiveFuid } from "./fuidActions";
import { setActiveTransfer } from "./documentManagmentActions";

import {
  addFuidAnnotationsDb,
  addMilestoneDb,
  deleteTransferDb,
} from "../../services/firebase/centralArchive";
import { Enum_LoanState, ILoan } from "../../interfaces/Loans";
import { v4 as uuid } from "uuid";
import { startsendExpedientCopy } from "./centralArchiveActions";
import Swal from "sweetalert2";
import { addNewFuid } from "../../services/firebase/correspondence";
import { uploadFileAsync } from "../../helpers/UploadFile";
import { deleteFuidAnnotationDB } from "../../services/firebase/fileModule";

export const startLoadFileModuleTransfers = (idEntity: string) => {
  return async (dispatch: Function) => {
    startListenerTransfersByEntity(idEntity, dispatch);
  };
};
export const loadTransfers = (trasnsfers: ITransfer[]): IFileModuleAction => ({
  type: Types.loadFileModuleTransfers,
  payload: trasnsfers,
});
export const startAproveTransfer = (fromCentralFile?: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    const { expedients: expedientsManag } = (getState() as AppState)
      .documentManagment;
    const { expedients: expedientsFileModule } = (getState() as AppState)
      .fileModule;
    const expedients = fromCentralFile ? expedientsFileModule : expedientsManag;
    const { activeTransfer } = (getState() as AppState).documentManagment;
    if (user && user.idEntity && activeTransfer) {
      const transferCopy = { ...activeTransfer };
      //isComplete en true y tambien approvalDate now()
      transferCopy.isComplete = true;
      transferCopy.aprovalDate = firebase.firestore.Timestamp.now();
      //actualizar la transferencia en FileModule y en Correspondence
      let okCorres = true;
      if (!fromCentralFile) {
        const { ok: okCorresDb } = await addTransferDb(
          user.idEntity,
          transferCopy
        );
        okCorres = okCorresDb;
      }
      const { ok: okFileM } = await addTransferFileModule(
        user.idEntity,
        transferCopy
      );
      if (okCorres && okFileM) {
        //traer los expedientes que se han asignado a la transferencia
        const { expedients: expedientsIds } = transferCopy;
        if (expedientsIds) {
          const expedientsFind = expedientsIds
            .map((expId) => expedients.filter((exp) => exp.id === expId))
            .flat();
          //setear el deposit en null
          //agregar transferType a el expediente (transfer.transferType)

          for (let index = 0; index < expedientsFind.length; index++) {
            const fuid = expedientsFind[index];
            if (!fromCentralFile) {
              fuid.deposit = null;
            }
            fuid.transferType = activeTransfer.transferType as any;
            if (fuid.transferType === TransferType.PRIMARY_TRANSFER) {
              fuid.owner = {
                id: TypeArchive.CENTRAL_ARCHIVE,
                name: TypeArchive.CENTRAL_ARCHIVE,
              };
            } else {
              fuid.owner = {
                id: TypeArchive.HISTORICAL_ARCHIVE,
                name: TypeArchive.HISTORICAL_ARCHIVE,
              };
            }

            //Guardar en FileModule/idEntity/Expedients
            const { ok: okAdd } = await setExpedientFileModule(
              fuid,
              user.idEntity
            );
            let okDelete = true;
            //Borrar los expedientes de Correspondencia
            if (!fromCentralFile) {
              const { ok: okDeleteDb } = await deleteFuidDb(
                user.idEntity,
                fuid.id
              );
              okDelete = okDeleteDb;
            }
            if (!okAdd && !okDelete) {
              return dispatch(uiOpenErrorAlert());
            }
          }
          dispatch(setSuccessMsg("TransferComplete"));
          dispatch(uiOpenSuccessAlert());
          dispatch(uiCloseModalInfo());
          dispatch(setActiveTransfer(null));
        }
      }
    }
  };
};
export const startLoadTransferExpedients = (idEntity: string) => {
  return async (dispatch: Function) => {
    startListenerTransferExpedientsByEntity(
      idEntity,

      dispatch
    );
  };
};
export const loadTransferExpedients = (
  trasnsfers: IFuid[]
): IFileModuleAction => ({
  type: Types.loadTransferExpedients,
  payload: trasnsfers,
});

//DISPATCH PARA ENVIAR FUID ACTIVO
export const setActiveFuidFileModule = (
  expedient: IFuid | null
): IFileModuleAction => ({
  type: Types.setActiveFuidFileModule,
  payload: expedient,
});

export const updateExpedientFileModule = (expedient: IFuid) => ({
  type: Types.updateExpedientFileModule,
  payload: expedient,
});

export const addExpedientDownloadFileModule = (document: IDocumentsFuid) => ({
  type: Types.addDocumentsFuid,
  payload: document,
});

export const deleteExpedientDownloadFileModule = (documentUrl: string) => ({
  type: Types.deleteDocumentsFuid,
  payload: documentUrl,
});

// reasignar radicado
export const startReassingSettledFuid = (
  valuesForm: IReassingForm,
  fromFileModule: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity, username, id } = getState().auth.user;
    const { activeExpedientFileModule: FuidFilesModule } =
      getState().fileModule;
    const { activeExpedient: FuidDocumentManagement } = getState().fuid;
    const active: IFuid = fromFileModule
      ? FuidFilesModule
      : FuidDocumentManagement;
    const copyActive: IFuid = getCopyArrayOrObject(active);

    if (copyActive.receivingDependency === valuesForm.receivingDependency) {
      dispatch(setErrorMsg("DifferentProductionOffice"));
      dispatch(uiOpenErrorAlert());
    } else {
      //PONER COMO DUEÑO DE LOS DOUCMENTOS DEL ACTUAL POR EL NUEVO DUEÑO DEL EXPEDIENTE
      active.documents?.forEach((value) => {
        if (value.officer.id === copyActive.owner.id) {
          value.officer = valuesForm.addressee;
        }
      });
      active.receivingDependency = valuesForm.receivingDependency;
      active.owner = valuesForm.addressee;
      const { ok } = await setEditExpedientDb(active, idEntity);
      //AGREGAR AL MILESTONE
      const { ok: okMil } = await addMilestoneDb(
        idEntity,
        active.id,
        firebase.firestore.Timestamp.now(),
        `Expediente reasignado a ${valuesForm.addressee.name}`,
        id,
        username,
        false
      );

      if (ok && okMil) {
        fromFileModule
          ? dispatch(setActiveFuidFileModule(active))
          : dispatch(setActiveFuid(active));
        dispatch(uiCloseModalAction());
        dispatch(uiCloseModalSee());
        dispatch(setSuccessMsg("SettledReassigned"));
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(setErrorMsg("ErrorReassignSettled"));
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};
export const setActiveFileModuleTransfer = (transfer: ITransfer | null) => ({
  type: Types.deleteDocumentsFuid,
  payload: transfer,
});

export const startRejectTransfer = (
  transfer: ITransfer,
  fromFileModule: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    if (user && user.idEntity) {
      const { idTransfer } = transfer;
      transfer.rejectedDate = firebase.firestore.Timestamp.now();
      transfer.submitDate = null;
      transfer.senderTransferUser = null;
      //actualizar la transferencia de gestion documental
      const { ok: okEdit } = await addTransferDb(user.idEntity, transfer);

      const { ok: okDelete } = await deleteTransferDb(
        user.idEntity,
        idTransfer,
        fromFileModule
      );

      if (okEdit && okDelete) {
        dispatch(uiCloseModalReject());
        dispatch(setSuccessMsg("TransferRejected"));
        dispatch(setActiveTransfer(null));

        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};

export const startLoadLoans = (idEntity: string) => {
  return async (dispatch: Function) => {
    startListenerLoansByEntity(idEntity, dispatch);
  };
};
export const loadLoans = (loans: ILoan[]): IFileModuleAction => ({
  type: Types.loadLoans,
  payload: loans,
});
export const setActiveLoan = (loan: any): IFileModuleAction => ({
  type: Types.setActiveLoan,
  payload: loan,
});

export const startSetLoan = (loan: ILoan, edit: boolean, intl: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    const { expedients } = (getState() as AppState).fileModule;
    if (user && user.idEntity) {
      const { idExpedient } = loan;

      //Agregar el prestamo a la db
      if (!edit) {
        loan.id = uuid();
      }

      //mandar la copia del expediente que solicita el usuario
      const expedientFind = expedients.find((exp) => exp.id === idExpedient);
      if (!expedientFind) return;

      const lastCopySender = expedientFind.copyTo;
      const finalUsers = lastCopySender
        ? [
            ...lastCopySender,
            {
              id: loan.officer.id,
              submitDate: firebase.firestore.Timestamp.now(),
              userName: loan.officer.name,
              uploadPermission: false,
            },
          ]
        : [
            {
              id: loan.officer.id,
              submitDate: firebase.firestore.Timestamp.now(),
              userName: loan.officer.name,
              uploadPermission: false,
            },
          ];

      const objsSendCopyExpedient: ISendCopyExpedient = {
        idExpedient: expedientFind.id,
        users: finalUsers,
      };

      if (
        !edit &&
        lastCopySender &&
        lastCopySender.find((us) => us.id === loan.officer.id)
      ) {
        return Swal.fire({
          title: "Error",
          icon: "error",
          timer: 2000,
          text: `${intl.formatMessage({ id: "AlreadySendCopy" })}`,
          showConfirmButton: false,
          customClass: {
            container: "swal-container-z-index",
          },
        });
      }

      const { ok: okLoan } = await setLoan(user.idEntity, loan);

      if (!okLoan)
        return Swal.fire({
          title: "Error",
          icon: "error",
          timer: 2000,
          text: `${intl.formatMessage({ id: "SavingDataError" })}`,
          showConfirmButton: false,
          customClass: {
            container: "swal-container-z-index",
          },
        });
      if (!edit) {
        await dispatch(
          startsendExpedientCopy(objsSendCopyExpedient, loan.officer, true)
        );
      }
      dispatch(uiCloseModalAdd());
      dispatch(uiCloseModalEdit());
      dispatch(setActiveLoan(null));
      Swal.fire({
        title: `${intl.formatMessage({ id: "Success" })}`,
        icon: "success",
        timer: 2000,
        text: !edit
          ? `${intl.formatMessage({ id: "LoanSended" })}`
          : `${intl.formatMessage({ id: "EditLoan" })}`,
        showConfirmButton: false,
        customClass: {
          container: "swal-container-z-index",
        },
      });
    }
  };
};

export const startDeleteLoan = (loan: ILoan, intl: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    const { expedients } = (getState() as AppState).fileModule;
    if (user && user.idEntity) {
      //encontrar el expediente
      const findExp = expedients.find((exp) => exp.id === loan.idExpedient);
      if (!findExp) return;

      //eliminar al usuario que se le mando la copia
      findExp.copyTo = findExp.copyTo?.filter(
        (cop) => cop.id !== loan.officer.id
      );
      findExp.copyToIds = findExp.copyToIds?.filter(
        (cop) => cop !== loan.officer.id
      );
      //actualizar el exp
      const { ok: okFuid } = await addNewFuid(user.idEntity, findExp, true);
      //eliminar el prestamo
      const { ok: okLoan } = await deleteLoan(user.idEntity, loan.id);
      if (!okFuid || !okLoan) {
        return Swal.fire({
          title: "Error",
          icon: "error",
          timer: 2000,
          text: `${intl.formatMessage({ id: "DeleteError" })}`,
          showConfirmButton: false,
          customClass: {
            container: "swal-container-z-index",
          },
        });
      }
      Swal.fire({
        title: `${intl.formatMessage({ id: "LoanDeleted" })}`,
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
        customClass: {
          container: "swal-container-z-index",
        },
      });
    }
  };
};

// Devolver préstamo
export const startReturnLoan = (
  loan: ILoan,
  intl?: any,
  isAutomatic?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;
    const { expedients } = (getState() as AppState).fileModule;
    loan.state = Enum_LoanState.Returned;
    loan.returnDate = firebase.firestore.Timestamp.now();

    const findExp = expedients.find((exp) => exp.id === loan.idExpedient);
    if (!findExp) return;

    //eliminar al usuario que se le mando la copia
    findExp.copyTo = findExp.copyTo?.filter(
      (cop) => cop.id !== loan.officer.id
    );
    findExp.copyToIds = findExp.copyToIds?.filter(
      (cop) => cop !== loan.officer.id
    );

    const { ok: ok1 } = await setLoan(idEntity!, loan);

    //actualizar el exp
    const { ok: ok2 } = await addNewFuid(idEntity, findExp, true);
    if (!isAutomatic) {
      // Swal.fire({
      //   title:`${intl.formatMessage({ id: "Success" })}`,
      //   icon:  "success" ,
      //   text:`${intl.formatMessage({ id: "ReturnedDateLoan" })}`,
      //   showConfirmButton: false,
      //   timer: 2000,
      //   customClass: {
      //     container: "swal-container-z-index",
      //   },
      // });
      Swal.fire({
        title:
          ok1 && ok2
            ? `${intl.formatMessage({ id: "Success" })}`
            : `${intl.formatMessage({ id: "ErrorReturningFile" })}`,
        icon: ok1 && ok2 ? "success" : "error",
        text: ok1 && ok2 ? `${intl.formatMessage({ id: "FileReturned" })}` : "",
        showConfirmButton: false,
        timer: 5000,
        customClass: {
          container: "swal-container-z-index",
        },
      });
    }
  };
};

// AGREGAR ANOTACION PARA EXPEDIENTES
export const startUploadFuidAnnotations = (
  values: ICreateAnnotationForm,
  fromModule: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const closeErrorAnnotation = async () => {
      dispatch(setErrorMsg("ErrorToCreateAnnotation"));
      dispatch(uiCloseModalAction());
      dispatch(uiOpenErrorAlert());
    };

    const { user } = (getState() as AppState).auth;
    const { activeExpedient: FuidDocumentManagement } = (getState() as AppState)
      .fuid;
    const { activeExpedientFileModule: FuidFilesModule } = (
      getState() as AppState
    ).fileModule;
    const expedient = fromModule ? FuidFilesModule : FuidDocumentManagement;
    if (expedient) {
      expedient.documents = expedient.documents ? expedient.documents : [];
    }
    const id = Date.now();
    const date = firebase.firestore.Timestamp.now();

    if (user && user?.idEntity && user.username && expedient) {
      let fuidAnnotatiosFiles: IFiles[] = [];
      for (const file of values.attachedDocuments) {
        if (file.file) {
          const resp = await uploadFileAsync(
            file.file,
            `/FuidAnnotations/${user.idEntity}/${file.fileName}`
          );
          const obj = { fileName: file.fileName, url: resp };
          fuidAnnotatiosFiles.push(obj);
        }
        file.url !== "#" && fuidAnnotatiosFiles.push(file);
      }

      const selectUser: IUserCorrespondence = {
        id: user.id.toString(),
        name: user.username,
      };
      // OBJETO DOCUMENTO PARA AGREGAR A EXPEDIENTE
      fuidAnnotatiosFiles.forEach((file) => {
        const objAppenDoc: IDocumentsFuid = {
          url: file.url,
          fileName: file.fileName,
          description: values.description,
          documentalType: null,
          officer: selectUser,
          trdCode: null,
          uploadDate: firebase.firestore.Timestamp.now(),
        };
        expedient.documents?.push(objAppenDoc);
      });
      const module = fromModule ? "FileModule" : "Correspondence";
      // AGREGAR DOCUMENTOS SI HAY O NO EXPEDIENTE ASIGANDO A LA CORRESPONDENCIA
      if (expedient.documents) {
        const { ok } = await addAttachedDocumentExpedientDb(
          module,
          expedient.id,
          expedient.documents,
          user.idEntity
        );
        if (ok) {
          dispatch(setActiveFuid(expedient));
        }
      }
      const addFuidAnnotationNew: ICorresAnnotations = {
        attachedFiles: fuidAnnotatiosFiles,
        description: values.description,
        id: id.toString() + "_" + user.id.toString(),
        user: selectUser,
        createDate: date,
      };
      const { ok } = await addFuidAnnotationsDb(
        user.idEntity,
        expedient.id,
        addFuidAnnotationNew,
        fromModule
      );
      if (ok) {
        fromModule
          ? await dispatch(addFuidAnnotation(addFuidAnnotationNew))
          : await dispatch(
              addFuidDocumentManagementAnnotation(addFuidAnnotationNew)
            );
        dispatch(setSuccessMsg("CreatedCommunication"));
        dispatch(uiCloseModalAction());
        dispatch(uiOpenSuccessAlert());
      } else {
        closeErrorAnnotation();
      }
    } else {
      closeErrorAnnotation();
    }
  };
};

// AGREGAR ANOTACION A REDUX
export const addFuidAnnotation = (
  fuidAnnotation: ICorresAnnotations
): IFileModuleAction => ({
  type: Types.addFuidAnnotation,
  payload: fuidAnnotation,
});

export const addFuidDocumentManagementAnnotation = (
  fuidAnnotation: ICorresAnnotations
) => ({
  type: Types.addFuidDocumentManagementAnnotation,
  payload: fuidAnnotation,
});

export const loadFuidAnnotations = (fuidAnnotations: ICorresAnnotations[]) => ({
  type: Types.loadAllFuidAnnotations,
  payload: fuidAnnotations,
});

export const setActiveFuidAnnotation = (
  active: ICorresAnnotations | null
): IFileModuleAction => ({
  type: Types.setActiveFuidAnnotation,
  payload: active,
});

export const startDeleteFuidAnnotation = (
  annotation: ICorresAnnotations,
  fromFileModule: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    const { activeExpedient: fuidDocumentManagement } = (getState() as AppState)
      .fuid;
    const { activeExpedientFileModule: fuidFileModule } = (
      getState() as AppState
    ).fileModule;

    const expedient = fromFileModule ? fuidFileModule : fuidDocumentManagement;

    if (user && user.idEntity && expedient) {
      const { ok: ok1 } = await deleteFuidAnnotationDB(
        user.idEntity,
        expedient.id,
        annotation.id,
        fromFileModule
      );

      if (annotation.attachedFiles[0] !== undefined) {
        const { ok: ok2 } = await deleteFuidAnnotationDBDoc(
          user.idEntity,
          annotation.attachedFiles[0].fileName
        );
        if (ok1 && ok2) {
          await dispatch(setSuccessMsg("DeletedAnnotation"));

          await dispatch(deleteFuidAnnotationReducer(annotation.id));

          await dispatch(uiCloseModalAction());
          await dispatch(uiOpenSuccessAlert());
        } else {
          await dispatch(setErrorMsg("DeletingError"));
          await dispatch(uiCloseModalAction());
          await dispatch(uiOpenErrorAlert());
        }
      }
    }
  };
};

const deleteFuidAnnotationReducer = (idFuidAnnotation: string) => ({
  type: Types.deleteFuidAnnotation,
  payload: idFuidAnnotation,
});

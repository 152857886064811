import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
} from "@material-ui/core";
import Axios from "axios";
import { Form, Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { useStyles } from "../../shared/styles/useStyles";
import * as yup from "yup";
import { MyTextField } from "../custom/MyTextField";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import {
  getEntity,
  updateEntity as bdUpdateEntity,
  validateEmailInMailjet,
} from "../../services/firebase/entities";
import { MyAlert } from "../custom/MyAlert";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";
import { Entity } from "../../interfaces/Users";
import { updateCredentialsEntity } from "../../services/firebase/entities";
import { TypeEntity } from "../../enums/enums";
import {
  editEntity,
  purgeActiveEntity,
  startAddEntity,
} from "../../redux/actions/entitiesActions";
import {
  uiCloseModalAdd,
  uiCloseModalEdit,
  uiOpenErrorAlert,
  uiOpenSuccessAlert,
} from "../../redux/actions/uiActions";
import { registerWithEmailPassword } from "../../services/firebase/auth";
import { uiCloseErrorAlert, setErrorMsg } from "../../redux/actions/uiActions";
import { ICityData, ICountryData, IStateData } from "../../interfaces/Apis";

interface EntityFormProps {
  edit?: boolean;
}

interface Icredentials {
  email: string;
  password: string;
}

export const FormEntity: FC<EntityFormProps> = ({ edit = false }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { errorAlert, errorMsg } = useSelector((state: AppState) => state.ui);
  const [countries, setCountries] = useState<ICountryData[]>([]);
  const [countrySelected, setCountrySelected] = useState("");
  const [departmentSelected, setDepartmentSelected] = useState<string>("");
  const [departments, setDepartments] = useState<IStateData[]>([]);
  const [townSelected, setTownSelected] = useState("");
  const [filteredTowns, setFilteredTowns] = useState<ICityData[]>([]);
  const { activeEntity } = useSelector((state: AppState) => state.entities);
  const [oldCredentials, setOldCredentials] = useState<Icredentials | null>(
    null
  );
  const [showExistAlert, setShowExistAlert] = useState(false);
  const [entityTypeSelected, setEntityTypeSelected] = useState<TypeEntity>(
    TypeEntity.PUBLIC
  );
  const [tokenApi, setTokenApi] = useState<string | null>(null);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState<Partial<Entity>>({
    businessName: "",
    nit: "",
    address: "",
    phone: "",
    department: "", //CONSULYTAR AL API LOS departmentS Y SELECCIONAR ANTIOQUIA POR DEFECTO
    town: "",
    sigepCode: "",
    daneCode: "",
    firtsName: "",
    secondName: "",
    surname: "",
    secondSurname: "",
    email: "",
    emailSender: "comercial@appsus.co",
    id: "",
    country: "",
  });

  const validationSchema = yup.object({
    businessName: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    nit: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`)
      .min(8, `${intl.formatMessage({ id: "MinimumPassword" })}`),
    address: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    phone: yup
      .number()
      .typeError(`${intl.formatMessage({ id: "NumericValue" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    firtsName: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    surname: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    email: yup
      .string()
      .email(`${intl.formatMessage({ id: "InvalidEmail" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    emailSender: yup
      .string()
      .email(`${intl.formatMessage({ id: "InvalidEmail" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    id: yup
      .string()
      .typeError(`${intl.formatMessage({ id: "NumericValue" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`)
      .min(5, `${intl.formatMessage({ id: "MinimumDocument" })}`),
  });
  useEffect(() => {
    //obtener auth token
    Axios.get("https://www.universal-tutorial.com/api/getaccesstoken", {
      headers: {
        Accept: "application/json",
        "api-token":
          "YzDgxWedn4ChtdDYADAmE7swHAWpPcM9wwC8uipVHtxxka9_73hlghotMZGJ7XXSiw0",
        "user-email": "desarrollos.appsus@gmail.com",
      },
    })
      .then((response) => {
        setTokenApi(response.data.auth_token);
        return response.data.auth_token;
      })
      .then((token) => {
        Axios.get("https://www.universal-tutorial.com/api/countries/", {
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
          },
        })
          .then((response) => {
            setCountries(response.data);
            return response.data;
          })
          .then(async (data) => {
            if (edit && activeEntity) {
              const { department, town, email, id, country } = activeEntity;
              setEntityTypeSelected(activeEntity.entityType);
              setDepartmentSelected(department);
              setCountrySelected(country);
              setTownSelected(town);

              await setInitialValues({ ...activeEntity });
              setOldCredentials({ email, password: id });
            } else {
              setCountrySelected("Colombia");
              setEntityTypeSelected(TypeEntity.PUBLIC);
            }
          });
      });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (departmentSelected.length > 0 && tokenApi) {
      Axios.get(
        `https://www.universal-tutorial.com/api/cities/${departmentSelected}`,
        {
          headers: {
            Authorization: "Bearer " + tokenApi,
            Accept: "application/json",
          },
        }
      ).then(async (response) => {
        const { data } = response;
        await setFilteredTowns(data);
        if (!edit) {
          setTownSelected(data[0].municipio);
        }
      });
    }
  }, [departmentSelected, edit, tokenApi]);
  useEffect(() => {
    if (countrySelected.length > 0 && tokenApi) {
      //BUSCAR LOS DEPARTAMENTOS DEL PAIS
      Axios.get(
        `https://www.universal-tutorial.com/api/states/${countrySelected}`,
        {
          headers: {
            Authorization: "Bearer " + tokenApi,
            Accept: "application/json",
          },
        }
      ).then(async (response) => {
        const { data } = response;
        await setDepartments(data);
        if (!edit) {
          setDepartmentSelected(data[0].state_name);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countrySelected]);

  const handleCreateEntity = async (entity: Entity) => {
    const { email, id, nit, emailSender } = entity;
    try {
      const isMailInMailjet = await validateEmailInMailjet(emailSender);
      if (isMailInMailjet) {
        if (edit && oldCredentials) {
          const { email: oldEmail, password: oldPassword } = oldCredentials;

          if (id !== oldPassword) {
            const { localId } = await updateCredentialsEntity(
              oldEmail,
              oldPassword.toString(),
              id.toString()
            );

            await bdUpdateEntity(entity, localId);
          } else {
            entity.uid = activeEntity ? activeEntity.uid : "";
            await bdUpdateEntity(entity, entity.uid);
          }
          dispatch(editEntity({ ...entity }, oldPassword));
        } else {
          const existsEntity = await getEntity(nit);

          if (existsEntity) return setShowExistAlert(true);
          else {
            const response = await registerWithEmailPassword(
              email,
              id.toString()
            );
            dispatch(
              startAddEntity({ ...entity, active: true }, response.localId)
            );
          }
        }
        await dispatch(uiOpenSuccessAlert());
        dispatch(purgeActiveEntity());
        dispatch(uiCloseModalAdd());
        dispatch(uiCloseModalEdit());
      } else {
        dispatch(setErrorMsg("SenderIsNotInMailjet"));
        dispatch(uiOpenErrorAlert());
      }
    } catch (error) {
      dispatch(uiOpenErrorAlert());
    }
  };

  const onClose = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    dispatch(purgeActiveEntity());
  };

  return (
    <Box m={1}>
      <Formik
        enableReinitialize
        validateOnChange
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          values.department = departmentSelected;
          values.town = townSelected;
          values.entityType = entityTypeSelected;
          values.country = countrySelected;
          await handleCreateEntity(values as Entity);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="BusinessName" />*
                </label>
                <MyTextField
                  name="businessName"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="Nit" />*
                </label>
                <MyTextField
                  name="nit"
                  variant="outlined"
                  disabled={edit}
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="Address" />*
                </label>
                <MyTextField
                  name="address"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="Phone" />*
                </label>
                <MyTextField
                  name="phone"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="Country" />
                </label>
                <TextField
                  size="small"
                  name="country"
                  variant="outlined"
                  select
                  disabled={edit}
                  value={countrySelected || ""}
                  onChange={(e: any) => setCountrySelected(e.target.value)}
                  className={classes.myTextFieldRoot}
                  autoComplete="off"
                >
                  {countries.map((country) => (
                    <MenuItem
                      key={`${country.country_phone_code}-${country.country_short_name}`}
                      value={country.country_name}
                    >
                      <p>{country.country_name}</p>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="Department" />
                </label>
                <TextField
                  size="small"
                  name="department"
                  variant="outlined"
                  select
                  disabled={edit}
                  value={departmentSelected || ""}
                  onChange={(e: any) => setDepartmentSelected(e.target.value)}
                  className={classes.myTextFieldRoot}
                  autoComplete="off"
                >
                  {departments.map((department) => (
                    <MenuItem
                      key={department.state_name}
                      value={department.state_name}
                    >
                      <p>{department.state_name}</p>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="Town" />
                </label>
                <TextField
                  size="small"
                  name="town"
                  variant="outlined"
                  select
                  disabled={edit}
                  value={townSelected || ""}
                  onChange={(e: any) => setTownSelected(e.target.value)}
                  className={classes.myTextFieldRoot}
                >
                  {filteredTowns.map((town) => (
                    <MenuItem key={town.city_name} value={town.city_name}>
                      <p>{town.city_name}</p>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="SigepCode" />
                </label>
                <MyTextField
                  name="sigepCode"
                  variant="outlined"
                  disabled={edit}
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="DaneCode" />
                </label>
                <MyTextField
                  name="daneCode"
                  variant="outlined"
                  disabled={edit}
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="EmailForReceiveCommunications" />*
                </label>
                <MyTextField
                  name="emailSender"
                  variant="outlined"
                  // disabled={edit}
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="EntityType" />
                </label>
                <TextField
                  size="small"
                  name="entityType"
                  variant="outlined"
                  select
                  value={entityTypeSelected}
                  onChange={(e: any) => setEntityTypeSelected(e.target.value)}
                  className={classes.myTextFieldRoot}
                >
                  <MenuItem value={TypeEntity.PUBLIC}>
                    <p>{TypeEntity.PUBLIC}</p>
                  </MenuItem>
                  <MenuItem value={TypeEntity.PRIVATE}>
                    <p>{TypeEntity.PRIVATE}</p>
                  </MenuItem>
                </TextField>
              </Grid>

              {/* DATOS DEL ADMIN */}
              <Grid item xs={12}>
                <h4>{<FormattedMessage id="SystemAdmin" />}</h4>
                <Divider light />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="FirstName" />*
                </label>
                <MyTextField
                  name="firtsName"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="SecondName" />
                </label>
                <MyTextField
                  name="secondName"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="FirstLastName" />*
                </label>
                <MyTextField
                  name="surname"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="SecondLastName" />
                </label>
                <MyTextField
                  name="secondSurname"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="Email" />*
                </label>
                <MyTextField
                  name="email"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  disabled={edit}
                />
              </Grid>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="DocumentNumber" />*
                </label>
                <MyTextField
                  name="id"
                  variant="outlined"
                  type="number"
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Box
                mt={2}
                width={1}
                display="flex"
                justifyContent="flex-start"
                flexDirection="row-reverse"
              >
                {!isSubmitting ? (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id="Save" />
                  </Button>
                ) : (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="button"
                    disabled={true}
                  >
                    <CircularProgress className={classes.btnLoading} />
                  </Button>
                )}
                <Button
                  className={clsx(classes.btn, classes.cancel)}
                  onClick={onClose}
                >
                  <FormattedMessage id="Cancel" />
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>

      <Box mt={3}>
        <MyAlert
          open={showExistAlert}
          typeAlert="error"
          message="ExistsEntity"
          time={3000}
          handleClose={() => setShowExistAlert(false)}
        />

        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message="SavingDataError"
          time={2000}
          handleClose={() => dispatch(uiCloseErrorAlert())}
        />

        {errorMsg && (
          <MyAlert
            open={errorAlert}
            typeAlert="error"
            message={errorMsg}
            time={10000}
            handleClose={() => dispatch(uiCloseErrorAlert())}
          />
        )}
      </Box>
    </Box>
  );
};

import clsx from "clsx";
import { Formik, Form } from "formik";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { TypeDays } from "../../../enums/enums";
import { IParamProcedure } from "../../../redux/types/types";
import { useStyles } from "../../../shared/styles/useStyles";
import { MyTextField } from "../../custom/MyTextField";
import {
  uiCloseModalAdd,
  uiCloseModalEdit,
} from "../../../redux/actions/uiActions";
import {
  startNewProcedure,
  setActiveProcedure,
} from "../../../redux/actions/correspondenceActions";
import { AppState } from "../../../redux/reducers/rootReducer";

export const ProceduresForm = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { activeParamProcedure: active } = useSelector(
    (state: AppState) => state.correspondence
  );

  const validationSchema = yup.object({
    id: yup.string(),
    procedureName: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    responseTime: yup
      .number()
      .min(1, `${intl.formatMessage({ id: "PositiveNumber" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    typeDays: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  let initialValues: IParamProcedure = {
    id: active ? active.id : "",
    procedureName: active ? active.procedureName : "",
    responseTime: active ? active.responseTime : 0,
    typeDays: active ? active.typeDays : TypeDays.BUSINESSDAYS,
  };

  const onClose = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    dispatch(setActiveProcedure(null));
  };

  return (
    <Box m={1}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          await dispatch(startNewProcedure(data));
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleChange, values }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="NameProcedure" />*
                </label>
                <MyTextField
                  name="procedureName"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="ResponseTime" />*
                </label>
                <MyTextField
                  name="responseTime"
                  type="number"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <RadioGroup
                  name="typeDays"
                  value={values.typeDays}
                  onChange={handleChange}
                >
                  <Box
                    mt={3}
                    display="flex"
                    style={{
                      fontSize: 12,
                      fontFamily: "Poppins",
                    }}
                  >
                    <FormControlLabel
                      value={TypeDays.BUSINESSDAYS}
                      control={<Radio color="primary" />}
                      label={`${intl.formatMessage({ id: "BusinessDays" })}`}
                    />
                    <FormControlLabel
                      value={TypeDays.CALENDAR}
                      control={<Radio color="primary" />}
                      label={`${intl.formatMessage({ id: "Calendar" })}`}
                    />
                  </Box>
                </RadioGroup>
              </Grid>
            </Grid>

            <Box mt={2} display="flex" flexDirection="row-reverse">
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="Save" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

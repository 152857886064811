import { TypeDays } from "../../enums/enums";
import { ITenders } from "../../interfaces/Correspondence";
import { ICorrespondence } from "../../interfaces/Correspondence";
import {
  IContractingOffice,
  IMilestones,
  IParamProcedure,
  IParamResponseTime,
} from "../types/types";
import {
  IAnnotation,
  ICorrespondenceActions,
  ICorrespondenceState,
  Types,
  IAppendDocsSend,
} from "../types/types";

const initialState: ICorrespondenceState = {
  activeAnnotation: null,
  activeParamProcedure: null,
  annotations: [],
  milestones: [],
  contractingOffice: null,
  paramResponseTime: {
    RightOfPetitionDays: 1,
    RightOfPetitionTypeDays: TypeDays.CALENDAR,
    ComplaintDays: 1,
    ComplaintTypeDays: TypeDays.CALENDAR,
    ClaimDays: 1,
    ClaimTypeDays: TypeDays.CALENDAR,
    SuggestionDays: 1,
    SuggestionTypeDays: TypeDays.CALENDAR,
    GuardianshipDays: 1,
    GuardianshipTypeDays: TypeDays.CALENDAR,
    RequestForInformationDays: 1,
    RequestForInformationTypeDays: TypeDays.CALENDAR,
    PetitionDays: 1,
    PetitionTypeDays: TypeDays.CALENDAR,
  },
  paramsProcedure: [],
  correspondence: [],
  activeCorrespondence: null,
  tenders: [],
  activeTender: null,
  corresAnnotations: [],
  answers: [],
  productionOfficeParam: null,
  labelSize: null,
};
export const correspondenceReducer = (
  state = initialState,
  action: ICorrespondenceActions
): ICorrespondenceState => {
  const { type, payload } = action;
  switch (type) {
    case Types.loadAnnotations:
      return {
        ...state,
        annotations: [...(payload as IAnnotation[])],
      };
    case Types.setAnnotation:
      const allAnnotations = [...state.annotations];
      const indexAnn = allAnnotations.findIndex(
        (el) => el.id === (payload as IAnnotation).id
      );
      if (indexAnn >= 0) {
        allAnnotations[indexAnn] = payload as IAnnotation;
        return {
          ...state,
          annotations: [...allAnnotations],
        };
      }
      return {
        ...state,
        annotations: [payload as IAnnotation, ...state.annotations],
      };
    case Types.setActiveAnnotation:
      return {
        ...state,
        activeAnnotation: payload as any,
      };
    case Types.deleteAnnotation:
      return {
        ...state,
        annotations: state.annotations.filter(
          (annotation) => annotation.id !== payload
        ),
      };
    case Types.deleteCorrespondence:
      return {
        ...state,
        correspondence: state.correspondence.filter(
          (correspondences) => correspondences.id !== payload
        ),
      };

    // ****************   PARAMETRIZACIÓN    ******************
    case Types.loadParamsProcedure:
      return {
        ...state,
        paramsProcedure: [...(payload as IParamProcedure[])],
      };

    case Types.addProcedure:
      return {
        ...state,
        paramsProcedure: [payload as IParamProcedure, ...state.paramsProcedure],
      };
    case Types.purgeCorrespondence:
      return initialState;

    case Types.setActiveProcedure:
      return {
        ...state,
        activeParamProcedure: payload as IParamProcedure | null,
      };

    case Types.updateProcedure:
      return {
        ...state,
        paramsProcedure: state.paramsProcedure.map((procedure) =>
          procedure.id === (payload as IParamProcedure).id
            ? (payload as IParamProcedure)
            : procedure
        ),
      };

    case Types.deleteProcedure:
      return {
        ...state,
        paramsProcedure: state.paramsProcedure.filter(
          (el) => el.id !== payload
        ),
      };

    // ****************   PARAMETRIZACIÓN - tiempos de respuesta    ******************
    case Types.loadParamsResponseTime:
      return {
        ...state,
        paramResponseTime: { ...(payload as IParamResponseTime) },
      };

    // -------------------------------- CORRESPONDENCE -----------------------
    case Types.loadCorrespondences:
      return {
        ...state,
        correspondence: [...(payload as ICorrespondence[])],
      };
    case Types.setCorrespondence:
      const allCorrespondences = [...state.correspondence];
      const indexCorres = allCorrespondences.findIndex(
        (el) => el.id === (payload as ICorrespondence).id
      );
      if (indexCorres >= 0) {
        allCorrespondences[indexCorres] = payload as ICorrespondence;
        return {
          ...state,
          correspondence: [...allCorrespondences],
        };
      }
      return {
        ...state,
        correspondence: [payload as ICorrespondence, ...state.correspondence],
      };
    case Types.setActiveCorrespondence:
      return {
        ...state,
        activeCorrespondence: payload as any,
      };

    case Types.loadMilestones:
      return {
        ...state,
        milestones: [...(payload as IMilestones[])],
      };
    case Types.loadContractingOffice:
      return {
        ...state,
        contractingOffice: payload as IContractingOffice,
      };
    // -------------------------------- Tenders -----------------------
    case Types.loadTenders:
      return {
        ...state,
        tenders: [...(payload as ITenders[])],
      };
    case Types.setTender:
      const allTenders = [...state.tenders];
      const indexTender = allTenders.findIndex(
        (el) => el.id === (payload as ITenders).id
      );
      if (indexTender >= 0) {
        allTenders[indexTender] = payload as ITenders;
        return {
          ...state,
          tenders: [...allTenders],
        };
      }
      return {
        ...state,
        tenders: [payload as ITenders, ...state.tenders],
      };

    case Types.setActiveTender:
      return {
        ...state,
        activeTender: payload as ITenders | null,
      };

    // Attached Document to Correspondence
    case Types.attachedDocumentCorrespondence:
      const { idCorrespondence, appendDocs } = payload as IAppendDocsSend;
      const attachedDocumentCorrespondence = [...state.correspondence];
      const indexCorrespondence = attachedDocumentCorrespondence.findIndex(
        (cor) => cor.id === idCorrespondence
      );
      attachedDocumentCorrespondence[indexCorrespondence].appendDocs =
        appendDocs;

      return {
        ...state,
        correspondence: attachedDocumentCorrespondence,
      };
    case Types.setProductionOfficeParam:
      return {
        ...state,
        productionOfficeParam: payload as string,
      };

    case Types.setLabelSize:
      return {
        ...state,
        labelSize: payload as number,
      };
    default:
      return state;
  }
};

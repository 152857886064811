import React from "react";
import { Box } from "@material-ui/core";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import { ParameterizationMangTabs } from "../../components/document-managment/parameterization/ParameterizationMangTabs";

export const ParameterizationManagmentScreen = () => {
  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box>
      <ParameterizationMangTabs/>
      </Box>
    </AppAnimate>
  );
};

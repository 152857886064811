import { Provider } from "react-redux";
import { store } from "./store/store";
import { IntlProvider } from "react-intl";
import messages_es from "./translations/es.json";
import messages_en from "./translations/en.json";
import { AppRouter } from "./router/AppRouter";
import "./app.scss";

const App = () => {
  const currentLocale = navigator.language.split(/[-_]/)[0];
  const messages = currentLocale === "es" ? messages_es : messages_en;

  return (
    <Provider store={store}>
      <IntlProvider locale={currentLocale} messages={messages}>
        <AppRouter />
      </IntlProvider>
    </Provider>
  );
};

export default App;

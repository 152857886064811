import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import { FileCopy, Delete } from "@material-ui/icons";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useStyles } from "../../../../shared/styles/useStyles";
import { MyTextField } from "../../../custom/MyTextField";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  setSuccessMsg,
  uiCloseModalAttachDocuments,
  uiOpenErrorAlert,
  uiOpenSuccessAlert,
} from "../../../../redux/actions/uiActions";
import {
  setActiveTrdVersion,
  startDeleteSupportDocument,
  startUploadSupportDocuments,
} from "../../../../redux/actions/trdActions";
import { IFiles } from "../../../../redux/types/types";
import { Enum_FileSize } from "../../../../enums/enums";

export const AttachDocuments = () => {
  const [noValid, setNoValid] = useState(false);
  const [noValidSize, setNoValidSize] = useState(false);
  const classes = useStyles();
  const [labelImage, setLabelImage] = useState("");
  const dispatch = useDispatch();
  const { activeVersion } = useSelector((state: AppState) => state.trd);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [fileNames, setFileNames] = useState<IFiles[]>([]);
  const intl = useIntl();

  const handleSelectFile = (e: any) => {
    const SUPPORTED_FORMATS = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/png",
      "application/msword",
      "text/csv",
      "application/pdf",
    ];
    const file = e.target.files[0] as File;
    const now = new Date().getTime().toString();
    if (file) {
      if (file.size > Enum_FileSize.maxAllowedSize) {
        setNoValidSize(true);
      } else {
        if (SUPPORTED_FORMATS.includes(file.type)) {
          setNoValid(false);

          setFileNames((prevState) => [
            { url: "#", fileName: now + "_" + file.name, file },
            ...prevState,
          ]);
          setLabelImage(e.target.files[0].name);
        } else {
          setNoValid(true);
        }
      }
    } else {
      setNoValid(false);
      setNoValidSize(false);
      setLabelImage("");
    }
  };
  useEffect(() => {
    if (
      activeVersion &&
      activeVersion.supportDocs &&
      activeVersion.supportDocs.length > 0
    ) {
      setFileNames(activeVersion.supportDocs);
    }
    // eslint-disable-next-line
  }, []);
  const handleDeleteFile = async (fileName: string, db: boolean) => {
    if (activeVersion) {
      setFiles((prevState) => prevState.filter((fil) => fil.name !== fileName));
      setFileNames((prevState) =>
        prevState.filter((fil) => fil.fileName !== fileName)
      );
      if (db) {
        setIsLoading(true);
        await dispatch(
          startDeleteSupportDocument(activeVersion.idVersion, fileName)
        );
        setIsLoading(false);
      }
    }
  };
  const onClose = () => {
    setLabelImage("");
    setFileNames([]);
    dispatch(setActiveTrdVersion(null));
    dispatch(uiCloseModalAttachDocuments());
  };
  const handleUploadSupportDocs = async () => {
    try {
      if (!noValid && files.length > 0) {
        setIsLoading(true);
        await dispatch(startUploadSupportDocuments(files));
        dispatch(uiOpenSuccessAlert());
        dispatch(setSuccessMsg("UploadedSupportDocuments"));
        setIsLoading(false);
      } else {
      }
    } catch (error) {
      dispatch(uiOpenErrorAlert());
    }
  };

  // ARCHIVOS SOPORTADOS PARA INPUT TYPE=FILE
  const fileSupported = {
    accept:
      "application/pdf, image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv",
  };

  return (
    <Formik
      validateOnChange={true}
      initialValues={files}
      //validationSchema={validationSchema}
      onSubmit={async (data, { setSubmitting }) => {}}
    >
      {({ isSubmitting }) => (
        <Box m={1}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <label className="form-text">
                <FormattedMessage id="AttachFile" />
              </label>

              <TextField
                size="small"
                type="file"
                inputProps={fileSupported}
                onChange={handleSelectFile}
                id="icon-button-file"
                autoComplete="off"
                style={{ display: "none" }}
              />

              <MyTextField
                disabled={true}
                variant="outlined"
                style={{ color: "black" }}
                label={labelImage}
                value={labelImage}
                name="file"
                className={classes.myTextFieldRoot}
              />
            </Grid>
            <Grid item xs={6}>
              <Box mt={3} ml={-2}>
                <Tooltip title={`${intl.formatMessage({ id: "AttachFile" })}`}>
                  <label htmlFor="icon-button-file">
                    <IconButton component="span">
                      <FileCopy />
                    </IconButton>
                  </label>
                </Tooltip>
              </Box>
            </Grid>
            {noValid && (
              <Grid item xs={12}>
                <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                  <FormattedMessage id="ValidFiles" />
                </Box>
              </Grid>
            )}
            {noValidSize && (
              <Grid item xs={12}>
                <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                  <FormattedMessage id="ValidDocumentalFilesSize" />
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <label className="form-text">
                <FormattedMessage id="SupportDocuments" />:
              </label>
            </Grid>
            {files.length <= 0 && (
              <Grid item xs={12}>
                <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                  <p>
                    {activeVersion &&
                    activeVersion.supportDocs &&
                    activeVersion.supportDocs.length > 0 ? (
                      <FormattedMessage id="NoNewFilesToUpload" />
                    ) : (
                      <FormattedMessage id="NoDocumentsAttached" />
                    )}
                  </p>
                </Box>
              </Grid>
            )}
            {fileNames.map(({ fileName, url }) => {
              return (
                <Grid container key={fileName}>
                  <Grid item xs={6} key={fileName}>
                    <Box style={{ fontSize: 12, color: "blue" }}>
                      <a href={url} target="__blank">
                        {fileName}
                      </a>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Tooltip title={`${intl.formatMessage({ id: "Delete" })}`}>
                      <IconButton
                        onClick={() => handleDeleteFile(fileName, url !== "#")}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>

          <Box
            mt={2}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <Button
              className={clsx(classes.btn, classes.cancel)}
              onClick={onClose}
            >
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              className={clsx(classes.btn, classes.save)}
              autoFocus
              type="submit"
              disabled={isLoading}
              onClick={handleUploadSupportDocs}
            >
              {!isLoading ? (
                <FormattedMessage id="Save" />
              ) : (
                <CircularProgress className={classes.btnLoading} />
              )}
            </Button>
          </Box>
        </Box>
      )}
    </Formik>
  );
};

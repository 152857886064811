import { FormattedMessage } from "react-intl";

import { Box, Card } from "@material-ui/core";
import { LoginForm } from "../../components/auth/LoginForm";
import { Fonts } from "../../shared/constants/fonts/Fonts";
import { useStylesLogin } from "../../shared/styles/useStyles";
import logo from "../../assets/images/logo-1-01.png";
import { MyAlert } from "../../components/custom/MyAlert";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";
import { uiCloseErrorAlert } from "../../redux/actions/uiActions";

export const LoginScreen = () => {
  const classes = useStylesLogin();
  const dispatch = useDispatch();
  const { errorAlert, errorMsg } = useSelector((state: AppState) => state.ui);

  const handleClose = () => {
    dispatch(uiCloseErrorAlert());
  };

  return (
    <Box className={classes.appAuth}>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box
          m={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Card className={classes.cardRoot}>
            {<Box mb={1} textAlign="center">
              <img
                className={classes.imgRoot}
                src={logo}
                alt="Logo Encuestapp"
              />
            </Box>}
            <Box px={{ xs: 6, sm: 10, xl: 15 }}>
              <Box
                component="h2"
                color={"#495047"}
                fontWeight={Fonts.REGULAR}
                fontSize={{ xs: 24, xl: 26 }}
              >
                <FormattedMessage id="SignIn" />
              </Box>
            </Box>

            <LoginForm />
          </Card>
        </Box>
      </Box>
      {errorMsg && (
        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={errorMsg}
          time={4000}
          handleClose={handleClose}
        />
      )}
    </Box>
  );
};

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

import { CustomizedSearch } from "../../components/custom/CustomizedSearch";
import CustomizedDialog from "../../components/custom/CustomizedDialog";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import {
  uiOpenModalAdd,
  uiCloseModalAdd,
  uiCloseModalInfo,
  uiCloseSuccessAlert,
  uiCloseErrorAlert,
  uiCloseModalReject,
  uiCloseModalSee,
  uiCloseModalEdit,
} from "../../redux/actions/uiActions";
import { useStyles } from "../../shared/styles/useStyles";
import { AppState } from "../../redux/reducers/rootReducer";
import { TransfersTable } from "../../components/file-module/transfers/TransfersTable";
import { ApproveTransfer } from "../../components/file-module/transfers/ApproveTransfer";
import { uiCloseModalAssignModules } from "../../redux/actions/uiActions";
import { AddFilesTransferForm } from "../../components/file-module/transfers/AddFilesTransferForm";
import { TransfersForm } from "../../components/file-module/transfers/TransfersForm";
import { MyAlert } from "../../components/custom/MyAlert";
import { RejectTransfer } from "../../components/file-module/transfers/RejectTransfer";
import { TransferDetails } from "../../components/file-module/transfers/TransferDetails";
import { setActiveTransfer } from "../../redux/actions/documentManagmentActions";


export const TransfersScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const {
    modalAddOpen,
    modalEditOpen,
    modalInfoOpen,
    modalAssignModulesOpen,
    modalRejectOpen,
    successMsg,
    successAlert,
    errorAlert,
    modalSeeOpen
  } = useSelector((state: AppState) => state.ui);

  const { transfers } = useSelector((state: AppState) => state.fileModule);
  const onDeny = ()=>{
    dispatch(uiCloseModalAdd())
    dispatch(uiCloseModalReject())
    dispatch(uiCloseModalInfo())
    dispatch(uiCloseModalSee() ); 
    dispatch(uiCloseModalEdit())
    dispatch(uiCloseModalAssignModules())
    dispatch( setActiveTransfer(null) )


  }

  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box>
        <Box mb={3} fontSize={20}>
          <Grid container>
            <Grid item xs={6}>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                className={classes.btnAdd}
                type="button"
                onClick={() => dispatch(uiOpenModalAdd()) }
                  
                style={{ width: "23rem" }}
              >
                <AddOutlinedIcon />
                <FormattedMessage id="NewDocumentaryTransfer" />
              </Button>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <CustomizedSearch data={transfers} />
            </Grid>
          </Grid>
        </Box>

        <TransfersTable transfers={transfers} isFileModule />

        <CustomizedDialog
          open={modalAddOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "DocumentaryTransfer" })}`}
          content={<TransfersForm fromFilesModule />}
          textButton="Settle"
        />
        {/* aprobar transferencia */}
        <CustomizedDialog
          open={modalEditOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "UpdateTransfer" })}`}
          content={<TransfersForm edit fromFilesModule />}
          textButton="Accept"
        />
        <CustomizedDialog
          open={modalInfoOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "ApproveTransfer" })}`}
          content={<ApproveTransfer />}
          textButton="Settle"
        />
        <CustomizedDialog
          open={modalRejectOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "RejectTransfer" })}`}
          content={<RejectTransfer />}
          textButton="Settle"
        />
        {/* agregar expediente */}
        <CustomizedDialog
          open={modalAssignModulesOpen}
          cancelBtn={true}
         
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "AddFiles" })}`}
          content={<AddFilesTransferForm readonly  />}
          textButton="Settle"
        />
        <CustomizedDialog
          open={modalSeeOpen}
          cancelBtn={true}
        
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "TransferDetails" })}`}
          content={<TransferDetails />}
          textButton="Settle"
        />
        {successMsg && (
          <MyAlert
            open={successAlert}
            typeAlert="success"
            message={successMsg}
            time={2000}
            handleClose={() => dispatch(uiCloseSuccessAlert())}
          />
        )}

        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={"SavingDataError"}
          time={2000}
          handleClose={() => dispatch(uiCloseErrorAlert())}
        />
      </Box>
    </AppAnimate>
  );
};

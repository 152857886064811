import React from "react";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import { toggleNavCollapsed } from "../../../redux/actions/settingActions";
import Logo from "../Logo/Logo";
import useStyles from "./header.styles";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppState } from "../../../redux/reducers/rootReducer";
import { TypeUser } from "../../../enums/enums";
import { FormattedMessage } from "react-intl";

interface AppHeaderProps {
  moduleName: string;
}

const AppHeader: React.FC<AppHeaderProps> = ({ moduleName }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state: AppState) => state.auth);

  let nameModule!: string;

  switch (moduleName) {
    case "/filemodule/reports":
      nameModule = "FileModule";
      break;
    case "/documentmanagment/reports":
      nameModule = "DocumentManagment";
      break;
    case "/correspondence/reports":
      nameModule = "Correspondence";
      break;
    default:
      nameModule = "";
  }

  return (
    <>
      <AppBar color="inherit" className={clsx(classes.appBar, "app-bar")}>
        <Box display="flex" alignItems={"center"} alignContent="center">
          <Toolbar className={classes.appToolbar}>
            <Hidden lgUp>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={() => dispatch(toggleNavCollapsed())}
              >
                <MenuIcon className={classes.menuIcon} />
              </IconButton>
            </Hidden>

            {nameModule !== "" && (
              <Box className={classes.moduleName}>
                <FormattedMessage id={nameModule} />
              </Box>
            )}

            {<Box className={classes.grow} />}
          </Toolbar>
          <Link
            to={user && user.rol === TypeUser.OFFICER ? "/modules" : "/"}
            style={{ position: "absolute", right: 0 }}
          >
            <Logo />
          </Link>
        </Box>
      </AppBar>
    </>
  );
};
export default AppHeader;

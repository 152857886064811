import React, { useContext } from "react";
import clsx from "clsx";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import AppContext from "../../../context/AppContext";
import AppContextPropsType from "../../../types/AppContextPropsType";
import { LayoutType } from "../../../shared/constants/AppEnums";
import ContentView from "../ContentView/ContentView";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import useStyles from "./layout.styles";
import { RouteItems } from "../../../interfaces/RouteItems";

interface LayoutProps {
  DashboardRouter: any;
  routeList:RouteItems[]
}

const Layout: React.FC<LayoutProps> = ({ DashboardRouter,routeList }) => {
  const { footer, themeStyle, layoutType, footerType } =
    useContext<AppContextPropsType>(AppContext);
  const classes = useStyles({ footer, themeStyle });
  return (
    <Box
      className={clsx(
        classes.appMain,
        layoutType === LayoutType.BOXED ? classes.boxedLayout : "",
        {
          appMainFooter: footer && footerType === "fluid",
          appMainFixedFooter: footer && footerType === "fixed",
        }
      )}
    >
      <Sidebar routeList={routeList} />

      <Box className={classes.mainContent}>
        <Hidden mdDown>
          <Box className={classes.mainContainer}>
            <Header moduleName={routeList[0].route} />
            <ContentView DashboardRoutes={DashboardRouter} />
          </Box>
        </Hidden>

        <Hidden lgUp>
          <Box className={classes.mainContainerFull}>
            <Header moduleName={routeList[0].route} />
            <ContentView DashboardRoutes={DashboardRouter} />
          </Box>
        </Hidden>
      </Box>
    </Box>
  );
};

export default Layout;

import React, { useState } from "react";
import { Box, Button, Grid, Paper, TextField } from "@material-ui/core";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import { useStyles } from "../../shared/styles/useStyles";
import Swal from "sweetalert2";
import { Colors } from "../../shared/constants/themes/Colors";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";
import { MyAlert } from "../../components/custom/MyAlert";
import {
  uiCloseErrorAlert,
  uiCloseSuccessAlert,
} from "../../redux/actions/uiActions";
import { startSetInitialSettled } from "../../redux/actions/entitiesActions";
import { IInitialSettled } from "../../redux/types/types";

export const ConsecutiveSettled = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const { errorAlert, errorMsg, successMsg, successAlert } = useSelector(
    (state: AppState) => state.ui
  );
  const { initialSettled } = useSelector((state: AppState) => state.entities);
  const handleSave = () => {
    let data: IInitialSettled = {
      initialSettled: value,
    };
    Swal.fire({
      title: `${intl.formatMessage({ id: "SurePerfomAction" })}`,
      text: `${intl.formatMessage({ id: "NoConfiguredAgain" })}`,
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: Colors.PRIMARY,
      cancelButtonColor: Colors.DANGER,
      confirmButtonText: "Si",
      cancelButtonText: "No",
      customClass: {
        container: "swal-container-z-index",
      },
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        await dispatch(startSetInitialSettled(data));
      },
    });
  };
  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box component={Paper} width={1}>
        {initialSettled ? (
          <Box p={3} fontSize={20} width={1}>
            <label className="form-text" style={{ color: "red" }}>
              <FormattedMessage id="MsgInitialSettled" /> *
            </label>
          </Box>
        ) : (
          <Box p={3} fontSize={20} width={1}>
            <FormattedMessage id="InitialNumberSettleds" />:
            <Grid container spacing={2} style={{ marginTop: "24px" }}>
              <Grid item xs={6}>
                <TextField
                  name="value"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  fullWidth
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  disabled={initialSettled != null}
                />
              </Grid>
              <Grid xs={3}>
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  onClick={handleSave}
                  disabled={initialSettled != null}
                >
                  <FormattedMessage id="Save" />
                </Button>
              </Grid>
            </Grid>
            <p style={{ fontSize: 16, marginTop: "24px" }}>
              <FormattedMessage id="SettledWillContinue" />
            </p>
          </Box>
        )}
        {successMsg && (
          <MyAlert
            open={successAlert}
            typeAlert="success"
            message={successMsg}
            time={2000}
            handleClose={() => dispatch(uiCloseSuccessAlert())}
          />
        )}
        {errorMsg && (
          <MyAlert
            open={errorAlert}
            typeAlert="error"
            message={errorMsg}
            time={3000}
            handleClose={() => dispatch(uiCloseErrorAlert())}
          />
        )}
      </Box>
    </AppAnimate>
  );
};

import { FC, useRef } from "react";
import { TableRow, TableCell } from "@material-ui/core";
import { CustomizedIcons } from "../../custom/CustomizedIcons";
import { IProponent } from "../../../interfaces/Correspondence";
import { useStyles } from "../../../shared/styles/useStyles";
import { CorrespondenceTenderLabel } from "../correspondence/CorrespondenceTenderLabel";
import { useReactToPrint } from "react-to-print";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useSelector } from "react-redux";

interface props {
  proponent: IProponent;
  readOnly?: boolean;
}
const pageStyle = `
  @page {
    size: 100mm 25mm;
    margin: 0
  }
`;

export const ProponentBody: FC<props> = ({ proponent, readOnly = false }) => {
  const classes = useStyles();
  const componentRef = useRef(null);

  const onPrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
  });
  const { correspondence } = useSelector(
    (state: AppState) => state.correspondence
  );

  const onDelete = () => {};
  const getTenderCorrespondence = (settledId: string) => {
    const findCorres = correspondence.find((corr) => corr.id === settledId);
    if (!findCorres) {
      return null;
    }
    return findCorres;
  };

  return (
    <TableRow key={proponent.idNumber}>
      <TableCell component="th" scope="row" align="center">
        {proponent.businessName.toUpperCase()}
      </TableCell>
      <TableCell align="center" className={classes.capitalize}>
        {proponent.legalRepresentative}
      </TableCell>
      <TableCell align="center" className={classes.capitalize}>
        {proponent.deliveryName}
      </TableCell>
      <TableCell align="center">
        {proponent.idType}-{proponent.idNumber}
      </TableCell>
      <TableCell align="center">{proponent.noEnvelopes}</TableCell>
      {!readOnly && (
        <TableCell align="center">
          <CustomizedIcons
            printIcon
            deleteIcon
            onPrint={onPrint}
            onDelete={onDelete}
          />
        </TableCell>
      )}
      <TableCell style={{ display: "none" }}>
        <div ref={componentRef} style={{ margin: 10 }}>
          <CorrespondenceTenderLabel
            tenderCorres={getTenderCorrespondence(proponent.settledId)}
            proponent={proponent}
          />
        </div>
      </TableCell>
    </TableRow>
  );
};

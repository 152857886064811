import { CommunicationChannels } from "../../enums/enums";

export const communicationChannels = [
  {
    id: CommunicationChannels.SINGLE_WINDOW,
    name: "Ventanilla Única",
  },
  {
    id: CommunicationChannels.EMAIL,
    name: "Dirección de correo electrónico",
  },
  {
    id: CommunicationChannels.SAC,
    name: "Comunicación Verbal",
  },
  {
    id: CommunicationChannels.VERBAL_COMMUNICATION,
    name: "Comunicación Verbal",
  },
  {
    id: CommunicationChannels.FACEBOOK,
    name: "Facebook",
  },
  {
    id: CommunicationChannels.WHATSAPP,
    name: "Whatsapp",
  },
  {
    id: CommunicationChannels.CALL,
    name: "Vía Telefónica",
  },
  {
    id: CommunicationChannels.INSTITUTIONAL_PAGE,
    name: "Página institucional",
  },
  {
    id: CommunicationChannels.FAX,
    name: "Fax",
  },
];

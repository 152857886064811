import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";

import Layout from "../components/ui/Layout/Layout";
import { ModulesType, TypeUser } from "../enums/enums";

import { Group, MoreHoriz } from "@material-ui/icons";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import RestoreOutlinedIcon from "@material-ui/icons/RestoreOutlined";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import TableChartOutlinedIcon from "@material-ui/icons/TableChartOutlined";

import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";

import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";

import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import PeopleOutlinedIcon from "@material-ui/icons/PeopleOutlined";

import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import SettingsApplicationsOutlinedIcon from "@material-ui/icons/SettingsApplicationsOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import GavelRoundedIcon from "@material-ui/icons/GavelRounded";

import AutorenewIcon from "@material-ui/icons/Autorenew";
import DescriptionIcon from "@material-ui/icons/Description";
import AssignmentLateOutlinedIcon from "@material-ui/icons/AssignmentLateOutlined";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import SendIcon from "@material-ui/icons/Send";
import MarkunreadMailboxOutlinedIcon from "@material-ui/icons/MarkunreadMailboxOutlined";
import LaptopMacOutlinedIcon from "@material-ui/icons/LaptopMacOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import { Modules } from "../pages/modulesDashboard/Modules";
import { AppState } from "../redux/reducers/rootReducer";
import { SuperAdminDashboarRouter } from "./SuperAdminDashboarRouter";
import { FileModuleRouter } from "./FileModuleRouter";
import { DocumentManagmentRouter } from "./DocumentManagmentRouter";
import { CorrespondenceRouter } from "./CorrespondenceRouter";
import { firebase } from "../config/firebase/firebase-config";
import { AdminDashboarRouter } from "./AdminRouter";

export const ModulesRouter = () => {
  const currentUser = firebase.auth().currentUser;
  const { user } = useSelector((state: AppState) => state.auth);
  const [isReady, setIsReady] = useState(false);

  const { correspondence, annotations } = useSelector(
    (state: AppState) => state.correspondence
  );
  const { transfers: transfersCentral } = useSelector(
    (state: AppState) => state.fileModule
  );
  const [countCorrespondence, setCountCorrespondence] = useState({
    returnedCorrespondence: 0,
  });

  const [countAnnotations, setCountAnnotations] = useState({
    annotationsReceived: 0,
    annotationsSend: 0,
  });

  const { transfers: transfersDocumentManagement } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { correspondence: communicationsManagment } = useSelector(
    (state: AppState) => state.documentManagment
  );

  const [countManagment, setCountManagment] = useState({
    returnedCorrespondence: 0,
    allCommunicationManagment: 0,
    communicationManagment: 0,
    pqrsManagment: 0,
    procedureManagment: 0,
    copiesManagment: 0,
    controlEntitiesManagment: 0,
    others: 0,
    voBo: 0,
    projection: 0,
    transfersRejected: 0,
    transfersCentralFile: 0,
  });

  useEffect(() => {
    if (correspondence.length > 0) {
      setCountCorrespondence({
        ...countCorrespondence,
        returnedCorrespondence: correspondence.filter(
          (el) => el.isGiveBack && !el.isReaded
        ).length,
      });
    }
    setIsReady(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correspondence]);

  useEffect(() => {
    if (communicationsManagment.length > 0) {
      setCountManagment({
        ...countManagment,
        allCommunicationManagment: user
          ? communicationsManagment.filter((el) => {
              return (
                !el.isReaded ||
                (el.reviewers?.find(
                  (obj) =>
                    obj.id === user.id.toString() &&
                    !obj.emitComments &&
                    !obj.submitDate
                ) &&
                  !el.isReadedVobo) ||
                (el.screeningRequest && el.screeningRequest.length !== undefined
                  ? !!el.screeningRequest.find(
                      (item: any) => item.officerId === user.id.toString()
                    )
                  : el.screeningRequest?.officerId === user.id.toString() &&
                    !el.isReadedProjection)
              );
            }).length
          : 0,
        communicationManagment: user
          ? communicationsManagment.filter((el) => {
              return !el.isReaded;
            }).length
          : 0,
        pqrsManagment: communicationsManagment.filter(
          (el) => el.communicationType?.isParam && !el.isReaded
        ).length,
        procedureManagment: communicationsManagment.filter(
          (el) => el.communicationType?.option === "Procedure" && !el.isReaded
        ).length,
        copiesManagment: user
          ? communicationsManagment.filter(
              (el) => el.copyToIds?.includes(user.id.toString()) && !el.isReaded
            ).length
          : 0,
        controlEntitiesManagment: communicationsManagment.filter(
          (el) =>
            (el.communicationType?.option ===
              "CommunicationOfControlEntities" ||
              el.communicationType?.option ===
                "SummonsToJudicialProceedings") &&
            !el.isReaded
        ).length,
        others: communicationsManagment.filter(
          (el) =>
            el.communicationType?.option !== "Procedure" &&
            el.communicationType?.option !== "CommunicationOfControlEntities" &&
            el.communicationType?.option !== "SummonsToJudicialProceedings" &&
            !el.communicationType?.isParam &&
            !el.isReaded
        ).length,
        voBo: user
          ? communicationsManagment.filter((el) => {
              return (
                el.reviewers?.find(
                  (obj) =>
                    obj.id === user.id.toString() &&
                    !obj.emitComments &&
                    !obj.submitDate
                ) && !el.isReadedVobo
              );
            }).length
          : 0,
        projection: user
          ? communicationsManagment.filter((cor) =>
              cor.screeningRequest && cor.screeningRequest.length !== undefined
                ? !!cor.screeningRequest.find(
                    (item: any) => item.officerId === user.id.toString()
                  )
                : cor.screeningRequest?.officerId === user.id.toString() &&
                  !cor.isReadedProjection
            ).length
          : 0,

        //  Transferencias
        transfersCentralFile: transfersCentral.filter((trs) => !trs.isComplete)
          .length,
        transfersRejected: transfersDocumentManagement.filter(
          (trs) => trs.isRejected
        ).length,
      });
    }
    setIsReady(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communicationsManagment, transfersCentral, transfersDocumentManagement]);

  useEffect(() => {
    if (annotations.length > 0) {
      setCountAnnotations({
        ...countAnnotations,
        annotationsSend: user
          ? annotations.filter(
              (ann) =>
                ann.sender &&
                ann.sender.id.toString() === user.id.toString() &&
                !ann.isReaded
            ).length
          : 0,
        annotationsReceived: user
          ? annotations.filter((ann) =>
              ann.recipientsData.some(
                (el) => el.id.toString() === user?.id.toString() && !el.isReaded
              )
            ).length
          : 0,
      });
    }
    setIsReady(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotations]);

  const getRouteComponent = (route: ModulesType, props: any): any => {
    let USER_LAYOUT: any = null;
    switch (route) {
      case ModulesType.FileModule:
        USER_LAYOUT = (
          <Layout
            DashboardRouter={FileModuleRouter}
            routeList={[
              {
                route: `/${route.toLowerCase()}/reports`,
                name: <FormattedMessage id="Reports" />,
                icon: <AssessmentOutlinedIcon />,
              },
              {
                route: `/${route.toLowerCase()}/transfers`,
                name: <FormattedMessage id="Transfers" />,
                icon: <AutorenewIcon />,
                count: countManagment.transfersCentralFile,
              },
              {
                route: `/${route.toLowerCase()}/fuid`,
                name: "FUID",
                icon: <DescriptionIcon />,
                subRoutes: [
                  {
                    route: `/${route.toLowerCase()}/unaccounted-files`,
                    name: <FormattedMessage id="UnaccountedForFiles" />,
                    icon: <AssignmentLateOutlinedIcon />,
                  },
                  {
                    route: `/${route.toLowerCase()}/central-files`,
                    name: <FormattedMessage id="CentralArchiveFiles" />,
                    icon: <ArchiveOutlinedIcon />,
                  },
                  {
                    route: `/${route.toLowerCase()}/historical-files`,
                    name: <FormattedMessage id="HistoricalArchiveFiles" />,
                    icon: <FindInPageOutlinedIcon />,
                  },
                ],
              },
              {
                route: `/${route.toLowerCase()}/trd`,
                name: "TRD",
                icon: <TableChartOutlinedIcon />,
              },
              {
                route: `/${route.toLowerCase()}/loans`,
                name: <FormattedMessage id="Loans" />,
                icon: <MarkunreadMailboxOutlinedIcon />,
                subRoutes: [
                  {
                    route: `/${route.toLowerCase()}/physical-loans`,
                    name: <FormattedMessage id="Physical" />,
                    icon: <MarkunreadMailboxOutlinedIcon />,
                  },
                  {
                    route: `/${route.toLowerCase()}/digital-loans`,
                    name: <FormattedMessage id="Digital" />,
                    icon: <LaptopMacOutlinedIcon />,
                  },
                ],
              },
              {
                route: `/${route.toLowerCase()}/parameterization`,
                name: <FormattedMessage id="Parameterization" />,
                icon: <SettingsApplicationsOutlinedIcon />,
              },
            ]}
            {...props}
          />
        );
        break;
      case ModulesType.DocumentManagment:
        USER_LAYOUT = (
          <Layout
            DashboardRouter={DocumentManagmentRouter}
            routeList={[
              {
                route: `/${route.toLowerCase()}/reports`,
                name: <FormattedMessage id="Reports" />,
                icon: <AssessmentOutlinedIcon />,
              },
              {
                route: `/${route.toLowerCase()}/correspondence`,
                name: <FormattedMessage id="Correspondence" />,
                icon: <EmailOutlinedIcon />,
                count: countManagment.allCommunicationManagment,
                subRoutes: [
                  {
                    route: `/${route.toLowerCase()}/correspondence`,
                    name: <FormattedMessage id="All" />,
                    icon: <EmailOutlinedIcon />,
                    count: countManagment.communicationManagment,
                  },
                  {
                    route: `/${route.toLowerCase()}/pqrs`,
                    name: <FormattedMessage id="QA" />,
                    icon: <ContactSupportOutlinedIcon />,
                    count: countManagment.pqrsManagment,
                  },
                  {
                    route: `/${route.toLowerCase()}/procedures`,
                    name: <FormattedMessage id="Procedures" />,
                    icon: <AssignmentOutlinedIcon />,
                    count: countManagment.procedureManagment,
                  },
                  {
                    route: `/${route.toLowerCase()}/entities-control`,
                    name: <FormattedMessage id="ControlEntitiesAndDj" />,
                    icon: <Group />,
                    count: countManagment.controlEntitiesManagment,
                  },
                  {
                    route: `/${route.toLowerCase()}/others`,
                    name: <FormattedMessage id="Others" />,
                    icon: <MoreHoriz />,
                    count: countManagment.others,
                  },
                  {
                    route: `/${route.toLowerCase()}/copies`,
                    name: <FormattedMessage id="Copies" />,
                    icon: <FileCopyOutlinedIcon />,
                    count: countManagment.copiesManagment,
                  },
                  {
                    route: `/${route.toLowerCase()}/vobo`,
                    name: <FormattedMessage id="VoBo" />,
                    icon: <ThumbUpOutlinedIcon />,
                    count: countManagment.voBo,
                  },
                  {
                    route: `/${route.toLowerCase()}/screening`,
                    name: <FormattedMessage id="Screening" />,
                    icon: <TrendingUpOutlinedIcon />,
                    count: countManagment.projection,
                  },
                ],
              },
              {
                route: `/${route.toLowerCase()}/annotations`,
                name: <FormattedMessage id="MassiveCommunications" />,
                icon: <AssignmentOutlinedIcon />,
                count:
                  countAnnotations.annotationsSend +
                  countAnnotations.annotationsReceived,
                subRoutes: [
                  {
                    route: `/${route.toLowerCase()}/annotationsSend`,
                    name: <FormattedMessage id="Send" />,
                    icon: <EmailOutlinedIcon />,
                    count: countAnnotations.annotationsSend,
                  },
                  {
                    route: `/${route.toLowerCase()}/annotationsReceived`,
                    name: <FormattedMessage id="Received" />,
                    icon: <ContactSupportOutlinedIcon />,
                    count: countAnnotations.annotationsReceived,
                  },
                ],
              },
              {
                route: `/${route.toLowerCase()}/fuid`,
                name: "FUID",
                icon: <DescriptionIcon />,
                subRoutes: [
                  {
                    route: `/${route.toLowerCase()}/fuid`,
                    name: <FormattedMessage id="Records" />,
                    icon: <DescriptionIcon />,
                  },
                  {
                    route: `/${route.toLowerCase()}/fuid/copies`,
                    name: <FormattedMessage id="Copies" />,
                    icon: <FileCopyOutlinedIcon />,
                  },
                ],
              },
              {
                route: `/${route.toLowerCase()}/transfers`,
                name: <FormattedMessage id="Transfers" />,
                icon: <AutorenewIcon />,
                subRoutes: [
                  {
                    route: `/${route.toLowerCase()}/transfers/sended`,
                    name: <FormattedMessage id="Send" />,
                    icon: <SendIcon />,
                    count: countManagment.transfersCentralFile,
                  },
                  {
                    route: `/${route.toLowerCase()}/transfers/reject`,
                    name: <FormattedMessage id="Rejected" />,
                    icon: <CancelPresentationIcon />,
                    count: countManagment.transfersRejected,
                  },
                ],
              },
              {
                route: `/${route.toLowerCase()}/trd`,
                name: "TRD",
                icon: <TableChartOutlinedIcon />,
              },
              {
                route: `/${route.toLowerCase()}/parameterization`,
                name: <FormattedMessage id="Parameterization" />,
                icon: <SettingsApplicationsOutlinedIcon />,
              },
            ]}
            {...props}
          />
        );
        break;
      case ModulesType.Correspondence:
        USER_LAYOUT = (
          <Layout
            DashboardRouter={CorrespondenceRouter}
            routeList={[
              {
                route: `/${route.toLowerCase()}/reports`,
                name: <FormattedMessage id="Reports" />,
                icon: <AssessmentOutlinedIcon />,
              },
              {
                route: `/${route.toLowerCase()}/correspondence`,
                name: <FormattedMessage id="Correspondence" />,
                icon: <EmailOutlinedIcon />,
                count: countCorrespondence.returnedCorrespondence,
                subRoutes: [
                  {
                    route: `/${route.toLowerCase()}/correspondence`,
                    name: <FormattedMessage id="All" />,
                    icon: <EmailOutlinedIcon />,
                  },
                  {
                    route: `/${route.toLowerCase()}/pqrs`,
                    name: <FormattedMessage id="QA" />,
                    icon: <ContactSupportOutlinedIcon />,
                  },
                  {
                    route: `/${route.toLowerCase()}/procedures`,
                    name: <FormattedMessage id="Procedures" />,
                    icon: <AssignmentOutlinedIcon />,
                  },
                  {
                    route: `/${route.toLowerCase()}/entities-control`,
                    name: <FormattedMessage id="ControlEntitiesAndDj" />,
                    icon: <Group />,
                  },
                  {
                    route: `/${route.toLowerCase()}/others`,
                    name: <FormattedMessage id="Others" />,
                    icon: <MoreHoriz />,
                  },
                  {
                    route: `/${route.toLowerCase()}/returned`,
                    name: <FormattedMessage id="Returned" />,
                    icon: <RestoreOutlinedIcon />,
                    count: countCorrespondence.returnedCorrespondence,
                  },
                ],
              },

              {
                route: `/${route.toLowerCase()}/tenders`,
                name: <FormattedMessage id="Tenders" />,
                icon: <GavelRoundedIcon />,
              },
              {
                route: `/${route.toLowerCase()}/annotations`,
                name: <FormattedMessage id="MassiveCommunications" />,
                icon: <AssignmentOutlinedIcon />,
                count:
                  countAnnotations.annotationsSend +
                  countAnnotations.annotationsReceived,
                subRoutes: [
                  {
                    route: `/${route.toLowerCase()}/annotationsSend`,
                    name: <FormattedMessage id="Send" />,
                    icon: <EmailOutlinedIcon />,
                    count: countAnnotations.annotationsSend,
                  },
                  {
                    route: `/${route.toLowerCase()}/annotationsReceived`,
                    name: <FormattedMessage id="Received" />,
                    icon: <ContactSupportOutlinedIcon />,
                    count: countAnnotations.annotationsReceived,
                  },
                ],
              },
              {
                route: `/${route.toLowerCase()}/parameterization`,
                name: <FormattedMessage id="Parameterization" />,
                icon: <SettingsApplicationsOutlinedIcon />,
              },
            ]}
            {...props}
          />
        );
        break;
      default:
        return null;
    }

    return USER_LAYOUT;
  };
  return (
    <div>
      {isReady && (
        <>
          {currentUser && user?.rol === TypeUser.OFFICER && user.modules && (
            <Switch>
              <Route path="/modules" component={Modules} />
              {user.modules.map((module) => {
                return (
                  <Route
                    key={module}
                    path={`/${module.toLowerCase()}/`}
                    render={(props) =>
                      getRouteComponent(module as ModulesType, props)
                    }
                  />
                );
              })}

              <Redirect to="/modules" />
            </Switch>
          )}
        </>
      )}
      {currentUser && user?.rol === TypeUser.SUPER_ADMIN && (
        <Switch>
          <Route
            path="/super/dashboard/"
            render={(props) => (
              <Layout
                DashboardRouter={SuperAdminDashboarRouter}
                routeList={[
                  {
                    route: "/super/dashboard/entity",
                    name: (<FormattedMessage id="Entities" />) as any,
                    icon: <HomeOutlinedIcon />,
                  },
                ]}
                {...props}
              />
            )}
          />
          <Redirect to="/super/dashboard/entity" />
        </Switch>
      )}

      {currentUser && user?.rol === TypeUser.ADMIN && (
        <Switch>
          <Route
            path="/admin/dashboard/"
            render={(props) => (
              <Layout
                DashboardRouter={AdminDashboarRouter}
                routeList={[
                  {
                    route: "/admin/dashboard/users",
                    name: (<FormattedMessage id="Users" />) as any,
                    icon: <PeopleOutlinedIcon />,
                  },
                  {
                    route: "/admin/dashboard/trd",
                    name: "TRD",
                    icon: <TableChartOutlinedIcon />,
                  },
                  {
                    route: "/admin/dashboard/organizationchart",
                    name: (<FormattedMessage id="OrganizationChart" />) as any,
                    icon: <AccountTreeOutlinedIcon />,
                  },
                  {
                    route: "/admin/dashboard/parameterization",
                    name: (<FormattedMessage id="Parameterization" />) as any,
                    icon: <SettingsIcon />,
                    subRoutes: [
                      {
                        route: `/admin/dashboard/parameterization`,
                        name: (
                          <FormattedMessage id="ConsecutiveOfSettled" />
                        ) as any,
                        icon: <EmailOutlinedIcon />,
                      },
                    ],
                  },
                ]}
                {...props}
              />
            )}
          />
          <Redirect to="/admin/dashboard/users" />
        </Switch>
      )}
    </div>
  );
};

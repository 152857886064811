import { ISignature } from './../../interfaces/Correspondence';
import {
  ICorrespondence,
} from "../../interfaces/Correspondence";
import { ActionSettled } from "../../enums/enums";
import { ICorresAnnotations, IFuid, ITransfer } from "../types/types";
import {
  ICorrespondenceActions,
  IDeleteCopy,
  IDeposits,
  IDocumentManagmentState,
  IProjectionRedux,
  ISendCopy,
  Types,
} from "../types/types";

const initialState: IDocumentManagmentState = {
  correspondence: [],
  activeCorrespondence: null,
  titleAction: ActionSettled.RECLASSIFY,
  Deposits: [],
  expedients: [],
  corresAnnotations: [],
  fuidAnnotations: [],
  activeCorresAnnotation: null,
  transfers: [],
  activeAnswer: null,
  activeTransfer: null,
  activeExpedient: null,
  signature: null,

};
export const documentManagmentReducer = (
  state = initialState,
  action: ICorrespondenceActions
): IDocumentManagmentState => {
  const { type, payload } = action;
  switch (type) {
    case Types.loadCorrespondenceDocumentManagment:
      return {
        ...state,
        correspondence: [...(payload as ICorrespondence[])],
      };

    case Types.setActiveCorrespondenceDocumentManagment:
      return {
        ...state,
        activeCorrespondence: payload as ICorrespondence,
      };

    case Types.setTitleAction:
      return {
        ...state,
        titleAction: payload as string,
      };

    case Types.updateCorrespondence:
      return {
        ...state,
        correspondence: state.correspondence.map((row) =>
          row.id === (payload as ICorrespondence).id
            ? (payload as ICorrespondence)
            : row
        ),
      };
    case Types.sendCorrespondenceCopy:
      const { idCorrespondence, users } = payload as ISendCopy;
      const allCorrespondences = [...state.correspondence];
      const indexCorrespondence = allCorrespondences.findIndex(
        (cor) => cor.id === idCorrespondence
      );
      if (indexCorrespondence !== -1) {
        let copyToIds = allCorrespondences[indexCorrespondence].copyToIds;
        if (copyToIds) {
          const addIds = users.map((user) => user.id);
          copyToIds = [...addIds];
        }

        allCorrespondences[indexCorrespondence].copyTo = users;
        allCorrespondences[indexCorrespondence].copyToIds = copyToIds;
      }
      return {
        ...state,
        correspondence: allCorrespondences,
      };

     
    case Types.deleteCorrespondenceCopy:
      const { idCorrespondence: idCorresDelete, idUser } =
        payload as IDeleteCopy;
      const correspondenceCopy = [...state.correspondence];
      const indexCorrespondenceDel = correspondenceCopy.findIndex(
        (cor) => cor.id === idCorresDelete
      );
      if (indexCorrespondenceDel !== -1) {
        correspondenceCopy[indexCorrespondenceDel].copyTo = correspondenceCopy[
          indexCorrespondenceDel
        ].copyTo?.filter((user) => user.id !== idUser);
        correspondenceCopy[indexCorrespondenceDel].copyToIds =
          correspondenceCopy[indexCorrespondenceDel].copyToIds?.filter(
            (ids) => ids !== idUser
          );
      }
      return {
        ...state,
        correspondence: [...correspondenceCopy],
      };
    case Types.requestProjection:
      const { projection } = payload as IProjectionRedux;
      const correspondenceCopy2 = [...state.correspondence];
      const indexCorresProjection = correspondenceCopy2.findIndex(
        (cor) => cor.id === (payload as IProjectionRedux).idCorrespondence
      );
      if (indexCorresProjection !== -1) {
        correspondenceCopy2[indexCorresProjection].screeningRequest =
          [projection];
      }
      return {
        ...state,
        correspondence: correspondenceCopy2,
      };
    case Types.loadDeposits:
      return {
        ...state,
        Deposits: [...(payload as IDeposits[])],
      };
    case Types.addDeposit:
      return {
        ...state,
        Deposits: [payload as IDeposits, ...state.Deposits],
      };

    case Types.loadAllCorresAnnotations:
      return {
        ...state,
        corresAnnotations: [...(payload as ICorresAnnotations[])],
      };

    case Types.addCorresAnnotation:
      return {
        ...state,
        corresAnnotations: [
          payload as ICorresAnnotations,
          ...state.corresAnnotations,
        ],
      };

    case Types.deleteCorresAnnotation:
      return {
        ...state,
        corresAnnotations: state.corresAnnotations.filter(
          (corresAnnotation) => corresAnnotation.id !== payload
        ),
      };

    case Types.setActiveCorrespondenceAnnotation:
      return {
        ...state,
        activeCorresAnnotation: payload as ICorresAnnotations,
      };
    // PAYLOAD CARGAR TRANSFERS EN REDUX
    case Types.loadTransfers:
      return {
        ...state,
        transfers: [...(payload as ITransfer[])],
      };

    // PAYLOAD GUARDAR TRANSFER EN REDUX
    case Types.addTransfer:
      const allTransfers = [...state.transfers];
      const indexTransf = allTransfers.findIndex(
        (el) => el.idTransfer === (payload as ITransfer).idTransfer
      );
      if (indexTransf >= 0) {
        allTransfers[indexTransf] = payload as ITransfer;
        return {
          ...state,
          transfers: [...allTransfers],
        };
      }
      return {
        ...state,
        transfers: [payload as ITransfer, ...state.transfers],
      };

    case Types.setActiveAnswer:
      return {
        ...state,
        activeAnswer: payload as any,
      };
    case Types.setActiveTransfer:
      return {
        ...state,
        activeTransfer: payload as any,
      };
          // PAYLOAD ELIMINAR EXPEDIENTE DE REDUX
    case Types.deleteDepositDocumentManagement:
      return {
        ...state,
        Deposits: state.Deposits.filter(
          (deposits) => deposits.id !== action.payload
        ),
      };



    // PAYLOAD ELIMINAR TRANSFERENCIA DE REDUX
    case Types.deleteTransferDocumentManagement:
      return {
        ...state,
        transfers: state.transfers.filter(
          (transfers) => transfers.idTransfer !== action.payload
        ),
      };

          // PAYLOAD GUARADR EXPEDIENTE EN REDUX
    case Types.addFuid:
      const allExpedients = [...state.expedients];
      const indexCorres = allExpedients.findIndex(
        (el) => el.id === (payload as IFuid).id
      );
      if (indexCorres >= 0) {
        allExpedients[indexCorres] = payload as IFuid;
        return {
          ...state,
          expedients: [...allExpedients],
        };
      }
      return {
        ...state,
        expedients: [payload as IFuid, ...state.expedients],
      };
    // PAYLOAD CARGAR LOS EXPEDIENTES A REDUX
    case Types.loadFuid:
      return {
        ...state,
        expedients: [...(action.payload as IFuid[])],
      };
    // PAYLOAD ELIMINAR EXPEDIENTE DE REDUX
    case Types.deleteFuid:
      return {
        ...state,
        expedients: state.expedients.filter(
          (expedients) => expedients.id !== action.payload
        ),
      };
      case Types.addFuidDocumentManagementAnnotation:
        return {
          ...state,
          fuidAnnotations: [
            payload as ICorresAnnotations,
            ...state.fuidAnnotations,
          ],
        };
        case Types.addSignature:
          return {
            ...state,
            signature: payload as ISignature,
          };
        case Types.deleteSignature:
          return {
            ...state,
            signature: payload as null,
          };
    default:
      return state;
  }
};

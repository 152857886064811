import moment from "moment";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { TableCell, TableRow, Checkbox, Box, Tooltip } from "@material-ui/core";
import { CustomizedIcons } from "../../../components/custom/CustomizedIcons";

import { AppState } from "../../../redux/reducers/rootReducer";
import { IDocumentsFuid } from "../../../redux/types/types";
import { uiOpenModalInfo } from "../../../redux/actions/uiActions";
import { setActiveDocument } from "../../../redux/actions/fuidActions";
import {
  addExpedientDownloadFileModule,
  deleteExpedientDownloadFileModule,
} from "../../../redux/actions/fileModuleActions";

interface props {
  documentFuid: IDocumentsFuid;
  readonly?: boolean;
  isCopy?: boolean;
}

export const DocumentsFuidBody: FC<props> = ({
  documentFuid,
  readonly = false,
  isCopy = false,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { documentalType: allDocumentalType } = useSelector(
    (state: AppState) => state.trd
  );

  const [check, setCheck] = useState(false);

  const findDocumentalTypeName = (id: string) => {
    const docType = allDocumentalType.find(
      (doc) => doc.idDocumentalType === id
    );
    if (docType) {
      return docType.name;
    }
    return "";
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheck(event.target.checked);

    if (!check) {
      documentFuid.check = true;
      dispatch(addExpedientDownloadFileModule(documentFuid));
    } else {
      dispatch(deleteExpedientDownloadFileModule(documentFuid.url));
    }
  };

  const onEdit = () => {
    dispatch(setActiveDocument(documentFuid));
    dispatch(uiOpenModalInfo());
  };

  const onDelete = () => {};

  return (
    <TableRow>
      <TableCell
        component="th"
        scope="row"
        align="center"
        style={{ cursor: "pointer" }}
        // onClick={donwloadFunction}
      >
        {/* <Link href={document.url}> {document.fileName} </Link> */}
        <a href={documentFuid.url} target="__blank">
          {documentFuid.fileName}
        </a>
      </TableCell>
      <TableCell align="center">
        {documentFuid.documentalType
          ? findDocumentalTypeName(documentFuid.documentalType)
          : ""}
      </TableCell>
      <TableCell align="center">
        {documentFuid.officer ? documentFuid.officer.name : ""}
      </TableCell>
      <TableCell align="center" style={{ textTransform: "lowercase" }}>
        {documentFuid.uploadDate
          ? moment(documentFuid.uploadDate.toDate()).format(
              "YYYY-MM-DD hh:mm a"
            )
          : ""}
      </TableCell>
      {!readonly && (
        <TableCell align="center">
          <Box display="flex" justifyContent={"center"}>
            <Tooltip
              title={`${intl.formatMessage({ id: "DownloadDocument" })}`}
            >
              <Checkbox
                checked={check}
                name="check"
                onChange={handleChange}
                color="default"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </Tooltip>
            {!isCopy && (
              <CustomizedIcons
                editIcon
                deleteIcon
                onEdit={onEdit}
                onDelete={onDelete}
              />
            )}
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
};

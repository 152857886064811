import moment from "moment";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Box, Grid, TextField, IconButton, Tooltip } from "@material-ui/core";
import AssignmentIndRoundedIcon from "@material-ui/icons/AssignmentIndRounded";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

import CustomizedDialog from "../../custom/CustomizedDialog";
import { MyAlert } from "../../custom/MyAlert";
import { FileForm } from "../../file-module/fuid/FileForm";
import { ActionSettled, TypeArchive } from "../../../enums/enums";
import { AppState } from "../../../redux/reducers/rootReducer";
import { setTitleAction } from "../../../redux/actions/documentManagmentActions";
import {
  uiCloseErrorAlert,
  uiCloseModalAction,
  uiOpenModalAction,
} from "../../../redux/actions/uiActions";
import {
  uiCloseModalInfo,
  uiCloseSuccessAlert,
} from "../../../redux/actions/uiActions";
import { setActiveFuidAnnotation } from "../../../redux/actions/fileModuleActions";
import { setActiveDocument } from "../../../redux/actions/fuidActions";
import { uiCloseModalDelete } from "../../../redux/actions/uiActions";
import { useStyles } from "../../../shared/styles/useStyles";
import { ConfirmDelete } from "../../ui/shared/ConfirmDelete";
import { CreateAnnotationForm } from "./CreateAnnotationForm";
import { FuidDetailsTabs } from "./FuidDetailsTabs";
import { ClassifyTrdForm } from "./ClassifyTrdForm";
import { ReassignForm } from "./ReassignForm";
import { SendCopyForm } from "./SendCopyForm";
import { UploadDocumentForm } from "./UploadDocumentForm";

interface props {
  component?: string;
  fromModule?: TypeArchive;
}
export const FuidDetails: FC<props> = ({ component, fromModule }) => {
  const intl = useIntl();
  const { titleAction } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { activeExpedient: FuidDocumentManagement } = useSelector(
    (state: AppState) => state.fuid
  );
  const { activeExpedientFileModule: FuidFilesModule } = useSelector(
    (state: AppState) => state.fileModule
  );
  const { user } = useSelector((state: AppState) => state.auth);

  const expedient = fromModule ? FuidFilesModule : FuidDocumentManagement;

  const pathRef = window.location.href.slice(-11);
  const isCopy = pathRef === "fuid/copies" ? true : false;

  const filterCopyToUser =
    expedient && expedient?.copyTo && user
      ? expedient.copyTo.filter((copy) => copy.id === user.id.toString())[0]
      : null;

  const userPermissions = isCopy
    ? !!(filterCopyToUser && filterCopyToUser.uploadPermission)
    : true;

  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    errorAlert,
    errorMsg,
    modalActionOpen,
    modalInfoOpen,
    successAlert,
    successMsg,
    questionMsg,
    modalDeleteOpen,
    titleMsg,
  } = useSelector((state: AppState) => state.ui);
  const [content, setContent] = useState(<p></p>);

  const { productionOffices, series, subSeries } = useSelector(
    (state: AppState) => state.trd
  );

  const { Deposits: depositsCentralFile } = useSelector(
    (state: AppState) => state.fileModule
  );
  const { Deposits: depositsManagments } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const Deposits = fromModule ? depositsCentralFile : depositsManagments;

  const form = {
    fileNumber: expedient ? expedient?.fileNumber : "",
    fileName: expedient ? expedient?.fileName : "",
    description: expedient ? expedient?.description : "",
    extremeInitialDate: expedient
      ? moment(expedient?.extremeInitialDate.toDate())
          .add(1, "days")
          .format("YYYY/MM/DD")
      : "",
    extremeFinalDate: expedient
      ? moment(expedient?.extremeFinalDate.toDate())
          .add(1, "days")
          .format("YYYY/MM/DD")
      : "",
    pagesOf: expedient ? expedient?.pagesOf : "",
    pagesTo: expedient ? expedient?.pagesTo : "",
    folderOrVolumeNumber: expedient ? expedient?.volumeNumber : "",
    totalFolderOrVolume: expedient ? expedient?.totalFolderOrVolume : "",
    fileType: expedient ? expedient?.fileType : "",
    deposit: expedient ? expedient?.deposit : "",
    shelf: expedient ? expedient?.shelf : "",
    folder: expedient ? expedient?.folder : "",
    box: expedient ? expedient?.box : "",
    trdOrTvd: expedient ? expedient?.trdOrTvd : "",
    productionOffice: expedient ? expedient?.productionOffice : "",
    series: expedient ? expedient?.series : "",
    subseries: expedient ? expedient?.subseries : "",
  };

  //   FIND PARA TDR
  const serieFinded = series.find((ser) => ser.idSerie === form.series);
  const subSeriesFinded = subSeries.find(
    (sub) => sub.idSubserie === form.subseries
  );
  const productionOfficeFinded = productionOffices.find(
    (po) => po.idProductionOffice === form.productionOffice
  );

  //   FIND DEPOSITS
  const depositsFind = Deposits.find((dep) => dep.id === form.deposit);

  // FUNCION PARA EJECUTAR BOTON DE ACCION
  const handleOpenButtonAction = (action: string) => {
    setContent(<></>);
    dispatch(setTitleAction(action));

    action === ActionSettled.REASSIGN &&
      setContent(<ReassignForm filesModule fromModule={fromModule} />);
    action === ActionSettled.SEND_COPY &&
      setContent(<SendCopyForm filesModule fromModule={fromModule} />);
    action === ActionSettled.ANNOTATION &&
      setContent(<CreateAnnotationForm filesModule fromModule={fromModule} />);
    action === ActionSettled.UPLOAD_DOCUMENT &&
      setContent(
        <UploadDocumentForm expedient={expedient!} fromModule={!!fromModule} />
      );
    action === ActionSettled.EDIT_EXPEDIENT &&
      setContent(<FileForm fromModule={fromModule} edit />);

    dispatch(uiOpenModalAction());
  };

  // CERRAR LOS MODALES
  const onDeny = () => {
    dispatch(uiCloseModalAction());
    dispatch(uiCloseModalInfo());
    dispatch(setActiveDocument(null));
    dispatch(uiCloseModalDelete());
    dispatch(setActiveFuidAnnotation(null));

    // dispatch(uiCloseModalEdit());
  };

  return (
    <Box m={2} mt={-1} mb={2}>
      <Grid container spacing={2}>
        <>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Box display={"flex"} justifyContent={"flex-end"} mr={5} mt={2}>
              <label className="form-text">
                <FormattedMessage id="Actions" />: &nbsp;
              </label>
            </Box>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Box display={"flex"} justifyContent={"flex-end"}>
              {/* ICONO CARGAR DOCUMENTOS */}
              {userPermissions && (
                <Tooltip
                  title={`${intl.formatMessage({ id: "UploadDocument" })}`}
                >
                  <IconButton
                    onClick={() =>
                      handleOpenButtonAction(ActionSettled.UPLOAD_DOCUMENT)
                    }
                  >
                    <BackupOutlinedIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              )}
              {!isCopy && (
                <>
                  {/* ICONO EDITAR EXPEDIENTE */}
                  <Tooltip title={`${intl.formatMessage({ id: "Edit" })}`}>
                    <IconButton
                      onClick={() =>
                        handleOpenButtonAction(ActionSettled.EDIT_EXPEDIENT)
                      }
                    >
                      <EditOutlinedIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                  {/* ICONO ENVIAR COPIA */}
                  <Tooltip title={`${intl.formatMessage({ id: "SendCopy" })}`}>
                    <IconButton
                      onClick={() =>
                        handleOpenButtonAction(ActionSettled.SEND_COPY)
                      }
                    >
                      <FileCopyOutlinedIcon fontSize="large" />
                    </IconButton>
                  </Tooltip>
                  {/* ICONO REASIGNAR */}
                  {user && expedient?.owner.id === user.id.toString() && (
                    <Tooltip
                      title={`${intl.formatMessage({ id: "Reassign" })}`}
                    >
                      <IconButton
                        onClick={() =>
                          handleOpenButtonAction(ActionSettled.REASSIGN)
                        }
                      >
                        <AssignmentIndRoundedIcon fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
              {/* ICONO PARA ANOTACIONES */}
              {!fromModule && (
                <Tooltip title={`${intl.formatMessage({ id: "Annotation" })}`}>
                  <IconButton
                    onClick={() =>
                      handleOpenButtonAction(ActionSettled.ANNOTATION)
                    }
                  >
                    <AssignmentOutlinedIcon fontSize="large" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Grid>
        </>

        <Grid item xs={6}>
          <label className="form-text">
            <FormattedMessage id="FileNumber" />:
          </label>
          <TextField
            name="fileNumber"
            variant="outlined"
            type="number"
            size="small"
            className={classes.myTextFieldRoot}
            value={form.fileNumber}
            disabled
          />
        </Grid>

        <Grid item xs={6}>
          <label className="form-text">
            <FormattedMessage id="FileName" />:
          </label>
          <TextField
            name="fileName"
            variant="outlined"
            size="small"
            className={classes.myTextFieldRoot}
            value={form.fileName}
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <label className="form-text">
            <FormattedMessage id="Description" />:
          </label>
          <TextField
            name="description"
            variant="outlined"
            size="small"
            className={classes.myTextFieldRoot}
            multiline
            rows={4}
            value={form.description}
            disabled
          />
        </Grid>

        <Grid item xs={4}>
          <label className="form-text">
            <FormattedMessage id="ExtremeInitialDate" />:
          </label>
          <TextField
            name="extremeInitialDate"
            variant="outlined"
            size="small"
            className={classes.myTextFieldRoot}
            value={form.extremeInitialDate}
            disabled
          />
        </Grid>

        <Grid item xs={4}>
          <label className="form-text">
            <FormattedMessage id="ExtremeFinalDate" />:
          </label>
          <TextField
            name="extremeFinalDate"
            variant="outlined"
            size="small"
            className={classes.myTextFieldRoot}
            value={form.extremeFinalDate}
            disabled
          />
        </Grid>

        <Grid item xs={2}>
          <label className="form-text">
            <FormattedMessage id="PagesOf" />:
          </label>
          <TextField
            name="pagesOf"
            variant="outlined"
            type="number"
            size="small"
            className={classes.myTextFieldRoot}
            value={form.pagesOf}
            disabled
          />
        </Grid>

        <Grid item xs={2}>
          <label className="form-text">
            <FormattedMessage id="PagesTo" />:
          </label>
          <TextField
            name="pagesTo"
            variant="outlined"
            type="number"
            size="small"
            className={classes.myTextFieldRoot}
            value={form.pagesTo}
            disabled
          />
        </Grid>

        <Grid item xs={4}>
          <label className="form-text">
            <FormattedMessage id="FolderOrVolumeNumberAbbreviation" />:
          </label>
          <TextField
            name="folderOrVolumeNumber"
            variant="outlined"
            type="number"
            size="small"
            className={classes.myTextFieldRoot}
            value={form.folderOrVolumeNumber}
            disabled
          />
        </Grid>

        <Grid item xs={5}>
          <label className="form-text">
            <FormattedMessage id="TotalFoldersOrVolumes" />:
          </label>
          <TextField
            name="totalFolderOrVolume"
            variant="outlined"
            type="number"
            size="small"
            className={classes.myTextFieldRoot}
            value={form.totalFolderOrVolume}
            disabled
          />
        </Grid>

        <Grid item xs={3}>
          <label className="form-text">
            <FormattedMessage id="FileType" />:
          </label>
          <TextField
            name="fileType"
            variant="outlined"
            size="small"
            className={classes.myTextFieldRoot}
            value={form.fileType}
            disabled
          ></TextField>
        </Grid>

        <Grid item xs={4}>
          <label className="form-text">
            <FormattedMessage id="Deposit" />:
          </label>
          <TextField
            name="deposit"
            variant="outlined"
            size="small"
            className={classes.myTextFieldRoot}
            value={depositsFind ? depositsFind.name : ""}
            disabled
          ></TextField>
        </Grid>

        <Grid item xs={4}>
          <label className="form-text">
            <FormattedMessage id="Shelf" />:
          </label>
          <TextField
            name="shelf"
            variant="outlined"
            size="small"
            className={classes.myTextFieldRoot}
            value={form.shelf}
            disabled
          />
        </Grid>

        <Grid item xs={2}>
          <label className="form-text">
            <FormattedMessage id="Box" />:
          </label>
          <TextField
            name="box"
            variant="outlined"
            size="small"
            className={classes.myTextFieldRoot}
            value={form.box}
            disabled
          />
        </Grid>

        <Grid item xs={2}>
          <label className="form-text">
            <FormattedMessage id="Folder" />:
          </label>
          <TextField
            name="folder"
            variant="outlined"
            size="small"
            className={classes.myTextFieldRoot}
            value={form.folder}
            disabled
          />
        </Grid>

        <Grid item xs={6}>
          <label className="form-text">
            <FormattedMessage id="TrdOrTvd" />:
          </label>
          <TextField
            name="trdOrTvd"
            variant="outlined"
            size="small"
            className={classes.myTextFieldRoot}
            value={form.trdOrTvd}
            disabled
          />
        </Grid>

        <Grid item xs={6}>
          <FormattedMessage id="ProductionOffice" />:
          <TextField
            name="productionOffice"
            variant="outlined"
            size="small"
            className={classes.myTextFieldRoot}
            value={productionOfficeFinded ? productionOfficeFinded.name : ""}
            disabled
          />
        </Grid>

        <Grid item xs={6}>
          <label className="form-text">
            <FormattedMessage id="Series" />:
          </label>
          <TextField
            name="series"
            variant="outlined"
            size="small"
            className={classes.myTextFieldRoot}
            value={serieFinded ? serieFinded.seriesName : ""}
            disabled
          />
        </Grid>

        <Grid item xs={6}>
          <FormattedMessage id="Subseries" />:
          <TextField
            name="subseries"
            variant="outlined"
            size="small"
            className={classes.myTextFieldRoot}
            value={subSeriesFinded ? subSeriesFinded.name : ""}
            disabled
          />
        </Grid>
      </Grid>

      <br />
      {expedient && (
        <FuidDetailsTabs
          fromFileModule={fromModule ? true : false}
          expedient={expedient}
          isCopy={isCopy}
        />
      )}

      <CustomizedDialog
        open={modalActionOpen}
        cancelBtn={true}
        onDeny={onDeny}
        title={`${intl.formatMessage({
          id: `${titleAction}`,
        })}`}
        content={content}
        textButton="Accept"
      />

      <CustomizedDialog
        open={modalInfoOpen}
        cancelBtn={true}
        onDeny={onDeny}
        title={`${intl.formatMessage({
          id: "EditDocumentaryType",
        })}`}
        content={
          <ClassifyTrdForm
            component={
              fromModule ? "ExpedientCentralFile" : "ExpedientManagment"
            }
          />
        }
        textButton="Accept"
      />

      {questionMsg && titleMsg && (
        <CustomizedDialog
          open={modalDeleteOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: `${titleMsg}` })}`}
          content={
            <ConfirmDelete
              title={`${intl.formatMessage({
                id: `${questionMsg}`,
              })}`}
              fromFileModule={!!fromModule}
            />
          }
          textButton="Accept"
        />
      )}

      {errorMsg && (
        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={errorMsg}
          time={2000}
          handleClose={() => dispatch(uiCloseErrorAlert())}
        />
      )}

      {successMsg && (
        <MyAlert
          open={successAlert}
          typeAlert="success"
          message={successMsg}
          time={2000}
          handleClose={() => dispatch(uiCloseSuccessAlert())}
        />
      )}
    </Box>
  );
};

import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import logo from "../../../assets/images/logo-1-01.png";
import { CremaTheme } from "../../../types/AppContextPropsType";

const Logo = () => {
  const useStyles = makeStyles((theme: CremaTheme) => ({
    logoRoot: {
      flexDirection: "row",
      cursor: "pointer",
      position: "relative",
      right: 5,
    },
    logo: {
      marginRight: 10,
      marginTop: -10,
      [theme.breakpoints.down("sm")]: {
        height: 50,
      },
      [theme.breakpoints.up("sm")]: {
        height: 65,
      },
    },
  }));
  const classes = useStyles();

  return (
    <Box className={classes.logoRoot}>
      <Hidden>
        <img className={classes.logo} src={logo} alt="docspace-logo" />
      </Hidden>
    </Box>
  );
};

export default Logo;

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useStyles } from "../../../shared/styles/useStyles";
import clsx from "clsx";
import { uiCloseModalAction } from "../../../redux/actions/uiActions";
import { MyTextField } from "../../custom/MyTextField";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import { IFiles } from "../../../redux/types/types";
import { Delete, FileCopy } from "@material-ui/icons";
import { startUploadAnnotations } from "../../../redux/actions/documentManagmentActions";
import { ICreateAnnotationForm } from "../../../interfaces/Correspondence";
import { Enum_FileSize, TypeArchive } from "../../../enums/enums";
import { startUploadFuidAnnotations } from "../../../redux/actions/fileModuleActions";

interface Props {
  fromModule?: TypeArchive;
  filesModule?: boolean;
}

export const CreateAnnotationForm = ({
  fromModule,
  filesModule = false,
}: Props) => {
  const [fileNames, setFileNames] = useState<IFiles[]>([]);
  const [labelImage, setLabelImage] = useState("");
  const [noValid, setNoValid] = useState(false);
  const [noValidSize, setNoValidSize] = useState(false);

  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(uiCloseModalAction());
  };
  const initialValues: ICreateAnnotationForm = {
    description: "",
    attachedDocuments: [],
  };

  const validationSchema = yup.object({
    description: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });
  const handleSelectFile = (e: any) => {
    const SUPPORTED_FORMATS = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/png",
      "application/msword",
      "text/csv",
      "application/pdf",
      "application/zip",
      "application/x-zip-compressed",
      "application/vnd.rar",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.oasis.opendocument.text",
      "zip",
      "rar",
      "odt",
      "pptx",
      "ppt"
    ];
    const file = e.target.files[0] as File;
    const now = new Date().getTime().toString();
    if (file) {
      if (file.size > Enum_FileSize.maxAllowedSize) {
        setNoValidSize(true);
      } else {

        let fileType = file.type;

        if(fileType.length === 0){
          const dataFileName = file.name.split(".");
          fileType = dataFileName.length > 0 ? dataFileName[dataFileName.length - 1]: "";
        }

        if (SUPPORTED_FORMATS.includes(fileType)) {
          setNoValid(false);
          setFileNames((prevState) => [
            { url: "#", fileName: now + "_" + file.name, file },
            ...prevState,
          ]);
          setLabelImage(e.target.files[0].name);
        } else {
          setNoValid(true);
        }
      }
    } else {
      setNoValid(false);
      setNoValidSize(false);
      setLabelImage("");
    }
  };

  const handleDeleteFile = async (fileName: string, db: boolean) => {
    setFileNames([]);
  };

  // ARCHIVOS SOPORTADOS PARA INPUT TYPE=FILE
  const fileSupported = {
    accept:
            `
            application/pdf,
            image/jpeg,
            image/png,
            application/msword,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            application/vnd.ms-excel,
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            text/csv,
            application/zip,
            application/x-zip-compressed,
            application/vnd.rar,
            application/vnd.ms-powerpoint,
            application/vnd.openxmlformats-officedocument.presentationml.presentation,
            application/vnd.oasis.opendocument.text,
        `,
  };

  return (
    <Box m={2} mt={-1} mb={2}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          values.attachedDocuments = fileNames;
          filesModule
            ? await dispatch(
                startUploadFuidAnnotations(values, fromModule ? true : false)
              )
            : await dispatch(startUploadAnnotations(values));
          setSubmitting(false);
        }}
      >
        {({ values, isSubmitting, handleChange, setFieldValue }) => (
          <Form>
            <Grid container spacing={2}>
              <label className="form-text">
                <FormattedMessage id="ContentDescription" />*
              </label>
              <MyTextField
                name="description"
                variant="outlined"
                className={classes.myTextFieldRoot}
                multiline
                rows={3}
              />
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="AttachDocument" />
                </label>
                <TextField
                  size="small"
                  type="file"
                  inputProps={fileSupported}
                  onChange={handleSelectFile}
                  id="icon-button-file"
                  autoComplete="off"
                  style={{ display: "none" }}
                />

                <MyTextField
                  disabled={true}
                  variant="outlined"
                  style={{ color: "black" }}
                  value={labelImage}
                  name="file"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <Box mt={3} ml={-2}>
                  <Tooltip
                    title={`${intl.formatMessage({ id: "SearchDocument" })}`}
                  >
                    <label htmlFor="icon-button-file">
                      <IconButton component="span">
                        <FileCopy />
                      </IconButton>
                    </label>
                  </Tooltip>
                </Box>
              </Grid>
              {noValid && (
                <Grid item xs={12}>
                  <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                    <FormattedMessage id="ValidDocumentalFiles" />
                  </Box>
                </Grid>
              )}
              {noValidSize && (
                <Grid item xs={12}>
                  <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                    <FormattedMessage id="ValidDocumentalFilesSize" />
                  </Box>
                </Grid>
              )}
            </Grid>
            {fileNames.map(({ fileName, url }) => {
              return (
                <Grid container key={fileName}>
                  <Grid item xs={6} key={fileName}>
                    <Box style={{ fontSize: 12, color: "blue" }}>
                      <a href={url} target="__blank">
                        {fileName}
                      </a>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Tooltip title={`${intl.formatMessage({ id: "Delete" })}`}>
                      <IconButton
                        onClick={() => handleDeleteFile(fileName, url !== "#")}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              );
            })}

            <Box mt={2} display="flex" flexDirection="row-reverse" width={1}>
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="Save" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

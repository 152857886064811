import clsx from "clsx";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@material-ui/core";

import { useStyles } from "../../../shared/styles/useStyles";
import { uiCloseModalInfo } from '../../../redux/actions/uiActions';
import { startAproveTransfer } from "../../../redux/actions/fileModuleActions";
import { AppState } from "../../../redux/reducers/rootReducer";

export const ApproveTransfer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { activeTransfer: active } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { transfers } = useSelector(
    (state: AppState) => state.fileModule
  );
  const { transfers:transferManagment } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const transferManag = transferManagment.find(tra=>tra.idTransfer === active?.idTransfer);
  const transferCentralFile = transfers.find(tra=>tra.idTransfer === active?.idTransfer);
  const isTransferFromCentralFile = transferCentralFile && !transferManag?true:false


  const onClose = () => {
    dispatch(uiCloseModalInfo());
  };

  const handleAproveTransfer = async() => {
    setLoading(true);
    // TODO: Disparar acción
    await dispatch( startAproveTransfer(isTransferFromCentralFile) )
    setLoading(false);
  };

  return (
    <>
      <Box m={2} mt={1} mb={2}>
        <Typography variant="body2" style={{ fontSize: 14 }}>
          <FormattedMessage id="ApproveTransferMessage" />
        </Typography>
      </Box>
      <Divider variant="fullWidth" />
      <Box mt={2} display="flex" flexDirection="row-reverse" width={1}>
        {!loading ? (
          <Button
            className={clsx(classes.btn, classes.save)}
            autoFocus
            type="submit"
            disabled={loading}
            onClick={handleAproveTransfer}
          >
            <FormattedMessage id="Approve" />
          </Button>
        ) : (
          <Button
            className={clsx(classes.btn, classes.save)}
            autoFocus
            type="button"
            disabled={true}
          >
            <CircularProgress className={classes.btnLoading} />
          </Button>
        )}
        <Button className={clsx(classes.btn, classes.cancel)} onClick={onClose}>
          <FormattedMessage id="Cancel" />
        </Button>
      </Box>
    </>
  );
};

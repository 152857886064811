export enum TypeUser {
  ADMIN = "ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
  OFFICER = "OFFICER",
}
export enum ModulesType {
  FileModule = "FileModule",
  DocumentManagment = "DocumentManagment",
  Correspondence = "Correspondence",
  StockTacking = "StockTacking",
}

export enum TypeDocUsers {
  CC = "CC",
  CE = "CE",
  PASSPORT = "Pasaporte",
}
export enum VersionOffice {
  VERSION_1 = "1.0",
  VERSION_2 = "2.0",
  VERSION_3 = "3.0",
}

export enum ProductionOffice {
  HOGAR = "Hogar",
  JUDICIAL = "Judicial",
  JURIDICA = "Juridica",
}
export enum TypeEntity {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
}
export enum TypeDays {
  CALENDAR = "CALENDAR",
  BUSINESSDAYS = "BUSINESSDAYS",
}
export enum TypeInbox {
  ANNOTATION = "Annotation",
  OFFICIAL_COMMUNICATION = "OFFICIAL_COMMUNICATION",
  NO_OFFICIAL_COMMUNICATION = "NO_OFFICIAL_COMMUNICATION",
}
export enum CommunicationChannels {
  SINGLE_WINDOW = "01",
  EMAIL = "02",
  FACEBOOK = "03",
  WHATSAPP = "04",
  CALL = "05",
  INSTITUTIONAL_PAGE = "06",
  FAX = "07",
  SAC = "08",
  VERBAL_COMMUNICATION = "09",
}

export enum CorrespondenceInputType {
  ER = "ER",
  EP = "EP",
  IR = "IR",
  IP = "IP",
  IPR = "IP-IR",
  OR = "OR",
  OP = "OP",
}
export enum CorrespondenceStatus {
  IN_PROCESS = "InProcess",
  RESOLVED = "Resolved",
  INACTIVE = "Inactive", //cuando se elimine una correspondencia se cambiara a este estado
}

export enum InternalCorrespondenceStatus {
  RESPONSE_ISSUED_ONTIME = "Response_Issued_OnTime",
  EXTEMPORANEOUS_RESPONSE = "Extemporaneous_Response",
  UNANSWERED = "Unanswered",
  NO_ANSWER_REQUIRED = "No_Answer_Required",
}

export enum ContractingOffice {
  MAYOROFFICE = "Mayor_Office",
  SECRETARY_OF_GOVERNEMENT = "Secretary_Of_Government",
  PLANNING_SECRETARIAT = "Planning_Secretariat",
  FINANCIAL_SECRETARIAT = "Financial_Secretariat",
  POLICE_INSPECTION = "Police_Inspection",
}

export enum ActionSettled {
  RECLASSIFY = "ReclassifySettled",
  REASSIGN = "ReassignSettled",
  SEND_COPY = "SendCopyOfSettled",
  RETURN = "Return",
  APPROVED = "RequestForApproval",
  REQUEST_FOR_PROJECTION = "RequestForProjection",
  CLASSIFY_WITH_TRD = "ClassifyWithTRDTitle",
  ASSIGN_TO_FILE = "AssignToFile",
  ANNOTATION = "CreateAnnotation",
  UPLOAD_DOCUMENT = "AttachDocument",
  REQUEST_ADDITIONAL_INFORMATION = "RequestAdditionalInformation",
  ISSUANCE_OF_RESPONSE = "IssuanceOfResponse",
  ISSUANCE_VO_BO = "IssuanceOfVoBoToResponse",
  VO_BO_APPROVED = "VoBoApproved",
  VO_BO_REJECTED = "VoBoRejected",
  EDIT_EXPEDIENT = "UpdateFile",
  EXTENSION_REQUEST = "extensionRequest",
  SEND_EMAIL = "SendToEmail",
}

export enum TransferType {
  PRIMARY_TRANSFER = "PrimaryTransfer",
  SECONDARY_TRANSFER = "SecondaryTransfer",
}

export enum TypeArchive {
  MANAGEMENT_FILE = "ManagementFile",
  CENTRAL_ARCHIVE = "CentralArchive",
  HISTORICAL_ARCHIVE = "HistoricalArchive",
}
export enum SupportType {
  PAPER = "Paper",
  ELECTRONIC = "Electronic",
}
export enum Enum_Notification_Type {
  ER = "ER",
  EP = "EP",
  FA = "FA",
  SI = "SI",
  EXTENSION = "EX",
}

export enum Enum_FileSize {
  // 1000MB
  maxAllowedSize = 1000 * 1024 * 1024,
}

export enum LabelsSize {
  SIZE_89MM = 0,
  SIZE_10CM = 1,
}

import React from "react";
import { Box,  Divider,  Paper } from "@material-ui/core";
import { useStyles } from "../../../shared/styles/useStyles";

import {
  uiCloseErrorAlert,

  uiCloseSuccessAlert,

} from "../../../redux/actions/uiActions";
import { DepositForm } from "./DepositForm";
import { DepositList } from "./DepositList";
import { MyAlert } from "../../custom/MyAlert";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";

export const DepositParam = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
 
  const {
    errorAlert,
    errorMsg,
   
    successMsg,
    successAlert,
  } = useSelector((state: AppState) => state.ui);
  return (
    <>
      <Divider variant="fullWidth" />
      <Paper square className={classes.paper}>
        <Box mb={3}>
          <Box style={{ height: "5px" }} />
          <Box m={2}>
           
            <DepositForm/>
            <DepositList/>
            
            {successMsg && (
              <MyAlert
                open={successAlert}
                typeAlert="success"
                message={successMsg}
                time={2000}
                handleClose={() => dispatch(uiCloseSuccessAlert())}
              />
            )}
            {errorMsg && (
              <MyAlert
                open={errorAlert}
                typeAlert="error"
                message={errorMsg}
                time={3000}
                handleClose={() => dispatch(uiCloseErrorAlert())}
              />
            )}
          </Box>
        </Box>
      </Paper>
    </>
  );
};

// import { getEntities } from "../../services/firebase/entities";
import { ICorrespondence } from "../../interfaces/Correspondence";
import {
  deleteFuidDb,
  listenerFuid,
  setEditCorrespondenceFileDb,
  setEditExpedientDb,
} from "../../services/firebase/fuid";
import { AppState } from "../reducers/rootReducer";
import { IFuid, Types, FuidAction, IDocumentsFuid } from "../types/types";
import { setCorrespondence } from "./correspondenceActions";
import {
  setErrorMsg,
  setSuccessMsg,
  uiCloseModalAction,
  uiOpenErrorAlert,
  uiOpenSuccessAlert,
} from "./uiActions";

// cargar todos los expedientes al redux desde la base de datos
export const startLoadFuid = (idEntity: string) => {
  return async (dispatch: any) => {

    listenerFuid(idEntity, dispatch )
  };
};

//BORRAR UN EXPEDIENTE DE LA BASE DE DATOS Y DEL REDUX
export const startDeleteFuid = (idFuid: string) => {
  return async (dispatch: Function, getState: Function) => {
    const closeErrorFuid = async () => {
      await dispatch(setErrorMsg("ErrorDeletedExpedient"));
      await dispatch(uiCloseModalAction());
      await dispatch(uiOpenErrorAlert());
    };
    const { user } = (getState() as AppState).auth;
    if (user && user.idEntity) {
      const { ok } = await deleteFuidDb(user.idEntity, idFuid);
      if (ok) {
        await dispatch(deleteFuid(idFuid));
        await dispatch(setSuccessMsg("DeletedFuid"));
        await dispatch(uiCloseModalAction());
        await dispatch(uiOpenSuccessAlert());
      } else {
        await closeErrorFuid();
      }
    } else {
      await closeErrorFuid();
    }
  };
};

//EJECUTAR EL DISPATCH PARA CARGAR LOS EXPEDIENTES DEL REDUX
export const loadFuid = (fuid: IFuid[]): FuidAction => ({
  type: Types.loadFuid,
  payload: fuid,
});

//EJECUTAR EL DISPTACH PARA ELIMINAR ALGUN EXPEDIENTE DEL REDUX
export const deleteFuid = (idFuid: string): FuidAction => ({
  type: Types.deleteFuid,
  payload: idFuid,
});

//DISPATCH PARA EDITAR EXPEDIENTE
export const setActiveFuid = (expedient: IFuid | null): FuidAction => ({
  type: Types.setActiveFuid,
  payload: expedient,
});

// FUNCION PARA EDITAR CORRESPONDENCE Y AGREGAR EXPEDIENTE A LA CORRESPONDENCIA
export const editCorrespondenceFuid = (
  correspondence: ICorrespondence,
  expedient: IFuid
) => {
  return async (dispatch: any, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    if (user && user.idEntity) {
      const { ok } = await setEditCorrespondenceFileDb(
        correspondence,
        user.idEntity
      );
      if (ok) {
        await setEditExpedientDb(expedient, user.idEntity);
        await dispatch(addNewFuidState(expedient));
        dispatch(uiOpenSuccessAlert());
        dispatch(setSuccessMsg("ExpedientSaved"));
        dispatch(setCorrespondence(correspondence));
      } else {
        dispatch(uiOpenErrorAlert());
        dispatch(setErrorMsg("ErrorExpedientSaved"));
      }
    }
  };
};

// EJECUTAR EL DISPATCH PARA GUARDAR LOS EXPEDIENTES DEL REDUX
const addNewFuidState = (fuid: IFuid): FuidAction => ({
  type: Types.addFuid,
  payload: fuid,
});
export const setActiveDocument = (
  document: IDocumentsFuid | null
): FuidAction => ({
  type: Types.setActiveDocument,
  payload: document,
});

import { firebase } from "../../config/firebase/firebase-config";
import { IAuthActions, IUserLoggedIn, Types } from "../types/types";
import { purgeCorrespondence } from "./correspondenceActions";
import { finishLoading, setErrorMsg } from "./uiActions";
import { getUserData } from "../../services/firebase/auth";
import {
  uiOpenErrorAlert,
  uiOpenSuccessAlert,
  startLoading,
} from "./uiActions";

export const startLogin = (email: string, password: string) => {
  return async (dispatch: Function) => {
    dispatch(startLoading());

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async ({ user }) => {
        if (user?.email) {
          const userData = await getUserData(user.email);

          if (userData && userData.active) {
            dispatch(Login(userData));
            window.location.reload();
          } else {
            dispatch(setErrorMsg("InactiveUser"));
            dispatch(uiOpenErrorAlert());
          }
        } else {
          dispatch(setErrorMsg("IncorrectEmailPassword"));
          dispatch(uiOpenErrorAlert());
        }
        dispatch(finishLoading());
      })
      .catch((e) => {
        dispatch(setErrorMsg("IncorrectEmailPassword"));
        dispatch(uiOpenErrorAlert());
        dispatch(finishLoading());
      });
  };
};
export const Login = (user: IUserLoggedIn): IAuthActions => ({
  type: Types.login,
  payload: user,
});
export const startLogout = () => {
  return async (dispatch: any) => {
    //LIMPIAR LOS REDUCERS
    await dispatch(logout());
    await dispatch(purgeCorrespondence());
    await firebase.auth().signOut();
  };
};
const logout = (): IAuthActions => ({
  type: Types.logout,
});
export const startChangePassword = (
  currentPassword: string,
  newPassword: string
) => {
  return async (dispatch: Function) => {
    const resp = reauthenticate(currentPassword);
    resp
      ?.then(() => {
        const user = firebase.auth().currentUser;
        user
          ?.updatePassword(newPassword)
          .then(() => {
            dispatch(uiOpenSuccessAlert());
            setTimeout(() => {
              dispatch(startLogout());
            }, 3000);
          })
          .catch((error) => {
            dispatch(uiOpenErrorAlert());
            throw new Error(error);
          });
      })
      .catch((error) => {
        dispatch(uiOpenErrorAlert());
        throw new Error(error);
      });
  };
};

const reauthenticate = (currentPassword: string) => {
  const user = firebase.auth().currentUser;

  if (user?.email) {
    const cred = firebase.auth.EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    return user.reauthenticateWithCredential(cred);
  }
};

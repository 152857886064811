import clsx from "clsx";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { ICorrespondence } from "../../../interfaces/Correspondence";
// import { uiOpenModalSee } from "../../../redux/actions/uiActions";
import { AppState } from "../../../redux/reducers/rootReducer";
// import { setActiveCorrespondenceDocManagment } from "../../../redux/actions/documentManagmentActions";
import { Colors } from "../../../shared/constants/themes/Colors";
import { useStyles } from "../../../shared/styles/useStyles";

export const SearchAddresseSender = () => {
  const classes = useStyles();
  const [noValid, setNoValid] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [results, setResults] = useState<ICorrespondence[]>([]);
  const [values, setValues] = useState({
    keyWord: "",
  });
  const { correspondence } = useSelector(
    (state: AppState) => state.correspondence
  );
  // const dispatch = useDispatch();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  const handleSearch = () => {
    if (values.keyWord !== "") {
      const filterCorrespondence = correspondence.filter(
        (corres) =>
          corres.addressee.name
            .toLowerCase()
            .includes(values.keyWord.toLowerCase()) ||
          corres.addressee.id
            .toString()
            .toLowerCase()
            .includes(values.keyWord.toLowerCase()) ||
          corres.externalSender.name
            .toLowerCase()
            .includes(values.keyWord.toLowerCase()) ||
          corres.externalSender.id
            .toString()
            .toLowerCase()
            .includes(values.keyWord.toLowerCase())
      );
      setNoValid(false);
      setResults(filterCorrespondence);
      filterCorrespondence.length === 0
        ? setShowAlert(true)
        : setShowAlert(false);
    } else {
      setNoValid(true);
    }
  };
  // const setActiveCorres = (
  //   event: React.SyntheticEvent,
  //   correspondence: ICorrespondence
  // ) => {
  //   event.preventDefault();
  //   dispatch(uiOpenModalSee());
  //   dispatch(setActiveCorrespondenceDocManagment(correspondence));
  // };

  const handleClean = () => {
    setResults([]);
    setShowAlert(false);
    setNoValid(false);
  };

  return (
    <Box padding={2} paddingTop={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.title}>
            <FormattedMessage id="AddresseSenderFinder" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.typography}>
            <FormattedMessage id="keyWordCorrespondence" />:
          </Typography>

          <TextField
            className={classes.myTextFieldRoot}
            name="keyWord"
            size="small"
            variant="outlined"
            onChange={handleChange}
            value={values.keyWord}
            onKeyPress={(event) => event.key === "Enter" && handleSearch()}
          />
        </Grid>
        {noValid && (
          <Grid item xs={12}>
            <Box mt={-1} ml={2} style={{ fontSize: 12, color: "red" }}>
              <FormattedMessage id="EnterValidValue" />
            </Box>
          </Grid>
        )}
        {<Grid item xs={4} />}
        {/* BOTON BUSQUEDA */}
        <Grid item xs={4}>
          <Button
            className={clsx(classes.btnAction, classes.save)}
            onClick={handleSearch}
          >
            <FormattedMessage id="Search" />
          </Button>
        </Grid>
        {/* BOTON LIMPIAR */}
        <Grid item xs={4}>
          <Button
            className={clsx(classes.btnAction, classes.cancel)}
            onClick={handleClean}
          >
            <FormattedMessage id="Clean" />
          </Button>
        </Grid>
        {/* RESULTADOS */}
        {results.length > 0 && (
          <Grid item xs={12} className={classes.typography}>
            <label
              className="form-text"
              style={{ display: "inline-block", marginBottom: 20 }}
            >
              <FormattedMessage id="Results" />:
            </label>
            <div className="container-scroll">
              {results.map((result) => (
                <Grid container key={result.id} spacing={2}>
                  <Grid item xs={5}>
                    {result.id}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      {/* <Link
                        href="#"
                        onClick={(e) => setActiveCorres(e, result)}
                      > */}
                        {result.description}
                      {/* </Link> */}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    Destinatario: {result.addressee.name}
                  </Grid>
                </Grid>
              ))}
            </div>
          </Grid>
        )}

        {results.length === 0 && showAlert && (
          <Grid item xs={12} className={classes.typography}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ height: 200 }}
              className={classes.title}
            >
              <InfoOutlinedIcon
                style={{ fontSize: 30, color: Colors.ACCENT }}
              />
              <FormattedMessage id={"NoData"} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

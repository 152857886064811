import { Types } from "../types/types";

export const uiOpenModalAdd = () => ({ type: Types.uiOpenModalAdd });

export const uiCloseModalAdd = () => ({ type: Types.uiCloseModalAdd });

export const uiOpenModalEdit = () => ({ type: Types.uiOpenModalEdit });

export const uiCloseModalEdit = () => ({ type: Types.uiCloseModalEdit });

export const uiOpenModalDelete = () => ({ type: Types.uiOpenModalDelete });

export const uiCloseModalDelete = () => ({ type: Types.uiCloseModalDelete });

export const uiOpenModalInfo = () => ({ type: Types.uiOpenModalInfo });

export const uiCloseModalInfo = () => ({ type: Types.uiCloseModalInfo });

export const uiOpenModalAttachDocuments = () => ({
  type: Types.uiOpenModalAttachDocuments,
});

export const uiCloseModalAttachDocuments = () => ({
  type: Types.uiCloseModalAttachDocuments,
});

export const uiOpenModalAssignModules = () => ({
  type: Types.uiOpenModalAssignModules,
});

export const uiCloseModalAssignModules = () => ({
  type: Types.uiCloseModalAssignModules,
});

export const uiOpenModalAssignOffices = () => ({
  type: Types.uiOpenModalAssignOffices,
});

export const uiCloseModalAssignOffices = () => ({
  type: Types.uiCloseModalAssignOffices,
});

export const uiOpenAlertExists = () => ({ type: Types.uiOpenAlertExists });

export const uiCloseAlertExists = () => ({ type: Types.uiCloseAlertExists });

export const uiOpenSuccessAlert = () => ({ type: Types.uiOpenSuccessAlert });

export const uiCloseSuccessAlert = () => ({ type: Types.uiCloseSuccessAlert });

export const uiOpenErrorAlert = () => ({ type: Types.uiOpenErrorAlert });

export const uiCloseErrorAlert = () => ({ type: Types.uiCloseErrorAlert });

export const uiOpenSuccessChange = () => ({ type: Types.uiOpenSuccessChange });

export const uiCloseSuccessChange = () => ({
  type: Types.uiCloseSuccessChange,
});
export const setSuccessMsg = (msg: string | null) => ({
  type: Types.uiSetSuccesMsg,
  payload: msg,
});
export const setErrorMsg = (msg: string | null) => ({
  type: Types.uiSetErrorMsg,
  payload: msg,
});
export const uiOpenModalSee = () => ({
  type: Types.uiOpenModalSee,
});
export const uiCloseModalSee = () => ({
  type: Types.uiCloseModalSee,
});
export const uiOpenModalAction = () => ({
  type: Types.uiOpenModalAction,
});
export const uiCloseModalAction = () => ({
  type: Types.uiCloseModalAction,
});

export const uiOpenModalAnswer = () => ({ type: Types.uiOpenModalAnswer });

export const uiCloseModalAnswer = () => ({ type: Types.uiCloseModalAnswer });

export const uiCloseAnswerModal = () => ({ type: Types.uiCloseAnswerModal });

export const uiOpenModalAnswerBroadcast = () => ({
  type: Types.uiOpenModalAnswerBroadcast,
});

export const uiCloseModalAnswerBroadcast = () => ({
  type: Types.uiCloseModalAnswerBroadcast,
});

export const uiOpenModalEmailStatusHistory = () => ({
  type: Types.uiOpenModalEmailStatusHistory,
});

export const uiCloseModalEmailStatusHistory = () => ({
  type: Types.uiCloseModalEmailStatusHistory,
});

export const uiOpenModalEmailSent = () => ({
  type: Types.uiOpenModalEmailSent,
});

export const uiCloseModalEmailSent = () => ({
  type: Types.uiCloseModalEmailSent,
});

export const uiOpenModalAnswerBroadcastEdit = () => ({
  type: Types.uiOpenModalAnswerBroadcastEdit,
});

export const uiCloseModalAnswerBroadcastEdit = () => ({
  type: Types.uiCloseModalAnswerBroadcastEdit,
});
export const uiOpenModalReject = () => ({
  type: Types.uiOpenModalReject,
});

export const uiCloseModalReject = () => ({
  type: Types.uiCloseModalReject,
});

export const startLoading = () => ({ type: Types.uiStartLoading });

export const finishLoading = () => ({ type: Types.uiFinishLoading });

export const setQuestionMsg = (msg: string | null) => ({
  type: Types.uiSetQuestionMsg,
  payload: msg,
});
export const setTitleMsg = (msg: string | null) => ({
  type: Types.uiSetTitleMsg,
  payload: msg,
});
export const uiSetOpenAnswerModal = () => ({
  type: Types.uiOpenModalAnswerCorrespondence,
});

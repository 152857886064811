import { Redirect, Route, Switch } from "react-router";
import { ModulesType } from "../enums/enums";
import { FilesScreen } from "../pages/filesModule/FilesScreen";
import { TransfersScreen } from "../pages/filesModule/TransfersScreen";
import { FuidManagmentScreen } from "../pages/documentManagmentModule/FuidManagmentScreen";
import { CentralFilesScreen } from "../pages/filesModule/CentralFilesScreen";
import { HistoricalFilesScreen } from "../pages/filesModule/HistoricalFilesScreen";
import { UnaccountedFilesScreen } from "../pages/filesModule/UnaccountedFilesScreen";
import { ParameterizationFilesScreen } from "../pages/filesModule/ParameterizationFilesScreen";
import { TRDTab } from "../components/admin/TRD/trd/TRDTab";
import { PhysicalLoansScreen } from "../pages/filesModule/PhysicalLoansScreen";
import { DigitalLoansScreen } from "../pages/filesModule/DigitalLoansScreen";
import { LoansScreen } from "../pages/filesModule/LoansScreen";

export const FileModuleRouter = () => {
  return (
    <div>
      <Switch>
        <Route
          path={`/${ModulesType.FileModule.toLowerCase()}/reports`}
          component={FilesScreen}
        />
        <Route
          path={`/${ModulesType.FileModule.toLowerCase()}/transfers`}
          component={TransfersScreen}
        />
        <Route
          path={`/${ModulesType.FileModule.toLowerCase()}/fuid`}
          component={FuidManagmentScreen}
        />
        <Route
          path={`/${ModulesType.FileModule.toLowerCase()}/loans`}
          component={LoansScreen}
        />
        <Route
          path={`/${ModulesType.FileModule.toLowerCase()}/physical-loans`}
          component={PhysicalLoansScreen}
        />
        <Route
          path={`/${ModulesType.FileModule.toLowerCase()}/digital-loans`}
          component={DigitalLoansScreen}
        />

        <Route
          path={`/${ModulesType.FileModule.toLowerCase()}/unaccounted-files`}
          component={UnaccountedFilesScreen}
        />
        <Route
          path={`/${ModulesType.FileModule.toLowerCase()}/central-files`}
          component={CentralFilesScreen}
        />
        <Route
          path={`/${ModulesType.FileModule.toLowerCase()}/historical-files`}
          component={HistoricalFilesScreen}
        />
        {/* --------------------- DESPUES HAY QUE PONER EL RESPOECTIVO COMPONENTE DEL MODULO, POR AHORA SE PONEN LOS MISMO DE GESTION DOCUMETAL ---------------- */}
        <Route
          path={`/${ModulesType.FileModule.toLowerCase()}/trd`}
          component={TRDTab}
        />
        <Route
          path={`/${ModulesType.FileModule.toLowerCase()}/parameterization`}
          component={ParameterizationFilesScreen}
        />
        {/* --------------------- **************** ---------------- */}
        {/* --------------- DEMAS RUTAS -------------- */}
        <Redirect to={`/${ModulesType.FileModule.toLowerCase()}/reports`} />
      </Switch>
    </div>
  );
};

import { Entity } from "../../interfaces/Users";
import {
  addInitialSettledDb,
  createEntity,
  getEntities,
  getEntityById,
  loadInitialSettledDb,
} from "../../services/firebase/entities";
import { AppState } from "../reducers/rootReducer";
import { IEntitiesAction, IInitialSettled, Types } from "../types/types";
import {
  setErrorMsg,
  setSuccessMsg,
  uiOpenErrorAlert,
  uiOpenSuccessAlert,
} from "./uiActions";
export const startLoadEntities = () => {
  return async (dispatch: any) => {
    const response = await getEntities();
    if (response) {
      dispatch(loadEntities(response));
    }
  };
};
export const loadEntities = (entities: Entity[]): IEntitiesAction => ({
  type: Types.entitiesLoad,
  payload: entities,
});
export const startAddEntity = (entity: Entity, uid: string) => {
  return async (dispatch: any) => {
    const newEntity = { ...entity, uid };
    //Agregar a la bd
    try {
      await createEntity(entity, uid);
      dispatch(addEntity(newEntity));
    } catch (error) {
      dispatch(uiOpenErrorAlert());
    }
  };
};

export const addEntity = (entity: Entity): IEntitiesAction => ({
  type: Types.addEntity,
  payload: entity,
});
export const editEntity = (entity: Entity, oldId: string): IEntitiesAction => ({
  type: Types.editEntity,
  payload: { entity, oldId },
});

export const startDeleteEntity = (idEntity: string) => {
  return (dispatch: any) => {
    //falta borrar de firebase
    dispatch(deleteEntity(idEntity));
  };
};
export const deleteEntity = (idEntity: string): IEntitiesAction => ({
  type: Types.deleteEntity,
  payload: idEntity,
});
export const setActiveEntity = (entity: Entity): IEntitiesAction => ({
  type: Types.setActiveEntity,
  payload: entity,
});
export const purgeActiveEntity = (): IEntitiesAction => ({
  type: Types.purgeActiveEntity,
});

/* ACTION LOAD INITIAL SETTLED IN ENTITY*/
export const startLoadInitialSettled = (idEntity: string) => {
  return async (dispatch: Function) => {
    const initialSettled = await loadInitialSettledDb(idEntity);
    if (initialSettled) {
      dispatch(setInitialSettledRedux(initialSettled));
    }
  };
};

export const getEntity = (idEntity: string) => {
  return async (dispatch: Function) => {
    const entity = await getEntityById(idEntity);
    dispatch(setActiveEntity(entity as Entity));
  };
};

/* ACTION SAVE INITIAL SETTLED IN ENTITY */
export const startSetInitialSettled = (data: IInitialSettled) => {
  return async (dispatch: any, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    if (user && user.idEntity) {
      const { ok } = await addInitialSettledDb(user.idEntity, data);
      if (ok) {
        dispatch(setInitialSettledRedux(data.initialSettled));
        dispatch(setSuccessMsg("AddInitialSettledSucces"));
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(setErrorMsg("AddInitialSettledError"));
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};

/* ACTION REDUX SAVE AND LOAD INITIAL SETTLED */
export const setInitialSettledRedux = (
  initialSettled: string
): IEntitiesAction => ({
  type: Types.addInitialSettled,
  payload: initialSettled,
});

import clsx from "clsx";
import { Form, Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { MyAlert } from "../../custom/MyAlert";
import { MyTextField } from "../../custom/MyTextField";
import { TypeDocUsers } from "../../../enums/enums";
import { useStyles } from "../../../shared/styles/useStyles";
import { ProponentTable } from "./ProponentTable";
import { IProponent } from "../../../interfaces/Correspondence";
import { startNewProponent } from "../../../redux/actions/correspondenceActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { uiCloseAlertExists } from "../../../redux/actions/uiActions";

export const ProponentForm = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { alertExists, errorMsg } = useSelector((state: AppState) => state.ui);

  const initialValues: IProponent = {
    idType: "",
    phone: "",
    deliveryName: "",
    legalRepresentative: "",
    noEnvelopes: 0,
    idNumber: "",
    businessName: "",
    settledId: "",
  };

  const validationSchema = yup.object({
    businessName: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    legalRepresentative: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    deliveryName: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    idType: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    idNumber: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`)
      .min(5, `${intl.formatMessage({ id: "MinimumDocument" })}`),
    phone: yup
      .number()
      .typeError(`${intl.formatMessage({ id: "NumericValue" })}`)
      .min(0, `${intl.formatMessage({ id: "PositiveNumber" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    noEnvelopes: yup
      .number()
      .min(0, `${intl.formatMessage({ id: "PositiveNumber" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  return (
    <Box m={2} mt={-1} mb={2}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          await dispatch(startNewProponent(data));
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.input}>
            <Grid container spacing={1}>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <label className="form-text">
                  <FormattedMessage id="BusinessNameOfProponent" />:
                </label>
                <MyTextField
                  name="businessName"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="LegalRepresentative" />:
                </label>
                <MyTextField
                  name="legalRepresentative"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="DeliveryPersonNameOne" />:
                </label>
                <MyTextField
                  name="deliveryName"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={4}>
                <label className="form-text">
                  <FormattedMessage id="DocumentType" />:
                </label>
                <MyTextField
                  name="idType"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                >
                  <MenuItem value={TypeDocUsers.CC}>
                    <FormattedMessage id="CitizenshipCard" />
                  </MenuItem>
                  <MenuItem value={TypeDocUsers.CE}>
                    <FormattedMessage id="ForeignersIdentityCard" />
                  </MenuItem>
                  <MenuItem value={TypeDocUsers.PASSPORT}>
                    <FormattedMessage id="Passport" />
                  </MenuItem>
                </MyTextField>
              </Grid>

              <Grid item xs={4}>
                <label className="form-text">
                  <FormattedMessage id="DocumentNumber" />:
                </label>
                <MyTextField
                  name="idNumber"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={4}>
                <label className="form-text">
                  <FormattedMessage id="Phone" />:
                </label>
                <MyTextField
                  name="phone"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={8}>
                <Box display="flex" justifyContent="space-between">
                  <label className="form-text" style={{ marginTop: "8px" }}>
                    <FormattedMessage id="NumberOfEnvelopes" />:
                  </label>

                  <Box width={"50%"}>
                    <MyTextField
                      name="noEnvelopes"
                      variant="outlined"
                      type="number"
                      className={classes.myTextFieldRoot}
                    />
                  </Box>
                </Box>
              </Grid>

              <Box mt={-6} display="flex" flexDirection="row-reverse" width={1}>
                {!isSubmitting ? (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id="Add" />
                  </Button>
                ) : (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="button"
                    disabled={true}
                  >
                    <CircularProgress className={classes.btnLoading} />
                  </Button>
                )}
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>

      <Box mt={2}>
        <ProponentTable />
      </Box>

      {errorMsg && (
        <MyAlert
          open={alertExists}
          typeAlert="error"
          message={errorMsg}
          time={2000}
          handleClose={() => dispatch(uiCloseAlertExists())}
        />
      )}
    </Box>
  );
};

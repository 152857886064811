import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid, MenuItem, TextField } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { CustomizedSearch } from "../../components/custom/CustomizedSearch";
import { OrganizationChartTable } from "../../components/admin/oganizationChart/OrganizationChartTable";
import CustomizedDialog from "../../components/custom/CustomizedDialog";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import {
  uiOpenModalAdd,
  uiCloseModalAdd,
  uiCloseModalEdit,
  uiCloseModalDelete,
  uiCloseSuccessAlert,
  setSuccessMsg,
} from "../../redux/actions/uiActions";
import { AppState } from "../../redux/reducers/rootReducer";
import { useStyles } from "../../shared/styles/useStyles";
import { FormAddOrganizationChart } from "../../components/admin/oganizationChart/FormAddOrganizationChart";
import { officeCleanActive } from "../../redux/actions/organizationChartActions";
import { MyAlert } from "../../components/custom/MyAlert";
import { useEffect, useState } from "react";
import { Office } from "../../interfaces/OrganizationChart";
import { ITrdVersion } from "../../redux/types/types";
import { ConfirmDelete } from "../../components/ui/shared/ConfirmDelete";

export const OrganizationChartScreen = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { modalAddOpen, modalEditOpen, modalDeleteOpen } = useSelector(
    (state: AppState) => state.ui
  );
  const { successAlert, successMsg } = useSelector(
    (state: AppState) => state.ui
  );
  const { offices }: any = useSelector(
    (state: AppState) => state.organizationChart
  );

  const { versions }: any = useSelector((state: AppState) => state.trd);
  const [versionSelected, setVersionSelected] = useState("0");
  const [offiecesFilter, setOffiecesFilter] = useState<Office[]>([]);

  const handleSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setVersionSelected(event.target.value as string);
    const versionSelected = event.target.value;
    if (versionSelected === "0") {
      return;
    }
    const versionFilter = versions.filter(
      (el: ITrdVersion) => el.name === versionSelected
    );
    const allOffices = [...offices];
    const officesFilter = allOffices.filter(
      (office: Office) => office.idVersion === versionFilter[0].idVersion
    );
    setOffiecesFilter(officesFilter);
  };

  useEffect(() => {
    if (versionSelected === "0") {
      setOffiecesFilter(offices);
    } else {
      const allOffices = [...offices];
      const versionFilter = versions.filter(
        (el: ITrdVersion) => el.name === versionSelected
      );
      const officesFilter = allOffices.filter(
        (office: Office) => office.idVersion === versionFilter[0].idVersion
      );
      setOffiecesFilter(officesFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionSelected]);

  const onDeny = () => {
    dispatch(officeCleanActive());
    dispatch(uiCloseModalEdit());
  };

  const onDelete = () => {
    dispatch(uiCloseModalDelete());
    dispatch(officeCleanActive());
  };

  const closeSuccessAlert = () => {
    dispatch(uiCloseSuccessAlert());
    dispatch(officeCleanActive());
    dispatch(setSuccessMsg(null));
  };

  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box mb={3}>
        <Box mb={3} fontSize={20}>
          <Grid container>
            <Grid item xs={5}>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                className={classes.btnAdd}
                type="button"
                onClick={() => dispatch(uiOpenModalAdd())}
              >
                <AddOutlinedIcon />
                <FormattedMessage id="CreateNewOffice" />
              </Button>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <CustomizedSearch
                data={versionSelected === "0" ? offices : offiecesFilter}
              />
            </Grid>
          </Grid>
          <br />
          <Grid item xs={12}>
            <label className="form-text">
              <FormattedMessage id="Version" />*
            </label>
            <TextField
              size="small"
              name="versionSelecter"
              variant="outlined"
              select
              className={classes.myTextFieldRoot}
              value={versionSelected}
              onChange={handleSelect}
              disabled={versions.length <= 0}
            >
              {versions.map((version: any) => {
                return (
                  <MenuItem key={version.idVersion} value={version.name}>
                    {version.name}
                  </MenuItem>
                );
              })}
              <MenuItem key="0" value="0">
                {`${intl.formatMessage({ id: "AllVersions" })}`}
              </MenuItem>
            </TextField>
          </Grid>
        </Box>

        <OrganizationChartTable
          offices={versionSelected === "0" ? offices : offiecesFilter}
        />

        <CustomizedDialog
          open={modalAddOpen}
          cancelBtn={true}
          onDeny={() => dispatch(uiCloseModalAdd())}
          title={`${intl.formatMessage({ id: "CreateNewOffice" })}`}
          content={<FormAddOrganizationChart />}
          textButton="Accept"
        />

        <CustomizedDialog
          open={modalEditOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "EditOffice" })}`}
          content={<FormAddOrganizationChart />}
          width
          textButton="Accept"
        />

        <CustomizedDialog
          open={modalDeleteOpen}
          cancelBtn={true}
          onDeny={onDelete}
          title={`${intl.formatMessage({ id: "DeleteOffice" })}`}
          content={
            <ConfirmDelete
              title={`${intl.formatMessage({
                id: "DeleteOffice?",
              })}`}
            />
          }
          textButton="Accept"
        />

        {successMsg && (
          <MyAlert
            open={successAlert}
            typeAlert="success"
            message={successMsg}
            time={2000}
            handleClose={closeSuccessAlert}
          />
        )}
      </Box>
    </AppAnimate>
  );
};

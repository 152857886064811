import React, {  useCallback } from "react";
import { AppBar, Tab, Tabs } from "@material-ui/core";
import { useIntl } from "react-intl";
import { TabPanel, useStyles, a11yProps } from "../../custom/Tabs";
import { TransferDetailsTab } from "./TransferDetailsTab";

import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { DocumentsFuidTab } from "../../document-managment/tabs/DocumentsFuidTab";
import { IDocumentsFuid } from "../../../redux/types/types";

export const TransferDetailsTabs = () => {
  const { activeTransfer } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { expedients } = useSelector((state: AppState) => state.fileModule);
    const classes = useStyles();
  const intl = useIntl();
  const [value, setValue] = React.useState(0);
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
      };

      const getExpedientsDetails = useCallback(
        (expedientsIds: string[]) => {
          const listExpedients = expedientsIds
            .map((expId) => expedients.filter((exp) => exp.id === expId))
            .flat();
          return listExpedients;
        },
        [expedients]
      );
      const expedientDetails = activeTransfer?.expedients? getExpedientsDetails(activeTransfer.expedients):[];
      const documents:IDocumentsFuid[] = expedientDetails.map((exp) => {
        let arrDocs:any[] = []
        if (exp.documents) {
         arrDocs.push(exp.documents)
        }  
        return arrDocs   
      }).flat().flat()
     
  return (
    <div className={classes.root}>
    <AppBar position="static">
      <Tabs
        className={classes.tabs}
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        centered
        TabIndicatorProps={{
          style: {
            backgroundColor: "#0A8FDC",
          },
        }}
      >
        <Tab
          className={classes.tab}
          label={`${intl.formatMessage({ id: "TransferDetails" }).toUpperCase()}`}
          {...a11yProps(0)}
        />
        <Tab
          className={classes.tab}
          label={`${intl.formatMessage({ id: "Documents" }).toUpperCase()}`}
          {...a11yProps(1)}
        />
       
      </Tabs>
    </AppBar>
    <TabPanel value={value} index={0}>
    <TransferDetailsTab/>
    </TabPanel>
    <TabPanel value={value} index={1}>
   <DocumentsFuidTab documents={ documents} readOnly />
    </TabPanel>
  
    
  </div>
  )
}

import React, { FC } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { IAnnotation } from "../../../redux/types/types";
import { useStyles } from "../../../shared/styles/useStyles";
import { CustomizedIcons } from "../../custom/CustomizedIcons";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  setActiveAnnotation,
  startReadedAnnotation,
} from "../../../redux/actions/correspondenceActions";
import {
  uiOpenModalDelete,
  uiOpenModalSee,
} from "../../../redux/actions/uiActions";

interface props {
  annotation: IAnnotation;
  readOnly?: boolean;
}
export const AnnotationsBody: FC<props> = ({
  annotation,
  readOnly = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onSee = () => {
    dispatch(setActiveAnnotation(annotation));
    dispatch(startReadedAnnotation(annotation));
    dispatch(uiOpenModalSee());
  };

  const onDelete = () => {
    dispatch(setActiveAnnotation(annotation));

    dispatch(uiOpenModalDelete());
  };

  return (
    <TableRow key={annotation.id}>
      <TableCell component="th" scope="row" align="center">
        {annotation.id}
      </TableCell>
      <TableCell align="center" className={classes.capitalize}>
        {annotation.subjectAnnotation}
      </TableCell>
      <TableCell align="center">
        {moment(annotation.createdAt.toDate()).format("YYYY/MM/DD")}
      </TableCell>
      {!readOnly && (
        <TableCell align="center">
          <CustomizedIcons
            seeIcon
            deleteIcon
            onSee={onSee}
            onDelete={onDelete}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

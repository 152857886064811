import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import { CustomizedSearch } from "../../components/custom/CustomizedSearch";
import CustomizedDialog from "../../components/custom/CustomizedDialog";
import {
  uiCloseErrorAlert,
  // uiCloseErrorAlert,
  uiCloseModalAdd,
  uiCloseModalDelete,
  uiCloseModalEdit,
  uiCloseModalInfo,
  uiCloseSuccessAlert,
  uiOpenModalAdd,
} from "../../redux/actions/uiActions";
import { AppState } from "../../redux/reducers/rootReducer";
import { useStyles } from "../../shared/styles/useStyles";
import { CorrespondenceTable } from "../../components/correspondence-module/correspondence/CorrespondenceTable";
import { CorrespondenceForm } from "../../components/correspondence-module/correspondence/CorrespondenceForm";
import { MyAlert } from "../../components/custom/MyAlert";
import { ConfirmDelete } from "../../components/ui/shared/ConfirmDelete";
import { setActiveCorrespondence } from "../../redux/actions/correspondenceActions";
import { setActiveExternalSender } from "../../redux/actions/usersActions";

export const CorrespondenceScreen = () => {
  const classes = useStyles();
  const {
    modalAddOpen,
    modalDeleteOpen,
    successAlert,
    successMsg,
    errorAlert,
    errorMsg,
    modalInfoOpen,
    modalEditOpen,
  } = useSelector((state: AppState) => state.ui);
  const { correspondence } = useSelector(
    (state: AppState) => state.correspondence
  );
  const dispatch = useDispatch();
  const intl = useIntl();

  const onDeny = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    dispatch(uiCloseModalDelete());
    dispatch(uiCloseModalInfo());
    dispatch(setActiveCorrespondence(null));
    dispatch(setActiveExternalSender(null));
  };
  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box>
        <Box mb={3} fontSize={20}>
          <Grid container>
            <Grid item xs={5}>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                className={classes.btnAdd}
                type="button"
                onClick={() => dispatch(uiOpenModalAdd())}
              >
                <AddOutlinedIcon />
                <FormattedMessage id="AddNewCommunication" />
              </Button>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <CustomizedSearch data={correspondence} />
            </Grid>
          </Grid>
        </Box>
        <CorrespondenceTable />
        <CustomizedDialog
          open={modalAddOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "NewCommunication" })}`}
          content={<CorrespondenceForm />}
          textButton="Accept"
        />
        <CustomizedDialog
          open={modalEditOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "UpdateCorrespondence" })}`}
          content={<CorrespondenceForm edit />}
          textButton="Accept"
        />
        <CustomizedDialog
          open={modalInfoOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "Correspondence" })}`}
          content={<CorrespondenceForm readOnly />}
          textButton="Accept"
        />
        <CustomizedDialog
          open={modalDeleteOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "DeleteCommunicationQuestion" })}`}
          content={
            <ConfirmDelete
              title={`${intl.formatMessage({
                id: "DeleteCommunication",
              })}`}
            />
          }
          textButton="Accept"
        />

        {successMsg && (
          <MyAlert
            open={successAlert}
            typeAlert="success"
            message={successMsg}
            time={2000}
            handleClose={() => dispatch(uiCloseSuccessAlert())}
          />
        )}

        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={errorMsg ? errorMsg : "SavingDataError"}
          time={2000}
          handleClose={() => dispatch(uiCloseErrorAlert())}
        />
      </Box>
    </AppAnimate>
  );
};

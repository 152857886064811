import React from "react";
import { Box, Button, CircularProgress, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import * as yup from "yup";
import { MyTextField } from "../../custom/MyTextField";
import { useStyles } from "../../../shared/styles/useStyles";
import { useDispatch, useSelector } from "react-redux";
import { uiCloseModalAction } from "../../../redux/actions/uiActions";
import { IRequestExtencionForm } from "../../../interfaces/Correspondence";
import { startRequestExtension } from "../../../redux/actions/documentManagmentActions";
import { AppState } from "../../../redux/reducers/rootReducer";

export const ExtensionRequestForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { paramResponseTime } = useSelector(
    (state: AppState) => state.correspondence
  );
  const daysAvailableDocument =
    //@ts-ignore
    paramResponseTime[activeCorrespondence.communicationType!.option + "Days"];
  const intl = useIntl();

  const onClose = () => {
    dispatch(uiCloseModalAction());
  };
  const initialValues: IRequestExtencionForm = {
    days: 0,
    comment: "",
    subject: `${intl.formatMessage({
      id: "extensionRequest",
    })}`,
  };
  const validationSchema = yup.object({
    days: yup
      .number()
      .integer()
      .moreThan(0, `${intl.formatMessage({ id: "PositiveNumber" })}`)
      .lessThan(
        daysAvailableDocument * 2,
        `${intl.formatMessage({ id: "DaysLessTo" })} ${
          daysAvailableDocument * 2
        }`
      ),

    comment: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });
  return (
    <Box m={2} mt={-1} mb={2}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          //DISPATCH
          if (activeCorrespondence?.finalExtensionDate === undefined) {
            await dispatch(startRequestExtension(values));
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            {activeCorrespondence?.finalExtensionDate !== undefined && (
              <Box
                justifyContent={"center"}
                alignItems="center"
                display={"flex"}
              >
                <label
                  className="form-text"
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  <FormattedMessage id="AlreadyExtensionRequest" />
                </label>
              </Box>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="DaysRequested" />:
                </label>
                <MyTextField
                  name="days"
                  variant="outlined"
                  type="number"
                  className={classes.myTextFieldRoot}
                  disabled={
                    activeCorrespondence?.finalExtensionDate !== undefined
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Comment" />:
                </label>
                <MyTextField
                  name="comment"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  multiline
                  rows={4}
                  disabled={
                    activeCorrespondence?.finalExtensionDate !== undefined
                  }
                />
              </Grid>
            </Grid>

            <Box mt={2} display="flex" flexDirection="row-reverse" width={1}>
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={
                    isSubmitting ||
                    activeCorrespondence?.finalExtensionDate !== undefined
                  }
                >
                  <FormattedMessage id="Save" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

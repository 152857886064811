import { TableCell, TableRow } from "@material-ui/core";
import { useIntl } from "react-intl";
import moment from "moment";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { IFuid } from "../../redux/types/types";
import { useStyles } from "../../shared/styles/useStyles";
import { CustomizedIcons } from "../custom/CustomizedIcons";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveFuid,
  startDeleteFuid,
} from "../../redux/actions/fuidActions";
import Swal from "sweetalert2";
import { uiOpenModalEdit, uiOpenModalSee } from "../../redux/actions/uiActions";
import { AppState } from "../../redux/reducers/rootReducer";
import { setActiveFuidFileModule } from "../../redux/actions/fileModuleActions";
interface props {
  fuid: IFuid;
  fromFilesModule?: boolean;
  readOnly: boolean;
}

export const FuidBody: FC<props> = ({
  fuid,
  readOnly,
  fromFilesModule = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { Deposits } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { Deposits: DepositFiles } = useSelector(
    (state: AppState) => state.fileModule
  );
  const depositsList = fromFilesModule ? DepositFiles : Deposits;

  const onEdit = () => {
    dispatch(setActiveFuid(fuid));
    dispatch(uiOpenModalEdit());
  };
  const getDepositName = (idDeposit: string) => {
    const deposit = depositsList.find((el) => el.id === idDeposit);
    return deposit ? deposit.name : "";
  };

  const onDeleteFuid = (idFuid: string, fuidFilename: string) => {
    Swal.fire({
      title:
        `${intl.formatMessage({ id: "SureDeleteExpedient" })} ` +
        fuidFilename +
        `${intl.formatMessage({ id: "WithCode" })} ` +
        idFuid +
        "?",
      text: `${intl.formatMessage({ id: "ReverseAction" })} `,
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startDeleteFuid(idFuid));
      }
    });
  };

  // FUNCTION OPEN MODAL SEE FUID
  const handleOpen = () => {
    fromFilesModule
      ? dispatch(setActiveFuidFileModule(fuid))
      : dispatch(setActiveFuid(fuid));
    dispatch(uiOpenModalSee());
  };
  return (
    <TableRow
      key={fuid.fileNumber}
      onClick={() => readOnly && handleOpen()}
      className={readOnly ? classes.styleRow : ""}
    >
      <TableCell component="th" scope="row" align="center">
        {fuid.fileNumber}
      </TableCell>
      {/* <TableCell align="center">{communication.inputType}</TableCell> */}
      <TableCell align="center">{fuid.fileName}</TableCell>
      <TableCell align="center">{fuid.description}</TableCell>
      <TableCell align="center" style={{ flexDirection: "column" }}>
        <FormattedMessage id={"Home"} />:<br />
        {moment(fuid.extremeInitialDate.toDate()).format("YYYY/MM/DD")}
        <br />
        <FormattedMessage id={"End"} />:<br />
        {moment(fuid.extremeFinalDate.toDate()).format("YYYY/MM/DD")}
      </TableCell>
      <TableCell align="center">{fuid.volumeNumber}</TableCell>
      <TableCell align="center">{fuid.totalFolderOrVolume}</TableCell>
      <TableCell align="center">{fuid.folder}</TableCell>
      <TableCell align="center">{fuid.trdOrTvd}</TableCell>
      <TableCell align="center">
        {fuid.deposit ? (
          getDepositName(fuid.deposit as any)
        ) : (
          <FormattedMessage id={"WithOutDeposit"} />
        )}
      </TableCell>
      <TableCell align="center">{fuid.shelf}</TableCell>
      <TableCell align="center">{fuid.box}</TableCell>
      {!readOnly && (
        <TableCell align="center">
          <CustomizedIcons
            editIcon
            listIcon
            deleteIcon
            onDelete={() => {
              onDeleteFuid(fuid.id, fuid.fileName);
            }}
            onEdit={onEdit}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

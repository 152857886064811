import clsx from "clsx";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Checkbox, CircularProgress } from "@material-ui/core";

import { useStyles } from "../../../shared/styles/useStyles";
import {
  userCleanActive,
  startAssignModules,
} from "../../../redux/actions/usersActions";
import {
  uiCloseModalAssignModules,
  uiCloseErrorAlert,
} from "../../../redux/actions/uiActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { MyAlert } from "../../custom/MyAlert";

export const AssignModules = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeUser } = useSelector((state: AppState) => state.users);
  const { errorAlert, successMsg } = useSelector((state: AppState) => state.ui);

  const [values, setValues] = useState({
    fileModule: activeUser && activeUser.modules.includes("FileModule") && true,
    documentManagment:
      activeUser && activeUser.modules.includes("DocumentManagment") && true,
    correspondence:
      activeUser && activeUser.modules.includes("Correspondence") && true,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };

  const onClose = () => {
    dispatch(userCleanActive());
    dispatch(uiCloseModalAssignModules());
  };

  const handleAssignModules = async () => {
    setLoading(true);
    let modules: string[] = [];
    values.fileModule && modules.push("FileModule");
    values.documentManagment && modules.push("DocumentManagment");
    values.correspondence && modules.push("Correspondence");

    await dispatch(startAssignModules(activeUser, modules));
    setLoading(false);
  };

  const closeAlert = () => {
    dispatch(uiCloseErrorAlert());
  };

  return (
    <Box m={1}>
      <Box className={classes.typography}>
        <Box mb={1}>
          <FormattedMessage id="MessageAssignModules" />:
        </Box>
        <Box>
          <Checkbox
            checked={values.fileModule}
            name="fileModule"
            onChange={handleChange}
            color="default"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <label>
            <FormattedMessage id="FileModule" />
          </label>
        </Box>
        <Box>
          <Checkbox
            checked={values.documentManagment}
            name="documentManagment"
            onChange={handleChange}
            color="default"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <label>
            <FormattedMessage id="DocumentManagment" />
          </label>
        </Box>
        <Box>
          <Checkbox
            checked={values.correspondence}
            name="correspondence"
            onChange={handleChange}
            color="default"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <label>
            <FormattedMessage id="Correspondence" />
          </label>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row-reverse">
        {!loading ? (
          <Button
            className={clsx(classes.btn, classes.save)}
            autoFocus
            type="button"
            disabled={loading}
            onClick={handleAssignModules}
          >
            <FormattedMessage id="Save" />
          </Button>
        ) : (
          <Button
            className={clsx(classes.btn, classes.save)}
            autoFocus
            type="button"
            disabled={true}
          >
            <CircularProgress className={classes.btnLoading} />
          </Button>
        )}
        <Button className={clsx(classes.btn, classes.cancel)} onClick={onClose}>
          <FormattedMessage id="Cancel" />
        </Button>
      </Box>

      {successMsg && (
        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={successMsg}
          time={2000}
          handleClose={closeAlert}
        />
      )}
    </Box>
  );
};

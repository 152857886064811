import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";

import {
  createTheme,
  ThemeProvider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@material-ui/core";
import { useStyles } from "../../../../shared/styles/useStyles";
import { TRDBody } from "./TRDBody";
import { ITrdTable } from "../../../../redux/types/types";
import { FC } from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Colors } from "../../../../shared/constants/themes/Colors";


const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});
interface props {
  trd:ITrdTable[]
}
export const TRDTable:FC<props> = ({trd}) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper} className={classes.heightTable}>
        {trd.length<=0?
         <Box
         display="flex"
         justifyContent="center"
         alignItems="center"
         style={{ height: 200 }}
         className={classes.title}
       >
         <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
         <FormattedMessage id={"NoCreatedTrd"} />
       </Box>
       :

        <Table
          stickyHeader
          className={classes.table}
          size="small"
          style={{ tableLayout: "auto" }}
          aria-label="a dense table"
        >
          <TableHead className={classes.titleTable}>
            <TableRow className={classes.borderTable}>
              <TableCell
                align="center"
                className={classes.titleTable}
                colSpan={3}
              >
                <FormattedMessage id="Code" />{" "}
              </TableCell>
              <TableCell
                align="center"
                className={classes.titleTable}
                colSpan={3}
              >
                <FormattedMessage id="DocumentaryDescription" />{" "}
              </TableCell>
              <TableCell
                align="center"
                className={clsx(classes.titleTable, classes.capitalize)}
                colSpan={2}
              >
                {`${intl.formatMessage({ id: "Retention" }).toLowerCase()}`}
              </TableCell>
              <TableCell
                align="center"
                className={clsx(classes.titleTable, classes.capitalize)}
                colSpan={2}
              >
                {`${intl.formatMessage({ id: "Support" }).toLowerCase()}`}
              </TableCell>
              <TableCell
                align="center"
                className={clsx(classes.titleTable, classes.capitalize)}
                colSpan={4}
              >
                {`${intl.formatMessage({ id: "Provision" }).toLowerCase()}`}
              </TableCell>
              <TableCell
                align="center"
                className={clsx(classes.titleTable, classes.capitalize)}
                rowSpan={2}
              >
                {`${intl.formatMessage({ id: "Procedure" }).toLowerCase()}`}
              </TableCell>
              <TableCell
                className={clsx(classes.titleTable, classes.capitalize)}
                align="center"
                rowSpan={2}
              >
                {`${intl.formatMessage({ id: "Template" }).toLowerCase()}`}
              </TableCell>
            </TableRow>
            <TableRow className={classes.borderTable}>
              <TableCell align="center">
                <FormattedMessage id="Dependency" />
              </TableCell>
              <TableCell align="center">
                <FormattedMessage id="Series" />
              </TableCell>
              <TableCell align="center">
                <FormattedMessage id="Subseries" />
              </TableCell>
              <TableCell align="center">
                <FormattedMessage id="DocumentarySeries" />
              </TableCell>
              <TableCell align="center">
                <FormattedMessage id="DocumentarySubseries" />
              </TableCell>
              <TableCell align="center">
                <FormattedMessage id="DocumentaryType" />
              </TableCell>
              <TableCell align="center">
                <FormattedMessage id="Management" />
              </TableCell>
              <TableCell align="center">
                <FormattedMessage id="Central" />
              </TableCell>
              <TableCell align="center">P</TableCell>
              <TableCell align="center">EL</TableCell>
              <TableCell align="center">E</TableCell>
              <TableCell align="center">S</TableCell>
              <TableCell align="center">CT</TableCell>
              <TableCell align="center">MD</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trd.map((row,index) => (
              <TRDBody key={row.dependency+"_"+index} {...row} />
            ))}
          </TableBody>
        </Table>
        }
      </TableContainer>
    </ThemeProvider>
  );
};

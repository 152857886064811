import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import {
  createTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { counter } from "../../../helpers/count";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
import { CustomizedTableFooter } from "../../custom/CustomizedTableFooter";
import { PqrsBody } from "./PqrsBody";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      whiteSpace: "nowrap",
    },
    head: {
      height: "40px",
    },
  })
)(TableCell);

/* const pqrs = [
  {
    id: "01ER2021100000000001 ",
    inputType: "Externa recibida",
    communicationType: "Petición",
    subject: "Petición juan perez",
    addressee: "Yolanda Meneses",
    externalSender: "Pepito Peréz",
    receivingDependency: "Secretaria financiera",
    state: "Respuesta emitida a tiempo",
  },
]; */

export const PqrsTable = () => {
  const classes = useStyles();
  const { data, value } = useSelector((state: AppState) => state.search);
  const { correspondence } = useSelector(
    (state: AppState) => state.correspondence
  );
  const pqrs = correspondence.filter(el=> el.answeredDate === null);
  

  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );
  const count = counter(value, pqrs, data);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, pqrs.length - page * rowsPerPage);

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper} className={classes.heightTable}>
        <Table
          className={classes.table}
          size="small"
          style={{ width: "auto", tableLayout: "auto" }}
          aria-label="a dense table"
        >
          <TableHead className={classes.titleTable}>
            <TableRow>
              <StyledTableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="Settled" />{" "}
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="CommunicationType" />{" "}
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="Subject" />{" "}
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="Dependency" />{" "}
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="Addressee" />{" "}
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="DateOfFiling" />{" "}
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="TrafficLight" />{" "}
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="Attachments" />{" "}
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="Viewed" />{" "}
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="State" />{" "}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!value.trim()
              ? (rowsPerPage > 0
                  ? pqrs.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : pqrs
                ).map((row) => <PqrsBody pqrs={row} key={row.id} />)
              : data &&
                (rowsPerPage > 0
                  ? data.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data
                ).map((row) => <PqrsBody pqrs={row} key={row.id} />)}

            {emptyRows > 0 && (
              <TableRow style={{ height: 13 * emptyRows }}>
                <TableCell colSpan={9} />
              </TableRow>
            )}
          </TableBody>
          {/* --------- Footer para paginación -------- */}
          <CustomizedTableFooter count={count} />
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

import { useDispatch, useSelector } from "react-redux";
import { TableFooter, TablePagination, TableRow } from "@material-ui/core";
import { setPage, setRowsPerPage } from "../../redux/actions/paginationActions";
import { AppState } from "../../redux/reducers/rootReducer";
import { TablePaginationAct } from "./TablePaginationAct";

interface Props {
  count: number;
}

export const CustomizedTableFooter = (props: Props) => {
  const { count } = props;
  const dispatch = useDispatch();

  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
  };

  return (
    <TableFooter style={{ padding: 0, margin: 0 }}>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[5]}
          colSpan={9}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { "aria-label": "rows per page" },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationAct}
        />
      </TableRow>
    </TableFooter>
  );
};

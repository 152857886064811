import React, { useState } from "react";
import { useIntl } from "react-intl";

import { AppBar, Tabs, Tab } from "@material-ui/core";

import { TabPanel, useStyles, a11yProps } from "../../custom/Tabs";
import { IFuid } from "../../../redux/types/types";
import { DocumentsFuidTab } from "../tabs/DocumentsFuidTab";
import { CopiesFuidTab } from "../tabs/CopiesFuidTab";
import { RecordTab } from "../tabs/RecordTab";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { AnnotationsFuidTab } from "../tabs/AnnotationsFuidTab";

interface Props {
  expedient: IFuid;
  fromFileModule: boolean;
  isCopy?: boolean;
}

export const FuidDetailsTabs = ({
  expedient,
  fromFileModule,
  isCopy = false,
}: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const { user } = useSelector((state: AppState) => state.auth);

  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          centered
          TabIndicatorProps={{
            style: {
              backgroundColor: "#0A8FDC",
            },
          }}
        >
          <Tab
            className={classes.tab}
            label={`${intl.formatMessage({ id: "Documents" }).toUpperCase()}`}
            {...a11yProps(0)}
          />
          <Tab
            className={classes.tab}
            label={`${intl.formatMessage({ id: "Copies" }).toUpperCase()}`}
            {...a11yProps(1)}
          />
          <Tab
            className={classes.tab}
            label={`${intl.formatMessage({ id: "Record" }).toUpperCase()}`}
            {...a11yProps(2)}
          />
          <Tab
            className={classes.tab}
            label={`${intl.formatMessage({ id: "Annotations" }).toUpperCase()}`}
            {...a11yProps(3)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DocumentsFuidTab
          documents={expedient.documents ? expedient.documents : []}
          isCopy={isCopy}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CopiesFuidTab copies={expedient.copyTo ? expedient.copyTo : []} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {user && user.idEntity && (
          <RecordTab
            id={expedient.id}
            idEntity={user.idEntity}
            isFuid={true}
            fromFileModule={fromFileModule}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AnnotationsFuidTab fromFileModule={fromFileModule} />
      </TabPanel>
    </div>
  );
};

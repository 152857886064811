import clsx from "clsx";
import { Form, Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";

import { MyTextField } from "../../custom/MyTextField";
import { useStyles } from "../../../shared/styles/useStyles";
import {
  uiCloseModalAdd,
  uiCloseModalEdit,
  uiCloseModalAction,
} from "../../../redux/actions/uiActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useEffect, useState } from "react";
import { SupportType, TypeArchive } from "../../../enums/enums";
import { startUploadFuid } from "../../../redux/actions/documentManagmentActions";
import firebase from "firebase";
import { setActiveFuid } from "../../../redux/actions/fuidActions";

interface Props {
  edit?: boolean;
  fromModule?: TypeArchive;
}
export const FileForm = ({ edit = false, fromModule }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { Deposits: depositsCentralFile } = useSelector(
    (state: AppState) => state.fileModule
  );
  const { Deposits: depositsManagments } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const Deposits = fromModule ? depositsCentralFile : depositsManagments;
  const { activeExpedient: activeDocumentManagment } = useSelector(
    (state: AppState) => state.fuid
  );
  const { activeExpedientFileModule: activeFuidFilesModule } = useSelector(
    (state: AppState) => state.fileModule
  );
  const active = fromModule ? activeFuidFilesModule : activeDocumentManagment;

  const [selected, setSelected] = useState({
    productionOffice: active ? active.productionOffice : "",
    series: active ? active.series : "",
    subseries: active ? active.subseries : "",
    trdOrTvd: active ? active.trdOrTvd : "",
  });
  const {
    productionOffices: allProductionOffices,
    series: allSeries,
    subSeries: allSubseries,
    versions,
  } = useSelector((state: AppState) => state.trd);
  const [series, setSeries] = useState(allSeries);
  const [subSeries, setSubSeries] = useState(allSubseries);
  const [productionOffices, setproductionOffices] =
    useState(allProductionOffices);

  // falta numero de orden,codigo de barras,Nombre de la serie y subserie,  otro,  folios, soporte (electronico o papel)
  const initialValues: any = {
    id: active ? active.id : "",
    fileNumber: active ? active.fileNumber : "",
    fileName: active ? active.fileName : "",
    description: active ? active.description : "",
    extremeInitialDate: active
      ? new Date(active.extremeInitialDate).toLocaleDateString("en-CA")
      : "",
    extremeFinalDate: active
      ? new Date(active.extremeFinalDate).toLocaleDateString("en-CA")
      : "",
    pagesOf: active ? active.pagesOf : "",
    pagesTo: active ? active.pagesTo : "",
    volumeNumber: active ? active.volumeNumber : "",
    totalFolderOrVolume: active ? active.totalFolderOrVolume : "",
    fileType: active ? active.fileType : "",
    deposit: active
      ? active.deposit
      : Deposits.length > 0
      ? Deposits[0].id
      : "",
    shelf: active ? active.shelf : "",
    box: active ? active.box : "",
    folder: active ? active.folder : "",
    trdOrTvd: active ? active.trdOrTvd : "",
    productionOffice: active ? active.productionOffice : "",
    series: active ? active.series : "",
    subseries: active ? active.subseries : "",
    orderNumber: active ? active.orderNumber : "",
    barCode: active ? active.barCode : "",
    invoice: active ? active.invoice : "",
    other: active ? active.other : "",
    serieName: active ? active.serieName : "",
    subSerieName: active ? active.subSerieName : "",
    support: active ? active.support : SupportType.PAPER,
  };

  useEffect(() => {
    //FILTRAR LOS SELECTS
    const POFiltered = allProductionOffices.filter(
      (po) => po.idVersion === selected.trdOrTvd
    );

    const seriesFiltered = allSeries.filter(
      (ser) => ser.idProductionOffice === selected.productionOffice
    );
    const subSeriesFiltered = allSubseries.filter(
      (subSer) => subSer.idSerie === selected.series
    );
    setSeries(seriesFiltered);
    setSubSeries(subSeriesFiltered);
    setproductionOffices(POFiltered);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected({ ...selected, [event.target.name]: event.target.value });
  };

  const validationSchema = yup.object({
    fileNumber: yup
      .number()
      .min(1, `${intl.formatMessage({ id: "PositiveNumber" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    fileName: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    description: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    extremeInitialDate: yup
      .date()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    extremeFinalDate: yup
      .date()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    pagesOf: yup
      .number()
      .min(1, `${intl.formatMessage({ id: "PositiveNumber" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    pagesTo: yup
      .number()
      .min(1, `${intl.formatMessage({ id: "PositiveNumber" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    volumeNumber: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    totalFolderOrVolume: yup
      .number()
      .min(1, `${intl.formatMessage({ id: "PositiveNumber" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    fileType: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    deposit: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    shelf: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    box: yup.string().required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    folder: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    /*  series: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    subseries: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`), */
    orderNumber: yup
      .number()
      .min(1, `${intl.formatMessage({ id: "PositiveNumber" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    barCode: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    invoice: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    other: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),

    support: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  const onClose = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    dispatch(uiCloseModalAction());
    dispatch(setActiveFuid(null));
  };

  return (
    <Box m={2} mt={-1} mb={2}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          const idFuid = edit
            ? values.id
            : firebase.firestore.Timestamp.now().toDate().getTime().toString();

          values.id = idFuid;
          values.productionOffice = selected.productionOffice;
          values.series = selected.series;
          values.subseries = selected.subseries;
          values.trdOrTvd = selected.trdOrTvd;
          const serieFinded = series.find(
            (ser) => ser.idSerie === selected.series
          );
          const subSerieFinded = subSeries.find(
            (ser) => ser.idSubserie === selected.subseries
          );

          if (serieFinded && subSerieFinded) {
            values.serieName = serieFinded.seriesName;
            values.subSerieName = subSerieFinded.name;
          }
          fromModule
            ? await dispatch(startUploadFuid(values, edit, fromModule))
            : await dispatch(startUploadFuid(values, edit));

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <label className="form-text">
                  <FormattedMessage id="FileNumber" />:
                </label>
                <MyTextField
                  name="fileNumber"
                  variant="outlined"
                  type="number"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={7}>
                <label className="form-text">
                  <FormattedMessage id="FileName" />:
                </label>
                <MyTextField
                  name="fileName"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Description" />:
                </label>
                <MyTextField
                  name="description"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  multiline
                  rows={4}
                />
              </Grid>

              <Grid item xs={4}>
                <label className="form-text">
                  <FormattedMessage id="ExtremeInitialDate" />:
                </label>
                <MyTextField
                  name="extremeInitialDate"
                  variant="outlined"
                  type="date"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={4}>
                <label className="form-text">
                  <FormattedMessage id="ExtremeFinalDate" />:
                </label>
                <MyTextField
                  name="extremeFinalDate"
                  variant="outlined"
                  type="date"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={2}>
                <label className="form-text">
                  <FormattedMessage id="PagesOf" />:
                </label>
                <MyTextField
                  name="pagesOf"
                  variant="outlined"
                  type="number"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={2}>
                <label className="form-text">
                  <FormattedMessage id="PagesTo" />:
                </label>
                <MyTextField
                  name="pagesTo"
                  variant="outlined"
                  type="number"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="NoVolume" />:
                </label>
                <MyTextField
                  name="volumeNumber"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="TotalVolumes" />:
                </label>
                <MyTextField
                  name="totalFolderOrVolume"
                  variant="outlined"
                  type="number"
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Grid item xs={3}>
                <label className="form-text">
                  <FormattedMessage id="Invoice" />:
                </label>
                <MyTextField
                  name="invoice"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Grid item xs={4}>
                <label className="form-text">
                  <FormattedMessage id="OrderNumber" />:
                </label>
                <MyTextField
                  name="orderNumber"
                  variant="outlined"
                  type="number"
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Grid item xs={4}>
                <label className="form-text">
                  <FormattedMessage id="BarsCode" />:
                </label>
                <MyTextField
                  name="barCode"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Grid item xs={3}>
                <label className="form-text">
                  <FormattedMessage id="Other" />:
                </label>
                <MyTextField
                  name="other"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={3}>
                <label className="form-text">
                  <FormattedMessage id="FileType" />:
                </label>
                <MyTextField
                  name="fileType"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  select
                >
                  {fromModule ? (
                    <MenuItem value={fromModule}>
                      <FormattedMessage id={fromModule} />
                    </MenuItem>
                  ) : (
                    <MenuItem value={TypeArchive.MANAGEMENT_FILE}>
                      <FormattedMessage id={TypeArchive.MANAGEMENT_FILE} />
                    </MenuItem>
                  )}
                </MyTextField>
              </Grid>
              <Grid item xs={3}>
                <label className="form-text">
                  <FormattedMessage id="Support" />:
                </label>
                <MyTextField
                  name="support"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  select
                >
                  <MenuItem value={SupportType.PAPER}>
                    <FormattedMessage id={SupportType.PAPER} />
                  </MenuItem>
                  <MenuItem value={SupportType.ELECTRONIC}>
                    <FormattedMessage id={SupportType.ELECTRONIC} />
                  </MenuItem>
                </MyTextField>
              </Grid>

              <Grid item xs={4}>
                <label className="form-text">
                  <FormattedMessage id="Deposit" />:
                </label>
                <MyTextField
                  name="deposit"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                >
                  {Deposits.map((dep) => (
                    <MenuItem key={dep.id} value={dep.id}>
                      {dep.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={4}>
                <label className="form-text">
                  <FormattedMessage id="Shelf" />:
                </label>
                <MyTextField
                  name="shelf"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={2}>
                <label className="form-text">
                  <FormattedMessage id="Box" />:
                </label>
                <MyTextField
                  name="box"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={2}>
                <label className="form-text">
                  <FormattedMessage id="N°Folder" />:
                </label>
                <MyTextField
                  name="folder"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="TrdOrTvd" />:
                </label>
                <TextField
                  name="trdOrTvd"
                  variant="outlined"
                  select
                  size="small"
                  value={selected.trdOrTvd}
                  className={classes.myTextFieldRoot}
                  onChange={handleSelect}
                >
                  {versions.map((versions) => (
                    <MenuItem
                      key={versions.idVersion}
                      value={versions.idVersion}
                    >
                      {versions.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <FormattedMessage id="ProductionOffice" />:
                <Box width={"90%"}>
                  <TextField
                    name="productionOffice"
                    variant="outlined"
                    select
                    size="small"
                    value={selected.productionOffice}
                    className={classes.myTextFieldRoot}
                    onChange={handleSelect}
                    //   disabled={ active ? true : false}
                  >
                    {productionOffices.map((productionOffice) => (
                      <MenuItem
                        key={productionOffice.idProductionOffice}
                        value={productionOffice.idProductionOffice}
                      >
                        {productionOffice.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="Series" />:
                </label>
                <TextField
                  name="series"
                  variant="outlined"
                  select
                  size="small"
                  value={selected.series}
                  className={classes.myTextFieldRoot}
                  onChange={handleSelect}
                  //   disabled={ active ? true : false}
                >
                  {series.map((serie) => (
                    <MenuItem key={serie.idSerie} value={serie.idSerie}>
                      {serie.seriesName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <FormattedMessage id="Subseries" />:
                <Box width={"90%"}>
                  <TextField
                    name="subseries"
                    variant="outlined"
                    select
                    size="small"
                    value={selected.subseries}
                    className={classes.myTextFieldRoot}
                    onChange={handleSelect}
                    //   disabled={ active ? true : false}
                  >
                    {subSeries.map((subSer) => (
                      <MenuItem
                        key={subSer.idSubserie}
                        value={subSer.idSubserie}
                      >
                        {subSer.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Grid>

              <Box mt={2} display="flex" flexDirection="row-reverse" width={1}>
                {!isSubmitting ? (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id="Save" />
                  </Button>
                ) : (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="button"
                    disabled={true}
                  >
                    <CircularProgress className={classes.btnLoading} />
                  </Button>
                )}
                <Button
                  className={clsx(classes.btn, classes.cancel)}
                  onClick={onClose}
                >
                  <FormattedMessage id="Cancel" />
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

/* eslint-disable no-mixed-operators */
import React, { FC, Fragment, useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
//@ts-ignore
import Barcode from "react-barcode";
import QRCode from "qrcode.react";
import { ICorrespondence } from "../../../interfaces/Correspondence";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { CorrespondenceInputType } from "../../../enums/enums";
import { ITrdProductionOffices } from "../../../redux/types/types";
import { getEntity } from "../../../services/firebase/entities";
import { Entity } from "../../../interfaces/Users";
interface props {
  communication: ICorrespondence;
  size: "lg" | "sm";
}
const FontStylesLg: any = {
  title: {
    fontSize: 70,
    fontWeight: "bold",
  },
  paragraph: {
    fontSize: 55,
  },
};
export const CorrespondenceLabel: FC<props> = ({ communication, size }) => {
  //dependiendo del tipo de entrada se debe mostrar de una manera u otra
  //el qr debe ir solamente cuando es externa recibida y tiene configurado tiempo de respuesta
  const { productionOffices } = useSelector((state: AppState) => state.trd);
  const [entity, setEntity] = useState<Entity | null>(null);
  const { users, externalSenders } = useSelector(
    (state: AppState) => state.users
  );

  const { user } = useSelector((state: AppState) => state.auth);
  const intl = useIntl();

  useEffect(() => {
    if (
      communication.inputType === CorrespondenceInputType.EP &&
      user &&
      user.idEntity
    ) {
      getEntityDetails(user.idEntity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getEntityDetails = async (nit: string) => {
    const resp = await getEntity(nit);
    if (resp) {
      setEntity(resp);
    }
    return null;
  };

  const getOfficeDetails = (idOffice: string): ITrdProductionOffices | null => {
    const findOffice = productionOffices.find(
      (el) => el.idProductionOffice === idOffice
    );
    if (!findOffice) {
      return null;
    }
    return findOffice;
  };
  const getUserDetails = (idUser: string): any | null => {
    const allUsers = users.concat(externalSenders as any);

    const findUser = (allUsers as any[]).find(
      (el) => el.id === parseInt(idUser)
    );
    if (!findUser) {
      return null;
    }

    return findUser;
  };

  /**
   * NUMERO DE RADICADO OBTENIDO DE EL ID DE LA COMUNICACIÓN
   */
  let registeredNumber = communication.id.substring(
    communication.id.length - 9,
    communication.id.length
  );

  return (
    <Grid container style={{ paddingTop: 5, paddingLeft: 5, paddingRight: 5 }}>
      {size === "sm" ? (
        <>
          {user && user.businessName && user.idEntity && (
            <Grid item xs={9}>
              <Grid container>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography
                    variant="h6"
                    style={{ fontSize: 8, fontWeight: "bold" }}
                  >
                    {user.businessName.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="h6"
                    style={{ fontSize: 6, fontWeight: "bold" }}
                  >
                    <FormattedMessage id="Settled" /> No.
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="h6" style={{ fontSize: 6 }}>
                    {communication.id}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="h6"
                    style={{ fontSize: 6, fontWeight: "bold" }}
                  >
                    <FormattedMessage id="Date" />
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="h6" style={{ fontSize: 6 }}>
                    {moment(communication.createAt.toDate()).format(
                      "YYYY/MM/DD h:mm:ss a"
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    variant="h6"
                    style={{ fontSize: 6, fontWeight: "bold" }}
                  >
                    <FormattedMessage id="Sender" />
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {communication.inputType === CorrespondenceInputType.EP &&
                    entity && (
                      <>
                        <Typography variant="h6" style={{ fontSize: 6 }}>
                          {communication.externalSender?.name}
                        </Typography>
                        <Typography variant="h6" style={{ fontSize: 6 }}>
                          {
                            getOfficeDetails(communication.receivingDependency)
                              ?.name
                          }
                        </Typography>
                        {/*      <Typography variant="h6" style={{ fontSize: 6 }}>
                      {`${entity.address} - ${entity.phone}`}
                    </Typography> */}
                      </>
                    )}
                  {communication.inputType === CorrespondenceInputType.ER && (
                    <Typography variant="h6" style={{ fontSize: 6 }}>
                      {communication.externalSender?.name}
                    </Typography>
                  )}
                  {(communication.inputType === CorrespondenceInputType.IP ||
                    communication.inputType === CorrespondenceInputType.IR) && (
                    <>
                      <Typography variant="h6" style={{ fontSize: 6 }}>
                        {communication.externalSender?.name}
                      </Typography>
                      <Typography variant="h6" style={{ fontSize: 6 }}>
                        {
                          getOfficeDetails(communication.receivingDependency)
                            ?.name
                        }
                      </Typography>
                    </>
                  )}
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    variant="h6"
                    style={{ fontSize: 6, fontWeight: "bold" }}
                  >
                    <FormattedMessage id="Recipient" />
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {communication.inputType === CorrespondenceInputType.EP &&
                    entity && (
                      <>
                        <Typography variant="h6" style={{ fontSize: 6 }}>
                          {communication.addressee?.name}
                        </Typography>
                        {/*   <Typography variant="h6" style={{ fontSize: 6 }}>
                      {communication.addressee.id}
                    </Typography> */}
                      </>
                    )}
                  {communication.inputType === CorrespondenceInputType.ER && (
                    <Typography variant="h6" style={{ fontSize: 6 }}>
                      {communication.addressee?.name}
                    </Typography>
                  )}
                  {(communication.inputType === CorrespondenceInputType.IP ||
                    communication.inputType === CorrespondenceInputType.IR) && (
                    <>
                      <Typography variant="h6" style={{ fontSize: 6 }}>
                        {communication.addressee?.name}
                      </Typography>
                      <Typography variant="h6" style={{ fontSize: 6 }}>
                        {
                          getOfficeDetails(communication.receivingDependency)
                            ?.name
                        }
                      </Typography>
                    </>
                  )}
                </Grid>
                {(communication.inputType === CorrespondenceInputType.ER ||
                  communication.inputType === CorrespondenceInputType.OR) && (
                  <>
                    <Grid item xs={4}>
                      <Typography
                        variant="h6"
                        style={{ fontSize: 6, fontWeight: "bold" }}
                      >
                        <FormattedMessage id="Communication" />
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="h6" style={{ fontSize: 6 }}>
                        {communication.communicationPages}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography
                        variant="h6"
                        style={{ fontSize: 6, fontWeight: "bold" }}
                      >
                        <FormattedMessage id="Annexes" />
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="h6" style={{ fontSize: 6 }}>
                        {communication.annexesPages}
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography
                        variant="h6"
                        style={{ fontSize: 6, fontWeight: "bold" }}
                      >
                        <FormattedMessage id="Copies" />
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="h6" style={{ fontSize: 6 }}>
                        {communication.copyPages}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          )}
          <Grid item xs={3}>
            <QRCode
              value={
                communication.inputType === CorrespondenceInputType.ER ||
                communication.inputType === CorrespondenceInputType.OR ||
                (communication.inputType === CorrespondenceInputType.IR &&
                  communication.communicationType?.isParam)
                  ? `http://docspace.mialcaldia.com.co/procedure-status/${communication.id}`
                  : "https://docspace.app/"
              }
              renderAs="svg"
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              size={60}
            />
            {(communication.inputType === CorrespondenceInputType.EP ||
              communication.inputType === CorrespondenceInputType.IP) && (
              <Typography variant="h6" style={{ fontSize: 6 }}>
                Software DocSpace
              </Typography>
            )}
          </Grid>

          {(communication.inputType === CorrespondenceInputType.ER ||
            communication.inputType === CorrespondenceInputType.OR ||
            communication.inputType === CorrespondenceInputType.IP ||
            communication.inputType === CorrespondenceInputType.IR) && (
            <>
              <Grid item xs={12}>
                <Barcode
                  value={communication.id}
                  fontSize={8}
                  width={1.3}
                  height={30}
                  margin={0}
                  displayValue={false}
                  marginTop={2}
                />
              </Grid>
            </>
          )}
        </>
      ) : (
        <>
          <Grid container spacing={10} style={{ marginTop: 10 }}>
            {Array.from(Array(2).keys()).map((item) => (
              <Fragment key={item}>
                {user && user.businessName && user.idEntity && (
                  <Grid item xs={6}>
                    <Grid
                      container
                      /* style={{
                        borderRight: "1px solid grey",
                        borderBottom: "1px solid grey",
                        borderLeft: "1px solid grey",
                      }} */
                    >
                      <Grid item xs={1} />
                      <Grid
                        item
                        xs={11}
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Typography variant="h6" style={FontStylesLg.title}>
                              {user.businessName.toUpperCase()}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography variant="h6" style={FontStylesLg.title}>
                              {intl
                                .formatMessage({
                                  id: `Settled`,
                                })
                                .toUpperCase()}{" "}
                              NO.{communication.id}
                            </Typography>
                          </Grid>

                          <Grid item xs={7}>
                            <Typography
                              variant="h6"
                              style={FontStylesLg.paragraph}
                            >
                              {moment(communication.createAt.toDate()).format(
                                "YYYY/MM/DD h:mm:ss a"
                              )}
                            </Typography>
                            <Typography
                              variant="h6"
                              style={{
                                ...FontStylesLg.paragraph,
                                fontWeight: "bold",
                              }}
                            >
                              <FormattedMessage id="Sender" />:
                            </Typography>
                            {(communication.inputType ===
                              CorrespondenceInputType.ER ||
                              communication.inputType ===
                                CorrespondenceInputType.EP ||
                              communication.inputType ===
                                CorrespondenceInputType.IR) && (
                              <Typography
                                variant="h6"
                                style={FontStylesLg.paragraph}
                              >
                                {communication.externalSender?.name}
                              </Typography>
                            )}

                            <Typography
                              variant="h6"
                              style={{
                                ...FontStylesLg.paragraph,
                                fontWeight: "bold",
                              }}
                            >
                              <FormattedMessage id="Recipient" />:
                            </Typography>
                            {(communication.inputType ===
                              CorrespondenceInputType.ER ||
                              communication.inputType ===
                                CorrespondenceInputType.EP ||
                              communication.inputType ===
                                CorrespondenceInputType.IR) && (
                              <Typography
                                variant="h6"
                                style={FontStylesLg.paragraph}
                              >
                                {communication.addressee?.name}
                              </Typography>
                            )}

                            {communication.inputType ===
                              CorrespondenceInputType.EP &&
                              entity && (
                                <>

                                  <Typography
                                    variant="h6"
                                    style={FontStylesLg.paragraph}
                                  >
                                    {getUserDetails(
                                      (
                                        communication.addressee.id as any
                                      ).toString()
                                    )?.mobilePhone
                                      ? getUserDetails(
                                          (
                                            communication.addressee.id as any
                                          ).toString()
                                        )?.mobilePhone
                                      : getUserDetails(
                                          (
                                            communication.addressee.id as any
                                          ).toString()
                                        )?.phone}
                                  </Typography>
                                </>
                              )}

                            {(communication.inputType ===
                              CorrespondenceInputType.ER ||
                              communication.inputType ===
                                CorrespondenceInputType.OR) && (
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      ...FontStylesLg.paragraph,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {`${intl.formatMessage({
                                      id: "Pages",
                                    })} ${intl.formatMessage({
                                      id: "Communication",
                                    })}: ${communication.communicationPages}`}
                                  </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      ...FontStylesLg.paragraph,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <FormattedMessage id="Annexes" />:
                                    {` ${communication.annexesPages}`}
                                  </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      ...FontStylesLg.paragraph,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <FormattedMessage id="Copies" />:
                                    {` ${communication.copyPages}`}
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                          <Grid item xs={4}>
                            <QRCode
                              value={
                                communication.inputType ===
                                  CorrespondenceInputType.ER ||
                                communication.inputType ===
                                  CorrespondenceInputType.OR ||
                                (communication.inputType ===
                                  CorrespondenceInputType.IR &&
                                  communication.communicationType?.isParam)
                                  ? `http://docspace.mialcaldia.com.co/procedure-status/${communication.id}`
                                  : "https://docspace.app/"
                              }
                              renderAs="svg"
                              bgColor={"#ffffff"}
                              fgColor={"#000000"}
                              size={
                                communication.inputType ===
                                  CorrespondenceInputType.EP ||
                                communication.inputType ===
                                  CorrespondenceInputType.IP
                                  ? 500
                                  : 600
                              }
                            />
                            {(communication.inputType ===
                              CorrespondenceInputType.EP ||
                              communication.inputType ===
                                CorrespondenceInputType.IP) && (
                              <Typography
                                variant="h6"
                                style={FontStylesLg.paragraph}
                              >
                                Software DocSpace
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={1}>
                            <p
                              style={{
                                writingMode: "vertical-lr",
                                transform: "rotate(180dg)",
                                fontSize: 108,
                                marginLeft: 10,
                              }}
                            >
                              {registeredNumber}
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Fragment>
            ))}
          </Grid>
        </>
      )}
    </Grid>
  );
};

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import {
  createTheme,
  ThemeProvider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { CustomizedTableFooter } from "../../custom/CustomizedTableFooter";
import { useStyles } from "../../../shared/styles/useStyles";
import { AppState } from "../../../redux/reducers/rootReducer";
import { OrganizationChartBody } from "./OrganizationChartBody";
import { Office } from '../../../interfaces/OrganizationChart';
import { Colors } from "../../../shared/constants/themes/Colors";
import { FC } from "react";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});

interface props {
  offices: Office[]
}

export const OrganizationChartTable: FC< props > = ({ offices }) => {
  const classes = useStyles();

  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );
  
  // search
  const { data, value }: any = useSelector((state: AppState) => state.search);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, offices.length - page * rowsPerPage);

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper}>
      {offices.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            <FormattedMessage id={"NoCreatedOffices"} />
          </Box>
        ) : (
        <Table
          className={classes.table}
          size="small"
          style={{ tableLayout: "auto" }}
          aria-label="a dense table"
        >
          <TableHead className={classes.titleTable}>
            <TableRow>
              <TableCell className={classes.titleTable}>
                <FormattedMessage id="Code" />{" "}
              </TableCell>
              <TableCell className={classes.titleTable}>
                <FormattedMessage id="ProductionOffice" />{" "}
              </TableCell>
              <TableCell className={classes.titleTable}>
                <FormattedMessage id="Office" />{" "}
              </TableCell>
              <TableCell className={classes.titleTable} align="center">
                <FormattedMessage id="Actions" />{" "}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
            !value.trim()
              ? (rowsPerPage > 0
                  ? offices.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : offices
                ).map((office) => (
                  <OrganizationChartBody key={office.idOffice} office={office} />
                ))
              : data &&
                (rowsPerPage > 0
                  ? data.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data
                ).map((office: any) => (
                  <OrganizationChartBody key={office.idOffice} {...office}  />
                ))
                }

            {emptyRows > 0 && (
              <TableRow style={{ height: 23 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {/* --------- Footer para paginación -------- */}
          <CustomizedTableFooter count={offices.length} />
        </Table>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import settingsReducer from "./settingsReducer";
import { paginationReducer } from "./paginationReducer";
import { uiReducer } from "./uiReducer";
import { usersReducer } from "./usersReducer";
import { organizationChartReducer } from "./organizationChartReducer";
import { entitiesReducer } from "./entitiesReducer";
import { searchReducer } from "./searchReducer";
import { trdReducer } from "./trdReducer";
import { correspondenceReducer } from "./correspondenceReducer";
import { documentManagmentReducer } from "./documentManagmentReducer";
import { fuidReducer } from "./fuidReducer";
import { fileModuleReducer } from "./fileModuleReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  setting: settingsReducer,
  pagination: paginationReducer,
  ui: uiReducer,
  users: usersReducer,
  organizationChart: organizationChartReducer,
  entities: entitiesReducer,
  search: searchReducer,
  trd: trdReducer,
  correspondence: correspondenceReducer,
  documentManagment: documentManagmentReducer,
  fuid: fuidReducer,
  fileModule: fileModuleReducer,

  // Todo: Demás Reducers
});

export type AppState = ReturnType<typeof rootReducer>;

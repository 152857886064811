import {
  Box,
  Button,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
import clsx from "clsx";

import moment from "moment";
import {  IFuid, ITrdSeries } from "../../../redux/types/types";
import { setActiveFuidFileModule } from "../../../redux/actions/fileModuleActions";
import { uiOpenModalSee } from "../../../redux/actions/uiActions";

export const SearchDocumentaryUnits = () => {
  const classes = useStyles();
  const { productionOffices, series: allSeries } = useSelector(
    (state: AppState) => state.trd
  );
  const [noValid, setNoValid] = useState(false);
  const { expedients } = useSelector((state: AppState) => state.fileModule);
  const [series, setSeries] = useState<ITrdSeries[]>([]);
  const [values, setValues] = useState({
    productionOffice: productionOffices[0]
      ? productionOffices[0].idProductionOffice
      : "",
    serie: series[0] ? series[0].idSerie : "",
    keyWord: "",
  });
  const [results, setResults] = useState<IFuid[]>([]);
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  useEffect(() => {
    const seriesFiltered = allSeries.filter(
      (ser) => ser.idProductionOffice === values.productionOffice
    );
    setSeries(seriesFiltered);
  }, [allSeries, values]);

  const handleSearch = () => {
    //Algoritmo para buscar coincidencia en

    if (values.productionOffice !== "" && values.serie !== "") {
      const filterFuid = expedients.filter(
        (exp) =>
          exp.productionOffice === values.productionOffice &&
          exp.series === values.serie &&
          (exp.fileName.toLowerCase().includes(values.keyWord) ||
            (exp.fileNumber as number).toString().includes(values.keyWord) ||
            exp.documents?.map((doc) =>
              doc.fileName?.toLowerCase().includes(values.keyWord)
            )[0])
      );
      setNoValid(false)
      setResults(filterFuid);
    }else{
      setNoValid(true)
    }
  };

  const setActiveFuid = (event: React.SyntheticEvent, fuid: IFuid) => {
    event.preventDefault();
    dispatch(uiOpenModalSee());
    dispatch(setActiveFuidFileModule(fuid));
  };

  return (
    <Box
      style={{ backgroundColor: "#fff", height: "100%", width: "100%" }}
      padding={2}
      paddingTop={2}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.title}>
            <FormattedMessage id="DocumentaryUnitFinder" />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <label className="form-text">
            <FormattedMessage id="ProductionOffice" />*
          </label>
          <TextField
            name="productionOffice"
            variant="outlined"
            select
            size="small"
            onChange={handleChange}
            value={values.productionOffice}
            className={classes.myTextFieldRoot}
          >
            {productionOffices.map((productionOffice) => (
              <MenuItem
                key={productionOffice.idProductionOffice}
                value={productionOffice.idProductionOffice}
              >
                {productionOffice.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <label className="form-text">
            <FormattedMessage id="Series" />*
          </label>
          <TextField
            name="serie"
            variant="outlined"
            select
            size="small"
            onChange={handleChange}
            value={values.serie}
            className={classes.myTextFieldRoot}
          >
            {series.map((serie) => (
              <MenuItem key={serie.idSerie} value={serie.idSerie}>
                {serie.seriesName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <label className="form-text">
            <FormattedMessage id="keyWord" />:
          </label>
          <TextField
            className={classes.myTextFieldRoot}
            name="keyWord"
            size="small"
            variant="outlined"
            onChange={handleChange}
            value={values.keyWord}
          />
        </Grid>
        {noValid && (
              <Grid item xs={12}>
                <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                  <FormattedMessage id="CompleteRequiredFields" />
                </Box>
              </Grid>
            )}
        {<Grid item xs={4} />}
        <Grid item xs={4}>
          <Button
            className={clsx(classes.btnAction, classes.save)}
            style={{ marginTop: 30 }}
            onClick={handleSearch}
          >
            <FormattedMessage id="Search" />
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            className={clsx(classes.btnAction, classes.cancel)}
            style={{ marginTop: 30 }}
            onClick={()=>setResults([])}
          >
            <FormattedMessage id="Clean" />
          </Button>
        </Grid>
        {results.length > 0 && (
          <Grid item xs={12} className={classes.typography}>
            <label
              className="form-text"
              style={{ display: "inline-block", marginBottom: 20 }}
            >
              <FormattedMessage id="Results" />:
            </label>
            <div className="container-scroll">
              {results.map((result) => (
                <Grid container key={result.id} spacing={2}>
                  <Grid item xs={4}>
                    {result.barCode}
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>
                      <Link href="#" onClick={(e) => setActiveFuid(e, result)}>
                        {result.description}
                      </Link>
                    </Typography>
                  </Grid>
                  {result.createDate && (
                    <Grid item xs={4}>
                      {moment(result.createDate.toDate()).format("YYYY/MM/DD")}
                    </Grid>
                  )}
                </Grid>
              ))}
            </div>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

import { IAuthActions, IAuthState, IUserLoggedIn, Types } from "../types/types";

const initialState: IAuthState = {
  user: null,
};
export const authReducer = (
  state = initialState,
  action: IAuthActions
): IAuthState => {
  const { type, payload } = action;
  switch (type) {
    case Types.login:
      return {
        user: payload as IUserLoggedIn,
      };
    case Types.logout:
      return {
        user: null,
      };

    default:
      return state;
  }
};

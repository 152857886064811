import clsx from "clsx";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import { IFiles } from "../../../redux/types/types";
import { MyTextField } from "../../custom/MyTextField";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "../../../shared/styles/useStyles";
import {
  CommunicationChannels,
  CorrespondenceInputType,
  Enum_FileSize,
} from "../../../enums/enums";
import { FormattedMessage, useIntl } from "react-intl";
import { AppState } from "../../../redux/reducers/rootReducer";
import * as yup from "yup";
import {
  uiCloseModalAnswer,
  uiCloseModalEdit,
} from "../../../redux/actions/uiActions";
import {
  ICorrespondenceResponse,
  IFinalAnswerForm,
} from "../../../interfaces/Correspondence";
import {
  setActiveAnswer,
  startSubmitFinalAnswer,
} from "../../../redux/actions/documentManagmentActions";
import { firebase } from "../../../config/firebase/firebase-config";
import { getUserById } from "../../../services/firebase/users";
import { validateEmail } from "../../../utils/utils";
import { getDataFromEntities } from "../../../services/firebase/entities";

export const AnswerForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [noValidSize, setNoValidSize] = useState(false);
  const [noValid, setNoValid] = useState(false);

  const { activeCorrespondence: active, activeAnswer } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { user } = useSelector((state: AppState) => state.auth);
  const { externalSenders } = useSelector((state: AppState) => state.users);
  const [dataUser, setDataUser]: any[] = useState([]);
  const [addresseeState, setAddresseeState] = useState({
    name: "",
    identification: "",
    phone: "",
    email: "",
    direction: "",
    country: "",
    city: "",
  });
  const [addresseeError, setAddresseeError] = useState({
    name: false,
    identification: false,
    phone: false,
    email: false,
    direction: false,
    country: false,
    city: false,
  });

  const [emailSendForm, setSelectedHowToSendEmail] = useState({
    howToSendEmail: "1",
    active: true,
  });

  const [entity, setEntity] = useState({ emailSender: "" });

  useEffect(() => {
    const fetchData = async () => {
      const response = await getDataFromEntities(user!.idEntity!);
      setEntity(response);
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getData = async () => {
      // TRAER DATOS USUARIO POR ID
      if (active) {
        const senderData = externalSenders.filter(
          (user) =>
            user.idDoc === active.externalSender.id ||
            user.uid === active.externalSender.id
        );

        if (senderData.length > 0) {
          await setDataUser(senderData);
        } else {
          // TRAER DATOS USUARIO POR ID
          const userData: any = await getUserById(active.externalSender.id);

          userData && (await setDataUser([userData]));
        }
      }
    };

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, setDataUser]);

  const [labelImage, setLabelImage] = useState("");
  const [fileNames, setFileNames] = useState<IFiles[]>(
    activeAnswer && activeAnswer.attachedDocuments
      ? activeAnswer.attachedDocuments
      : []
  );

  const handleSelectFile = (e: any) => {
    const SUPPORTED_FORMATS = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/png",
      "application/msword",
      "text/csv",
      "application/pdf",
      "application/zip",
      "application/x-zip-compressed",
      "application/vnd.rar",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.oasis.opendocument.text",
      "zip",
      "rar",
      "odt",
      "pptx",
      "ppt",
    ];
    const file = e.target.files[0] as File;
    const now = new Date().getTime().toString();
    if (file) {
      if (file.size > Enum_FileSize.maxAllowedSize) {
        setNoValidSize(true);
      } else {
        let fileType = file.type;

        if (fileType.length === 0) {
          const dataFileName = file.name.split(".");
          fileType =
            dataFileName.length > 0
              ? dataFileName[dataFileName.length - 1]
              : "";
        }

        if (SUPPORTED_FORMATS.includes(fileType)) {
          setNoValid(false);
          setFileNames((prevState) => [
            { url: "#", fileName: now + "_" + file.name, file },
            ...prevState,
          ]);
          setLabelImage(e.target.files[0].name);
        } else {
          setNoValid(true);
        }
      }
    } else {
      setNoValidSize(false);
      setNoValid(false);
      setLabelImage("");
    }
  };

  let initialValues: IFinalAnswerForm = {
    communicationChannel: active
      ? active.inputType === CorrespondenceInputType.IR
        ? CommunicationChannels.SINGLE_WINDOW
        : active.communicationChannel
      : "",
    name: "",
    identification: "",
    phone: "",
    email: "",
    address: "",
    country: "",
    city: "",
    subject: active
      ? `Respuesta a correspondencia con radicado No. ${active.id}`
      : "",
    description:
      activeAnswer && activeAnswer.description ? activeAnswer.description : "",
    file: null,
    inputType:
      active && active.inputType === CorrespondenceInputType.ER
        ? CorrespondenceInputType.EP
        : active?.inputType === CorrespondenceInputType.IP ||
          active?.inputType === CorrespondenceInputType.IR
        ? CorrespondenceInputType.IPR
        : CorrespondenceInputType.EP,
    emailForCommunications: entity.emailSender,
  };
  const validationSchema = yup.object({
    communicationChannel: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    name: yup.string(),

    phone: yup.string(),
    email: yup.string(),
    // .email(`${intl.formatMessage({ id: "EmailFormat" })}`),
    // email: yup
    // .string()
    // .email(`${intl.formatMessage({ id: "EmailFormat" })}`)
    // .required(`${intl.formatMessage({ id: "EmailRequired" })}`),
    address: yup.string(),
    country: yup.string(),
    city: yup.string(),
    subject: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    description: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  const onClose = () => {
    dispatch(uiCloseModalAnswer());
    dispatch(uiCloseModalEdit());
  };
  const handleChangeInputType = (
    inputType: CorrespondenceInputType,
    setValue: Function
  ) => {
    setValue("inputType", inputType);
  };

  useEffect(() => {
    if (dataUser.length > 0) {
      setAddresseeState({
        name: dataUser[0].name ? dataUser[0].name : dataUser[0].username,
        identification: dataUser[0].id,
        phone: dataUser[0].phone ? dataUser[0].phone : dataUser[0].mobilePhone,
        email: dataUser[0].email,
        direction: dataUser[0].address,
        country: dataUser[0].country,
        city: dataUser[0].city ? dataUser[0].city : "",
      });
    }
  }, [dataUser]);

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSelectedHowToSendEmail({
      ...emailSendForm,
      howToSendEmail: "1",
      active: true,
    });
    setAddresseeState({
      ...addresseeState,
      [event.target.name]: event.target.value,
    });

    if (event.target.name === "email") {
      if (event.target.value !== "") {
        const isEmail = validateEmail(event.target.value);
        setAddresseeError({
          ...addresseeError,
          [event.target.name]: !isEmail ? true : false,
        });
      } else {
        setSelectedHowToSendEmail({
          ...emailSendForm,
          howToSendEmail: "2",
          active: false,
        });
      }
    } else {
      setAddresseeError({
        ...addresseeError,
        [event.target.name]: event.target.value === "" ? true : false,
      });
    }
  };

  const handleHowToSend = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedHowToSendEmail({
      ...emailSendForm,
      howToSendEmail: event.target.value,
      active: true,
    });

  useEffect(() => {
    const validateEmailExternalSender = () => {
      setSelectedHowToSendEmail({
        ...emailSendForm,
        howToSendEmail: "1",
        active: true,
      });

      if (addresseeState.email === "") {
        setSelectedHowToSendEmail({
          ...emailSendForm,
          howToSendEmail: "2",
          active: false,
        });
      }
    };
    validateEmailExternalSender();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addresseeState]);

  return (
    <Box m={1}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await validationSchema.validate(values, { abortEarly: false });
          setSubmitting(true);
          if (
            !addresseeError.identification &&
            !addresseeError.phone &&
            !addresseeError.direction &&
            !addresseeError.country &&
            !addresseeError.city
          ) {
            const formValues = values;
            if (user && user.username) {
              const finalValues: ICorrespondenceResponse = {
                attachedDocuments: fileNames,
                description: formValues.description,
                isScreeningAnswer: false,
                officer: { id: user.id.toString(), name: user.username },
                subject: formValues.subject,
                submitDate: firebase.firestore.Timestamp.now(),
                addresseeDirection:
                  active?.inputType === CorrespondenceInputType.IR
                    ? ""
                    : addresseeState.direction,
                addresseeEmail:
                  active?.inputType === CorrespondenceInputType.IR
                    ? ""
                    : addresseeState.email,
                // addresseeId:
                //   active?.inputType === CorrespondenceInputType.IR
                //     ? active.externalSender.id
                //     : addresseeState.identification,
                addresseeId: active!.externalSender.id,
                addresseeName:
                  active?.inputType === CorrespondenceInputType.IR
                    ? active.externalSender.name
                    : addresseeState.name,
                addresseePhone:
                  active?.inputType === CorrespondenceInputType.IR
                    ? ""
                    : addresseeState.phone
                    ? addresseeState.phone
                    : "",
                country:
                  active?.inputType === CorrespondenceInputType.IR
                    ? ""
                    : addresseeState.country === undefined
                    ? ""
                    : addresseeState.country,
                city:
                  active?.inputType === CorrespondenceInputType.IR
                    ? ""
                    : addresseeState.city,
                communicationChannel: formValues.communicationChannel,
                communicationType:
                  active?.inputType === CorrespondenceInputType.IR
                    ? { isParam: false, option: "Answer", responseTime: 0 }
                    : null,
                inputType: formValues.inputType,
                isFinalAnswer: true,

                emailForCommunications: entity.emailSender,
              };
              await dispatch(
                startSubmitFinalAnswer(
                  finalValues,
                  emailSendForm.howToSendEmail
                )
              );
              await dispatch(setActiveAnswer(null));
            }
          }
          setSubmitting(false);
        }}
      >
        {({ values, isSubmitting, handleChange, setFieldValue }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="InputType" />*
                </label>

                <TextField
                  name="inputType"
                  variant="outlined"
                  select
                  onChange={(e) =>
                    handleChangeInputType(e.target.value as any, setFieldValue)
                  }
                  value={values.inputType}
                  className={classes.myTextFieldRoot}
                  size="small"
                  disabled={!!active}
                >
                  <MenuItem value={CorrespondenceInputType.EP}>
                    <FormattedMessage id="ExternalProduced" />
                  </MenuItem>
                  <MenuItem value={CorrespondenceInputType.ER}>
                    <FormattedMessage id="ExternalReceived" />
                  </MenuItem>
                  <MenuItem value={CorrespondenceInputType.IPR}>
                    <FormattedMessage id="InternalReceived" /> +{" "}
                    <FormattedMessage id="InternalProduced" />
                  </MenuItem>

                  <MenuItem
                    value={CorrespondenceInputType.OR}
                    //style={isDocManagment ? { display: "none" } : {}}
                  >
                    <FormattedMessage id="OtherShipmentsReceived" />
                  </MenuItem>
                  <MenuItem
                    value={CorrespondenceInputType.OP}
                    //style={isDocManagment ? { display: "none" } : {}}
                  >
                    <FormattedMessage id="OtherShipmentsProduced" />
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="Channel" />*
                </label>
                <MyTextField
                  name="communicationChannel"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                  disabled={
                    active && active.inputType === CorrespondenceInputType.IR
                  }
                >
                  <MenuItem value={CommunicationChannels.SINGLE_WINDOW}>
                    <FormattedMessage id="SingleWindow" />
                  </MenuItem>
                  <MenuItem value={CommunicationChannels.EMAIL}>
                    <FormattedMessage id="EmailAddress" />
                  </MenuItem>
                  <MenuItem value={CommunicationChannels.FACEBOOK}>
                    <FormattedMessage id="Facebook" />
                  </MenuItem>
                  <MenuItem value={CommunicationChannels.WHATSAPP}>
                    <FormattedMessage id="Whatsapp" />
                  </MenuItem>
                  <MenuItem value={CommunicationChannels.CALL}>
                    <FormattedMessage id="ByTelephone" />
                  </MenuItem>
                  <MenuItem value={CommunicationChannels.INSTITUTIONAL_PAGE}>
                    <FormattedMessage id="InstitutionalPage" />
                  </MenuItem>
                  <MenuItem value={CommunicationChannels.FAX}>
                    <FormattedMessage id="Fax" />
                  </MenuItem>
                </MyTextField>
              </Grid>

              {active && active.inputType !== CorrespondenceInputType.IR && (
                <>
                  <Grid item xs={12}>
                    <h4>
                      <FormattedMessage id="AdresseeDetails" />:
                    </h4>
                  </Grid>
                  <Grid item xs={12}>
                    <label className="form-text">
                      <FormattedMessage id="Name" />*
                    </label>
                    <TextField
                      name="name"
                      variant="outlined"
                      disabled={true}
                      value={addresseeState.name}
                      onChange={handleInputChange}
                      className={classes.myTextFieldRoot}
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <label className="form-text">
                      <FormattedMessage id="Identification" />
                    </label>
                    <TextField
                      name="identification"
                      variant="outlined"
                      //disabled={true}
                      value={addresseeState.identification}
                      onChange={handleInputChange}
                      className={classes.myTextFieldRoot}
                      size="small"
                      error={addresseeError.identification}
                      helperText={
                        addresseeError.identification &&
                        `${intl.formatMessage({ id: "RequiredFile" })}`
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <label className="form-text">
                      <FormattedMessage id="Phone" />
                    </label>
                    <TextField
                      name="phone"
                      variant="outlined"
                      size="small"
                      value={addresseeState.phone}
                      onChange={handleInputChange}
                      error={addresseeError.phone}
                      helperText={
                        addresseeError.phone &&
                        `${intl.formatMessage({ id: "RequiredFile" })}`
                      }
                      className={classes.myTextFieldRoot}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Email" />*
                </label>
                <MyTextField
                      placeholder={intl.formatMessage({ id: "EmailAddress" })}
                      name="email"
                      InputLabelProps={{ shrink: false }}
                      // value={addresseeState.email}
                      // onChange={handleInputChange}
                      className={classes.myTextFieldRoot}
                      variant="outlined"
                      autoComplete="off"
                    />
              </Grid> */}
                  <Grid item xs={12}>
                    <label className="form-text">
                      <FormattedMessage id="Email" />
                    </label>
                    <TextField
                      name="email"
                      variant="outlined"
                      size={"small"}
                      value={addresseeState.email}
                      onChange={handleInputChange}
                      error={addresseeError.email}
                      helperText={
                        addresseeError.email &&
                        `${intl.formatMessage({ id: "EmailFormat" })}`
                      }
                      className={classes.myTextFieldRoot}
                    />
                    {addresseeState.email === "" ? (
                      <Box style={{ fontSize: 12, color: "red" }}>
                        <FormattedMessage id="NoAdressedEmail" />
                      </Box>
                    ) : null}
                  </Grid>

                  <Grid item xs={12}>
                    <label className="form-text">
                      <FormattedMessage id="SendToEmail" />
                    </label>
                    <TextField
                      name="howToSendEmail"
                      variant="outlined"
                      select
                      value={emailSendForm.howToSendEmail}
                      className={classes.myTextFieldRoot}
                      size="small"
                      onChange={handleHowToSend}
                      disabled={!emailSendForm.active}
                    >
                      <MenuItem value="1">
                        <FormattedMessage id="SendEmailInmediatly" />
                      </MenuItem>
                      <MenuItem value="2">
                        <FormattedMessage id="SendEmailFromSettledEP" />
                      </MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <label className="form-text">
                      <FormattedMessage id="EmailForReceiveCommunications" />
                    </label>
                    <TextField
                      name="emailForCommunications"
                      variant="outlined"
                      size={"small"}
                      value={entity.emailSender}
                      className={classes.myTextFieldRoot}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <label className="form-text">
                      <FormattedMessage id="Address" />
                    </label>
                    <TextField
                      name="direction"
                      variant="outlined"
                      size={"small"}
                      value={addresseeState.direction}
                      onChange={handleInputChange}
                      error={addresseeError.direction}
                      helperText={
                        addresseeError.direction &&
                        `${intl.formatMessage({ id: "RequiredFile" })}`
                      }
                      className={classes.myTextFieldRoot}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <label className="form-text">
                      <FormattedMessage id="Country" />
                    </label>
                    <TextField
                      name="country"
                      variant="outlined"
                      size={"small"}
                      value={addresseeState.country}
                      onChange={handleInputChange}
                      error={addresseeError.country}
                      helperText={
                        addresseeError.country &&
                        `${intl.formatMessage({ id: "RequiredFile" })}`
                      }
                      className={classes.myTextFieldRoot}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <label className="form-text">
                      <FormattedMessage id="City" />
                    </label>
                    <TextField
                      name="city"
                      variant="outlined"
                      size={"small"}
                      value={addresseeState.city}
                      onChange={handleInputChange}
                      error={addresseeError.city}
                      helperText={
                        addresseeError.city &&
                        `${intl.formatMessage({ id: "RequiredFile" })}`
                      }
                      className={classes.myTextFieldRoot}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <h4>
                  <FormattedMessage id="Answer" />:
                </h4>
              </Grid>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Subject" />*
                </label>
                <MyTextField
                  name="subject"
                  variant="outlined"
                  multiline
                  rows={2}
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Description" />*
                </label>
                <MyTextField
                  name="description"
                  variant="outlined"
                  multiline
                  rows={3}
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <label className="form-text">
                    <FormattedMessage id="DigitalSupport" />:
                  </label>
                  <Box ml={2}>
                    <TextField
                      size="small"
                      type="file"
                      //inputProps={fileSupported}
                      onChange={handleSelectFile}
                      id="icon-button-file"
                      autoComplete="off"
                      style={{ display: "none" }}
                    />

                    <MyTextField
                      disabled={true}
                      variant="outlined"
                      style={{ color: "black" }}
                      label={labelImage}
                      name="file"
                      value={labelImage}
                      className={classes.myTextFieldRoot}
                    />
                  </Box>
                  <Box mt={1}>
                    <Tooltip title={`${intl.formatMessage({ id: "Attach" })}`}>
                      <label htmlFor="icon-button-file">
                        <Button
                          className={clsx(classes.btnAction, classes.save)}
                          component="span"
                        >
                          <FileCopy /> <FormattedMessage id="Attach" />
                        </Button>
                      </label>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>
              {noValid && (
                <Grid item xs={12}>
                  <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                    <FormattedMessage id="ValidFiles" />
                  </Box>
                </Grid>
              )}
              {noValidSize && (
                <Grid item xs={12}>
                  <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                    <FormattedMessage id="ValidDocumentalFilesSize" />
                  </Box>
                </Grid>
              )}
              {fileNames.map(({ fileName, url }) => {
                return (
                  <Grid container key={fileName}>
                    <Grid item xs={6} key={fileName}>
                      <Box style={{ fontSize: 12, color: "blue" }}>
                        <a href={url} target="__blank">
                          {fileName}
                        </a>
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            <Box mt={2} display="flex" flexDirection="row-reverse">
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="SendBtn" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import {
  Divider,
  Paper,
  Box,
  Grid,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { useStyles } from "../../../../shared/styles/useStyles";
import { TRDTable } from "./TRDTable";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { useSelector } from "react-redux";
import { ITrdTable, ITrdVersion} from "../../../../redux/types/types";
import { getCopyArrayOrObject } from "../../../../utils/utils";

export const TRDTab = () => {
  const classes = useStyles();
  const [trdFiltered, setTrdFiltered] = useState<ITrdTable[]>([])
  const [selected, setSelected] = useState({
    version: "0",
    productionOffice: "0",
    series: "0",
    subseries: "0",
  });

  const { series, productionOffices, versions, subSeries, documentalType } =
    useSelector((state: AppState) => state.trd);

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected({ ...selected, [event.target.name]: event.target.value });
  };
  const verifySelectedDocumentalType = (
    versionIds: string | string[] | undefined | null,
    option: string,
    isArr: boolean = false
  ) => {
    if (option !== "0") {
      if (versionIds) {
        if (!isArr) {
          return versionIds === option;
        }
        return versionIds.includes(option);
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  useEffect(() => {
    //HACER FILTROS
    const allVersions = [...versions];
    const filteredResp = allVersions.filter(
      (version) =>
        verifySelectedDocumentalType(version.idVersion, selected.version) &&
        verifySelectedDocumentalType(
          version.productionOffices,
          selected.productionOffice,
          true
        ) &&
        verifySelectedDocumentalType(version.series, selected.series, true) &&
        verifySelectedDocumentalType(
          version.subseries,
          selected.subseries,
          true
        )
    );
    
    //Armar el arr que se necesita para pintar la tabla
    //    dependency => codigo oficina productora
    // managment => lo que esta en subseries (managmentFile)
    // central => lo que esta en subseries (centralFile)
    //Antes de armar el trdTable hay que sacar los ids en los arr que no se necesitan comparando con lo que esta seleccionado
    const castedFilterResp:ITrdVersion[] = getCopyArrayOrObject(filteredResp)
    castedFilterResp.forEach((value,index)=>{
      if(value.series && selected.series !== "0"){
        castedFilterResp[index].series = value.series.filter(el=> el === selected.series)
      }
      if(value.subseries && selected.subseries !== "0"){
        castedFilterResp[index].subseries = value.subseries.filter(el=> el === selected.subseries)
      }
      if(value.productionOffices && selected.productionOffice !== "0"){
        castedFilterResp[index].productionOffices = value.productionOffices.filter(el=> el === selected.productionOffice)
      }
    })


    let finalArrTrdTable: ITrdTable[] = [];
    for (const version of castedFilterResp) {
      // hacer el map de las production offices asignadas
      let objTrdTable: ITrdTable = {
        dependency: null,
        documentarySeries: null,
        series: null,
        CT: null,
        E: null,
        S: null,
        MD: null,
        central: null,
        managment: null,
        documentarySubseries: null,
        procedure: null,
        subseries: null,
        EL: null,
        P: null,
        documentaryType: null,
        template: null,
      };
      if (version.productionOffices) {
        for (const productionOf of version.productionOffices) {
          const productionOffice = productionOffices.find(
            (po) => po.idProductionOffice === productionOf
          );
          if (productionOffice) {
            objTrdTable = {
              ...objTrdTable,
              dependency: productionOffice.code,
            };
            finalArrTrdTable.push(objTrdTable);
          }
        }
      }
      if (version.series) {
        //BUSCAR LA SERIE
        for (const serie of version.series) {
          const serieFind = series.find((ser) => ser.idSerie === serie);
          if (serieFind) {
            objTrdTable = {
              ...objTrdTable,
              dependency: serieFind.codeOp,
              documentarySeries: serieFind.seriesName,
              series: serieFind.serieCode,
            };
            const index = finalArrTrdTable.findIndex(
              (trd) => trd.dependency === serieFind.codeOp
            );

            if (index >= 0) {
              if (finalArrTrdTable[index].series === null) {
                finalArrTrdTable[index].dependency = serieFind.codeOp;
                finalArrTrdTable[index].documentarySeries =
                  serieFind.seriesName;
                finalArrTrdTable[index].series = serieFind.serieCode;
              } else {
                finalArrTrdTable.push(objTrdTable);
              }
            }
          }
        }
      }
      if (version.subseries) {
        for (const subSerie of version.subseries) {
          const subSerieFind = subSeries.find(
            (sub) => sub.idSubserie === subSerie
          );
          if (subSerieFind) {
            //traer la oficina productora para sacar el codigo
            const productionOffice = productionOffices.find(
              (po) => po.idProductionOffice === subSerieFind.idProductionOffice
            );
            if (productionOffice) {
              //traer la serie para sacar el serieCode
              const serieFind = series.find(
                (ser) => ser.idSerie === subSerieFind.idSerie
              );
              if (serieFind) {
                //traer el index del arr grande donde estan los datos que coinciden
                const index = finalArrTrdTable.findIndex(
                  (trd) =>
                    trd.dependency === productionOffice.code &&
                    trd.series === serieFind.serieCode
                );
                if (index >= 0) {
                  if (finalArrTrdTable[index].subseries === null) {
                    finalArrTrdTable[index].CT = subSerieFind.provision.CT;
                    finalArrTrdTable[index].E = subSerieFind.provision.E;
                    finalArrTrdTable[index].S = subSerieFind.provision.S;
                    finalArrTrdTable[index].MD = subSerieFind.provision.MD;
                    finalArrTrdTable[index].central = subSerieFind.centralFile;
                    finalArrTrdTable[index].managment =
                      subSerieFind.managementFile;
                    finalArrTrdTable[index].documentarySubseries =
                      subSerieFind.name;
                    finalArrTrdTable[index].procedure = subSerieFind.process;
                    finalArrTrdTable[index].subseries =
                      subSerieFind.subSerieCode;
                  } else {
                    finalArrTrdTable.push({
                      ...objTrdTable,
                      documentarySeries: serieFind.seriesName,
                      series: serieFind.serieCode,
                      CT: subSerieFind.provision.CT,
                      E: subSerieFind.provision.E,
                      S: subSerieFind.provision.S,
                      MD: subSerieFind.provision.MD,
                      central: subSerieFind.centralFile,
                      managment: subSerieFind.managementFile,
                      documentarySubseries: subSerieFind.name,
                      procedure: subSerieFind.process,
                      subseries: subSerieFind.subSerieCode,
                    });
                  }
                }
              }
            }
          }
        }
        if (version.documentalType) {
          for (const docType of version.documentalType) {
            const docTypeFind = documentalType.find(
              (doc) => doc.idDocumentalType === docType
            );
            if (docTypeFind) {
              //traer la oficina productora para sacar el codigo
              const productionOffice = productionOffices.find(
                (po) => po.idProductionOffice === docTypeFind.idProductionOffice
              );
              if (productionOffice) {
                //traer la serie
                //traer la serie para sacar el serieCode
                const serieFind = series.find(
                  (ser) => ser.idSerie === docTypeFind.idSerie
                );
                if (serieFind) {
                  //traer la subSerie
                  const subSerieFind = subSeries.find(
                    (sub) => sub.idSubserie === docTypeFind.idSubSerie
                  );
                  if (subSerieFind) {
                    //Buscar el index en el finalArr

                    const index = finalArrTrdTable.findIndex(
                      (trd) =>
                        trd.dependency === productionOffice.code &&
                        trd.series === serieFind.serieCode &&
                        trd.subseries === subSerieFind.subSerieCode
                    );

                    if (index >= 0) {
                      if (finalArrTrdTable[index].documentaryType === null) {
                        finalArrTrdTable[index].EL =
                          docTypeFind.supportElectronic;
                        finalArrTrdTable[index].P =
                          docTypeFind.supportElectronic;
                        finalArrTrdTable[index].documentaryType =
                          docTypeFind.name;
                        finalArrTrdTable[index].template = docTypeFind.template;
                      } else {
                        finalArrTrdTable.push({
                          ...objTrdTable,
                          documentarySeries: serieFind.seriesName,
                          series: serieFind.serieCode,
                          CT: subSerieFind.provision.CT,
                          E: subSerieFind.provision.E,
                          S: subSerieFind.provision.S,
                          MD: subSerieFind.provision.MD,
                          central: subSerieFind.centralFile,
                          managment: subSerieFind.managementFile,
                          documentarySubseries: subSerieFind.name,
                          procedure: subSerieFind.process,
                          subseries: subSerieFind.subSerieCode,
                          EL: docTypeFind.supportElectronic,
                          P: docTypeFind.supportElectronic,
                          documentaryType: docTypeFind.name,
                          template: docTypeFind.template,
                        });
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    setTrdFiltered(finalArrTrdTable)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <>
      <Divider variant="fullWidth" />
      <Paper square className={classes.paper}>
        <Box mb={3}>
          <Box style={{ height: "5px" }} />
          <Box m={2}>
            <Grid container style={{ marginBottom: "20px" }}>
              <Box width={1} display="flex" mt={1}>
                <Box width={"14%"} mr={2} mt={2} style={{ fontSize: 14 }}>
                  <FormattedMessage id="Version" />:
                </Box>
                <Box width={"70%"}>
                  <TextField
                    name="version"
                    variant="outlined"
                    select
                    size="small"
                    value={selected.version}
                    className={classes.myTextFieldRoot}
                    onChange={handleSelect}
                    //   disabled={ active ? true : false}
                  >
                    <MenuItem value={"0"}>
                      <FormattedMessage id={"All"} />
                    </MenuItem>
                    {versions.map((version) => (
                      <MenuItem
                        key={version.idVersion}
                        value={version.idVersion}
                      >
                        {version.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>

              <Box width={1} display="flex" mt={1}>
                <Box width={"14%"} mr={2} mt={2} style={{ fontSize: 14 }}>
                  <FormattedMessage id="ProductionOffice" />:
                </Box>
                <Box width={"70%"}>
                  <TextField
                    name="productionOffice"
                    variant="outlined"
                    select
                    size="small"
                    value={selected.productionOffice}
                    className={classes.myTextFieldRoot}
                    onChange={handleSelect}
                    //   disabled={ active ? true : false}
                  >
                    <MenuItem value={"0"}>
                      <FormattedMessage id={"All"} />
                    </MenuItem>
                    {productionOffices.map((productionOffice) => (
                      <MenuItem
                        key={productionOffice.idProductionOffice}
                        value={productionOffice.idProductionOffice}
                      >
                        {productionOffice.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>

              <Box width={1} display="flex" mt={1}>
                <Box width={"14%"} mr={2} mt={2} style={{ fontSize: 14 }}>
                  <FormattedMessage id="Series" />:
                </Box>
                <Box width={"70%"}>
                  <TextField
                    name="series"
                    variant="outlined"
                    select
                    size="small"
                    value={selected.series}
                    className={classes.myTextFieldRoot}
                    onChange={handleSelect}
                    //   disabled={ active ? true : false}
                  >
                    <MenuItem value={"0"}>
                      <FormattedMessage id={"All"} />
                    </MenuItem>
                    {series.map((serie) => (
                      <MenuItem key={serie.idSerie} value={serie.idSerie}>
                        {serie.seriesName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>

              <Box width={1} display="flex" mt={1}>
                <Box width={"14%"} mr={2} mt={2} style={{ fontSize: 14 }}>
                  <FormattedMessage id="Subseries" />:
                </Box>
                <Box width={"70%"}>
                  <TextField
                    name="subseries"
                    variant="outlined"
                    select
                    size="small"
                    value={selected.subseries}
                    className={classes.myTextFieldRoot}
                    onChange={handleSelect}
                    //   disabled={ active ? true : false}
                  >
                    <MenuItem value={"0"}>
                      <FormattedMessage id={"All"} />
                    </MenuItem>
                    {subSeries.map((subSer) => (
                      <MenuItem
                        key={subSer.idSubserie}
                        value={subSer.idSubserie}
                      >
                        {subSer.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>
            </Grid>
            <TRDTable trd={trdFiltered}/>

            <Box style={{ height: "20px" }} />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

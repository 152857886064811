import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { Box, Divider } from "@material-ui/core";
import { CorrespondenceForm } from "../correspondence/CorrespondenceForm";
import { AppState } from "../../../redux/reducers/rootReducer";

export const PqrsDetails = () => {
  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.correspondence
  );
  return (
    <Box m={6} mt={4}>
      <h1>
        {" "}
        <FormattedMessage id="Settled" /> No. {activeCorrespondence?.id}
      </h1>
      <Divider />
      <br />
      <CorrespondenceForm details />
    </Box>
  );
};

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import {
  Box,
  createTheme,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { CustomizedTableFooter } from "../../custom/CustomizedTableFooter";
import { counter } from "../../../helpers/count";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Colors } from "../../../shared/constants/themes/Colors";
import { useStyles } from "../../../shared/styles/useStyles";
import { ProponentBody } from "./ProponentBody";
import { IProponent } from "../../../interfaces/Correspondence";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});

const defaultProps = {
  bgcolor: "background.paper",
  border: 1,
  style: { width: "100%", height: "100%" },
};

export const ProponentTable = () => {
  const classes = useStyles();

  const { activeTender } = useSelector(
    (state: AppState) => state.correspondence
  );
  const { data, value } = useSelector((state: AppState) => state.search);
  const proponents: IProponent[] = activeTender ? activeTender.proponents : [];
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );
  const count = counter(value, proponents, data);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, proponents.length - page * rowsPerPage);
  return (
    <ThemeProvider theme={theme}>
      <Box borderColor="grey.400" borderRadius={4} {...defaultProps}>
        <TableContainer component={Paper} className={classes.heightTable}>
          {proponents.length <= 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ height: 200 }}
              className={classes.title}
            >
              <InfoOutlinedIcon
                style={{ fontSize: 30, color: Colors.ACCENT }}
              />
              <FormattedMessage id={"NoCreatedProponents"} />
            </Box>
          ) : (
            <Table
              className={classes.table}
              size="small"
              style={{ tableLayout: "auto" }}
              aria-label="a dense table"
            >
              <TableHead className={classes.titleTable}>
                <TableRow>
                  <TableCell
                    //   width={"25%"}
                    align="center"
                    className={classes.titleTable}
                  >
                    <FormattedMessage id="BusinessName" />{" "}
                  </TableCell>
                  <TableCell align="center" className={classes.titleTable}>
                    <FormattedMessage id="LegalRepresentative" />{" "}
                  </TableCell>
                  <TableCell
                    //   width={"30%"}
                    align="center"
                    className={classes.titleTable}
                  >
                    <FormattedMessage id="DeliveryPersonNameTwo" />{" "}
                  </TableCell>
                  <TableCell align="center" className={classes.titleTable}>
                    <FormattedMessage id="Identification" />{" "}
                  </TableCell>
                  <TableCell align="center" className={classes.titleTable}>
                    <FormattedMessage id="Phone" />{" "}
                  </TableCell>
                  <TableCell
                    width={"20%"}
                    className={classes.titleTable}
                    align="center"
                  >
                    <FormattedMessage id="Actions" />{" "}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!value.trim()
                  ? (rowsPerPage > 0
                      ? proponents.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : proponents
                    ).map((row) => (
                      <ProponentBody proponent={row} key={row.idNumber} />
                    ))
                  : data &&
                    (rowsPerPage > 0
                      ? data.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : data
                    ).map((row) => (
                      <ProponentBody proponent={row} key={row.idNumber} />
                    ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 13 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {/* --------- Footer para paginación -------- */}
              <CustomizedTableFooter count={count} />
            </Table>
          )}
        </TableContainer>
      </Box>
    </ThemeProvider>
  );
};

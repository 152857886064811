import firebase from "firebase/app";
import { IUserAnnotations, Types, IExternalSender } from "../types/types";
import { User } from "../../interfaces/Users";
import {
  addUser,
  assignModulesUser,
  assignOfficesUser,
  editUser,
  getUserByDocument,
  getUserByEmail,
  getUsersByEntity,
} from "../../services/firebase/users";
import {
  setSuccessMsg,
  uiOpenAlertExists,
  uiOpenSuccessAlert,
} from "./uiActions";
import { registerWithEmailPassword } from "../../services/firebase/auth";
import { uploadFileAsync } from "../../helpers/UploadFile";
import { uiOpenErrorAlert, uiCloseModalAssignModules } from "./uiActions";
import { Office } from "../../interfaces/OrganizationChart";
import { getOfficeById } from "../../services/firebase/correspondence";

export const startNewUser = (user: User) => {
  return async (dispatch: any, getState: any) => {
    const { idEntity, businessName } = getState().auth.user;
    const { email, document, firstName, firstLastName } = user;
    let { profileImage, secondName, secondLastName } = user;
    user.document = Number(document);

    const existsUserDocument = await getUserByDocument(user.document, idEntity);
    const existsUserEmail = await getUserByEmail(email, idEntity);

    if (existsUserDocument.length > 0 || existsUserEmail.length > 0) {
      dispatch(uiOpenAlertExists());
    } else {
      try {
        // Manejo de imagen de usuario
        if (profileImage) {
          const uriResponse = await uploadFileAsync(
            profileImage as File,
            `avatars/${document}`
          );
          user.profileImage = uriResponse;
        } else {
          user.profileImage = null;
        }

        // Manejo para username
        if (secondName?.trim()) {
          secondName = ` ${secondName}`;
        }
        if (secondLastName?.trim()) {
          secondLastName = ` ${secondLastName}`;
        }
        user.username =
          `${firstName}${secondName} ${firstLastName} ${secondLastName}`.trim();

        user.idEntity = idEntity;
        user.businessName = businessName;
        user.id = document;

        const { localId } = await registerWithEmailPassword(
          email,
          document.toString()
        );
        user.uid = localId;
        user.createdAt = firebase.firestore.Timestamp.now();

        await addUser(user);
        dispatch(addNewUser(user));
        dispatch(uiOpenSuccessAlert());
      } catch (error: any) {
        throw new Error(error);
      }
    }
  };
};

const addNewUser = (user: User) => ({
  type: Types.userAddNew,
  payload: user,
});

// Cargar usuarios del admin
export const startLoadingUsers = (idEntity: string) => {
  return async (dispatch: any) => {
    const resp = await getUsersByEntity(idEntity);

    dispatch(setUsers(resp));
  };
};
const fetchOfficesDetails = async (officesIds: string[]) => {
  const officesArr: Office[] = [];
  for (const office of officesIds) {
    const resp = await getOfficeById(office);
    resp && officesArr.push(resp);
  }

  return officesArr;
};
export const startLoadingUsersAnnotations = (idEntity: string) => {
  return async (dispatch: Function) => {
    const resp = await getUsersByEntity(idEntity);
    //poner por defecto la propiedad selected en false y armar el label name
    const finalArrUsers: IUserAnnotations[] = [];
    for (const user of resp) {
      const detailsOffice = await fetchOfficesDetails(user.offices);
      let concatName = "";
      //@ts-ignore
      for (const [i, office] of detailsOffice.entries()) {
        const str =
          i < detailsOffice.length - 1 ? office.name + " " : office.name;
        concatName += str;
      }

      const finalName =
        user.firstName +
        " " +
        user.secondName +
        " " +
        user.firstLastName +
        " " +
        user.secondLastName +
        " - " +
        concatName.split(" ").join(" - ");

      const finalObj: IUserAnnotations = {
        ...user,
        selected: false,
        labelName: finalName,
      };
      finalArrUsers.push(finalObj);
    }
    dispatch(loadUsersAnnotations(finalArrUsers));
  };
};
export const loadUsersAnnotations = (users: IUserAnnotations[]) => ({
  type: Types.usersLoad,
  payload: users,
});

export const setUsers = (users: User[]) => ({
  type: Types.usersLoad,
  payload: users,
});

// Usuario activo
export const activeUser = (user: User) => ({
  type: Types.userActive,
  payload: user,
});

export const userCleanActive = () => ({ type: Types.userCleanActive });

// Editar usuario
export const startEditUser = (user: User, changeImage: boolean) => {
  return async (dispatch: Function) => {
    const { document, profileImage, firstName, firstLastName } = user;
    let { secondName, secondLastName } = user;

    try {
      if (changeImage) {
        const uriResponse = await uploadFileAsync(
          profileImage as File,
          `avatars/${document}`
        );
        user.profileImage = uriResponse;
      }

      secondName?.trim() && (secondName = ` ${secondName}`);
      secondLastName?.trim() && (secondLastName = ` ${secondLastName}`);
      user.username =
        `${firstName}${secondName} ${firstLastName} ${secondLastName}`.trim();

      dispatch(activeUser(user));

      await editUser(user);
      dispatch(updateUser(user));
      dispatch(uiOpenSuccessAlert());
    } catch (error: any) {
      throw new Error(error);
    }
  };
};

export const updateUser = (user: User) => ({
  type: Types.userUpdated,
  payload: user,
});

export const startAssignModules = (user: User, modules: string[]) => {
  return async (dispatch: Function) => {
    try {
      await assignModulesUser(user, modules);
      dispatch(updateUser({ ...user, modules }));

      dispatch(uiCloseModalAssignModules());
      dispatch(setSuccessMsg("ModulesAssigned"));
      dispatch(uiOpenSuccessAlert());
    } catch (error) {
      dispatch(setSuccessMsg("ErrorAssigningModules"));
      dispatch(uiOpenErrorAlert());
    }
  };
};

// Cargar oficinas asignadas
export const startLoadAssignedOffices = (userOffices: string[]) => {
  return async (dispatch: Function, getState: Function) => {
    const { offices } = getState().organizationChart;

    const assignedOffices = offices.filter((office: Office) =>
      userOffices.includes(office.idOffice)
    );
    dispatch(setAssignedOffices(assignedOffices));
  };
};

export const setAssignedOffices = (offices: Office[]) => ({
  type: Types.userLoadAssignedOffices,
  payload: offices,
});

// Asignar oficinas
export const startAssignOffice = (idVersion: string, idOffice: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { activeUser } = getState().users;
    const { offices } = getState().organizationChart;
    const user = { ...activeUser };
    let newOffices: string[] = [];

    const idProductionOffice =
      offices.length > 0
        ? offices.filter((office: Office) => office.idOffice === idOffice)[0]
            .idProductionOffice
        : "";

    const validateProductionOffice = activeUser.offices.some((office: string) =>
      office.includes(idProductionOffice)
    );

    if (activeUser.offices.length > 0 && !validateProductionOffice) {
      dispatch(setSuccessMsg("OnlyOneProducingOffice"));
      dispatch(uiOpenAlertExists());
    } else {
      if (activeUser.offices.includes(idOffice)) {
        dispatch(setSuccessMsg("OfficeAlreadyAssigned"));
        dispatch(uiOpenAlertExists());
      } else {
        newOffices = [...activeUser.offices, idOffice];
        activeUser.offices = newOffices;

        const officeFiltered =
          offices.length > 0
            ? offices.filter(
                (office: Office) =>
                  office.idOffice === idOffice && office.idVersion === idVersion
              )[0]
            : [];

        try {
          await assignOfficesUser(`${activeUser.document}`, newOffices);
          dispatch(updateUser({ ...user, offices: newOffices }));
          dispatch(updateUser({ ...activeUser }));
          dispatch(addNewAssignedOffice(officeFiltered));
          dispatch(setSuccessMsg("AssignedOffice"));
          dispatch(uiOpenSuccessAlert());
        } catch (error) {
          dispatch(setSuccessMsg("ErrorAssignOffice"));
          dispatch(uiOpenErrorAlert());
        }
      }
    }
  };
};

const addNewAssignedOffice = (office: Office) => ({
  type: Types.userAddAssignedOffice,
  payload: office,
});

// Eliminar oficina
export const startDeleteAssignedOffice = (office: Office) => {
  return async (dispatch: Function, getState: Function) => {
    const { activeUser } = getState().users;
    const user = { ...activeUser };
    const newOffices: string[] = activeUser.offices.filter(
      (el: string) => el !== office.idOffice
    );
    activeUser.offices = newOffices;

    try {
      await assignOfficesUser(`${activeUser.document}`, newOffices);
      dispatch(updateUser({ ...user, offices: newOffices }));
      dispatch(deleteAssignedOffice(office.idOffice));
      dispatch(setSuccessMsg("OfficeDeleted"));
      dispatch(uiOpenSuccessAlert());
    } catch (error) {
      dispatch(setSuccessMsg("ErrorDeletingAssignedOffice"));
      dispatch(uiOpenErrorAlert());
    }
  };
};

const deleteAssignedOffice = (idOffice: string) => ({
  type: Types.userDeleteAssignedOffice,
  payload: idOffice,
});

export const setActiveExternalSender = (sender: IExternalSender | null) => ({
  type: Types.setActiveExternalSender,
  payload: sender,
});

import moment from "moment";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Box, Grid } from "@material-ui/core";

import { CorrespondenceTable } from "../../components/correspondence-module/correspondence/CorrespondenceTable";
import { CustomizedSearch } from "../../components/custom/CustomizedSearch";
import { CustomizedWindow } from "../../components/custom/CustomizedWindow";
import { SettledDetails } from "../../components/document-managment/correspondence/SettledDetails";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import { firebase } from "../../config/firebase/firebase-config";
import { ICorrespondence } from "../../interfaces/Correspondence";
import { setActiveCorrespondence } from "../../redux/actions/correspondenceActions";
import { uiCloseModalSee } from "../../redux/actions/uiActions";
import { AppState } from "../../redux/reducers/rootReducer";

export const VoBoScreen = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { correspondence } = useSelector(
    (state: AppState) => state.correspondence
  );
  const { modalSeeOpen } = useSelector((state: AppState) => state.ui);
  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { user } = useSelector((state: AppState) => state.auth);

  let requestVoboToMe: ICorrespondence[] = [];

  if (user) {
    requestVoboToMe = correspondence.filter((cor) => {
      return cor.reviewers?.find(
        (obj) =>
          obj.id === user.id.toString() && !obj.emitComments && !obj.submitDate
      );
    });
  }
  const date =
    activeCorrespondence?.createAt &&
    moment(
      (activeCorrespondence.createAt as firebase.firestore.Timestamp).toDate()
    ).format("YYYY/MM/DD - h:mm:ss");
  const onDeny = () => {
    dispatch(uiCloseModalSee());
    dispatch(setActiveCorrespondence(null));
  };

  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box>
        <Box mb={3} fontSize={20}>
          <Grid container>
            <Grid item xs={7} />
            <Grid item xs={5}>
              <CustomizedSearch data={requestVoboToMe} />
            </Grid>
          </Grid>
        </Box>

        <CorrespondenceTable readOnly rows={requestVoboToMe} component="VoBo" />
        <CustomizedWindow
          open={modalSeeOpen}
          content={<SettledDetails component="VoBo" />}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "Settled" })} No. ${
            activeCorrespondence?.id
          } -  ${date}`}
        />
      </Box>
    </AppAnimate>
  );
};

import clsx from "clsx";
import moment from "moment";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { ModulesType } from "../../../enums/enums";
import { ICorrespondence } from "../../../interfaces/Correspondence";
// import { setActiveCorrespondenceDocManagment } from "../../../redux/actions/documentManagmentActions";
// import { uiOpenModalSee } from "../../../redux/actions/uiActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Colors } from "../../../shared/constants/themes/Colors";
import { useStyles } from "../../../shared/styles/useStyles";

interface Props {
  module: ModulesType.Correspondence | ModulesType.DocumentManagment;
}

export const CorrespondenceSearch = ({ module }: Props) => {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [results, setResults] = useState<ICorrespondence[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [noValid, setNoValid] = useState(false);

  const { correspondence: communicationsCorrespondence } = useSelector(
    (state: AppState) => state.correspondence
  );
  const { correspondence: communicationsManagment } = useSelector(
    (state: AppState) => state.documentManagment
  );

  const arrayCorrespondences =
    module === ModulesType.Correspondence
      ? communicationsCorrespondence
      : communicationsManagment;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAlert(false);
    setValue(event.target.value);
  };

  const handleSearch = () => {
    if (value !== "") {
      const searchText = value.toLowerCase();

      const filterCorrespondences = arrayCorrespondences.filter(
        (cor) =>
          cor.id.toLowerCase().includes(searchText) ||
          cor.description.toLowerCase().includes(searchText)
      );

      setNoValid(false);
      setResults(filterCorrespondences);
      filterCorrespondences.length === 0
        ? setShowAlert(true)
        : setShowAlert(false);
    } else {
      setNoValid(true);
    }
  };

  // const setActiveCorrespondence = (
  //   event: React.SyntheticEvent,
  //   correspondence: ICorrespondence
  // ) => {
  //   event.preventDefault();
  //   dispatch(uiOpenModalSee());
  //   dispatch(setActiveCorrespondenceDocManagment(correspondence));
  // };
  return (
    <Box p={2}>
      <Typography variant="body1" className={classes.title}>
        <FormattedMessage id="CorrespondenceSearch" />
      </Typography>
      <Box mt={1}>
        <Typography variant="body2" className={classes.typography}>
          <FormattedMessage id="CorrespondenceSearchValue" />:
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={9}>
            <TextField
              className={classes.myTextFieldRoot}
              name="value"
              size="small"
              variant="outlined"
              onChange={handleChange}
              value={value}
              autoComplete="off"
              onKeyPress={(event) => event.key === "Enter" && handleSearch()}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              className={clsx(classes.btnAction, classes.save)}
              style={{ marginTop: 10 }}
              onClick={handleSearch}
            >
              <FormattedMessage id="Search" />
            </Button>
          </Grid>

          {noValid && (
            <Grid item xs={12}>
              <Box mt={0} ml={2} style={{ fontSize: 12, color: "red" }}>
                <FormattedMessage id="EnterValidValue" />
              </Box>
            </Grid>
          )}

          {/* Resultados  */}
          {results.length > 0 && (
            <Grid item xs={12} className={classes.typography}>
              <label
                className="form-text"
                style={{
                  display: "inline-block",
                  marginBottom: 15,
                  marginTop: 20,
                }}
              >
                <FormattedMessage id="Results" />:
              </label>
              <div className="container-scroll">
                {results.map((result) => (
                  <Grid container key={result.id} spacing={2}>
                    <Grid item xs={6} className={classes.typography}>
                      {result.id}
                    </Grid>
                    <Grid item xs={3}>
                      {/* <Link
                        href="#"
                        onClick={(e) => setActiveCorrespondence(e, result)}
                      > */}
                        {result.description}
                      {/* </Link> */}
                    </Grid>
                    {result.createAt && (
                      <Grid item xs={3}>
                        {moment(result.createAt.toDate()).format("YYYY/MM/DD")}
                      </Grid>
                    )}
                  </Grid>
                ))}
              </div>
            </Grid>
          )}
          {results.length === 0 && showAlert && (
            <Grid item xs={12} className={classes.typography}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ height: 200 }}
                className={classes.title}
              >
                <InfoOutlinedIcon
                  style={{ fontSize: 30, color: Colors.ACCENT }}
                />
                <FormattedMessage id={"NoData"} />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

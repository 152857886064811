import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  ICorrespondence,
  ICorrespondenceResponse,
} from "../../../interfaces/Correspondence";
import {
  setActiveCorrespondence,
  startSetCorrespondence,
} from "../../../redux/actions/correspondenceActions";
import {
  uiOpenModalDelete,
  uiOpenModalEdit,
  uiOpenModalInfo,
} from "../../../redux/actions/uiActions";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { TableCell, TableRow } from "@material-ui/core";
import { CustomizedIcons } from "../../custom/CustomizedIcons";
import { CorrespondenceLabel } from "./CorrespondenceLabel";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  CorrespondenceInputType,
  CorrespondenceStatus,
  LabelsSize,
} from "../../../enums/enums";
import { useStyles } from "../../../shared/styles/useStyles";
// import { joinUserNames } from "../../../utils/utils";
import { uiOpenModalSee } from "../../../redux/actions/uiActions";
import { setActiveCorrespondenceDocManagment } from "../../../redux/actions/documentManagmentActions";
import clsx from "clsx";
import { TrafficLight } from "../pqrs/TrafficLight";
import { getCorrespondenceAnswers } from "../../../services/firebase/correspondence";

interface props {
  communication: ICorrespondence;
  readOnly?: boolean;
  isDocManagment?: boolean;
  isRead?: boolean;
  component?: string;
  showState?: boolean;
}
/* const pageStyle = `
@page {
  size: 89mm 28mm;
  margin: 0
}
`; */

export const CorrespondenceBody: FC<props> = ({
  communication,
  readOnly = false,
  isDocManagment = false,
  isRead = false,
  component = "OTHER",
  showState = false,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const componentRef = useRef(null);
  const { productionOffices } = useSelector((state: AppState) => state.trd);
  const { labelSize } = useSelector((state: AppState) => state.correspondence);
  const dispatch = useDispatch();
  const { paramResponseTime }: any = useSelector(
    (state: AppState) => state.correspondence
  );

  const [emailsSendedEp, setEmailSendedEp] = useState<boolean>(false);

  const pageStyle =
    !labelSize || labelSize === LabelsSize.SIZE_89MM
      ? `
      @page {
        size: 89mm 28mm;
        margin: 0
      }
      `
      : `
   @page {
     size: 1000mm 250mm;
     margin: 2mm 2mm 2mm 2mm;
   }
      `;
  const onPrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
  });

  const onDelete = () => {
    dispatch(setActiveCorrespondence(communication));
    dispatch(uiOpenModalDelete());
  };
  const onEdit = () => {
    dispatch(setActiveCorrespondence(communication));
    dispatch(uiOpenModalEdit());
  };
  const onSee = () => {
    dispatch(setActiveCorrespondence(communication));
    dispatch(uiOpenModalInfo());
  };
  const getOficeName = (idProductionOffice: string) => {
    const findOffice = productionOffices.find(
      (el) => el.idProductionOffice === idProductionOffice
    );
    if (findOffice) {
      return findOffice.name;
    }
    return "";
  };

  const handleOpen = () => {
    dispatch(setActiveCorrespondenceDocManagment(communication));
    dispatch(uiOpenModalSee());
  };
  useEffect(() => {
    const checkExtensionStatus = async () => {
      if (communication.isInExtension) {
        //comparar fecha actual y si tiene prorroga
        //en caso de que la fecha actual sea mayor a la fecha final de la prorroga poner isExtencion en false y finalExtensionDate en null
        const currenDate = moment();
        const finalExtensionDate = moment(
          communication.finalExtensionDate?.toDate()
        );
        if (currenDate.isAfter(finalExtensionDate)) {
          const copyCorrespondence = { ...communication };
          copyCorrespondence.isInExtension = false;
          copyCorrespondence.finalExtensionDate = null;
          await dispatch(
            startSetCorrespondence(copyCorrespondence, true, true)
          );
        }
      }
    };
    const checkAnwers = async () => {
      setEmailSendedEp(false);
      if (communication.inputType === CorrespondenceInputType.EP) {
        await getCorrespondenceAnswers(
          communication.idEntity,
          communication.id,
          (resp: ICorrespondenceResponse[] | null) => {
            if (resp != null) {
              resp.forEach((resp) => {
                if (!emailsSendedEp) {
                  if (resp.subject.startsWith("Enviado al")) {
                    setEmailSendedEp(true);
                  }
                }
              });
            }
          }
        );
      }
    };
    checkExtensionStatus();
    checkAnwers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const checkAnwers = async () => {
      setEmailSendedEp(false);
      if (communication.inputType === CorrespondenceInputType.EP) {
        await getCorrespondenceAnswers(
          communication.idEntity,
          communication.id,
          (resp: ICorrespondenceResponse[] | null) => {
            if (resp != null) {
              resp.forEach((resp) => {
                if (!emailsSendedEp) {
                  if (resp.subject.startsWith("Enviado al")) {
                    setEmailSendedEp(true);
                  }
                }
              });
            }
          }
        );
      }
    };

    checkAnwers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Obtener fecha limite respuesta
   */
  let responseDateParam: any = null;
  let daysAvailableDocument: any = null;
  let responseString = null;
  let availableDays = null;
  if (
    communication.communicationType?.isParam ||
    communication.responseTimeDays
  ) {
    const paramNameDays = communication.communicationType?.option + "Days";

    daysAvailableDocument =
      communication && communication.responseTimeDays
        ? communication.responseTimeDays
        : paramResponseTime[paramNameDays];

    const typeDays =
      paramResponseTime[communication.communicationType!.option + "TypeDays"];

    responseDateParam =
      typeDays === "BUSINESSDAYS"
        ? moment(communication.createAt.toDate()).businessAdd(
            daysAvailableDocument,
            "days"
          )
        : moment(communication.createAt.toDate()).add(
            daysAvailableDocument,
            "days"
          );

    /**
     * Dias disponibles para responder
     */
    const dateCurrent = moment();
    const minute = 1000 * 60;
    const hour = minute * 60;
    const day = hour * 24;
    availableDays = Math.floor(
      moment(responseDateParam).diff(dateCurrent) / day
    );

    /**
     * OBTENER CON RESPUESTA O SIN RESPUESTA
     */
    communication.answer && communication.answeredDate
      ? (responseString = intl.formatMessage({
          id: "answered",
        }))
      : (responseString = intl.formatMessage({
          id: "Unanswered",
        }));
  }

  const withoutDocuments = () => {
    return (
      (communication.digitalSupport as []).length === 0 &&
      (communication?.appendDocs === undefined ||
        communication?.appendDocs?.length === 0)
    );
  };

  const withoutEmail = () => {
    return (
      !emailsSendedEp && communication.inputType === CorrespondenceInputType.EP
    );
  };

  return (
    <TableRow
      key={communication.id}
      onClick={() =>
        readOnly &&
        !communication.isTender &&
        communication.status !== CorrespondenceStatus.INACTIVE &&
        handleOpen()
      }
      className={
        ((component === "VoBo" && communication.isReadedVobo) ||
          (component === "PROJECTION" && communication.isReadedProjection) ||
          (component === "OTHER" && isRead) ||
          (component === "Correspondence" && isRead)) &&
        readOnly
          ? communication.status === CorrespondenceStatus.INACTIVE
            ? classes.styleRowReaded2
            : classes.styleRowReaded
          : communication.status === CorrespondenceStatus.INACTIVE
          ? classes.styleRowReaded2
          : classes.styleRow
      }
    >
      <TableCell
        component="th"
        scope="row"
        align="center"
        className={
          communication.status === CorrespondenceStatus.INACTIVE
            ? classes.disableCell
            : undefined
        }
      >
        {communication.id}
      </TableCell>
      {/* <TableCell align="center" className={communication.status === CorrespondenceStatus.INACTIVE?classes.disableCell:undefined}>{communication.inputType}</TableCell> */}
      <TableCell
        align="center"
        className={
          communication.status === CorrespondenceStatus.INACTIVE
            ? classes.disableCell
            : undefined
        }
      >
        {communication.inputType === CorrespondenceInputType.EP && (
          <FormattedMessage id="ExternalProduced" />
        )}
        {communication.inputType === CorrespondenceInputType.ER && (
          <FormattedMessage id="ExternalReceived" />
        )}
        {communication.inputType === CorrespondenceInputType.IR && (
          <FormattedMessage id="InternalReceived" />
        )}
        {communication.inputType === CorrespondenceInputType.IP && (
          <FormattedMessage id="InternalProduced" />
        )}
        {communication.inputType === CorrespondenceInputType.OR && (
          <FormattedMessage id="OtherShipmentsReceived" />
        )}
        {communication.inputType === CorrespondenceInputType.OP && (
          <FormattedMessage id="OtherShipmentsProduced" />
        )}
      </TableCell>
      <TableCell
        align="center"
        className={
          communication.status === CorrespondenceStatus.INACTIVE
            ? classes.disableCell
            : undefined
        }
      >
        {communication.communicationType && communication.isTender
          ? communication.communicationType.option
          : communication.communicationType &&
            communication.communicationType.option
          ? communication.communicationType.responseTime === 0 ||
            !communication.communicationType.responseTime
            ? intl.formatMessage({
                id: `${communication.communicationType.option}`,
              })
            : `${communication.communicationType.option}`
          : ""}
      </TableCell>
      <TableCell
        align="center"
        className={
          communication.status === CorrespondenceStatus.INACTIVE
            ? classes.disableCell
            : undefined
        }
      >
        {communication.subject}
      </TableCell>
      <TableCell
        align="center"
        className={
          communication.status === CorrespondenceStatus.INACTIVE
            ? classes.disableCell
            : undefined
        }
      >
        {moment(communication.createAt.toDate()).format("YYYY/MM/DD")}
      </TableCell>
      {(!readOnly || !isDocManagment) &&
      !communication.isTender &&
      communication.status !== CorrespondenceStatus.INACTIVE ? (
        <>
          <TableCell align="center">
            {getOficeName(communication.receivingDependency)}
          </TableCell>
          <TableCell align="center">
            {communication.addressee ? communication.addressee.name : ""}
          </TableCell>
          <TableCell align="center">
            {communication.externalSender
              ? communication.externalSender.name
              : ""}
            {/* {joinUserNames(communication.externalSender)} */}
          </TableCell>
          {communication.inputType === CorrespondenceInputType.ER ||
          communication.inputType === CorrespondenceInputType.IR ||
          communication.inputType === CorrespondenceInputType.OR ? (
            <TableCell>
              {(communication.communicationType?.isParam ||
                communication.responseTimeDays) &&
                communication.communicationType?.option !== "Answer" && (
                  <TrafficLight
                    // documentDate={communication.createAt.toDate()}
                    paramResponse={communication.communicationType}
                    answer={{
                      answer: communication.answer
                        ? communication.answer
                        : null,
                      answerDate: communication.answeredDate,
                      createDate: communication.createAt,
                    }}
                    correspondence={communication}
                  />
                )}
            </TableCell>
          ) : (
            <TableCell align="center" />
          )}

          <TableCell align="center">
            {withoutDocuments() ? <CustomizedIcons alertAttachments /> : ""}
          </TableCell>

          <TableCell align="center">
            {withoutEmail() ? <CustomizedIcons alertEmail /> : ""}
          </TableCell>
          <TableCell align="center">
            {responseDateParam && responseDateParam.format("YYYY/MM/DD")}
          </TableCell>
          <TableCell align="center">
            {availableDays && (availableDays > 0 ? availableDays : 0)}
          </TableCell>
          <TableCell align="center">{responseString}</TableCell>
          {showState && (
            <TableCell align="center">
              {communication.internalState && (
                <FormattedMessage id={`${communication.internalState}`} />
              )}
            </TableCell>
          )}

          <TableCell
            align="center"
            className={clsx(classes.fixedColumn, classes.styleAction)}
          >
            <CustomizedIcons
              editIcon
              //editIcon={communication.answer ? undefined : true}
              //seeIcon={communication.answer ? true : undefined}
              printIcon
              deleteIcon
              onEdit={onEdit}
              onSee={onSee}
              onPrint={onPrint}
              onDelete={onDelete}
            />
          </TableCell>
        </>
      ) : (
        <>
          <TableCell
            align="center"
            className={
              communication.status === CorrespondenceStatus.INACTIVE
                ? classes.disableCell
                : undefined
            }
          >
            {getOficeName(
              communication.settledAnswerId && communication.producerDependency
                ? communication.producerDependency
                : communication.receivingDependency
            )}
          </TableCell>
          <TableCell
            align="center"
            className={
              communication.status === CorrespondenceStatus.INACTIVE
                ? classes.disableCell
                : undefined
            }
          >
            {communication.addressee ? communication.addressee.name : ""}
          </TableCell>
          <TableCell
            align="center"
            className={
              communication.status === CorrespondenceStatus.INACTIVE
                ? classes.disableCell
                : undefined
            }
          >
            {communication.externalSender
              ? communication.externalSender.name
              : ""}
            {/* {joinUserNames(communication.externalSender)} */}
          </TableCell>
          {communication.inputType === CorrespondenceInputType.ER ||
          communication.inputType === CorrespondenceInputType.IR ||
          communication.inputType === CorrespondenceInputType.OR ? (
            <TableCell>
              {(communication.communicationType?.isParam ||
                communication.responseTimeDays) &&
                communication.communicationType?.option !== "Answer" && (
                  <TrafficLight
                    paramResponse={communication.communicationType}
                    answer={{
                      answer: communication.answer,
                      answerDate: communication.answeredDate,
                      createDate: communication.createAt,
                    }}
                    correspondence={communication}
                  />
                )}
            </TableCell>
          ) : (
            <TableCell align="center" />
          )}

          <TableCell align="center">
            {withoutDocuments() ? <CustomizedIcons alertAttachments /> : ""}
          </TableCell>

          <TableCell align="center">
            {withoutEmail() ? <CustomizedIcons alertEmail /> : ""}
          </TableCell>
          <TableCell
            align="center"
            className={
              communication.status === CorrespondenceStatus.INACTIVE
                ? classes.disableCell
                : undefined
            }
          >
            {responseDateParam && responseDateParam.format("YYYY/MM/DD")}
          </TableCell>
          <TableCell
            align="center"
            className={
              communication.status === CorrespondenceStatus.INACTIVE
                ? classes.disableCell
                : undefined
            }
          >
            {availableDays && (availableDays > 0 ? availableDays : 0)}
          </TableCell>
          <TableCell
            align="center"
            className={
              communication.status === CorrespondenceStatus.INACTIVE
                ? classes.disableCell
                : undefined
            }
          >
            {responseString}
          </TableCell>
          {showState && (
            <TableCell align="center">
              {communication.internalState && (
                <FormattedMessage id={`${communication.internalState}`} />
              )}
            </TableCell>
          )}
        </>
      )}
      {
        <TableCell style={{ display: "none" }}>
          <div ref={componentRef}>
            <CorrespondenceLabel
              communication={communication}
              size={
                !labelSize || labelSize === LabelsSize.SIZE_89MM ? "sm" : "lg"
              }
            />
          </div>
        </TableCell>
      }
    </TableRow>
  );
};

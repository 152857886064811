import { useState } from "react";
import { useDispatch } from "react-redux";

import { TableCell, TableRow } from "@material-ui/core";
import {
  activeUser,
  updateUser,
  startLoadAssignedOffices,
} from "../../../redux/actions/usersActions";
import { CustomizedIcons } from "../../custom/CustomizedIcons";
import { AntSwitch } from "../../custom/CustomizedSwitch";
import {
  uiOpenModalAssignOffices,
  uiOpenModalAssignModules,
  uiOpenModalEdit,
  uiOpenSuccessChange,
} from "../../../redux/actions/uiActions";
import { User } from "../../../interfaces/Users";
import { db } from "../../../config/firebase/firebase-config";

export const UsersBody = (user: User) => {
  const [state, setState] = useState({
    checkedA: user.active,
  });
  const dispatch = useDispatch();

  const onEditUser = () => {
    dispatch(activeUser(user));
    dispatch(uiOpenModalEdit());
  };

  const onDeleteUser = () => {};

  const onAssignModules = () => {
    dispatch(activeUser(user));
    dispatch(uiOpenModalAssignModules());
  };

  const onAssignOffice = async () => {
    dispatch(activeUser(user));
    await dispatch(startLoadAssignedOffices(user.offices));
    await dispatch(uiOpenModalAssignOffices());
  };

  // Función para cambiar estado
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    const check = event.target.checked;

    // Actualizacion en BD
    await db
      .collection("Users")
      .doc(`${user.document}`)
      .set({ active: check }, { merge: true });
    dispatch(updateUser({ ...user, active: check }));
    dispatch(uiOpenSuccessChange());
  };

  return (
    <TableRow key={user.document}>
      <TableCell component="th" scope="row">
        {user.firstName} {user.secondName} {user.firstLastName} {user.secondLastName}
      </TableCell>
      <TableCell>{user.typeDoc}</TableCell>
      <TableCell>{user.document}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell align="center">
        <AntSwitch
          checked={state.checkedA}
          name="checkedA"
          onChange={handleChange}
        />
      </TableCell>
      <TableCell align="center">
        <CustomizedIcons
          editIcon
          deleteIcon
          assignModulesIcon
          assignOfficeIcon
          onEdit={onEditUser}
          onDelete={onDeleteUser}
          onAssignModules={onAssignModules}
          onAssignOffice={onAssignOffice}
        />
      </TableCell>
    </TableRow>
  );
};

import { Office } from "../../interfaces/OrganizationChart";
import { Types, IOfficesState } from '../types/types';

const initialState: IOfficesState = {
  offices: [],
  activeOffice: null,
};

export const organizationChartReducer = (state = initialState, action: any): IOfficesState => {
  switch (action.type) {
    case Types.OrganizationChartAddNew:
      return {
        ...state,
        offices: [...state.offices, action.payload],
      };

    case Types.OrganizationChartLoad:
      return {
        ...state,
        offices: [...action.payload],
      };

    case Types.OrganizationCharActive:
      return {
        ...state,
        activeOffice: {
          ...action.payload,
        },
      };

    case Types.OrganizationCharCleaning:
      return {
        ...state,
        activeOffice: null,
      };

    case Types.OrganizationChartUpdated:
      return {
        ...state,
        offices: state.offices.map((office: Office) =>
          office.idOffice === action.payload.idOffice ? action.payload : office
        ),
      };

    case Types.OrganizationCharDelete:
      return {
        ...state,
        activeOffice: null,
        offices: state.offices.filter(
          (office: Office) => office.idOffice !== action.payload
        ),
      };

    default:
      return state;
  }
};

import { FC } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import {
  uiOpenModalDelete,
  uiOpenModalEdit,
} from "../../../redux/actions/uiActions";
import { CustomizedIcons } from "../../custom/CustomizedIcons";
import { useDispatch, useSelector } from "react-redux";
import { activeOffice } from "../../../redux/actions/organizationChartActions";
import { Office } from "../../../interfaces/OrganizationChart";
import { AppState } from "../../../redux/reducers/rootReducer";

interface props {
  office: Office;
  readonly?: boolean;
}

export const OrganizationChartBody: FC<props> = ({
  office,
  readonly = false,
}) => {
  const dispatch = useDispatch();

  const { versions } = useSelector((state: AppState) => state.trd);

  const onEditOrganizationChart = () => {
    dispatch(activeOffice(office));
    dispatch(uiOpenModalEdit());
  };

  const onDeleteOrganizationChart = () => {
    dispatch(activeOffice(office));
    dispatch(uiOpenModalDelete());
  };

  const filterVersion = versions.find(
    (version) => version.idVersion === office.idVersion
  );

  return (
    <TableRow key={office.idOffice}>
      <TableCell component="th" scope="row" align="center">
        {filterVersion ? filterVersion.name : "not found"}
      </TableCell>
      <TableCell align="center">{office.productionOffice}</TableCell>
      <TableCell align="center">{office.name}</TableCell>
      {!readonly && (
        <TableCell align="center">
          <CustomizedIcons
            editIcon
            deleteIcon
            onEdit={onEditOrganizationChart}
            onDelete={onDeleteOrganizationChart}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

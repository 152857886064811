import { useDispatch } from "react-redux";
import moment from "moment";
import { TableCell, TableRow } from "@material-ui/core";
import { CustomizedIcons } from "../../../custom/CustomizedIcons";
import { uiOpenModalAttachDocuments, uiOpenModalDelete, uiOpenModalEdit } from "../../../../redux/actions/uiActions";
import { ITrdVersion } from "../../../../redux/types/types";
import { firebase } from "../../../../config/firebase/firebase-config";
import { setActiveTrdVersion } from "../../../../redux/actions/trdActions";
import { FC } from "react";
interface props{
  version:ITrdVersion,
  readonly?:boolean
}
export const VersionBody:FC<props> = ({version,readonly=false}) => {
  const dispatch = useDispatch();

  const onEditVersion = () => {
    dispatch( setActiveTrdVersion(version) )
    dispatch(uiOpenModalEdit());
  };

  const onDeleteVersion = () => {
    dispatch( setActiveTrdVersion(version) )
    dispatch( uiOpenModalDelete() )

  };

  const onAttach = () => {
    dispatch( setActiveTrdVersion(version) )
    dispatch( uiOpenModalAttachDocuments() )

  };

  return (
    <TableRow key={version.code}>
      <TableCell component="th" scope="row" align="center">
        {version.code}
      </TableCell>
      <TableCell align="center">{version.name}</TableCell>
      <TableCell align="center">
        {(version.approvalDate as firebase.firestore.Timestamp).toDate
          ? moment(
              (version.approvalDate as firebase.firestore.Timestamp).toDate()
            ).format("YYYY/MM/DD")
          : moment(version.approvalDate).format("YYYY/MM/DD")}
      </TableCell>
      {!readonly && (

      <TableCell align="center">
        <CustomizedIcons
          editIcon
          deleteIcon
          attachIcon
          onEdit={onEditVersion}
          onDelete={onDeleteVersion}
          onAttach={onAttach}
        />
      </TableCell>
      )}
    </TableRow>
  );
};

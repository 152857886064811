import { FC } from "react";
import { useDispatch } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";
import { CustomizedIcons } from "../../../custom/CustomizedIcons";
import { uiOpenModalDelete, uiOpenModalEdit } from "../../../../redux/actions/uiActions";
import { ITrdSeries } from "../../../../redux/types/types";
import { setActiveSerie } from "../../../../redux/actions/trdActions";

interface props{
  serie:ITrdSeries,
  readonly?:boolean
}
export const SeriesBody:FC<props> = ({serie,readonly=false}) => {
  const dispatch = useDispatch();

  const onEditOffice = () => {
    dispatch( setActiveSerie(serie) )
    dispatch(uiOpenModalEdit());
  };

  const onDeleteOffice = () => {

    dispatch( setActiveSerie(serie) )
    dispatch( uiOpenModalDelete() )
  };

  return (
    <TableRow key={serie.serieCode}>
      <TableCell component="th" scope="row" align="center">
        {serie.codeOp}
      </TableCell>
      <TableCell align="center">{serie.nameOp}</TableCell>
      <TableCell align="center">{serie.serieCode}</TableCell>
      <TableCell align="center">{serie.seriesName}</TableCell>
      <TableCell align="center">
        {!readonly &&
        
        <CustomizedIcons
          editIcon
          deleteIcon
          onEdit={onEditOffice}
          onDelete={onDeleteOffice}
        />
        }
      </TableCell>
    </TableRow>
  );
};

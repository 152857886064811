import clsx from "clsx";
import memoize from "fast-memoize";
import { Form, Formik } from "formik";
import { useState, useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import Swal from "sweetalert2";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  IconButton,
  Divider,
} from "@material-ui/core";
import { Delete, FileCopy } from "@material-ui/icons";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PersonAddRoundedIcon from "@material-ui/icons/PersonAddRounded";
import { Autocomplete } from "@material-ui/lab";

import { MyTextField } from "../../custom/MyTextField";
import {
  CommunicationChannels,
  CorrespondenceInputType,
  CorrespondenceStatus,
  Enum_FileSize,
  InternalCorrespondenceStatus,
} from "../../../enums/enums";
import { useStyles } from "../../../shared/styles/useStyles";
import {
  setErrorMsg,
  uiCloseErrorAlert,
  uiCloseModalAdd,
  uiCloseModalEdit,
  uiCloseModalAnswer,
  uiOpenModalAnswer,
} from "../../../redux/actions/uiActions";
import { IExternalSender, IFiles } from "../../../redux/types/types";
import {
  ICorrespondence,
  IUserCorrespondence,
  ICommunicationType,
  IUserCopy,
} from "../../../interfaces/Correspondence";
import { AppState } from "../../../redux/reducers/rootReducer";
import { User } from "../../../interfaces/Users";
import { firebase } from "../../../config/firebase/firebase-config";
import {
  searchExternalSendersByName,
  setActiveCorrespondence,
  startDeleteCorrespondenceFile,
  startSetCorrespondence,
} from "../../../redux/actions/correspondenceActions";

import { SenderForm } from "./SenderForm";

import CustomizedDialog from "../../custom/CustomizedDialog";
import { MyAlert } from "../../custom/MyAlert";
import { MilestoneCorrespondence } from "../../milestones/MilestoneCorrespondence";
import { setActiveExternalSender } from "../../../redux/actions/usersActions";
import { Colors } from "../../../shared/constants/themes/Colors";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Props {
  details?: boolean;
  readOnly?: boolean;
  edit?: boolean;
  isDocManagment?: boolean;
}

export const CorrespondenceForm = ({
  details,
  edit = false,
  isDocManagment = false,
  readOnly = false,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { paramsProcedure } = useSelector(
    (state: AppState) => state.correspondence
  );

  //Vienen desde bd en la parte de parametrizacion de tiempos de respueta
  const optionsCommunicationType: ICommunicationType[] = [
    {
      isParam: true,
      option: "RightOfPetition",
      responseTime: 0,
    },
    {
      isParam: true,
      option: "Petition",
      responseTime: 0,
    },

    {
      isParam: true,
      option: "Complaint",
      responseTime: 0,
    },
    {
      isParam: true,
      option: "Claim",
      responseTime: 0,
    },
    {
      isParam: true,
      option: "Suggestion",
      responseTime: 0,
    },
    {
      isParam: true,
      option: "RequestForInformation",
      responseTime: 0,
    },
    {
      isParam: true,
      option: "Guardianship",
      responseTime: 0,
    },
    {
      isParam: true,
      option: "CommunicationOfControlEntities",
      responseTime: 0,
    },
    {
      isParam: true,
      option: "SummonsToJudicialProceedings",
      responseTime: 0,
    },
    {
      isParam: false,
      option: "Answer",
      responseTime: 0,
    },
    {
      isParam: false,
      option: "InternalProcedure",
      responseTime: 0,
    },
    {
      isParam: false,
      option: "AdministrativeActs",
      responseTime: 0,
    },
    {
      isParam: false,
      option: "CommunicationTypeReports",
      responseTime: 0,
    },
    {
      isParam: false,
      option: "CollectionAccounts",
      responseTime: 0,
    },
    {
      isParam: false,
      option: "Notification",
      responseTime: 0,
    },
    {
      isParam: false,
      option: "CommunicationTypeAnnotation",
      responseTime: 0,
    },
    {
      isParam: false,
      option: "Invitation",
      responseTime: 0,
    },
    {
      isParam: false,
      option: "Consultation",
      responseTime: 0,
    },
    {
      isParam: false,
      option: "Circular",
      responseTime: 0,
    },
    {
      isParam: false,
      option: "Memorandum",
      responseTime: 0,
    },
    {
      isParam: false,
      option: "Letter",
      responseTime: 0,
    },
    {
      isParam: false,
      option: "InternalNote",
      responseTime: 0,
    },
  ];

  paramsProcedure.map((item: any) => {
    return optionsCommunicationType.push({
      isParam: true,
      option: item.procedureName,
      responseTime: item.responseTime,
    });
  });

  const getFiles = () => {
    const digitalSupports = active ? active.digitalSupport : [];
    const appendDocs = active && active?.appendDocs ? active.appendDocs : [];
    const appendDocsUpdated = appendDocs.map((doc) => {
      return {
        owner: doc.officer,
        fileName: doc.filename,
        url: doc.url,
      };
    });
    return digitalSupports.concat(appendDocsUpdated);
  };

  const [labelImage, setLabelImage] = useState("");
  const [noValid, setNoValid] = useState(false);
  const [noValidSize, setNoValidSize] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { activeCorrespondence: active } = useSelector(
    (state: AppState) => state.correspondence
  );

  const [fileNames, setFileNames] = useState<IFiles[]>(getFiles());

  const [addresseeSelected, setAddresseeSelected] =
    useState<IUserCorrespondence | null>(active ? active.addressee : null);
  const initialCopyUsers: IUserCorrespondence[] = [];
  if (active) {
    active.copyTo?.forEach((user) => {
      initialCopyUsers.push({
        id: user.id,
        name: user.userName,
      });
    });
  }
  const [copyToSelected, setCopyToSelected] =
    useState<IUserCorrespondence[]>(initialCopyUsers);
  const [senderSelected, setSenderSelected] =
    useState<IUserCorrespondence | null>(active ? active.externalSender : null);
  const [communicationTypeSelected, setCommunicationTypeSelected] =
    useState<ICommunicationType | null>(
      active ? active.communicationType : null
    );
  const [responseTimes, setResponseTimes] = useState<string | number | null>(
    active && active.responseTimeDays ? active.responseTimeDays.toString() : ""
  );
  const [errorResponseTimes, setErrorResponseTimes] = useState(false);
  const [errorCommunicationType, setErrorCommunicationType] = useState(false);
  const [errorSender, setErrorSender] = useState(false);
  const [errorAddressee, setErrorAddressee] = useState(false);
  const { productionOffices } = useSelector((state: AppState) => state.trd);
  const { modalAnswerOpen, errorMsg, errorAlert } = useSelector(
    (state: AppState) => state.ui
  );
  const {
    users,
    externalSenders,
    searchExternalSenders,
    searchExternalAddressee,
  } = useSelector((state: AppState) => state.users);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [producerDependency, setProducerDependency] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [receivingDependency, setReceivingDependency] = useState("");
  const [senderOptions, setSenderOptions] = useState<IUserCorrespondence[]>([]);

  const { user } = useSelector((state: AppState) => state.auth);
  const validateInputType = (inputType: CorrespondenceInputType) => {
    if (
      inputType === CorrespondenceInputType.IP ||
      inputType === CorrespondenceInputType.IR
    ) {
      return CorrespondenceInputType.IPR;
    } else {
      return inputType;
    }
  };

  const [communicationInputType, setCommunicationInputType] =
    useState<CorrespondenceInputType>(
      active ? validateInputType(active.inputType) : CorrespondenceInputType.ER
    );

  const initialValues: ICorrespondence = {
    addressee: { id: "", name: "" },
    annexesPages: active ? active.annexesPages : 0,
    communicationChannel: active ? active.communicationChannel : "",
    communicationPages: active ? active.communicationPages : 0,
    communicationType: { isParam: false, option: "" },
    copyPages: active ? active.copyPages : 0,
    createAt: active ? active.createAt : null,
    description: active ? active.description : "",
    digitalSupport: active ? active.digitalSupport : [],
    externalSender: { id: "", name: "" },
    guide: active ? active.guide : "",
    id: active ? active.id : "",
    importanceType: {
      urgentCommunication: active
        ? (active.importanceType.urgentCommunication as boolean)
        : false,
      confidential: active
        ? (active.importanceType.confidential as boolean)
        : false,
    },
    inputType: active
      ? validateInputType(active.inputType)
      : CorrespondenceInputType.ER,
    postalAgency: active ? active.postalAgency : "",
    producerDependency: active ? active.producerDependency : "",
    receivingDependency: active ? active.receivingDependency : "",
    status: active ? active.status : CorrespondenceStatus.IN_PROCESS,
    subject: active ? active.subject : "",
    answeredDate: active ? active.answeredDate : null,
    pendingDate: active
      ? active.pendingDate
      : firebase.firestore.Timestamp.now(),
    internalState: active
      ? active.internalState
      : InternalCorrespondenceStatus.UNANSWERED,
    isReaded: active ? active.isReaded : false,
    isReadedVobo: active ? active.isReadedVobo : false,
    answer: active ? active.answer : null,
    isOk: active ? active.isOk : false,
    emailChannel: active ? active.emailChannel : "",
    idEntity: user && user.idEntity ? user.idEntity : "",
    isGiveBack: false,
    responseTimeDays:
      active && active.responseTimeDays
        ? active.responseTimeDays.toString()
        : "",
  };

  const validationSchema = yup.object({
    inputType: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    communicationType: yup.object(),
    communicationChannel: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),

    receivingDependency:
      communicationInputType === CorrespondenceInputType.ER ||
      communicationInputType === CorrespondenceInputType.IPR
        ? yup.string().required(`${intl.formatMessage({ id: "RequiredFile" })}`)
        : yup.string().optional(),

    producerDependency:
      communicationInputType === CorrespondenceInputType.EP ||
      communicationInputType === CorrespondenceInputType.IPR
        ? yup.string().required(`${intl.formatMessage({ id: "RequiredFile" })}`)
        : yup.string().optional(),

    externalSender: yup.object(),
    addressee: yup.object(),
    subject: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    description: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    communicationPages: yup.number(),
    annexesPages: yup.number(),
    createdAt: yup.number(),
    importanceType: yup.object({
      urgentCommunication: yup.boolean(),
      confidential: yup.boolean(),
    }),
    digitalSupport: yup.mixed(),
    postalAgency: yup.string(),
    guide: yup.string(),
    responseTimeDays: yup.number(),
  });

  const onClose = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    setLabelImage("");
    setFileNames([]);
    dispatch(setActiveCorrespondence(null));
    dispatch(setActiveExternalSender(null));
  };

  const onCloseSecondModal = () => {
    dispatch(uiCloseModalAnswer());
    dispatch(setActiveExternalSender(null));
  };

  const handleSelectFile = (e: any) => {
    const SUPPORTED_FORMATS = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/png",
      "application/msword",
      "text/csv",
      "application/pdf",
      "application/zip",
      "application/x-zip-compressed",
      "application/vnd.rar",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.oasis.opendocument.text",
      "zip",
      "rar",
      "odt",
      "pptx",
      "ppt",
    ];

    const file = e.target.files[0] as File;
    const now = new Date().getTime().toString();

    if (file) {
      if (file.size > Enum_FileSize.maxAllowedSize) {
        setNoValidSize(true);
      } else {
        let fileType = file.type;

        if (fileType.length === 0) {
          const dataFileName = file.name.split(".");
          fileType =
            dataFileName.length > 0
              ? dataFileName[dataFileName.length - 1]
              : "";
        }
        if (SUPPORTED_FORMATS.includes(fileType)) {
          setNoValid(false);

          setFileNames((prevState) => [
            { url: "#", fileName: now + "_" + file.name, file },
            ...prevState,
          ]);
          setLabelImage(e.target.files[0].name);
        } else {
          setNoValid(true);
        }
      }
    } else {
      setNoValid(false);
      setNoValidSize(false);
      setLabelImage("");
    }
  };

  const isExternalSender = (value: any) => {
    const isExternal = externalSenders.find(
      (externalSender) => value.idDoc === externalSender.idDoc
    );
    return isExternal;
  };

  const handleSelectAddressees = (value: any) => {
    if (value !== null) {
      const isExternal = isExternalSender(value);

      const addresse = {
        id: isExternal !== undefined ? value.idDoc : value.id,
        name: value.name,
      };
      setErrorAddressee(false);
      setAddresseeSelected(addresse);

      if (isExternal) {
        dispatch(setActiveExternalSender(value as IExternalSender));
      }
    } else {
      setErrorAddressee(true);
      setAddresseeSelected(null);
      dispatch(setActiveExternalSender(null));
    }
  };

  const handleSelectAddresseesCopy = (value: any) => {
    value ? setCopyToSelected(value) : setCopyToSelected([]);
  };

  const handleSelectSender = (value: any) => {
    !value ? setErrorSender(true) : setErrorSender(false);

    dispatch(
      setActiveExternalSender(value ? (value as IExternalSender) : null)
    );

    value
      ? setSenderSelected({
          id: value.idDoc ? value.idDoc : value.id.toString(),
          name: value.name,
        })
      : setSenderSelected(null);
  };
  useEffect(() => {
    if (isDocManagment && user) {
      setAddresseeSelected({
        id: user.id.toString(),
        name: (user.firstName +
          " " +
          user.secondName +
          " " +
          user.firstLastName +
          " " +
          user.secondLastName) as string,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateSelects = (inputType: string) => {
    if (
      inputType !== CorrespondenceInputType.ER &&
      inputType !== CorrespondenceInputType.EP &&
      inputType !== CorrespondenceInputType.IPR
    ) {
      setErrorCommunicationType(false);
      setCommunicationTypeSelected({ isParam: false, option: "" });
    } else {
      communicationTypeSelected && communicationTypeSelected?.option !== ""
        ? setErrorCommunicationType(false)
        : setErrorCommunicationType(true);

      !communicationTypeSelected && setErrorCommunicationType(true);
    }

    if (
      communicationTypeSelected?.option === "CommunicationOfControlEntities" ||
      communicationTypeSelected?.option === "SummonsToJudicialProceedings"
    ) {
      responseTimes === ""
        ? setErrorResponseTimes(true)
        : setErrorResponseTimes(false);
    } else {
      setErrorResponseTimes(false);
    }

    senderSelected ? setErrorSender(false) : setErrorSender(true);

    addresseeSelected ? setErrorAddressee(false) : setErrorAddressee(true);
  };

  const handleDeleteFile = async (fileName: string, db: boolean) => {
    if (
      (edit && communicationInputType !== CorrespondenceInputType.EP) ||
      !edit
    ) {
      if (db) {
        setIsLoading(true);
        await dispatch(startDeleteCorrespondenceFile(fileName));
        setFileNames((prevState) =>
          prevState.filter((fil) => fil.fileName !== fileName)
        );
        setIsLoading(false);
      } else {
        setFileNames((prevState) =>
          prevState.filter((fil) => fil.fileName !== fileName)
        );

        if (fileName.indexOf(labelImage) !== -1) {
          setLabelImage("");
        }
      }
    } else if (communicationInputType === CorrespondenceInputType.EP) {
      Swal.fire({
        title: `${intl.formatMessage({ id: "CantDeleteFileTitle" })} `,
        text: `${intl.formatMessage({ id: "CantDeleteFileDelete" })} `,
        icon: "warning",
        showConfirmButton: true,
        confirmButtonColor: Colors.PRIMARY,
        confirmButtonText: "Ok",
        customClass: {
          container: "swal-container-z-index",
        },
      });
    }
  };

  const handleAddCitizen = () => {
    dispatch(uiOpenModalAnswer());
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUsers = useCallback(
    memoize((dependency: string) => {
      const filteredUsers: User[] = (users as User[]).filter((el) =>
        el.offices.toString().includes(dependency)
      );
      const arrayUsers: IUserCorrespondence[] = [];

      filteredUsers.forEach((user: User) => {
        arrayUsers.push({
          id: user.id.toString(),
          name:
            user.firstName +
            " " +
            user.secondName +
            " " +
            user.firstLastName +
            " " +
            user.secondLastName,
          uid: user.uid,
        });
      });
      return arrayUsers;
    }),
    []
  );

  const [emailSendForm, setSelectedHowToSendEmail] = useState({
    howToSendEmail: "1",
    active: true,
  });

  const handleHowToSend = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSelectedHowToSendEmail({
      ...emailSendForm,
      howToSendEmail: event.target.value,
      active: true,
    });

  const closeAlertAlert = () => {
    dispatch(uiCloseErrorAlert());
    dispatch(setErrorMsg(null));
  };

  const handleSelectCommunicationType = (value: ICommunicationType | null) => {
    value && value.responseTime && setResponseTimes(value.responseTime);
    value ? setErrorCommunicationType(false) : setErrorCommunicationType(true);

    setCommunicationTypeSelected(value);
  };

  const handleSelectResponseTimes = (value: number) => {
    setResponseTimes(Number(value));
  };
  const handleChangeInputType = (
    inputType: CorrespondenceInputType,
    setValue: Function
  ) => {
    setSenderSelected(null);
    setCommunicationTypeSelected(null);
    setAddresseeSelected(null);
    setCopyToSelected([]);

    if (user && isDocManagment) {
      if (
        inputType === CorrespondenceInputType.EP ||
        inputType === CorrespondenceInputType.IPR
      ) {
        setSenderSelected({
          id: user.id.toString(),
          name: user.username as string,
        });
        setAddresseeSelected(null);
      }
      if (inputType === CorrespondenceInputType.ER) {
        setAddresseeSelected({
          id: user.id.toString(),
          name: user.username as string,
        });
        setSenderSelected(null);
        setValue("producerDependency", "");
        dispatch(searchExternalSendersByName("", "sender"));
      } else if (inputType === CorrespondenceInputType.EP) {
        dispatch(searchExternalSendersByName("", "addressee"));
      }
    }
    setValue("inputType", inputType);
    setCommunicationInputType(inputType);

    if (
      inputType === CorrespondenceInputType.IPR ||
      inputType === CorrespondenceInputType.EP
    ) {
      setValue("receivingDependency", "");
    }
  };

  // ARCHIVOS SOPORTADOS PARA INPUT TYPE=FILE
  const fileSupported = {
    accept: `
              application/pdf,
              image/jpeg,
              image/png,
              application/msword,
              application/vnd.openxmlformats-officedocument.wordprocessingml.document,
              application/vnd.ms-excel,
              application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
              text/csv,
              application/zip,
              application/x-zip-compressed,
              application/vnd.rar,
              application/vnd.ms-powerpoint,
              application/vnd.openxmlformats-officedocument.presentationml.presentation,
              application/vnd.oasis.opendocument.text,
           `,
  };

  async function sendCorrespondence(values: ICorrespondence) {
    if (edit && active) {
      values.inputType = active.inputType;
    }
    values.communicationType = communicationTypeSelected;
    values.digitalSupport = fileNames;
    values.externalSender = senderSelected as IUserCorrespondence;
    values.addressee = addresseeSelected as IUserCorrespondence;
    values.responseTimeDays =
      communicationTypeSelected?.option === "CommunicationOfControlEntities" ||
      communicationTypeSelected?.option === "SummonsToJudicialProceedings" ||
      responseTimes !== 0
        ? responseTimes
        : null;

    values.createAt = edit ? values.createAt : active ? active.createAt : null;

    const usersToCopy: IUserCopy[] = [];
    const copyToIds: string[] = [];
    if (copyToSelected.length > 0) {
      copyToSelected.forEach((user) => {
        usersToCopy.push({
          id: user.id,
          userName: user.name,
          submitDate: firebase.firestore.Timestamp.now(),
        });
        copyToIds.push(user.id);
      });
    }
    values.copyTo = usersToCopy;
    values.copyToIds = copyToIds;

    if (values.inputType === CorrespondenceInputType.EP) {
      values.receivingDependency = values.producerDependency || "";
    }

    return dispatch(
      startSetCorrespondence(
        values,
        edit,
        false,
        undefined,
        undefined,
        emailSendForm.howToSendEmail
      )
    );
  }

  useEffect(() => {
    if (communicationInputType === CorrespondenceInputType.EP) {
      let name = "";
      if (active) {
        const data = active.addressee.name.split(" ");
        name = data && data.length > 0 ? data[0] : "";
      }
      dispatch(searchExternalSendersByName(name, "addressee"));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (communicationInputType === CorrespondenceInputType.ER) {
      let name = "";
      if (active) {
        const data = active.externalSender.name.split(" ");
        name = data && data.length > 0 ? data[0] : "";
      }
      dispatch(searchExternalSendersByName(name, "sender"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchExternal = (
    eventType: string,
    externalType: string,
    inputValue: string
  ) => {
    if ((eventType === "click" && inputValue === "") || inputValue === "") {
      dispatch(searchExternalSendersByName("", externalType));
    }

    if (
      inputValue &&
      inputValue !== "" &&
      eventType === "change" &&
      inputValue.length >= 3
    ) {
      dispatch(searchExternalSendersByName(inputValue, externalType));
    }
  };

  return (
    <Box m={1}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (
            !noValid &&
            !errorCommunicationType &&
            !errorAddressee &&
            !errorSender &&
            !errorResponseTimes
          ) {
            if (
              fileNames.length === 0 &&
              values.inputType === CorrespondenceInputType.EP
            ) {
              Swal.fire({
                title: `${intl.formatMessage({ id: "NoFilesAttachedTitle" })}`,
                html: `${intl.formatMessage({ id: "NoFilesAttachedContent" })}`,
                icon: "warning",
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonColor: "#36B37E",
                cancelButtonColor: "#FF5630",

                confirmButtonText: "CONTINUAR",
                cancelButtonText: "VOLVER",
                customClass: {
                  container: "swal-container-z-index",
                  confirmButton: "confirm-button",
                  cancelButton: "cancel-button",
                },
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                  emailSendForm.howToSendEmail = "0";
                  setSubmitting(true);
                  await sendCorrespondence(values);
                  setSubmitting(false);
                },
              });
            } else {
              setSubmitting(true);
              await sendCorrespondence(values);
              setSubmitting(false);
            }
          }
        }}
      >
        {({ values, isSubmitting, handleChange, setFieldValue }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="InputType" />*
                </label>

                <TextField
                  name="inputType"
                  variant="outlined"
                  select
                  onChange={(e) =>
                    handleChangeInputType(e.target.value as any, setFieldValue)
                  }
                  value={values.inputType}
                  className={classes.myTextFieldRoot}
                  size="small"
                  disabled={readOnly ? true : edit && active ? edit : details}
                >
                  <MenuItem value={CorrespondenceInputType.EP}>
                    <FormattedMessage id="ExternalProduced" />
                  </MenuItem>
                  <MenuItem value={CorrespondenceInputType.ER}>
                    <FormattedMessage id="ExternalReceived" />
                  </MenuItem>
                  <MenuItem value={CorrespondenceInputType.IPR}>
                    <FormattedMessage id="InternalReceived" /> +{" "}
                    <FormattedMessage id="InternalProduced" />
                  </MenuItem>

                  <MenuItem
                    value={CorrespondenceInputType.OR}
                    style={isDocManagment ? { display: "none" } : {}}
                  >
                    <FormattedMessage id="OtherShipmentsReceived" />
                  </MenuItem>
                  <MenuItem
                    value={CorrespondenceInputType.OP}
                    style={isDocManagment ? { display: "none" } : {}}
                  >
                    <FormattedMessage id="OtherShipmentsProduced" />
                  </MenuItem>
                </TextField>
              </Grid>
              {(values.inputType === CorrespondenceInputType.ER ||
                values.inputType === CorrespondenceInputType.EP ||
                values.inputType === CorrespondenceInputType.IPR) && (
                <Grid item xs={6}>
                  <label className="form-text">
                    <FormattedMessage id="CommunicationType" />*
                  </label>

                  <Autocomplete
                    id="communicationType"
                    options={optionsCommunicationType}
                    disabled={readOnly ? true : edit && active ? edit : details}
                    disableCloseOnSelect
                    value={communicationTypeSelected}
                    getOptionLabel={(option) =>
                      option.responseTime && option.responseTime > 0
                        ? option.option
                        : intl.formatMessage({ id: `${option.option}` })
                    }
                    getOptionSelected={(option, value) =>
                      option.option === value.option
                    }
                    onChange={(event: any, value: ICommunicationType | null) =>
                      handleSelectCommunicationType(value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        name="communicationType"
                        className={classes.myTextFieldRoot}
                        helperText={
                          errorCommunicationType &&
                          `${intl.formatMessage({ id: "RequiredFile" })}`
                        }
                        error={errorCommunicationType}
                        FormHelperTextProps={{
                          className: classes.helperText,
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="Channel" />*
                </label>
                <MyTextField
                  name="communicationChannel"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                  disabled={readOnly ? true : edit && active ? edit : details}
                >
                  <MenuItem value={CommunicationChannels.SINGLE_WINDOW}>
                    <FormattedMessage id="SingleWindow" />
                  </MenuItem>
                  <MenuItem value={CommunicationChannels.EMAIL}>
                    <FormattedMessage id="EmailAddress" />
                  </MenuItem>
                  <MenuItem value={CommunicationChannels.SAC}>SAC</MenuItem>
                  <MenuItem value={CommunicationChannels.VERBAL_COMMUNICATION}>
                    <FormattedMessage id="VerbalCommunication" />
                  </MenuItem>
                  <MenuItem value={CommunicationChannels.FACEBOOK}>
                    <FormattedMessage id="Facebook" />
                  </MenuItem>
                  <MenuItem value={CommunicationChannels.WHATSAPP}>
                    <FormattedMessage id="Whatsapp" />
                  </MenuItem>
                  <MenuItem value={CommunicationChannels.CALL}>
                    <FormattedMessage id="ByTelephone" />
                  </MenuItem>
                  <MenuItem value={CommunicationChannels.INSTITUTIONAL_PAGE}>
                    <FormattedMessage id="InstitutionalPage" />
                  </MenuItem>
                  <MenuItem value={CommunicationChannels.FAX}>
                    <FormattedMessage id="Fax" />
                  </MenuItem>
                </MyTextField>
              </Grid>
              {values.communicationChannel === CommunicationChannels.EMAIL &&
                values.inputType !== CorrespondenceInputType.EP && (
                  <Grid item xs={6}>
                    <label className="form-text">
                      <FormattedMessage id="Email" />*
                    </label>
                    <MyTextField
                      name="emailChannel"
                      variant="outlined"
                      className={classes.myTextFieldRoot}
                      disabled={readOnly ? true : details}
                    />
                  </Grid>
                )}
              {values.communicationChannel === CommunicationChannels.EMAIL &&
                values.inputType === CorrespondenceInputType.EP && (
                  <Grid item xs={12}>
                    <label className="form-text">
                      <FormattedMessage id="SendToEmail" />
                    </label>
                    <TextField
                      name="howToSendEmail"
                      variant="outlined"
                      select
                      value={emailSendForm.howToSendEmail}
                      className={classes.myTextFieldRoot}
                      size="small"
                      onChange={handleHowToSend}
                      disabled={!emailSendForm.active}
                    >
                      <MenuItem value="1">
                        <FormattedMessage id="SendEmailInmediatly" />
                      </MenuItem>
                      <MenuItem value="2">
                        <FormattedMessage id="SendEmailLater" />
                      </MenuItem>
                    </TextField>
                  </Grid>
                )}
              {(values.inputType === CorrespondenceInputType.ER ||
                values.inputType === CorrespondenceInputType.IPR ||
                values.inputType === CorrespondenceInputType.OR) && (
                <Grid
                  item
                  xs={values.inputType === CorrespondenceInputType.IPR ? 12 : 6}
                >
                  <label className="form-text">
                    <FormattedMessage id="ReceivingDependency" />*{" "}
                  </label>
                  <TextField
                    name="receivingDependency"
                    variant="outlined"
                    select
                    size="small"
                    onChange={(e) => {
                      values.receivingDependency = e.target.value;
                      setAddresseeSelected(null);
                      setReceivingDependency(
                        isDocManagment &&
                          values.inputType === CorrespondenceInputType.ER &&
                          user &&
                          user.offices &&
                          user.offices.length > 0
                          ? (values.receivingDependency =
                              user.offices.toString().split("_").length > 1
                                ? user.offices.toString().split("_")[0] +
                                  "_" +
                                  user.offices.toString().split("_")[1]
                                : user.offices.toString().split("_")[0])
                          : values.receivingDependency
                      );

                      setSenderOptions(
                        getUsers(values.receivingDependency) || "-1"
                      );
                    }}
                    value={
                      isDocManagment &&
                      values.inputType === CorrespondenceInputType.ER &&
                      user &&
                      user.offices &&
                      user.offices.length > 0
                        ? (values.receivingDependency =
                            user.offices.toString().split("_").length > 1
                              ? user.offices.toString().split("_")[0] +
                                "_" +
                                user.offices.toString().split("_")[1]
                              : user.offices.toString().split("_")[0])
                        : values.receivingDependency
                    }
                    className={classes.myTextFieldRoot}
                    disabled={
                      readOnly
                        ? true
                        : isDocManagment &&
                          values.inputType === CorrespondenceInputType.ER
                        ? true
                        : edit && active
                        ? edit
                        : details
                    }
                  >
                    {productionOffices.map((productionOffice) => (
                      <MenuItem
                        key={productionOffice.idProductionOffice}
                        value={productionOffice.idProductionOffice}
                      >
                        {productionOffice.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              <Grid item xs={details ? 12 : 11}>
                <label className="form-text">
                  {values.inputType === CorrespondenceInputType.EP ? (
                    <>
                      <FormattedMessage id="ExternalAddressee" />*
                    </>
                  ) : (
                    <>
                      <FormattedMessage
                        id={
                          values.inputType === CorrespondenceInputType.IPR
                            ? "OfficerReceiver"
                            : "InternalAddressee"
                        }
                      />
                      *
                    </>
                  )}
                </label>
                <Autocomplete
                  // multiple
                  id="addressee"
                  value={addresseeSelected}
                  options={
                    values.inputType === CorrespondenceInputType.EP
                      ? searchExternalAddressee
                      : values.receivingDependency &&
                        (values.inputType === CorrespondenceInputType.IPR ||
                          values.inputType === CorrespondenceInputType.ER)
                      ? getUsers(values.receivingDependency || "-1")
                      : []
                  }
                  size="small"
                  disabled={
                    readOnly
                      ? true
                      : isDocManagment &&
                        values.inputType === CorrespondenceInputType.ER
                      ? true
                      : edit && active
                      ? edit
                      : details
                  }
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => {
                    if (isExternalSender(option)) {
                      return option.idDoc === value.id;
                    }
                    return option.id === value.id;
                  }}
                  onInputChange={(event: any, newInputValue: string) => {
                    if (values.inputType === CorrespondenceInputType.EP) {
                      if (event === null) {
                        return;
                      }
                      searchExternal(
                        event.type.toString(),
                        "addressee",
                        newInputValue
                      );
                    }
                  }}
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                      />
                      {option.name}
                    </>
                  )}
                  onChange={(event: any, value: IUserCorrespondence | null) =>
                    handleSelectAddressees(value)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      name="addressee"
                      className={classes.myTextFieldRoot}
                      helperText={
                        errorAddressee &&
                        `${intl.formatMessage({ id: "RequiredFile" })}`
                      }
                      error={errorAddressee}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                    />
                  )}
                />
              </Grid>
              {values.inputType === CorrespondenceInputType.EP && (
                <Grid item xs={1}>
                  <Box ml={-2} mt={3}>
                    <Tooltip
                      title={`${intl.formatMessage({ id: "AddAddressee" })}`}
                    >
                      <span>
                        <IconButton
                          disabled={readOnly}
                          onClick={handleAddCitizen}
                          size="medium"
                        >
                          <PersonAddRoundedIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </Grid>
              )}
              {(values.inputType === CorrespondenceInputType.IPR ||
                values.inputType === CorrespondenceInputType.EP ||
                values.inputType === CorrespondenceInputType.OP) && (
                <Grid item xs={12}>
                  <label className="form-text">
                    <FormattedMessage id="SendingDependency" />*{" "}
                  </label>

                  <TextField
                    name="producerDependency"
                    variant="outlined"
                    select
                    size="small"
                    onChange={(e) => {
                      values.producerDependency = e.target.value;
                      setSenderSelected(null);
                      setProducerDependency(
                        isDocManagment &&
                          user &&
                          user.offices &&
                          user.offices.length > 0
                          ? (values.producerDependency =
                              user.offices.toString().split("_").length > 1
                                ? user.offices.toString().split("_")[0] +
                                  "_" +
                                  user.offices.toString().split("_")[1]
                                : user.offices.toString().split("_")[0])
                          : values.producerDependency
                      );

                      setSenderOptions(
                        getUsers(values.producerDependency) || "-2"
                      );
                    }}
                    value={
                      isDocManagment &&
                      user &&
                      user.offices &&
                      user.offices.length > 0
                        ? (values.producerDependency =
                            user.offices.toString().split("_").length > 1
                              ? user.offices.toString().split("_")[0] +
                                "_" +
                                user.offices.toString().split("_")[1]
                              : user.offices.toString().split("_")[0])
                        : values.producerDependency
                    }
                    className={classes.myTextFieldRoot}
                    disabled={
                      readOnly
                        ? true
                        : isDocManagment
                        ? true
                        : edit && active
                        ? edit
                        : details
                    }
                  >
                    {productionOffices.map((productionOffice) => (
                      <MenuItem
                        key={productionOffice.idProductionOffice}
                        value={productionOffice.idProductionOffice}
                      >
                        {productionOffice.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              )}
              <Grid item xs={details ? 12 : 11}>
                <label className="form-text">
                  {values.inputType === CorrespondenceInputType.EP ? (
                    <>
                      <FormattedMessage id="SenderUser" />*
                    </>
                  ) : (
                    <>
                      <FormattedMessage
                        id={
                          values.inputType === CorrespondenceInputType.IPR
                            ? "OfficerSender"
                            : "ExternalSender"
                        }
                      />
                      *
                    </>
                  )}
                </label>
                <Autocomplete
                  // multiple
                  id="externalSender"
                  options={
                    values.inputType === CorrespondenceInputType.EP ||
                    values.inputType === CorrespondenceInputType.IPR
                      ? senderOptions
                      : searchExternalSenders
                  }
                  value={senderSelected}
                  disabled={
                    readOnly
                      ? true
                      : isDocManagment &&
                        (values.inputType === CorrespondenceInputType.EP ||
                          values.inputType === CorrespondenceInputType.IPR)
                      ? true
                      : edit && active
                      ? edit
                      : details
                  }
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => {
                    if (isExternalSender(option)) {
                      return option.idDoc === value.id;
                    }
                    return option.id === value.id;
                  }}
                  renderOption={(option, { selected }) => (
                    <>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color="primary"
                      />
                      {option.name}
                    </>
                  )}
                  onChange={(event: any, value: IExternalSender | null) => {
                    handleSelectSender(value);
                  }}
                  onInputChange={(event: any, newInputValue: string) => {
                    if (values.inputType === CorrespondenceInputType.ER) {
                      if (event === null) {
                        return;
                      }
                      searchExternal(
                        event.type.toString(),
                        "sender",
                        newInputValue
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      name="externalSender"
                      className={classes.myTextFieldRoot}
                      helperText={
                        errorSender &&
                        `${intl.formatMessage({ id: "RequiredFile" })}`
                      }
                      error={errorSender}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                    />
                  )}
                />
              </Grid>
              {!edit &&
                !details &&
                values.inputType !== CorrespondenceInputType.IPR &&
                values.inputType !== CorrespondenceInputType.EP && (
                  <Grid item xs={1}>
                    <Box ml={-2} mt={3}>
                      <Tooltip
                        title={`${intl.formatMessage({ id: "AddSender" })}`}
                      >
                        <IconButton
                          disabled={readOnly}
                          onClick={handleAddCitizen}
                          size="medium"
                        >
                          <PersonAddRoundedIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Grid>
                )}
              {values.inputType === CorrespondenceInputType.ER && (
                <Grid item xs={12}>
                  <label className="form-text">
                    <FormattedMessage id="SelectOfficerSendCopy" />
                  </label>
                  <Autocomplete
                    multiple
                    id="copyTo"
                    value={copyToSelected}
                    options={getUsers("")}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id
                    }
                    renderOption={(option, { selected }) => (
                      <>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                          color="primary"
                        />
                        {option.name}
                      </>
                    )}
                    onChange={(event: any, value: any) =>
                      handleSelectAddresseesCopy(value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        name="addressee"
                        className={classes.myTextFieldRoot}
                        helperText={
                          errorAddressee &&
                          `${intl.formatMessage({ id: "RequiredFile" })}`
                        }
                        error={errorAddressee}
                        FormHelperTextProps={{
                          className: classes.helperText,
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Subject" />*
                </label>
                <MyTextField
                  name="subject"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  disabled={readOnly ? true : details}
                />
              </Grid>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="ContentDescription" />*
                </label>
                <MyTextField
                  name="description"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  multiline
                  minRows={3}
                  disabled={readOnly ? true : details}
                />
              </Grid>
              {(communicationTypeSelected?.option ===
                "CommunicationOfControlEntities" ||
                communicationTypeSelected?.option ===
                  "SummonsToJudicialProceedings") && (
                <Grid item xs={12}>
                  <label className="form-text">
                    <FormattedMessage id="ResponseTimeInDays" />*
                  </label>
                  <TextField
                    name="responseTimeDays"
                    variant="outlined"
                    type="number"
                    size="small"
                    value={responseTimes ? responseTimes : ""}
                    onChange={(e: any) =>
                      handleSelectResponseTimes(e.target.value)
                    }
                    className={classes.myTextFieldRoot}
                    disabled={readOnly ? true : details}
                    helperText={
                      errorResponseTimes &&
                      `${intl.formatMessage({ id: "RequiredFile" })}`
                    }
                    error={errorResponseTimes}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="NumberOfPages" />:
                </label>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginTop: "19px" }}
                >
                  <label className="form-text">
                    <FormattedMessage id="Communication" />
                  </label>
                  <Box width={"45px"}>
                    <MyTextField
                      name="communicationPages"
                      variant="outlined"
                      className={classes.myTextFieldRoot}
                      disabled={readOnly ? true : details}
                    />
                  </Box>

                  <label className="form-text">
                    <FormattedMessage id="Annexes" />
                  </label>
                  <Box width={"45px"}>
                    <MyTextField
                      name="annexesPages"
                      variant="outlined"
                      className={classes.myTextFieldRoot}
                      disabled={readOnly ? true : details}
                    />
                  </Box>

                  <label className="form-text">
                    <FormattedMessage id="Copies" />
                  </label>
                  <Box width={"45px"}>
                    <MyTextField
                      name="copyPages"
                      variant="outlined"
                      className={classes.myTextFieldRoot}
                      disabled={readOnly ? true : details}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="PostalAgency" />:
                </label>
                <MyTextField
                  name="postalAgency"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  disabled={readOnly ? true : details}
                />
              </Grid>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="Guide" />:
                </label>

                <MyTextField
                  name="guide"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  disabled={readOnly ? true : details}
                />
              </Grid>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="MarkAs" />:
                </label>
              </Grid>
              <Grid item xs={12}>
                <Box display={"flex"}>
                  <Box>
                    <Checkbox
                      checked={values.importanceType.urgentCommunication}
                      name="importanceType.urgentCommunication"
                      onChange={handleChange}
                      color="default"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <label
                      className="form-text"
                      style={{ marginTop: "10px", marginLeft: "-5px" }}
                    >
                      <FormattedMessage id="UrgentCommunication" />
                    </label>
                  </Box>
                  <Box>
                    <Checkbox
                      checked={values.importanceType.confidential}
                      name="importanceType.confidential"
                      onChange={handleChange}
                      color="default"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <label
                      className="form-text"
                      style={{ marginTop: "10px", marginLeft: "-5px" }}
                    >
                      <FormattedMessage id="Confidential" />
                    </label>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <label className="form-text">
                    <FormattedMessage id="DigitalSupport" />:
                  </label>
                  {!details && (
                    <Box ml={2}>
                      <TextField
                        size="small"
                        type="file"
                        inputProps={fileSupported}
                        onChange={handleSelectFile}
                        id="icon-button-file"
                        autoComplete="off"
                        style={{ display: "none" }}
                      />

                      <MyTextField
                        disabled={true}
                        variant="outlined"
                        style={{ color: "black" }}
                        label={labelImage}
                        name="file"
                        value={labelImage}
                        className={classes.myTextFieldRoot}
                      />
                    </Box>
                  )}

                  {!details && (
                    <Box mt={1}>
                      <Tooltip
                        title={`${intl.formatMessage({ id: "Attach" })}`}
                      >
                        <label htmlFor="icon-button-file">
                          <Button
                            disabled={readOnly}
                            className={clsx(classes.btnAction, classes.save)}
                            component="span"
                          >
                            <FileCopy /> <FormattedMessage id="Attach" />
                          </Button>
                        </label>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </Grid>
              {noValid && (
                <Grid item xs={12}>
                  <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                    <FormattedMessage id="ValidFiles" />
                  </Box>
                </Grid>
              )}
              {noValidSize && (
                <Grid item xs={12}>
                  <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                    <FormattedMessage id="ValidDocumentalFilesSize" />
                  </Box>
                </Grid>
              )}
            </Grid>

            <Box display="flex" mt={1}>
              <Grid item xs={5}>
                {fileNames.map(({ fileName, url, owner }) => {
                  return (
                    <Box display="flex" alignItems="center" key={fileName}>
                      <DescriptionOutlinedIcon color="action" />
                      <Box ml={2} width={"90%"}>
                        <a
                          href={url}
                          target="__blank"
                          style={{
                            fontSize: 12,
                            color: "blue",
                            fontFamily: "Poppins",
                          }}
                        >
                          {fileName}
                        </a>
                      </Box>
                      {((owner && owner.id === user?.id.toString()) ||
                        url === "#") && (
                        <Box width={"10%"}>
                          <Tooltip
                            title={`${intl.formatMessage({ id: "Delete" })}`}
                          >
                            <IconButton
                              disabled={readOnly}
                              onClick={() =>
                                handleDeleteFile(fileName, url !== "#")
                              }
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Grid>
            </Box>

            {!details && !readOnly && (
              <Box mt={2} display="flex" flexDirection="row-reverse">
                {!isSubmitting ? (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => validateSelects(values.inputType)}
                  >
                    {isLoading ? (
                      <CircularProgress className={classes.btnLoading} />
                    ) : (
                      <FormattedMessage id="Settle" />
                    )}
                  </Button>
                ) : (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="button"
                    disabled={true}
                  >
                    <CircularProgress className={classes.btnLoading} />
                  </Button>
                )}
                <Button
                  className={clsx(classes.btn, classes.cancel)}
                  onClick={onClose}
                >
                  <FormattedMessage id="Cancel" />
                </Button>
              </Box>
            )}
          </Form>
        )}
      </Formik>
      {details && active && user ? (
        <Box pt={5}>
          <h1>
            {" "}
            <FormattedMessage id="Record" />
          </h1>
          <Divider />
          <br />
          <MilestoneCorrespondence
            idSettled={active.id}
            idEntity={user.idEntity}
          />
        </Box>
      ) : (
        <></>
      )}
      <CustomizedDialog
        open={modalAnswerOpen}
        cancelBtn={true}
        onDeny={onCloseSecondModal}
        title={`${intl.formatMessage({ id: "AddExternalSender" })}`}
        content={
          <SenderForm
            updateName={(id: string, idDoc: string, name: string) => {
              if (
                senderSelected &&
                (senderSelected.id === id || senderSelected?.id === idDoc)
              ) {
                setSenderSelected({
                  ...senderSelected,
                  name,
                });
              } else if (
                addresseeSelected &&
                (addresseeSelected.id === id || addresseeSelected?.id === idDoc)
              ) {
                setAddresseeSelected({
                  ...addresseeSelected,
                  name,
                });
              }
            }}
          />
        }
        textButton="Accept"
      />

      {errorMsg && (
        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={errorMsg}
          time={3000}
          handleClose={closeAlertAlert}
        />
      )}
    </Box>
  );
};

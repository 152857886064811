import { TableCell, TableRow } from "@material-ui/core";
import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { db } from "../../config/firebase/firebase-config";
import { Entity } from "../../interfaces/Users";
import {
  editEntity,
  setActiveEntity,
} from "../../redux/actions/entitiesActions";

import {
  uiOpenModalEdit,
  uiOpenSuccessAlert,
} from "../../redux/actions/uiActions";

import { CustomizedIcons } from "../custom/CustomizedIcons";
import { AntSwitch } from "../custom/CustomizedSwitch";
interface Props {
  entity: Entity;
}
export const EntitiesBody: FC<Props> = ({ entity }) => {
  const [state, setState] = useState({
    checkedA: entity.active,
  });
  const dispatch = useDispatch();
  // Función para cambiar estado
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    const check = event.target.checked;

    // Actualizacion en BD
    await db
      .collection("Users")
      .doc(entity.nit)
      .set({ active: check }, { merge: true });
    //Actualizar la lista de las entidades
    const updatedEntity = { ...entity };
    updatedEntity.active = check;
    dispatch(editEntity(updatedEntity, entity.id));
    dispatch(uiOpenSuccessAlert());
    /*  dispatch(updateEntity({ ...entity, activo: check }));
    dispatch(uiOpenAlert()); */
  };

  const onEdit = () => {
    dispatch(setActiveEntity(entity));
    dispatch(uiOpenModalEdit());
  };
  /*  const onDelete = () => {
    dispatch(uiOpenModalDelete());
  }; */
  return (
    <>
      <TableRow key={entity.nit}>
        <TableCell
          component="th"
          scope="row"
          style={{ width: 170, textTransform: "capitalize" }}
        >
          {entity.businessName}
        </TableCell>
        <TableCell style={{ width: 160 }}>{entity.entityType}</TableCell>
        <TableCell style={{ width: 160 }}>{entity.department}</TableCell>
        <TableCell style={{ width: 120 }}>{entity.town}</TableCell>
        <TableCell style={{ width: 160 }}>{entity.nit}</TableCell>
        <TableCell style={{ width: 30 }} align="center">
          <AntSwitch
            checked={state.checkedA}
            name="checkedA"
            onChange={handleChange}
          />
        </TableCell>
        <TableCell style={{ width: 130 }} align="center">
          <CustomizedIcons
            editIcon
            //deleteIcon
            onEdit={onEdit}
            //onDelete={onDelete}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

import { ICommunicationType } from "../../interfaces/Correspondence";

export const optionsCommunicationType: ICommunicationType[] = [
  { isParam: true, option: "RightOfPetition" },
  { isParam: true, option: "Petition" },
  { isParam: true, option: "Complaint" },
  { isParam: true, option: "Claim" },
  { isParam: true, option: "Suggestion" },
  {
    isParam: true,
    option: "RequestForInformation",
  },
  { isParam: true, option: "Guardianship" },

  {
    isParam: false,
    option: "CommunicationOfControlEntities",
  },
  {
    isParam: false,
    option: "SummonsToJudicialProceedings",
  },
  {
    isParam: false,
    option: "Answer",
  },
  {
    isParam: false,
    option: "InternalProcedure",
  },
  {
    isParam: false,
    option: "AdministrativeActs",
  },
  {
    isParam: false,
    option: "CommunicationTypeReports",
  },
  {
    isParam: false,
    option: "CollectionAccounts",
  },
  {
    isParam: false,
    option: "Notification",
  },
  {
    isParam: false,
    option: "CommunicationTypeAnnotation",
  },
  {
    isParam: false,
    option: "Invitation",
  },
  { isParam: false, option: "Consultation" },
  { isParam: false, option: "Circular" },
  { isParam: false, option: "Memorandum" },
  { isParam: false, option: "Letter" },
  { isParam: false, option: "InternalNote" },
  // { isParam: false, option: "Procedure" },
];

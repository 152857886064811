import { Redirect, Route, Switch } from "react-router";
import { EntitiesScreen } from "../pages/superAdmin/EntitiesScreen";

export const SuperAdminDashboarRouter = () => {
  return (
    <div>
      <Switch>
        <Route path="/super/dashboard/entity" component={EntitiesScreen} />
        <Redirect to="/super/dashboard/entity" />
      </Switch>
    </div>
  );
};

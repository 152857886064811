import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  Divider,
  Paper,
  Box,
  Grid,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { useStyles } from "../../../../shared/styles/useStyles";
import {
  uiOpenModalAdd,
  uiCloseModalAdd,
  uiCloseSuccessAlert,
  uiCloseErrorAlert,
  uiCloseModalDelete,
} from "../../../../redux/actions/uiActions";
import { CustomizedSearch } from "../../../custom/CustomizedSearch";
import CustomizedDialog from "../../../custom/CustomizedDialog";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { ProductionOfficeTable } from "./ProductionOfficeTable";
import { ProductionOfficeForm } from "./ProductionOfficeForm";
import { uiCloseModalEdit } from "../../../../redux/actions/uiActions";
import { setActiveProductionOffice } from "../../../../redux/actions/trdActions";
import { MyAlert } from "../../../custom/MyAlert";
import { ConfirmDelete } from "../../../ui/shared/ConfirmDelete";
import { ITrdProductionOffices } from "../../../../redux/types/types";

export const ProductionOfficeTab = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { versions, productionOffices } = useSelector(
    (state: AppState) => state.trd
  );
  const [productionOfficesFiltered, setProductionOfficesFiltered] = useState<
    ITrdProductionOffices[]
  >([]);
  const [versionSelected, setVersionSelected] = useState("0");

  const {
    errorAlert,
    modalAddOpen,
    modalDeleteOpen,
    modalEditOpen,
    successAlert,
    successMsg,
  } = useSelector((state: AppState) => state.ui);

  const handleSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setVersionSelected(event.target.value as string);

    const allProductionOffices = [...productionOffices];
    const filterResp = allProductionOffices.filter(
      (office) => office.idVersion === event.target.value
    );
    setProductionOfficesFiltered(filterResp);
  };
  useEffect(() => {
    if (versionSelected !== "0") {
      const allProductionOffices = [...productionOffices];
      const filterResp = allProductionOffices.filter(
        (office) => office.idVersion === versionSelected
      );
      setProductionOfficesFiltered(filterResp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDenyEdit = () => {
    dispatch(uiCloseModalEdit());
    dispatch(setActiveProductionOffice(null));
  };

  const onDenyDelete = () => {
    dispatch(uiCloseModalDelete());
    dispatch(setActiveProductionOffice(null));
  };

  return (
    <>
      <Divider variant="fullWidth" />
      <Paper square className={classes.paper}>
        <Box>
          <Box style={{ height: "5px" }}></Box>
          <Box m={2}>
            <Grid container style={{ marginBottom: "20px" }}>
              <Grid item xs={5}>
                <Button
                  style={{ fontSize: 13 }}
                  size="large"
                  variant="contained"
                  color="secondary"
                  className={classes.btnAdd}
                  type="button"
                  onClick={() => dispatch(uiOpenModalAdd())}
                >
                  <AddOutlinedIcon />
                  <FormattedMessage id="AddProductionOffice" />
                </Button>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={5}>
                <CustomizedSearch
                  data={
                    versionSelected === "0"
                      ? productionOffices
                      : productionOfficesFiltered
                  }
                />
              </Grid>

              <Box width={1} display="flex" mt={1}>
                <Box mr={2} mt={2} style={{ fontSize: 14 }}>
                  <FormattedMessage id="Version" />:
                </Box>
                <Box width={"70%"}>
                  <TextField
                    name="version"
                    variant="outlined"
                    select
                    size="small"
                    value={versionSelected}
                    className={classes.myTextFieldRoot}
                    onChange={handleSelect}
                    disabled={versions.length <= 0}
                  >
                    <MenuItem value={"0"}>
                      <FormattedMessage id={"All"} />
                    </MenuItem>
                    {versions.map((ver) => (
                      <MenuItem key={ver.idVersion} value={ver.idVersion}>
                        {ver.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>
            </Grid>
            <ProductionOfficeTable
              productionOffices={
                versionSelected === "0"
                  ? productionOffices
                  : productionOfficesFiltered
              }
            />

            <CustomizedDialog
              open={modalAddOpen}
              cancelBtn={true}
              onDeny={() => dispatch(uiCloseModalAdd())}
              title={`${intl.formatMessage({ id: "NewProductionOffice" })}`}
              content={<ProductionOfficeForm />}
              textButton="Accept"
            />

            <CustomizedDialog
              open={modalEditOpen}
              cancelBtn={true}
              onDeny={onDenyEdit}
              title={`${intl.formatMessage({ id: "EditProductionOffice" })}`}
              content={<ProductionOfficeForm />}
              textButton="Accept"
            />

            <CustomizedDialog
              open={modalDeleteOpen}
              cancelBtn={true}
              onDeny={onDenyDelete}
              title={`${intl.formatMessage({ id: "DeleteProductionOffice" })}`}
              content={
                <ConfirmDelete
                  title={`${intl.formatMessage({
                    id: "QuestionDeleteProductionOffice",
                  })}`}
                />
              }
              textButton="Accept"
            />

            {successMsg && (
              <>
                <MyAlert
                  open={successAlert}
                  typeAlert="success"
                  message={successMsg}
                  time={2000}
                  handleClose={() => dispatch(uiCloseSuccessAlert())}
                />

                <MyAlert
                  open={errorAlert}
                  typeAlert="error"
                  message={successMsg}
                  time={3000}
                  handleClose={() => dispatch(uiCloseErrorAlert())}
                />
              </>
            )}
          </Box>
        </Box>
      </Paper>
    </>
  );
};

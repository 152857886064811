import { useIntl } from "react-intl";

import { IconButton, TableCell, TableRow, Tooltip } from "@material-ui/core";
import { useStyles } from "../../../../shared/styles/useStyles";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { ITrdTable } from "../../../../redux/types/types";

export const TRDBody = (trd: ITrdTable) => {
  const classes = useStyles();
  const intl = useIntl();

  const onLoadTemplate = () => {
    trd.template && window.open(trd.template.url, "__blank");
  };

  return (
    <TableRow key={trd.dependency} className={classes.borderTable}>
      <TableCell component="th" scope="row" align="center">
        {trd.dependency}
      </TableCell>
      <TableCell align="center">{trd.series ? trd.series : ""}</TableCell>
      <TableCell align="center">{trd.subseries ? trd.subseries : ""}</TableCell>
      <TableCell align="center">
        {trd.documentarySeries ? trd.documentarySeries : ""}
      </TableCell>
      <TableCell align="center">
        {trd.documentarySubseries ? trd.documentarySubseries : ""}
      </TableCell>
      <TableCell align="center">
        {trd.documentaryType ? trd.documentaryType : ""}
      </TableCell>
      <TableCell align="center">{trd.managment ? trd.managment : ""}</TableCell>
      <TableCell align="center">{trd.central ? trd.central : ""}</TableCell>
      <TableCell align="center">{trd.P ? "P" : ""}</TableCell>
      <TableCell align="center">{trd.EL ? "EL" : ""}</TableCell>
      <TableCell align="center">{trd.E ? "E" : ""}</TableCell>
      <TableCell align="center">{trd.S ? "S" : ""}</TableCell>
      <TableCell align="center">{trd.CT ? "CT" : ""}</TableCell>
      <TableCell align="center">{trd.MD ? "CT" : ""}</TableCell>
      <TableCell align="center">{trd.procedure ? trd.procedure : ""}</TableCell>
      <TableCell align="center">
        {trd.template && trd.template.url !== "#" && (
          <Tooltip title={`${intl.formatMessage({ id: "DownloadTemplate" })}`}>
            <IconButton aria-label="expand row" onClick={onLoadTemplate}>
              <DescriptionOutlinedIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

import { makeStyles } from "@material-ui/core";
import { ThemeMode } from "../../../shared/constants/AppEnums";
import { CremaTheme } from "../../../types/AppContextPropsType";

const useStyles = makeStyles((theme: CremaTheme) => {
  return {
    container: {
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
      position: "relative",
      top: 0,
      left: 0,
      width: "19rem",
      maxHeight: "100vh",
      [theme.breakpoints.up("lg")]: {
        position: "fixed",
      },
      [theme.breakpoints.up("xl")]: {
        width: "21.6rem",
      },
    },
    sidebarBg: {
      backgroundColor: (props: { themeMode: ThemeMode }) =>
        props.themeMode === ThemeMode.SEMI_DARK
          ? "#313541"
          : props.themeMode === ThemeMode.LIGHT
          ? theme.palette.common.white
          : "#495057",
    },
    scrollAppSidebar: {
      paddingTop: 8,
      paddingBottom: 20,
      height: "calc(100vh - 58px) !important",

      [theme.breakpoints.up("xl")]: {
        height: "calc(100vh - 65px) !important",
      },
    },
    drawerScrollAppSidebar: {
      paddingTop: 8,
      paddingBottom: 20,
      height: "calc(100vh - 58px) !important",

      [theme.breakpoints.up("xl")]: {
        height: "calc(100vh - 65px) !important",
      },
    },
    sidebarStandard: {
      height: "100vh",
      width: "100%",
      color: theme.palette.common.white,
      overflowY: "auto",
      overflowX: "hidden",
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        width: "0.5em",
        height: "0.5em",
      },
      "&::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px #757575",
        webkitBoxShadow: "inset 0 0 6px #757575",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#BDBDBD",
      },
    },
  };
});
export default useStyles;

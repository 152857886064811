import {
  Box,
  createTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useStyles } from "../../shared/styles/useStyles";
//   import { EntitiesBody } from "./EntitiesBody";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Colors } from "../../shared/constants/themes/Colors";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";
import { Entity } from "../../interfaces/Users";
import { CustomizedTableFooter } from "../custom/CustomizedTableFooter";
import { EntitiesBody } from "./EntitiesBody";
import { useEffect } from "react";
import { setRowsPerPage } from "../../redux/actions/paginationActions";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 12,
  },
});

export const EntitiesTable = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { entities } = useSelector((state: AppState) => state.entities);
  const { data, value } = useSelector((state: AppState) => state.search);

  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );
  let count: number = 0;

  const rows: Entity[] = entities;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  if (!value.trim()) {
    count = entities.length;
  } else {
    if (data) {
      count = data.length;
    }
  }

  useEffect(() => {
    dispatch(setRowsPerPage(6));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        {rows.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            <FormattedMessage id={"NoCreatedEntity"} />
          </Box>
        ) : (
          <Table
            className={classes.table}
            aria-label="custom pagination table"
            style={{ tableLayout: "auto" }}
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.titleTable}>
                  <FormattedMessage id="NameEntity" />{" "}
                </TableCell>
                <TableCell className={classes.titleTable}>
                  <FormattedMessage id="EntityType" />{" "}
                </TableCell>
                <TableCell className={classes.titleTable}>
                  <FormattedMessage id="Department" />{" "}
                </TableCell>
                <TableCell className={classes.titleTable}>
                  <FormattedMessage id="Town" />{" "}
                </TableCell>
                <TableCell className={classes.titleTable}>
                  <FormattedMessage id="Nit" />{" "}
                </TableCell>
                <TableCell className={classes.titleTable} align="center">
                  <FormattedMessage id="State" />{" "}
                </TableCell>
                <TableCell className={classes.titleTable} align="center">
                  <FormattedMessage id="Actions" />{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!value.trim()
                ? (rowsPerPage > 0
                    ? entities.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : entities
                  ).map((entity) => (
                    <EntitiesBody key={entity.nit} entity={entity} />
                  ))
                : data &&
                  (rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((entity) => (
                    <EntitiesBody key={entity.nit} entity={entity} />
                  ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 23 * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
            {/* --------- Footer para paginación -------- */}
            <CustomizedTableFooter count={count} />
          </Table>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

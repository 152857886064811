import { firebase } from "../config/firebase/firebase-config";
import { IResponseFirebase } from "../interfaces/firebase";

export const uploadFileAsync = async (
  file: File,
  fileName: string
): Promise<string> => {
  ;

  return new Promise(async (res, rej) => {
    const upload = firebase.storage().ref(fileName).put(file);
    upload.on(
      "state_changed",
      (snapshot) => {},
      (err) => {
        rej(err);
      },
      async () => {
        const url = await upload.snapshot?.ref.getDownloadURL();
        res(url as string);
      }
    );
  });
};
export const deleteFile = async (root: string): Promise<IResponseFirebase> => {
  try {
    await firebase.storage().ref(root).delete();
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

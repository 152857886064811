import React, { FC } from "react";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import { Fonts } from "../../../shared/constants/fonts/Fonts";

interface props {
  variant: Highcharts.SeriesOptionsType;
  title: string;
  series: Highcharts.PointOptionsObject[];
}
export const MyCharts: FC<props> = ({ variant, title, series }) => {
  const options: Highcharts.Options = {
    title: {
      text: title,
      align: "left",
      style: { fontWeight: `${Fonts.MEDIUM}` },
      x: 5,
      y: 25,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        type: variant.type as any,
        data: series as any,
      },
    ],
    credits: {
      enabled: false,
    },
    chart:{
      width:320
    }
    ,
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 320,
          },
          chartOptions: {
            plotOptions: {
              series: {
                marker: {
                  radius: 2.5,
                },
              },
            },
          },
        },
      ],
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

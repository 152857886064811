import firebase from "firebase/app";
import moment from "moment";
import { uploadFileAsync } from "../../helpers/UploadFile";
import {
  addOrUpdateSupportDocuments,
  setTrdVersion,
  deleteFileSupportDocument,
  deleteTrdSerie,
  deleteTrdVersion,
  getTrdSeries,
  getTrdVersions,
  setTrdSerie,
  existsProductionOffice,
  addOrUpdateProductionOffice,
  updateArrayProductionOffices,
  deleteProductionOfficeFirebase,
  loadTrdDocumentalTypes,
  setTrdDocumentalType,
  addOrUpdateSubserie,
  updateArraySubseries,
  loadSubseries,
  loadProductionOffices,
  validateSeriesCreated,
  existsSubserie,
  deleteSubserieFirebase,
  deleteTrdDocumentalType,
} from "../../services/firebase/trd";
import { AppState } from "../reducers/rootReducer";
import { uiOpenErrorAlert, setErrorMsg } from "./uiActions";
import {
  ITrdAction,
  ITrdVersion,
  Types,
  ITrdProductionOffices,
  ITrdSeries,
  ITrdSubSerie,
  ITrdDocumentalType,
} from "../types/types";
import {
  setSuccessMsg,
  uiCloseModalAdd,
  uiCloseModalAttachDocuments,
  uiCloseModalDelete,
  uiCloseModalEdit,
  uiOpenAlertExists,
  uiOpenSuccessAlert,
} from "./uiActions";

// --------------------------------------------------------
// ---------------        VERSIONES     -------------------
// --------------------------------------------------------

export const startLoadVersions = (idEntity: string) => {
  return async (dispatch: any) => {
    const response = await getTrdVersions(idEntity);
    if (response.length > 0) {
      dispatch(loadVersions(response));
    }
  };
};
const loadVersions = (trdVersions: ITrdVersion[]): ITrdAction => ({
  type: Types.loadVersions,
  payload: trdVersions,
});

export const startAddOrEditTrdVersion = (trdVersion: ITrdVersion) => {
  return async (dispatch: any, getState: any) => {
    const { user } = (getState() as AppState).auth;
    if (user && user.idEntity) {
      const { idEntity } = user;
      const time = moment(trdVersion.approvalDate).toDate().getTime();
      const idVersion = `${trdVersion.code}_${time}`;
      const objToDb: ITrdVersion = {
        ...trdVersion,
        idVersion,
        approvalDate: moment(trdVersion.approvalDate).toDate(),
        documentalType: null,
        productionOffices: null,
        series: null,
        subseries: null,
        supportDocs: null,
      };
      const { ok } = await setTrdVersion(idEntity, objToDb);
      if (ok) {
        dispatch(addOrEditTrdVersion(objToDb));
        dispatch(uiCloseModalAdd());
        dispatch(uiCloseModalEdit());
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};

const addOrEditTrdVersion = (trdVersion: ITrdVersion): ITrdAction => ({
  type: Types.addOrEditTrdVersion,
  payload: trdVersion,
});
export const setActiveTrdVersion = (
  trdVersion: ITrdVersion | null
): ITrdAction => ({
  type: Types.setActiveTrdVersion,
  payload: trdVersion,
});
export const startDeleteTrdVersion = (idVersion: string) => {
  return async (dispatch: any, getState: any) => {
    const { user } = (getState() as AppState).auth;
    if (user && user.idEntity) {
      const { ok } = await deleteTrdVersion(user.idEntity, idVersion);
      if (ok) {
        dispatch(deleteVersion(idVersion));
      } else {
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};

const deleteVersion = (idVersion: string): ITrdAction => ({
  type: Types.deleteVersion,
  payload: idVersion,
});
export const startUploadSupportDocuments = (files: File[]) => {
  return async (dispatch: any, getState: any) => {
    const { activeVersion } = (getState() as AppState).trd;
    const { user } = (getState() as AppState).auth;
    if (activeVersion && files.length > 0 && user && user.idEntity) {
      let supportDocs = activeVersion.supportDocs
        ? [...activeVersion.supportDocs]
        : [];

      /*  let urlFiles: string[] = []; */
      for (const file of files) {
        const resp = await uploadFileAsync(
          file,
          `/supportDocuments/${activeVersion.idVersion}/${file.name}`
        );
        const obj = { fileName: file.name, url: resp };
        supportDocs.push(obj);
      }

      const { ok } = await addOrUpdateSupportDocuments(
        user.idEntity,
        activeVersion.idVersion,
        supportDocs
      );
      if (ok) {
        const updateVersion = { ...activeVersion };
        updateVersion.supportDocs = [...supportDocs];
        dispatch(addOrEditTrdVersion(updateVersion));
        dispatch(uiCloseModalAttachDocuments());
      } else {
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};
export const startDeleteSupportDocument = (
  idVersion: string,
  fileName: string
) => {
  return async (dispatch: any, getState: any) => {
    const { activeVersion } = (getState() as AppState).trd;
    const { user } = (getState() as AppState).auth;

    if (activeVersion && activeVersion.supportDocs && user && user.idEntity) {
      const updatedVersion = { ...activeVersion };
      const filteredDocs = updatedVersion.supportDocs?.filter(
        (el) => el.fileName !== fileName
      );
      if (filteredDocs) {
        updatedVersion.supportDocs = filteredDocs;
        await deleteFileSupportDocument(idVersion, fileName);
        await addOrUpdateSupportDocuments(
          user.idEntity,
          idVersion,
          filteredDocs
        );
        dispatch(addOrEditTrdVersion(updatedVersion));
      }
    }
  };
};

// --------------------------------------------------------
// ---------------        SERIES        -------------------
// --------------------------------------------------------
export const startLoadSeries = (idEntiy: string) => {
  return async (dispatch: any) => {
    const series = await getTrdSeries(idEntiy);
    if (series.length > 0) {
      dispatch(loadSeries(series));
    }
  };
};
const loadSeries = (series: ITrdSeries[]): ITrdAction => ({
  type: Types.loadSeries,
  payload: series,
});

export const startSetSerie = (serie: ITrdSeries, edit: boolean) => {
  return async (dispatch: any, getState: any) => {
    const { user } = (getState() as AppState).auth;
    const { versions, activeSerie } = (getState() as AppState).trd;
    if (user && user.idEntity) {
      const idSerie = !activeSerie
        ? `${serie.serieCode}_${Date.now()}`
        : activeSerie.idSerie;
      const objSerie: ITrdSeries = {
        ...serie,
        idSerie,
      };
      const versionIndex = versions.findIndex(
        (ver) => ver.idVersion === serie.idVersion
      );
      if (versionIndex >= 0) {
        const versionState = versions[versionIndex];
        let seriesToTrd = versionState.series
          ? [idSerie, ...(versionState.series as string[])]
          : [idSerie];

        const { ok } = await setTrdSerie(
          user.idEntity,
          objSerie,
          // @ts-ignore
          activeSerie ? [...seriesToTrd] : seriesToTrd
        );

        if (ok) {
          // @ts-ignore
          versionState.series = activeSerie ? [...seriesToTrd] : seriesToTrd;
          dispatch(addOrEditTrdVersion(versionState));
          dispatch(setSerie(objSerie));
          dispatch(setSuccessMsg(!edit ? "SerieCreated" : "SerieUpdated"));
          dispatch(uiCloseModalAdd());
          dispatch(uiCloseModalEdit());
          dispatch(setActiveSerie(null));
          dispatch(uiOpenSuccessAlert());
        }
      }
    }
  };
};
const setSerie = (serie: ITrdSeries): ITrdAction => ({
  type: Types.setTrdSerie,
  payload: serie,
});
export const setActiveSerie = (serie: ITrdSeries | null): ITrdAction => ({
  type: Types.setActiveSerie,
  payload: serie,
});
export const startDeleteSerie = (idSerie: string) => {
  return async (dispatch: any, getState: any) => {
    const { user } = (getState() as AppState).auth;
    const { versions, activeSerie } = (getState() as AppState).trd;
    if (user && user.idEntity && activeSerie) {
      const versionIndex = versions.findIndex(
        (ver) => ver.idVersion === activeSerie.idVersion
      );
      if (versionIndex !== -1) {
        const versionState = { ...versions[versionIndex] };
        let versionSeries = versionState.series;
        if (versionSeries) {
          versionSeries = versionSeries.filter(
            (version) => version !== activeSerie.idSerie
          );
          versionState.series = [...versionSeries];

          const { ok } = await deleteTrdSerie(user.idEntity, idSerie);
          const { ok: okVersion } = await setTrdVersion(
            user.idEntity,
            versionState
          );

          if (ok && okVersion) {
            dispatch(deleteSerie(idSerie));
            dispatch(addOrEditTrdVersion(versionState));
            dispatch(uiCloseModalDelete());
            dispatch(setSuccessMsg("SerieDeleted"));
            dispatch(uiOpenSuccessAlert());
          } else {
            dispatch(uiOpenErrorAlert());
          }
        }
      }
    }
  };
};
const deleteSerie = (idSerie: string): ITrdAction => ({
  type: Types.deleteSerie,
  payload: idSerie,
});

// --------------------------------------------------------
// --------------- OFICINAS PRODUCTORAS -------------------
// --------------------------------------------------------

// Cargar oficinas productoras
export const startLoadingProductionOffices = (idEntity: string) => {
  return async (dispatch: Function) => {
    const productionOffices = await loadProductionOffices(idEntity);
    dispatch(setProductionOffices(productionOffices));
  };
};

// setear oficinas productoras
export const setProductionOffices = (
  productionOffices: ITrdProductionOffices
) => ({
  type: Types.loadProductionOffices,
  payload: productionOffices,
});

// Agregar o editar oficina productora
export const startAddOrEditProductionOffice = (office: any) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;
    const { versions, activeProductionOffice } = getState().trd;
    const { idVersion } = office;
    let newProductionOffices: string[] = [];
    office.name = office.name.toUpperCase();
    office.code = office.code.toUpperCase();

    const existsOffice = await existsProductionOffice(
      idEntity,
      idVersion,
      office.code,
      office.name,
      !!activeProductionOffice
    );

    if (existsOffice.length > 0) {
      dispatch(setSuccessMsg("ExistsProductionOffice"));
      dispatch(uiOpenAlertExists());
    } else {
      if (!!activeProductionOffice) {
        // EDITAR OFICINA PRODUCTORA
        const { ok } = await addOrUpdateProductionOffice(idEntity, office);
        if (ok) {
          dispatch(updateProductionOffice(office));
          dispatch(setSuccessMsg("ProductionOfficeUpdated"));
          dispatch(uiCloseModalEdit());
          dispatch(setActiveProductionOffice(null));
          dispatch(uiOpenSuccessAlert());
        } else {
          dispatch(setSuccessMsg("ErrorProductionOfficeUpdate"));
          dispatch(uiOpenErrorAlert());
        }
      } else {
        // CREAR OFICINA PRODUCTORA
        // Consultar array de oficinas productoras en TRD
        const versionFiltered = versions.filter(
          (ver: ITrdVersion) => ver.idVersion === idVersion
        )[0];
        office.idProductionOffice = `${office.code}_${Date.now()}`;
        office.createAt = firebase.firestore.Timestamp.now();
        newProductionOffices = versionFiltered.productionOffices
          ? [...versionFiltered.productionOffices, office.idProductionOffice]
          : [office.idProductionOffice];
        // Agregar a colección de oficinas productoras
        const { ok } = await addOrUpdateProductionOffice(idEntity, office);
        // Actualizar array de la versión
        const { ok: okUpdated } = await updateArrayProductionOffices(
          idEntity,
          idVersion,
          newProductionOffices
        );
        if (ok && okUpdated) {
          dispatch(addProductionOffice(office));
          dispatch(
            addOrEditTrdVersion({
              ...versionFiltered,
              productionOffices: newProductionOffices,
            })
          );
          dispatch(setSuccessMsg("ProductionOfficeCreated"));
          dispatch(uiCloseModalAdd());
          dispatch(uiOpenSuccessAlert());
        } else {
          dispatch(setSuccessMsg("ErrorProductionOfficeCreate"));
          dispatch(uiOpenErrorAlert());
        }
      }
    }
  };
};

const addProductionOffice = (office: ITrdProductionOffices) => ({
  type: Types.addTrdProductionOffice,
  payload: office,
});

const updateProductionOffice = (office: ITrdProductionOffices) => ({
  type: Types.updateTrdProductionOffice,
  payload: office,
});

// Activar oficina productora
export const setActiveProductionOffice = (
  productionOffice: ITrdProductionOffices | null
) => ({
  type: Types.setActiveProductionOffice,
  payload: productionOffice,
});

// Eliminar oficina productora
export const startDeleteProductionOffice = (idProductionOffice: string) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;
    const { versions, activeProductionOffice } = getState().trd;
    let newProductionOffices: string[] = [];

    const versionFiltered = versions.filter(
      (ver: ITrdVersion) => ver.idVersion === activeProductionOffice.idVersion
    )[0];

    // Verificar si no se han creado series
    const existsSeries = await validateSeriesCreated(
      idEntity,
      idProductionOffice
    );

    if (existsSeries.length > 0) {
      dispatch(setSuccessMsg("ProductionOfficeDeletedError"));
      dispatch(uiOpenErrorAlert());
    } else {
      versionFiltered.productionOffices &&
        (newProductionOffices = versionFiltered.productionOffices.filter(
          (el: string) => el !== idProductionOffice
        ));

      const { ok } = await deleteProductionOfficeFirebase(
        idEntity,
        idProductionOffice
      );
      const { ok: okUpdated } = await updateArrayProductionOffices(
        idEntity,
        activeProductionOffice.idVersion,
        newProductionOffices
      );

      if (ok && okUpdated) {
        dispatch(deleteProductionOffice(idProductionOffice));
        dispatch(
          addOrEditTrdVersion({
            ...versionFiltered,
            productionOffices: newProductionOffices,
          })
        );
        dispatch(setSuccessMsg("ProductionOfficeDeleted"));
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(setSuccessMsg("ProductionOfficeDeletedError"));
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};

const deleteProductionOffice = (idProductionOffice: string) => ({
  type: Types.deleteProductionOffice,
  payload: idProductionOffice,
});

//TIPO DOCUMENTAL
export const startLoadDocumentalType = (idEntity: string) => {
  return async (dispatch: any) => {
    const documentalTypes = await loadTrdDocumentalTypes(idEntity);
    if (documentalTypes) {
      dispatch(loadDocumentalTypes(documentalTypes));
    }
  };
};
export const startSetDocumentalType = (documentalType: ITrdDocumentalType) => {
  return async (dispatch: any, getState: any) => {
    const { user } = (getState() as AppState).auth;
    const {
      versions,
      activeDocumentalType,
      productionOffices,
      series,
      subSeries,
    } = (getState() as AppState).trd;
    if (user && user.idEntity) {
      const idDocumentalType = !activeDocumentalType
        ? `${documentalType.code}_${Date.now()}`
        : activeDocumentalType.idDocumentalType;
      const productioOffice = productionOffices.find(
        (prod) => prod.idProductionOffice === documentalType.idProductionOffice
      );
      const serie = series.find(
        (serie) => serie.idSerie === documentalType.idSerie
      );
      const subSerie = subSeries.find(
        (sub) => sub.idSubserie === documentalType.idSubSerie
      );
      if (productioOffice && serie && subSerie) {
        const objDocumentalType: ITrdDocumentalType = {
          ...documentalType,
          idDocumentalType,
          codeOp: productioOffice.code,
          serieCode: serie.serieCode,
          subSerieCode: subSerie.subSerieCode,
        };
        const versionIndex = versions.findIndex(
          (ver) => ver.idVersion === documentalType.idVersion
        );
    
        if (versionIndex >= 0) {
          const versionState = versions[versionIndex];
          let documentalTypeToTrd = versionState.documentalType;
          documentalTypeToTrd = versionState.documentalType
            ? [idDocumentalType, ...(versionState.documentalType as string[])]
            : [idDocumentalType];
          if (documentalType.template.file) {
            const resp = await uploadFileAsync(
              documentalType.template.file,
              `/documentalType/${documentalType.idVersion}/${documentalType.template.fileName}`
            );
            objDocumentalType.template = {
              fileName: objDocumentalType.template.fileName,
              url: resp,
            };
          }

          if (activeDocumentalType) {
            const isSameVersion =
              activeDocumentalType.idVersion === documentalType.idVersion;
            if (!isSameVersion) {
              const indexRemoveVersion = versions.findIndex(
                (version) =>
                  version.idVersion === activeDocumentalType.idVersion
              );

              const versionStateRemoveDocType = {
                ...versions[indexRemoveVersion],
              };
              let documentalTypeToTrdLastVersion =
                versionStateRemoveDocType.documentalType
                  ? versionStateRemoveDocType.documentalType
                  : [];
              if (indexRemoveVersion >= 0) {
                documentalTypeToTrdLastVersion =
                  versionStateRemoveDocType.documentalType?.filter(
                    (docType) => docType !== documentalType.idDocumentalType
                  ) as string[];
              }

              versionStateRemoveDocType.documentalType =
                documentalTypeToTrdLastVersion.length > 0
                  ? documentalTypeToTrdLastVersion
                  : null;
              if (versionStateRemoveDocType.documentalType) {
                const { ok } = await setTrdVersion(
                  user.idEntity,
                  versionStateRemoveDocType
                );

                if (!ok) {
                  return dispatch(uiOpenErrorAlert());
                }
                dispatch(addOrEditTrdVersion(versionStateRemoveDocType));
              } else {
                const findTrd = versions.find(
                  (ver) => ver.idVersion === documentalType.idVersion
                );
                if (findTrd) {
                  const { ok } = await setTrdVersion(
                    user.idEntity,
                    findTrd
                  );

                  if (!ok) {

                    return dispatch(uiOpenErrorAlert());
                  }
                  dispatch(addOrEditTrdVersion(versionStateRemoveDocType));
                }

              }
            }
          }
          const { ok } = await setTrdDocumentalType(
            user.idEntity,
            objDocumentalType,
            documentalTypeToTrd
          );

          if (ok) {
            versionState.documentalType = documentalTypeToTrd;
            dispatch(addOrEditTrdVersion(versionState));
            dispatch(setDocumentalType(objDocumentalType));
            dispatch(
              setSuccessMsg(
                !activeDocumentalType
                  ? "DocumentalTypeCreated"
                  : "DocumentalTypeUpdated"
              )
            );
            dispatch(uiOpenSuccessAlert());
          } else {
            dispatch(uiOpenErrorAlert());
          }
          dispatch(uiCloseModalAdd());
          dispatch(uiCloseModalEdit());
          dispatch(setActiveDocumentalType(null));
        }
      }
    }
  };
};
export const startDeleteDocumentalType = (idDocumentalType: string) => {
  return async (dispatch: any, getState: any) => {
    const { user } = (getState() as AppState).auth;
    const { activeDocumentalType, versions } = (getState() as AppState).trd;
    if (user && user.idEntity && activeDocumentalType) {
      let documentalTypesToTrd: string[] = [];
      const versionObj = versions.find(
        (ver) => ver.idVersion === activeDocumentalType.idVersion
      );
      if (versionObj) {
        documentalTypesToTrd = versionObj.documentalType
          ? versionObj.documentalType.filter(
              (docType) => docType !== activeDocumentalType.idDocumentalType
            )
          : [];

        const { ok } = await deleteTrdDocumentalType(
          user.idEntity,
          idDocumentalType,
          activeDocumentalType.template.fileName,
          activeDocumentalType.idVersion,
          documentalTypesToTrd
        );
        if (ok) {
          versionObj.documentalType = documentalTypesToTrd;
          dispatch(deleteDocumentalType(idDocumentalType));
          dispatch(addOrEditTrdVersion(versionObj));
          dispatch(uiCloseModalDelete());
          dispatch(uiOpenSuccessAlert());
          dispatch(setSuccessMsg("SuccesDeleteDocumentalType"));
          dispatch(setActiveDocumentalType(null));
        } else {
          dispatch(uiOpenErrorAlert());
        }
      }
    }
  };
};
const deleteDocumentalType = (idDocumentalType: string): ITrdAction => ({
  type: Types.deleteTrdDocumentalType,
  payload: idDocumentalType,
});

const setDocumentalType = (documentalType: ITrdDocumentalType): ITrdAction => ({
  type: Types.setTrdDocumentalType,
  payload: documentalType,
});
export const setActiveDocumentalType = (
  documentalType: ITrdDocumentalType | null
): ITrdAction => ({
  type: Types.setActiveDocumentalType,
  payload: documentalType,
});

const loadDocumentalTypes = (
  documentalTypes: ITrdDocumentalType[]
): ITrdAction => ({
  type: Types.loadTrdDocumentalTypes,
  payload: documentalTypes,
});

// --------------------------------------------------------
// --------------- Subseries ------------------------------
// --------------------------------------------------------

// Cargar subseries
export const startLoadingSubseries = (idEntity: string) => {
  return async (dispatch: Function) => {
    const subSeries = await loadSubseries(idEntity);

    if (subSeries) {
      dispatch(setSubseries(subSeries));
    }
  };
};

// setear subseries
export const setSubseries = (subSeries: ITrdSubSerie) => ({
  type: Types.loadSubseries,
  payload: subSeries,
});

export const startAddOrEditSubserie = (subserie: ITrdSubSerie) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;
    const { versions, activeSubSerie } = getState().trd;
    const { idVersion, idSerie } = subserie;
    let newSubSeries: string[] = [];

    const existsSubseries = await existsSubserie(
      idEntity,
      idVersion,
      idSerie,
      subserie.subSerieCode,
      subserie.name,
      !!activeSubSerie
    );

    if (existsSubseries.length > 0) {
      dispatch(setSuccessMsg("ExistsSubserie"));
      dispatch(uiOpenErrorAlert());
    } else {
      if (activeSubSerie) {
        const { ok } = await addOrUpdateSubserie(idEntity, idSerie, subserie);
        if (ok) {
          dispatch(updateSubserie(subserie));
          dispatch(setSuccessMsg("SubSerieUpdated"));
          dispatch(uiCloseModalEdit());
          dispatch(setActiveSubserie(null));
          dispatch(uiOpenSuccessAlert());
        } else {
          dispatch(uiOpenErrorAlert());
          dispatch(setSuccessMsg("ErrorSubserieUpdate"));
        }
      } else {
        // CREAR SubSerie
        // Consultar array de oficinas productoras, series y versiones en TRD
        const versionFiltered = versions.filter(
          (ver: ITrdVersion) => ver.idVersion === idVersion
        )[0];

        subserie.idSubserie = `${subserie.subSerieCode}_${Date.now()}`;
        subserie.provision = {
          CT: subserie.provision.CT,
          E: subserie.provision.E,
          MD: subserie.provision.MD,
          S: subserie.provision.S,
        };
        newSubSeries = versionFiltered.subseries
          ? [...versionFiltered.subseries, subserie.idSubserie]
          : [subserie.idSubserie];
        // Agregar a colección subseries
        const { ok } = await addOrUpdateSubserie(idEntity, idSerie, subserie);
        const { ok: updated } = await updateArraySubseries(
          idEntity,
          idVersion,
          newSubSeries
        );
        if (ok && updated) {
          dispatch(setSuccessMsg("SubSerieCreated"));
          dispatch(uiCloseModalAdd());
          dispatch(uiOpenSuccessAlert());
          dispatch(addSubSerie(subserie));
          dispatch(
            addOrEditTrdVersion({
              ...versionFiltered,
              subseries: newSubSeries,
            })
          );
        } else {
          dispatch(setSuccessMsg("ErrorSubserieCreate"));
          dispatch(uiOpenErrorAlert());
        }
      }
    }
  };
};

const addSubSerie = (trdSubserie: ITrdSubSerie): ITrdAction => ({
  type: Types.addSubserie,
  payload: trdSubserie,
});

const updateSubserie = (trdSubserie: ITrdSubSerie) => ({
  type: Types.updateTrdSubserie,
  payload: trdSubserie,
});

// Activar Subserie
export const setActiveSubserie = (subSerie: ITrdSubSerie | null) => ({
  type: Types.setActiveSubserie,
  payload: subSerie,
});

// Eliminar Subserie
export const startDeleteSubSerie = (idSubserie: string) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;
    const { versions, activeSubSerie, documentalType } = getState().trd;
    let newSubseries: string[] = [];

    const versionFiltered = versions.filter(
      (ver: ITrdVersion) => ver.idVersion === activeSubSerie.idVersion
    )[0];

    // Verificar si no se han creado tipos documental
    const documentalTypeBySubserieCode = documentalType.filter(
      (doc: ITrdDocumentalType) =>
        doc.subSerieCode === activeSubSerie.subSerieCode
    );

    if (documentalTypeBySubserieCode.length > 0) {
      dispatch(setErrorMsg("ErrorDeleteSubserieByDocumentalType"));
      dispatch(uiOpenErrorAlert());
    } else {
      versionFiltered &&
        (newSubseries = versionFiltered.subseries.filter(
          (el: string) => el !== idSubserie
        ));

      const { ok } = await deleteSubserieFirebase(
        idEntity,
        activeSubSerie.idSerie,
        idSubserie
      );
      const { ok: okUpdated } = await updateArraySubseries(
        idEntity,
        activeSubSerie.idVersion,
        newSubseries
      );

      if (ok && okUpdated) {
        dispatch(deleteSubserie(idSubserie));
        dispatch(
          addOrEditTrdVersion({
            ...versionFiltered,
            subseries: newSubseries,
          })
        );
        dispatch(setSuccessMsg("SuccesDeleteSubserie"));
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(setSuccessMsg("ErrorDeleteSubserie"));
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};

const deleteSubserie = (idSubserie: string) => ({
  type: Types.deleteSubserie,
  payload: idSubserie,
});

import {
  Box,
  createTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { counter } from "../../../helpers/count";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { FormattedMessage } from "react-intl";
import { Colors } from "../../../shared/constants/themes/Colors";
import { CustomizedTableFooter } from "../../custom/CustomizedTableFooter";
import { FileBody } from "./FileBody";
import { FC, useEffect } from "react";
import { IExpedientCorrespondence } from "../../../interfaces/Correspondence";
import { setActiveCorresExpedient } from "../../../redux/actions/documentManagmentActions";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});
interface props {
  expedients: IExpedientCorrespondence[];
  readonly?: boolean;
  component?: string;
}

export const FileTab: FC<props> = ({
  expedients,
  readonly = false,
  component = "OTHER",
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, value } = useSelector((state: AppState) => state.search);

  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );
  const count = counter(value, expedients, data);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, expedients.length - page * rowsPerPage);

  useEffect(() => {
    dispatch(setActiveCorresExpedient(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper} style={{ paddingTop: "10px" }}>
        {expedients.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            <FormattedMessage id={"NoCreatedFiles"} />
          </Box>
        ) : (
          <Table
            className={classes.table}
            size="small"
            style={{ tableLayout: "auto" }}
            aria-label="a dense table"
          >
            <TableHead className={classes.titleTable}>
              <TableRow>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="FileName" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Description" />{" "}
                </TableCell>
                {!readonly && (
                  <TableCell className={classes.titleTable} align="center">
                    <FormattedMessage id="Actions" />{" "}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!value.trim()
                ? (rowsPerPage > 0
                    ? expedients.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : expedients
                  ).map((procedure) => (
                    <FileBody
                      key={procedure.id}
                      settle={procedure}
                      readOnly={readonly}
                      component={component}
                    />
                  ))
                : data &&
                  (rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((procedure) => (
                    <FileBody
                      key={procedure.id}
                      settle={procedure}
                      readOnly={readonly}
                      component={component}
                    />
                  ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 13 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {/* --------- Footer para paginación -------- */}
            <CustomizedTableFooter count={count} />
          </Table>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

import moment from "moment";
import { FC } from "react";

import { TableCell, TableRow } from "@material-ui/core";
import { CustomizedIcons } from "../../../components/custom/CustomizedIcons";
import { IUserCopy } from "../../../interfaces/Correspondence";
import Swal from "sweetalert2";
import { Colors } from "../../../shared/constants/themes/Colors";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { startDeleteFuidCopy } from "../../../redux/actions/centralArchiveActions";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

interface props {
  copy: IUserCopy;
  readonly?: boolean;
}

export const CopiesFuidBody: FC<props> = ({ copy, readonly = false }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onDelete = () => {
    Swal.fire({
      title: `${intl.formatMessage({ id: "DeleteCopyQuestion" })}${
        copy.userName
      }?`,
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: Colors.PRIMARY,
      cancelButtonColor: Colors.DANGER,
      confirmButtonText: "Si",
      cancelButtonText: "No",
      customClass: {
        container: "swal-container-z-index",
      },
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        await dispatch(startDeleteFuidCopy(copy.id,copy.userName, intl));
      },
    });
  };

  return (
    <TableRow>
      <TableCell align="center">{copy.userName}
      {copy.uploadPermission && (
        <FileCopyOutlinedIcon fontSize="small" />
      )}
      </TableCell>
      <TableCell align="center" style={{ textTransform: "lowercase" }}>
        {copy.submitDate
          ? moment((copy.submitDate as any).toDate()).format(
              "YYYY-MM-DD hh:mm a"
            )
          : ""}
      </TableCell>
      {!readonly && (
        <TableCell align="center">
          <CustomizedIcons deleteIcon onDelete={onDelete} />
        </TableCell>
      )}
    </TableRow>
  );
};

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import {
  createTheme,
  ThemeProvider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { CustomizedTableFooter } from "../../../custom/CustomizedTableFooter";
import { useStyles } from "../../../../shared/styles/useStyles";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { ProductionOfficeBody } from "./ProductionOfficeBody";
import { ITrdProductionOffices } from "../../../../redux/types/types";
import { Colors } from "../../../../shared/constants/themes/Colors";
import { counter } from "../../../../helpers/count";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});

interface Props {
  productionOffices: ITrdProductionOffices[];
}

export const ProductionOfficeTable = (props: Props) => {
  const classes = useStyles();
  const { productionOffices } = props;
  const { data, value } = useSelector((state: AppState) => state.search);
  const list: ITrdProductionOffices[] = productionOffices;

  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );
  const count = counter( value, productionOffices, data );
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, list.length - page * rowsPerPage);

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        {list.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            <FormattedMessage id={"NoCreatedProductionOffices"} />
          </Box>
        ) : (
          <Table
            className={classes.table}
            size="small"
            style={{ tableLayout: "auto" }}
            aria-label="a dense table"
          >
            <TableHead className={classes.titleTable}>
              <TableRow>
                <TableCell
                  width={"15%"}
                  align="center"
                  className={classes.titleTable}
                >
                  <FormattedMessage id="Code" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="ProductionOffice" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Abbreviation" />{" "}
                </TableCell>
                <TableCell
                  width={"20%"}
                  className={classes.titleTable}
                  align="center"
                >
                  <FormattedMessage id="Actions" />{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!value.trim()
                ? (rowsPerPage > 0
                    ? list.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : list
                  ).map((office) => (
                    <ProductionOfficeBody key={office.code} office={office} />
                  ))
                : data &&
                  (rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((office) => (
                    <ProductionOfficeBody key={office.code} office={office} />
                  ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 13 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {/* --------- Footer para paginación -------- */}
            <CustomizedTableFooter count={count} />
          </Table>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

import { Types } from "../types/types";
import {
  loadOffices,
  addOffice,
  editOffice,
  deleteOfficeQuery,
} from "../../services/firebase/organizationChart";
import {
  uiOpenSuccessAlert,
  setSuccessMsg,
  uiCloseModalEdit,
  uiCloseModalAdd,
  uiCloseModalDelete,
  uiOpenAlertExists,
} from "./uiActions";
import { Office } from "../../interfaces/OrganizationChart";

// Crear nueva oficina
export const startNewOrganizationChart = (
  values: Office,
  nameProducionOffice: string,
  idProductionOffice: string
) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;
    const { offices } = getState().organizationChart;
    const officesByProductionOffice = offices.filter(
      (el: Office) => el.idProductionOffice === values.idProductionOffice
    );
    const existsOfficeName = officesByProductionOffice.filter(
      (el: Office) => el.name === values.name
    );

    if (existsOfficeName.length > 0) {
      dispatch(uiOpenAlertExists());
    } else {

      const nameSplit = values.name.split(" ").join("");
      values.idOffice = `${values.idProductionOffice}_${nameSplit}`;

      const newOffice: Office = {
        name: values.name,
        idVersion: values.idVersion,
        idProductionOffice: values.idProductionOffice,
        productionOffice: nameProducionOffice,
        idOffice: values.idOffice,
      };

      await addOffice(newOffice, idEntity, idProductionOffice);
      dispatch(addNewOffice(newOffice));
      dispatch(setSuccessMsg("OfficeAddSuccess"));
      dispatch(uiCloseModalAdd());
      dispatch(officeCleanActive());
      dispatch(uiOpenSuccessAlert());
    }
  };
};

// Adiconar oficina
const addNewOffice = (office: any) => ({
  type: Types.OrganizationChartAddNew,
  payload: office,
});

export const startLoadingOffices = (idEntity: string) => {
  return async (dispatch: Function) => {
    const offices = await loadOffices(idEntity);
    if (offices) {
      dispatch(setOffices(offices));
    }
  };
};
// setear las oficinas
export const setOffices = (offices: Office) => ({
  type: Types.OrganizationChartLoad,
  payload: offices,
});

// Oficina Activa
export const activeOffice = (office: Office) => ({
  type: Types.OrganizationCharActive,
  payload: office,
});

export const officeCleanActive = () => ({
  type: Types.OrganizationCharCleaning,
});

// Editar Oficina
export const startEditOffice = (office: Office) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;
    const { offices } = getState().organizationChart;
    const officesByProductionOffice = offices.filter(
      (el: Office) => el.idProductionOffice === office.idProductionOffice
    );
    const existsOfficeName = officesByProductionOffice.filter(
      (el: Office) => el.name === office.name
    );

    if (existsOfficeName.length > 0) {
      dispatch(uiOpenAlertExists());
    } else {
      try {
        dispatch(activeOffice(office));
        await editOffice(office, idEntity);
        dispatch(updateOffice(office));
        dispatch(setSuccessMsg("OfficeEditSuccess"));
        dispatch(uiCloseModalEdit());
        dispatch(officeCleanActive());
        dispatch(uiOpenSuccessAlert());
      } catch (error: any) {
        throw new Error(error);
      }
    }
  };
};

export const updateOffice = (office: Office) => ({
  type: Types.OrganizationChartUpdated,
  payload: office,
});

// borrar oficina

export const startDeleting = (office: Office) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;

    try {
      await deleteOfficeQuery(office, idEntity);
      dispatch(deleteOffice(office.idOffice));
      dispatch(setSuccessMsg("SuccesDeleteOffice"));
      dispatch(uiCloseModalDelete());
      dispatch(officeCleanActive());
      dispatch(uiOpenSuccessAlert());
    } catch (error: any) {
      throw new Error(error);
    }
  };
};

export const deleteOffice = (idOffice: string | undefined) => ({
  type: Types.OrganizationCharDelete,
  payload: idOffice,
});

import clsx from "clsx";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { PhotoCamera } from "@material-ui/icons";
import { MyTextField } from "../../custom/MyTextField";
import { Enum_FileSize, TypeDocUsers, TypeUser } from "../../../enums/enums";
import {
  uiCloseModalAdd,
  uiCloseAlertExists,
  uiCloseSuccessAlert,
} from "../../../redux/actions/uiActions";
import { useStyles } from "../../../shared/styles/useStyles";
import { User } from "../../../interfaces/Users";
import { startNewUser } from "../../../redux/actions/usersActions";
import { MyAlert } from "../../custom/MyAlert";
import { AppState } from "../../../redux/reducers/rootReducer";

export const FormAddUser = () => {
  const intl = useIntl();
  const classes = useStyles();

  const dispatch = useDispatch();
  const { alertExists, successAlert } = useSelector(
    (state: AppState) => state.ui
  );
  const [noValid, setNoValid] = useState(false);
  const [noValidSize, setNoValidSize] = useState(false);
  const [labelImage, setLabelImage] = useState("");
  const [profileFile, setProfileFile] = useState<File | null>(null);
  const { user } = useSelector((state: AppState) => state.auth);
  const initialValues: User = {
    id: "",
    createdAt: "",
    typeDoc: "",
    document: "",
    firstName: "",
    secondName: "",
    firstLastName: "",
    secondLastName: "",
    username: "",
    email: "",
    mobilePhone: "",
    address: "",
    profileImage: null,
    active: false,
    rol: TypeUser.OFFICER,
    modules: [],
    idEntity: "",
    uid: "",
    businessName: "",
    offices: [],
    country: user ? user.country : "",
    department: user ? user.department : "",
  };

  const validationSchema = yup.object({
    typeDoc: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    document: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`)
      .min(5, `${intl.formatMessage({ id: "MinimumDocument" })}`),
    firstName: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    secondName: yup.string(),
    firstLastName: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    secondLastName: yup.string(),
    email: yup
      .string()
      .email(`${intl.formatMessage({ id: "InvalidEmail" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    mobilePhone: yup
      .number()
      .typeError(`${intl.formatMessage({ id: "NumericValue" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    address: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  const onClose = () => {
    dispatch(uiCloseModalAdd());
  };

  const handleSelectFile = (e: any) => {
    const SUPPORTED_FORMATS = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/png",
      "application/msword",
      "text/csv",
      "application/pdf",
    ];
    const file = e.target.files[0] as File;
    const now = new Date().getTime().toString();
    if (file) {
      if (file.size > Enum_FileSize.maxAllowedSize) {
        setNoValidSize(true);
      } else {
        !SUPPORTED_FORMATS.includes(file.type)
          ? setNoValid(true)
          : setNoValid(false);
        setProfileFile(file);
        setLabelImage(now + "_" + e.target.files[0].name);
      }
    } else {
      setNoValid(false);
      setNoValidSize(false);
      setLabelImage("");
    }
  };

  const closeAlert = () => {
    dispatch(uiCloseAlertExists());
  };

  const closeSuccess = () => {
    dispatch(uiCloseSuccessAlert());
    dispatch(uiCloseModalAdd());
  };

  // ARCHIVOS SOPORTADOS PARA INPUT TYPE=FILE
  const fileSupported = {
    accept:
      "application/pdf, image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv",
  };

  return (
    <Box m={1}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (!noValid) {
            setSubmitting(true);
            values.profileImage = profileFile;
            await dispatch(startNewUser(values));
            setSubmitting(false);
          } else {
            setSubmitting(false);
          }
        }}
      >
        {({ values, isSubmitting }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <label className="form-text">
                  <FormattedMessage id="DocumentType" />*
                </label>
                <MyTextField
                  name="typeDoc"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                >
                  <MenuItem value={TypeDocUsers.CC}>
                    <FormattedMessage id="CitizenshipCard" />
                  </MenuItem>
                  <MenuItem value={TypeDocUsers.CE}>
                    <FormattedMessage id="ForeignersIdentityCard" />
                  </MenuItem>
                  <MenuItem value={TypeDocUsers.PASSPORT}>
                    <FormattedMessage id="Passport" />
                  </MenuItem>
                </MyTextField>
              </Grid>

              <Grid item xs={8}>
                <label className="form-text">
                  <FormattedMessage id="DocumentNumber" />*
                </label>
                <MyTextField
                  name="document"
                  variant="outlined"
                  type="number"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="FirstName" />*
                </label>
                <MyTextField
                  name="firstName"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="SecondName" />
                </label>
                <MyTextField
                  name="secondName"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="FirstLastName" />*
                </label>
                <MyTextField
                  name="firstLastName"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="SecondLastName" />
                </label>
                <MyTextField
                  name="secondLastName"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="UserName" />
                </label>
                <MyTextField
                  name="usernames"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  InputLabelProps={{ shrink: false }}
                  label={`${values.firstName} ${values.secondName} ${values.firstLastName} ${values.secondLastName}`}
                  disabled={true}
                  style={{ color: "black" }}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="Email" />*
                </label>
                <MyTextField
                  name="email"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="Mobile" />*
                </label>
                <MyTextField
                  name="mobilePhone"
                  variant="outlined"
                  type="number"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="Address" />*
                </label>
                <MyTextField
                  name="address"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="ProfileImage" />
                </label>

                <TextField
                  size="small"
                  type="file"
                  inputProps={fileSupported}
                  onChange={handleSelectFile}
                  id="icon-button-file"
                  autoComplete="off"
                  style={{ display: "none" }}
                />

                <MyTextField
                  disabled={true}
                  variant="outlined"
                  style={{ color: "black" }}
                  label={labelImage}
                  name="hola"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <Box mt={3} ml={-2}>
                  <Tooltip
                    title={`${intl.formatMessage({ id: "SearchImage" })}`}
                  >
                    <label htmlFor="icon-button-file">
                      <IconButton component="span">
                        <PhotoCamera />
                      </IconButton>
                    </label>
                  </Tooltip>
                </Box>
              </Grid>
              {noValid && (
                <Grid item xs={12}>
                  <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                    <FormattedMessage id="ValidFiles" />
                  </Box>
                </Grid>
              )}
              {noValidSize && (
                <Grid item xs={12}>
                  <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                    <FormattedMessage id="ValidDocumentalFilesSize" />
                  </Box>
                </Grid>
              )}
            </Grid>

            <Box mt={2} display="flex" flexDirection="row-reverse">
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="Save" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>

      <MyAlert
        open={alertExists}
        typeAlert="error"
        message="MessageExistsUser"
        time={2000}
        handleClose={closeAlert}
      />

      <MyAlert
        open={successAlert}
        typeAlert="success"
        message="UserAddSuccess"
        time={2000}
        handleClose={closeSuccess}
      />
    </Box>
  );
};

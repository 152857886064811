import clsx from "clsx";
import { Form, Formik } from "formik";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
} from "@material-ui/core";
import {
  uiCloseAlertExists,
  uiCloseModalAdd,
  uiCloseModalEdit,
} from "../../../../redux/actions/uiActions";
import { MyTextField } from "../../../custom/MyTextField";
import { useStyles } from "../../../../shared/styles/useStyles";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { setActiveProductionOffice } from "../../../../redux/actions/trdActions";
import { startAddOrEditProductionOffice } from "../../../../redux/actions/trdActions";
import { MyAlert } from "../../../custom/MyAlert";

export const ProductionOfficeForm = () => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { versions, activeProductionOffice: active } = useSelector(
    (state: AppState) => state.trd
  );
  const { alertExists, successMsg } = useSelector(
    (state: AppState) => state.ui
  );

  const validationSchema = yup.object({
    idVersion: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    code: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    name: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    abbreviation: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  let initialValues = {
    idVersion: active ? active.idVersion : "",
    code: active ? active.code : "",
    name: active ? active.name : "",
    abbreviation: active ? active.abbreviation : "",
    idProductionOffice: active ? active.idProductionOffice : "",
  };

  const onClose = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    dispatch(setActiveProductionOffice(null));
  };

  return (
    <Box m={1}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          await dispatch(startAddOrEditProductionOffice(data));
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Version" />*
                </label>
                <MyTextField
                  name="idVersion"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                  disabled={!!active}
                >
                  {versions.map((ver) => (
                    <MenuItem key={ver.idVersion} value={ver.idVersion}>
                      {ver.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Code" />*
                </label>
                <MyTextField
                  name="code"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  disabled={!!active}
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Name" />*
                </label>
                <MyTextField
                  name="name"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Abbreviation" />*
                </label>
                <MyTextField
                  name="abbreviation"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>
            </Grid>

            <Box mt={2} display="flex" flexDirection="row-reverse">
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="Save" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>

      {successMsg && (
        <MyAlert
          open={alertExists}
          typeAlert="error"
          message={successMsg}
          time={2000}
          handleClose={() => dispatch(uiCloseAlertExists())}
        />
      )}
    </Box>
  );
};

import { FC } from "react";
import clsx from "clsx";
import { Form, Formik } from "formik";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
} from "@material-ui/core";
import {
  uiCloseModalAdd,
  uiCloseModalEdit,
} from "../../../../redux/actions/uiActions";
import { MyTextField } from "../../../custom/MyTextField";
import { useStyles } from "../../../../shared/styles/useStyles";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { ITrdSeries } from "../../../../redux/types/types";
import { setActiveSerie, startSetSerie } from "../../../../redux/actions/trdActions";
interface SeriesProps {
  edit?: boolean;
}
export const SeriesForm: FC<SeriesProps> = ({ edit = false }) => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeSerie, versions, productionOffices } = useSelector(
    (state: AppState) => state.trd
  );

  const validationSchema = yup.object({
    idVersion: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    idProductionOffice: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    serieCode: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    seriesName: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  // TODO: CREAR INTERFAZ PARA VERSIÓN
  let initialValues: Partial<ITrdSeries> = {
    idVersion: "",
    idProductionOffice: "",
    serieCode: "",
    seriesName: "",
  };
  if (activeSerie) {
    initialValues = {
      ...activeSerie
    };
  }

  const onClose = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    dispatch( setActiveSerie(null) )
  };

  return (
    <Box m={1}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          const productionOffice = productionOffices.find(op=>op.idProductionOffice === data.idProductionOffice);
          if(productionOffice){
            data.codeOp=productionOffice.code;
            data.nameOp=productionOffice.name
            await dispatch( startSetSerie(data as ITrdSeries,edit) )
          }
          // TODO: Disparar acción
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Version" />*
                </label>
                <MyTextField
                  name="idVersion"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                  disabled={edit}
                >
                  {versions.map((ver) => (
                    <MenuItem key={ver.idVersion} value={ver.idVersion}>
                      {ver.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="ProductionOffice" />*
                </label>
                <MyTextField
                  name="idProductionOffice"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                  //   disabled={ active ? true : false}
                >
                  {productionOffices.map((ProductionOffice) => (
                    <MenuItem
                      key={ProductionOffice.idProductionOffice}
                      value={ProductionOffice.idProductionOffice}
                    >
                      {ProductionOffice.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="SeriesCode" />*
                </label>
                <MyTextField
                  name="serieCode"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  disabled={edit}
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="SeriesName" />*
                </label>
                <MyTextField
                  name="seriesName"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>
            </Grid>

            <Box mt={2} display="flex" flexDirection="row-reverse">
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="Save" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

import { db, firebase } from "../../config/firebase/firebase-config";
import { IUserLoggedIn, ITransfer, IFiles } from "../../redux/types/types";
import {
  ICorrespondence,
  ICorrespondenceEmailResponse,
  ISignature,
} from "../../interfaces/Correspondence";
import { IResponseFirebase } from "../../interfaces/firebase";
import {
  loadCorresAnnotations,
  loadTransferManagment,
} from "../../redux/actions/documentManagmentActions";
import axios from "axios";
interface ISendEmailData {
  email: string;
  subject: string;
  settled: string;
  answer: string;
  file: IFiles | null;
  idEntity: string;
}
export const loadCorresAnnotationsDB = async (
  idEntity: string,
  correspondenceId: string,
  callback: Function
) => {
  return db
    .collection("Correspondence")
    .doc(idEntity)
    .collection("CorrespondenceSection")
    .doc(correspondenceId)
    .collection("CorresAnnotations")
    .onSnapshot((snap) => {
      let response: any[] = [];
      snap.forEach((doc) => {
        response.push(doc.data() as any);
      });
      callback(loadCorresAnnotations(response));
    });

  /*  const corresAnnotations = await docRef.get();
  const resp: any[] = [];

  corresAnnotations.forEach((doc) => {
    resp.push(doc.data() as any);
  });

  return resp; */
};

export const deleteCorresAnnotationDB = async (
  idEntity: string,
  correspondenceId: string,
  idAnnotation: string
) => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(correspondenceId)
      .collection("CorresAnnotations")
      .doc(idAnnotation)
      .delete();
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const deleteCorresAnnotationDBDoc = async (
  idEntity: string,
  fileName: string
) => {
  try {
    await firebase
      .storage()
      .ref(`/CorresAnnotations/${idEntity}/${fileName}`)
      .delete();
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// cambiar propiedad isReaded cuando la correspondencia se abre

export const itWasRead = async (
  correspondence: ICorrespondence,
  user: IUserLoggedIn
) => {
  try {
    await db
      .collection("Correspondence")
      .doc(user.idEntity)
      .collection("CorrespondenceSection")
      .doc(correspondence.id)
      .set({ ...correspondence }, { merge: true });
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// deacargar archivo de correspondencia desde las tabs document managment
export const donwloadFile = async (
  folder: string,
  idEntity: string,
  fileName: string
) => {
  try {
    const linkdescarga = await firebase
      .storage()
      .ref(`${folder}/${idEntity}/${fileName}`)
      .getDownloadURL();
    return {
      ok: true,
      data: linkdescarga,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

//AGREGAR TRANSFERENCIA A DATA BASE
export const addTransferDb = async (
  idEntity: string,
  transfer: ITransfer,
  fromCentralFile?: boolean
) => {
  try {
    await db
      .collection(fromCentralFile ? "FileModule" : "Correspondence")
      .doc(idEntity)
      .collection("Transfers")
      .doc(transfer.idTransfer)
      .set({ ...transfer }, { merge: true });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// SERVICIO PARA CARGAR TRANSFER POR ORDEN
export const startListenerTransfersByEntity = (
  idEntity: string,
  callback: Function
) => {
  db.collection("Correspondence")
    .doc(idEntity)
    .collection("Transfers")
    .orderBy("createDate", "desc")
    .onSnapshot((snap) => {
      let response: ITransfer[] = [];
      snap.forEach((doc) => {
        response.push(doc.data() as ITransfer);
      });
      callback(loadTransferManagment(response));
    });
};

//  ELIMINAR DEPOSITO DE LA BD
export const deleteDepositDb = async (
  idEntity: string,
  idDeposit: string
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("Deposits")
      .doc(idDeposit)
      .delete();
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

//  ELIMINAR TRANSFERENCIA DE LA BD
export const deleteTransferDb = async (
  idEntity: string,
  idTransfer: string,
  isFileModule: boolean
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection(isFileModule ? "FileModule" : "Correspondence")
      .doc(idEntity)
      .collection("Transfers")
      .doc(idTransfer)
      .delete();
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};
export const sendEmailToUser = (data: ISendEmailData) => {
  return axios
    .post(
       `https://us-central1-modulosmialcaldia.cloudfunctions.net/sendEmail`,
      {
        ...data,
      }
    )
    .then((resp: any) => {
      const { data } = resp;
      return data as ICorrespondenceEmailResponse;
    });
};

/* AGREGAR SUBCOLLECTION SIGNATURE */
export const addSignatureDb = async (
  idUser: string,
  idEntity: string,
  signature: ISignature
) => {
  try {
    await db
      .collection("Entities")
      .doc(idEntity)
      .collection("signatures")
      .doc(idUser)
      .set({
        ...signature,
      });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

/* LOAD SUBCOLLECTION SIGNATURE */
export const loadSignatureDb = async (idUser: string, idEntity: string) => {
  const param: any = await db
    .collection("Entities")
    .doc(idEntity)
    .collection("signatures")
    .doc(idUser)
    .get();
  if (param.data()) {
    return {
      idUser: (param.data() as any).idUser,
      signature: (param.data() as any).signature,
    };
  } else {
    return null;
  }
};

/* DELETE SIGNATURE */
export const deleteSignatureDb = async (
  idEntity: string,
  idUser: string,
  fileName: string
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection("Entities")
      .doc(idEntity)
      .collection("signatures")
      .doc(idUser)
      .delete();
    if (fileName) {
      await firebase
        .storage()
        .ref(`/signatures/${idEntity}/${idUser}_${fileName}`)
        .delete();
    }
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

//get emails answers correspondence
export const getEmailAnswersCorrespondence = async (
  idEntity: string,
  idCorrespodence: string,
  callback: Function
) => {
  try {
    return db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(idCorrespodence)
      .collection("Answers")
      .where("isSendEmailResponse", "==", true)
      .onSnapshot(snap=>{
        const response: any[] = [];
        snap.forEach((doc) => {
          response.push(doc.data());
        });
       
          callback(response);
       
        

      })

  } catch (error) {
    return null;
  }
};

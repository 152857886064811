import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { FileCopy, Close } from "@material-ui/icons";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Enum_FileSize } from "../../../enums/enums";
import { IFiles } from "../../../redux/types/types";
import { useStyles } from "../../../shared/styles/useStyles";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import clsx from "clsx";
import {
  startDeleteSignature,
  startUploadSignature,
} from "../../../redux/actions/documentManagmentActions";
import {
  uiCloseErrorAlert,
  uiCloseSuccessAlert,
} from "../../../redux/actions/uiActions";
import { MyAlert } from "../../custom/MyAlert";

const Signature = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { errorAlert, errorMsg, successMsg, successAlert } = useSelector(
    (state: AppState) => state.ui
  );

  const { signature } = useSelector(
    (state: AppState) => state.documentManagment
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isDb, setIsDb] = useState(
    signature && signature.signature[0].url ? true : false
  );
  const [isBtn, setIsBtn] = useState(false);
  const [labelImage, setLabelImage] = useState("");
  const [noValid, setNoValid] = useState(false);
  const [imageSrc, setImageSrc] = useState<any>(
    signature ? signature.signature[0].url : ""
  );
  const [noValidSize, setNoValidSize] = useState(false);
  const [fileNames, setFileNames] = useState<IFiles[]>(
    signature ? signature.signature : []
  );
  const classes = useStyles();
  // ARCHIVOS SOPORTADOS PARA INPUT TYPE=FILE
  const fileSupported = {
    accept: "image/png",
  };
  // signature && setImageSrc(signature.signature[0].url)
  /* ONCHANGE FILES*/
  const handleSelectFile = (e: any) => {
    const SUPPORTED_FORMATS = ["image/png"];
    const file = e.target.files[0] as File;
    const imgUrl = URL.createObjectURL(e.target.files[0]);
    setImageSrc(imgUrl);
    const now = new Date().getTime().toString();
    if (file) {
      if (file.size > Enum_FileSize.maxAllowedSize) {
        setNoValidSize(true);
      } else {
        if (SUPPORTED_FORMATS.includes(file.type)) {
          setNoValid(false);
          setFileNames(() => [
            { url: "#", fileName: now + "_" + file.name, file },
          ]);
          setLabelImage(e.target.files[0].name);
          setIsBtn(true);
        } else {
          setNoValid(true);
        }
      }
    } else {
      setNoValid(false);
      setNoValidSize(false);
      setLabelImage("");
    }
  };

  /* AGREGAR FIRMA */
  const addSignature = async () => {
    setIsLoading(true);
    await dispatch(startUploadSignature(fileNames));
    setIsDb(true);
    setIsLoading(false);
  };

  /* DELETE FILES */
  const handleDeleteFile = async (fileName: string, Db: boolean) => {
    if (isDb || Db) {
      setIsLoading(true);
      await dispatch(startDeleteSignature(fileName));
      setFileNames((prevState) =>
        prevState.filter((fil) => fil.fileName !== fileName)
      );
      setIsLoading(false);
    } else {
      setFileNames((prevState) =>
        prevState.filter((fil) => fil.fileName !== fileName)
      );
    }
    setIsDb(false);
    setIsBtn(false);
    setImageSrc("");
    setLabelImage("");
  };
  return (
    <>
      <Divider variant="fullWidth" />
      <Paper square className={classes.paper}>
        <Box mb={3} ml={3}>
          <Box style={{ height: "5px" }} />
          {isDb ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <label className="form-text" style={{ color: "red" }}>
                    <FormattedMessage id="MsgSignature" />:
                  </label>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <label className="form-text">
                    <FormattedMessage id="Signature" />:
                  </label>

                  <Box ml={2}>
                    <TextField
                      size="small"
                      type="file"
                      inputProps={fileSupported}
                      onChange={handleSelectFile}
                      id="icon-button-file"
                      autoComplete="off"
                      style={{ display: "none" }}
                    />

                    <TextField
                      disabled={true}
                      size="small"
                      variant="outlined"
                      autoComplete="off"
                      style={{ color: "black" }}
                      name="file"
                      value={labelImage}
                      className={classes.myTextFieldRoot}
                    />
                  </Box>
                  <Box mt={1} ml={2}>
                    <Tooltip title={`${intl.formatMessage({ id: "Attach" })}`}>
                      <label htmlFor="icon-button-file">
                        <Button
                          // disabled={readOnly}
                          className={clsx(classes.btnAction, classes.save)}
                          component="span"
                        >
                          <FileCopy /> <FormattedMessage id="Attach" />
                        </Button>
                      </label>
                    </Tooltip>
                  </Box>
                  {isBtn && (
                    <Box mt={1} ml={2}>
                      <Tooltip title={`${intl.formatMessage({ id: "Save" })}`}>
                        <label htmlFor="icon-button-save">
                          <Button
                            className={clsx(classes.btnAction, classes.save)}
                            type="button"
                            onClick={() => addSignature()}
                          >
                            {isLoading ? (
                              <CircularProgress
                                className={classes.btnLoading}
                              />
                            ) : (
                              <FormattedMessage id="Save" />
                            )}
                          </Button>
                        </label>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </Grid>
              {noValid && (
                <Grid item xs={12}>
                  <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                    <FormattedMessage id="ValidFiles" />
                  </Box>
                </Grid>
              )}
              {noValidSize && (
                <Grid item xs={12}>
                  <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                    <FormattedMessage id="ValidDocumentalFilesSize" />
                  </Box>
                </Grid>
              )}
            </Grid>
          )}
          <Box display="flex" mt={1} ml={10} mr={10}>
            {fileNames.map(({ fileName, url }) => {
              return (
                <>
                  <Grid item xs={12} alignItems="center">
                    <img src={imageSrc} width="95%" alt="Front card" />
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title={`${intl.formatMessage({ id: "Delete" })}`}>
                      <IconButton
                        // disabled={readOnly}
                        onClick={() => handleDeleteFile(fileName, url !== "#")}
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </>
              );
            })}
            {successMsg && (
              <MyAlert
                open={successAlert}
                typeAlert="success"
                message={successMsg}
                time={2000}
                handleClose={() => dispatch(uiCloseSuccessAlert())}
              />
            )}
            {errorMsg && (
              <MyAlert
                open={errorAlert}
                typeAlert="error"
                message={errorMsg}
                time={3000}
                handleClose={() => dispatch(uiCloseErrorAlert())}
              />
            )}
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default Signature;

/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import * as yup from "yup";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import {
  IAnnotation,
  IFiles,
  IRecipients,
  IUserAnnotations,
} from "../../../redux/types/types";
import {
  uiCloseModalAdd,
  uiCloseModalEdit,
} from "../../../redux/actions/uiActions";
import { useStyles } from "../../../shared/styles/useStyles";
import { MyTextField } from "../../custom/MyTextField";
import { Delete, FileCopy } from "@material-ui/icons";
import { CustomizedSearch } from "../../custom/CustomizedSearch";

import Card from "@material-ui/core/Card";

import CardContent from "@material-ui/core/CardContent";
import { Colors } from "../../../shared/constants/themes/Colors";
import { firebase } from "../../../config/firebase/firebase-config";

import { startSetAnnotation } from "../../../redux/actions/correspondenceActions";
import { Enum_FileSize } from "../../../enums/enums";
import { v4 as uuid } from "uuid";

interface props {
  edit?: boolean;
}

export const AnnotationsForm: FC<props> = ({ edit = false }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [noValid, setNoValid] = useState(false);
  const [noValidSize, setNoValidSize] = useState(false);
  const [file, setFile] = useState<IFiles | null>(null);
  const { users } = useSelector((state: AppState) => state.users);
  const { user } = useSelector((state: AppState) => state.auth);
  const [listUsers, setListUsers] = useState<IUserAnnotations[]>([...users]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState<IUserAnnotations[]>([]);
  const { activeAnnotation } = useSelector(
    (state: AppState) => state.correspondence
  );
  const intl = useIntl();
  const handleSelectAll = () => {
    setIsSelectedAll(!isSelectedAll);
  };

  useEffect(() => {
    const allUsers = [...listUsers];

    allUsers.forEach((user, index) => {
      allUsers[index].selected = isSelectedAll;
    });

    setListUsers(allUsers);
  }, [isSelectedAll]);

  const validationSchema = yup.object({
    annotation: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    subjectAnnotation: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  // TODO: CREAR INTERFAZ PARA VERSIÓN
  let initialValues: Partial<IAnnotation> = {
    annotation: "",
    subjectAnnotation: "",
  };

  if (activeAnnotation) {
    initialValues = {
      ...activeAnnotation,
    };
  }
  const onClose = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    //dispatch(setActiveTrdVersion(null));
  };
  const handleSelectFile = (e: any) => {
    const SUPPORTED_FORMATS = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/png",
      "application/msword",
      "text/csv",
      "application/pdf",
    ];
    const file = e.target.files[0] as File;
    const now = new Date().getTime().toString();

    if (file) {
      if (file.size > Enum_FileSize.maxAllowedSize) {
        setNoValidSize(true);
      } else {
        if (!SUPPORTED_FORMATS.includes(file.type)) {
          setNoValid(true);
        } else {
          setNoValid(false);
          setFile({
            fileName: now + "_" + e.target.files[0].name,
            url: "#",
            file,
          });
        }
      }
    } else {
      setNoValid(false);
      setNoValidSize(false);
      setFile(null);
    }
  };

  const handleDeleteFile = async (fileName: string, db: boolean) => {
    setFile(null);
  };

  const handleChangeCheck = (idUser: string | number) => {
    const allUsers =
      filteredUsers.length > 0 ? [...filteredUsers] : [...listUsers];
    const indexUser = allUsers.findIndex((user) => user.id === idUser);
    if (indexUser >= 0) {
      allUsers[indexUser].selected = !allUsers[indexUser].selected;
    }
    setListUsers(allUsers);
  };
  const createArrRecipients = () => {
    const selectedUsers =
      filteredUsers.length > 0
        ? filteredUsers.filter((user) => user.selected === true)
        : listUsers.filter((user) => user.selected === true);
    if (selectedUsers.length > 0) {
      const arrLabelName: string[] = [];
      selectedUsers.forEach((user) => {
        arrLabelName.push(user.labelName);
      });
      const castedArr = new Set([...arrLabelName]);
      // @ts-ignore
      return [...castedArr];
    } else {
      return null;
    }
  };
  const handleFiltered = (txt: string) => {
    const allUsers = [...listUsers];
    const newArr = allUsers.filter((user) => {
      return user.labelName.toUpperCase().includes(txt.toUpperCase());
    });
    setFilteredUsers(newArr);
  };

  return (
    <Box>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          const arrRecipientsNames = createArrRecipients();
          if (arrRecipientsNames && user) {
            const finalArrRecipients: IRecipients[] = [];
            const arrStrRecipients: string[] = [];
            const time = firebase.firestore.Timestamp.now();
            arrRecipientsNames.forEach((value) => {
              const userObj = users.find(
                (user: IUserAnnotations) => user.labelName === value
              );
              if (userObj) {
                finalArrRecipients.push({
                  id: userObj.id,
                  recipient: value,
                  submittedDate: time,
                  confirmationDate: null,
                  readedDate: null,
                });
                arrStrRecipients.push(`${userObj.id}`);
              }
            });

            const finalObjAnnotation: IAnnotation = {
              annotation: data.annotation as string,
              subjectAnnotation: data.subjectAnnotation as string,
              recipientsData: finalArrRecipients,
              recipients: arrStrRecipients,
              attachedDocument: file,
              createdAt: time,
              id: uuid() + "_" + Date.now(),
              sender: {
                id: user.id.toString(),
                name:
                  user.firstName +
                  " " +
                  user.secondName +
                  " " +
                  user.firstLastName +
                  " " +
                  user.secondLastName,
              },
              isReaded:
                (!!activeAnnotation && activeAnnotation.isReaded) || false,
            };

            await dispatch(startSetAnnotation(finalObjAnnotation, edit));
          }

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="SubjectAnnotation" />*
                </label>
                <MyTextField
                  name="subjectAnnotation"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  disabled={edit}
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Annotation" />*
                </label>
                <MyTextField
                  name="annotation"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  multiline
                  minRows={4}
                  maxRows={8}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="AttachDocument" />
                </label>
                <TextField
                  size="small"
                  type="file"
                  onChange={handleSelectFile}
                  id="icon-button-file"
                  autoComplete="off"
                  style={{ display: "none" }}
                />

                <MyTextField
                  disabled={true}
                  variant="outlined"
                  style={{ color: "black" }}
                  label={""}
                  value={file ? file.fileName : ""}
                  name="file"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <Box mt={3} ml={-2}>
                  <Tooltip
                    title={`${intl.formatMessage({ id: "SearchDocument" })}`}
                  >
                    <label htmlFor="icon-button-file">
                      <IconButton component="span">
                        <FileCopy />
                      </IconButton>
                    </label>
                  </Tooltip>
                </Box>
              </Grid>
              {noValid && (
                <Grid item xs={12}>
                  <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                    <FormattedMessage id="ValidDocumentalFiles" />
                  </Box>
                </Grid>
              )}
              {noValidSize && (
                <Grid item xs={12}>
                  <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                    <FormattedMessage id="ValidDocumentalFilesSize" />
                  </Box>
                </Grid>
              )}
              {file && (
                <Grid container>
                  <Grid item xs={6}>
                    <Box style={{ fontSize: 12, color: "blue" }} ml={1} mt={1}>
                      <a href={file.url} target="__blank">
                        {file.fileName}
                      </a>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Tooltip title={`${intl.formatMessage({ id: "Delete" })}`}>
                      <IconButton
                        onClick={() =>
                          handleDeleteFile(file.fileName, file.url !== "#")
                        }
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Recipients" />
                </label>
              </Grid>
              <Grid item xs={12}>
                <CustomizedSearch
                  isInModal
                  onInputChange={(e) => {
                    e.target.value.length > 3 && handleFiltered(e.target.value);
                    e.target.value.length === 0 && setFilteredUsers([]);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <label className="form-text">
                      <FormattedMessage id="SelectRecipients" />:
                    </label>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={isSelectedAll}
                        onChange={handleSelectAll}
                        color="primary"
                      />
                      <Box
                        style={{
                          fontSize: 12,
                          color: Colors.LIGTH_PRIMARY,
                        }}
                      >
                        <label>
                          {" "}
                          <FormattedMessage id="SelectAll" />{" "}
                        </label>
                      </Box>
                    </div>

                    {filteredUsers.length > 0
                      ? filteredUsers.map((user) => (
                          <div
                            key={user.id}
                            style={{
                              display: "flex",
                              flexDirection: "row-reverse",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Checkbox
                              checked={user.selected}
                              color="primary"
                              onChange={() => handleChangeCheck(user.id)}
                            />
                            <Box
                              style={{
                                fontSize: 12,
                                color: Colors.LIGTH_PRIMARY,
                              }}
                            >
                              <label>{user.labelName}</label>
                            </Box>
                          </div>
                        ))
                      : listUsers.map((user) => (
                          <div
                            key={user.id}
                            style={{
                              display: "flex",
                              flexDirection: "row-reverse",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Checkbox
                              checked={user.selected}
                              color="primary"
                              onChange={() => handleChangeCheck(user.id)}
                            />
                            <Box
                              style={{
                                fontSize: 12,
                                color: Colors.LIGTH_PRIMARY,
                              }}
                            >
                              <label>{user.labelName}</label>
                            </Box>
                          </div>
                        ))}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>

            <Box mt={2} display="flex" flexDirection="row-reverse">
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="Save" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

import { FC, useEffect, useState, useRef } from "react";
import {
  Box,
  createTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { counter } from "../../../helpers/count";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { FormattedMessage } from "react-intl";
import { Colors } from "../../../shared/constants/themes/Colors";
import { CustomizedTableFooter } from "../../custom/CustomizedTableFooter";
import { DocumentsBody } from "./DocumentsBody";
import {
  IAppendDocs,
  ICorrespondenceResponse,
} from "../../../interfaces/Correspondence";
import { getEmailAnswersCorrespondence } from "../../../services/firebase/documentManagment";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});
interface props {
  documents: IAppendDocs[] | any;
  readonly?: boolean;
  component?: string;
}

export const DocumentsTab: FC<props> = ({
  documents,
  readonly = false,
  component = "OTHER",
}) => {
  const classes = useStyles();
  const { data, value } = useSelector((state: AppState) => state.search);
  const { user } = useSelector((state: AppState) => state.auth);
  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.documentManagment
  );

  const [answers, setAnswers] = useState<ICorrespondenceResponse[]>([]);
  const unSubRef = useRef<any>(null);

  const { corresAnnotations } = useSelector(
    (state: AppState) => state.documentManagment
  );

  const filesAnnotations: any[] = [];
  corresAnnotations.map((cor) => {
    cor.attachedFiles.map((file) => filesAnnotations.push(file));
    return cor;
  });
  // @ts-ignore
  const totalDocuments = documents.concat(filesAnnotations);

  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );
  const count = counter(value, totalDocuments, data);
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, totalDocuments.length - page * rowsPerPage);

  useEffect(() => {
    //traer todas las respuestas con isSendEmailResponse
    const getEmailAnswer = async () => {
      if (user && user.idEntity && activeCorrespondence) {
        unSubRef.current = await getEmailAnswersCorrespondence(
          user.idEntity,
          activeCorrespondence.id,
          (resp: ICorrespondenceResponse[]) => {
            setAnswers(resp);
          }
        );
      }
    };
    getEmailAnswer();

    return () => {
      //CANCELAR SNAPSHOT
      unSubRef.current();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper} style={{ paddingTop: "10px" }}>
        {totalDocuments.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            <FormattedMessage id={"NoCreatedDocuments"} />
          </Box>
        ) : (
          <Table
            className={classes.table}
            size="small"
            style={{ tableLayout: "auto" }}
            aria-label="a dense table"
          >
            <TableHead className={classes.titleTable}>
              <TableRow>
                <TableCell
                  width={"15%"}
                  align="center"
                  className={classes.titleTable}
                >
                  <FormattedMessage id="DocumentName" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="TDRCode" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="DocumentType" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Description" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="User" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Date" />{" "}
                </TableCell>
                {!readonly && (
                  <TableCell
                    width={"20%"}
                    className={classes.titleTable}
                    align="center"
                  >
                    <FormattedMessage id="Actions" />{" "}
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? totalDocuments.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : totalDocuments
              ).map((procedure: any, index: any) => (
                <DocumentsBody
                  key={index}
                  document={procedure}
                  readonly={readonly}
                  component={component}
                  answers={answers}
                />
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 13 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {/* --------- Footer para paginación -------- */}
            <CustomizedTableFooter count={count} />
          </Table>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

import moment from "moment";
import { db, firebase } from "../../config/firebase/firebase-config";
import { IResponseFirebase } from "../../interfaces/firebase";
import { ITrdSubSerie } from "../../redux/types/types";
import {
  ITrdDocumentalType,
  ITrdProductionOffices,
  ITrdSeries,
  ITrdVersion,
  IFiles,
} from "../../redux/types/types";

export const getTrdVersions = async (
  idEntity: string
): Promise<ITrdVersion[]> => {
  const versionsSnap = await db
    .collection("Entities")
    .doc(idEntity)
    .collection("TRD")
    .orderBy("approvalDate", "desc")
    .get();
  const versions: ITrdVersion[] = [];
  versionsSnap.forEach((doc) => {
    versions.push(doc.data() as ITrdVersion);
  });
  return versions;
};
export const setTrdVersion = async (
  idEntity: string,
  version: ITrdVersion
): Promise<IResponseFirebase> => {
  try {
    const docRef = db.collection("Entities").doc(idEntity);

    await docRef
      .collection("TRD")
      .doc(version.idVersion)
      .set(
        {
          ...version,
          approvalDate: (version.approvalDate as firebase.firestore.Timestamp)
            .toDate
            ? version.approvalDate
            : firebase.firestore.Timestamp.fromDate(
                moment(version.approvalDate).toDate()
              ),
          series:
            version.series && version.series.length > 0 ? version.series : null,
        },
        { merge: true }
      );
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};
export const deleteTrdVersion = async (
  idEntity: string,
  idVersion: string
): Promise<IResponseFirebase> => {
  try {
    const docRef = db.collection("Entities").doc(idEntity);
    const snapOp = await docRef.collection("ProductionOffices").limit(1).get();
    if (snapOp.empty) {
      return {
        ok: false,
      };
    }
    await docRef.collection("TRD").doc(idVersion).delete();
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};
export const addOrUpdateSupportDocuments = async (
  idEntity: string,
  idVersion: string,
  docs: IFiles[]
) => {
  try {
    await db
      .collection("Entities")
      .doc(idEntity)
      .collection("TRD")
      .doc(idVersion)
      .set(
        {
          supportDocs: docs.length > 0 ? docs : null,
        },
        {
          merge: true,
        }
      );
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};
export const deleteFileSupportDocument = async (
  idVersion: string,
  fileName: string
): Promise<IResponseFirebase> => {
  try {
    await firebase
      .storage()
      .ref(`/supportDocuments/${idVersion}/${fileName}`)
      .delete();
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

//SERIES
export const getTrdSeries = async (idEntity: string): Promise<ITrdSeries[]> => {
  const snap = await db
    .collection("Entities")
    .doc(idEntity)
    .collection("Series")
    .get();
  let response: ITrdSeries[] = [];
  snap.forEach((doc) => {
    response.push(doc.data() as ITrdSeries);
  });
  return response;
};

export const setTrdSerie = async (
  idEntity: string,
  serie: ITrdSeries,
  seriesArr: any
): Promise<IResponseFirebase> => {
  try {
    const docRef = db.collection("Entities").doc(idEntity);

    await docRef
      .collection("Series")
      .doc(serie.idSerie)
      .set({
        ...serie,
        createAt: firebase.firestore.Timestamp.now(),
      });
    await docRef
      .collection("TRD")
      .doc(serie.idVersion)
      .set(
        {
          series: [...seriesArr],
        },
        { merge: true }
      );
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};
export const deleteTrdSerie = async (
  idEntity: string,
  idSerie: string
): Promise<IResponseFirebase> => {
  try {
    const docRef = db
      .collection("Entities")
      .doc(idEntity)
      .collection("Series")
      .doc(idSerie);
    const subSerieSnap = await docRef.collection("SubSeries").limit(1).get();
    if (subSerieSnap.empty) {
      await docRef.delete();
      return {
        ok: true,
      };
    } else {
      return {
        ok: false,
      };
    }
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// --------------------------------------------------------
// --------------- OFICINAS PRODUCTORAS -------------------
// --------------------------------------------------------

// Cargar oficinas Productoras
export const loadProductionOffices = async (idEntity: string) => {
  const productionOfficesSnap = await db
    .collection(`/Entities/${idEntity}/ProductionOffices`)
    .get();
  const productionOffices: any = [];

  productionOfficesSnap.forEach((snapChild) => {
    productionOffices.push({
      id: snapChild.id,
      ...snapChild.data(),
    });
  });

  return productionOffices;
};

// Agregar oficina productora
export const addOrUpdateProductionOffice = async (
  idEntity: string,
  office: ITrdProductionOffices
) => {
  try {
    await db
      .collection("Entities")
      .doc(idEntity)
      .collection("ProductionOffices")
      .doc(office.idProductionOffice)
      .set(office, { merge: true });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// Actualizar array de oficinas productoras en TRD versión
export const updateArrayProductionOffices = async (
  idEntity: string,
  idVersion: string,
  offices: string[]
) => {
  try {
    await db
      .collection("Entities")
      .doc(idEntity)
      .collection("TRD")
      .doc(idVersion)
      .set(
        {
          productionOffices: offices.length > 0 ? offices : null,
        },
        {
          merge: true,
        }
      );
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const existsProductionOffice = async (
  idEntity: string,
  idVersion: string,
  code: string,
  name: string,
  edit: boolean
) => {
  let offices: any[] = [];
  const refProduction = db
    .collection("Entities")
    .doc(idEntity)
    .collection("ProductionOffices");

  if (!edit) {
    const officesCodeSnap = await refProduction
      .where("idVersion", "==", idVersion)
      .where("code", "==", code)
      .get();
    officesCodeSnap.forEach((snap) => {
      offices.push({
        id: snap.id,
        ...snap.data(),
      });
    });
  }

  const officesNameSnap = await refProduction
    .where("idVersion", "==", idVersion)
    .where("name", "==", name)
    .get();
  officesNameSnap.forEach((snap) => {
    offices.push({
      id: snap.id,
      ...snap.data(),
    });
  });

  return offices;
};

// Eliminar oficina productora
// Eliminar encuestas tranasmitidas
export const deleteProductionOfficeFirebase = async (
  idEntity: string,
  idProductionOffice: string
) => {
  try {
    await db
      .collection("Entities")
      .doc(idEntity)
      .collection("ProductionOffices")
      .doc(idProductionOffice)
      .delete();
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const validateSeriesCreated = async (
  idEntity: string,
  idProductionOffice: string
) => {
  const seriesSnap = await db
    .collection("Entities")
    .doc(idEntity)
    .collection("Series")
    .where("idProductionOffice", "==", idProductionOffice)
    .limit(1)
    .get();
  let series: any[] = [];

  seriesSnap.forEach((snap) => {
    series.push({
      id: snap.id,
      ...snap.data(),
    });
  });

  return series;
};

//DOCUMENTAL TYPES
export const loadTrdDocumentalTypes = async (
  idEntity: string
): Promise<ITrdDocumentalType[] | null> => {
  const snap = await db
    .collection("Entities")
    .doc(idEntity)
    .collection("DocumentalTypes")
    .get();
  if (snap.empty) {
    return null;
  }
  const response: ITrdDocumentalType[] = [];
  snap.forEach((doc) => {
    response.push(doc.data() as ITrdDocumentalType);
  });
  return response;
};
export const setTrdDocumentalType = async (
  idEntity: string,
  documentalType: ITrdDocumentalType,
  documentalArr: any
): Promise<IResponseFirebase> => {
  try {
    const docRef = db.collection("Entities").doc(idEntity);

    await docRef
      .collection("DocumentalTypes")
      .doc(documentalType.idDocumentalType)
      .set({
        ...documentalType,
        createAt: firebase.firestore.Timestamp.now(),
      });
    await docRef
      .collection("TRD")
      .doc(documentalType.idVersion)
      .set(
        {
          documentalType: documentalArr.length > 0 ? documentalArr : null,
        },
        { merge: true }
      );
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const deleteTrdDocumentalType = async (
  idEntiy: string,
  idDocumentalType: string,
  fileName:string,
  idVersion: string,
  documetalTypesToTrd: string[]
): Promise<IResponseFirebase> => {
  try {
    const docRef = db.collection("Entities").doc(idEntiy);
    await docRef.collection("DocumentalTypes").doc(idDocumentalType).delete();
    await firebase
      .storage()
      .ref(`/documentalType/${idVersion}/${fileName}`)
      .delete();
    await docRef
      .collection("TRD")
      .doc(idVersion)
      .set(
        {
          documentalType:
            documetalTypesToTrd.length > 0 ? documetalTypesToTrd : null,
        },
        { merge: true }
      );
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// --------------------------------------------------------
// -------------------- Sub-Series ------------------------
// --------------------------------------------------------

// Cargar Subserie
export const loadSubseries = async (idEntity: string) => {
  const docRef = db.collection("Entities").doc(idEntity).collection("Series");

  const series = await getTrdSeries(idEntity);

  // hacer consulta interna de cada serie
  if (series.length > 0) {
    const subSeriesARR: any = [];
    for (let values of series) {
      let subSeriesSnap = await docRef
        .doc(values.idSerie)
        .collection("SubSeries")
        .get();
      subSeriesSnap.forEach((snapChild) => {
        subSeriesARR.push({
          id: snapChild.id,
          ...snapChild.data(),
        });
      });
    }
    return subSeriesARR;
  }

  return null;
};

// Agregar Sub-serie
export const addOrUpdateSubserie = async (
  idEntity: string,
  idSerie: string,
  subSerie: ITrdSubSerie
) => {
  try {
    await db
      .collection("Entities")
      .doc(idEntity)
      .collection("Series")
      .doc(idSerie)
      .collection("SubSeries")
      .doc(subSerie.idSubserie)
      .set(subSerie, { merge: true });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// Actualizar array de oficinas productoras en TRD versión
export const updateArraySubseries = async (
  idEntity: string,
  idVersion: string,
  subseries: string[]
) => {
  try {
    await db
      .collection("Entities")
      .doc(idEntity)
      .collection("TRD")
      .doc(idVersion)
      .set(
        {
          subseries: subseries.length > 0 ? subseries : null,
        },
        {
          merge: true,
        }
      );
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// validar sub-serie
export const existsSubserie = async (
  idEntity: string,
  idVersion: string,
  idSerie: string,
  code: string,
  name: string,
  edit: boolean
) => {
  let subSerie: any[] = [];
  const refProduction = db
    .collection("Entities")
    .doc(idEntity)
    .collection("Series")
    .doc(idSerie)
    .collection("SubSeries");

  if (!edit) {
    const subSerieCodeSnap = await refProduction
      .where("idVersion", "==", idVersion)
      .where("subSerieCode", "==", code)
      .get();
    subSerieCodeSnap.forEach((snap) => {
      subSerie.push({
        id: snap.id,
        ...snap.data(),
      });
    });
  }

  const subSerieNameSnap = await refProduction
    .where("idVersion", "==", idVersion)
    .where("name", "==", name)
    .get();
  subSerieNameSnap.forEach((snap) => {
    subSerie.push({
      id: snap.id,
      ...snap.data(),
    });
  });

  return subSerie;
};

// Borrar subserie
export const deleteSubserieFirebase = async (
  idEntity: string,
  idSerie: string,
  idSubserie: string
) => {
  try {
    await db
      .collection("Entities")
      .doc(idEntity)
      .collection("Series")
      .doc(idSerie)
      .collection("SubSeries")
      .doc(idSubserie)
      .delete();
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};
import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Delete } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useStyles } from "../../../shared/styles/useStyles";
import { Colors } from "../../../shared/constants/themes/Colors";
import Swal from "sweetalert2";
import {
  changeDepositExpedient,
  startDeleteDeposit,
} from "../../../redux/actions/documentManagmentActions";

export const DepositList = () => {
  const classes = useStyles();

  const { Deposits } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { expedients } = useSelector((state: AppState) => state.fileModule);
  const intl = useIntl();
  const dispatch = useDispatch();

  // SERVICIO ELIMINAR DEPOSITO Y PONER NULL EN EXPEDIENTES QUE LO TENGAN
  const onDeleteDeposit = (id: string, name: string) => {
    // FILTRO PARA TRAER LOS EXPEDIENTES QUE ESTEN ASOCIADOS AL DEPOSITO A ELIMINAR
    const expedientsFilter = expedients.filter(
      (expedient) => expedient.deposit === id
    );
    // SWAL ALERT PARA CONFIRMAR LA ELIMINACION DEL DEPOSITO
    Swal.fire({
      title:
        `${intl.formatMessage({ id: "SureDeleteDeposit" })} ` +
        name +
        `${intl.formatMessage({ id: "WithCode" })} ` +
        id +
        "?",
      text: `${intl.formatMessage({ id: "ReverseAction" })} `,
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        // DISPATCH PARA ELIMINAR EL DEPOSITO Y ACTUALIZAR LOS DEPOSTIOS QUE INCLUIAN ESE DEPOSITO
        dispatch(changeDepositExpedient(expedientsFilter));
        dispatch(startDeleteDeposit(id));
      }
    });
  };

  return (
    <Box>
      {Deposits.length <= 0 && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: 200 }}
          className={classes.title}
        >
          <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
          <FormattedMessage id={"NoCreatedDeposits"} />
        </Box>
      )}
      {Deposits.map(({ createDate, id, name }) => {
        return (
          <Box m={2} key={id}>
            <Grid container>
              <Grid item xs={2}>
                <Box style={{ fontSize: 12, color: "blue" }}>{name}</Box>
              </Grid>
              <Grid item xs={3}>
                <Box mt={-2}>
                  <Tooltip title={`${intl.formatMessage({ id: "Delete" })}`}>
                    <IconButton
                      onClick={() => {
                        onDeleteDeposit(id, name);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Box>
        );
      })}
    </Box>
  );
};

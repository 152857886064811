import moment from "moment";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";
import { useStyles } from "../../../shared/styles/useStyles";
import { IFuid } from "../../../redux/types/types";
import { CustomizedIcons } from "../../custom/CustomizedIcons";
import { AppState } from "../../../redux/reducers/rootReducer";
import Swal from "sweetalert2";
import { Colors } from "../../../shared/constants/themes/Colors";
import { startDeleteFileOfTransfer } from "../../../redux/actions/documentManagmentActions";

interface props {
  expedient: IFuid;
  readonly?: boolean;
}

export const AddFilesTransferBody: FC<props> = ({
  expedient,
  readonly = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { activeTransfer } = useSelector(
    (state: AppState) => state.documentManagment
  );

  const onDelete = (fileName: string) => {
    Swal.fire({
      title: `${intl.formatMessage({
        id: "DeleteFileQuestionSwal",
      })}${fileName}?`,
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: Colors.PRIMARY,
      cancelButtonColor: Colors.DANGER,
      confirmButtonText: `${intl.formatMessage({ id: "Yes" })}`,
      cancelButtonText: `${intl.formatMessage({ id: "No" })}`,
      customClass: {
        container: "swal-container-z-index",
      },
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        await dispatch(startDeleteFileOfTransfer(expedient.id, intl));
      },
    });
  };

  return (
    <TableRow key={expedient.id}>
      <TableCell component="th" scope="row" align="center">
      {expedient.fileName}
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        {expedient.orderNumber}
      </TableCell>
      <TableCell align="center" className={classes.capitalize}>
        {expedient.barCode}
      </TableCell>
      <TableCell align="center" style={{ textTransform: "lowercase" }}>
        {expedient.trdOrTvd}
      </TableCell>

      <TableCell align="center">
        {`${expedient.subSerieName}, ${expedient.serieName}`}
      </TableCell>
      <TableCell align="center">
        {moment(expedient.extremeInitialDate.toDate()).format("YYYY/MM/DD")}
      </TableCell>
      <TableCell align="center">
        {moment(expedient.extremeFinalDate.toDate()).format("YYYY/MM/DD")}
      </TableCell>
      <TableCell align="center">{expedient.volumeNumber}</TableCell>
      <TableCell align="center">{expedient.totalFolderOrVolume}</TableCell>
      <TableCell align="center">{expedient.folder}</TableCell>
      <TableCell align="center">{expedient.other}</TableCell>
      <TableCell align="center">{expedient.invoice}</TableCell>
      <TableCell align="center">
        <FormattedMessage id={expedient.support} />
      </TableCell>
      {(!activeTransfer!.isComplete && activeTransfer?.submitDate === null) && (
        <TableCell align="center">
          <CustomizedIcons
            deleteIcon
            onDelete={() => onDelete(expedient.fileName)}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

import { db, firebase } from "../../config/firebase/firebase-config";
import { TypeDays, TypeUser } from "../../enums/enums";
import { Entity } from "../../interfaces/Users";
import Axios from "axios";
import { firebaseApiResponse } from "../../interfaces/firebase";
import { IInitialSettled } from "../../redux/types/types";

export const getEntities = (): Promise<Entity[] | null> => {
  return db
    .collection("Users")
    .where("rol", "==", TypeUser.ADMIN)
    .get()
    .then((snap) => {
      let response: Entity[] = [];
      snap.forEach((doc) => {
        response.push(doc.data() as Entity);
      });
      return response;
    });
};

export const updateCredentialsEntity = (
  oldEmail: string,
  oldPassword: string,
  newPassword: string
): Promise<firebaseApiResponse | any> => {
  //HACER EL CAMBIO MEDIANTE EL API REST PARA NO GENERAR UNA NUEVA SESION EN LA APP
  return Axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=${process.env.REACT_APP_FIREBASE_SERVER_KEY}`,
    { email: oldEmail, password: oldPassword, returnSecureToken: true }
  ).then((response) => {
    const { data } = response;
    const { idToken } = data as firebaseApiResponse;

    //peticion para cambiar contraseña
    return Axios.post(
      `https://identitytoolkit.googleapis.com/v1/accounts:update?key=${process.env.REACT_APP_FIREBASE_SERVER_KEY}`,
      {
        idToken,
        password: newPassword,
        returnSecureToken: true,
      }
    ).then((resp) => {
      const { data } = resp;
      return data as firebaseApiResponse;
    });
  });
};

export const validateEmailInMailjet = (email: string) => {
  return Axios.post(
    "https://us-central1-modulosmialcaldia.cloudfunctions.net/validateMailInMailjet",
    {
      email: email,
    }
  )
    .then((resp: any) => {
      const { data } = resp;

      return data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const createEntity = (entity: Entity, uid: string): Promise<any> => {
  const {
    id,
    firtsName,
    businessName,
    nit,
    entityType,
    secondName,
    surname,
    secondSurname,
  } = entity;

  const createAt = firebase.firestore.Timestamp.now();
  return db
    .collection("Users")
    .doc(nit)
    .set(
      {
        ...entity,
        active: true,
        rol: TypeUser.ADMIN,
        fullName: `${firtsName?.trim()} ${secondName?.trim()} ${surname?.trim()} ${secondSurname?.trim()}`,
        createAt,
        entityType,
        idEntity: nit,
        uid,
      },
      { merge: true }
    )
    .then(() => {
      //CREAR LA COLECCION DE LOS MUNICIPIOS
      return db
        .collection("Entities")
        .doc(nit)
        .set(
          {
            admin: id,
            businessName,
            nit,
            entityType,
            emailSender: entity.emailSender,
          },
          { merge: true }
        )
        .then(() => {
          //Crear correspondencia con documento tiempos de respuesta
          const docRef = db.collection("Correspondence").doc(nit);

          return docRef
            .set({ idEntity: nit })
            .then(() => {
              return docRef
                .collection("ParamResponseTime")
                .doc(nit)
                .set(
                  {
                    petitionRightsDays: 1,
                    petitionRightsTypeDays: TypeDays.CALENDAR,
                    complaintsDays: 1,
                    complaintsTypeDays: TypeDays.CALENDAR,
                    claimDays: 1,
                    claimTypeDays: TypeDays.CALENDAR,
                    suggestionsDays: 1,
                    suggestionsTypeDays: TypeDays.CALENDAR,
                    guardianshipDays: 1,
                    guardianshipTypeDays: TypeDays.CALENDAR,
                    informationRequestDays: 1,
                    informationRequestTypeDays: TypeDays.CALENDAR,
                    questionDays: 1,
                    questionTypeDays: TypeDays.CALENDAR,
                  },
                  { merge: true }
                )
                .then(() => {
                  //Crear documento de FileModule
                  const docRef = db.collection("FileModule").doc(nit);

                  return docRef.set({ idEntity: nit }).then(() => {
                    return docRef
                      .collection("Parameterization")
                      .doc(nit)
                      .set({ createAt }, { merge: true });
                  });
                });
            })
            .then(() => {
              return {
                ok: true,
              };
            });
        });
    });
};

export const updateEntity = (entity: Entity, uid: string): Promise<any> => {
  const {
    id,

    businessName,

    nit,

    entityType,
  } = entity;
  return db
    .collection("Users")
    .doc(nit)
    .set(
      {
        ...entity,
        idEntity: nit,
        uid,
      },
      { merge: true }
    )
    .then(() => {
      //CREAR LA COLECCION DE LOS MUNICIPIOS
      return db
        .collection("Entities")
        .doc(nit)
        .set(
          {
            admin: id,
            businessName,
            nit,
            entityType,
          },
          { merge: true }
        )
        .then(() => {
          return {
            ok: true,
          };
        });
    });
};

export const getEntity = (nit: string): Promise<any> => {
  return db
    .collection("Users")
    .where("rol", "==", TypeUser.ADMIN)
    .where("nit", "==", nit)
    .limit(1)
    .get()
    .then((snapShot) => {
      let users: any;
      snapShot.forEach((doc: any) => {
        users = {
          id: doc.id,
          ...(doc.data() as any),
        };
      });

      return users;
    })
    .catch((err) => console.log(err));
};
export const getEntityById = (id: string): Promise<any> => {
  return db
    .collection("Users")
    .where("rol", "==", TypeUser.ADMIN)
    .where("idEntity", "==", id)
    .limit(1)
    .get()
    .then((snapShot) => {
      let users: any;
      snapShot.forEach((doc: any) => {
        users = {
          id: doc.id,
          ...(doc.data() as any),
        };
      });

      return users;
    })
    .catch((err) => console.log(err));
};

export const getDataFromEntities = (id: string): Promise<any> => {
  return db
    .collection("Entities")
    .where("nit", "==", id)
    .limit(1)
    .get()
    .then((snapshot) => {
      let entity: any;
      snapshot.forEach((doc: any) => {
        entity = {
          admin: doc.admin,
          ...(doc.data() as any),
        };
      });
      return entity;
    })
    .catch((err) => console.log(err));
};

/* CARGAR PARAMETRIZACION INITIAL SETTLED A ENTITY */
export const loadInitialSettledDb = async (idEntity: string) => {
  const param = await db.collection("Entities").doc(idEntity).get();
  return (param.data() as any).initialSettled;
};

/* AGREGAR SUBCOLLECTION SIGNATURE */
export const addInitialSettledDb = async (
  idEntity: string,
  initialSettled: IInitialSettled
) => {
  try {
    await db
      .collection("Entities")
      .doc(idEntity)
      .set(
        {
          ...initialSettled,
        },
        { merge: true }
      );
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

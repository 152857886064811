import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { Autocomplete } from "@material-ui/lab";
import { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useStyles } from "../../../shared/styles/useStyles";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  uiCloseModalAction,
  setErrorMsg,
  uiCloseErrorAlert,
  uiOpenErrorAlert,
} from "../../../redux/actions/uiActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import * as yup from "yup";
import { IDocumentsFuid, IFuid } from "../../../redux/types/types";
import {
  ICorrespondence,
  IExpedientCorrespondence,
} from "../../../interfaces/Correspondence";
import { editCorrespondenceFuid } from "../../../redux/actions/fuidActions";
import { MyAlert } from "../../custom/MyAlert";
import firebase from "firebase";
import { Colors } from "../../../shared/constants/themes/Colors";
// import { uiOpenErrorAlert } from '../../../redux/actions/uiActions';
import Swal from "sweetalert2";
interface props {
  correspondence: ICorrespondence;
}

export const AssignFileForm: FC<props> = ({ correspondence }) => {
  const [expedientSelected, setExpedientSelected] = useState<any | null>(null);
  const [errorExpedient, setErrorExpedient] = useState(false);
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { errorAlert, errorMsg } = useSelector((state: AppState) => state.ui);
  const [noValid, setNoValid] = useState(false);

  const { expedients } = useSelector((state: AppState) => state.documentManagment);
  const { user } = useSelector((state: AppState) => state.auth);

  const initialValues: any = {
    expedients: "",
  };

  const validationSchema = yup.object({});

  const handleSelectExpedient = (value: any | null) => {
    value ? setErrorExpedient(false) : setErrorExpedient(true);

    setExpedientSelected(value);
  };
  const onClose = () => {
    dispatch(uiCloseModalAction());
  };

  const closeErrorAndSuccessAlert = () => {
    dispatch(uiCloseErrorAlert());
    dispatch(setErrorMsg(null));
  };

  return (
    <Box m={2} mt={-1} mb={2}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          // todos los expedientes almacenado en bd de la correspondencia activa
          if(correspondence.expedients?.length! > 0){
            Swal.fire({
              title:
                `${intl.formatMessage({ id: "FileFound" })} `,
              text: `${intl.formatMessage({ id: "AssignedFileMsg" })} `,
              icon: "warning",
              showConfirmButton: true,
              confirmButtonColor: Colors.PRIMARY,
              confirmButtonText: "Ok",
              customClass: {
                container: "swal-container-z-index",
              },
            })
          } else {

          // validaciones 
          const expedientOwner = expedientSelected.owner.id;
          const civilServant = user?.id.toString();
          // 1. El propietario del expediente debe ser el único en asignar la correspondencia.
          if( civilServant !== expedientOwner ){
            dispatch(setErrorMsg("UserIsNotTheOwnwerFIle"));
            dispatch( uiOpenErrorAlert() );
            return;
          // 2. Si la correspondencia ya tiene un expediente no se le deben asignar mas.
          }else if(correspondence.expedients?.length! > 0){
            dispatch(setErrorMsg("ThereAreExpedients"));
            dispatch( uiOpenErrorAlert() );
            return;
          }
          
          // todos los expedientes almacenado en bd de la correspondencia activa
          const lastExpedients = correspondence.expedients;
          //agregar el expediene a el value del formulario
          values.expedients = expedientSelected as IFuid;
          //crear nuevo objeto de expediente que se enviara a la bd
          const expedientObj: IExpedientCorrespondence = {
            id: values.expedients.id,
            name: values.expedients.fileName,
            description: values.expedients.description,
          };

          const isAdded = lastExpedients
            ? lastExpedients.filter((item) => item.id === values.expedients.id)
            : [];

          if (isAdded.length === 0) {
            setNoValid(false);
            //condicional si hay o no expedientes en la bd para asi crear uno nuevo o enviar al ya exisistente
            const expedients: IExpedientCorrespondence[] = lastExpedients
              ? [...lastExpedients, expedientObj]
              : [expedientObj];

            // EXPEDIENTE SELECCIONADO COMO IFUID
            const fuidSelected = expedientSelected as IFuid;

            // CICLO PARA AGREGAR LOS APPENDDOCS Y DIGITALSUPPORT DE CORRESPONDENCE A EXPEDIENTES
            let documentsExpedientParse: IDocumentsFuid[] = [];
            if (correspondence.appendDocs) {
              // OBJETO PARA AGREGAR LOS DOCUMENTOS A LOS EXPEDIENTES
              for (const document of correspondence.appendDocs) {
                const newObjDocumentExpedient: IDocumentsFuid = {
                  url: document.url,
                  fileName: document.filename,
                  documentalType: document.documentalType,
                  idCorrespondence: correspondence.id,
                  uploadDate: firebase.firestore.Timestamp.now(),
                  officer: document.officer,
                };
                if (document.documentalType) {
                  documentsExpedientParse.push(newObjDocumentExpedient);
                }
              }
              for (const document of correspondence.digitalSupport) {
                // OBJETO PARA AGREGAR LOS DOCUMENTOS A LOS EXPEDIENTES
                const newObjDocumentExpedient: IDocumentsFuid = {
                  url: document.url,
                  fileName: document.fileName,
                  documentalType: document.documentalType,
                  idCorrespondence: correspondence.id,
                  uploadDate: firebase.firestore.Timestamp.now(),
                  officer: correspondence.externalSender,
                };
                if (document.documentalType) {
                  documentsExpedientParse.push(newObjDocumentExpedient);
                }
              }
            }

            // AGREGAR LOS DOCUMENTOS A EL EXPEDIENTE SELECCIONADO
            fuidSelected.documents = fuidSelected.documents
              ? fuidSelected.documents.concat(documentsExpedientParse)
              : documentsExpedientParse;

            // AGREGAR A LA CORRESPONDENCIA EL EXPEDIENTE SELECCIONADO
             correspondence.expedients = expedients; 
            await dispatch(
              editCorrespondenceFuid(correspondence, fuidSelected)
            );
            dispatch(uiCloseModalAction());
          } else {
            setNoValid(true);
          }
        }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.input}>
            <Typography
              variant="body2"
              style={{ fontSize: 14, marginBottom: 18 }}
            >
              <FormattedMessage id="AsingExpedientMsg" />
            </Typography>
            <label className="form-text">
              <FormattedMessage id="SelectExpedient" />:
            </label>
            <Autocomplete
              id="expedient"
              options={expedients}
              disableCloseOnSelect
              value={expedientSelected}
              getOptionLabel={(option) => option.fileName}
              getOptionSelected={(option, value) => option.id === value.id}
              renderOption={(option) => <>{option.fileName}</>}
              onChange={(event: any, value: IFuid | null) =>
                handleSelectExpedient(value)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  name="expedient"
                  className={classes.myTextFieldRoot}
                  helperText={
                    errorExpedient &&
                    `${intl.formatMessage({ id: "RequiredFile" })}`
                  }
                  error={errorExpedient}
                  FormHelperTextProps={{
                    className: classes.helperText,
                  }}
                />
              )}
            />
            {noValid && (
              <Box mt={2} ml={2} style={{ fontSize: 12, color: "red" }}>
                <FormattedMessage id="ExpedientFound" />
              </Box>
            )}
            <Box mt={2} display="flex" flexDirection="row-reverse" width={1}>
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="Save" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      {errorMsg && (
        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={errorMsg}
          time={2000}
          handleClose={closeErrorAndSuccessAlert}
        />
      )}
    </Box>
  );
};

export const Colors = {
  PRIMARY: "#37307Dff",
  DARK_PRIMARY: "#3D1A7Bff",
  LIGTH_PRIMARY: "#C9CFE4ff",
  TEXT_ICONS: "#FBFFF1",
  ACCENT: "#24D7A8ff",
  LIGTH_ACCENT: "#94ACC0ff",
  PRIMARY_TEXT: "#212121",
  SECONDARY_TEXT: "#757575",
  DIVIDER_COLOR: "#BDBDBD",
  DARK_BUTTON: "rgb(63, 81, 181)",
  GREY_LIGHT: "#fafafa",
  DANGER: "#BF2F42",
  BLACK: "#313541",
  LIGHT_SKY_BLUE: "#58AAE1",
  MEDIUM_SEA_GREEN: "#22A877",
  ROYAL_BLUE: "#3B76CB",
  STEEL_BLUE: "#3366cc",
  MEDIUM_PURPLE: "#9585F4",
  SALMON: "#F06B66",
  DARK_GOLDEN_ROD: "#98712D",
  INDIANRED: "#CB4A49",
};

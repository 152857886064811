import { Types } from "../types/types";

export const setPage = (page: number) => ({
  type: Types.setPage,
  payload: page,
});

export const setRowsPerPage = (rows: number) => ({
  type: Types.setRowsPerPage,
  payload: rows,
});

import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { TableCell, TableRow } from "@material-ui/core";
import { CustomizedIcons } from "../../../components/custom/CustomizedIcons";
import { useStyles } from "../../../shared/styles/useStyles";
import {
  uiOpenModalAnswerBroadcast,
  uiOpenModalAnswerBroadcastEdit,
  uiOpenModalAnswer,
  uiSetOpenAnswerModal,
  uiOpenModalEmailStatusHistory,
  uiOpenModalEmailSent,
} from "../../../redux/actions/uiActions";
import { ICorrespondenceResponse } from "../../../interfaces/Correspondence";
import { ActionSettled, CorrespondenceInputType } from "../../../enums/enums";
import { setActiveAnswer } from "../../../redux/actions/documentManagmentActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { checkEmailStatus } from "../../../utils/utils";

interface props {
  answer: ICorrespondenceResponse;
  readonly?: boolean;
  component?: string;
}

export const AnswerBody: FC<props> = ({
  answer,
  readonly = false,
  component = "OTHER",
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const ActionSettledArr = [
    ActionSettled.APPROVED,
    ActionSettled.ANNOTATION,
    ActionSettled.ASSIGN_TO_FILE,
    ActionSettled.ISSUANCE_OF_RESPONSE,
    ActionSettled.ISSUANCE_VO_BO,
    ActionSettled.REQUEST_ADDITIONAL_INFORMATION,
    ActionSettled.REQUEST_FOR_PROJECTION,
    ActionSettled.VO_BO_APPROVED,
    ActionSettled.VO_BO_REJECTED,
  ];
  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.documentManagment
  );

  const onSee = () => {
    dispatch(setActiveAnswer(answer));
    if (answer.isSendEmailResponse) {
      return dispatch(uiSetOpenAnswerModal());
    }
    dispatch(uiOpenModalAnswerBroadcastEdit());
  };

  const onHistory = () => {
    dispatch(setActiveAnswer(answer));
    dispatch(uiOpenModalEmailStatusHistory());
  };

  const onEmailSent = () => {
    dispatch(setActiveAnswer(answer));
    dispatch(uiOpenModalEmailSent());
  };

  const onSend = () => {
    dispatch(setActiveAnswer(answer));

    dispatch(uiOpenModalAnswer());
  };

  const onEditAnswer = () => {
    dispatch(setActiveAnswer(answer));
    dispatch(uiOpenModalAnswerBroadcast());
  };

  const emailStatusClass = (status: string = "") => {
    let statusClasses = "";

    if (checkEmailStatus(status)) {
      const styleClasses = classes as unknown as any;
      statusClasses = styleClasses[`${status}BackgroundColor`];
    }

    return `${classes["statusEmail"]} ${statusClasses}`;
  };

  const getStatusMessage = (status: string = "") => {
    if (checkEmailStatus(status)) {
      return intl.formatMessage({ id: `${status}Status` });
    }

    return status;
  };

  const EmailStatus = () => {
    return (
      <div className={classes.emailStatusBody}>
        <div className={classes["tableStatusEmail"]}>
          <div>{answer.emailData?.addressee.email}</div>
          <div>
            {answer.status && answer.status.length > 0 && (
              <span
                className={emailStatusClass(
                  answer.status[answer.status.length - 1].status
                )}
              >
                {getStatusMessage(
                  answer.status[answer.status.length - 1].status
                )}
              </span>
            )}
          </div>
        </div>
        {answer.emailDataCopy?.map((element, i) => {
          return (
            <div key={i} className={classes.mt1}>
              <div className={classes["tableStatusEmail"]}>
                <div>{element.email}</div>
                <div>
                  {element.status && element.status.length > 0 && (
                    <span
                      className={emailStatusClass(
                        element.status[element.status.length - 1].status
                      )}
                    >
                      {getStatusMessage(
                        element.status[element.status.length - 1].status
                      )}
                    </span>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <TableRow
      key={answer.officer.id + "-" + answer.submitDate.toDate().getTime()}
    >
      <TableCell component="th" scope="row" align="center">
        {ActionSettledArr.includes(answer.subject as any) ? (
          <FormattedMessage id={answer.subject} />
        ) : (
          answer.subject
        )}
      </TableCell>
      <TableCell align="center" style={{ textTransform: "lowercase" }}>
        {answer.settled ? answer.settled : ""}

        {answer.status &&
        activeCorrespondence?.inputType === CorrespondenceInputType.EP ? (
          <EmailStatus></EmailStatus>
        ) : (
          <></>
        )}
      </TableCell>
      <TableCell align="center" className={classes.capitalize}>
        {moment(answer.submitDate.toDate()).format("YYYY/MM/DD")}
      </TableCell>
      <TableCell align="center">{answer.officer.name}</TableCell>
      <TableCell align="center">
        {!readonly &&
        answer.isScreeningAnswer &&
        activeCorrespondence &&
        !activeCorrespondence.answer &&
        component !== "COPY" ? (
          <>
            {component !== "VoBo" &&
              component !== "PROJECTION" &&
              answer.subject !== ActionSettled.VO_BO_APPROVED &&
              answer.subject !== ActionSettled.VO_BO_REJECTED && (
                <CustomizedIcons
                  sendIcon
                  editIcon
                  onSend={onSend}
                  onEdit={onEditAnswer}
                />
              )}
          </>
        ) : (
          <>
            {answer.subject === ActionSettled.APPROVED ||
            answer.subject === ActionSettled.VO_BO_REJECTED ||
            answer.subject === ActionSettled.VO_BO_APPROVED ? (
              <></>
            ) : (
              <>
                <CustomizedIcons seeIcon onSee={onSee} />
                {activeCorrespondence?.inputType ===
                  CorrespondenceInputType.EP && (
                  <>
                    <CustomizedIcons emailIcon onEmailSent={onEmailSent} />
                    <CustomizedIcons historyIcon onHistory={onHistory} />
                  </>
                )}
              </>
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import {
  createTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import { useStyles } from "../../../shared/styles/useStyles";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useEffect } from "react";
import { TrafficLight } from "../pqrs/TrafficLight";
interface Props {
  answers: any;
}

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: "4px 12px 4px 12px",
      },
    },
  },
});

export const ResponseReportTable = ({ answers }: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const { paramResponseTime }: any = useSelector(
    (state: AppState) => state.correspondence
  );

  useEffect(() => {
    if (answers.length > 0) {
      answers.map((row: any) => {
        /**
         * Obtener fecha limite respuesta
         */
        let responseDateParam: any = null;
        let daysAvailableDocument: any = null;
        let availableDays = null;
        if (row.communicationType?.isParam || row.responseTimeDays) {
          const paramNameDays = row.communicationType?.option + "Days";
          daysAvailableDocument =
            row && row.responseTimeDays
              ? row.responseTimeDays
              : paramResponseTime[paramNameDays];
          const typeDays =
            paramResponseTime[row.communicationType!.option + "TypeDays"];
          responseDateParam =
            typeDays === "BUSINESSDAYS"
              ? moment(row.createAt.toDate()).businessAdd(
                  daysAvailableDocument,
                  "days"
                )
              : moment(row.createAt.toDate()).add(
                  daysAvailableDocument,
                  "days"
                );
        }
        /**
         * Dias disponibles para responder
         */
        const dateCurrent = moment();
        const minute = 1000 * 60;
        const hour = minute * 60;
        const day = hour * 24;
        availableDays = Math.floor(
          moment(responseDateParam).diff(dateCurrent) / day
        );
        row.responseDateParam = responseDateParam
          ? responseDateParam.format("YYYY/MM/DD")
          : null;
        row.availableDays = availableDays > 0 ? availableDays : 0;
        return row;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers]);

  return (
    <ThemeProvider theme={theme}>
      <TableContainer>
        <Table aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableBorder} align="center">
                # {`${intl.formatMessage({ id: "Settled" }).toUpperCase()} `}
              </TableCell>
              <TableCell className={classes.tableBorder} align="left">
                T.C
              </TableCell>
              <TableCell className={classes.tableBorder} align="left">
                {`${intl.formatMessage({ id: "Subject" }).toUpperCase()} `}
              </TableCell>
              <TableCell className={classes.tableBorder} align="left">
                {`${intl.formatMessage({ id: "Sender" }).toUpperCase()} `}
              </TableCell>
              <TableCell className={classes.tableBorder} align="left">
                {`${intl.formatMessage({ id: "Addressee" }).toUpperCase()} `}
              </TableCell>
              <TableCell className={classes.tableBorder} align="left">
                {`${intl.formatMessage({ id: "FilingDate" }).toUpperCase()} `}
              </TableCell>
              <TableCell className={classes.tableBorder} align="left">
                {`${intl
                  .formatMessage({ id: "DateOfResponse" })
                  .toUpperCase()} `}
              </TableCell>
              <TableCell className={classes.tableBorder} align="left">
                {`${intl
                  .formatMessage({ id: "ResponseStatus" })
                  .toUpperCase()} `}
              </TableCell>
              <TableCell className={classes.tableBorder} align="left">
                {`${intl
                  .formatMessage({ id: "ResponseStatus" })
                  .toUpperCase()} `}
              </TableCell>
              <TableCell className={classes.tableBorder} align="left">
                {`${intl
                  .formatMessage({ id: "SettledResponse" })
                  .toUpperCase()} `}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {answers.map((row: any) => (
              <TableRow key={row.id}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.tableBorder}
                  width={"5%"}
                >
                  <div className={classes.textTable}>{row.id}</div>
                </TableCell>

                <TableCell
                  className={classes.tableBorder}
                  align="left"
                  width={"10%"}
                >
                  {row.communicationType.responseTime === 0 ||
                  !row.communicationType.responseTime
                    ? `${intl.formatMessage({
                        id: `${row.communicationType.option}`,
                      })}`
                    : `${row.communicationType.option}`}
                </TableCell>
                <TableCell
                  className={classes.tableBorder}
                  align="left"
                  width={"30%"}
                >
                  <div className={classes.textTable}>{row.subject}</div>
                </TableCell>
                <TableCell
                  className={classes.tableBorder}
                  align="left"
                  width={"10%"}
                >
                  {row.externalSender.name}
                </TableCell>
                <TableCell
                  className={classes.tableBorder}
                  align="left"
                  width={"10%"}
                >
                  {row.addressee.name}
                </TableCell>
                <TableCell className={classes.tableBorder} align="left">
                  {moment(row.createAt.toDate()).format("YYYY/MM/DD")}
                </TableCell>
                <TableCell className={classes.tableBorder} align="center">
                  {row.responseDateParam
                    ? row.responseDateParam +
                      " " +
                      `(${row.availableDays} dias restantes)`
                    : ""}
                </TableCell>
                <TableCell className={classes.tableBorder} align="left">
                  {row.internalState && (
                    <FormattedMessage id={`${row.internalState}`} />
                  )}
                </TableCell>
                <TableCell className={classes.tableBorder} align="left">
                  {(row.communicationType?.isParam || row.responseTimeDays) && (
                    <TrafficLight
                      paramResponse={row.communicationType}
                      answer={{
                        answer: row.answer,
                        answerDate: row.answeredDate,
                        createDate: row.createAt,
                      }}
                      correspondence={row}
                    />
                  )}
                </TableCell>
                <TableCell className={classes.tableBorder} align="left">
                  <div className={classes.textTable}>
                    {row.dataAnswer
                      ? `${row.dataAnswer.id} -
                        ${moment(
                          new Date(row.dataAnswer.createAt.seconds * 1000)
                        ).format("YYYY/MM/DD - HH:mm:ss")}`
                      : ""}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

import List from '@material-ui/core/List';
import VerticalItem, { VerticalItemProps } from './vertical-item/VerticalItem';

const Navigation: React.FC<VerticalItemProps> = ({listItems}) => {
    return (
        <List>
            <VerticalItem listItems={listItems} />
        </List>
    );
};

export default Navigation;
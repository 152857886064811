import { Redirect, Route, Switch } from "react-router";
import { ModulesType } from "../enums/enums";
import { ReportsScreen } from "../pages/correspondenceModule/ReportsScreen";
import { CorrespondenceScreen } from "../pages/correspondenceModule/CorrespondenceScreen";
import { AnnotationsScreen } from "../pages/correspondenceModule/AnnotationsScreen";
import { ParameterizationScreen } from "../pages/correspondenceModule/ParameterizationScreen";
import { PqrsScreen } from "../pages/correspondenceModule/PqrsScreen";
import { TendersScreen } from "../pages/correspondenceModule/TendersScreen";
import { ProcedureCorrespondenceScreen } from "../pages/correspondenceModule/ProcedureCorrespondenceScreen";
import { EntitiesControlCorrespondenceScreen } from "../pages/correspondenceModule/EntitiesControlCorrespondenceScreen";
import { OthersCorrespondenceScreen } from "../pages/correspondenceModule/OthersCorrespondenceScreen";
import { ReturnedScreen } from "../pages/correspondenceModule/ReturnedScreen";

export const CorrespondenceRouter = () => {
  return (
    <div>
      <Switch>
        <Route
          path={`/${ModulesType.Correspondence.toLowerCase()}/reports`}
          component={ReportsScreen}
        />
        <Route
          path={`/${ModulesType.Correspondence.toLowerCase()}/correspondence`}
          component={CorrespondenceScreen}
        />
        <Route
          path={`/${ModulesType.Correspondence.toLowerCase()}/pqrs`}
          component={PqrsScreen}
        />
        <Route
          path={`/${ModulesType.Correspondence.toLowerCase()}/procedures`}
          component={ProcedureCorrespondenceScreen}
        />
        <Route
          path={`/${ModulesType.Correspondence.toLowerCase()}/entities-control`}
          component={EntitiesControlCorrespondenceScreen}
        />
        <Route
          path={`/${ModulesType.Correspondence.toLowerCase()}/others`}
          component={OthersCorrespondenceScreen}
        />
        <Route
          path={`/${ModulesType.Correspondence.toLowerCase()}/returned`}
          component={ReturnedScreen}
        />
        <Route
          path={`/${ModulesType.Correspondence.toLowerCase()}/tenders`}
          component={TendersScreen}
        />
        <Route
          path={`/${ModulesType.Correspondence.toLowerCase()}/annotationsSend`}
          component={AnnotationsScreen}
        />
        <Route
          path={`/${ModulesType.Correspondence.toLowerCase()}/annotationsReceived`}
          component={AnnotationsScreen}
        />
        <Route
          path={`/${ModulesType.Correspondence.toLowerCase()}/parameterization`}
          component={ParameterizationScreen}
        />
        {/* --------------- DEMAS RUTAS -------------- */}
        <Redirect to={`/${ModulesType.Correspondence.toLowerCase()}/reports`} />
      </Switch>
    </div>
  );
};

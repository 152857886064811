import { firebase } from "../../config/firebase/firebase-config";
import moment from "moment";
import { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import { filterCorrespondence } from "../../helpers/filteredCorrespondence";
import {
  loadCorrespondenceManagment,
  setActiveCorrespondenceDocManagment,
} from "../../redux/actions/documentManagmentActions";
import { AppState } from "../../redux/reducers/rootReducer";
import { useStyles } from "../../shared/styles/useStyles";
import { Box, Button, Grid } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import {
  uiCloseErrorAlert,
  uiCloseModalAdd,
  uiCloseModalDelete,
  uiCloseModalEdit,
  uiCloseSuccessAlert,
  uiOpenModalAdd,
} from "../../redux/actions/uiActions";
import { CustomizedSearch } from "../../components/custom/CustomizedSearch";
import { CorrespondenceTable } from "../../components/correspondence-module/correspondence/CorrespondenceTable";
import CustomizedDialog from "../../components/custom/CustomizedDialog";
import { CorrespondenceForm } from "../../components/correspondence-module/correspondence/CorrespondenceForm";
import { MyAlert } from "../../components/custom/MyAlert";
import { setActiveCorrespondence } from "../../redux/actions/correspondenceActions";
import { CustomizedWindow } from "../../components/custom/CustomizedWindow";
import { SettledDetails } from "../../components/document-managment/correspondence/SettledDetails";
import { uiCloseModalSee } from "../../redux/actions/uiActions";

export const PqrsManagmentScreen = () => {
  const classes = useStyles();
  const { modalAddOpen, modalSeeOpen, successMsg, successAlert, errorAlert } =
    useSelector((state: AppState) => state.ui);
  const { correspondence } = useSelector(
    (state: AppState) => state.correspondence
  );
  const { activeCorrespondence,correspondence:CorespondenceManag } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { user } = useSelector((state: AppState) => state.auth);

  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    if (user) {
      const filteredCorrespondence = correspondence.filter((cor) =>
        filterCorrespondence(cor, user.id.toString())
      );
      dispatch(loadCorrespondenceManagment(filteredCorrespondence));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correspondence]);

  const onDeny = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    dispatch(uiCloseModalDelete());
    dispatch(uiCloseModalSee());
    dispatch(setActiveCorrespondence(null));
    dispatch(setActiveCorrespondenceDocManagment(null));
  };

  const date =
    activeCorrespondence?.createAt &&
    moment(
      (activeCorrespondence.createAt as firebase.firestore.Timestamp).toDate()
    ).format("YYYY/MM/DD - h:mm:ss");

  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box>
        <Box mb={3} fontSize={20}>
          <Grid container>
            <Grid item xs={5}>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                className={classes.btnAdd}
                type="button"
                onClick={() => dispatch(uiOpenModalAdd())}
              >
                <AddOutlinedIcon />
                <FormattedMessage id="AddNewCommunication" />
              </Button>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <CustomizedSearch data={CorespondenceManag} />
            </Grid>
          </Grid>
        </Box>

        <CorrespondenceTable readOnly pqrs />
        <CustomizedDialog
          open={modalAddOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "NewCommunication" })}`}
          content={<CorrespondenceForm isDocManagment />}
          textButton="Accept"
        />

        <CustomizedWindow
          open={modalSeeOpen}
          content={<SettledDetails component="OTHER" />}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "Settled" })} No. ${
            activeCorrespondence?.id
          } -  ${date}`}
        />
        {successMsg && (
          <MyAlert
            open={successAlert}
            typeAlert="success"
            message={successMsg}
            time={2000}
            handleClose={() => dispatch(uiCloseSuccessAlert())}
          />
        )}

        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={"SavingDataError"}
          time={2000}
          handleClose={() => dispatch(uiCloseErrorAlert())}
        />
      </Box>
    </AppAnimate>
  );
};

import moment from "moment";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { TableCell, TableRow } from "@material-ui/core";
import { ActionSettled } from "../../../enums/enums";

interface props {
  settle: any;
  readonly?: boolean;
}

export const RecordBody: FC<props> = ({ settle, readonly = false }) => {
  const intl = useIntl();
  return (
    <TableRow>
      <TableCell component="th" scope="row" align="left">
        {settle.processName === ActionSettled.APPROVED ? (
          <>
            <FormattedMessage id={ActionSettled.APPROVED} />{" "}
            {intl.formatMessage({ id: "By" })} {settle.owner.name}{" "}
          </>
        ) : settle.processName === ActionSettled.VO_BO_APPROVED ? (
          <>
            <FormattedMessage id={"VoBoApproved"} />{" "}
            {intl.formatMessage({ id: "By" })} {settle.owner.name}{" "}
          </>
        ) : settle.processName === ActionSettled.VO_BO_REJECTED ? (
          <>
            <FormattedMessage id={"ApprovalRejected"} />{" "}
            {intl.formatMessage({ id: "By" })} {settle.owner.name}{" "}
          </>
        ) : (
          <>
            {settle.processName} {intl.formatMessage({ id: "By" })}{" "}
            {settle.owner.name}
          </>
        )}
      </TableCell>
      <TableCell align="center">
        {moment(settle.createAt.toDate()).format("YYYY-MM-DD hh:mm a")}
      </TableCell>
    </TableRow>
  );
};

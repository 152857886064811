import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  Divider,
  Paper,
  Box,
  Grid,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { useStyles } from "../../../../shared/styles/useStyles";
import {
  uiOpenModalAdd,
  uiCloseModalAdd,
  uiCloseSuccessAlert,
  uiCloseModalEdit,
  uiCloseModalDelete,
  uiCloseErrorAlert,
} from "../../../../redux/actions/uiActions";
import { CustomizedSearch } from "../../../custom/CustomizedSearch";
import CustomizedDialog from "../../../custom/CustomizedDialog";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { SeriesForm } from "./SeriesForm";
import { SeriesTable } from "./SeriesTable";
import { MyAlert } from "../../../custom/MyAlert";
import { setActiveSerie } from "../../../../redux/actions/trdActions";
import { ITrdSeries } from "../../../../redux/types/types";
import { ConfirmDelete } from "../../../ui/shared/ConfirmDelete";

export const SeriesTab = () => {
  const { versions, series, productionOffices } = useSelector(
    (state: AppState) => state.trd
  );
  const [seriesFiltered, setSeriesFiltered] = useState<ITrdSeries[]>([]);
  const [versionSelected, setVersionSelected] = useState("0");
  const [productionOfficeSelected, setProductionOfficeSelected] = useState("0");
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    modalAddOpen,
    successAlert,
    errorAlert,
    successMsg,
    modalEditOpen,
    modalDeleteOpen,
  } = useSelector((state: AppState) => state.ui);

  const handleSelectVersion = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const allSeries = [...series];
    let filterResp: ITrdSeries[] = [];

    setVersionSelected(event.target.value as string);
    if (event.target.value !== "0") {
      if (productionOfficeSelected !== "0") {
        filterResp = allSeries.filter(
          (serie) =>
            serie.idVersion === event.target.value &&
            serie.idProductionOffice === productionOfficeSelected
        );
      } else {
        filterResp = allSeries.filter(
          (serie) => serie.idVersion === event.target.value
        );
      }
    } else {
      filterResp = allSeries.filter(
        (serie) => serie.idProductionOffice === productionOfficeSelected
      );
    }
    setSeriesFiltered(filterResp);
  };
  const handleSelectPO = async (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const allSeries = [...series];
    let filterResp: ITrdSeries[] = [];

    setProductionOfficeSelected(event.target.value as string);
    if (event.target.value !== "0") {
      if (versionSelected !== "0") {
        filterResp = allSeries.filter(
          (serie) =>
            serie.idProductionOffice === event.target.value &&
            serie.idVersion === versionSelected
        );
      } else {
        filterResp = allSeries.filter(
          (serie) => serie.idProductionOffice === event.target.value
        );
      }
    } else {
      filterResp = allSeries.filter(
        (serie) => serie.idVersion === versionSelected
      );
    }
    setSeriesFiltered(filterResp);
  };

  useEffect(() => {
    if (versionSelected !== "0" || productionOfficeSelected !== "0") {
      const allSeries = [...series];
      const filterResp = allSeries.filter(
        (serie) => serie.idVersion === versionSelected
      );
      setSeriesFiltered(filterResp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [series]);
  const onDenySetSerie = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    dispatch(setActiveSerie(null));
  };
  const onDenyDeleteSerie = () => {
    dispatch(setActiveSerie(null));
    dispatch(uiCloseModalDelete());
  };

  return (
    <>
      <Divider variant="fullWidth" />
      <Paper square className={classes.paper}>
        <Box>
          <Box style={{ height: "5px" }}></Box>
          <Box m={2}>
            <Grid container style={{ marginBottom: "20px" }}>
              <Grid item xs={5}>
                <Button
                  style={{ fontSize: 13 }}
                  size="large"
                  variant="contained"
                  color="secondary"
                  className={classes.btnAdd}
                  type="button"
                  onClick={() => dispatch(uiOpenModalAdd())}
                >
                  <AddOutlinedIcon />
                  <FormattedMessage id="AddNewSeries" />
                </Button>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={5}>
                <CustomizedSearch
                  data={
                    versionSelected === "0" && productionOfficeSelected === "0"
                      ? series
                      : seriesFiltered
                  }
                />
              </Grid>

              <Box width={1} display="flex" mt={1}>
                <Box mr={2} mt={2} style={{ fontSize: 14 }}>
                  <FormattedMessage id="Version" />:
                </Box>
                <Box width={"70%"}>
                  <TextField
                    name="version"
                    variant="outlined"
                    select
                    size="small"
                    value={versionSelected}
                    className={classes.myTextFieldRoot}
                    onChange={handleSelectVersion}
                    disabled={versions.length <= 0}
                  >
                    <MenuItem value={"0"}>
                      <FormattedMessage id={"All"} />
                    </MenuItem>
                    {versions.map((ver) => (
                      <MenuItem key={ver.idVersion} value={ver.idVersion}>
                        {ver.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>
              <Box width={1} display="flex" mt={1}>
                <Box mr={2} mt={2} style={{ fontSize: 14 }}>
                  <FormattedMessage id="ProductionOffice" />:
                </Box>
                <Box width={"70%"}>
                  <TextField
                    name="version"
                    variant="outlined"
                    select
                    size="small"
                    value={productionOfficeSelected}
                    className={classes.myTextFieldRoot}
                    onChange={handleSelectPO}
                    disabled={productionOffices.length <= 0}
                  >
                    <MenuItem value={"0"}>
                      <FormattedMessage id={"All"} />
                    </MenuItem>
                    {productionOffices.map((productionOffice) => (
                      <MenuItem
                        key={productionOffice.idProductionOffice}
                        value={productionOffice.idProductionOffice}
                      >
                        {productionOffice.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>
            </Grid>
            <SeriesTable
              series={
                versionSelected === "0" && productionOfficeSelected === "0"
                  ? series
                  : seriesFiltered
              }
            />

            <CustomizedDialog
              open={modalAddOpen}
              cancelBtn={true}
              onDeny={onDenySetSerie}
              title={`${intl.formatMessage({ id: "NewSeries" })}`}
              content={<SeriesForm />}
              textButton="Accept"
            />
            <CustomizedDialog
              open={modalEditOpen}
              cancelBtn={true}
              onDeny={onDenySetSerie}
              title={`${intl.formatMessage({ id: "EditSerie" })}`}
              content={<SeriesForm edit />}
              textButton="Accept"
            />
            <CustomizedDialog
              open={modalDeleteOpen}
              cancelBtn={true}
              onDeny={onDenyDeleteSerie}
              title={`${intl.formatMessage({ id: "DeleteSerie" })}`}
              content={
                <ConfirmDelete
                  title={`${intl.formatMessage({
                    id: "QuestionDeleteSerie",
                  })}`}
                />
              }
              textButton="Accept"
            />
          </Box>
          {successMsg && (
            <MyAlert
              open={successAlert}
              typeAlert="success"
              message={successMsg}
              time={2000}
              handleClose={() => dispatch(uiCloseSuccessAlert())}
            />
          )}
          <MyAlert
            open={errorAlert}
            typeAlert="error"
            message="SerieDeletedError"
            time={2000}
            handleClose={() => dispatch(uiCloseErrorAlert())}
          />
        </Box>
      </Paper>
    </>
  );
};

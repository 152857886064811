import React, { useCallback } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import moment from "moment";

export const TransferDetailsTab = () => {
    const { activeTransfer } = useSelector(
        (state: AppState) => state.documentManagment
      );
      const { expedients } = useSelector((state: AppState) => state.fileModule);
      const getExpedientsDetails = useCallback(
        (expedientsIds: string[]) => {
          const listExpedients = expedientsIds
            .map((expId) => expedients.filter((exp) => exp.id === expId))
            .flat();
          return listExpedients;
        },
        [expedients]
      );
  return (
    <Box m={2} mt={1} mb={2}>



        {activeTransfer && (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  style={{ fontSize: 14, fontWeight: "bold" }}
                >
                  <FormattedMessage id="TransferDate" />:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" style={{ fontSize: 14 }}>
                  {moment(activeTransfer.transferDate.toDate()).format(
                    "YYYY/MM/DD hh:mm:ss a"
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  style={{ fontSize: 14, fontWeight: "bold" }}
                >
                  <FormattedMessage id="ApprovalDate" />:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" style={{ fontSize: 14 }}>
                  {activeTransfer.aprovalDate &&
                    moment(activeTransfer.aprovalDate.toDate()).format(
                      "YYYY/MM/DD hh:mm:ss a"
                    )}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  style={{ fontSize: 14, fontWeight: "bold" }}
                >
                  <FormattedMessage id="SenderEntity" />:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" style={{ fontSize: 14 }}>
                  {activeTransfer.sendingEntity}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  style={{ fontSize: 14, fontWeight: "bold" }}
                >
                  <FormattedMessage id="ProducingEntity" />:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" style={{ fontSize: 14 }}>
                  {activeTransfer.producingEntity}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  style={{ fontSize: 14, fontWeight: "bold" }}
                >
                  <FormattedMessage id="TransferType" />:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" style={{ fontSize: 14 }}>
              
                  <FormattedMessage id={activeTransfer.transferType} />
                </Typography>
              </Grid>
    
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  style={{ fontSize: 14, fontWeight: "bold" }}
                >
                  <FormattedMessage id="TransferExpedients" />:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" style={{ fontSize: 14 }}>
                  {activeTransfer.expedients &&
                    getExpedientsDetails(activeTransfer.expedients).map((exp) => (
                      <p>{exp.fileName}</p>
                    ))}
                </Typography>
              </Grid>
           
            </Grid>
          )}
    </Box>
  )
}

import { FC } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { FileCopy, LibraryBooks, MenuBook } from "@material-ui/icons";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import { Link } from "react-router-dom";
import { useStyles } from "../../../../shared/styles/useStyles";
import { FormattedMessage } from "react-intl";
import { ModulesType } from "../../../../enums/enums";

interface props {
  modules: string[];
}
export const CardNavigation: FC<props> = ({ modules }) => {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={3} />
      <Grid item xs={8}>
        {modules.map((module, index) => {
          return (
            <Link
              key={index}
              to={`/${module.toLowerCase()}`}
              style={{ marginLeft: "3%", float: "left", marginBottom: 20 }}
            >
              <Card className={classes.cardsContainer}>
                <CardActionArea>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={4} lg={6}>
                        {module === ModulesType.FileModule && (
                          <FileCopy fontSize="large" />
                        )}
                        {module === ModulesType.DocumentManagment && (
                          <LibraryBooks fontSize="large" />
                        )}
                        {module === ModulesType.Correspondence && (
                          <EmailOutlinedIcon fontSize="large" />
                        )}
                        {module === ModulesType.StockTacking && (
                          <MenuBook fontSize="large" />
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          variant="subtitle1"
                          style={{
                            marginTop: "-5px",
                            fontFamily: "Poppins",
                            marginLeft: "-35px",
                          }}
                        >
                          <FormattedMessage id={module} />
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          );
        })}
      </Grid>
    </Grid>
  );
};

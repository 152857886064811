import React, { FC, useEffect, useState, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress } from "@material-ui/core";
import {
  AnswerTimeCorrespondence,
  ICommunicationType,
  ICorrespondence,
  ICorrespondenceResponse,
} from "../../../interfaces/Correspondence";
import { useStyles } from "../../../shared/styles/useStyles";
import { TrafficLight } from "../../correspondence-module/pqrs/TrafficLight";
import { AnswerTable } from "./AnswerTable";
import CustomizedDialog from "../../custom/CustomizedDialog";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  uiCloseAnswerModal,
  uiCloseModalAnswer,
  uiCloseModalAnswerBroadcast,
  uiCloseModalAnswerBroadcastEdit,
  uiCloseModalEmailSent,
  uiCloseModalEmailStatusHistory,
} from "../../../redux/actions/uiActions";
import { AnswerForm } from "./AnswerForm";
import { AnswerFormBroadcast } from "./AnswerFormBroadcast";
import { setActiveAnswer } from "../../../redux/actions/documentManagmentActions";
import { IssuanceResponseForm } from "../correspondence/IssuanceResponseForm";
import {
  CorrespondenceInputType,
  InternalCorrespondenceStatus,
} from "../../../enums/enums";
import { SendEmail } from "../correspondence/SendEmail";
import { EmailStatusHistory } from "../correspondence/EmailStatusHistory";
import { EmailSent } from "../correspondence/EmailSent";
import { getCorrespondenceAnswers } from "../../../services/firebase/correspondence";

interface props {
  // date: Date;
  paramResponse: ICommunicationType | null | undefined;
  answer?: AnswerTimeCorrespondence | null;
  component?: string;
  correspondence: ICorrespondence;
}
export const AnswersTab: FC<props> = ({
  // date,
  paramResponse,
  answer,
  component = "OTHER",
  correspondence,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();
  const unSubRef = useRef<any>(null);
  const {
    modalAnswerOpen,
    modalAnswerBroadcastOpen,
    modalAnswerBroadcastOpenEdit,
    openAnswerModal,
    modalEmailStatusHistory,
    modalEmailSent,
  } = useSelector((state: AppState) => state.ui);
  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.documentManagment
  );

  const [loading, setLoading] = useState(false);

  const [answers, setAnswers] = useState<ICorrespondenceResponse[]>([]);
  const { user } = useSelector((state: AppState) => state.auth);

  const onDeny = () => {
    dispatch(setActiveAnswer(null));
    dispatch(uiCloseAnswerModal());
    dispatch(uiCloseModalAnswer());
    dispatch(uiCloseModalAnswerBroadcast());
    dispatch(uiCloseModalAnswerBroadcastEdit());
    dispatch(uiCloseModalEmailStatusHistory());
    dispatch(uiCloseModalEmailSent());
  };

  const isCorrespondenceEP =
    activeCorrespondence?.inputType === CorrespondenceInputType.EP;
  const emailSent = answers && answers.length > 0;

  useEffect(() => {
    const fetchAnswers = async () => {
      if (user && user.idEntity && activeCorrespondence) {
        setLoading(true);
        unSubRef.current = await getCorrespondenceAnswers(
          user.idEntity,
          activeCorrespondence.id,
          (resp: ICorrespondenceResponse[] | null) => {
            if (resp) {
              setAnswers(resp);
            }
            setLoading(false);
          }
        );
      }
    };
    fetchAnswers();
    return () => {
      //CANCELAR SNAPSHOT
      unSubRef.current();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <br />
      <div className={classes.centerMediumSize}>
        <FormattedMessage
          id={isCorrespondenceEP ? "EmailDeliveryStatus" : "ResponseStatus"}
        />
        {activeCorrespondence &&
          activeCorrespondence.internalState !==
            InternalCorrespondenceStatus.NO_ANSWER_REQUIRED && (
            <>
              {!isCorrespondenceEP ? (
                <TrafficLight
                  paramResponse={paramResponse}
                  answer={answer}
                  correspondence={correspondence}
                />
              ) : (
                <></>
              )}
            </>
          )}
        {activeCorrespondence && (
          <span
            className={`${classes.fontWeightBold} ${
              !isCorrespondenceEP ? classes.ml0 : classes.ml05
            }`}
          >
            {!isCorrespondenceEP ? (
              <FormattedMessage id={activeCorrespondence.internalState} />
            ) : (
              <p
                className={
                  !emailSent ? classes.redColor : classes.darkBlueColor
                }
              >
                {intl.formatMessage({
                  id: !emailSent
                    ? "EmailNotSentAddressee"
                    : "EmailSentAddressee",
                })}
              </p>
            )}
          </span>
        )}
      </div>
      <div className={classes.mt1}>
        <AnswerTable component={component} />
      </div>
      <CustomizedDialog
        open={modalAnswerOpen}
        cancelBtn={true}
        onDeny={onDeny}
        title={`${intl.formatMessage({ id: "SendAnswer" })}`}
        content={<AnswerForm />}
        textButton="Accept"
      />
      <CustomizedDialog
        open={openAnswerModal}
        cancelBtn={true}
        onDeny={onDeny}
        title={`${intl.formatMessage({ id: "SendToEmail" })}`}
        content={<SendEmail />}
        textButton="Accept"
      />
      <CustomizedDialog
        open={modalAnswerBroadcastOpen}
        cancelBtn={true}
        onDeny={onDeny}
        title={`${intl.formatMessage({ id: "AnswerBroadcast" })}`}
        content={<IssuanceResponseForm edit={true} />}
        textButton="Accept"
      />

      <CustomizedDialog
        open={modalAnswerBroadcastOpenEdit}
        cancelBtn={true}
        onDeny={onDeny}
        title={`${intl.formatMessage({ id: "AnswerBroadcast" })}`}
        content={<AnswerFormBroadcast edit={true} />}
        textButton="Accept"
      />
      <CustomizedDialog
        open={modalEmailStatusHistory}
        cancelBtn={true}
        onDeny={onDeny}
        title={`${intl.formatMessage({ id: "EmailStatusHistory" })}`}
        content={<EmailStatusHistory />}
        textButton="Accept"
      ></CustomizedDialog>

      <CustomizedDialog
        open={modalEmailSent}
        cancelBtn={true}
        onDeny={onDeny}
        title={`${intl.formatMessage({ id: "EmailSent" })}`}
        content={<EmailSent />}
        textButton="Accept"
      ></CustomizedDialog>
    </div>
  );
};

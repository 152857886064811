import {
  ITrdAction,
  ITrdDocumentalType,
  ITrdProductionOffices,
  ITrdSeries,
  ITrdSubSerie,
  ITrdState,
  ITrdVersion,
  Types,
} from "../types/types";

const initialState: ITrdState = {
  versions: [],
  documentalType: [],
  productionOffices: [],
  series: [],
  subSeries: [],
  activeVersion: null,
  activeDocumentalType: null,
  activeProductionOffice: null,
  activeSerie: null,
  activeSubSerie: null,
};
export const trdReducer = (
  state = initialState,
  action: ITrdAction
): ITrdState => {
  const { type, payload } = action;
  switch (type) {
    case Types.loadVersions:
      return {
        ...state,
        versions: [...(payload as ITrdVersion[])],
      };
    case Types.addOrEditTrdVersion:
      const allVersions = [...state.versions];
      const indexVersion = allVersions.findIndex(
        (el) => el.idVersion === (payload as ITrdVersion).idVersion
      );
      if (indexVersion >= 0) {
        allVersions[indexVersion] = payload as ITrdVersion;
        return {
          ...state,
          versions: [...allVersions],
        };
      }
      return {
        ...state,
        versions: [payload as ITrdVersion, ...state.versions],
      };

    case Types.setActiveTrdVersion:
      return {
        ...state,
        activeVersion: payload as any,
      };
    case Types.deleteVersion:
      return {
        ...state,
        versions: state.versions.filter((el) => el.idVersion !== payload),
      };

    // ----------- PRODUCTION OFFICE ------------------
    case Types.loadProductionOffices:
      return {
        ...state,
        productionOffices: [...(payload as ITrdProductionOffices[])],
      };

    case Types.addTrdProductionOffice:
      return {
        ...state,
        productionOffices: [
          payload as ITrdProductionOffices,
          ...state.productionOffices,
        ],
      };

    case Types.setActiveProductionOffice:
      return {
        ...state,
        activeProductionOffice: payload as ITrdProductionOffices | null,
      };

    case Types.updateTrdProductionOffice:
      return {
        ...state,
        productionOffices: state.productionOffices.map((office) =>
          office.idProductionOffice ===
          (payload as ITrdProductionOffices).idProductionOffice
            ? (payload as ITrdProductionOffices)
            : office
        ),
      };

    case Types.deleteProductionOffice:
      return {
        ...state,
        productionOffices: state.productionOffices.filter(
          (el) => el.idProductionOffice !== payload
        ),
      };

    // -----------     SERIES   ------------------
    case Types.setTrdSerie:
      const allSeries = [...state.series];
      const indexSerie = allSeries.findIndex(
        (el) => el.idSerie === (payload as ITrdSeries).idSerie
      );
      if (indexSerie >= 0) {
        allSeries[indexSerie] = payload as ITrdSeries;
        return {
          ...state,
          series: [...allSeries],
        };
      }
      return {
        ...state,
        series: [payload as ITrdSeries, ...state.series],
      };
    case Types.setActiveSerie:
      return {
        ...state,
        activeSerie: payload as any,
      };
    case Types.deleteSerie:
      return {
        ...state,
        series: state.series.filter((el) => el.idSerie !== payload),
      };
    case Types.loadSeries:
      return {
        ...state,
        series: [...(payload as ITrdSeries[])],
      };
    case Types.purgeSeries:
      return {
        ...state,
        series: [],
      };

    //DOCUMENTAL TYPE
    case Types.loadTrdDocumentalTypes:
      return {
        ...state,
        documentalType: [...(payload as ITrdDocumentalType[])],
      };
    case Types.setTrdDocumentalType:
      const allDocuementalType = [...state.documentalType];
      const indexDT = allDocuementalType.findIndex(
        (el) =>
          el.idDocumentalType ===
          (payload as ITrdDocumentalType).idDocumentalType
      );
      if (indexDT >= 0) {
        allDocuementalType[indexDT] = payload as ITrdDocumentalType;
        return {
          ...state,
          documentalType: [...allDocuementalType],
        };
      }
      return {
        ...state,
        documentalType: [
          payload as ITrdDocumentalType,
          ...state.documentalType,
        ],
      };
    case Types.deleteTrdDocumentalType:
      return {
        ...state,
        documentalType: state.documentalType.filter(
          (dt) => dt.idDocumentalType !== payload
        ),
      };
    case Types.setActiveDocumentalType:
      return {
        ...state,
        activeDocumentalType: payload as any,
      };
    // -----------     SUB-SERIES   ------------------
    case Types.loadSubseries:
      return {
        ...state,
        subSeries: [...(payload as ITrdSubSerie[])],
      };

    case Types.setActiveSubserie:
      return {
        ...state,
        activeSubSerie: payload as ITrdSubSerie | null,
      };

    case Types.addSubserie:
      return {
        ...state,
        subSeries: [payload as ITrdSubSerie, ...state.subSeries],
      };

    case Types.updateTrdSubserie:
      return {
        ...state,
        subSeries: state.subSeries.map((subSerie) =>
          subSerie.idSubserie === (payload as ITrdSubSerie).idSubserie
            ? (payload as ITrdSubSerie)
            : subSerie
        ),
      };

    case Types.deleteSubserie:
      return {
        ...state,
        subSeries: state.subSeries.filter((el) => el.idSubserie !== payload),
      };

    default:
      return state;
  }
};

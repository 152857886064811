import { FC, useCallback } from "react";

import { TableRow, TableCell, CircularProgress } from "@material-ui/core";
import { CustomizedIcons } from "../../custom/CustomizedIcons";
import { useDispatch, useSelector } from "react-redux";
import {
  uiOpenModalInfo,
  uiOpenModalAssignModules,
  setErrorMsg,
  uiOpenErrorAlert,
  uiOpenModalReject,
  uiOpenModalSee,
  uiOpenModalEdit,
} from "../../../redux/actions/uiActions";
import { ITransfer } from "../../../redux/types/types";
import moment from "moment";
import {
  setActiveTransfer,
  startDeleteTransfer,
  startSendTransfer,
} from "../../../redux/actions/documentManagmentActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import Swal from "sweetalert2";
import { useIntl } from "react-intl";
import { Colors } from "../../../shared/constants/themes/Colors";
import { useStyles } from "../../../shared/styles/useStyles";

// import { useDispatch } from "react-redux";
// import { firebase } from "../../../config/firebase/firebase-config";
// import moment from "moment";

interface props {
  transfers: ITransfer;
  isFileModule?: true;
  readOnly?: boolean;
}

export const TransfersBody: FC<props> = ({
  transfers,
  readOnly = false,
  isFileModule,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { loading } = useSelector((state: AppState) => state.ui);
  const { activeTransfer } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { transfers: allTransfers } = useSelector(
    (state: AppState) => state.fileModule
  );
  const { transfers: transferManagment } = useSelector(
    (state: AppState) => state.documentManagment
  );

  const { expedients } = useSelector((state: AppState) => state.documentManagment);

  const transferManag = transferManagment.find(
    (tra) => tra.idTransfer === transfers.idTransfer
  );
  const transferCentralFile = allTransfers.find(
    (tra) => tra.idTransfer === transfers.idTransfer
  );
  const isTransferFromCentralFile =
    transferCentralFile && !transferManag ? true : false;

  const classes = useStyles();

  const getExpedientsDetails = useCallback(
    (expedientsIds: string[]) => {
      const listExpedients = expedientsIds
        .map((expId) => expedients.filter((exp) => exp.id === expId))
        .flat();
      return listExpedients;
    },
    [expedients]
  );
  const expedientDetails = transfers.expedients
    ? getExpedientsDetails(transfers.expedients)
    : [];

  const onAddFiles = () => {
    dispatch(setActiveTransfer(transfers));
    dispatch(uiOpenModalAssignModules());
  };

  const onEdit = () => {
    dispatch(setActiveTransfer(transfers));
    dispatch(uiOpenModalEdit());
  };

  const onApproveTransfer = () => {
    dispatch(setActiveTransfer(transfers));
    dispatch(uiOpenModalInfo());
  };

  const onDownloadTransferFormatIcon = () => {};

  const onDelete = () => {
    Swal.fire({
      title: `${intl.formatMessage({ id: "DeleteTransfer" })}${
        transfers.object
      }?`,
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: Colors.PRIMARY,
      cancelButtonColor: Colors.DANGER,
      confirmButtonText: "Si",
      cancelButtonText: "No",
      customClass: {
        container: "swal-container-z-index",
      },
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        await dispatch(
          startDeleteTransfer(transfers.idTransfer, isFileModule!)
        );
      },
    });
  };

  const onSend = () => {
    if (transfers.submitDate) {
      dispatch(setErrorMsg("AlreadyTransferSended"));
      dispatch(uiOpenErrorAlert());
    } else {
      const validateDocumentalType = expedientDetails.find((exp) =>
        exp.documents?.find((doc) => !doc.documentalType)
      );

      if (validateDocumentalType) {
        Swal.fire({
          title: `${intl.formatMessage({
            id: "ErrorSendingTransfer",
          })}`,
          text: `${intl.formatMessage({
            id: "ErrorClassifyAllDocuments",
          })}`,
          icon: "error",
          showConfirmButton: false,
          showDenyButton: true,
          denyButtonColor: Colors.PRIMARY,
          denyButtonText: `${intl.formatMessage({ id: "Accept" })}`,
          customClass: {
            container: "swal-container-z-index",
          },
        });
      } else {
        dispatch(setActiveTransfer(transfers));

        dispatch(startSendTransfer(transfers));
      }
    }
  };
  const onReject = () => {
    dispatch(setActiveTransfer(transfers));
    dispatch(uiOpenModalReject());
  };
  const onSee = () => {
    dispatch(setActiveTransfer(transfers));
    dispatch(uiOpenModalSee());
  };
  const onComments = () => {
    //abrir modal para ver los detalles del porque se rechazo la transferencia
    dispatch(setActiveTransfer(transfers));
    dispatch(uiOpenModalInfo());
  };

  //   const date = moment(
  //     (transfers.closingDate as firebase.firestore.Timestamp).toDate()
  //   ).format("MMMM Do YYYY, h:mm:ss a");
  return (
    <TableRow
      key={transfers.idTransfer}
      className={transfers.isComplete ? classes.styleRowReaded2 : undefined}
    >
      <TableCell component="th" scope="row" align="center">
        {transfers.sendingEntity}
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        {transfers.producingEntity}
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        {transfers.adminUnit}
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        {transfers.productionOffice}
      </TableCell>
      <TableCell component="th" scope="row" align="center">
        {transfers.object}
      </TableCell>
      <TableCell align="center">
        {moment(transfers.transferDate.toDate()).format("YYYY/MM/DD")}
      </TableCell>

      <TableCell align="center">{transfers.transferNumber}</TableCell>
      <TableCell align="center">
        {transfers.senderTransferUser && transfers.senderTransferUser.username}
      </TableCell>
      {!readOnly && (
        <TableCell
          align="center"
          className={classes.fixedColumn}
          style={{
            backgroundColor: transfers.isComplete
              ? "#f3f3f3"
              : classes.fixedColumn,
          }}
        >
          {transfers.isComplete ? (
            <CustomizedIcons
              seeIcon
              addFilesIcon
              onAddFiles={onAddFiles}
              onSee={onSee}
            />
          ) : loading && transfers.idTransfer === activeTransfer?.idTransfer ? (
            <CircularProgress />
          ) : (
            <>
              {isFileModule ? (
                <CustomizedIcons
                  approveTransferIcon={
                    isFileModule &&
                    transfers.expedients &&
                    transfers.expedients.length > 0
                      ? true
                      : undefined
                  }
                  editIcon={isTransferFromCentralFile ? true : undefined}
                  onEdit={onEdit}
                  rejectIcon={
                    transfers.expedients &&
                    transfers.expedients.length > 0 &&
                    !isTransferFromCentralFile
                      ? true
                      : undefined
                  }
                  deleteIcon
                  addFilesIcon
                  onAddFiles={onAddFiles}
                  onApproveTransfer={onApproveTransfer}
                  onReject={onReject}
                  onDelete={onDelete}
                />
              ) : transfers.isRejected ? (
                <CustomizedIcons
                  commentsIcon
                  addFilesIcon
                  editIcon
                  downloadTransferFormatIcon
                  deleteIcon
                  onAddFiles={onAddFiles}
                  onEdit={onEdit}
                  sendIcon={
                    transfers.expedients &&
                    transfers.expedients.length > 0 &&
                    transfers.senderTransferUser
                      ? true
                      : undefined
                  }
                  isTransfer
                  onSend={onSend}
                  onComments={onComments}
                  onApproveTransfer={onApproveTransfer}
                  onDownloadTransferFormat={onDownloadTransferFormatIcon}
                  onDelete={onDelete}
                />
              ) : (
                <CustomizedIcons
                  addFilesIcon
                  editIcon={!transfers.submitDate ? true : undefined}
                  downloadTransferFormatIcon
                  deleteIcon
                  onAddFiles={onAddFiles}
                  onEdit={onEdit}
                  sendIcon={
                    transfers.expedients && transfers.expedients.length > 0
                      ? true
                      : undefined
                  }
                  isTransfer
                  onSend={onSend}
                  onApproveTransfer={onApproveTransfer}
                  onDownloadTransferFormat={onDownloadTransferFormatIcon}
                  onDelete={onDelete}
                />
              )}
            </>
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";
import { FC } from "react";
import moment from "moment";

import { ICorrespondence } from "../../../interfaces/Correspondence";
import { TrafficLight } from "./TrafficLight";
import { AppState } from "../../../redux/reducers/rootReducer";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { CustomizedIcons } from "../../custom/CustomizedIcons";

interface props {
  pqrs: ICorrespondence;
}

export const PqrsBody: FC<props> = ({ pqrs }) => {
  const { productionOffices } = useSelector((state: AppState) => state.trd);
  const getOficeName = (idProductionOffice: string) => {
    const findOffice = productionOffices.find(
      (el) => el.idProductionOffice === idProductionOffice
    );
    if (findOffice) {
      return findOffice.name;
    }
    return "";
  };

  const isAnswer = {
    answer: pqrs.answer,
    answerDate: pqrs.answeredDate,
    createDate: pqrs.createAt,
  };

  // const handleSee = () => {
  //   dispatch(setActiveCorrespondence(pqrs));
  //   dispatch(uiOpenModalAdd());
  // };

  return (
    <TableRow key={pqrs.id}>
      <TableCell component="th" scope="row" align="center">
        {/* <Tooltip title={`${intl.formatMessage({ id: "See" })}`}> */}
        {pqrs.id}
        {/* </Tooltip> */}
      </TableCell>
      <TableCell align="center">
        {pqrs.communicationType?.option !== "" && (
          <FormattedMessage id={`${pqrs.communicationType?.option}`} />
        )}
      </TableCell>
      <TableCell align="center">{pqrs.subject}</TableCell>
      <TableCell align="center">
        {getOficeName(pqrs.receivingDependency)}
      </TableCell>
      <TableCell align="center">{pqrs.addressee.name}</TableCell>
      <TableCell align="center">
        {moment(pqrs.createAt.toDate()).format("YYYY/MM/DD h:mm:ss a")}
        {/* {moment(communication.createdAt.toDate()).format("YYYY/MM/DD")} */}
      </TableCell>
      <TableCell align="center">
        <TrafficLight
          correspondence={pqrs}
          paramResponse={pqrs.communicationType}
          answer={isAnswer}
        />
      </TableCell>
      <TableCell align="center">
        {(pqrs.digitalSupport as []).length === 0 ? (
          <CustomizedIcons alertAttachments />
        ) : (
          ""
        )}
      </TableCell>
      <TableCell align="center">
        {pqrs.isReaded ? (
          <VisibilityOutlinedIcon />
        ) : (
          <FormattedMessage id={"Unread"} />
        )}
      </TableCell>

      <TableCell align="center">
        {pqrs.internalState && (
          <FormattedMessage id={`${pqrs.internalState}`} />
        )}
      </TableCell>
    </TableRow>
  );
};

import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  Divider,
  Paper,
  Box,
  Grid,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { useStyles } from "../../../../shared/styles/useStyles";
import {
  uiOpenModalAdd,
  uiCloseModalAdd,
  uiCloseSuccessAlert,
  uiCloseErrorAlert,
  uiCloseModalEdit,
  uiCloseModalDelete,
} from "../../../../redux/actions/uiActions";
import { CustomizedSearch } from "../../../custom/CustomizedSearch";
import CustomizedDialog from "../../../custom/CustomizedDialog";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { DocumentaryTypeForm } from "./DocumentaryTypeForm";
import { DocumentaryTypeTable } from "./DocumentaryTypeTable";
import { MyAlert } from "../../../custom/MyAlert";
import { setActiveDocumentalType } from "../../../../redux/actions/trdActions";
import { ConfirmDelete } from "../../../ui/shared/ConfirmDelete";
import { ITrdDocumentalType } from "../../../../redux/types/types";

export const DocumentaryTypeTab = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [documentalFiltered, setDocumentalFiltered] = useState<
    ITrdDocumentalType[]
  >([]);
  const [selected, setSelected] = useState({
    version: "0",
    productionOffice: "0",
    series: "0",
    subseries: "0",
  });

  const { series, productionOffices, versions, subSeries, documentalType } =
    useSelector((state: AppState) => state.trd);
  const {
    modalAddOpen,
    successAlert,
    successMsg,
    errorAlert,
    modalEditOpen,
    modalDeleteOpen,
  } = useSelector((state: AppState) => state.ui);

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected({ ...selected, [event.target.name]: event.target.value });
  };
  const verifySelectedDocumentalType = (docTypeId:string,option:string)=>{
    if (option !== "0") {
      return docTypeId === option;
    }else{
      return true
    }
  }
  useEffect(() => {
    //HACER FILTROS
    const allDocumentalTypes = [...documentalType];
    const filteredResp = allDocumentalTypes.filter(
      (docType) =>
      verifySelectedDocumentalType(docType.idVersion,selected.version) &&
      verifySelectedDocumentalType(docType.idProductionOffice,selected.productionOffice) &&
      verifySelectedDocumentalType(docType.idSerie,selected.series) &&
      verifySelectedDocumentalType(docType.idSubSerie,selected.subseries)
    );
    setDocumentalFiltered(filteredResp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  const onDeny = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    dispatch(uiCloseModalDelete());
    dispatch(setActiveDocumentalType(null));
  };

  return (
    <>
      <Divider variant="fullWidth" />
      <Paper square className={classes.paper}>
        <Box mb={3}>
          <Box style={{ height: "5px" }} />
          <Box m={2}>
            <Grid container style={{ marginBottom: "20px" }}>
              <Grid item xs={5}>
                <Button
                  style={{ fontSize: 13 }}
                  size="large"
                  variant="contained"
                  color="secondary"
                  className={classes.btnAdd}
                  type="button"
                  onClick={() => dispatch(uiOpenModalAdd())}
                >
                  <AddOutlinedIcon />
                  <FormattedMessage id="AddDocumentaryType" />
                </Button>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={5}>
                <CustomizedSearch
                  data={
                    selected.version === "0" &&
                    selected.productionOffice === "0" &&
                    selected.series === "0" &&
                    selected.subseries === "0"
                      ? documentalType
                      : documentalFiltered
                  }
                />
              </Grid>

              <Box width={1} display="flex" mt={1}>
                <Box width={"14%"} mr={2} mt={2} style={{ fontSize: 14 }}>
                  <FormattedMessage id="Version" />:
                </Box>
                <Box width={"70%"}>
                  <TextField
                    name="version"
                    variant="outlined"
                    select
                    size="small"
                    value={selected.version}
                    className={classes.myTextFieldRoot}
                    onChange={handleSelect}
                    //   disabled={ active ? true : false}
                  >
                    <MenuItem value={"0"}>
                      <FormattedMessage id={"All"} />
                    </MenuItem>
                    {versions.map((version) => (
                      <MenuItem
                        key={version.idVersion}
                        value={version.idVersion}
                      >
                        {version.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>

              <Box width={1} display="flex" mt={1}>
                <Box width={"14%"} mr={2} mt={2} style={{ fontSize: 14 }}>
                  <FormattedMessage id="ProductionOffice" />:
                </Box>
                <Box width={"70%"}>
                  <TextField
                    name="productionOffice"
                    variant="outlined"
                    select
                    size="small"
                    value={selected.productionOffice}
                    className={classes.myTextFieldRoot}
                    onChange={handleSelect}
                    //   disabled={ active ? true : false}
                  >
                    <MenuItem value={"0"}>
                      <FormattedMessage id={"All"} />
                    </MenuItem>
                    {productionOffices.map((productionOffice) => (
                      <MenuItem
                        key={productionOffice.idProductionOffice}
                        value={productionOffice.idProductionOffice}
                      >
                        {productionOffice.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>

              <Box width={1} display="flex" mt={1}>
                <Box width={"14%"} mr={2} mt={2} style={{ fontSize: 14 }}>
                  <FormattedMessage id="Series" />:
                </Box>
                <Box width={"70%"}>
                  <TextField
                    name="series"
                    variant="outlined"
                    select
                    size="small"
                    value={selected.series}
                    className={classes.myTextFieldRoot}
                    onChange={handleSelect}
                    //   disabled={ active ? true : false}
                  >
                    <MenuItem value={"0"}>
                      <FormattedMessage id={"All"} />
                    </MenuItem>
                    {series.map((serie) => (
                      <MenuItem key={serie.idSerie} value={serie.idSerie}>
                        {serie.seriesName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>

              <Box width={1} display="flex" mt={1}>
                <Box width={"14%"} mr={2} mt={2} style={{ fontSize: 14 }}>
                  <FormattedMessage id="Subseries" />:
                </Box>
                <Box width={"70%"}>
                  <TextField
                    name="subseries"
                    variant="outlined"
                    select
                    size="small"
                    value={selected.subseries}
                    className={classes.myTextFieldRoot}
                    onChange={handleSelect}
                    //   disabled={ active ? true : false}
                  >
                    <MenuItem value={"0"}>
                      <FormattedMessage id={"All"} />
                    </MenuItem>
                    {subSeries.map((subSer) => (
                      <MenuItem
                        key={subSer.idSubserie}
                        value={subSer.idSubserie}
                      >
                        {subSer.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>
            </Grid>
            <DocumentaryTypeTable
              documentalType={
                selected.version === "0" &&
                selected.productionOffice === "0" &&
                selected.series === "0" &&
                selected.subseries === "0"
                  ? documentalType
                  : documentalFiltered
              }
            />

            <CustomizedDialog
              open={modalAddOpen}
              cancelBtn={true}
              onDeny={onDeny}
              title={`${intl.formatMessage({ id: "NewDocumentaryType" })}`}
              content={<DocumentaryTypeForm />}
              textButton="Accept"
            />

            <CustomizedDialog
              open={modalEditOpen}
              cancelBtn={true}
              onDeny={onDeny}
              title={`${intl.formatMessage({ id: "EditDocumentaryType" })}`}
              content={<DocumentaryTypeForm edit />}
              textButton="Accept"
            />
            <CustomizedDialog
              open={modalDeleteOpen}
              cancelBtn={true}
              onDeny={onDeny}
              title={`${intl.formatMessage({ id: "DeleteDocType" })}`}
              content={
                <ConfirmDelete
                  title={`${intl.formatMessage({
                    id: "QuestionDeleteDocType",
                  })}`}
                />
              }
              textButton="Accept"
            />
            <Box style={{ height: "20px" }} />
          </Box>
          {successMsg && (
            <MyAlert
              open={successAlert}
              typeAlert="success"
              message={successMsg}
              time={2000}
              handleClose={() => dispatch(uiCloseSuccessAlert())}
            />
          )}
          <MyAlert
            open={errorAlert}
            typeAlert="error"
            message="SavingDataError"
            time={2000}
            handleClose={() => dispatch(uiCloseErrorAlert())}
          />
        </Box>
      </Paper>
    </>
  );
};

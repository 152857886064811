import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Divider, Grid, Paper } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { useStyles } from "../../../shared/styles/useStyles";
import { CustomizedSearch } from "../../custom/CustomizedSearch";
import CustomizedDialog from "../../custom/CustomizedDialog";
import { MyAlert } from "../../custom/MyAlert";
import {
  uiOpenModalAdd,
  uiCloseModalAdd,
  uiCloseSuccessAlert,
  uiCloseErrorAlert,
  uiCloseModalEdit,
  uiCloseModalDelete,
} from "../../../redux/actions/uiActions";
import { setActiveProcedure } from "../../../redux/actions/correspondenceActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { ProceduresTable } from "./ProceduresTable";
import { ProceduresForm } from "./ProceduresForm";
import { ConfirmDelete } from "../../ui/shared/ConfirmDelete";

export const ProceduresTab = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const {
    errorAlert,
    errorMsg,
    modalAddOpen,
    modalEditOpen,
    modalDeleteOpen,
    successMsg,
    successAlert,
  } = useSelector((state: AppState) => state.ui);
  const { paramsProcedure } = useSelector(
    (state: AppState) => state.correspondence
  );

  const onDenyEdit = () => {
    dispatch(uiCloseModalEdit());
    dispatch(setActiveProcedure(null));
  };

  const onDenyDelete = () => {
    dispatch(uiCloseModalDelete());
    dispatch(setActiveProcedure(null));
  };

  return (
    <>
      <Divider variant="fullWidth" />
      <Paper square className={classes.paper}>
        <Box mb={3}>
          <Box style={{ height: "5px" }} />
          <Box m={2}>
            <Grid container style={{ marginBottom: "20px" }}>
              <Grid item xs={5}>
                <Button
                  style={{ fontSize: 13 }}
                  size="large"
                  variant="contained"
                  color="secondary"
                  className={classes.btnAdd}
                  type="button"
                  onClick={() => dispatch(uiOpenModalAdd())}
                >
                  <AddOutlinedIcon />
                  <FormattedMessage id="AddNewProcedure" />
                </Button>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={5}>
                <CustomizedSearch data={paramsProcedure} />
              </Grid>
            </Grid>

            <ProceduresTable />

            <CustomizedDialog
              open={modalAddOpen}
              cancelBtn={true}
              onDeny={() => dispatch(uiCloseModalAdd())}
              title={`${intl.formatMessage({ id: "NewProcedure" })}`}
              content={<ProceduresForm />}
              textButton="Accept"
            />

            <CustomizedDialog
              open={modalEditOpen}
              cancelBtn={true}
              onDeny={onDenyEdit}
              title={`${intl.formatMessage({ id: "EditProcedure" })}`}
              content={<ProceduresForm />}
              textButton="Accept"
            />

            <CustomizedDialog
              open={modalDeleteOpen}
              cancelBtn={true}
              onDeny={onDenyDelete}
              title={`${intl.formatMessage({ id: "DeleteProcedure" })}`}
              content={
                <ConfirmDelete
                  title={`${intl.formatMessage({
                    id: "QuestionDeleteProcedure",
                  })}`}
                />
              }
              textButton="Accept"
            />

            {successMsg && (
              <MyAlert
                open={successAlert}
                typeAlert="success"
                message={successMsg}
                time={2000}
                handleClose={() => dispatch(uiCloseSuccessAlert())}
              />
            )}
            {errorMsg && (
              <MyAlert
                open={errorAlert}
                typeAlert="error"
                message={errorMsg}
                time={3000}
                handleClose={() => dispatch(uiCloseErrorAlert())}
              />
            )}
          </Box>
        </Box>
      </Paper>
    </>
  );
};

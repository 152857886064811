import { db, firebase } from "../../config/firebase/firebase-config";
import {
  TypeInbox,
  CorrespondenceStatus,
  InternalCorrespondenceStatus,
  CommunicationChannels,
} from "../../enums/enums";
import {
  IAppendDocs,
  ICorrespondence,
  ICorrespondenceAnswer,
  ICorrespondenceEmailResponse,
  ICorrespondenceInfo,
  ICorrespondenceResponse,
  // IRequestProjection,
  ITenderCorrespondence,
  IUserCopy,
  IUserCorrespondence,
} from "../../interfaces/Correspondence";
import { IResponseFirebase } from "../../interfaces/firebase";
import { Office } from "../../interfaces/OrganizationChart";
import {
  IAnnotation,
  IContractingOffice,
  ICorresAnnotations,
  IExternalSender,
  IFiles,
  IFuid,
  ILabelSize,
  IMilestones,
  IParamResponseTime,
  IRecipients,
} from "../../redux/types/types";
import { IParamProcedure, IDeposits } from "../../redux/types/types";
import Axios from "axios";
import { ITenders } from "../../interfaces/Correspondence";
import {
  loadAnnotations,
  loadCorrespondeces,
} from "../../redux/actions/correspondenceActions";
import { loadCorrespondenceManagment } from "../../redux/actions/documentManagmentActions";
import { filterCorrespondence } from "../../helpers/filteredCorrespondence";
import axios from "axios";
import {
  IEmailNotificationParams,
  IFormSendEmailCopy,
  IInputDataEmail,
} from "../../interfaces/emailNotification";
import { CorrespondenceInputType } from "../../enums/enums";
import moment from "moment";

export const getOfficeById = async (id: string): Promise<Office | null> => {
  const snap = await db
    .collectionGroup("Offices")
    .where("idOffice", "==", id)
    .limit(1)
    .get();
  if (snap.empty) {
    return null;
  }
  let response: any;
  snap.forEach((doc) => {
    response = doc.data() as Office;
  });
  return response;
};

export const startListenerAnnotations = async (
  idEntity: string,
  callback: Function
) => {
  db.collection("Correspondence")
    .doc(idEntity)
    .collection("Annotations")
    .onSnapshot((snap) => {
      const resp: IAnnotation[] = [];
      snap.forEach((doc) => {
        resp.push(doc.data() as IAnnotation);
      });
      callback(loadAnnotations(resp));
    });
};

export const setAnnotationDb = async (
  idEntity: string,
  annotation: IAnnotation
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("Annotations")
      .doc(annotation.id)
      .set(
        {
          ...annotation,
        },
        { merge: true }
      );

    /*  for (const recipient of annotation.recipientsData) {
      await db
        .collection("Users")
        .doc(recipient.id.toString())
        .collection("Inbox")
        .doc(annotation.id)
        .set({
          isReaded: false,
          confirmDate: null,
          type: TypeInbox.ANNOTATION,
          submitDate: annotation.createdAt,
          annotation: annotation.annotation,
          id: annotation.id,
          subjectAnnotation: annotation.subjectAnnotation,
          attachedDocument: annotation.attachedDocument,
        });
    } */

    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const deleteAnnotationDb = async (
  idEntity: string,
  idAnnotation: string,
  recipients: IRecipients[],
  attachedDocument: IFiles | null
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("Annotations")
      .doc(idAnnotation)
      .delete();
    if (attachedDocument && attachedDocument.url !== "#") {
      await firebase
        .storage()
        .ref(`/annotations/${idEntity}/${attachedDocument.fileName}`)
        .delete();
    }
    for (const recipient of recipients) {
      await db
        .collection("Users")
        .doc(recipient.id.toString())
        .collection("Inbox")
        .doc(idAnnotation)
        .delete();
    }
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

//BORRAR COMUNICACION EN CORRESPONDENCIA CON SUS ARCHIVOS
export const deleteCommunicationDb = async (
  idEntity: string,
  idCommunication: string,
  addressee: IUserCorrespondence | null,
  digitalSupport: IFiles[]
  //digitalSupport: IFiles | null
): Promise<IResponseFirebase> => {
  try {
    //ANULAR CORRESPONDENCIA
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(idCommunication)
      .set(
        {
          status: CorrespondenceStatus.INACTIVE,
        },
        { merge: true }
      );
    /* for (const files of digitalSupport) {
      if (digitalSupport && files.url !== "#") {
        await firebase
          .storage()
          .ref(`/correspondence/${idEntity}/${files.fileName}`)
          .delete();
      }
    }
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(idCommunication)
      .collection("Milestones")
      .get()
      .then((res) => {
        res.forEach((item) => {
          item.ref.delete();
        });
      }); */

    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// Agregar trámite
export const addOrUpdateProcedure = async (
  idEntity: string,
  procedure: IParamProcedure
) => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("ParamProcedure")
      .doc(procedure.id)
      .set(procedure, { merge: true });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// Cargar trámites
export const loadProcedures = async (idEntity: string) => {
  const proceduresSnap = await db
    .collection(`/Correspondence/${idEntity}/ParamProcedure`)
    .get();
  const procedures: any = [];

  proceduresSnap.forEach((snapChild) => {
    procedures.push({
      ...snapChild.data(),
    });
  });

  return procedures;
};

// Eliminar trámite
export const deleteProcedureFirebase = async (
  idEntity: string,
  idProcedure: string
) => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("ParamProcedure")
      .doc(idProcedure)
      .delete();
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// Cargar parámetros del formulario de tiempo de respuesta
export const loadResponseTimesParamsForm = async (idEntity: string) => {
  const ResponseTimesParamsFormSnap = await db
    .collection(`/Correspondence/`)
    .doc(`${idEntity}`)
    .collection(`/ParamResponseTime/`)
    .doc(`${idEntity}`)
    .get();

  return ResponseTimesParamsFormSnap.data();
};
export const loadProductionOfficeParam = async (idEntity: string) => {
  const param = await db.collection("Correspondence").doc(idEntity).get();
  return (param.data() as any).productionOffice;
};

// Actualizar Tiempo de respuesta
export const updateResponseTimes = async (
  idEntity: string,
  form: IParamResponseTime
) => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("ParamResponseTime")
      .doc(idEntity)
      .set(form, { merge: true });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const getSettledStatus = async (
  idSettled: string
): Promise<ICorrespondence | null> => {
  try {
    const { data } = await Axios.get(
      "https://us-central1-modulosmialcaldia.cloudfunctions.net/getSettledStatus",
      {
        params: {
          idSettled,
        },
      }
    );
    if (data.length > 0) {
      return data[0];
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const loadCorrespondencesByEntity = async (
  idEntity: string
): Promise<ICorrespondence[] | null> => {
  try {
    let response: ICorrespondence[] = [];
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .orderBy("createAt", "desc")
      .onSnapshot((snap) => {
        snap.forEach((doc) => {
          response.push(doc.data() as ICorrespondence);
        });
      });
    return response;
  } catch (error) {
    return null;
  }
};
export const startListenerCorrespondencesByEntity = (
  idEntity: string,
  idUser: string,
  callback: Function
) => {
  db.collection("Correspondence")
    .doc(idEntity)
    .collection("CorrespondenceSection")
    .orderBy("createAt", "desc")
    .onSnapshot((snap) => {
      let response: ICorrespondence[] = [];
      snap.forEach((doc) => {
        response.push(doc.data() as ICorrespondence);
      });
      callback(loadCorrespondeces(response));
      const filteredCorrespondence = response.filter((cor) =>
        filterCorrespondence(cor, idUser)
      );
      callback(loadCorrespondenceManagment(filteredCorrespondence));
    });
};
export const getLastCorrespondence = async (idEntity: string) => {
  const startOfYear = moment().startOf("year").toDate();
  const endOfYear = moment().endOf("year").toDate();
  const parseStartDate = firebase.firestore.Timestamp.fromDate(startOfYear);
  const parseEndDate = firebase.firestore.Timestamp.fromDate(endOfYear);

  const dosSnap = await db
    .collection("Correspondence")
    .doc(idEntity)
    .collection("CorrespondenceSection")
    .where("createAt", ">=", parseStartDate)
    .where("createAt", "<=", parseEndDate)
    .orderBy("createAt", "desc")
    .limit(1)
    .get();
  const response: ICorrespondence[] = [];
  dosSnap.forEach((doc) => {
    response.push(doc.data() as ICorrespondence);
  });
  return response.length > 0 ? response[0] : null;
};

export const setCorrespondenceDb = async (
  correspondence: ICorrespondence,
  idEntity: string,
  idUser: number,
  namesUser: string,
  edit: boolean
): Promise<IResponseFirebase> => {
  const isParam = correspondence.communicationType
    ? correspondence.communicationType.isParam
    : false;

  correspondence.internalState =
    isParam && correspondence.inputType !== CorrespondenceInputType.IP
      ? InternalCorrespondenceStatus.UNANSWERED
      : InternalCorrespondenceStatus.NO_ANSWER_REQUIRED;
  const now = firebase.firestore.Timestamp.now();
  //correspondence.createAt = now;
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(correspondence.id)
      .set(
        {
          ...correspondence,
          createAt: correspondence.createAt ? correspondence.createAt : now,
          type: TypeInbox.OFFICIAL_COMMUNICATION,
          isParam,
        },
        { merge: true }
      );

    await addMilestoneDb(
      idEntity,
      correspondence.id,
      now,
      edit ? "Editado" : "Radicado",
      idUser,
      namesUser
    );

    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const updateFilesCorrespondence = async (
  attachedDocuments: IFiles[],
  entityId: string,
  correspondenceId: string
) => {
  try {
    await db
      .collection("Correspondence")
      .doc(entityId)
      .collection("CorrespondenceSection")
      .doc(correspondenceId)
      .set(
        {
          digitalSupport: attachedDocuments,
        },
        {
          merge: true,
        }
      );
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const updateFilesAnswerCorrespondence = async (
  attachedDocuments: IFiles[],
  entityId: string,
  correspondenceId: string
) => {
  try {
    await db
      .collection("Correspondence")
      .doc(entityId)
      .collection("CorrespondenceSection")
      .doc(correspondenceId)
      .set(
        {
          answer: {
            attachedDocuments,
          },
        },
        {
          merge: true,
        }
      );
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const setEditCorrespondenceDb = async (
  correspondence: ICorrespondence,
  idEntity: string
  // edit: boolean
): Promise<IResponseFirebase> => {
  const isParam = correspondence.communicationType
    ? correspondence.communicationType.isParam
    : false;
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(correspondence.id)
      .set(
        {
          ...correspondence,
          isParam,
        },
        { merge: true }
      );

    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// AGREGAR DOCUMENTO ADJUNTO A CORRESPONDENCIA
export const addAttachedDocumentCorrespondenceDb = async (
  idCorrespondence: string,
  values: IAppendDocs[],
  idEntity: string
  // edit: boolean
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(idCorrespondence)
      .set({ appendDocs: values }, { merge: true });

    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const deleteFileCorrespondence = async (
  idEntity: string,
  fileName: string
): Promise<IResponseFirebase> => {
  try {
    await firebase
      .storage()
      .ref(`/correspondence/${idEntity}/${fileName}`)
      .delete();
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// Crear External Sender
export const addExternalSender = async (
  idEntity: string,
  idDoc: string | null,
  form: IExternalSender
) => {
  try {
    let docRef = db
      .collection("Entities")
      .doc(idEntity)
      .collection("ExternalSenders");
    delete form.idDoc;
    if (idDoc) {
      await docRef.doc(`${idDoc}`).set(form, { merge: true });
      await updateUserCorrespondence(idDoc, form.name);
      return {
        ok: true,
        data: idDoc,
      };
    } else {
      const response = await docRef.add(form);

      return {
        ok: true,
        data: response.id as string,
      };
    }
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const searchExternalSendersByNameAndIdEntity = async (
  name: string,
  idEntity: string
) => {
  let documents;
  if (name === "") {
    documents = await db
      .collection("Entities")
      .doc(idEntity)
      .collection("ExternalSenders")
      .orderBy("name", "asc")
      .limit(50)
      .get();
  } else {
    documents = await db
      .collection("Entities")
      .doc(idEntity)
      .collection("ExternalSenders")
      .orderBy("name", "asc")
      .startAt("!")
      .endAt(`${name}\uf8ff`)
      .get();
  }

  const data = documents.docs.map((doc) => {
    const idDoc = doc.id;
    return {
      ...doc.data(),
      idDoc,
    };
  });
  // resp.push({ ...doc.data(), idDoc } as any);
  return data;
};

//Actualizar nombre remitente externo en cada correspondencia asociada
const updateUserCorrespondence = async (idDoc: string, name: string) => {
  try {
    const correspondences = await db.collection("Correspondence").get();

    correspondences.forEach(async (co) => {
      const correspondenceSection = db
        .collection("Correspondence")
        .doc(co.id)
        .collection("CorrespondenceSection");

      await updateNameAddressee(correspondenceSection, idDoc, name);
      await updateNameExternalSender(correspondenceSection, idDoc, name);
    });
  } catch (error) {
    return;
  }
};

//Actualiza nombre remitente externo cuando el remitente es el usuario receptor
const updateNameAddressee = async (
  correspondenceSection: firebase.firestore.Query<firebase.firestore.DocumentData>,
  idDoc: string,
  name: string
) => {
  await correspondenceSection
    .where("addressee.id", "==", idDoc)
    .get()
    .then(function (
      querySnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
    ) {
      const batch = db.batch();
      querySnapshot.forEach((doc) => {
        batch.set(
          doc.ref,
          {
            addressee: {
              name,
            },
          },
          { merge: true }
        );
      });

      batch.commit().then(() => {});
    });
};

//Actualiza nombre remitente externo cuando el remitente es el usuario radicador
const updateNameExternalSender = async (
  correspondenceSection: firebase.firestore.Query<firebase.firestore.DocumentData>,
  idDoc: string,
  name: string
) => {
  await correspondenceSection
    .where("externalSender.id", "==", idDoc)
    .get()
    .then(function (querySnapshot) {
      const batch = db.batch();
      querySnapshot.forEach((doc) => {
        batch.set(
          doc.ref,
          {
            externalSender: {
              name,
            },
          },
          { merge: true }
        );
      });

      batch.commit().then(() => {});
    });
};

// cargar remitentes externos
export const loadExternalSenders = async (idEntity: string) => {
  const docRef = db
    .collection(`Entities`)
    .doc(idEntity)
    .collection("ExternalSenders");

  const senders = await docRef.get();
  const resp: IExternalSender[] = [];

  senders.forEach((doc) => {
    const idDoc = doc.id;
    resp.push({ ...doc.data(), idDoc } as any);
  });

  return resp;
};

//AGREGAR COLLECTION MILESTONE
export const addMilestoneDb = async (
  idEntity: string,
  correspondenceId: string,
  date: firebase.firestore.Timestamp,
  process: string,
  idUser: number,
  namesUser: string | undefined
) => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(correspondenceId)
      .collection("Milestones")
      .add({
        owner: { name: namesUser, id: idUser },
        createAt: date,
        processName: process,
      });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};
export const addMilestoneExpedientManagment = async (
  idEntity: string,
  expedientId: string,
  date: firebase.firestore.Timestamp,
  process: string,
  idUser: number,
  namesUser: string | undefined
) => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("Expedients")
      .doc(expedientId)
      .collection("Milestones")
      .add({
        owner: { name: namesUser, id: idUser },
        createAt: date,
        processName: process,
      });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const addAnswersCorrespondence = async (
  idEntity: string,
  correspondenceId: string,
  answer: ICorrespondenceAnswer
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(correspondenceId)
      .collection("Answers")
      .add({
        ...answer,
        submitDate: firebase.firestore.Timestamp.now(),
      });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const getCorrespondenceInbox = async (
  idUser: string
): Promise<ICorrespondenceInfo[] | null> => {
  try {
    const snap = await db
      .collection("Users")
      .doc(idUser)
      .collection("Inbox")
      .where("type", "==", "OFFICIAL_COMMUNICATION")
      .get();
    const response: ICorrespondenceInfo[] = [];
    snap.forEach((doc) => {
      response.push(doc.data() as ICorrespondenceInfo);
    });
    return response;
  } catch (error) {
    return null;
  }
};
export const getCorrespondenceUser = async (
  idEntity: string,
  idUser: string
): Promise<ICorrespondence[] | null> => {
  try {
    const response: ICorrespondence[] = [];
    const snapShot = await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .where("externalSender.id", "==", idUser)
      .where("addressee.id", "in", idUser)
      .orderBy("createAt", "desc")
      .get();
    snapShot.forEach((doc) => {
      response.push(doc.data() as ICorrespondence);
    });
    return response;
  } catch (error) {
    return null;
  }
};
export const getPqrs = async (
  idEntity: string
): Promise<ICorrespondence[] | null> => {
  try {
    const snap = await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .where("answeredDate", "==", null)
      .get();
    const response: ICorrespondence[] = [];
    snap.forEach((doc) => {
      response.push(doc.data() as ICorrespondence);
    });
    return response;
  } catch (error) {
    return null;
  }
};

// TRAER MILESTONES DE CORRESPONDENCESECTION DB
export const getMilestones = async (
  idEntity: string | undefined,
  correspondenceId: string
): Promise<IMilestones[]> => {
  // return async ( getState: Function) => {
  // const { user } = (getState() as AppState).auth
  const snapShot = await db
    .collection("Correspondence")
    .doc(idEntity)
    .collection("CorrespondenceSection")
    .doc(correspondenceId)
    .collection("Milestones")
    .orderBy("createAt", "desc")
    .get();
  const resp: IMilestones[] = [];
  snapShot.forEach((doc) => {
    resp.push(doc.data() as IMilestones);
  });
  return resp;
};

// AGERGAR OFICINA DE DEPENDENCIA CONTRATACION
export const setContractingOfficeDb = async (
  data: IContractingOffice
): Promise<IResponseFirebase> => {
  try {
    await db.collection("Correspondence").doc(data.idEntity).set(
      {
        productionOffice: data.productionOffice,
        responsibleUser: data.responsibleUser,
      },
      { merge: true }
    );

    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// TRAER CONTRACTING OFFICE DB
export const getContractingOffice = async (
  idEntity: string
): Promise<IContractingOffice | null> => {
  const snapShot = await db.collection("Correspondence").doc(idEntity).get();
  if (!snapShot.exists) {
    return null;
  }
  return snapShot.data() as IContractingOffice;
};

// Licitaciones
export const loadTenders = async (idEntity: string) => {
  const docRef = db
    .collection(`Correspondence`)
    .doc(idEntity)
    .collection("Tenders");

  const tenders = await docRef.get();
  const resp: ITenders[] = [];

  tenders.forEach((doc) => {
    resp.push(doc.data() as ITenders);
  });

  return resp;
};

// Editar correspondencia
export const updateCorrespondenceDB = async (
  correspondence: ICorrespondence,
  idEntity: string
) => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(correspondence.id)
      .set(correspondence, { merge: true });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const setSendCorrespondenceCopy = async (
  idEntity: string,
  idCorrespondence: string,
  to: IUserCopy[]
): Promise<IResponseFirebase> => {
  try {
    const usersIds = to.map((user) => user.id);
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(idCorrespondence)
      .set({ copyTo: to, copyToIds: usersIds }, { merge: true });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};
export const setRequestProjection = async (
  idEntity: string,
  idCorrespondence: string,
  data: any
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(idCorrespondence)
      .set(
        { screeningRequest: data, isReadedProjection: false },
        { merge: true }
      );
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

//AGREGAR SUBCOLLECTION CorresAnnotations
export const addCorresAnnotationsDb = async (
  idEntity: string,
  correspondenceId: string,
  annotation: ICorresAnnotations
) => {
  try {
    const { id } = annotation;
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(correspondenceId)
      .collection("CorresAnnotations")
      .doc(id)
      .set({
        ...annotation,
      });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const addCorrespondenceResponse = async (
  idEntity: string,
  idCorrespondence: string,
  response: ICorrespondenceResponse
): Promise<IResponseFirebase> => {
  try {
    const now = response.submitDate
      ? response.submitDate
      : firebase.firestore.Timestamp.now();
    const idResp = response.idAnswer
      ? response.idAnswer
      : now.toDate().getTime().toString();

    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(idCorrespondence)
      .collection("Answers")
      .doc(idResp)
      .set({ ...response, submitDate: now, idAnswer: idResp }, { merge: true });
    return {
      ok: true,
      data: { answerId: idResp },
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const updateEmailCorrespondece = async (
  inputDataEmail: IInputDataEmail
) => {
  try {
    const emailDataCopy: IFormSendEmailCopy = {
      messageId: inputDataEmail.messageId,
      email: inputDataEmail.email,
      status: [...inputDataEmail.emailCopyStatus],
    };
    const answer = await db
      .collection("Correspondence")
      .doc(inputDataEmail.entityId)
      .collection("CorrespondenceSection")
      .doc(inputDataEmail.correspondenceId)
      .collection("Answers")
      .doc(inputDataEmail.emailAnswerParentId)
      .get();

    const answerData = answer.data() as ICorrespondenceResponse;
    const answersCopy = answerData.emailDataCopy ?? [];

    answersCopy.push(emailDataCopy);

    await answer.ref.set({ emailDataCopy: answersCopy }, { merge: true });

    return { ok: true };
  } catch (error) {
    return { ok: false, data: error };
  }
};

export const getCorrespondenceAnswers = async (
  idEntity: string,
  idCorrespondence: string,
  callback: Function
) => {
  return db
    .collection("Correspondence")
    .doc(idEntity)
    .collection("CorrespondenceSection")
    .doc(idCorrespondence)
    .collection("Answers")
    .orderBy("submitDate", "desc")
    .onSnapshot((snap) => {
      const resp: ICorrespondenceResponse[] = [];
      snap.forEach((doc) => {
        resp.push(doc.data() as ICorrespondenceResponse);
      });
      if (resp.length > 0) {
        callback(resp);
      }
      callback(null);
    });
};
export const getCorrespondenceIScreeningAnswer = async (
  idEntity: string,
  idCorrespondence: string
) => {
  const docRef = db
    .collection("Correspondence")
    .doc(idEntity)
    .collection("CorrespondenceSection")
    .doc(idCorrespondence)
    .collection("Answers")
    .where("isScreeningAnswer", "==", true)
    .limit(1);

  const corresAnswer = await docRef.get();
  const resp: any[] = [];

  corresAnswer.forEach((doc) => {
    resp.push(doc.data() as any);
  });

  return resp;
};

export const loadDepositsDb = async (
  idEntity: string,
  productionOf: string
): Promise<IDeposits[]> => {
  const snapShot = await db
    .collection("Correspondence")
    .doc(idEntity)
    .collection("Deposits")
    .where("productionOffice", "==", productionOf)
    .get();
  const resp: IDeposits[] = [];
  snapShot.forEach((doc) => {
    resp.push(doc.data() as IDeposits);
  });
  return resp;
};
export const addNewDepositDb = async (
  idEntity: string,
  deposit: IDeposits
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("Deposits")

      .doc(deposit.id)
      .set({ ...deposit });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: true,
      data: error,
    };
  }
};

export const submitFinalAnswer = async (
  idEntity: string,
  idCorrespondence: string,
  answer: ICorrespondenceResponse,
  status: CorrespondenceStatus,
  internalState: InternalCorrespondenceStatus
): Promise<IResponseFirebase> => {
  try {
    //firebase.firestore.FieldValue.serverTimestamp()
    const idDoc = answer.submitDate.toDate().getTime().toString();
    const docRef = db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(idCorrespondence);
    await docRef.set(
      {
        answer: {
          idAnswer: idDoc,
          subject: answer.subject,
          description: answer.description,
          attachedDocuments: answer.attachedDocuments,
        },
        answeredDate: answer.submitDate,
        status,
        internalState,
      },
      { merge: true }
    );
    await docRef
      .collection("Answers")
      .doc(answer.submitDate.toDate().getTime().toString())
      .set({
        ...answer,
        idAnswer: answer.submitDate.toDate().getTime().toString(),
      });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

//Agregar Addcional request SUBCOLLECCION Answers
export const addAddicionalInformationDb = async (
  idEntity: string,
  correspondenceId: string,
  docs: IFiles[],
  description: string,
  subectj: string,
  isScreeningAnswer: boolean,
  id: string,
  officer: IUserCorrespondence,
  createDate: firebase.firestore.Timestamp
) => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(correspondenceId)
      .collection("Answers")
      .doc(id)
      .set({
        description: description,
        subject: subectj,
        id: id,
        attachedFiles: docs.length > 0 ? docs : null,
        officer: officer,
        submitDate: createDate,
        isScreeningAnswer,
      });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

//AGREGAR COLLECTION Fuid
export const addNewFuid = async (
  idEntity: string,
  // fuid: string
  fuid: IFuid,
  fromModule?: boolean
) => {
  try {
    await db
      .collection(fromModule ? "FileModule" : "Correspondence")
      .doc(idEntity)
      .collection("Expedients")
      .doc(fuid.id)
      .set(
        {
          ...fuid,
          createDate: fuid.createDate
            ? fuid.createDate
            : firebase.firestore.Timestamp.now(),
        },
        { merge: true }
      );
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};
export const addOrUpdateTender = async (
  idEntity: string,
  tender: ITenders
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("Tenders")
      .doc(tender.id)
      .set({ ...tender }, { merge: true });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};
export const addCorrespondenceTender = async (
  idEntity: string,
  tenderCorrespondence: ITenderCorrespondence
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(tenderCorrespondence.id)
      .set({ ...tenderCorrespondence });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};
export const getCorrespondenceStadistics = async (
  idEntity: string,
  communicationChannel: string,
  idProductionOf: string,
  endDate: string,
  officer: IUserCorrespondence,
  startDate: string
) => {
  const parseStartDate = firebase.firestore.Timestamp.fromDate(
    new Date(startDate)
  );
  const parseEndDate = firebase.firestore.Timestamp.fromDate(new Date(endDate));

  let docRef =
    idProductionOf === "Todos"
      ? db
          .collection("Correspondence")
          .doc(idEntity)
          .collection("CorrespondenceSection")
          .where("createAt", ">=", parseStartDate)
          .where("createAt", "<=", parseEndDate)
      : db
          .collection("Correspondence")
          .doc(idEntity)
          .collection("CorrespondenceSection")
          .where("receivingDependency", "==", idProductionOf)
          .where("createAt", ">=", parseStartDate)
          .where("createAt", "<=", parseEndDate);

  const newDocRef =
    communicationChannel === "Todos"
      ? docRef
      : docRef.where("communicationChannel", "==", communicationChannel);

  let response: ICorrespondence[] = [];
  //Consultar las que recibio
  const snapAddressee =
    officer.name === "Todos"
      ? await newDocRef.orderBy("createAt", "desc").get()
      : await newDocRef
          .where("addressee", "==", officer)
          .orderBy("createAt", "desc")
          .get();
  snapAddressee.forEach((doc) => {
    response.push(doc.data() as any);
  });

  //consultar las que envio
  const snapOfficer =
    officer.name === "Todos"
      ? await newDocRef.orderBy("createAt", "desc").get()
      : await newDocRef
          .where("externalSender", "==", officer)
          .orderBy("createAt", "desc")
          .get();
  snapOfficer.forEach((doc) => {
    response.push(doc.data() as any);
  });
  //borrar duplicados
  let hash: any = {};
  response = response.filter((current) => {
    let exists = !hash[current.id];
    hash[current.id] = true;
    return exists;
  });
  return response;
};

export const sendEmailNotification = (data: IEmailNotificationParams) => {
  data.emailSender = "comercial@appsus.co";
  return axios
    .post(
      `https://us-central1-modulosmialcaldia.cloudfunctions.net/sendNotificationEmail`,
      {
        ...data,
      }
    )
    .then((resp: any) => {
      const { data } = resp;
      return data as ICorrespondenceEmailResponse;
    })
    .catch((err) => {
      console.log(err);
    });
};

/* CARGAR PARAMETRIZACION LABEL SIZE A CORRESPONCENCE */
export const loadLabelSizeDb = async (idEntity: string) => {
  const param = await db.collection("Correspondence").doc(idEntity).get();
  return (param.data() as any).labelSize;
};

/* AGREGAR PARAMETRIZACION LABEL SIZE EN DATABASE */
export const setLabelSizeDb = async (
  data: ILabelSize
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection("Correspondence")
      .doc(data.idEntity)
      .set({ labelSize: data.labelSize }, { merge: true });

    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const getInfoCompilationFiling = async (
  idEntity: string,
  idProductionOf: string,
  endDate: string,
  officer: IUserCorrespondence,
  startDate: string
) => {
  const parseStartDate = firebase.firestore.Timestamp.fromDate(
    new Date(startDate)
  );
  const parseEndDate = firebase.firestore.Timestamp.fromDate(new Date(endDate));

  let docRef =
    idProductionOf === "Todos"
      ? db
          .collection("Correspondence")
          .doc(idEntity)
          .collection("CorrespondenceSection")
          .where("createAt", ">=", parseStartDate)
          .where("createAt", "<=", parseEndDate)
          .where("inputType", "==", CorrespondenceInputType.ER)
          .where(
            "communicationChannel",
            "==",
            CommunicationChannels.SINGLE_WINDOW
          )
      : db
          .collection("Correspondence")
          .doc(idEntity)
          .collection("CorrespondenceSection")
          .where("producerDependency", "==", idProductionOf)
          .where("createAt", ">=", parseStartDate)
          .where("createAt", "<=", parseEndDate)
          .where("inputType", "==", CorrespondenceInputType.ER)
          .where(
            "communicationChannel",
            "==",
            CommunicationChannels.SINGLE_WINDOW
          );

  let response: ICorrespondence[] = [];
  //Consultar las que recibio
  const snapAddressee =
    officer.name === "Todos"
      ? await docRef.orderBy("createAt", "desc").get()
      : await docRef
          .where("addressee", "==", officer)
          .orderBy("createAt", "desc")
          .get();
  snapAddressee.forEach((doc) => {
    response.push(doc.data() as any);
  });

  //consultar las que envio
  const snapOfficer =
    officer.name === "Todos"
      ? await docRef.orderBy("createAt", "desc").get()
      : await docRef
          .where("externalSender.id", "==", officer.id)
          .orderBy("createAt", "desc")
          .get();
  snapOfficer.forEach((doc) => {
    response.push(doc.data() as any);
  });
  //borrar duplicados
  let hash: any = {};
  response = response.filter((current) => {
    let exists = !hash[current.id];
    hash[current.id] = true;
    return exists;
  });

  return response;
};

export const getAnswersCorrespondence = async (
  idEntity: string,
  idSettled: string
) => {
  const docRef = await db
    .collection("Correspondence")
    .doc(idEntity)
    .collection("CorrespondenceSection")
    .where("settledAnswerId", "==", idSettled)
    .orderBy("createAt", "desc")
    .get();

  let response: ICorrespondence[] = [];

  docRef.forEach((doc) => {
    response.push(doc.data() as any);
  });

  //borrar duplicados
  let hash: any = {};
  response = response.filter((current) => {
    let exists = !hash[current.id];
    hash[current.id] = true;
    return exists;
  });

  return response;
};

export const getInfoCorrespondenceReceived = async (
  idEntity: string,
  dependency: string,
  inputType: CorrespondenceInputType | "Todos",
  startDate: string,
  endDate: string,
  channel: string
) => {
  const parseStartDate = firebase.firestore.Timestamp.fromDate(
    new Date(startDate)
  );
  const parseEndDate = firebase.firestore.Timestamp.fromDate(new Date(endDate));

  //Filtrar por fechas
  let docRef = db
    .collection("Correspondence")
    .doc(idEntity)
    .collection("CorrespondenceSection")
    .where("createAt", ">=", parseStartDate)
    .where("createAt", "<=", parseEndDate);

  // Filtrar por canal
  docRef =
    channel === "Todos"
      ? docRef
      : docRef.where("communicationChannel", "==", channel);

  //Filtrar por dependencia
  docRef =
    dependency === "Todos"
      ? docRef
      : docRef.where("receivingDependency", "==", dependency);

  // Filtrar por tipo de entrada
  let docFilterInputType;
  let docInputTypeER;
  let docInputTypeIR;
  let docInputTypeOR;
  if (inputType === "Todos") {
    docInputTypeER = docRef.where(
      "inputType",
      "==",
      CorrespondenceInputType.ER
    );
    docInputTypeIR = docRef.where(
      "inputType",
      "==",
      CorrespondenceInputType.IR
    );
    docInputTypeOR = docRef.where(
      "inputType",
      "==",
      CorrespondenceInputType.OR
    );
  } else {
    docFilterInputType = docRef.where("inputType", "==", inputType);
  }

  let response: ICorrespondence[] = [];
  // Si inputType !== Todos
  const snapRefFilterReceiving =
    docFilterInputType &&
    (await docFilterInputType.orderBy("createAt", "asc").get());
  snapRefFilterReceiving &&
    snapRefFilterReceiving.forEach((doc) => {
      response.push(doc.data() as any);
    });

  // Si inputType === ER
  const snapRefReceivingER =
    docInputTypeER && (await docInputTypeER.orderBy("createAt", "asc").get());
  snapRefReceivingER &&
    snapRefReceivingER.forEach((doc) => {
      response.push(doc.data() as any);
    });

  // Si inputType === IR
  const snapRefReceivingIR =
    docInputTypeIR && (await docInputTypeIR.orderBy("createAt", "asc").get());
  snapRefReceivingIR &&
    snapRefReceivingIR.forEach((doc) => {
      response.push(doc.data() as any);
    });

  // Si inputType === OR
  const snapRefReceivingOR =
    docInputTypeOR && (await docInputTypeOR.orderBy("createAt", "asc").get());
  snapRefReceivingOR &&
    snapRefReceivingOR.forEach((doc) => {
      response.push(doc.data() as any);
    });

  //borrar duplicados
  let hash: any = {};
  response = response.filter((current) => {
    let exists = !hash[current.id];
    hash[current.id] = true;
    return exists;
  });

  for (const resp of response) {
    const docOwner = await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(resp.id)
      .collection("Milestones")
      .where("processName", "==", "Radicado")
      .limit(1)
      .get();

    docOwner.forEach((doc: any) => {
      const data = doc.data() as any;
      resp.owner = data.owner;
    });
  }

  response = response.sort(
    (a, b) =>
      moment(a.createAt.toDate(), "DD-MM-YYYY").unix() -
      moment(b.createAt.toDate(), "DD-MM-YYYY").unix()
  );

  return response;
};

import clsx from "clsx";
import memoize from "fast-memoize";
import { Formik, Form } from "formik";
import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Grid,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { MyTextField } from "../../custom/MyTextField";
import { IUserCorrespondence } from "../../../interfaces/Correspondence";
import { User } from "../../../interfaces/Users";
import { uiCloseModalAdd } from "../../../redux/actions/uiActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
import { Enum_LoanState, ILoan } from "../../../interfaces/Loans";
import { firebase } from "../../../config/firebase/firebase-config";
import { startSetLoan } from "../../../redux/actions/fileModuleActions";
import moment from "moment";
interface Props {
  component: "Physical" | "Digital";
  edit?: boolean;
}

export const LoansForm = ({ component, edit = false }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { expedients, activeLoan } = useSelector(
    (state: AppState) => state.fileModule
  );
  const { productionOffices } = useSelector((state: AppState) => state.trd);
  const { users } = useSelector((state: AppState) => state.users);
  const [officerSelected, setOfficerSelected] =
    useState<IUserCorrespondence | null>(
      activeLoan ? activeLoan.officer : null
    );
  const [errorOfficer, setErrorOfficer] = useState(false);

  const initialValues = {
    id: activeLoan ? activeLoan.id : "",
    idExpedient: activeLoan ? activeLoan.idExpedient : "",
    description: activeLoan ? activeLoan.description : "",
    phone: activeLoan ? activeLoan.phone : "",
    dependency: activeLoan ? activeLoan.dependency : "",
    officer: activeLoan ? activeLoan.officer : { id: "", name: "" },
    returnDate: activeLoan
      ? activeLoan.returnDate.toDate().toLocaleDateString("en-CA")
      : new Date().toLocaleDateString("en-CA"),
    isDigitalLoan: component === "Digital" ? true : false,
  };

  const validationSchema = yup.object({
    idExpedient: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    description: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    dependency: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    officer: yup.object(),
    returnDate: yup
      .date()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    phone: yup.string().required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  const onClose = () => {
    dispatch(uiCloseModalAdd());
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUsers = useCallback(
    memoize((dependency: string) => {
      const filteredUsers: User[] = (users as User[]).filter((el) =>
        el.offices.toString().includes(dependency)
      );
      const arrayUsers: IUserCorrespondence[] = [];

      filteredUsers.forEach((user: User) => {
        arrayUsers.push({
          id: user.id.toString(),
          name:
          user.firstName +
          " " +
          user.secondName +
          " " +
          user.firstLastName +
          " " +
          user.secondLastName,
        });
      });

      return arrayUsers;
    }),
    []
  );

  const handleSelectOfficer = (value: any) => {
    !value ? setErrorOfficer(true) : setErrorOfficer(false);

    value ? setOfficerSelected(value) : setOfficerSelected(null);
  };

  const validateOfficer = () => {
    !officerSelected ? setErrorOfficer(true) : setErrorOfficer(false);
  };
  return (
    <Box m={2} mt={-1} mb={2}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (!errorOfficer) {
            values.officer = officerSelected as IUserCorrespondence;
            setSubmitting(true);
        
            const obj: ILoan = {
              ...values,
              id:values.id,
              createAt:  activeLoan?activeLoan.createAt: firebase.firestore.Timestamp.now(),
              returnDate: firebase.firestore.Timestamp.fromDate(
                moment(values.returnDate).toDate()
              ),
              state: Enum_LoanState.WithoutReturning,
            };
           
            await dispatch(startSetLoan(obj,edit, intl));
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="SelectExpedient" />:
                </label>
                <MyTextField
                  name="idExpedient"
                  className={classes.myTextFieldRoot}
                  select
                  variant="outlined"
                  disabled={edit}
                >
                  {expedients.map((exp) => (
                    <MenuItem key={exp.id} value={exp.id}>
                      {exp.fileName}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="FileDescription" />:
                </label>
                <MyTextField
                  name="description"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  multiline
                  rows={4}
                  disabled={edit}
                />
              </Grid>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Phone" />:
                </label>
                <MyTextField
                  name="phone"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="SelectDependency" />:
                </label>
                <MyTextField
                  name="dependency"
                  className={classes.myTextFieldRoot}
                  select
                  variant="outlined"
                  disabled={edit}
                >
                  {productionOffices.map((office) => (
                    <MenuItem
                      key={office.idProductionOffice}
                      value={office.idProductionOffice}
                    >
                      {office.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="OfficerRequestsLoan" />:
                </label>
                <Autocomplete
                  id="officer"
                  value={officerSelected}
                  options={getUsers(values.dependency)}
                  disabled={edit}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderOption={(option) => <>{option.name}</>}
                  onChange={(event: any, value: IUserCorrespondence | null) =>
                    handleSelectOfficer(value)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      name="addressee"
                      className={classes.myTextFieldRoot}
                      helperText={
                        errorOfficer &&
                        `${intl.formatMessage({ id: "RequiredFile" })}`
                      }
                      error={errorOfficer}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={8}>
                <label className="form-text">
                  <FormattedMessage id="DaysLoanRequested" />:
                </label>
                <MyTextField
                  name="returnDate"
                  variant="outlined"
                  type="date"
                  className={classes.myTextFieldRoot}
                />
              </Grid>
            </Grid>

            <Box mt={2} display="flex" flexDirection="row-reverse" width={1}>
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={isSubmitting}
                  onClick={validateOfficer}
                >
                  <FormattedMessage id="Save" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

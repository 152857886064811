
export const counter = (value:any, array: any[], data?: any[] ): number => {

    let count: number = 0;

    if (!value.trim()) {
        return count = array.length;
      } else {
        if (data) {
          return count = data.length;
        }
      }

    return count;

} 
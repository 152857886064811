import { FC } from "react";
import { useDispatch } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";
import { CustomizedIcons } from "../../../components/custom/CustomizedIcons";
import { uiOpenModalDelete } from "../../../redux/actions/uiActions";
import { IExpedientCorrespondence } from "../../../interfaces/Correspondence";
import { setQuestionMsg, setTitleMsg } from "../../../redux/actions/uiActions";
import { setActiveCorresExpedient } from "../../../redux/actions/documentManagmentActions";

interface props {
  settle: IExpedientCorrespondence;
  readOnly?: boolean;
  component?: string;
}

export const FileBody: FC<props> = ({
  settle,
  readOnly = false,
  component = "OTHER",
}) => {
  const dispatch = useDispatch();

  const onDeleteFile = async () => {
    dispatch(setActiveCorresExpedient(settle));
    dispatch(setTitleMsg("DeleteFile"));
    dispatch(setQuestionMsg("DeleteFileQuestion"));
    dispatch(uiOpenModalDelete());
  };

  return (
    <TableRow key={settle.id}>
      <TableCell component="th" scope="row" align="center">
        {settle.name}
      </TableCell>
      <TableCell align="center" style={{ textTransform: "lowercase" }}>
        {settle.description}
      </TableCell>
      {!readOnly && (
        <TableCell align="center">
          {component !== "COPY" ? (
            <CustomizedIcons deleteIcon onDelete={onDeleteFile} />
          ) : (
            <></>
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

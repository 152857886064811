import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

import { CustomizedSearch } from "../../components/custom/CustomizedSearch";
import CustomizedDialog from "../../components/custom/CustomizedDialog";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import { CustomizedWindow } from "../../components/custom/CustomizedWindow";
import {
  uiOpenModalAdd,
  uiCloseModalAdd,
  uiCloseErrorAlert,
  uiCloseSuccessAlert,
  uiCloseModalEdit,
  uiCloseModalSee,
} from "../../redux/actions/uiActions";
import { useStyles } from "../../shared/styles/useStyles";
import { AppState } from "../../redux/reducers/rootReducer";
import { FileForm } from "../../components/file-module/fuid/FileForm";
import { FuidTable } from "../../components/fuid/FuidTable";
import { MyAlert } from "../../components/custom/MyAlert";
import { setActiveFuid } from "../../redux/actions/fuidActions";
import { FuidDetails } from "../../components/document-managment/correspondence/FuidDetails";
import { FC } from "react";
interface props {
  fromCopies?: boolean;
}
export const FuidManagmentScreen: FC<props> = ({ fromCopies = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { modalAddOpen, modalEditOpen, successMsg, successAlert, errorAlert } =
    useSelector((state: AppState) => state.ui);

  const { expedients: expedientsCentralFile } = useSelector(
    (state: AppState) => state.fileModule
  );
  const { expedients: expedientsManagment } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { user } = useSelector((state: AppState) => state.auth);
  const expedients =
    fromCopies && user
      ? expedientsManagment
          .filter((exp) => exp.copyToIds?.includes(user.id.toString()))
          .concat(
            expedientsCentralFile.filter((exp) =>
              exp.copyToIds?.includes(user.id.toString())
            )
          )
      : user ? expedientsManagment.filter((exp)=>exp.owner.id === user.id.toString()):[]
  const { activeExpedient } = useSelector((state: AppState) => state.fuid);
  const { modalSeeOpen } = useSelector((state: AppState) => state.ui);

  const onDeny = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalSee());
    dispatch(uiCloseModalEdit());
    dispatch(setActiveFuid(null));
  };

  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box>
        <Box mb={3} fontSize={20}>
          <Grid container>
            {!fromCopies?
            
            <Grid item xs={6}>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                className={classes.btnAdd}
                type="button"
                onClick={() => dispatch(uiOpenModalAdd())}
                style={{ width: "23rem" }}
              >
                <AddOutlinedIcon />
                <FormattedMessage id="NewFile" />
              </Button>
            </Grid>
          :
            <Grid item xs={6}/>
          }
            <Grid item xs={1} />
            <Grid item xs={5}>
              <CustomizedSearch data={expedients} />
            </Grid>
          </Grid>
        </Box>

        <FuidTable readOnly expedients={expedients} />

        <CustomizedWindow
          open={modalSeeOpen}
          content={<FuidDetails />}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "File" })} ${
            activeExpedient?.fileName
          }`}
        />

        <CustomizedDialog
          open={modalAddOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "NewFile" })}`}
          content={<FileForm />}
          textButton="Settle"
        />
        <CustomizedDialog
          open={modalEditOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "UpdateFile" })}`}
          content={<FileForm edit />}
          textButton="Accept"
        />
        {successMsg && (
          <MyAlert
            open={successAlert}
            typeAlert="success"
            message={successMsg}
            time={2000}
            handleClose={() => dispatch(uiCloseSuccessAlert())}
          />
        )}

        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={"SavingDataError"}
          time={2000}
          handleClose={() => dispatch(uiCloseErrorAlert())}
        />
      </Box>
    </AppAnimate>
  );
};

import clsx from "clsx";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  CircularProgress,
  createTheme,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Tooltip,
} from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { CustomizedTableFooter } from "../../custom/CustomizedTableFooter";
import { MyAlert } from "../../custom/MyAlert";
import { Office } from "../../../interfaces/OrganizationChart";
import { Colors } from "../../../shared/constants/themes/Colors";
import {
  startAssignOffice,
  startDeleteAssignedOffice,
} from "../../../redux/actions/usersActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
import {
  uiCloseAlertExists,
  uiCloseErrorAlert,
  uiCloseSuccessAlert,
} from "../../../redux/actions/uiActions";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});

export const AssignOffices = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    idVersion: "",
    idOffice: "",
  });
  const [selectedOffices, setSelectedOffices] = useState<Office[]>([]);
  const { versions } = useSelector((state: AppState) => state.trd);
  const { alertExists, errorAlert, successAlert, successMsg } = useSelector(
    (state: AppState) => state.ui
  );
  const { assignedOffices } = useSelector((state: AppState) => state.users);
  const { offices } = useSelector((state: AppState) => state.organizationChart);

  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );

  const handleSelectVersion = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValues({ ...values, idVersion: value, idOffice: "" });
    const officesFiltered = offices.filter(
      (office) => office.idVersion === value
    );
    setSelectedOffices(officesFiltered);
  };

  const handleSelectOffice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValues({ ...values, idOffice: value });
  };
  const handleAssign = async () => {
    if (values.idVersion !== "" && values.idOffice !== "") {
      setLoading(true);
      await dispatch(startAssignOffice(values.idVersion, values.idOffice));
      setLoading(false);
    }
  };

  const onDelete = async (office: Office) => {
    setLoading(true);
    await dispatch(startDeleteAssignedOffice(office));
    setLoading(false);
  };

  const closeAlert = () => {
    dispatch(uiCloseAlertExists());
    dispatch(uiCloseErrorAlert());
    dispatch(uiCloseSuccessAlert());
  };

  return (
    <Box m={0}>
      <Box mb={2}>
        <Box width={1} className={classes.typography} display="flex">
          <label style={{ marginTop: "10px" }} className="form-text">
            <FormattedMessage id="Version" />:
          </label>

          <Box ml={2} width={1} className={classes.typography}>
            <TextField
              name="idVersion"
              className={classes.inputSelect}
              onChange={handleSelectVersion}
              value={values.idVersion}
              select
              size="small"
              variant="outlined"
            >
              {versions.map((ver) => (
                <MenuItem key={ver.idVersion} value={ver.idVersion}>
                  {ver.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        <Box width={1} className={classes.typography} display="flex">
          <label
            style={{ marginTop: "10px", marginRight: "3px" }}
            className="form-text"
          >
            <FormattedMessage id="Office" />:
          </label>

          <Box ml={2} width={1} className={classes.typography}>
            <TextField
              name="idOffice"
              className={classes.inputSelect}
              onChange={handleSelectOffice}
              value={values.idOffice}
              select
              size="small"
              variant="outlined"
            >
              {selectedOffices.map((office: Office) => (
                <MenuItem key={office.idOffice} value={office.idOffice}>
                  {office.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end">
          <Box width={"30%"} mt={2}>
            {!loading ? (
              <Button
                autoFocus
                className={clsx(classes.btnAction, classes.save)}
                disabled={loading}
                onClick={handleAssign}
                size="medium"
                type="submit"
              >
                <FormattedMessage id="Assign" />
              </Button>
            ) : (
              <Button
                autoFocus
                className={clsx(classes.btnAction, classes.save)}
                disabled={true}
                size="medium"
                type="button"
              >
                <CircularProgress className={classes.btnLoading} />
              </Button>
            )}
          </Box>
        </Box>
        {/* </Grid> */}
      </Box>

      <ThemeProvider theme={theme}>
        <Box borderColor="grey.400" borderRadius={4}>
          <TableContainer component={Paper}>
            {assignedOffices.length === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ height: 150 }}
                className={classes.title}
              >
                <InfoOutlinedIcon
                  style={{ fontSize: 30, color: Colors.ACCENT }}
                />
                <FormattedMessage id={"UnassignedOffices"} />
              </Box>
            ) : (
              <Table
                className={classes.table}
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="AssignedOffices" />{" "}
                    </TableCell>
                    <TableCell align="center">
                      <FormattedMessage id="Delete" />{" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? assignedOffices.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : assignedOffices
                  ).map((office) => (
                    <TableRow key={office.idOffice}>
                      <TableCell size="small" component="th" scope="row">
                        {office.name}
                      </TableCell>
                      <TableCell size="small" align="center">
                        <Tooltip
                          title={`${intl.formatMessage({ id: "Delete" })}`}
                        >
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => onDelete(office)}
                          >
                            {" "}
                            <DeleteOutlineOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                {/* --------- Footer para paginación -------- */}
                <CustomizedTableFooter count={assignedOffices.length} />
              </Table>
            )}
          </TableContainer>
        </Box>
      </ThemeProvider>

      {successMsg && (
        <>
          <MyAlert
            open={alertExists}
            typeAlert="error"
            message={successMsg}
            time={2000}
            handleClose={closeAlert}
          />
          <MyAlert
            open={errorAlert}
            typeAlert="error"
            message={successMsg}
            time={2000}
            handleClose={closeAlert}
          />
          <MyAlert
            open={successAlert}
            typeAlert="success"
            message={successMsg}
            time={2000}
            handleClose={closeAlert}
          />
        </>
      )}
    </Box>
  );
};

import { FC, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  createTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { counter } from "../../../helpers/count";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
import { CustomizedTableFooter } from "../../custom/CustomizedTableFooter";
import { Colors } from "../../../shared/constants/themes/Colors";
import { CorrespondenceBody } from "./CorrespondenceBody";
import {
  setPage,
  setRowsPerPage,
} from "../../../redux/actions/paginationActions";
import { ICorrespondence } from "../../../interfaces/Correspondence";
import clsx from "clsx";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      whiteSpace: "nowrap",
    },
    head: {
      height: "40px",
    },
  })
)(TableCell);

interface props {
  readOnly?: boolean;
  pqrs?: boolean;
  rows?: ICorrespondence[] | null;
  component?: string;
  showState?: boolean;
}
export const CorrespondenceTable: FC<props> = ({
  readOnly = false,
  pqrs = false,
  rows = null,
  component = "OTHER",
  showState = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data, value } = useSelector((state: AppState) => state.search);
  const { user } = useSelector((state: AppState) => state.auth);
  const { correspondence: allCommunications } = useSelector(
    (state: AppState) => state.correspondence
  );
  const { correspondence: communicationsManagment } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const pqrsCorrespondence = communicationsManagment.filter(
    (el) =>
      el.communicationType?.isParam === true &&
      el.answeredDate === null &&
      user &&
      !el.copyToIds?.includes(user.id.toString()) &&
      !(el.screeningRequest && el.screeningRequest.length !== undefined
        ? !!el.screeningRequest.find(
            (item: any) => item.officerId === user.id.toString()
          )
        : el.screeningRequest?.officerId === user.id.toString()) &&
      !el.reviewers?.find((obj) => obj.id === user.id.toString())
  );
  const communicationsFilter =
    component === "Correspondence"
      ? communicationsManagment.filter(
          (el) =>
            user &&
            !el.copyToIds?.includes(user.id.toString()) &&
            !(el.screeningRequest && el.screeningRequest.length !== undefined
              ? !!el.screeningRequest.find(
                  (item: any) => item.officerId === user.id.toString()
                )
              : el.screeningRequest?.officerId === user.id.toString()) &&
            !el.reviewers?.find((obj) => obj.id === user.id.toString())
        )
      : communicationsManagment;

  let communications = readOnly
    ? pqrs
      ? pqrsCorrespondence
      : rows
      ? rows
      : communicationsFilter
    : allCommunications;

  useEffect(() => {
    readOnly ? dispatch(setRowsPerPage(6)) : dispatch(setRowsPerPage(5));
    dispatch(setPage(0));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );
  const count = counter(value, communications, data);
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, communications.length - page * rowsPerPage);
  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper} className={classes.heightTable}>
        {communications.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            {component === "VoBo" && (
              <FormattedMessage id={"NoPendingAprrovalRequest"} />
            )}
            {(component === "OTHER" || component === "Correspondence") && (
              <FormattedMessage id={"NoCorrespondences"} />
            )}
            {component === "Returned" && (
              <FormattedMessage id={"NoReturnedCorrespondence"} />
            )}
          </Box>
        ) : (
          <Table
            className={classes.table}
            size="small"
            style={{ width: "auto", tableLayout: "auto" }}
            aria-label="a dense table"
          >
            <TableHead className={classes.titleTable}>
              <TableRow>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Settled" />{" "}
                </StyledTableCell>
                <StyledTableCell
                  width={150}
                  align="center"
                  className={classes.titleTable}
                >
                  <FormattedMessage id="InputType" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="CommunicationType" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Subject" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Date" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Dependency" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Addressee" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Sender" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="TrafficLight" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Attachments" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="EmailsSended" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="FLR" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="DR" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="ER" />{" "}
                </StyledTableCell>
                {showState && (
                  <StyledTableCell
                    align="center"
                    className={classes.titleTable}
                  >
                    <FormattedMessage id="State" />{" "}
                  </StyledTableCell>
                )}
                {!readOnly && (
                  <StyledTableCell
                    align="center"
                    className={clsx(classes.titleTable, classes.fixedColumn)}
                  >
                    <FormattedMessage id="Actions" />{" "}
                  </StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!value.trim()
                ? (rowsPerPage > 0
                    ? communications.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : communications
                  ).map((row) => (
                    <CorrespondenceBody
                      communication={row}
                      key={row.id}
                      readOnly={readOnly}
                      isDocManagment
                      isRead={row.isReaded}
                      component={component}
                      showState={showState}
                    />
                  ))
                : data &&
                  (rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((row) => (
                    <CorrespondenceBody
                      communication={row}
                      key={row.id}
                      readOnly={readOnly}
                      isDocManagment
                      isRead={row.isReaded}
                      component={component}
                      showState={showState}
                    />
                  ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 13 * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
            {/* --------- Footer para paginación -------- */}
            <CustomizedTableFooter count={count} />
          </Table>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

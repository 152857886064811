import clsx from "clsx";
import { Form, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  uiCloseModalAdd,
  uiCloseModalEdit,
} from "../../../../redux/actions/uiActions";
import { MyTextField } from "../../../custom/MyTextField";
import { useStyles } from "../../../../shared/styles/useStyles";
import { ITrdDocumentalType } from "../../../../redux/types/types";
import { AppState } from "../../../../redux/reducers/rootReducer";
import {
  setActiveDocumentalType,
  startSetDocumentalType,
} from "../../../../redux/actions/trdActions";
import { Enum_FileSize } from "../../../../enums/enums";
interface props {
  edit?: boolean;
}
export const DocumentaryTypeForm: FC<props> = ({ edit = false }) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    versions,
    productionOffices,
    series,
    subSeries,
    activeDocumentalType,
  } = useSelector((state: AppState) => state.trd);
  const dispatch = useDispatch();

  const [labelImage, setLabelImage] = useState("");
  const [noValid, setNoValid] = useState(false);
  const [templateFile, setTemplateFile] = useState<File | null>(null);

  const validationSchema = yup.object({
    idVersion: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    idProductionOffice: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    idSerie: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    idSubSerie: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    code: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    name: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    supportPaper: yup.boolean(),
    supportElectronic: yup.boolean(),
  });

  let initialValues: Partial<ITrdDocumentalType> = {
    idVersion: "",
    idProductionOffice: "",
    code: "",
    name: "",
    supportPaper: false,
    supportElectronic: false,
    idSerie: "",
    idSubSerie: "",
    template: { fileName: "", url: "#" },
  };
  if (activeDocumentalType) {
    initialValues = {
      ...activeDocumentalType,
    };
  }

  const onClose = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    dispatch(setActiveDocumentalType(null));
  };

  const handleSelectFile = (e: any) => {
    const SUPPORTED_FORMATS = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/png",
      "application/msword",
      "text/csv",
      "application/pdf",
    ];
    const file = e.target.files[0] as File;
    const now = new Date().getTime().toString();

    if (file && file.size < Enum_FileSize.maxAllowedSize) {
      if (!SUPPORTED_FORMATS.includes(file.type)) {
        setNoValid(true);
      } else {
        setNoValid(false);
        setTemplateFile(file);
        setLabelImage(now + "_" + e.target.files[0].name);
      }
    } else {
      setNoValid(false);
      setLabelImage("");
    }
  };
  useEffect(() => {
    if (activeDocumentalType) {
      setLabelImage(activeDocumentalType.template.fileName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ARCHIVOS SOPORTADOS PARA INPUT TYPE=FILE
  const fileSupported = {
    accept:
      "application/pdf, image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv",
  };

  return (
    <Box m={1}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          data.template = {
            fileName: labelImage,
            url:
              activeDocumentalType && activeDocumentalType.template.url !== "#"
                ? activeDocumentalType.template.url
                : "#",
            file: templateFile as File,
          };

          await dispatch(startSetDocumentalType(data as ITrdDocumentalType));
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, values, handleChange }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Version" />*
                </label>
                <MyTextField
                  name="idVersion"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                  //   disabled={ active ? true : false}
                >
                  {versions.map((version) => (
                    <MenuItem key={version.idVersion} value={version.idVersion}>
                      {version.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="ProductionOffice" />*
                </label>
                <MyTextField
                  name="idProductionOffice"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                  //   disabled={ active ? true : false}
                >
                  {productionOffices.map((productionOffice) => (
                    <MenuItem
                      key={productionOffice.idProductionOffice}
                      value={productionOffice.idProductionOffice}
                    >
                      {productionOffice.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Series" />*
                </label>
                <MyTextField
                  name="idSerie"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                  //   disabled={ active ? true : false}
                >
                  {series.map((serie) => (
                    <MenuItem key={serie.idSerie} value={serie.idSerie}>
                      {serie.seriesName}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Subseries" />*
                </label>
                <MyTextField
                  name="idSubSerie"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                  disabled={subSeries.length <= 0}
                >
                  {subSeries.map((subSerie) => (
                    <MenuItem
                      key={subSerie.idSubserie}
                      value={subSerie.idSubserie}
                    >
                      {subSerie.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="DocumentaryTypeCode" />*
                </label>
                <MyTextField
                  name="code"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  disabled={edit}
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="DocumentaryTypeName" />*
                </label>
                <MyTextField
                  name="name"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Support" />
                </label>
                <Box display="flex">
                  <Box width={"20%"}>
                    <Checkbox
                      checked={values.supportPaper}
                      name="supportPaper"
                      onChange={handleChange}
                      color="default"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <label
                      className="form-text"
                      style={{ marginTop: "10px", marginLeft: "-5px" }}
                    >
                      <FormattedMessage id="Paper" />
                    </label>
                  </Box>

                  <Box width={"25%"}>
                    <Checkbox
                      checked={values.supportElectronic}
                      name="supportElectronic"
                      onChange={handleChange}
                      color="default"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                    <label
                      className="form-text"
                      style={{ marginTop: "10px", marginLeft: "-5px" }}
                    >
                      <FormattedMessage id="Electronic" />
                    </label>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <label className="form-text">
                  <FormattedMessage id="Template" />
                </label>
                <TextField
                  size="small"
                  type="file"
                  inputProps={fileSupported}
                  onChange={handleSelectFile}
                  id="contained-button-file"
                  autoComplete="off"
                  style={{ display: "none" }}
                />

                <MyTextField
                  disabled={true}
                  variant="outlined"
                  style={{ color: "black" }}
                  label={labelImage}
                  value={labelImage}
                  name="file"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={2}>
                <Box ml={-1}>
                  <label htmlFor="contained-button-file">
                    <Button
                      style={{ marginTop: "27px" }}
                      className={clsx(classes.btnAction, classes.save)}
                      size="medium"
                      component="span"
                    >
                      <FormattedMessage id="Attach" />
                    </Button>
                  </label>
                </Box>
              </Grid>
              {activeDocumentalType &&
                activeDocumentalType.template.url !== "#" && (
                  <Box style={{ fontSize: 12, color: "blue" }}>
                    <a
                      href={activeDocumentalType.template.url}
                      target="__blank"
                    >
                      {labelImage}
                    </a>
                  </Box>
                )}
              {noValid && (
                <Grid item xs={12}>
                  <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                    <FormattedMessage id="ValidDocumentalFiles" />
                  </Box>
                </Grid>
              )}
            </Grid>

            <Box mt={2} display="flex" flexDirection="row-reverse">
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="Save" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

import { db } from "../../config/firebase/firebase-config";
import { ICorrespondence } from "../../interfaces/Correspondence";
import { IResponseFirebase } from "../../interfaces/firebase";
import { loadFuid } from "../../redux/actions/fuidActions";
import { IFuid, IDocumentsFuid, IMilestones } from "../../redux/types/types";

// TRAER TODOS LOS EXPEDIENTES DE LA BD
export const listenerFuid = async (idEntity: string, callback: Function) => {
  db.collection("Correspondence")
    .doc(idEntity)
    .collection("Expedients")
    .orderBy("createDate", "desc")
    .onSnapshot((snap) => {
      const resp: IFuid[] = [];
      snap.forEach((doc) => {
        resp.push(doc.data() as IFuid);
      });

      callback(loadFuid(resp));
    });
};

//  ELIMINAR EXPEDIENTE DE LA BD
export const deleteFuidDb = async (
  idEntity: string,
  idFuid: string
): Promise<IResponseFirebase> => {
  try {
    const docRef = db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("Expedients")
      .doc(idFuid);
    const snap = await docRef
      .collection("Milestones")
      .orderBy("createAt", "desc")
      .get();

    if (!snap.empty) {
      await Promise.all(snap.docs.map((doc) => doc.ref.delete()));
    }
    await docRef.delete();

    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// EDITAR CORRESPONDENCE Y AGREGAR EXPEDIENTE
export const setEditCorrespondenceFileDb = async (
  correspondence: ICorrespondence,
  idEntity: string
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("CorrespondenceSection")
      .doc(correspondence.id)
      .set(
        {
          ...correspondence,
        },
        { merge: true }
      );

    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// EDITAR EXPEDIENTE Y AGREGAR OBJ DOCUMENTS
export const setEditExpedientDb = async (
  expedients: IFuid,
  idEntity: string,
  fromFilesModule?: boolean
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection(fromFilesModule ? "FileModule" : "Correspondence")
      .doc(idEntity)
      .collection("Expedients")
      .doc(expedients.id)
      .set(
        {
          ...expedients,
        },
        { merge: true }
      );

    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

// AGREGAR DOCUMENTO ADJUNTO AL EXPEDIENTE
export const addAttachedDocumentExpedientDb = async (
  module: string,
  idExpedient: string,
  values: IDocumentsFuid[],
  idEntity: string
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection(module)
      .doc(idEntity)
      .collection("Expedients")
      .doc(idExpedient)
      .set({ documents: values }, { merge: true });

    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};
// TRAER MILESTONES DE Fuid DB
export const getFuidMilestones = async (
  idEntity: string,
  idExpedient: string,
  fromFilesModule: boolean
): Promise<IMilestones[]> => {
  const snapShot = await db
    .collection(fromFilesModule ? "FileModule" : "Correspondence")
    .doc(idEntity)
    .collection("Expedients")
    .doc(idExpedient)
    .collection("Milestones")
    .orderBy("createAt", "desc")
    .get();
  const resp: IMilestones[] = [];
  snapShot.forEach((doc) => {
    resp.push(doc.data() as IMilestones);
  });
  return resp;
};

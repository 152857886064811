import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import { Box, Grid, Paper } from "@material-ui/core";
import { CorrespondenceSearch } from "../../components/correspondence-module/reports/CorrespondenceSearch";
import { ModulesType } from "../../enums/enums";
import { useStyles } from "../../shared/styles/useStyles";
import { CustomizedWindow } from "../../components/custom/CustomizedWindow";
import { AppState } from "../../redux/reducers/rootReducer";
import { useSelector, useDispatch } from "react-redux";
import { SettledDetails } from "../../components/document-managment/correspondence/SettledDetails";
import { useIntl } from "react-intl";
import { uiCloseModalSee } from "../../redux/actions/uiActions";
import { firebase } from "../../config/firebase/firebase-config";
import { setActiveCorrespondenceDocManagment } from "../../redux/actions/documentManagmentActions";
import moment from "moment";
import { SearchAddresseSender } from "../../components/correspondence-module/reports/SearchAddresseSender";
import { CorrespondenceStatistics } from "../../components/correspondence-module/reports/CorrespondenceStatistics";
import { CorrespondenceReceived } from "../../components/correspondence-module/reports/CorrespondenceReceived";
import { FollowupCorrespondenceReceived } from "../../components/correspondence-module/reports/FollowupCorrespondenceReceived";

export const ReportsScreen = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { modalSeeOpen } = useSelector((state: AppState) => state.ui);

  const onDeny = () => {
    dispatch(uiCloseModalSee());
    dispatch(setActiveCorrespondenceDocManagment(null));
  };

  // FECHA CORRESPONDENCIA ACTIVA
  const date =
    activeCorrespondence?.createAt &&
    moment(
      (activeCorrespondence.createAt as firebase.firestore.Timestamp).toDate()
    ).format("YYYY/MM/DD - h:mm:ss");

  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Paper square className={classes.paper}>
              <CorrespondenceSearch module={ModulesType.Correspondence} />
            </Paper>
          </Grid>
          <Grid item xs={7}>
            <Paper square className={classes.paper}>
              <SearchAddresseSender />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper square className={classes.paper}>
              <CorrespondenceStatistics />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper square className={classes.paperAuto}>
              <CorrespondenceReceived />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper square className={classes.paperAuto}>
              <FollowupCorrespondenceReceived />
            </Paper>
          </Grid>
        </Grid>

        <CustomizedWindow
          open={modalSeeOpen}
          content={<SettledDetails readonly />}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "Settled" })} No. ${
            activeCorrespondence?.id
          } -  ${date}`}
        />
      </Box>
    </AppAnimate>
  );
};

import { FC } from "react";
import clsx from "clsx";
import { Form, Formik } from "formik";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import { Box, Button, CircularProgress, Grid } from "@material-ui/core";
import {
  setSuccessMsg,
  uiCloseModalAdd,
  uiCloseModalEdit,
} from "../../../../redux/actions/uiActions";
import { MyTextField } from "../../../custom/MyTextField";
import { useStyles } from "../../../../shared/styles/useStyles";
import {
  setActiveTrdVersion,
  startAddOrEditTrdVersion,
} from "../../../../redux/actions/trdActions";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { ITrdVersion } from "../../../../redux/types/types";
import { firebase } from "../../../../config/firebase/firebase-config";
interface VersionProps {
  edit?: boolean;
}

export const VersionForm: FC<VersionProps> = ({ edit = false }) => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeVersion } = useSelector((state: AppState) => state.trd);

  const validationSchema = yup.object({
    code: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    name: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    approvalDate: yup
      .date()
      .max(new Date(), `${intl.formatMessage({ id: "GreaterCurrentDate" })}`)
      .required(),
  });

  // TODO: CREAR INTERFAZ PARA VERSIÓN
  let initialValues: Partial<ITrdVersion> = {
    code: "",
    approvalDate: new Date().toLocaleDateString("en-CA"),
    name: "",
  };

  if (activeVersion) {
    initialValues = {
      code: activeVersion.code,
      approvalDate: (activeVersion.approvalDate as firebase.firestore.Timestamp).toDate
      ?
          (activeVersion.approvalDate as firebase.firestore.Timestamp).toDate().toLocaleDateString("en-CA")
          :
          (activeVersion.approvalDate as Date).toLocaleDateString("en-CA")
      ,
      name: activeVersion.name,
    };
  }
  const onClose = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    dispatch(setActiveTrdVersion(null));
  };

  return (
    <Box m={1}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          // TODO: Disparar acción
          await dispatch(startAddOrEditTrdVersion(data as any));

          dispatch(
            setSuccessMsg(
              edit
                ? "VersionTrdUpdated"
                : "VersionTrdCreated"
            )
          );

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Code" />*
                </label>
                <MyTextField
                  name="code"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  disabled={edit}
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Name" />*
                </label>
                <MyTextField
                  name="name"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="ApprovalDate" />*
                </label>
                <MyTextField
                  name="approvalDate"
                  variant="outlined"
                  type="date"
                  className={classes.myTextFieldRoot}
                />
              </Grid>
            </Grid>

            <Box mt={2} display="flex" flexDirection="row-reverse">
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="Save" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

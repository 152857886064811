import clsx from "clsx";
import { Form, Formik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { MyTextField } from "../../custom/MyTextField";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "../../../shared/styles/useStyles";
import { FormattedMessage, useIntl } from "react-intl";
import { AppState } from "../../../redux/reducers/rootReducer";
import * as yup from "yup";
import { AddFilesTransferTable } from "./AddFilesTransferTable";
import { uiCloseModalAssignModules } from "../../../redux/actions/uiActions";
import {
  setActiveTransfer,
  startUploadTransfer,
} from "../../../redux/actions/documentManagmentActions";
import { FC, useState } from "react";
import { TransferType } from "../../../enums/enums";
interface props {
  readonly?: boolean;
}

export const AddFilesTransferForm: FC<props> = ({ readonly = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { activeTransfer: active } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { transfers } = useSelector((state: AppState) => state.fileModule);
  const { transfers: transferManagment } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const transferManag = transferManagment.find(
    (tra) => tra.idTransfer === active?.idTransfer
  );
  const transferCentralFile = transfers.find(
    (tra) => tra.idTransfer === active?.idTransfer
  );
  const isTransferFromCentralFile =
    transferCentralFile && !transferManag ? true : false;

  const { expedients: expedientsManag } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { expedients: expedientsFileModule } = useSelector(
    (state: AppState) => state.fileModule
  );
  const expedients = isTransferFromCentralFile
    ? expedientsFileModule.filter(
        (exp) =>
          exp?.transferType! === TransferType.PRIMARY_TRANSFER &&
          exp.deposit !== null
      )
    : expedientsManag;
  const [noValid, setNoValid] = useState(false);

  const initialValues = {
    expedient: "",
  };
  const validationSchema = yup.object({
    expedient: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  const onClose = () => {
    dispatch(setActiveTransfer(null));
    dispatch(uiCloseModalAssignModules());
  };
  return (
    <Box m={1} mt={-1} mb={2}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          if (active) {
            const finalExpedients: string[] = active.expedients
              ? [...active.expedients]
              : [];
            if (finalExpedients.includes(values.expedient)) {
              return setNoValid(true);
            }
            finalExpedients.push(values.expedient);
            const activeCopy = { ...active };
            activeCopy.expedients = finalExpedients;
            await dispatch(
              startUploadTransfer(activeCopy, true, isTransferFromCentralFile)
            );
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="SelectProceedings" />*
                </label>
                <MyTextField
                  name="expedient"
                  variant="outlined"
                  select
                  disabled={
                    active?.isComplete?true:
                    (readonly || !!active?.submitDate) &&
                    !isTransferFromCentralFile
                  }
                  className={classes.myTextFieldRoot}
                >
                  {expedients.map((item, index) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.fileName}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>
              {noValid && (
                <Grid item xs={12}>
                  <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                    <FormattedMessage id="ExpedientAlreadyAdded" />
                  </Box>
                </Grid>
              )}
            </Grid>

            {<Box mt={3}>
              <AddFilesTransferTable
                expedientsIds={
                  active && active.expedients ? active.expedients : []
                }
              />
            </Box>}

            <Box mt={2} display="flex" flexDirection="row-reverse">
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={
                    active?.isComplete?true:
                    (isSubmitting || readonly || !!active?.submitDate) &&
                    !isTransferFromCentralFile
                  }
                >
                  <FormattedMessage id="Save" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

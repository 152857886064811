import { Box, Button, Grid } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import { useStyles } from "../../shared/styles/useStyles";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { CustomizedSearch } from "../../components/custom/CustomizedSearch";
import { AnnotationsTable } from "../../components/correspondence-module/annotations/AnnotationsTable";
import CustomizedDialog from "../../components/custom/CustomizedDialog";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";
import {
  uiCloseErrorAlert,
  uiCloseModalAdd,
  uiCloseModalDelete,
  uiCloseModalSee,
  uiCloseSuccessAlert,
  uiOpenModalAdd,
} from "../../redux/actions/uiActions";
import { MyAlert } from "../../components/custom/MyAlert";
import { AnnotationsForm } from "../../components/correspondence-module/annotations/AnnotationsForm";
import { setActiveAnnotation } from "../../redux/actions/correspondenceActions";
import { AnnotationDetails } from "../../components/correspondence-module/annotations/AnnotationDetails";
import { ConfirmDelete } from "../../components/ui/shared/ConfirmDelete";
import { IAnnotation } from "../../redux/types/types";

export const AnnotationsScreen = () => {
  const classes = useStyles();
  const {
    modalAddOpen,
    modalSeeOpen,
    modalDeleteOpen,
    successAlert,
    errorAlert,
    successMsg,
    errorMsg,
  } = useSelector((state: AppState) => state.ui);

  const { annotations } = useSelector(
    (state: AppState) => state.correspondence
  );
  const { user } = useSelector((state: AppState) => state.auth);
  const pathRef = window.location.href.slice(-4);
  let filterArray: IAnnotation[] = [];

  if (pathRef !== "Send") {
    const annotationsToMe = annotations.filter((ann) =>
      ann.recipients?.includes(user?.id.toString()!)
    );
    filterArray = annotationsToMe;
  } else {
    if (user) {
      const annotationsSend = annotations.filter(
        (ann) => ann.sender && ann.sender.id === user.id.toString()
      );

      filterArray = annotationsSend;
    }
  }

  const dispatch = useDispatch();
  const intl = useIntl();

  const onDeny = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalSee());
    dispatch(uiCloseModalDelete());
    dispatch(setActiveAnnotation(null));
  };
  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box>
        <Box mb={3} fontSize={20}>
          <Grid container>
            <Grid item xs={5}>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                className={classes.btnAdd}
                type="button"
                onClick={() => dispatch(uiOpenModalAdd())}
              >
                <AddOutlinedIcon />
                <FormattedMessage id="CreateNewCommunication" />
              </Button>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <CustomizedSearch data={filterArray} />
            </Grid>
          </Grid>
        </Box>
        <AnnotationsTable annotations={filterArray} />
        <CustomizedDialog
          open={modalAddOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "CreateNewCommunication" })}`}
          content={<AnnotationsForm />}
          textButton="Accept"
        />
        <CustomizedDialog
          open={modalSeeOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "ReadNotifications" })}`}
          content={<AnnotationDetails />}
          textButton="Accept"
        />
        <CustomizedDialog
          open={modalDeleteOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "DeleteAnnotation" })}`}
          content={
            <ConfirmDelete
              title={`${intl.formatMessage({
                id: "DeleteAnnotationQuestion",
              })}`}
            />
          }
          textButton="Accept"
        />
        {successMsg && (
          <MyAlert
            open={successAlert}
            typeAlert="success"
            message={successMsg}
            time={2000}
            handleClose={() => dispatch(uiCloseSuccessAlert())}
          />
        )}
        {errorMsg && (
          <MyAlert
            open={errorAlert}
            typeAlert="error"
            message={errorMsg}
            time={2000}
            handleClose={() => dispatch(uiCloseErrorAlert())}
          />
        )}
      </Box>
    </AppAnimate>
  );
};

import { NavLink } from "react-router-dom";
import clsx from "clsx";

import {
  Badge,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import "../../../../shared/styles/app.scss";
import useStyles from "./verticalItem.styles";
import { RouteItems } from "../../../../interfaces/RouteItems";
import Accordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { grey } from "@material-ui/core/colors";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NotificationsIcon from "@material-ui/icons/Notifications";

export interface VerticalItemProps {
  listItems: RouteItems[];
}

const AccordionSummary = withStyles({
  content: {
    margin: "0px 0",
  },
  expanded: {
    maxHeight: "64px",
  },
})(MuiAccordionSummary);

const VerticalItem: React.FC<VerticalItemProps> = ({ listItems }) => {
  const classes = useStyles();

  return (
    <List>
      {listItems.map((item, index) =>
        item.subRoutes ? (
          <Accordion
            style={{ backgroundColor: "transparent", padding: 0, margin: 0 }}
            key={index}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              style={{ padding: 0, margin: 0 }}
            >
              <ListItem className={clsx(classes.navItem, "nav-item")}>
                <ListItemIcon
                  className={clsx(classes.listIcon, "nav-item-icon")}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: "nav-item-text" }}
                  primary={item.name}
                />
              </ListItem>
              {item.count && item.count > 0 ? (
                <Box alignSelf={"center"}>
                  <Badge
                    badgeContent={item.count ? item.count : 0}
                    color="secondary"
                  >
                    <NotificationsIcon style={{ color: grey[500] }} />
                  </Badge>
                </Box>
              ) : (
                <Box />
              )}
            </AccordionSummary>

            <AccordionDetails style={{ flexDirection: "column" }}>
              {item.subRoutes &&
                item.subRoutes.map((subRoute, indexSub) => (
                  <List key={subRoute.route} style={{ display: "flex" }}>
                    <ListItem
                      exact
                      activeClassName="active"
                      className={clsx(classes.navItem, "nav-item")}
                      component={NavLink}
                      to={subRoute.route}
                    >
                      <ListItemIcon
                        className={clsx(classes.listIcon, "nav-item-icon")}
                      >
                        {subRoute.icon}
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: "nav-item-text" }}
                        primary={subRoute.name}
                      />
                    </ListItem>
                    {subRoute.count && subRoute.count > 0 ? (
                      <Box alignSelf={"center"}>
                        <Badge
                          badgeContent={subRoute.count ? subRoute.count : 0}
                          color="secondary"
                        >
                          <NotificationsIcon style={{ color: grey[500] }} />
                        </Badge>
                      </Box>
                    ) : (
                      <Box />
                    )}
                  </List>
                ))}
            </AccordionDetails>
          </Accordion>
        ) : (
          <List key={item.route} style={{ display: "flex",paddingRight:20 }}>
          <ListItem
          
            activeClassName="active"
            className={clsx(classes.navItem, "nav-item")}
            component={NavLink}
            to={item.route}
          >
            <ListItemIcon
              className={clsx(classes.listIcon, "nav-item-icon")}
            >
              {item.icon}
            </ListItemIcon>
            <ListItemText
              classes={{ primary: "nav-item-text" }}
              primary={item.name}
            />
          </ListItem>
          {item.count && item.count > 0 ? (
            <Box alignSelf={"center"}>
              <Badge
                badgeContent={item.count ? item.count : 0}
                color="secondary"
              >
                <NotificationsIcon style={{ color: grey[500] }} />
              </Badge>
            </Box>
          ) : (
            <Box />
          )}
        </List>
         
           /*  <ListItem
              key={item.route}
              activeClassName="active"
              className={clsx(classes.navItem, "nav-item")}
              component={NavLink}
              to={item.route}
            >
              <ListItemIcon className={clsx(classes.listIcon, "nav-item-icon")}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                classes={{ primary: "nav-item-text" }}
                primary={item.name}
              />
            {item.count && item.count > 0 && (
              <Box alignSelf={"center"}>
                <Badge
                  badgeContent={item.count ? item.count : 0}
                  color="secondary"
                >
                  <NotificationsIcon style={{ color: grey[500] }} />
                </Badge>
              </Box>
            )}
            </ListItem> */
       
        )
      )}
    </List>
  );
};

export default VerticalItem;

import firebase from "firebase";
import moment from "moment";
import { uploadFileAsync } from "../../helpers/UploadFile";
import {
  addOrUpdateProcedure,
  loadProcedures,
  loadResponseTimesParamsForm,
  deleteAnnotationDb,
  setAnnotationDb,
  updateResponseTimes,
  setCorrespondenceDb,
  setEditCorrespondenceDb,
  addExternalSender,
  loadExternalSenders,
  deleteCommunicationDb,
  getCorrespondenceInbox,
  getMilestones,
  setContractingOfficeDb,
  getContractingOffice,
  deleteFileCorrespondence,
  loadTenders,
  addMilestoneDb,
  deleteProcedureFirebase,
  addOrUpdateTender,
  loadProductionOfficeParam,
  addCorrespondenceTender,
  startListenerCorrespondencesByEntity,
  sendEmailNotification,
  startListenerAnnotations,
  setLabelSizeDb,
  loadLabelSizeDb,
  getLastCorrespondence,
  updateFilesCorrespondence,
  updateFilesAnswerCorrespondence,
  searchExternalSendersByNameAndIdEntity,
} from "../../services/firebase/correspondence";
import { AppState } from "../reducers/rootReducer";
import {
  IAnnotation,
  ICorrespondenceActions,
  Types,
  IParamProcedure,
  IParamResponseTime,
  IFiles,
  IExternalSender,
  IMilestones,
  IContractingOffice,
  ILabelSize,
  IRecipients,
} from "../types/types";
import {
  setErrorMsg,
  setSuccessMsg,
  uiCloseModalAdd,
  uiCloseModalDelete,
  uiOpenErrorAlert,
  uiOpenSuccessAlert,
  uiCloseModalSee,
  uiCloseModalEdit,
  uiCloseModalAction,
  uiCloseModalAnswer,
  uiCloseModalInfo,
} from "./uiActions";
import { randomNumber } from "../../helpers/randomNumber";
import {
  ICorrespondence,
  ICorrespondenceInfo,
  ITenderCorrespondence,
} from "../../interfaces/Correspondence";
import { createIncrementalCode } from "../../helpers/incrementalCode";
import {
  CommunicationChannels,
  CorrespondenceInputType,
  CorrespondenceStatus,
  Enum_Notification_Type,
  InternalCorrespondenceStatus,
} from "../../enums/enums";
import {
  ITenders,
  IProponent,
  ICorrespondenceResponse,
} from "../../interfaces/Correspondence";
import { uiOpenAlertExists } from "./uiActions";
import { getFuidMilestones } from "../../services/firebase/fuid";
import {
  IEmailNotificationParams,
  IEmailParamDataEr,
  IEmailStatus,
  IUserSendEmail,
} from "../../interfaces/emailNotification";
import {
  getEntityById,
  getDataFromEntities,
} from "../../services/firebase/entities";
import { setActiveExternalSender } from "./usersActions";
import {
  getCopyArrayOrObject,
  getCurrentDateAndHourColombia,
} from "../../utils/utils";
import { communicationChannels } from "../../shared/constants/communicationChannels";
import { addOneBarcode, addTwoBarcode } from "../../helpers/addBarcode";
import {
  IEmailParamDataEp,
  IFormSendEmail,
} from "../../interfaces/emailNotification";
import { addCorrespondenceResponse } from "../../services/firebase/correspondence";

export const startLoadAnnotations = (idEntity: string) => {
  return async (dispatch: Function) => {
    startListenerAnnotations(idEntity, dispatch);
  };
};
export const purgeCorrespondence = (): ICorrespondenceActions => ({
  type: Types.purgeCorrespondence,
});

export const loadAnnotations = (
  annotations: IAnnotation[]
): ICorrespondenceActions => ({
  type: Types.loadAnnotations,
  payload: annotations,
});
export const startSetAnnotation = (annotation: IAnnotation, edit: boolean) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    if (user && user.idEntity) {
      if (annotation.attachedDocument && annotation.attachedDocument.file) {
        const urlFile = await uploadFileAsync(
          annotation.attachedDocument.file,
          `annotations/${user.idEntity}/${annotation.attachedDocument.fileName}`
        );
        annotation.attachedDocument = {
          fileName: annotation.attachedDocument.fileName,
          url: urlFile,
        };
      }
      const { ok } = await setAnnotationDb(user.idEntity, annotation);
      if (ok) {
        dispatch(setAnnotation(annotation));
        dispatch(uiCloseModalAdd());
        dispatch(
          setSuccessMsg(edit ? "UpdatedCommunication" : "CreatedCommunication")
        );
        dispatch(uiOpenSuccessAlert());
        dispatch(setActiveAnnotation(null));
      } else {
        dispatch(setErrorMsg("SavingDataError"));
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};
export const setAnnotation = (
  annotation: IAnnotation
): ICorrespondenceActions => ({
  type: Types.setAnnotation,
  payload: annotation,
});
export const setActiveAnnotation = (
  active: IAnnotation | null
): ICorrespondenceActions => ({
  type: Types.setActiveAnnotation,
  payload: active,
});

export const startDeleteAnnotation = () => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    const { activeAnnotation } = (getState() as AppState).correspondence;

    if (user && user.idEntity && activeAnnotation) {
      const { ok } = await deleteAnnotationDb(
        user.idEntity,
        activeAnnotation.id,
        activeAnnotation.recipientsData,
        activeAnnotation.attachedDocument
      );
      if (ok) {
        dispatch(deleteAnnotation(activeAnnotation.id));
        dispatch(uiCloseModalDelete());
        dispatch(setActiveAnnotation(null));
        dispatch(setSuccessMsg("DeletedAnnotation"));
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(setErrorMsg("DeletingError"));
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};
const deleteAnnotation = (idAnnotation: string): ICorrespondenceActions => ({
  type: Types.deleteAnnotation,
  payload: idAnnotation,
});

// *****************    PARAMETRIZACIÓN    *****************
// Cargar trámites
export const startLoadingProcedures = (idEntity: string) => {
  return async (dispatch: Function) => {
    const procedures = await loadProcedures(idEntity);
    dispatch(setProcedures(procedures));
  };
};

// Setear trámites
export const setProcedures = (procedures: IParamProcedure) => ({
  type: Types.loadParamsProcedure,
  payload: procedures,
});

// Agregar o actualizar trámite
export const startNewProcedure = (procedure: IParamProcedure) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;
    const { activeParamProcedure } = getState().correspondence;

    if (!!activeParamProcedure) {
      // Editar trámite
      const { ok } = await addOrUpdateProcedure(idEntity, procedure);

      if (ok) {
        dispatch(updateProcedure(procedure));
        dispatch(setSuccessMsg("ProcedureUpdated"));
        dispatch(uiCloseModalEdit());
        dispatch(setActiveProcedure(null));
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(setErrorMsg("ErrorProcedureUpdate"));
        dispatch(uiOpenErrorAlert());
      }
    } else {
      // Crear nuevo trámite
      const random = randomNumber(1, 1000000);
      procedure.id = `${random}_${Date.now()}`;

      const { ok } = await addOrUpdateProcedure(idEntity, procedure);

      if (ok) {
        dispatch(addProcedure(procedure));
        dispatch(setSuccessMsg("ProcedureCreated"));
        dispatch(uiCloseModalAdd());
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(setErrorMsg("ErrorProcedureCreate"));
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};
const addProcedure = (procedure: IParamProcedure) => ({
  type: Types.addProcedure,
  payload: procedure,
});

// Activar trámite
export const setActiveProcedure = (procedure: IParamProcedure | null) => ({
  type: Types.setActiveProcedure,
  payload: procedure,
});

const updateProcedure = (procedure: IParamProcedure) => ({
  type: Types.updateProcedure,
  payload: procedure,
});

// Eliminar trámite
export const startDeleteProcedure = (idProcedure: string) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;

    const { ok } = await deleteProcedureFirebase(idEntity, idProcedure);

    if (ok) {
      dispatch(deleteProcedure(idProcedure));
      dispatch(setSuccessMsg("ProcedureDeleted"));
      dispatch(uiOpenSuccessAlert());
    } else {
      dispatch(setSuccessMsg("ProcedureDeletedError"));
      dispatch(uiOpenErrorAlert());
    }
  };
};

const deleteProcedure = (idProcedure: string) => ({
  type: Types.deleteProcedure,
  payload: idProcedure,
});
export const startLoadProductionOfficeParam = (idEntity: string) => {
  return async (dispatch: Function) => {
    const producOffParam = await loadProductionOfficeParam(idEntity);
    if (producOffParam) {
      dispatch(setProductionOfficeParam(producOffParam));
    }
  };
};
const setProductionOfficeParam = (office: string): ICorrespondenceActions => ({
  type: Types.setProductionOfficeParam,
  payload: office,
});

// ****************   PARAMETRIZACIÓN - tiempos de respuesta    ******************
// cargar parametros del formulario de tiempo de respuesta
export const startLoadingResponseTimesParams = (idEntity: string) => {
  return async (dispatch: Function) => {
    const ResponseTimesParamsForm = await loadResponseTimesParamsForm(idEntity);
    dispatch(setResponseTimesParamsForm(ResponseTimesParamsForm));
  };
};

// setear parametros del formulario de tiempo de respuesta
export const setResponseTimesParamsForm = (form: IParamResponseTime | any) => ({
  type: Types.loadParamsResponseTime,
  payload: form,
});

// actualizar formulario de respuestas
export const updateResponseTimesForm = (form: IParamResponseTime) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;
    const { ok } = await updateResponseTimes(idEntity, form);
    if (ok) {
      await dispatch(setResponseTimesParamsForm(form));
      dispatch(setSuccessMsg("FormResponseTimesUpdated"));
      dispatch(uiOpenSuccessAlert());
    } else {
      dispatch(setErrorMsg("ErrorProcedureUpdate"));
      dispatch(uiOpenErrorAlert());
    }
  };
};

// -------------------------------- CORRESPONDENCE ACTIONS ------------------------

export const startLoadCorrespondences = (idUser: number, idEntity: string) => {
  return async (dispatch: any) => {
    startListenerCorrespondencesByEntity(idEntity, idUser.toString(), dispatch);
  };
};
export const loadCorrespondeces = (
  correspondences: ICorrespondence[]
): ICorrespondenceActions => ({
  type: Types.loadCorrespondences,
  payload: correspondences,
});

// FUNCION PARA EDITAR ADDRESSEE Y GIVEBACK CUANDO SE RETORNA LA CORRESPONDENCIA
export const editCorrespondenceReturn = (correspondence: ICorrespondence) => {
  return async (dispatch: any, getState: Function) => {
    correspondence.addressee = { id: "", name: "" };
    correspondence.isGiveBack = true;
    const { user } = (getState() as AppState).auth;
    if (user && user.idEntity) {
      const { ok } = await setEditCorrespondenceDb(
        correspondence,
        user.idEntity
      );
      if (ok) {
        //TODO
        //AGREGAR AL MILESTONE
        await addMilestoneDb(
          user.idEntity,
          correspondence.id,
          firebase.firestore.Timestamp.now(),
          `Devuelto a módulo correspondencia`,
          user.id,
          user.names
        );
        dispatch(setCorrespondence(correspondence));
      }
    }
  };
};
const getLastConsecutive = async (idEntity: string) => {
  const lastCorrespondence = await getLastCorrespondence(idEntity);
  let lastConsecutive = "0";
  if (lastCorrespondence) {
    lastConsecutive = lastCorrespondence.id.substring(
      lastCorrespondence.id.length - 9
    );
  }
  return lastConsecutive;
};

const saveFilesFirebase = async (
  files: any[],
  correspondenceId1: string,
  correspondenceId2: string,
  userId: string,
  username: string,
  entityId: string
) => {
  let digitalSupportParse: IFiles[] = [];
  if (files.length > 0) {
    for (const document of files) {
      if (document.file) {
        if (document.file.type === "application/pdf") {
          if (correspondenceId2 === "") {
            document.file = await addOneBarcode(
              document.file,
              correspondenceId1
            );
          } else {
            document.file = await addTwoBarcode(
              document.file,
              correspondenceId1,
              correspondenceId2
            );
          }
        }
        const newFileObj = await uploadFileToFirebase(
          document.file,
          entityId,
          document.fileName,
          userId,
          username
        );
        digitalSupportParse.push(newFileObj);
      }
      document.url !== "#" && digitalSupportParse.push(document);
    }
  }

  return digitalSupportParse.length > 0 ? digitalSupportParse : files;
};

const uploadFileToFirebase = async (
  file: any,
  entityId: string,
  fileName: string,
  userId: string,
  username: string
) => {
  const url = await uploadFileAsync(
    file,
    `/correspondence/${entityId}/${fileName}`
  );
  const newFileObj: IFiles = {
    fileName: fileName,
    url,
    owner: {
      id: userId,
      username: username,
    },
  };

  return newFileObj;
};

export const startSetCorrespondence = (
  correspondenceForm: ICorrespondence,
  edit: boolean,
  deleteFile: boolean,
  tdr?: boolean,
  isFinalAnswer?: boolean,
  howToSendEmail?: string
) => {
  return async (dispatch: any, getState: Function) => {
    // CUANDO EL TIPO DE ENTRADA SEA IP O IR HAY QUE RADICAR 2 VECES CON LAS CORRESPONDIENTES DEPENDENCIAS
    // armar el id/radicado
    // TIPO CANAL+TIPO ENTRADA+AÑO+ULTIMA VERSION DE LA TRD+NUMERO CONSECUTIVO DE 9 DIGITOS
    // para hacer el numero consecutivo por año hay que tomar le tamaño del arr de correspondencia y validar si en el actual año no se han creado correspondencias arranca desde 0
    const { versions, productionOffices } = (getState() as AppState).trd;
    const { initialSettled } = (getState() as AppState).entities;
    const { user } = (getState() as AppState).auth;
    const { users, externalSenders } = (getState() as AppState).users;

    const allUsers = users.concat(externalSenders as any);
    const currentTrdVersion = versions.length > 0 ? versions[0] : null;
    const correspondence = { ...correspondenceForm };

    const { communicationChannel, inputType } = correspondence;

    if (!initialSettled) {
      dispatch(setErrorMsg("WarningInitialSetled"));
      dispatch(uiOpenErrorAlert());

      dispatch(uiCloseModalAdd());
      return dispatch(uiCloseModalEdit());
    }

    if (currentTrdVersion && user && user.idEntity && user.username) {
      let incrementalCode = createIncrementalCode(parseInt(initialSettled));

      const lastConsecutive = await getLastConsecutive(user.idEntity);
      if (lastConsecutive !== "0") {
        incrementalCode = createIncrementalCode(
          parseInt(lastConsecutive, 10) + 1
        );
      }

      if (inputType === CorrespondenceInputType.IPR) {
        //hacer el codigo pero con diferentes los respectivos inputType
        const correspondenceCopyIP = getCopyArrayOrObject(correspondence);
        const officeCodeIP = correspondenceCopyIP.producerDependency
          ? correspondenceCopyIP.producerDependency.split("_", 1)
          : "";
        const officeCodeIR = correspondenceCopyIP.receivingDependency.split(
          "_",
          1
        );
        const fullSettledCodeIp = edit
          ? correspondenceCopyIP.id
          : communicationChannel +
            CorrespondenceInputType.IP +
            moment().format("YYYY") +
            officeCodeIP +
            incrementalCode;

        //GUARDAR PRIMERO EL IP y luego el IR
        correspondenceCopyIP.id = fullSettledCodeIp;
        correspondenceCopyIP.inputType = CorrespondenceInputType.IP;
        correspondenceCopyIP.internalState =
          InternalCorrespondenceStatus.NO_ANSWER_REQUIRED;
        correspondenceCopyIP.status = CorrespondenceStatus.RESOLVED;
        const { ok } = await setCorrespondenceDb(
          correspondenceCopyIP,
          user.idEntity,
          user.id,
          user.username,
          edit
        );

        if (ok) {
          const correspondenceCopyIR = getCopyArrayOrObject(correspondence);
          const lastConsecutive = await getLastConsecutive(user.idEntity);
          const fullSettledCodeIR = edit
            ? correspondenceCopyIR.id
            : communicationChannel +
              CorrespondenceInputType.IR +
              moment().format("YYYY") +
              officeCodeIR +
              createIncrementalCode(parseInt(lastConsecutive, 10) + 1);

          correspondenceCopyIR.id = fullSettledCodeIR;
          correspondenceCopyIR.inputType = CorrespondenceInputType.IR;

          const tempFiles = [...correspondence.digitalSupport];
          correspondence.digitalSupport = [];

          const files = await saveFilesFirebase(
            tempFiles,
            fullSettledCodeIp,
            fullSettledCodeIR,
            user.id.toString(),
            user.username,
            user.idEntity
          );

          correspondenceCopyIR.digitalSupport = [...files];
          correspondence.digitalSupport = [...files];

          await updateFilesCorrespondence(
            files,
            user.idEntity,
            fullSettledCodeIp
          );

          if (!edit) {
            const { ok } = await setCorrespondenceDb(
              correspondenceCopyIR,
              user.idEntity,
              user.id,
              user.username,
              edit
            );

            if (ok) {
              if (!deleteFile) {
                if (isFinalAnswer) {
                  updateFilesAnswerCorrespondence(
                    files,
                    user.idEntity,
                    fullSettledCodeIp
                  );

                  updateFilesAnswerCorrespondence(
                    files,
                    user.idEntity,
                    fullSettledCodeIR
                  );

                  return correspondence;
                }
                dispatch(
                  setSuccessMsg(
                    edit ? "UpdatedCorrespondence" : "CorrespondenceCreated"
                  )
                );
                dispatch(uiOpenSuccessAlert());
              }
            } else {
              dispatch(uiOpenErrorAlert());
            }
            if (!tdr) {
              dispatch(uiCloseModalAdd());
              dispatch(uiCloseModalEdit());
            } else {
              dispatch(uiCloseModalAction());
            }
          }
        } else {
          dispatch(uiOpenErrorAlert());
        }
        if (!tdr) {
          dispatch(uiCloseModalAdd());
          dispatch(uiCloseModalEdit());
        } else {
          dispatch(uiCloseModalAction());
        }
      } else {
        if (inputType === CorrespondenceInputType.ER) {
          const officeCodeER = correspondence.receivingDependency.split("_", 1);

          const fullSettledCode = edit
            ? correspondence.id
            : communicationChannel +
              inputType +
              moment().format("YYYY") +
              officeCodeER +
              incrementalCode;

          correspondence.id = fullSettledCode;
        }
        if (inputType === CorrespondenceInputType.EP) {
          const officeCodeEP = correspondence.receivingDependency
            ? correspondence.receivingDependency.split("_", 1)
            : "";

          const fullSettledCode = edit
            ? correspondence.id
            : communicationChannel +
              inputType +
              moment().format("YYYY") +
              officeCodeEP +
              incrementalCode;

          correspondence.id = fullSettledCode;
        }

        const tempFiles = [...correspondence.digitalSupport];
        correspondence.digitalSupport = [];
        const files = await saveFilesFirebase(
          tempFiles,
          correspondence.id,
          "",
          user.id.toString(),
          user.username,
          user.idEntity
        );

        correspondence.digitalSupport = [...files];

        const { ok } = await setCorrespondenceDb(
          correspondence,
          user.idEntity,
          user.id,
          user.username,
          edit
        );

        if (ok) {
          //si es respuesta final retornar el id del radicado
          if (isFinalAnswer) {
            updateFilesAnswerCorrespondence(
              files,
              user.idEntity,
              correspondence.id
            );
            return correspondence;
          }
          //CONDICIONAL CON EL TIPO DE ENTRADA PARA PODER MANDAR EL CORREO EP o ER
          const addresseFind = (allUsers as any[]).find(
            (user) =>
              user.id === parseInt(correspondence.addressee.id) ||
              user?.idDoc === correspondence.addressee?.idDoc
          );

          const senderFind = (allUsers as any[]).find(
            (user) =>
              user.id === parseInt(correspondence.externalSender.id) ||
              user.idDoc === correspondence.externalSender.id
          );

          if (addresseFind && senderFind && senderFind.email.length > 0) {
            //const { address } = addresseFind;

            const officeFind = productionOffices.find((office) =>
              senderFind.offices
                ? senderFind.offices[0].includes(office.idProductionOffice)
                : addresseFind.offices[0].includes(office.idProductionOffice)
            );

            if (officeFind) {
              if (correspondence.inputType === CorrespondenceInputType.ER) {
                const communicationChannelName = communicationChannels.find(
                  (channel) => channel.id === communicationChannel
                )?.name;

                const entity = await getEntityById(addresseFind.idEntity);
                const { emailSender } = await getDataFromEntities(
                  entity.idEntity.toString()
                );
                const emailParamsEr: IEmailParamDataEr = {
                  addresse: addresseFind.username
                    ? addresseFind.username
                    : addresseFind.name,
                  createAt: moment().format("YYYY/MM/DD hh:mm a"),
                  entity: entity.businessName,
                  sender: senderFind.username
                    ? senderFind.username
                    : senderFind.name,
                  settled: correspondence.id,
                  channel: communicationChannelName!,
                  emailCommunication: emailSender,
                };

                const totalParams: IEmailNotificationParams = {
                  type: Enum_Notification_Type.ER,
                  data: emailParamsEr,
                  email: senderFind.email, //enviar correo al remitente externo
                  emailSender: entity.emailSender,
                  entityName: entity.businessName,
                  // emailSender: "comercial@appsus.co",
                };

                await sendEmailNotification(totalParams);
              } else if (
                correspondence.inputType === CorrespondenceInputType.EP &&
                howToSendEmail === "1"
              ) {
                const emailSender = await getDataFromEntities(
                  senderFind.idEntity
                );
                const entity = await getEntityById(correspondence.idEntity);
                const addresseFindExternal = (allUsers as any[]).find(
                  (user) =>
                    user.id === parseInt(correspondence.addressee.id) ||
                    user.idDoc === correspondence.addressee.id
                );

                const emailParamsEp: IEmailParamDataEp = {
                  addresse: {
                    name: addresseFindExternal.username
                      ? addresseFindExternal.username
                      : addresseFindExternal.name,
                    address: addresseFindExternal.address,
                    city: addresseFindExternal.city,
                    phone: addresseFindExternal.mobilePhone
                      ? addresseFindExternal.mobilePhone
                      : addresseFindExternal.phone,
                    id: addresseFindExternal.username
                      ? addresseFindExternal.id.toString()
                      : addresseFindExternal.id,
                  },
                  createAt: moment().format("YYYY/MM/DD hh:mm a"),
                  description: correspondence.description,
                  entity: {
                    name: entity.businessName,
                    address: entity.address,
                    phone: entity.phone,
                    city: entity.town,
                    id: entity.id,
                  },
                  files,
                  sender: {
                    name: senderFind.username
                      ? senderFind.username
                      : senderFind.name,
                    office: officeFind.name,
                  },
                  settled: correspondence.id,
                  subject: correspondence.subject,
                  email: emailSender.emailSender,
                };

                const totalParams: IEmailNotificationParams = {
                  type: Enum_Notification_Type.EP,
                  data: emailParamsEp,
                  email: addresseFindExternal.email, //enviar correo al destinatario
                  emailSender: entity.emailSender,
                  entityName: entity.businessName,
                };

                const response = (await sendEmailNotification(
                  totalParams
                )) as unknown as any;
                if (response.ok) {
                  const messageId =
                    response?.data?.body?.Messages[0]?.To[0]?.MessageUUID ?? "";

                  const status: IEmailStatus[] = initEmailState();
                  //mandar al historial
                  const userSendEmail: IUserSendEmail = {
                    email: addresseFindExternal.email,
                    id: correspondence.idEntity,
                  };
                  const emailData: IFormSendEmail = {
                    addressee: userSendEmail,
                    copyTo: [],
                    files: correspondence.digitalSupport,
                  };
                  const answerMsg = `Enviado al correo electrónico ${addresseFindExternal.email}`;
                  const objAnswer: ICorrespondenceResponse = {
                    subject: answerMsg,
                    submitDate: firebase.firestore.Timestamp.now(),
                    officer: { id: user.id.toString(), name: user.username },
                    isScreeningAnswer: false,
                    isSendEmailResponse: true,
                    emailData: emailData,
                    status,
                    messageId,
                  };
                  await addCorrespondenceResponse(
                    user.idEntity,
                    correspondence.id,
                    objAnswer
                  );
                }
              }
            }
          }

          if (!deleteFile) {
            if (!tdr) {
              dispatch(uiCloseModalAdd());
              dispatch(uiCloseModalEdit());
            } else {
              dispatch(uiCloseModalAction());
            }

            dispatch(
              setSuccessMsg(
                edit ? "UpdatedCorrespondence" : "CorrespondenceCreated"
              )
            );
            dispatch(uiOpenSuccessAlert());
          }
        } else {
          if (tdr) {
            dispatch(uiCloseModalAction());
          } else {
            dispatch(uiCloseModalAdd());
            dispatch(uiCloseModalEdit());
          }
          dispatch(uiOpenErrorAlert());
        }
      }
      //limpiar la correspondencia activa
      dispatch(setActiveCorrespondence(null));
    }
  };
};

const initEmailState = () => {
  const emailStatus: IEmailStatus[] = [
    {
      date: getCurrentDateAndHourColombia(),
      status: "sent",
    },
  ];
  return emailStatus;
};

export const setCorrespondence = (
  correspondence: ICorrespondence | ITenderCorrespondence
): ICorrespondenceActions => ({
  type: Types.setCorrespondence,
  payload: correspondence,
});
export const setActiveCorrespondence = (
  correspondence: ICorrespondence | null
): ICorrespondenceActions => ({
  type: Types.setActiveCorrespondence,
  payload: correspondence,
});
export const startDeleteCorrespondenceFile = (fileName: string) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    const { activeCorrespondence } = (getState() as AppState).correspondence;
    if (activeCorrespondence && user && user.idEntity) {
      const existAppendDoc = activeCorrespondence.appendDocs?.some(
        (doc) => doc.filename === fileName
      );
      const existInDigitalSupport = activeCorrespondence.digitalSupport?.some(
        (doc: any) => doc.fileName === fileName
      );

      let copyActiveCorrespondende = { ...activeCorrespondence };

      if (existInDigitalSupport) {
        const filteredDocs = activeCorrespondence.digitalSupport.filter(
          (el: any) => el.fileName !== fileName
        );
        copyActiveCorrespondende.digitalSupport = [];
        copyActiveCorrespondende.digitalSupport = filteredDocs;
      }

      if (existAppendDoc) {
        const filteredDocs = activeCorrespondence.appendDocs?.filter(
          (el: any) => el.filename !== fileName
        );
        copyActiveCorrespondende.appendDocs = [];
        copyActiveCorrespondende.appendDocs = filteredDocs;
      }

      const { ok } = await deleteFileCorrespondence(user.idEntity, fileName);

      if (ok) {
        await dispatch(
          startSetCorrespondence(copyActiveCorrespondende, true, true)
        );
      }
    }
  };
};

export const startDeleteCorrespondece = () => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    const { activeCorrespondence } = (getState() as AppState).correspondence;
    if (user && user.idEntity && activeCorrespondence) {
      // const ok =true;
      const { ok } = await deleteCommunicationDb(
        user.idEntity,
        activeCorrespondence.id,
        activeCorrespondence.addressee,
        activeCorrespondence.digitalSupport
      );
      if (ok) {
        //dispatch(deleteCorrespondence(activeCorrespondence.id));
        dispatch(uiCloseModalDelete());
        dispatch(setActiveCorrespondence(null));
        dispatch(setSuccessMsg("DeletedCommunication"));
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(setErrorMsg("DeletingError"));
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};

/* const deleteCorrespondence = (
  idCorrespondence: string
): ICorrespondenceActions => ({
  type: Types.deleteCorrespondence,
  payload: idCorrespondence,
}); */

// ****************   CORRESPONDENCIA - Remitente externo    ******************
// cargar remitentes externos
export const startLoadingExternalSender = (idEntity: string) => {
  return async (dispatch: Function) => {
    const ExternalSender = await loadExternalSenders(idEntity);
    if (ExternalSender.length > 0) {
      dispatch(setExternalSenderParamsForm(ExternalSender));
    }
  };
};
// agregar remitente externo
export const startNewExternalSender = (
  form: IExternalSender,
  editUser?: boolean
) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;
    const { externalSenders, activeExternalSender: active } = getState().users;
    const { users, activeUser } = getState().users;
    const idDoc = form.idDoc ? form.idDoc : null;

    const existsUser =
      !activeUser && users.find((user: any) => user.document === form.id);

    const existsSender =
      !active &&
      externalSenders.find(
        (user: IExternalSender) => user.id === form.id && user.id.length > 0
      );

    if (existsSender || existsUser) {
      dispatch(setErrorMsg("ExistsSender"));
      dispatch(uiOpenErrorAlert());
    } else {
      const { ok, data } = await addExternalSender(idEntity, idDoc, form);
      if (ok) {
        form.idDoc = data as string;
        dispatch(setActiveExternalSender(form));
        !!active || editUser
          ? dispatch(updateExternalSenderParamsForm(form))
          : dispatch(addExternalSenderParamsForm(form));
        dispatch(uiCloseModalAnswer());
        dispatch(uiCloseModalInfo());
        dispatch(
          setSuccessMsg(
            !!active || editUser
              ? "ExternalSenderUpdated"
              : "ExternalSenderCreated"
          )
        );
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(setErrorMsg("ErrorExternalSenderCreate"));
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};

export const searchExternalSendersByName = (
  name: string,
  externalType: string
) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;
    let results: any[] = [];
    if (externalType === "sender") {
      results = await searchExternalSendersByNameAndIdEntity(name, idEntity);
      dispatch(searchExternalSenders(results));
    } else {
      results = await searchExternalSendersByNameAndIdEntity(name, idEntity);
      dispatch(searchExternalAddressees(results));
    }
  };
};

// setear parametros del formulario del remitente
export const setExternalSenderParamsForm = (form: IExternalSender | any) => ({
  type: Types.loadParamsExternalSender,
  payload: form,
});
// adicionar remitente
export const addExternalSenderParamsForm = (form: IExternalSender | any) => ({
  type: Types.addExternalSender,
  payload: form,
});

export const searchExternalSenders = (data: IExternalSender[] | any[]) => ({
  type: Types.searchExternalSenders,
  payload: data,
});

export const searchExternalAddressees = (data: IExternalSender[] | any[]) => ({
  type: Types.searchExternalAddressees,
  payload: data,
});

// editar remitente
export const updateExternalSenderParamsForm = (
  form: IExternalSender | any
) => ({
  type: Types.updateExternalSender,
  payload: form,
});

export const startLoadCorrespondenceInbox = (idUser: number) => {
  return async (dispatch: Function) => {
    const inbox = await getCorrespondenceInbox(idUser.toString());
    if (inbox) {
      dispatch(loadCorrespondeceInbox(inbox));
    }
  };
};
export const loadCorrespondeceInbox = (
  inbox: ICorrespondenceInfo[]
): ICorrespondenceActions => ({
  type: Types.loadCorrespondeceInbox,
  payload: inbox,
});

// CARGAR MILESTONES DE CORRESPONDENCIA
export const startLoadMilestones = (
  idEntity: string | undefined,
  correspondenceId: string
) => {
  return async (dispatch: Function) => {
    const response = await getMilestones(idEntity, correspondenceId);

    dispatch(loadMilestones(response.length > 0 ? response : []));
  };
};
// CARGAR MILESTONES DE EXPEDIENTES
export const startLoadExpedientsMilestones = (
  idEntity: string,
  idExpedient: string,
  fromFilesModule: boolean
) => {
  return async (dispatch: Function) => {
    const response = await getFuidMilestones(
      idEntity,
      idExpedient,
      fromFilesModule
    );

    dispatch(loadMilestones(response.length > 0 ? response : []));
  };
};

export const loadMilestones = (
  milestones: IMilestones[]
): ICorrespondenceActions => ({
  type: Types.loadMilestones,
  payload: milestones,
});

// SUBIR OFICINA CONTRATACION DB

export const startSetContractingOffice = (data: IContractingOffice) => {
  return async (dispatch: any, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    if (user && user.idEntity) {
      data.idEntity = user.idEntity;
      const { ok } = await setContractingOfficeDb(data);
      if (ok) {
        dispatch(setContractingOffice(data));
        dispatch(setSuccessMsg("ContractingOfficeUpdated"));
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(setErrorMsg("ErrorProcedureUpdate"));
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};

// CARGAR CONTRACTING OFFICE
export const startLoadContractingOffice = (idEntity: string) =>
  // contractingOffice:string
  {
    return async (dispatch: Function) => {
      const response = await getContractingOffice(idEntity);
      if (response) {
        dispatch(setContractingOffice(response));
      }
      // else {
      // }
    };
  };

export const setContractingOffice = (
  contractingOffice: IContractingOffice
): ICorrespondenceActions => ({
  type: Types.loadContractingOffice,
  payload: contractingOffice,
});

// ****************   CORRESPONDENCIA - Licitaciones    ******************

export const startLoadingTenders = (idEntity: string) => {
  return async (dispatch: Function) => {
    const Tenders = await loadTenders(idEntity);
    dispatch(loadAllTenders(Tenders));
  };
};

export const startSetTender = (tender: ITenders, deleteFile: boolean) => {
  return async (dispatch: any, getState: Function) => {
    // crear licitación
    const { user } = (getState() as AppState).auth;
    const { activeTender } = (getState() as AppState).correspondence;

    if (user && user.idEntity) {
      const id = user.idEntity;
      const parseDate = firebase.firestore.Timestamp.fromDate(
        new Date(tender.closingDate as string)
      );
      const objTender: ITenders = {
        ...tender,
        id: activeTender ? tender.id : tender.id.trim(),
        closingDate: parseDate,
      };
      const { ok } = await addOrUpdateTender(id, objTender);
      if (ok) {
        dispatch(setTender(objTender));
        activeTender
          ? dispatch(uiCloseModalEdit())
          : dispatch(uiCloseModalAdd());
        await dispatch(
          setSuccessMsg(activeTender ? "ProcessUpdated" : "NewTenderAdded")
        );
        dispatch(uiOpenSuccessAlert());
        dispatch(setActiveTender(null));
      } else {
        dispatch(setErrorMsg("ErrorUpdatingProcess"));
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};
const setTender = (tender: ITenders): ICorrespondenceActions => ({
  type: Types.setTender,
  payload: tender,
});

export const loadAllTenders = (tender: ITenders[]): ICorrespondenceActions => ({
  type: Types.loadTenders,
  payload: tender,
});

export const setActiveTender = (
  active: ITenders | null
): ICorrespondenceActions => ({
  type: Types.setActiveTender,
  payload: active,
});

export const startNewProponent = (proponent: IProponent) => {
  return async (dispatch: Function, getState: Function) => {
    const { idEntity } = getState().auth.user;
    const { activeTender: active } = getState().correspondence;
    const { contractingOffice, correspondence: CorrespondenceState } = (
      getState() as AppState
    ).correspondence;

    const existsIdNumber = active.proponents.some(
      (obj: IProponent) => obj.idNumber === proponent.idNumber
    );
    if (contractingOffice) {
      if (existsIdNumber) {
        dispatch(setErrorMsg("ExistsProponent"));
        dispatch(uiOpenAlertExists());
      } else {
        //Crear una correspondencia de licitacion
        //armar el codigo de radicado de la licitacion
        //01 + ER + Año + paramPO codigo + consecutivo
        const currentYearCorrespondece = CorrespondenceState.filter(
          (el) =>
            moment(el.createAt.toDate()).format("YYYY") ===
            moment().format("YYYY")
        );
        const incrementalCode = createIncrementalCode(
          currentYearCorrespondece.length + 1
        );
        const codeOP = contractingOffice.productionOffice.split("_");
        const settledCode =
          CommunicationChannels.SINGLE_WINDOW +
          CorrespondenceInputType.ER +
          moment().format("YYYY") +
          codeOP[0] +
          incrementalCode;
        const finalProponent = { ...proponent };
        finalProponent.settledId = settledCode;
        active.proponents = [...active.proponents, finalProponent];
        //crear la correspondencia

        const { ok: okProponent } = await addOrUpdateTender(idEntity, active);

        //RAZON SOCIAL = REMITENTE
        //DESTINATARIO = QUIEN ESTE A CARGO DE LA OFICINA DE CONTRATACION
        //MANDAR CAMPO isTender para saber cual correspondencia es de licitacion.
        //TIPO DE COMUNICACION = CODIGO DE LA LICITACION
        const corresponceTender: ITenderCorrespondence = {
          id: settledCode,
          externalSender: {
            id: contractingOffice.productionOffice,
            name: proponent.businessName,
          },
          addressee: contractingOffice.responsibleUser,
          isTender: true,
          communicationType: {
            isParam: false,
            option: active.id,
          },
          inputType: CorrespondenceInputType.ER,
          createAt: firebase.firestore.Timestamp.now(),
          subject: `Licitacion No.${active.id}`,
          receivingDependency: contractingOffice.productionOffice,
        };
        const { ok: okCorresTender } = await addCorrespondenceTender(
          idEntity,
          corresponceTender
        );

        if (okProponent && okCorresTender) {
          dispatch(setTender(active));
          dispatch(setCorrespondence(corresponceTender));

          dispatch(uiCloseModalSee());
          dispatch(setSuccessMsg("ProponentAdded"));
          dispatch(uiOpenSuccessAlert());
          dispatch(setActiveTender(null));
        } else {
          dispatch(setErrorMsg("ErrorAddingProponent"));
          dispatch(uiOpenAlertExists());
        }
      }
    }
  };
};

/* ACTION LOAD LABEL SIZE IN CORRESPONDENCE ENTITY*/
export const startLoadLabelSize = (idEntity: string) => {
  return async (dispatch: Function) => {
    const labelSize = await loadLabelSizeDb(idEntity);
    if (labelSize === 0 || labelSize === 1) {
      dispatch(setLabelSizeRedux(labelSize));
    }
  };
};

/* ACTION SAVE LABEL SIZE IN CORRESPONDENCE ENTITY*/
export const startSetlabelSize = (data: ILabelSize) => {
  return async (dispatch: any, getState: Function) => {
    const { user } = (getState() as AppState).auth;
    if (user && user.idEntity) {
      data.idEntity = user.idEntity;
      const { ok } = await setLabelSizeDb(data);
      if (ok) {
        dispatch(setLabelSizeRedux(data.labelSize));
        dispatch(setSuccessMsg("labelSizeUpdated"));
        dispatch(uiOpenSuccessAlert());
      } else {
        dispatch(setErrorMsg("ErrorlabelSizeUpdate"));
        dispatch(uiOpenErrorAlert());
      }
    }
  };
};

/* ACTION REDUX SAVE LABELSIZE */
export const setLabelSizeRedux = (
  labelSize: number
): ICorrespondenceActions => ({
  type: Types.setLabelSize,
  payload: labelSize,
});

// Cambiar estado de visto de una anotación
export const startReadedAnnotation = (annotation: IAnnotation) => {
  return async (dispatch: Function, getState: Function) => {
    const { user } = (getState() as AppState).auth;

    if (user) {
      if (annotation.sender.id === user.id.toString()) {
        annotation.isReaded = true;
      }

      if (annotation.recipients.includes(user.id.toString())) {
        annotation.recipientsData = annotation.recipientsData.map(
          (el: IRecipients) =>
            el.id.toString() === user.id.toString()
              ? { ...el, isReaded: true }
              : el
        );
      }
      try {
        user.idEntity && (await setAnnotationDb(user.idEntity, annotation));
      } catch (error) {
        console.log(error);
      }
    }
  };
};

import { FC } from "react";
import moment from "moment";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { TableCell, TableRow } from "@material-ui/core";
import { CustomizedIcons } from "../../../components/custom/CustomizedIcons";
import { useStyles } from "../../../shared/styles/useStyles";
import { AppState } from "../../../redux/reducers/rootReducer";
import { deleteDocumentCorrespondence } from "../../../redux/actions/documentManagmentActions";
import { Colors } from "../../../shared/constants/themes/Colors";
import { ICorrespondenceResponse } from "../../../interfaces/Correspondence";
import { CorrespondenceInputType } from "../../../enums/enums";

interface props {
  document: any;
  readonly?: boolean;
  component?: string;
  answers: ICorrespondenceResponse[];
}

export const DocumentsBody: FC<props> = ({
  document,
  readonly = false,
  component = "OTHER",
  answers,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { documentalType: allDocumentalType } = useSelector(
    (state: AppState) => state.trd
  );
  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { user } = useSelector((state: AppState) => state.auth);
  const onDeleteSettle = async (item: string) => {
    const isFileAlreadySended =  !!answers.find((answer) =>
    answer.emailData?.files.find((file) => file.url === document.url)
  )
    if (activeCorrespondence?.addressee.id === user?.id.toString() || !isFileAlreadySended) {
      Swal.fire({
        title: `${intl.formatMessage({
          id: "DeleteDocumentQuestion",
        })}${item}?`,
        icon: "warning",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: Colors.PRIMARY,
        cancelButtonColor: Colors.DANGER,
        confirmButtonText: `${intl.formatMessage({ id: "Yes" })}`,
        cancelButtonText: `${intl.formatMessage({ id: "No" })}`,
        customClass: {
          container: "swal-container-z-index",
        },
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          //await dispatch(startDeleteFuidCopy(copy.id,copy.userName, intl));
          const isSettled = document.filename ? true : false;
          if (isSettled) {
            const appendDocs = activeCorrespondence?.appendDocs?.filter(
              (el) => el.filename !== item
            );
            if (appendDocs && activeCorrespondence) {
              activeCorrespondence.appendDocs = appendDocs;
              await dispatch(
                deleteDocumentCorrespondence(item, activeCorrespondence, intl)
              );
            }
          } else {
            const digitalSupport = activeCorrespondence?.digitalSupport?.filter(
              (el: any) => el.fileName !== item
            );
            if (digitalSupport && activeCorrespondence) {
              activeCorrespondence.digitalSupport = digitalSupport;
              await dispatch(
                deleteDocumentCorrespondence(item, activeCorrespondence, intl)
              );
              // await dispatch(startSetCorrespondence(activeCorrespondence, true, false));
            }
          }
        },
      });
    } else {
      Swal.fire({
        title: `${intl.formatMessage({
          id: "ErrorDocumentDelete",
        })}`,
        text:
          activeCorrespondence?.inputType === CorrespondenceInputType.EP
            ? `${intl.formatMessage({
              
                id: "CantDeleteFileEmail",
              })}`
            : `${intl.formatMessage({
                id: "OnlyOwnerCanDelete",
              })}`,
        icon: "error",
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonColor: Colors.PRIMARY,
        denyButtonText: `${intl.formatMessage({ id: "Accept" })}`,
        customClass: {
          container: "swal-container-z-index",
        },
      });
    }
  };

  const findDocumentalTypeName = (id: string) => {
    const docType = allDocumentalType.find(
      (doc) => doc.idDocumentalType === id
    );
    if (docType) {
      return docType.name;
    }
    return "";
  };

  return (
    <TableRow>
      <TableCell
        component="th"
        scope="row"
        align="center"
        style={{ cursor: "pointer" }}
      >
        <a href={document.url && document.url} target="_blank" rel="noreferrer">
          {" "}
          {document.filename ? document.filename : document.fileName}{" "}
        </a>
      </TableCell>
      <TableCell align="center" className={classes.capitalize}>
        {document.trdCode
          ? document.trdCode
          : document.trdVersion
          ? document.trdVersion
          : ""}
      </TableCell>
      <TableCell align="center" style={{ textTransform: "lowercase" }}>
        {document.documentalType
          ? findDocumentalTypeName(document.documentalType)
          : ""}
      </TableCell>
      <TableCell align="center" style={{ textTransform: "lowercase" }}>
        {document.description ? document.description : ""}
      </TableCell>
      <TableCell align="center" style={{ textTransform: "lowercase" }}>
        {document.officer
          ? document.officer.name
          : document.owner
          ? document.owner.username
          : ""}
      </TableCell>
      <TableCell align="center" style={{ textTransform: "lowercase" }}>
        {document.uploadDate
          ? moment(document.uploadDate.toDate()).format("YYYY-MM-DD hh:mm a")
          : activeCorrespondence &&
            moment(activeCorrespondence.createAt.toDate()).format(
              "YYYY-MM-DD hh:mm a"
            )}
      </TableCell>
      {!readonly && (
        <TableCell align="center">
          {component !== "COPY" &&
            (document.owner?.id === user?.id.toString() ||
              document.officer?.id === user?.id.toString()) &&
            !!!answers.find((answer) =>
              answer.emailData?.files.find((file) => file.url === document.url)
            ) && (
              <CustomizedIcons
                //listIcon
                deleteIcon={
                  document.fileName || document.filename ? true : undefined
                }
                onDelete={() => {
                  document.filename
                    ? onDeleteSettle(document.filename)
                    : onDeleteSettle(document.fileName);
                }}
                //onList={onListSettle}
              />
            )}
        </TableCell>
      )}
    </TableRow>
  );
};

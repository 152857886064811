import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import {
  uiCloseErrorAlert,
  uiCloseModalAdd,
  uiCloseModalEdit,
  uiCloseModalSee,
  uiCloseSuccessAlert,
  uiOpenModalAdd,
} from "../../redux/actions/uiActions";
import { CustomizedSearch } from "../../components/custom/CustomizedSearch";
import CustomizedDialog from "../../components/custom/CustomizedDialog";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import { useStyles } from "../../shared/styles/useStyles";
import { AppState } from "../../redux/reducers/rootReducer";
import { FileForm } from "../../components/file-module/fuid/FileForm";
import { FuidTable } from "../../components/fuid/FuidTable";
import { MyAlert } from "../../components/custom/MyAlert";
import { FC } from "react";
import { TypeArchive } from "../../enums/enums";

import { IFuid } from "../../redux/types/types";
import { FuidDetails } from "../../components/document-managment/correspondence/FuidDetails";
import { CustomizedWindow } from "../../components/custom/CustomizedWindow";
import { setActiveFuidFileModule } from "../../redux/actions/fileModuleActions";

interface props {
  typeExpedients: IFuid[];
  readOnly?: boolean;
  fromModule?: TypeArchive;
}

export const FuidScreen: FC<props> = ({
  typeExpedients,
  readOnly = false,
  fromModule,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { activeExpedientFileModule: FuidFilesModule } = useSelector(
    (state: AppState) => state.fileModule
  );


  let selectExpedients: IFuid[] | null = [];

  if (typeExpedients?.length > 0) {
    selectExpedients = typeExpedients;
  }

  const {
    modalAddOpen,
    modalEditOpen,
    modalSeeOpen,
    successMsg,
    successAlert,
    errorAlert,
  } = useSelector((state: AppState) => state.ui);

  const onDeny = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalSee());
    dispatch(uiCloseModalEdit());
    dispatch(setActiveFuidFileModule(null))

    /* dispatch(setActiveFuid(null));
    dispatch( setActiveEx ) */
  };

  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box>
        <Box mb={3} fontSize={20}>
          <Grid container>
            {!readOnly && (
              <Grid item xs={6}>
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  className={classes.btnAdd}
                  type="button"
                  onClick={() => dispatch(uiOpenModalAdd())}
                  style={{ width: "23rem" }}
                >
                  <AddOutlinedIcon />
                  <FormattedMessage id="NewFile" />
                </Button>
              </Grid>
            )}
            {readOnly && <Grid item xs={6} />}
            <Grid item xs={1} />
            <Grid item xs={5}>
              <CustomizedSearch data={selectExpedients} />
            </Grid>
          </Grid>
        </Box>

        <FuidTable readOnly fromFilesModule expedients={selectExpedients} />

        <CustomizedWindow
          open={modalSeeOpen}
          content={<FuidDetails fromModule={fromModule}/>}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "File" })} ${
            FuidFilesModule?.fileName
          }`}
        />

        <CustomizedDialog
          open={modalAddOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "NewFile" })}`}
          content={
            fromModule ? <FileForm fromModule={fromModule} /> : <FileForm />
          }
          textButton="Settle"
        />
        <CustomizedDialog
          open={modalEditOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "UpdateFile" })}`}
          content={
            fromModule ? (
              <FileForm fromModule={fromModule} edit />
            ) : (
              <FileForm />
            )
          }
          textButton="Accept"
        />
        {successMsg && (
          <MyAlert
            open={successAlert}
            typeAlert="success"
            message={successMsg}
            time={2000}
            handleClose={() => dispatch(uiCloseSuccessAlert())}
          />
        )}

        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={"SavingDataError"}
          time={2000}
          handleClose={() => dispatch(uiCloseErrorAlert())}
        />
      </Box>
    </AppAnimate>
  );
};

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import {
  createTheme,
  ThemeProvider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { CustomizedTableFooter } from "../../custom/CustomizedTableFooter";
import { useStyles } from "../../../shared/styles/useStyles";
import { AppState } from "../../../redux/reducers/rootReducer";
import { UsersBody } from "./UsersBody";
import { User } from "../../../interfaces/Users";
import { Colors } from "../../../shared/constants/themes/Colors";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});

export const UsersTable = () => {
  const classes = useStyles();

  const { users } = useSelector((state: AppState) => state.users);
  const { data, value } = useSelector((state: AppState) => state.search);
  const list: User[] = users;
  let count: number = 0;

  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, list.length - page * rowsPerPage);

  if (!value.trim()) {
    count = list.length;
  } else {
    if (data) {
      count = data.length;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        {list.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            <FormattedMessage id={"NoCreatedUsers"} />
          </Box>
        ) : (
          <Table
            className={classes.table}
            size="small"
            style={{ tableLayout: "auto" }}
            aria-label="a dense table"
          >
            <TableHead className={classes.titleTable}>
              <TableRow>
                <TableCell className={classes.titleTable}>
                  <FormattedMessage id="Name" />{" "}
                </TableCell>
                <TableCell className={classes.titleTable}>
                  <FormattedMessage id="DocumentType" />{" "}
                </TableCell>
                <TableCell className={classes.titleTable}>
                  <FormattedMessage id="Identification" />{" "}
                </TableCell>
                <TableCell className={classes.titleTable}>
                  <FormattedMessage id="Email" />{" "}
                </TableCell>
                <TableCell className={classes.titleTable} align="center">
                  <FormattedMessage id="State" />{" "}
                </TableCell>
                <TableCell className={classes.titleTable} align="center">
                  <FormattedMessage id="Actions" />{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!value.trim()
                ? (rowsPerPage > 0
                    ? list.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : list
                  ).map((row) => <UsersBody key={row.document} {...row} />)
                : data &&
                  (rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((row) => <UsersBody key={row.document} {...row} />)}

              {emptyRows > 0 && (
                <TableRow style={{ height: 23 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {/* --------- Footer para paginación -------- */}
            <CustomizedTableFooter count={count} />
          </Table>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

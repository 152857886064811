import { CorrespondenceInputType } from "../enums/enums";
import { ICorrespondence } from "../interfaces/Correspondence";

export const filterCorrespondence = (
  correspondence: ICorrespondence,
  idUser: string
) => {
  if (
    correspondence.inputType === CorrespondenceInputType.IP ||
    correspondence.inputType === CorrespondenceInputType.IR
  ) {
    if (
      correspondence.inputType === CorrespondenceInputType.IP &&
      (correspondence.externalSender?.id === idUser ||
        correspondence.copyToIds?.includes(idUser) ||
        (correspondence.screeningRequest?.length !== undefined
          ? !!correspondence.screeningRequest.find(
              (item: any) => item.officerId === idUser
            )
          : correspondence.screeningRequest?.officerId === idUser))
    ) {
      return true;
    }
    if (
      correspondence.inputType === CorrespondenceInputType.IR &&
      (correspondence.addressee?.id === idUser ||
        correspondence.copyToIds?.includes(idUser) ||
        (correspondence.screeningRequest?.length !== undefined
          ? !!correspondence.screeningRequest.find(
              (item: any) => item.officerId === idUser
            )
          : correspondence.screeningRequest?.officerId === idUser))
    ) {
      return true;
    }
    return false;
  } else {
    return (
      correspondence.addressee?.id === idUser ||
      correspondence.externalSender?.id === idUser ||
      correspondence.copyToIds?.includes(idUser) ||
      (correspondence.screeningRequest?.length !== undefined
        ? !!correspondence.screeningRequest.find(
            (item: any) => item.officerId === idUser
          )
        : correspondence.screeningRequest?.officerId === idUser)
    );
  }
};

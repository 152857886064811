import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { getEntity } from "../../../redux/actions/entitiesActions";
import { ICorrespondenceResponse } from "../../../interfaces/Correspondence";
import { useStyles } from "../../../shared/styles/useStyles";
import { formatDate } from "../../../utils/utils";

export const EmailSent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { activeEntity } = useSelector((state: AppState) => state.entities);
  const { productionOffices } = useSelector((state: AppState) => state.trd);
  const { users, externalSenders } = useSelector(
    (state: AppState) => state.users
  );
  const { activeAnswer } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const answer = activeAnswer as ICorrespondenceResponse;

  useEffect(() => {
    dispatch(getEntity(activeCorrespondence?.idEntity ?? ""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCorrespondence?.idEntity]);

  const allUsers = users.concat(externalSenders as any);

  const senderFind = (allUsers as any[]).find(
    (user) =>
      user.id.toString() ===
        activeCorrespondence?.externalSender?.id?.toString() ||
      user.idDoc === activeCorrespondence?.externalSender.id
  );

  const addresseFind = (allUsers as any[]).find(
    (user) =>
      user.id.toString() === activeCorrespondence?.addressee?.id?.toString() ||
      user.idDoc === activeCorrespondence?.addressee.id
  );

  const officeFind = productionOffices.find((office) =>
    senderFind.offices
      ? senderFind.offices[0].includes(office.idProductionOffice)
      : addresseFind.offices[0].includes(office.idProductionOffice)
  );

  return (
    <>
      <main className={`${classes.pl1} ${classes.pr1}`}>
        {activeCorrespondence?.answer ? (
          <h2>
            Respuesta a correspondencia con radicado No.
            {activeCorrespondence.settledAnswerId}
          </h2>
        ) : (
          <h2>Correspondencia con radicado No. {activeCorrespondence?.id}</h2>
        )}

        <p className={`${classes.steelBlueColor} ${classes.mt2}`}>
          No responda a este e-mail automático
        </p>

        <p className={classes.mt1}>Hola.</p>

        <div className={classes.mt1}>
          {activeCorrespondence?.answer ? (
            <p>
              Se ha emitido la respuesta con radicado
              <strong> {activeCorrespondence.id}</strong> a la correspondencia
              recibida con radicado {activeCorrespondence.settledAnswerId}.
            </p>
          ) : (
            <p>
              Se ha emitido una correspondencia desde el sistema DocSpace con
              radicado No. {activeCorrespondence?.id}.
            </p>
          )}
        </div>

        <div className={`${classes.mt1} ${classes.steelBlueColor}`}>
          <p>
            Para responder a este mensaje o comunicarse con la entidad, por
            favor escriba al correo{" "}
            {answer?.emailSenderEntity ?? ""} y cite el siguiente
            radicado en el asunto:{" "}
            <strong className={classes.blackColor}>
              {activeCorrespondence?.id}.
            </strong>
          </p>
        </div>
        <div className={classes.mt2}>
          <p>
            <strong>Destinatario:</strong>
          </p>
          <p className={`${classes.mt1} ${classes.flex1}`}>
            {activeCorrespondence?.addressee.name}
          </p>
        </div>

        <p className={classes.mt2}>
          <strong>Asunto:</strong> {activeCorrespondence?.subject}
        </p>
        <p>{activeCorrespondence?.description}</p>
        <div className={classes.mt2}>
          <p>
            <strong>Archivos adjuntos:</strong>
          </p>
          <div className={classes.mt1}>
            {answer?.emailData?.files.map((file, i) => (
              <div key={i}>
                <a target="_blank" rel="noreferrer" href={file.url}>
                  {file.fileName}
                </a>
                <br />
              </div>
            ))}
          </div>
        </div>
        <p className={classes.mt3}>Muchas gracias.</p>

        <p className={classes.mt2}>Atentamente,</p>

        <p className={classes.mt1}>
          <strong>Entidad: </strong>
          {activeEntity?.businessName}
        </p>

        <p className={classes.mt1}>
          <strong>Fecha: </strong>
          {formatDate(activeCorrespondence?.createAt)}
        </p>

        <div className={classes.mt2}>
          <p className={classes.flex1}>
            <strong>Remitente:</strong>
          </p>
          <div className={`${classes.mt1} ${classes.flex1}`}>
            <p>{activeCorrespondence?.externalSender.name}</p>

            <p className={classes.mt1}>{officeFind?.name}</p>

            <p className={classes.mt1}>
              {activeEntity?.address} - {activeEntity?.phone}
            </p>

            <p className={classes.mt1}>
              Correo para correspondencia:{" "}
              <span className={classes.steelBlueColor}>
                {answer?.emailSenderEntity ?? ""}
              </span>
            </p>
          </div>
        </div>
        <p className={`${classes.mt2} ${classes.steelBlueColor}`}>
          No responda a este e-mail automático.
        </p>
        <p className={`${classes.mt1} ${classes.steelBlueColor}`}>
          Nota: La entrega por medio de correo electrónico se entiende como
          válida y no requiere de ser enviada por medio físico nuevamente.
          (Circular 242 de 2014, Circular 133 de 2012, Directiva Presidencial
          No. 04 de 2012 y Ley 527 de 1999).
        </p>
        <p className={classes.mt1}>Equipo de DocSpace.</p>
      </main>
    </>
  );
};

import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Divider,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useStyles } from "../../shared/styles/useStyles";
import user from "../../assets/images/user.jpg";
import Avatar from "@material-ui/core/Avatar";
import { FormattedMessage } from "react-intl";
import { getUserById } from "../../services/firebase/users";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";
import clsx from "clsx";
import { Form, Formik } from "formik";
import { MyTextField } from "../custom/MyTextField";
import { startNewExternalSender } from "../../redux/actions/correspondenceActions";
import { setActiveExternalSender } from "../../redux/actions/usersActions";

interface Props {
  id_user: any;
}

export const InfoModal = ({ id_user }: Props) => {
  const [dataUser, setDataUser]: any[] = useState([]);
  const { externalSenders } = useSelector((state: AppState) => state.users);
  const dispatch = useDispatch();
  useEffect(() => {
    const getData = async () => {
      const senderData = externalSenders.filter(
        (user) => user.idDoc === id_user || user.id === id_user
      );

      if (senderData.length > 0) {
        await setDataUser(senderData);
      } else {
        // TRAER DATOS USUARIO POR ID
        const userData: any = await getUserById(id_user);
        userData && (await setDataUser([userData]));
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id_user, setDataUser]);

  const classes = useStyles();
  const initialValues = {
    name:
      dataUser.length > 0
        ? dataUser[0].username
          ? dataUser[0].username
          : dataUser[0].name
          ? dataUser[0].name
          : ""
        : "",
    address: dataUser.length > 0 ? dataUser[0].address : "",
    id: dataUser.length > 0 ? dataUser[0].id : "",
    phone:
      dataUser.length > 0
        ? dataUser[0].mobilePhone
          ? dataUser[0].mobilePhone
          : dataUser[0].phone
          ? dataUser[0].phone
          : ""
        : "",
    email: dataUser.length > 0 ? dataUser[0].email : "",
  };

  return (
    <Box m={1} sx={{ width: "100%" }}>
      <Grid container spacing={2}>
        {dataUser[0] && (
          <>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="space-between"
                marginBottom={3}
              >
                <Avatar className={classes.profilePic} src={user} />
                <label className="form-text">
                  {dataUser[0].firstName && dataUser[0].firstLastName ? (
                    <>
                      {" "}
                      {dataUser[0].firstName} {dataUser[0].firstLastName}
                    </>
                  ) : (
                    <>{dataUser[0].name}</>
                  )}
                </label>
              </Box>
              <Divider />
            </Grid>
            <Formik
              initialValues={initialValues}
              onSubmit={async (values: any, { setSubmitting }) => {
                setSubmitting(true);
                if (dataUser.length > 0 && dataUser[0].idDoc) {
                  values.idDoc = dataUser[0].idDoc;
                  dispatch(setActiveExternalSender(dataUser[0]));
                  await dispatch(startNewExternalSender(values));
                  dispatch(setActiveExternalSender(null));
                }

                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <h1>
                        <FormattedMessage id="PersonalInformation" />
                      </h1>
                    </Grid>
                    <Grid item xs={6}>
                      <h1>
                        <FormattedMessage id="OtherData" />
                      </h1>
                    </Grid>
                    <Grid item xs={6}>
                      <label className="form-text">
                        <FormattedMessage id="UserName" />
                      </label>
                      <MyTextField
                        disabled={dataUser[0].uid}
                        name="name"
                        variant="outlined"
                        className={classes.myTextFieldRoot}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <label className="form-text">
                        <FormattedMessage id="Address" />
                      </label>
                      <MyTextField
                        variant="outlined"
                        className={classes.myTextFieldRoot}
                        name="address"
                        disabled={dataUser[0].uid}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <label className="form-text">
                        <FormattedMessage id="Identification" />
                      </label>
                      <MyTextField
                        variant="outlined"
                        className={classes.myTextFieldRoot}
                        name="id"
                        disabled={dataUser[0].uid}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <label className="form-text">
                        <FormattedMessage id="Mobile" />
                      </label>
                      <MyTextField
                        variant="outlined"
                        className={classes.myTextFieldRoot}
                        name="phone"
                        disabled={dataUser[0].uid}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <label className="form-text">
                        <FormattedMessage id="Email" />
                      </label>
                      <MyTextField
                        variant="outlined"
                        className={classes.myTextFieldRoot}
                        name="email"
                        disabled={dataUser[0].uid}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <label className="form-text">
                        <FormattedMessage id="BusinessName" />
                      </label>
                      <TextField
                        variant="outlined"
                        className={classes.myTextFieldRoot}
                        defaultValue={
                          dataUser[0].businessName
                            ? dataUser[0].businessName
                            : dataUser[0].name
                            ? dataUser[0].name
                            : ""
                        }
                        size="small"
                        disabled
                      />
                    </Grid>
                  </Grid>
                  {!dataUser[0].uid && (
                    <Box mt={2} width={1}>
                      {!isSubmitting ? (
                        <Button
                          className={clsx(classes.btn, classes.save)}
                          type="submit"
                          disabled={isSubmitting}
                        >
                          <FormattedMessage id="Edit" />
                        </Button>
                      ) : (
                        <Button
                          className={clsx(classes.btn, classes.save)}
                          autoFocus
                          type="button"
                          disabled={true}
                        >
                          <CircularProgress className={classes.btnLoading} />
                        </Button>
                      )}
                    </Box>
                  )}
                </Form>
              )}
            </Formik>
          </>
        )}
      </Grid>
    </Box>
  );
};

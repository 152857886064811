import clsx from "clsx";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";

import { useStyles } from "../../../shared/styles/useStyles";
import { uiCloseModalReject } from "../../../redux/actions/uiActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { startRejectTransfer } from "../../../redux/actions/fileModuleActions";

export const RejectTransfer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState("");

  const { activeTransfer } = useSelector(
    (state: AppState) => state.documentManagment
  );
 /*  const { transfers } = useSelector(
    (state: AppState) => state.fileModule
  );
  const { transfers:transferManagment } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const transferManag = transferManagment.find(tra=>tra.idTransfer === activeTransfer?.idTransfer);
  const transferCentralFile = transfers.find(tra=>tra.idTransfer === activeTransfer?.idTransfer);
  const isTransferFromCentralFile = transferCentralFile && !transferManag?true:false */

  const onClose = () => {
    dispatch(uiCloseModalReject());
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComments(event.target.value);
  };

  const handleReject = async () => {
    setLoading(true);
    // TODO: Disparar acción
    //rechazar transferencia (eliminarla)
    if (activeTransfer) {
     
      const transfer = {...activeTransfer}
      //acomodar interface de ITransfer para recibir isRejected, rejectComment y rejectedDate 
      transfer.isRejected=true
      transfer.rejectComment = comments.length>0?comments.trim():null
      await dispatch(startRejectTransfer(transfer, true));
    }
    setLoading(false);
  };

  return (
    <>
      <Box m={2} mt={1} mb={2}>
        <Typography variant="body2" style={{ fontSize: 14 }}>
          <FormattedMessage id="RejectTransferMessage" />
        </Typography>
        

        <Grid container>
          <Grid item xs={12}>
            <label className="form-text">
              <FormattedMessage id="Comment" />
            </label>
            <TextField
              name="comment"
              variant="outlined"
              className={classes.myTextFieldRoot}
              value={comments}
              onChange={handleChange}
              multiline
              rows={3}
              // disabled={details}
            />
          </Grid>
        </Grid>
      
      </Box>
      <Divider variant="fullWidth" />
      <Box mt={2} display="flex" flexDirection="row-reverse" width={1}>
        {!loading ? (
          <Button
            className={clsx(classes.btn, classes.save)}
            autoFocus
            type="submit"
            disabled={loading}
            onClick={handleReject}
          >
            <FormattedMessage id="Reject" />
          </Button>
        ) : (
          <Button
            className={clsx(classes.btn, classes.save)}
            autoFocus
            type="button"
            disabled={true}
          >
            <CircularProgress className={classes.btnLoading} />
          </Button>
        )}
        <Button className={clsx(classes.btn, classes.cancel)} onClick={onClose}>
          <FormattedMessage id="Cancel" />
        </Button>
      </Box>
    </>
  );
};

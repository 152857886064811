import { FC } from "react";
// import { useDispatch } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";
import { useStyles } from "../../../shared/styles/useStyles";
import { Enum_LoanState } from "../../../interfaces/Loans";
import moment from "moment";
import { FormattedMessage } from "react-intl";
interface props {
  loan: any;
  readonly?: boolean;
}

export const LoanBody: FC<props> = ({ loan, readonly = false }) => {
  const classes = useStyles();
  //   const dispatch = useDispatch();

  return (
    <TableRow key={loan.id}>
      <TableCell component="th" scope="row" align="center">
        {loan.officer.name}
      </TableCell>
      <TableCell align="center" className={classes.capitalize}>
        {loan.phone}
      </TableCell>
      <TableCell align="center" style={{ textTransform: "lowercase" }}>
        {loan.idExpedient}
      </TableCell>
      <TableCell align="center" style={{ textTransform: "lowercase" }}>
        {loan.description}
      </TableCell>
      <TableCell align="center" style={{ textTransform: "lowercase" }}>
        {moment(loan.returnDate.toDate()).format("YYYY/MM/DD")}
      </TableCell>
      {loan.state === Enum_LoanState.Returned ? (
        <>
          <TableCell align="center" style={{ textTransform: "lowercase" }}>
            0
          </TableCell>
          <TableCell
            align="center"
            style={{ textTransform: "lowercase", backgroundColor: "green" }}
          >
            <FormattedMessage id={Enum_LoanState.Returned} />
          </TableCell>
        </>
      ) : (
        <>
          <TableCell align="center" style={{ textTransform: "lowercase" }}>
            {moment(loan.returnDate.toDate()).diff(moment(), "days")}
          </TableCell>
          <TableCell
            align="center"
            style={{ textTransform: "lowercase", backgroundColor: "red" }}
          >
            <FormattedMessage id={Enum_LoanState.WithoutReturning} />
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

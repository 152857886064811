import { Form, Formik } from "formik";
import { useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import * as yup from "yup";

import {
  Box,
  Button,
  Checkbox,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { MyTextField } from "../custom/MyTextField";
import { useStylesLogin } from "../../shared/styles/useStyles";
import { useDispatch, useSelector } from "react-redux";
import { startLogin } from "../../redux/actions/authActions";
import { AppState } from "../../redux/reducers/rootReducer";

export const LoginForm = () => {
  const intl = useIntl();
  const classes = useStylesLogin();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const { loading } = useSelector((state: AppState) => state.ui);

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(`${intl.formatMessage({ id: "InvalidEmail" })}`)
      .required(`${intl.formatMessage({ id: "EmailRequired" })}`),
    password: yup
      .string()
      .required(`${intl.formatMessage({ id: "PasswordRequired" })}`),
  });
  const handleClick = () => {
    setShowPassword((prev) => !prev);
  };
  return (
    <Box flex={1} display="flex" flexDirection="column">
      <Box
        px={{ xs: 6, sm: 10, xl: 15 }}
        pt={5}
        flex={1}
        display="flex"
        flexDirection="column"
        className={classes.dividerRoot}
      >
        <Formik
          validateOnChange={true}
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            dispatch(startLogin(data.email, data.password));

            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <Form className={classes.form} noValidate autoComplete="off">
              <Box mb={{ xs: 5, xl: 8 }}>
                <MyTextField
                  placeholder={intl.formatMessage({ id: "Email" })}
                  name="email"
                  variant="outlined"
                  className={classes.textField}
                />
              </Box>

              <Box mb={{ xs: 3, lg: 4 }}>
                <MyTextField
                  placeholder={intl.formatMessage({ id: "Password" })}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  variant="outlined"
                  className={classes.textField}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClick}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box
                mb={{ xs: 3, xl: 4 }}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems={{ sm: "center" }}
                justifyContent={{ sm: "space-between" }}
                fontSize={15}
              >
                <Box display="flex" alignItems="center">
                  <Checkbox className={classes.checkboxRoot} />
                  <Box className={classes.textGrey} component="span">
                    <FormattedMessage id="RememberMe" />
                  </Box>
                </Box>
                <Box
                  component="span"
                  ml={{ sm: 4 }}
                  className={classes.pointer}
                  fontSize={15}
                >
                  <Link to="/auth/forgot">
                    <FormattedMessage id="ForgetPassword" />
                  </Link>
                </Box>
              </Box>

              <Box
                mb={2}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems={{ sm: "center" }}
                justifyContent={{ sm: "space-between" }}
              >
                {!loading ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={loading}
                    className={classes.btnRoot}
                  >
                    <FormattedMessage id="SignIn" />
                  </Button>
                ) : (
                  <Button
                    className={classes.btnRoot}
                    autoFocus
                    type="button"
                    disabled={true}
                  >
                    <CircularProgress className={classes.btnLoading} />
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
 
  apiKey: "AIzaSyDHapGfKFZ9vdNTGdYPUY4EXpuM0th9nIU",
  authDomain: "modulosmialcaldia.firebaseapp.com",
  projectId: "modulosmialcaldia",
  storageBucket: "modulosmialcaldia.appspot.com",
  messagingSenderId: "419719717970",
  appId: "1:419719717970:web:4edfc9a7db021b56e82b8b",
  measurementId: "G-FT8BTCK6EJ",

 /*  
  apiKey: "AIzaSyB_LqCbKpvVrukecJFRs2VR0x3SCdSR0sE",
  authDomain: "appsus-59509.firebaseapp.com",
  projectId: "appsus-59509",
  storageBucket: "appsus-59509.appspot.com",
  messagingSenderId: "1052097204190",
  appId: "1:1052097204190:web:99451cca026c4e75c8974f",
  */
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
export { db, firebase };

// import { useEffect } from "react";
import {
  MenuItem,
  Box,
  Grid,
  Divider,
  Paper,
  Button,
  CircularProgress,
} from "@material-ui/core";
// import { ContractingOffice } from "../../../enums/enums";
import { FormattedMessage, useIntl } from "react-intl";
import { useStyles } from "../../../shared/styles/useStyles";
import { Form, Formik } from "formik";
import { MyTextField } from "../../custom/MyTextField";
import { AppState } from "../../../redux/reducers/rootReducer";
import * as yup from "yup";
import clsx from "clsx";
import { startSetContractingOffice } from "../../../redux/actions/correspondenceActions";
import { useDispatch, useSelector } from "react-redux";
import { MyAlert } from "../../custom/MyAlert";
import {
  setSuccessMsg,
  uiCloseErrorAlert,
  uiCloseSuccessAlert,
} from "../../../redux/actions/uiActions";
import { IContractingOffice } from "../../../redux/types/types";
import { ConfigureLabels } from "./ConfigureLabels";

export const OthersTab = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { successAlert, errorAlert, successMsg, errorMsg } = useSelector(
    (state: AppState) => state.ui
  );

  const { productionOffices } = useSelector((state: AppState) => state.trd);
  const { users } = useSelector((state: AppState) => state.users);

  const { contractingOffice } = useSelector(
    (state: AppState) => state.correspondence
  );

  const onCloseSuccessAlert = () => {
    dispatch(uiCloseSuccessAlert());
    dispatch(setSuccessMsg(null));
  };

  const initialValues = {
    // productionOffice: productionOffices[0].idProductionOffice,
    productionOffice: contractingOffice
      ? contractingOffice.productionOffice
      : "",
    responsable:
      contractingOffice && contractingOffice.responsibleUser
        ? contractingOffice?.responsibleUser.id
        : "",
  };

  //   VALIDACIONES YUP
  const validationSchema = yup.object({
    productionOffice: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    responsable: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });
  return (
    <>
      <Divider variant="fullWidth" />
      <Paper square className={classes.paper}>
        <Box mb={3}>
          <Box style={{ height: "5px" }} />
          <Box m={1} width={1} ml={5} mr={5}>
            <Grid container spacing={2} style={{ marginBottom: "20px" }}>
              <Grid item xs={6}>
                <Formik
                  validateOnChange={true}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={async (data, { setSubmitting }) => {
                    setSubmitting(true);
                    const userFind = users.find(
                      (el) => el.id.toString() === data.responsable
                    );
                    if (userFind) {
                      const objParam: IContractingOffice = {
                        idEntity: "",
                        productionOffice: data.productionOffice,
                        responsibleUser: {
                          id: data.responsable,
                          name:
                            userFind.firstName +
                            " " +
                            userFind.secondName +
                            " " +
                            userFind.firstLastName +
                            " " +
                            userFind.secondLastName,
                        },
                      };
                      await dispatch(startSetContractingOffice(objParam));
                    }
                    setSubmitting(false);
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form className={classes.input}>
                      <Grid
                        container
                        spacing={2}
                        className={classes.myTextFieldRootflex}
                      >
                        <Grid item xs={12} className={classes.title}>
                          <label
                            className="form-text"
                            style={{
                              display: "inline-block",
                              marginTop: "10px",
                            }}
                          >
                            <FormattedMessage id="contractingOffice" />*
                          </label>
                        </Grid>
                        <Grid item xs={12}>
                          <MyTextField
                            name="productionOffice"
                            variant="outlined"
                            select
                            className={classes.myTextFieldRoot}
                            size="small"
                          >
                            {productionOffices.map((item, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={item.idProductionOffice}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </MyTextField>
                        </Grid>
                        <Grid item xs={12} className={classes.title}>
                          <label
                            className="form-text"
                            style={{
                              display: "inline-block",
                              marginTop: "10px",
                            }}
                          >
                            <FormattedMessage id="ResponsibleUser" />*
                          </label>
                        </Grid>
                        <Grid item xs={12}>
                          <MyTextField
                            name="responsable"
                            variant="outlined"
                            select
                            className={classes.myTextFieldRoot}
                            size="small"
                          >
                            {users.map((item) => {
                              return (
                                <MenuItem
                                  key={item.id.toString()}
                                  value={item.id.toString()}
                                >
                                  {item.firstName +
                                    " " +
                                    item.secondName +
                                    " " +
                                    item.firstLastName +
                                    " " +
                                    item.secondLastName}
                                </MenuItem>
                              );
                            })}
                          </MyTextField>
                        </Grid>
                      </Grid>
                      <Box
                        mt={5}
                        display="flex"
                        alignItems="center"
                        flexDirection="column-reverse"
                      >
                        {!isSubmitting ? (
                          <Button
                            className={clsx(classes.btn, classes.save)}
                            autoFocus
                            type="submit"
                            disabled={isSubmitting}
                          >
                            <FormattedMessage id="Save" />
                          </Button>
                        ) : (
                          <Button
                            className={clsx(classes.btn, classes.save)}
                            autoFocus
                            type="button"
                            disabled={true}
                          >
                            <CircularProgress className={classes.btnLoading} />
                          </Button>
                        )}
                      </Box>
                      <br />
                      {successMsg && (
                        <MyAlert
                          open={successAlert}
                          typeAlert="success"
                          message={successMsg}
                          time={2000}
                          handleClose={onCloseSuccessAlert}
                        />
                      )}
                      {errorMsg && (
                        <MyAlert
                          open={errorAlert}
                          typeAlert="error"
                          message={errorMsg}
                          time={3000}
                          handleClose={() => dispatch(uiCloseErrorAlert())}
                        />
                      )}
                    </Form>
                  )}
                </Formik>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={5}>
                <ConfigureLabels />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

import { Box, IconButton, Paper, Tooltip } from "@material-ui/core";
import { useStyles } from "../../shared/styles/useStyles";
import { Close } from "@material-ui/icons";

import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";
import { CardNavigation } from "../../components/ui/navigation/cards/CardNavigation";
import { startLogout } from "../../redux/actions/authActions";
import { FormattedMessage, useIntl } from "react-intl";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Colors } from "../../shared/constants/themes/Colors";

export const Modules = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { user } = useSelector((state: AppState) => state.auth);
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(startLogout());
  };
  return (
    <Box className={classes.modulesContainer}>
      <Tooltip title={`${intl.formatMessage({ id: "SignOut" })}`}>
        <IconButton
          aria-label="toggle logout"
          onClick={handleLogout}
          className={classes.closeBtn}
        >
          <Close fontSize="small" style={{ color: "#fff" }} />
        </IconButton>
      </Tooltip>
      {user && user.modules?.length === 0 ? (
        <Paper square style={{ width: "40%", opacity: 0.8 }}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              height: "10vh",
              backgroundColor: Colors.TEXT_ICONS,
            }}
            className={classes.title}
          >
            <InfoOutlinedIcon
              style={{ fontSize: 30, color: Colors.DARK_BUTTON }}
            />
            <FormattedMessage id={"NoModulesAssigned"} />
          </Box>
        </Paper>
      ) : (
        <>{user && user.modules && <CardNavigation modules={user.modules} />}</>
      )}
    </Box>
  );
};

import clsx from "clsx";
import { Form, Formik } from "formik";
import { FC, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { FileCopy, Delete } from "@material-ui/icons";
import { IFiles } from "../../../redux/types/types";
import { MyTextField } from "../../custom/MyTextField";
import { useSelector, useDispatch } from "react-redux";
import { useStyles } from "../../../shared/styles/useStyles";
import { FormattedMessage, useIntl } from "react-intl";
import { AppState } from "../../../redux/reducers/rootReducer";

import {
  uiCloseModalAnswerBroadcast,
  uiCloseModalEdit,
} from "../../../redux/actions/uiActions";
import * as yup from "yup";
import { ActionSettled, Enum_FileSize } from "../../../enums/enums";

interface Props {
  edit: boolean;
}

export const AnswerFormBroadcast: FC<Props> = ({ edit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [noValid, setNoValid] = useState(false);
  const [noValidSize, setNoValidSize] = useState(false);
  const [labelImage, setLabelImage] = useState("");
  const { activeAnswer: active } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const [fileNames, setFileNames] = useState<IFiles[]>(
    active && active.attachedDocuments ? active.attachedDocuments : []
  );

  const handleSelectFile = (e: any) => {
    const SUPPORTED_FORMATS = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/png",
      "application/msword",
      "text/csv",
      "application/pdf",
    ];
    const file = e.target.files[0] as File;
    const now = new Date().getTime().toString();
    if (file) {
      if (file.size > Enum_FileSize.maxAllowedSize) {
        setNoValidSize(true);
      } else {
        if (SUPPORTED_FORMATS.includes(file.type)) {
          setNoValid(false);
          setFileNames((prevState) => [
            { url: "#", fileName: now + "_" + file.name, file },
            ...prevState,
          ]);
          setLabelImage(e.target.files[0].name);
        } else {
          setNoValid(true);
        }
      }
    } else {
      setNoValidSize(false);
      setNoValid(false);
      setLabelImage("");
    }
  };
  const ActionSettledArr = [
    ActionSettled.APPROVED,
    ActionSettled.ANNOTATION,
    ActionSettled.ASSIGN_TO_FILE,
    ActionSettled.ISSUANCE_OF_RESPONSE,
    ActionSettled.ISSUANCE_VO_BO,
    ActionSettled.REQUEST_ADDITIONAL_INFORMATION,
    ActionSettled.REQUEST_FOR_PROJECTION,
    ActionSettled.VO_BO_APPROVED,
    ActionSettled.VO_BO_REJECTED,
  ];
  const initialValues = {
    // officer: active ? active.officer.name : "",
    officer: active ? active.addresseeName : "",
    subject: active
      ? ActionSettledArr.includes(active.subject as any)
        ? intl.formatMessage({ id: active.subject })
        : active.subject
      : "",
    description: active && active.description ? active.description : "",
    file: "",
  };

  const validationSchema = yup.object({
    subject: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    description: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  const onClose = () => {
    dispatch(uiCloseModalAnswerBroadcast());
    dispatch(uiCloseModalEdit());
  };

  // ARCHIVOS SOPORTADOS PARA INPUT TYPE=FILE
  const fileSupported = {
    accept:
      "application/pdf, image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv",
  };

  return (
    <Box m={1}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          setSubmitting(false);
        }}
      >
        {({ values, isSubmitting, handleChange, setFieldValue }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormattedMessage id="CorrespondenceRespondeAnswer" />
              </Grid>
              {edit && (
                <Grid item xs={12}>
                  <label className="form-text">
                    <FormattedMessage id="Addressee" />
                  </label>
                  <TextField
                    name="officer"
                    disabled={edit}
                    variant="outlined"
                    value={values.officer}
                    className={classes.myTextFieldRoot}
                    size="small"
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Subject" />*
                </label>
                <MyTextField
                  name="subject"
                  disabled={edit}
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Description" />*
                </label>
                <MyTextField
                  name="description"
                  variant="outlined"
                  disabled={edit}
                  multiline
                  rows={3}
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              {!edit && (
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <label className="form-text">
                      <FormattedMessage id="DigitalSupport" />:
                    </label>
                    <Box ml={2}>
                      <TextField
                        size="small"
                        type="file"
                        inputProps={fileSupported}
                        onChange={handleSelectFile}
                        id="icon-button-file"
                        autoComplete="off"
                        style={{ display: "none" }}
                      />

                      <MyTextField
                        disabled={true}
                        variant="outlined"
                        style={{ color: "black" }}
                        label={labelImage}
                        name="file"
                        value={labelImage}
                        className={classes.myTextFieldRoot}
                      />
                    </Box>
                    <Box mt={1}>
                      <Tooltip
                        title={`${intl.formatMessage({ id: "Attach" })}`}
                      >
                        <label htmlFor="icon-button-file">
                          <Button
                            className={clsx(classes.btnAction, classes.save)}
                            component="span"
                          >
                            <FileCopy /> <FormattedMessage id="Attach" />
                          </Button>
                        </label>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
              )}

              {noValid && (
                <Grid item xs={12}>
                  <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                    <FormattedMessage id="ValidFiles" />
                  </Box>
                </Grid>
              )}
              {noValidSize && (
                <Grid item xs={12}>
                  <Box mt={-2} ml={2} style={{ fontSize: 12, color: "red" }}>
                    <FormattedMessage id="ValidDocumentalFilesSize" />
                  </Box>
                </Grid>
              )}
              {fileNames.map(({ fileName, url }) => {
                return (
                  <Grid container key={fileName}>
                    <Grid item xs={6} key={fileName}>
                      <Box style={{ fontSize: 12, color: "blue" }}>
                        <a
                          href={url}
                          className={edit ? classes.disabled : undefined}
                          target="__blank"
                        >
                          {fileName}
                        </a>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Tooltip
                        title={`${intl.formatMessage({ id: "Delete" })}`}
                      >
                        <IconButton
                          disabled={edit}
                          onClick={() => console.log("Borrar el archivo")}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            {!edit && (
              <Box mt={2} display="flex" flexDirection="row-reverse">
                {!isSubmitting ? (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id="Save" />
                  </Button>
                ) : (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="button"
                    disabled={true}
                  >
                    <CircularProgress className={classes.btnLoading} />
                  </Button>
                )}
                <Button
                  className={clsx(classes.btn, classes.cancel)}
                  onClick={onClose}
                >
                  <FormattedMessage id="Cancel" />
                </Button>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

import { useSelector } from "react-redux";
import { TransferType, TypeArchive } from "../../enums/enums";
import { AppState } from "../../redux/reducers/rootReducer";
import { FuidScreen } from "./FuidScreen";
export const HistoricalFilesScreen = () => {
  const { expedients } = useSelector((state: AppState) => state.fileModule);
  const expedientsByTransferType = expedients?.filter(
    (exp) =>
      exp?.transferType! === TransferType.SECONDARY_TRANSFER &&
      exp.deposit !== null 
  );
  return <FuidScreen typeExpedients={expedientsByTransferType} fromModule={TypeArchive.HISTORICAL_ARCHIVE}/> ;
};

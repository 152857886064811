import { FC, useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Box,
  createTheme,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  ThemeProvider,
  TableRow,
  TableBody,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { Colors } from "../../../shared/constants/themes/Colors";
import { useStyles } from "../../../shared/styles/useStyles";
import { CorrespondenceStatisticsBody } from "./CorrespondenceStatisticsBody";
import { optionsCommunicationType } from "../../../shared/constants/communicationTypes";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  ICorrespondence,
  ICorrespondenceStatistics,
} from "../../../interfaces/Correspondence";
import { CorrespondenceInputType } from "../../../enums/enums";
import moment from "moment-business-days";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});

interface Props {
  correspondences: ICorrespondence[];
}
export const CorrespondenceStatisticsTable: FC<Props> = ({
  correspondences: correspondence,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const { paramResponseTime, paramsProcedure }: any = useSelector(
    (state: AppState) => state.correspondence
  );
  const [finalArrStadistics, setFinalArrStadistics] = useState<
    ICorrespondenceStatistics[]
  >([]);

  const getResponseNum = (corres: ICorrespondence, onTime: boolean) => {
    const { communicationType, createAt, answeredDate } = corres;
    if (!communicationType || !communicationType.isParam || !answeredDate)
      return false;
    const paramNameDays = communicationType.option + "Days";
    //const paramNameTypeDays = communicationType.option + "TypeDays";
    const daysAvailableDocument = paramResponseTime[paramNameDays];
    //const typeDays = paramResponseTime[paramNameTypeDays];
    const answerDateDoc = moment(answeredDate.toDate());
    const createDate = moment(createAt.toDate());
    const calculateResponseDate = createDate.add(daysAvailableDocument, "d");

    return onTime
      ? answerDateDoc.isSameOrBefore(calculateResponseDate)
      : answerDateDoc.isAfter(calculateResponseDate);
  };

  useEffect(() => {
    if (paramsProcedure.length > 0) {
      let optionsParamsProcedure: any[] = [];
      paramsProcedure.map((item: any) =>
        optionsParamsProcedure.push({
          isParam: true,
          option: item.procedureName,
          responseTime: item.responseTime,
        })
      );

      const newOptions = optionsCommunicationType.concat(
        optionsParamsProcedure
      );

      let arrObj: ICorrespondenceStatistics[] = [];
      newOptions.forEach((data) => {
        const obj: ICorrespondenceStatistics = {
          communicationType:
            data.responseTime === 0 || !data.responseTime
              ? `${intl.formatMessage({
                  id: `${data.option}`,
                })}`
              : `${data.option}`,
          numEr: correspondence.filter(
            (cor) =>
              cor.communicationType?.option === data.option &&
              cor.inputType === CorrespondenceInputType.ER
          ).length,
          numEp: correspondence.filter(
            (cor) =>
              cor.communicationType?.option === data.option &&
              cor.inputType === CorrespondenceInputType.EP
          ).length,
          numAnswered: correspondence.filter(
            (cor) =>
              cor.communicationType?.option === data.option && cor.answeredDate
          ).length,
          numIp: correspondence.filter(
            (cor) =>
              cor.communicationType?.option === data.option &&
              cor.inputType === CorrespondenceInputType.IP
          ).length,
          numIr: correspondence.filter(
            (cor) =>
              cor.communicationType?.option === data.option &&
              cor.inputType === CorrespondenceInputType.IR
          ).length,
          numNoNeedAnswer: correspondence.filter(
            (cor) =>
              cor.communicationType?.option === data.option &&
              !cor.communicationType?.isParam
          ).length,
          numOp: correspondence.filter(
            (cor) =>
              cor.communicationType?.option === data.option &&
              cor.inputType === CorrespondenceInputType.OP
          ).length,
          numOr: correspondence.filter(
            (cor) =>
              cor.communicationType?.option === data.option &&
              cor.inputType === CorrespondenceInputType.OR
          ).length,
          numResponseOnTime: correspondence.filter(
            (cor) =>
              cor.communicationType?.option === data.option &&
              getResponseNum(cor, true)
          ).length,
          numResponseOutTime: correspondence.filter(
            (cor) =>
              cor.communicationType?.option === data.option &&
              getResponseNum(cor, false)
          ).length,
          numWithOutAnswer: correspondence.filter(
            (cor) =>
              cor.communicationType?.option === data.option && !cor.answeredDate
          ).length,
        };
        arrObj.push(obj);
      });

      setFinalArrStadistics(arrObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsProcedure]);

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper} className={classes.heightTable}>
        {correspondence.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            <FormattedMessage id={"NoData"} />
          </Box>
        ) : (
          <Table
            stickyHeader
            className={classes.table}
            size="small"
            style={{ tableLayout: "auto" }}
            aria-label="a dense table"
          >
            <TableHead className={classes.titleTable}>
              <TableRow className={classes.borderTable}>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Type" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="ExternalReceived" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="ExternalProduced" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="InternalReceived" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="InternalProduced" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="OtherShipmentsReceived" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="OtherShipmentsProduced" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Answered" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Unanswered" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="NoAnswerNeeded" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="ResponseOnTime" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="ExtemporaneousResponse" />{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* MAPEAR LOS TIPOS DE COMUNICACIONES */}
              {finalArrStadistics.map((row, index) => (
                <CorrespondenceStatisticsBody
                  key={row.communicationType + "_" + index}
                  data={row}
                />
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

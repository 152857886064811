import clsx from "clsx";
import memoize from "fast-memoize";
import { Formik, Form } from "formik";
import { useState, useCallback, FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { MyTextField } from "../../custom/MyTextField";
import { CommunicationChannels } from "../../../enums/enums";
import { IUserCorrespondence } from "../../../interfaces/Correspondence";
import { User } from "../../../interfaces/Users";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
interface Props {
  onSearch(data: any): void;
}
export const CorrespondenceStatisticsForm: FC<Props> = ({ onSearch }) => {
  const classes = useStyles();
  const intl = useIntl();

  const { productionOffices } = useSelector((state: AppState) => state.trd);
  const { users } = useSelector((state: AppState) => state.users);
  const [officerSelected, setOfficerSelected] =
    useState<IUserCorrespondence | null>(null);
  const [errorOfficer, setErrorOfficer] = useState(false);

  const initialValues = {
    idProductionOffice: "",
    officer: { id: "", name: "" },
    startDate: new Date().toLocaleDateString("en-CA"),
    endDate: new Date().toLocaleDateString("en-CA"),
    channel: "",
  };

  const validationSchema = yup.object({
    idProductionOffice: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    officer: yup.object(),
    startDate: yup
      .date()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`)
      .max(
        yup.ref("endDate"),
        `${intl.formatMessage({ id: "GreaterStartDate" })}`
      ),
    endDate: yup
      .date()
      .min(
        yup.ref("startDate"),
        `${intl.formatMessage({ id: "LowerEndDate" })}`
      )
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    channel: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUsers = useCallback(
    memoize((dependency: string) => {
      const filteredUsers: User[] =
        dependency === "Todos"
          ? users
          : (users as User[]).filter((el) =>
              el.offices.toString().includes(dependency)
            );
      const arrayUsers: IUserCorrespondence[] = [{ id: "0", name: "Todos" }];

      filteredUsers.forEach((user: User) => {
        arrayUsers.push({
          id: user.id.toString(),
          name:
            user.firstName +
            " " +
            user.secondName +
            " " +
            user.firstLastName +
            " " +
            user.secondLastName,
        });
      });

      return arrayUsers;
    }),
    []
  );

  const handleSelectOfficer = (value: any) => {
    !value ? setErrorOfficer(true) : setErrorOfficer(false);

    value ? setOfficerSelected(value) : setOfficerSelected(null);
  };

  const validateOfficer = () => {
    !officerSelected ? setErrorOfficer(true) : setErrorOfficer(false);
  };
  return (
    <Box mt={1} mb={3}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (!errorOfficer) {
            values.officer = officerSelected as IUserCorrespondence;
            setSubmitting(true);
            onSearch(values);
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="body2"
                    className={classes.typography}
                    style={{ marginTop: 12 }}
                  >
                    <FormattedMessage id="SelectProductionOffice" />
                  </Typography>

                  <Box width={"55%"} mr={0} alignSelf="flex-end">
                    <MyTextField
                      name="idProductionOffice"
                      variant="outlined"
                      select
                      className={classes.myTextFieldRoot}
                      onChange={() => setOfficerSelected(null)}
                    >
                      <MenuItem value="Todos">Todos</MenuItem>
                      {productionOffices.map((productionOffice) => (
                        <MenuItem
                          key={productionOffice.idProductionOffice}
                          value={productionOffice.idProductionOffice}
                        >
                          {productionOffice.name}
                        </MenuItem>
                      ))}
                    </MyTextField>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={5}>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="body2"
                    className={classes.typography}
                    style={{ marginTop: 12 }}
                  >
                    <FormattedMessage id="SelectOfficer" />
                  </Typography>

                  <Box width={"50%"}>
                    <Autocomplete
                      id="officer"
                      value={officerSelected}
                      options={getUsers(values.idProductionOffice)}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      renderOption={(option) => <>{option.name}</>}
                      onChange={(
                        event: any,
                        value: IUserCorrespondence | null
                      ) => handleSelectOfficer(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          name="addressee"
                          className={classes.myTextFieldRoot}
                          helperText={
                            errorOfficer &&
                            `${intl.formatMessage({ id: "RequiredFile" })}`
                          }
                          error={errorOfficer}
                          FormHelperTextProps={{
                            className: classes.helperText,
                          }}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box display="flex" justifyContent="space-between">
                  <label className="form-text" style={{ marginTop: 12 }}>
                    <FormattedMessage id="From" />
                  </label>
                  <Box width={"75%"}>
                    <MyTextField
                      name="startDate"
                      variant="outlined"
                      type="date"
                      className={classes.myTextFieldRoot}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={3}>
                <Box display="flex" justifyContent="space-between">
                  <label className="form-text" style={{ marginTop: 12 }}>
                    <FormattedMessage id="To" />
                  </label>
                  <Box width={"75%"}>
                    <MyTextField
                      name="endDate"
                      variant="outlined"
                      type="date"
                      className={classes.myTextFieldRoot}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    variant="body2"
                    className={classes.typography}
                    style={{ marginTop: 12 }}
                  >
                    <FormattedMessage id="Channel" />
                  </Typography>

                  <Box width={"80%"}>
                    <MyTextField
                      name="channel"
                      variant="outlined"
                      select
                      className={classes.myTextFieldRoot}
                    >
                      <MenuItem value="Todos">Todos</MenuItem>
                      <MenuItem value={CommunicationChannels.SINGLE_WINDOW}>
                        <FormattedMessage id="SingleWindow" />
                      </MenuItem>
                      <MenuItem value={CommunicationChannels.EMAIL}>
                        <FormattedMessage id="EmailAddress" />
                      </MenuItem>
                      <MenuItem value={CommunicationChannels.SAC}>SAC</MenuItem>
                      <MenuItem
                        value={CommunicationChannels.VERBAL_COMMUNICATION}
                      >
                        <FormattedMessage id="VerbalCommunication" />
                      </MenuItem>
                      <MenuItem value={CommunicationChannels.FACEBOOK}>
                        <FormattedMessage id="Facebook" />
                      </MenuItem>
                      <MenuItem value={CommunicationChannels.WHATSAPP}>
                        <FormattedMessage id="Whatsapp" />
                      </MenuItem>
                      <MenuItem value={CommunicationChannels.CALL}>
                        <FormattedMessage id="ByTelephone" />
                      </MenuItem>
                      <MenuItem
                        value={CommunicationChannels.INSTITUTIONAL_PAGE}
                      >
                        <FormattedMessage id="InstitutionalPage" />
                      </MenuItem>
                      <MenuItem value={CommunicationChannels.FAX}>
                        <FormattedMessage id="Fax" />
                      </MenuItem>
                    </MyTextField>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={2}>
                {!isSubmitting ? (
                  <Button
                    className={clsx(classes.btnAction, classes.save)}
                    autoFocus
                    type="submit"
                    disabled={isSubmitting}
                    style={{ marginTop: "8px" }}
                    onClick={validateOfficer}
                  >
                    <FormattedMessage id="Search" />
                  </Button>
                ) : (
                  <Button
                    className={clsx(classes.btnAction, classes.save)}
                    autoFocus
                    type="button"
                    disabled={true}
                    style={{ marginTop: "8px" }}
                  >
                    <CircularProgress className={classes.btnLoading} />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

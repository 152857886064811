import { SettingsActionTypes, Types } from '../types/types';

export const toggleNavCollapsed = () => ({ type: Types.TOGGLE_NAV_COLLAPSED });

export const setInitialPath = (
    initialPath: string | undefined,
): SettingsActionTypes => ({
    type: Types.SET_INITIAL_PATH,
    initialPath,
});

import moment from "moment";
import React, { useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Box, Typography } from "@material-ui/core";

import { ICorrespondence } from "../../../interfaces/Correspondence";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
import { getInfoCorrespondenceReceived } from "../../../services/firebase/correspondence";
import { CustomizedDialogPDF } from "../../custom/CustomizedDialogPDF";
import { downloadPDF } from "../../../helpers/downloadPDF";
import { CorrespondenceReceivedForm } from "./CorrespondenceReceivedForm";
import { FollowupCorrespondenceReceivingPDF } from "./FollowupCorrespondenceReceivingPDF";

export const FollowupCorrespondenceReceived = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state: AppState) => state.auth);
  const [results, setResults] = useState<ICorrespondence[]>([]);
  const [openPdf, setOpenPdf] = useState(false);
  const componentRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [startDownload, setStartDownload] = useState(false);
  const [timeString, setTimeString] = useState("");
  const [inputType, setInputType] = useState("Todos");
  const [channel, setChannel] = useState("Todos");

  const onSearch = async (data: any) => {
    const start = moment(data.startDate).format("DD/MM/YYYY");
    const end = moment(data.endDate).format("DD/MM/YYYY");
    setTimeString(start + " - " + end);
    setInputType(data.inputType);
    setChannel(data.channel);

    setOpenPdf(true);
    try {
      setLoading(true);
      if (user && user.idEntity) {
        const startDate = data.startDate + "T00:00:00";
        const endDate = data.endDate + "T23:59:00";
        const response: any = await getInfoCorrespondenceReceived(
          user.idEntity,
          data.dependency,
          data.inputType,
          startDate,
          endDate,
          data.channel
        );

        if (response && response.length > 0) {
          setResults(response);
        } else {
          setResults([]);
        }

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      throw new Error("Error al consultar datos");
    }
  };

  const onDownload = async () => {
    setStartDownload(true);
    await downloadPDF(
      componentRef,
      `${intl.formatMessage({ id: "CorrespondenceReport" })}`
    );

    setStartDownload(false);
  };

  const onDeny = () => {
    setResults([]);
    setOpenPdf(false);
  };

  return (
    <Box p={2}>
      <Typography variant="body1" className={classes.title}>
        <FormattedMessage id="FollowupCorrespondenceReceived" />
      </Typography>

      <CorrespondenceReceivedForm onSearch={onSearch} />

      <CustomizedDialogPDF
        open={openPdf}
        onConfirm={onDownload}
        onDeny={onDeny}
        title={`${intl.formatMessage({
          id: "FollowupReportCorrespondenceReceived",
        })}`}
        titlePDF={""}
        content={
          <div ref={componentRef}>
            <FollowupCorrespondenceReceivingPDF
              loading={loading}
              time={timeString}
              data={results}
              inputType={inputType}
              channel={channel}
            />
          </div>
        }
        loadingDownload={startDownload}
        textButton="Download"
      />
    </Box>
  );
};

import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { Box, CircularProgress, Grid } from "@material-ui/core";
import { Entity } from "../../../interfaces/Users";
import { AppState } from "../../../redux/reducers/rootReducer";
import { getEntity } from "../../../services/firebase/entities";
import { useStyles } from "../../../shared/styles/useStyles";
import { CorrespondenceReportTable } from "./CorrespondenceReportTable";
import { CommunicationChannels } from "../../../enums/enums";

interface Props {
  loading: boolean;
  time: string;
  data: any;
  inputType: string;
  channel: string;
}

export const CorrespondenceReceivingPDF = ({
  loading,
  time,
  data,
  inputType,
  channel,
}: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const { user } = useSelector((state: AppState) => state.auth);
  const { productionOffices } = useSelector((state: AppState) => state.trd);
  const [entity, setEntity] = useState<Entity | null>(null);
  const [loadingAnswers, setLoadingAnswers] = useState(false);
  const [arrayInfo, setArrayInfo] = useState<any[]>([]);

  useEffect(() => {
    setLoadingAnswers(true);
    if (data.length > 0) {
      let dependencies: string[] = [];
      data.forEach((resp: any) => {
        if (!dependencies.includes(resp.receivingDependency)) {
          dependencies.push(resp.receivingDependency);
        }
      });

      const newResponse: any[] = dependencies.map((id) => {
        const dataFilter = data.filter(
          (resp: any) => resp.receivingDependency === id
        );
        return dataFilter;
      });

      setArrayInfo(newResponse);
    } else {
      setArrayInfo([]);
    }
    setLoadingAnswers(false);
  }, [data]);

  // useEffect(() => {
  //   setLoadingAnswers(true);
  //   const getAnswers = async () => {
  //     if (data.length > 0) {
  //       // let arrayAnswers: any[] = [];
  //       data.forEach(async (row: any) => {
  //         const resp =
  //           user &&
  //           user.idEntity &&
  //           (await getAnswersCorrespondence(user.idEntity, row.id));

  //         if (resp && resp.length > 0) {
  //           setArrayAnswers((prevArray) => [...prevArray, resp[0]]);
  //         }
  //       });
  //     } else {
  //       setArrayAnswers([]);
  //     }
  //     setLoadingAnswers(false);
  //   };
  //   getAnswers();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  useEffect(() => {
    if (user && user.idEntity) {
      getEntityDetails(user.idEntity);
      // setArrayAnswers([]);
    }
    return () => {
      // setArrayAnswers([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEntityDetails = async (nit: string) => {
    const resp = await getEntity(nit);
    if (resp) {
      setEntity(resp);
    }
    return null;
  };

  return (
    <Box m={4} mt={4}>
      {loading || !entity || loadingAnswers ? (
        <div className={classes.rootLoading}>
          <Grid container className={classes.paperLoading}>
            <Box mx="auto" my="30vh">
              <CircularProgress className={classes.colorLoading} />
            </Box>
          </Grid>
        </div>
      ) : (
        <>
          <Box display="flex" justifyContent="center" sx={{ fontSize: 14 }}>
            {entity?.businessName.toUpperCase()}
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            mb={2}
            sx={{ fontSize: 14 }}
          >
            NIT: {entity?.nit}
          </Box>

          <Box display="flex" justifyContent="center" sx={{ fontSize: 14 }}>
            {`${intl
              .formatMessage({ id: "CorrespondenceReceivedReport" })
              .toUpperCase()}`}
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            mb={2}
            sx={{ fontSize: 14 }}
          >
            {moment().format("DD/MM/YYYY hh:mm:ss")}
          </Box>

          {data.length === 0 ? (
            <Box m={3} display="flex" justifyContent="center">
              <p
                style={{
                  textAlign: "center",
                  fontSize: 26,
                  color: "red",
                  width: "80%",
                }}
              >
                <FormattedMessage id={"NoInformationCorrespondenceReport"} />
              </p>
            </Box>
          ) : (
            <>
              {arrayInfo.map((row: any, index: number) => (
                <Box
                  key={`${row[0].receivingDependency} - ${index}`}
                  mb={5}
                  sx={{ fontSize: 14 }}
                >
                  <Grid container style={{ marginBottom: "20px" }}>
                    <Grid item xs={7}>
                      <Box>
                        {`${intl
                          .formatMessage({ id: "Dependency" })
                          .toUpperCase()}`}
                        {": "}
                        {productionOffices?.find(
                          (office: any) =>
                            office.id === row[0]?.receivingDependency
                        )?.name || ""}
                      </Box>
                      <Box>
                        {`${intl
                          .formatMessage({ id: "InputType" })
                          .toUpperCase()}`}
                        {": "}
                        {inputType === "Todos" ? "TODAS" : inputType}
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Box>
                        {`${intl
                          .formatMessage({ id: "Period" })
                          .toUpperCase()}`}
                        : {time}
                      </Box>
                      <Box>
                        {`${intl
                          .formatMessage({ id: "Channel" })
                          .toUpperCase()}`}
                        {": "}
                        {channel === "Todos"
                          ? `TODOS`
                          : channel === CommunicationChannels.SINGLE_WINDOW
                          ? `${intl
                              .formatMessage({
                                id: "SingleWindow",
                              })
                              .toUpperCase()}`
                          : channel === CommunicationChannels.EMAIL
                          ? `${intl
                              .formatMessage({
                                id: "EmailAddress",
                              })
                              .toUpperCase()}`
                          : channel === CommunicationChannels.EMAIL
                          ? `${intl
                              .formatMessage({
                                id: "Facebook",
                              })
                              .toUpperCase()}`
                          : channel === CommunicationChannels.WHATSAPP
                          ? `${intl
                              .formatMessage({
                                id: "Whatsapp",
                              })
                              .toUpperCase()}`
                          : channel === CommunicationChannels.CALL
                          ? `${intl
                              .formatMessage({
                                id: "ByTelephone",
                              })
                              .toUpperCase()}`
                          : channel === CommunicationChannels.INSTITUTIONAL_PAGE
                          ? `${intl
                              .formatMessage({
                                id: "InstitutionalPage",
                              })
                              .toUpperCase()}`
                          : `${intl
                              .formatMessage({
                                id: "Fax",
                              })
                              .toUpperCase()}`}
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ fontSize: 14 }}>
                    <CorrespondenceReportTable data={row} />
                  </Box>
                </Box>
              ))}
            </>
          )}
        </>
      )}
    </Box>
  );
};

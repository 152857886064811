import { Grid, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { RecipientsTable } from "./RecipientsTable";

export const AnnotationDetails = () => {

  const { activeAnnotation } = useSelector(
    (state: AppState) => state.correspondence
  );
  return (
    <Grid container spacing={2}>
      {activeAnnotation && (
        <>
          <Grid item xs={6}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {" "}
              <FormattedMessage id="AnnotationSubject" />:{" "}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {" "}
              <FormattedMessage id="AttachedDocumentAnnotation" />:{" "}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              {activeAnnotation.subjectAnnotation}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              {activeAnnotation.attachedDocument ? (
                <a
                  href={activeAnnotation.attachedDocument.url}
                  target="__blank"
                >
                  {activeAnnotation.attachedDocument.fileName}
                </a>
              ) : (
                <FormattedMessage id="None" />
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {" "}
              <FormattedMessage id="Annotation" />:{" "}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              <FormattedMessage id="Annotation" />:{" "}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              {activeAnnotation.annotation}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              {" "}
              <FormattedMessage id="Recipients" />:{" "}
            </Typography>
          </Grid>
          <RecipientsTable />
        </>
      )}
    </Grid>
  );
};

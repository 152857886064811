import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

import { CustomizedSearch } from "../../components/custom/CustomizedSearch";
import CustomizedDialog from "../../components/custom/CustomizedDialog";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import {
  uiOpenModalAdd,
  uiCloseModalAdd,
  uiCloseSuccessAlert,
  uiCloseErrorAlert,
  uiCloseModalAssignModules,
  uiCloseModalSee,
  uiCloseModalEdit,
  uiCloseModalInfo,
} from "../../redux/actions/uiActions";
import { useStyles } from "../../shared/styles/useStyles";
import { AppState } from "../../redux/reducers/rootReducer";
import { TransfersTable } from "../../components/file-module/transfers/TransfersTable";
import { TransfersForm } from "../../components/file-module/transfers/TransfersForm";
import { MyAlert } from "../../components/custom/MyAlert";
import { AddFilesTransferForm } from "../../components/file-module/transfers/AddFilesTransferForm";
import { setActiveTransfer } from "../../redux/actions/documentManagmentActions";
import { TransferDetails } from "../../components/file-module/transfers/TransferDetails";
import { FC } from "react";
import { TransfersComments } from "../../components/file-module/transfers/TransfersComments";
interface props{
  fromRejected?:boolean,

}

export const TransferManagmentScreen:FC<props> = ({fromRejected=false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const {
    modalAddOpen,
    successMsg,
    successAlert,
    errorAlert,
    modalAssignModulesOpen,
    modalSeeOpen,
    modalEditOpen,
    modalInfoOpen,
    errorMsg
  } = useSelector((state: AppState) => state.ui);

  const { transfers:allTransfers, activeTransfer } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const transfers = !fromRejected?allTransfers.filter(trsn=>!trsn.isRejected):allTransfers.filter(trsn=>trsn.isRejected)
  const onDeny = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalInfo())
    dispatch(uiCloseModalAssignModules());
    dispatch(setActiveTransfer(null));
    dispatch(uiCloseModalEdit());
    dispatch(setActiveTransfer(null));
  };

  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box>
        <Box mb={3} fontSize={20}>
          <Grid container>
            {!fromRejected ?(
            <Grid item xs={6}>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                className={classes.btnAdd}
                type="button"
                onClick={() => dispatch(uiOpenModalAdd())}
                style={{ width: "23rem" }}
              >
                <AddOutlinedIcon />
                <FormattedMessage id="NewDocumentaryTransfer" />
              </Button>
            </Grid>

            ) :
            <Grid xs={6} />
            }
            <Grid item xs={1} />
            <Grid item xs={5}>
              <CustomizedSearch  data={transfers}/>
            </Grid>
          </Grid>
        </Box>

        <TransfersTable transfers={transfers} />

        <CustomizedDialog
          open={modalAddOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "DocumentaryTransfer" })}`}
          content={<TransfersForm />}
        />
        <CustomizedDialog
          open={modalEditOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "UpdateTransfer" })}`}
          content={<TransfersForm edit />}
          textButton="Accept"
        />
        <CustomizedDialog
          open={modalSeeOpen}
          cancelBtn={true}
          onDeny={() => {
            dispatch(uiCloseModalSee());
            dispatch(setActiveTransfer(null));
          }}
          title={`${intl.formatMessage({ id: "TransferDetails" })}`}
          content={<TransferDetails />}
          textButton="Settle"
        />
        {/* agregar expediente */}
        
          <CustomizedDialog
            open={modalAssignModulesOpen}
            cancelBtn={true}
            onDeny={onDeny}
            title={`${intl.formatMessage({ id: "AddFiles" })}`}
            content={
              <AddFilesTransferForm readonly={activeTransfer?.isComplete} />
            }
          />
          <CustomizedDialog
            open={modalInfoOpen}
            cancelBtn={true}
            onDeny={onDeny}
            title={`${intl.formatMessage({ id: "Comment" })}s`}
            content={
              <TransfersComments/>
            }
          />
        
        {successMsg && (
          <MyAlert
            open={successAlert}
            typeAlert="success"
            message={successMsg}
            time={2000}
            handleClose={() => dispatch(uiCloseSuccessAlert())}
          />
        )}

        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={errorMsg?errorMsg: "SavingDataError"}
          time={2000}
          handleClose={() => dispatch(uiCloseErrorAlert())}
        />
      </Box>
    </AppAnimate>
  );
};

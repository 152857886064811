import { useState } from "react";
import { Box, Grid, Divider, Paper, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import { MyCharts } from "../../components/file-module/home/MyCharts";
import { SearchDocumentaryUnits } from "../../components/file-module/home/SearchDocumentaryUnits";
import { LoanTable } from "../../components/file-module/home/LoanTable";
import { useStyles } from "../../shared/styles/useStyles";
import { MenuItem, TextField } from "@material-ui/core";
import { AppState } from "../../redux/reducers/rootReducer";
import { TypeArchive } from "../../enums/enums";
import { TransferType } from "../../enums/enums";
import { IFuid } from "../../redux/types/types";
import { CustomizedWindow } from "../../components/custom/CustomizedWindow";
import { FuidDetails } from "../../components/document-managment/correspondence/FuidDetails";
import { useDispatch, useSelector } from "react-redux";
import { uiCloseModalSee } from "../../redux/actions/uiActions";
import { setActiveFuidFileModule } from "../../redux/actions/fileModuleActions";
import * as Highcharts from "highcharts";

export const FilesScreen = () => {
  const { versions, productionOffices } = useSelector(
    (state: AppState) => state.trd
  );
  const [selected, setSelected] = useState({
    trdOrTvd: versions[0] ? versions[0].idVersion : "",
    module: "CA",
  });
  const classes = useStyles();
  const { expedients } = useSelector((state: AppState) => state.fileModule);
  const { expedients: expedientsDocumentManagement } = useSelector(
    (state: AppState) => state.documentManagment
  );

  const { activeExpedientFileModule } = useSelector(
    (state: AppState) => state.fileModule
  );
  const { modalSeeOpen } = useSelector((state: AppState) => state.ui);
  const intl = useIntl();
  const dispatch = useDispatch();

  const expedientsCentralFiles = expedients?.filter(
    (exp) =>
      exp?.transferType! === TransferType.PRIMARY_TRANSFER &&
      exp.deposit !== null
  );

  const expedientsHistoricalFiles = expedients?.filter(
    (exp) =>
      exp?.transferType! === TransferType.SECONDARY_TRANSFER &&
      exp.deposit !== null
  );
  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected({
      ...selected,
      [event.target.name]: event.target.value,
      module: "",
    });
  };

  const handleSelectVersion = async (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    setSelected({ ...selected, module: event.target.value });
  };

  const buildSeries = (): Highcharts.PointOptionsObject[] => {
    let series: Highcharts.PointOptionsObject[] = [];
    let filterResp: IFuid[] = [];
    switch (selected.module) {
      // CASE ARCHIVO CENTRAL
      case "CA":
        filterResp = expedientsCentralFiles.filter(
          (expedients) => expedients.trdOrTvd === selected.trdOrTvd
        );
        productionOffices.forEach((office) => {
          const response = filterResp.filter(
            (item) => item.productionOffice === office.idProductionOffice
          );
          if (response.length > 0) {
            const obj = { name: office.name, y: series.length };
            series.push(obj);
          }
        });

        break;
      // CASE ARCHIVO HISTORICO
      case "HA":
        filterResp = expedientsHistoricalFiles.filter(
          (expedients) => expedients.trdOrTvd === selected.trdOrTvd
        );
        productionOffices.forEach((office) => {
          const response = filterResp.filter(
            (item) => item.productionOffice === office.idProductionOffice
          );
          if (response.length > 0) {
            const obj = { name: office.name, y: response.length };
            series.push(obj);
          }
        });

        break;
      // CASE ARCHIVO DE GESTION
      case "MF":
        filterResp = expedientsDocumentManagement.filter(
          (expedients) => expedients.trdOrTvd === selected.trdOrTvd
        );
        productionOffices.forEach((office) => {
          const response = filterResp.filter(
            (item) => item.productionOffice === office.idProductionOffice
          );
          if (response.length > 0) {
            const obj = { name: office.name, y: response.length };
            series.push(obj);
          }
        });

        break;

      default:
        return [];
    }

    return series;
  };

  const onDeny = () => {
    dispatch(uiCloseModalSee());
    dispatch(setActiveFuidFileModule(null));
  };

  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box m={2} mt={-1} mb={2}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              backgroundColor: "#fff",
              alignItems: "center",
            }}
          >
            <Grid item xs={9}>
              {buildSeries().length > 0 ? (
                <MyCharts
                  title="Inventario Documental por Dependencia"
                  series={buildSeries()}
                  variant={{ type: "pie" }}
                />
              ) : (
                <Typography variant="h3" style={{ alignSelf: "center" }}>
                  <FormattedMessage id={"NoData"} />
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              <Grid item xs={12}>
                <FormattedMessage id="Trd" /> :
                <Box width={"90%"}>
                  <TextField
                    name="trdOrTvd"
                    variant="outlined"
                    className={classes.myTextFieldRoot}
                    value={selected.trdOrTvd}
                    onChange={handleSelect}
                    select
                  >
                    {versions.map((versions) => (
                      <MenuItem
                        key={versions.idVersion}
                        value={versions.idVersion}
                      >
                        {versions.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Grid>
              {/* MODULOS */}
              <Grid item xs={12} style={{ marginTop: "20px" }}>
                <FormattedMessage id={"Module"} /> :
                <Box width={"90%"}>
                  <TextField
                    name="module"
                    variant="outlined"
                    select
                    className={classes.myTextFieldRoot}
                    value={selected.module}
                    onChange={handleSelectVersion}
                  >
                    <MenuItem key="CA" value="CA">
                      <FormattedMessage id={TypeArchive.CENTRAL_ARCHIVE} />
                    </MenuItem>
                    <MenuItem key={"HA"} value={"HA"}>
                      <FormattedMessage id={TypeArchive.HISTORICAL_ARCHIVE} />
                    </MenuItem>
                    <MenuItem key={"MF"} value={"MF"}>
                      <FormattedMessage id={TypeArchive.MANAGEMENT_FILE} />
                    </MenuItem>
                  </TextField>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <SearchDocumentaryUnits />
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <Paper square className={classes.paper}>
              <h1
                className={classes.title}
                style={{
                  marginTop: "12px",
                  padding: "15px",
                }}
              >
                <FormattedMessage id={"RecentLoans"} />
              </h1>
              <Box ml={2} mr={2}>
                <LoanTable />
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <CustomizedWindow
          open={modalSeeOpen}
          content={<FuidDetails fromModule={TypeArchive.CENTRAL_ARCHIVE} />}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "File" })} ${
            activeExpedientFileModule?.fileName
          }`}
        />
      </Box>
    </AppAnimate>
  );
};

import { FC, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import clsx from "clsx";
import { FormattedMessage, useIntl } from "react-intl";
import { useStyles } from "../../../shared/styles/useStyles";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { VersionBody } from "../../admin/TRD/version/VersionBody";
import {
  setActiveDocumentalType,
  setActiveSerie,
  setActiveTrdVersion,
  setActiveSubserie,
  startDeleteDocumentalType,
  startDeleteProductionOffice,
  startDeleteSerie,
  startDeleteTrdVersion,
  startDeleteSubSerie,
} from "../../../redux/actions/trdActions";
import {
  setSuccessMsg,
  uiCloseModalDelete,
} from "../../../redux/actions/uiActions";
import { SeriesBody } from "../../admin/TRD/series/SeriesBody";
import { setActiveProductionOffice } from "../../../redux/actions/trdActions";
import { ProductionOfficeBody } from "../../admin/TRD/productionOffice/ProductionOfficeBody";
import { SubseriesBody } from "../../admin/TRD/subseries/SubseriesBody";
import { DocumentaryTypeBody } from "../../admin/TRD/documentaryType/DocumentaryTypeBody";
import { startDeleting } from "../../../redux/actions/organizationChartActions";
import { OrganizationChartBody } from "../../admin/oganizationChart/OrganizationChartBody";
import {
  startDeleteProcedure,
  setActiveProcedure,
  setActiveAnnotation,
  setActiveCorrespondence,
  startDeleteAnnotation,
  startDeleteCorrespondece,
} from "../../../redux/actions/correspondenceActions";
import { ProceduresBody } from "../../correspondence-module/parameterization/ProceduresBody";
import { AnnotationsBody } from "../../correspondence-module/annotations/AnnotationsBody";
import { CorrespondenceBody } from "../../correspondence-module/correspondence/CorrespondenceBody";
import { CorresAnnotationsBody } from "../../document-managment/tabs/AnnotationsBody";
import {
  startDeleteExpedientCorres,
  setActiveCorresAnnotation,
} from "../../../redux/actions/documentManagmentActions";
import {
  deleteCorresAnnotation,
  setActiveCorresExpedient,
} from "../../../redux/actions/documentManagmentActions";
import { FileBody } from "../../document-managment/tabs/FileBody";
import {
  setActiveFuidAnnotation,
  startDeleteFuidAnnotation,
} from "../../../redux/actions/fileModuleActions";
import { AnnotationsFuidBody } from "../../document-managment/tabs/AnnotationsFuidBody";
interface props {
  title: string;
  fromFileModule?: boolean;
}
export const ConfirmDelete: FC<props> = ({ title, fromFileModule = false }) => {
  const [isSubmitting, setisSubmitting] = useState(false);

  const {
    activeProductionOffice,
    activeVersion,
    activeSerie,
    activeDocumentalType,
    activeSubSerie,
  } = useSelector((state: AppState) => state.trd);
  const { activeAnnotation } = useSelector(
    (state: AppState) => state.correspondence
  );

  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.correspondence
  );

  const { activeOffice }: any = useSelector(
    (state: AppState) => state.organizationChart
  );

  const { activeParamProcedure } = useSelector(
    (state: AppState) => state.correspondence
  );

  const {
    activeCorrespondence: activeCorrespondenceDM,
    activeCorresAnnotation,
    activeExpedient,
  } = useSelector((state: AppState) => state.documentManagment);

  const { activeFuidAnnotation } = useSelector(
    (state: AppState) => state.fileModule
  );

  const { user } = useSelector((state: AppState) => state.auth);

  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const onClose = () => {
    dispatch(setActiveTrdVersion(null));
    dispatch(setActiveProductionOffice(null));
    dispatch(setActiveSerie(null));
    dispatch(setActiveDocumentalType(null));
    dispatch(setActiveProcedure(null));
    dispatch(setActiveAnnotation(null));
    dispatch(setActiveCorrespondence(null));
    dispatch(setActiveCorresAnnotation(null));
    dispatch(setActiveCorresExpedient(null));
    dispatch(setActiveFuidAnnotation(null));
    dispatch(uiCloseModalDelete());
  };
  const handleDelete = async () => {
    setisSubmitting(true);
    if (activeVersion) {
      await dispatch(startDeleteTrdVersion(activeVersion.idVersion));
      dispatch(
        setSuccessMsg(`${intl.formatMessage({ id: "DeletedTrdVersion" })}`)
      );
    }
    if (activeProductionOffice) {
      await dispatch(
        startDeleteProductionOffice(activeProductionOffice.idProductionOffice)
      );
    }
    if (activeSerie) {
      await dispatch(startDeleteSerie(activeSerie.idSerie));
    }
    if (activeSubSerie) {
      await dispatch(startDeleteSubSerie(activeSubSerie.idSubserie));
    }
    if (activeOffice) {
      await dispatch(startDeleting(activeOffice));
    }
    if (activeDocumentalType) {
      await dispatch(
        startDeleteDocumentalType(activeDocumentalType.idDocumentalType)
      );
    }
    if (activeParamProcedure) {
      await dispatch(startDeleteProcedure(activeParamProcedure.id));
    }
    if (activeAnnotation) {
      await dispatch(startDeleteAnnotation());
    }
    if (activeCorrespondence) {
      await dispatch(startDeleteCorrespondece());
    }
    if (activeCorresAnnotation) {
      let correspondenceId = "";
      let idEntity = "";
      if (activeCorrespondenceDM?.id && user?.idEntity) {
        correspondenceId = activeCorrespondenceDM?.id;
        idEntity = user?.idEntity;
      }
      await dispatch(
        deleteCorresAnnotation(
          idEntity,
          correspondenceId,
          activeCorresAnnotation.id
        )
      );
    }
    if (activeExpedient) {
      await dispatch(startDeleteExpedientCorres(activeExpedient.id));
    }
    if (activeFuidAnnotation) {
      await dispatch(
        startDeleteFuidAnnotation(activeFuidAnnotation, fromFileModule)
      );
    }
    setisSubmitting(false);
    dispatch(uiCloseModalDelete());
    dispatch(setActiveTrdVersion(null));
    dispatch(setActiveProductionOffice(null));
    dispatch(setActiveSerie(null));
    dispatch(setActiveSubserie(null));
    dispatch(setActiveProcedure(null));
    dispatch(setActiveAnnotation(null));
    dispatch(setActiveCorresAnnotation(null));
    dispatch(setActiveCorresExpedient(null));
    dispatch(uiCloseModalDelete());
  };

  return (
    <Box m={1} display={"flex"} flexDirection="column">
      <Box mb={1}>
        <label className="form-text">{title}</label>
      </Box>
      <Table
        className={classes.table}
        size="small"
        style={{ tableLayout: "auto" }}
        aria-label="a dense table"
      >
        <TableHead className={classes.titleTable}>
          {activeVersion && (
            <TableRow>
              <TableCell
                width={"15%"}
                align="center"
                className={classes.titleTable}
              >
                <FormattedMessage id="Code" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="Name" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="Date" />{" "}
              </TableCell>
            </TableRow>
          )}

          {activeProductionOffice && (
            <TableRow>
              <TableCell
                width={"15%"}
                align="center"
                className={classes.titleTable}
              >
                <FormattedMessage id="Code" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="ProductionOffice" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="Abbreviation" />{" "}
              </TableCell>
            </TableRow>
          )}

          {activeOffice && (
            <TableRow>
              <TableCell
                width={"15%"}
                align="center"
                className={classes.titleTable}
              >
                <FormattedMessage id="Code" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="ProductionOffice" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="Office" />{" "}
              </TableCell>
            </TableRow>
          )}

          {activeSerie && (
            <TableRow>
              <TableCell
                width={"15%"}
                align="center"
                className={classes.titleTable}
              >
                <FormattedMessage id="CodeOP" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="ProductionOffice" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="SeriesCode" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="SeriesName" />{" "}
              </TableCell>
            </TableRow>
          )}

          {activeSubSerie && (
            <TableRow>
              <TableCell
                width={"15%"}
                align="center"
                className={classes.titleTable}
              >
                <FormattedMessage id="CodeOP" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="ProductionOffice" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="SeriesCode" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="SeriesName" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="SubseriesCode" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="SubseriesName" />{" "}
              </TableCell>
            </TableRow>
          )}

          {activeDocumentalType && (
            <TableRow>
              <TableCell
                width={"10%"}
                align="center"
                className={classes.titleTable}
              >
                <FormattedMessage id="CodeOP" />{" "}
              </TableCell>
              <TableCell
                width={"20%"}
                align="center"
                className={classes.titleTable}
              >
                <FormattedMessage id="SeriesCode" />{" "}
              </TableCell>
              <TableCell
                width={"10%"}
                align="center"
                className={classes.titleTable}
              >
                <FormattedMessage id="SubseriesCode" />{" "}
              </TableCell>
              <TableCell
                width={"20%"}
                align="center"
                className={classes.titleTable}
              >
                <FormattedMessage id="DocumentalTypeCode" />{" "}
              </TableCell>
              <TableCell
                width={"10%"}
                align="center"
                className={classes.titleTable}
              >
                <FormattedMessage id="DocumentalTypeName" />{" "}
              </TableCell>
            </TableRow>
          )}

          {activeAnnotation && (
            <TableRow>
              <TableCell
                width={"15%"}
                align="center"
                className={classes.titleTable}
              >
                ID
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="SubjectAnnotation" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="Time" />{" "}
              </TableCell>
            </TableRow>
          )}

          {activeCorrespondence && (
            <TableRow>
              <TableCell
                width={"15%"}
                align="center"
                className={classes.titleTable}
              >
                ID
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="InputType" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="CommunicationType" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="Subject" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="Date" />{" "}
              </TableCell>
            </TableRow>
          )}

          {activeParamProcedure && (
            <TableRow>
              <TableCell
                width={"15%"}
                align="center"
                className={classes.titleTable}
              >
                ID
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="NameProcedure" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="Time" />{" "}
              </TableCell>
            </TableRow>
          )}
          {(activeCorrespondenceDM && !activeExpedient) ||
            (activeFuidAnnotation && (
              <TableRow>
                <TableCell
                  width={"15%"}
                  align="center"
                  className={classes.titleTable}
                >
                  <FormattedMessage id="DocumentName" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Annotation" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="User" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Date" />{" "}
                </TableCell>
              </TableRow>
            ))}

          {activeExpedient && (
            <TableRow>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="FileName" />{" "}
              </TableCell>
              <TableCell align="center" className={classes.titleTable}>
                <FormattedMessage id="Description" />{" "}
              </TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody className={classes.typography}>
          {activeVersion && <VersionBody version={activeVersion} readonly />}
          {activeProductionOffice && (
            <ProductionOfficeBody office={activeProductionOffice} readonly />
          )}
          {activeOffice && (
            <OrganizationChartBody office={activeOffice} readonly />
          )}
          {activeSerie && <SeriesBody serie={activeSerie} readonly />}
          {activeSubSerie && (
            <SubseriesBody subserie={activeSubSerie} readonly />
          )}
          {activeDocumentalType && (
            <DocumentaryTypeBody
              documentalType={activeDocumentalType}
              readonly
            />
          )}
          {activeParamProcedure && (
            <ProceduresBody procedure={activeParamProcedure} readonly />
          )}
          {activeAnnotation && (
            <AnnotationsBody annotation={activeAnnotation} readOnly />
          )}
          {activeCorrespondence && (
            <CorrespondenceBody communication={activeCorrespondence} readOnly />
          )}
          {activeCorresAnnotation && (
            <CorresAnnotationsBody settle={activeCorresAnnotation} readOnly />
          )}
          {activeFuidAnnotation && (
            <AnnotationsFuidBody annotation={activeFuidAnnotation} readOnly />
          )}
          {activeExpedient && <FileBody settle={activeExpedient} readOnly />}
        </TableBody>
      </Table>
      <Box mt={2} display="flex" justifyContent="space-between">
        {!isSubmitting ? (
          <Button
            className={clsx(classes.btn, classes.save)}
            onClick={handleDelete}
            disabled={isSubmitting}
          >
            <FormattedMessage id="Delete" />
          </Button>
        ) : (
          <Button
            className={clsx(classes.btn, classes.save)}
            autoFocus
            type="button"
            disabled={true}
          >
            <CircularProgress className={classes.btnLoading} />
          </Button>
        )}

        <Button className={clsx(classes.btn, classes.cancel)} onClick={onClose}>
          <FormattedMessage id="Cancel" />
        </Button>
      </Box>
    </Box>
  );
};

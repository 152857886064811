import clsx from "clsx";
import { Formik, Form } from "formik";
import { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  MenuItem,
} from "@material-ui/core";

import { MyAlert } from "../../custom/MyAlert";
import { MyTextField } from "../../custom/MyTextField";
import { getCities } from "../../../helpers/getInfoCountries";
import {
  getCountries,
  getTokenApi,
  getDepartments,
} from "../../../helpers/getInfoCountries";
import { ICityData, ICountryData, IStateData } from "../../../interfaces/Apis";
import { IExternalSender } from "../../../redux/types/types";
import { useStyles } from "../../../shared/styles/useStyles";
import {
  uiCloseErrorAlert,
  uiCloseModalAnswer,
} from "../../../redux/actions/uiActions";
import { startNewExternalSender } from "../../../redux/actions/correspondenceActions";

import { AppState } from "../../../redux/reducers/rootReducer";

interface ChildProps {
  updateName?: Function;
}

export const SenderForm = (props: ChildProps) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [tokenApi, setTokenApi] = useState<string | null>(null);
  const [listCountries, setListCountries] = useState<ICountryData[]>([]);
  const [countrySelected, setCountrySelected] = useState("");
  const [listDepartments, setListDepartments] = useState<IStateData[]>([]);
  const [departmentSelected, setDepartmentSelected] = useState("");
  const [listCities, setListCities] = useState<ICityData[]>([]);
  const [citySelected, setCitySelected] = useState("");

  const { errorMsg, errorAlert } = useSelector((state: AppState) => state.ui);
  const { activeExternalSender: active } = useSelector(
    (state: AppState) => state.users
  );

  const validationSchema = yup.object({
    // id: yup
    //   .string()
    //   .required(`${intl.formatMessage({ id: "RequiredFile" })}`)
    //   .min(5, `${intl.formatMessage({ id: "MinimumDocument" })}`),
    // address: yup
    //   .string()
    //   .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    name: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    // email: yup
    //   .string()
    //   .email(`${intl.formatMessage({ id: "InvalidEmail" })}`)
    //   .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    // phone: yup
    //   .number()
    //   .typeError(`${intl.formatMessage({ id: "NumericValue" })}`),
  });

  let initialValues: IExternalSender = {
    address: active?.address ? active.address : "",
    city: active?.city ? active.city : "",
    country: active?.country ? active.country : "",
    department: active?.department ? active.department : "",
    email: active?.email ? active.email : "",
    id: active?.id ? active.id : "",
    idDoc: active?.idDoc && active.idDoc,
    name: active?.name ? active.name : "",
    phone: active?.phone ? active.phone : "",
  };

  const onClose = () => {
    dispatch(uiCloseModalAnswer());
  };

  useEffect(() => {
    const getCountriesData = async () => {
      const token = await getTokenApi();

      if (token) {
        setTokenApi(token);
        const countries = await getCountries(token);
        if (countries) {
          setListCountries(countries);
          setCountrySelected(active?.country ? active.country : "");
        }
      }
    };
    getCountriesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // GET Departments
  useEffect(() => {
    const getDepartmentsData = async () => {
      if (countrySelected.length > 0 && tokenApi) {
        const departments = await getDepartments(countrySelected, tokenApi);
        if (departments) {
          setListDepartments(departments);
          setDepartmentSelected(active?.department ? active.department : "");
        }
      }
    };
    getDepartmentsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countrySelected]);

  // Get Cities
  useEffect(() => {
    const getCitiesData = async () => {
      if (departmentSelected.length > 0 && tokenApi) {
        const cities = await getCities(departmentSelected, tokenApi);
        if (cities) {
          setListCities(cities);
          setCitySelected(active?.city ? active.city : "");
        }
      }
    };
    getCitiesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentSelected]);

  const handleSelectCountry = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDepartmentSelected("");
    setCitySelected("");
    setCountrySelected(event.target.value);
  };

  const handleSelectDepartment = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCitySelected("");
    setDepartmentSelected(event.target.value);
  };

  return (
    <Box m={1}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          data.country = countrySelected;
          data.department = departmentSelected;
          data.city = citySelected;

          props.updateName?.(data.id, data.idDoc, data.name);

          await dispatch(startNewExternalSender(data));
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="PersonName" /> /{" "}
                  <FormattedMessage id="Entity" /> *
                </label>
                <MyTextField
                  name="name"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="Identification" />
                </label>
                <MyTextField
                  name="id"
                  type="number"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  disabled={!!active}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="Phone" />
                </label>
                <MyTextField
                  name="phone"
                  type="number"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Email" />
                </label>
                <MyTextField
                  name="email"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Direction" />
                </label>
                <MyTextField
                  name="address"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Country" />
                </label>
                <TextField
                  name="country"
                  select
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  size="small"
                  onChange={handleSelectCountry}
                  value={countrySelected || ""}
                >
                  <MenuItem key="" value=""></MenuItem>
                  {listCountries.map((country) => (
                    <MenuItem
                      key={`${country.country_phone_code}-${country.country_short_name}`}
                      value={country.country_name}
                    >
                      {country.country_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Department" />
                </label>
                <TextField
                  name="department"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  select
                  size="small"
                  value={departmentSelected}
                  onChange={handleSelectDepartment}
                >
                  <MenuItem key="" value=""></MenuItem>

                  {listDepartments.map((depart, index) => (
                    <MenuItem key={index} value={depart.state_name}>
                      {depart.state_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="City" />
                </label>
                <TextField
                  name="city"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  select
                  size="small"
                  value={citySelected}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCitySelected(e.target.value)
                  }
                >
                  <MenuItem key="" value=""></MenuItem>

                  {listCities.map((city, index) => (
                    <MenuItem key={index} value={city.city_name}>
                      {city.city_name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Box mt={2} display="flex" flexDirection="row-reverse">
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="Save" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>

      {errorMsg && (
        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={errorMsg}
          time={2000}
          handleClose={() => dispatch(uiCloseErrorAlert())}
        />
      )}
    </Box>
  );
};

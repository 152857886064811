import clsx from "clsx";
import { Form, Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Autocomplete } from "@material-ui/lab";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
} from "@material-ui/core";

import { MyTextField } from "../../custom/MyTextField";
import { useStyles } from "../../../shared/styles/useStyles";
import {
  uiCloseModalAdd,
  uiCloseModalEdit,
} from "../../../redux/actions/uiActions";
import { TransferType } from "../../../enums/enums";
import { AppState } from "../../../redux/reducers/rootReducer";
import firebase from "firebase";
import { IUserByEntity } from "../../../interfaces/Correspondence";
import { FC, useEffect, useState } from "react";
// import { IExternalSender } from "../../../redux/types/types";
import {
  setActiveTransfer,
  startUploadTransfer,
} from "../../../redux/actions/documentManagmentActions";
import { User } from "../../../interfaces/Users";
import moment from "moment";

interface Props {
  edit?: boolean,
  fromFilesModule?:boolean
}

export const TransfersForm:FC<Props> = ({ edit = false,fromFilesModule=false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  // USUARIOS PARA CARGAR EN EL SELECT DE SENDER TRANSFER
  const { users } = useSelector((state: AppState) => state.users);

  const { productionOffices } = useSelector((state: AppState) => state.trd);

  // ESTADO PARA ALMACENAR LOS DATOS DEL SENDER TRANSFER (ID, NAME)
  const [senderSelected, setSenderSelected] = useState<IUserByEntity | null>(
    null
  );

  // TRANSFER ACTIVE
  const { activeTransfer } = useSelector(
    (state: AppState) => state.documentManagment
  );

  // MANEJO ERRORES SENDER
  const [errorSender, setErrorSender] = useState(false);

  // FUNCTION ONCHANGE PARA OBTENER LOS DATOS DE USUARIO DEL SELECT EXTERNAL SENDER
  const handleSelectSender = (value: any) => {
    !value ? setErrorSender(true) : setErrorSender(false);
    value
      ? setSenderSelected({
          id: value.id.toString(),
          username: value.username,
        })
      : setSenderSelected(null);
  };

  const initialValues: any = {
    idTransfer: activeTransfer ? activeTransfer.idTransfer : "",
    transferType: activeTransfer
      ? activeTransfer.transferType
      : fromFilesModule? TransferType.SECONDARY_TRANSFER : TransferType.PRIMARY_TRANSFER,
    sendingEntity: activeTransfer ? activeTransfer.sendingEntity : "",
    producingEntity: activeTransfer ? activeTransfer.producingEntity : "",
    adminUnit: activeTransfer ? activeTransfer.adminUnit : "",
    productionOffice: activeTransfer ? activeTransfer.productionOffice : "",
    object: activeTransfer ? activeTransfer.object : "",
    transferDate: activeTransfer
      ? moment(activeTransfer.transferDate.toDate()).format("YYYY-MM-DD")
      : "",
    transferNumber: activeTransfer ? activeTransfer.transferNumber : "",
    senderTransferUser: activeTransfer && activeTransfer.senderTransferUser
      ? {
          id: activeTransfer.senderTransferUser.id,
          name: activeTransfer.senderTransferUser.username,
        }
      : { id: "", name: "" },
    expedients: activeTransfer ? activeTransfer.expedients : null,
    createDate: activeTransfer
      ? moment(activeTransfer.createDate.toDate()).format("YYYY-MM-DD")
      : "",
    submitDate:
      activeTransfer && activeTransfer.submitDate
        ? moment(activeTransfer.submitDate.toDate()).format("YYYY-MM-DD")
        : null,
    aprovalDate:
      activeTransfer && activeTransfer.aprovalDate
        ? moment(activeTransfer.aprovalDate.toDate()).format("YYYY-MM-DD")
        : null,
  };

  const validationSchema = yup.object({
    transferType: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    sendingEntity: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    producingEntity: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    adminUnit: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    productionOffice: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    object: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    transferDate: yup
      .date()
      .max(new Date(), `${intl.formatMessage({ id: "GreaterCurrentDate" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    transferNumber: yup
      .number()
      .min(1, `${intl.formatMessage({ id: "PositiveNumber" })}`)
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    senderTransferUser: yup.object(),
  });

  const onClose = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    dispatch(setActiveTransfer(null));
  };

  useEffect(() => {
    if (activeTransfer && activeTransfer.senderTransferUser) {
      setSenderSelected({
        id: activeTransfer.senderTransferUser.id.toString(),
        username: activeTransfer.senderTransferUser.username as string,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box m={2} mt={-1} mb={2}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (!errorSender) {
            // CREATE ID TRANSFER
            const idTransfer = edit
              ? values.idTransfer
              : firebase.firestore.Timestamp.now()
                  .toDate()
                  .getTime()
                  .toString();
            values.idTransfer = idTransfer;
            // ASIGNAR VALOR TIMESTAP DE FIREBASE PARA ENVIO A DB
            values.createDate = firebase.firestore.Timestamp.now();
            const transferDate = new Date(values.transferDate);
            values.transferDate =
              firebase.firestore.Timestamp.fromDate(transferDate);
            // OBJETO CON NOMBRE Y ID DE USUARIO DE EL SENDER TRANSFER PARA ENVIO A DB
            values.senderTransferUser = senderSelected;
            setSubmitting(true);
            // DISPATCH PARA EJECUTAR LOS SERVICIOS (AGREGAR DATA EN DB Y REDUX)
            await dispatch(startUploadTransfer(values, edit,fromFilesModule));
            setSubmitting(false);
            dispatch(uiCloseModalAdd());
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="TransferType" />:
                </label>
                <MyTextField
                  name="transferType"
                  variant="outlined"
                  select
                  disabled={fromFilesModule}
                  className={classes.myTextFieldRoot}
                >
                  <MenuItem value={TransferType.PRIMARY_TRANSFER}>
                    <FormattedMessage id={TransferType.PRIMARY_TRANSFER} />
                  </MenuItem>
                  <MenuItem value={TransferType.SECONDARY_TRANSFER}>
                    <FormattedMessage id={TransferType.SECONDARY_TRANSFER} />
                  </MenuItem>
                </MyTextField>
              </Grid>

              <Grid item xs={6}>
                <label className={clsx("form-text", classes.capitalize)}>
                  <FormattedMessage id="SenderEntity" />:
                </label>
                <MyTextField
                  name="sendingEntity"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                >
                  {productionOffices.map((productionOffice) => (
                    <MenuItem
                      key={productionOffice.idProductionOffice}
                      value={productionOffice.idProductionOffice}
                    >
                      {productionOffice.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={6}>
                <label className={clsx("form-text", classes.capitalize)}>
                  <FormattedMessage id="ProducingEntity" />:
                </label>
                <MyTextField
                  name="producingEntity"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                >
                  {productionOffices.map((productionOffice) => (
                    <MenuItem
                      key={productionOffice.idProductionOffice}
                      value={productionOffice.idProductionOffice}
                    >
                      {productionOffice.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={6}>
                <label className={clsx("form-text", classes.capitalize)}>
                  <FormattedMessage id="AdministrativeUnit" />:
                </label>
                <MyTextField
                  name="adminUnit"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                >
                  {productionOffices.map((productionOffice) => (
                    <MenuItem
                      key={productionOffice.idProductionOffice}
                      value={productionOffice.idProductionOffice}
                    >
                      {productionOffice.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={6}>
                <label className={clsx("form-text", classes.capitalize)}>
                  <FormattedMessage id="ProductionOffice" />:
                </label>
                <MyTextField
                  name="productionOffice"
                  variant="outlined"
                  select
                  className={classes.myTextFieldRoot}
                >
                  {productionOffices.map((productionOffice) => (
                    <MenuItem
                      key={productionOffice.idProductionOffice}
                      value={productionOffice.idProductionOffice}
                    >
                      {productionOffice.name}
                    </MenuItem>
                  ))}
                </MyTextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Object" />:
                </label>
                <MyTextField
                  name="object"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  multiline
                  rows={4}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="TransferDate" />:
                </label>
                <MyTextField
                  name="transferDate"
                  variant="outlined"
                  type="date"
                  className={classes.myTextFieldRoot}
                />
              </Grid>

              <Grid item xs={6}>
                <label className="form-text">
                  <FormattedMessage id="TransferNumber" />:
                </label>
                <MyTextField
                  name="transferNumber"
                  variant="outlined"
                  type="number"
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="TransferSender" />:
                </label>
                <Autocomplete
                  id="senderTransferUser"
                  value={senderSelected}
                  options={users}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.username}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderOption={(option) => <>{option.username}</>}
                  onChange={(event: any, value: User | null) =>
                    handleSelectSender(value)
                  }
                  renderInput={(params) => (
                    <MyTextField
                      {...params}
                      variant="outlined"
                      size="small"
                      name="addressee"
                      className={classes.myTextFieldRoot}
                      helperText={
                        errorSender &&
                        `${intl.formatMessage({ id: "RequiredFile" })}`
                      }
                      error={errorSender}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                    />
                  )}
                />
              </Grid>
              <Box mt={2} display="flex" flexDirection="row-reverse" width={1}>
                {!isSubmitting ? (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id="Save" />
                  </Button>
                ) : (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="button"
                    disabled={true}
                  >
                    <CircularProgress className={classes.btnLoading} />
                  </Button>
                )}
                <Button
                  className={clsx(classes.btn, classes.cancel)}
                  onClick={onClose}
                >
                  <FormattedMessage id="Cancel" />
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

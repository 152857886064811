import React, { FC, Fragment } from "react";
import { Grid, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import {
  ICorrespondence,
  IProponent,
} from "../../../interfaces/Correspondence";
import { AppState } from "../../../redux/reducers/rootReducer";
//@ts-ignore
import Barcode from "react-barcode";
import moment from "moment";
interface props {
  tenderCorres: ICorrespondence | null;
  proponent: IProponent;
}
export const CorrespondenceTenderLabel: FC<props> = ({
  tenderCorres,
  proponent,
}) => {
  const { user } = useSelector((state: AppState) => state.auth);

  if (!tenderCorres) {
    return <></>;
  }
  return (
    <Grid container>
      {Array.from(Array(2).keys()).map((item) => (
        <Fragment key={item}>
          {user && user.businessName && user.idEntity && (
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Typography
                    variant="h6"
                    style={{ fontSize: 6, fontWeight: "bold" }}
                  >
                    {user.businessName.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: 6,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    <FormattedMessage id={"TenderProcessNo"} />
                    {tenderCorres.communicationType?.option}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    style={{
                      fontSize: 8,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    <FormattedMessage id={"Settled"} /> No.{tenderCorres.id}
                  </Typography>
                </Grid>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    marginBottom: 5,
                  }}
                >
                  <Barcode
                    value={tenderCorres.id}
                    fontSize={6}
                    width={0.7}
                    height={18}
                    margin={0}
                    marginRight={4}
                    displayValue={false}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h6" style={{ fontSize: 6 }}>
                    {moment(tenderCorres.createAt.toDate()).format(
                      "YYYY/MM/DD"
                    )}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="h6" style={{ fontSize: 6 }}>
                    {moment(tenderCorres.createAt.toDate()).format("h:mm:ss a")}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          )}
        </Fragment>
      ))}
    </Grid>
  );
};

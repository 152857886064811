import React from "react";
import { useIntl } from "react-intl";

import { AppBar, Tab, Tabs } from "@material-ui/core";
import { a11yProps, TabPanel, useStyles } from "../../custom/Tabs";
import { DepositParam } from "./DepositParam";
import Signature from "./Signature";

export const ParameterizationMangTabs = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          centered
          TabIndicatorProps={{
            style: {
              backgroundColor: "#0A8FDC",
            },
          }}
        >
          <Tab
            className={classes.tab}
            label={`${intl.formatMessage({ id: "Deposit" }).toUpperCase()}`}
            {...a11yProps(0)}
          />
          <Tab
            className={classes.tab}
            label={`${intl.formatMessage({ id: "Signature" }).toUpperCase()}`}
            {...a11yProps(1)}
          />

          {/* TAB OTROS */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <DepositParam />
      </TabPanel>
      {/* TAB FIRMA */}
      <TabPanel value={value} index={1}>
        <Signature />
      </TabPanel>
    </div>
  );
};

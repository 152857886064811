import { FC } from "react";
import { useDispatch } from "react-redux";

import { TableCell, TableRow } from "@material-ui/core";
import { CustomizedIcons } from "../../../components/custom/CustomizedIcons";
import { useStyles } from "../../../shared/styles/useStyles";

import {
  uiOpenModalDelete,
} from "../../../redux/actions/uiActions";
import moment from "moment";
import { IUserCopy } from "../../../interfaces/Correspondence";
import { firebase } from "../../../config/firebase/firebase-config";
import { startDeleteCorrespondenceCopy } from "../../../redux/actions/documentManagmentActions";


interface props {
  officer: IUserCopy,
  readonly?: boolean;
}

export const SendCopyBody: FC<props> = ({ officer, readonly = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onDeleteOfficer = async() => {
    //dispatch(setActiveProcedure(officer));
   await dispatch( startDeleteCorrespondenceCopy(officer.id) )
    dispatch(uiOpenModalDelete());
  };

  return (
    <TableRow key={officer.id}>
      <TableCell component="th" scope="row" align="center">
        {officer.userName}
      </TableCell>
      <TableCell align="center" className={classes.capitalize}>
     { moment((officer.submitDate as firebase.firestore.Timestamp ).toDate()).format("YYYY-MM-DD hh:mm a")}
      </TableCell>
      {!readonly && (
        <TableCell align="center">
          <CustomizedIcons
            deleteIcon
            onDelete={onDeleteOfficer}
          />
        </TableCell>
      )}
    </TableRow>
  );
};


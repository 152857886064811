import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  createTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  CircularProgress,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { CustomizedTableFooter } from "../../custom/CustomizedTableFooter";
import { counter } from "../../../helpers/count";
import { loadFuidAnnotations } from "../../../redux/actions/fileModuleActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
import { Colors } from "../../../shared/constants/themes/Colors";
import { loadFuidAnnotationsDB } from "../../../services/firebase/fileModule";
import { AnnotationsFuidBody } from "./AnnotationsFuidBody";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});

interface Props {
  fromFileModule: boolean;
}

export const AnnotationsFuidTab = ({ fromFileModule }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data, value } = useSelector((state: AppState) => state.search);
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state: AppState) => state.auth);
  const { activeExpedient: fuidDocumentManagement } = useSelector(
    (state: AppState) => state.fuid
  );
  const { activeExpedientFileModule: fuidFileModule } = useSelector(
    (state: AppState) => state.fileModule
  );
  const { fuidAnnotations } = useSelector(
    (state: AppState) => state.fileModule
  );

  const expedient = fromFileModule ? fuidFileModule : fuidDocumentManagement;

  useEffect(() => {
    const fetchFuidAnnotations = async () => {
      if (user && user.idEntity && expedient) {
        setLoading(true);

        const resp = await loadFuidAnnotationsDB(
          user.idEntity,
          expedient?.id,
          fromFileModule
        );

        dispatch(loadFuidAnnotations(resp));
        setLoading(false);
      }
    };
    fetchFuidAnnotations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );
  const count = counter(value, fuidAnnotations, data);
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, fuidAnnotations.length - page * rowsPerPage);

  if (loading) {
    return (
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper} style={{ paddingTop: "10px" }}>
        {fuidAnnotations.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            <FormattedMessage id={"NoCreatedCommunication"} />
          </Box>
        ) : (
          <Table
            className={classes.table}
            size="small"
            style={{ tableLayout: "auto" }}
            aria-label="a dense table"
          >
            <TableHead className={classes.titleTable}>
              <TableRow>
                <TableCell
                  width={"15%"}
                  align="center"
                  className={classes.titleTable}
                >
                  <FormattedMessage id="DocumentName" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Annotation" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="User" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Date" />{" "}
                </TableCell>
                <TableCell
                  width={"20%"}
                  className={classes.titleTable}
                  align="center"
                >
                  <FormattedMessage id="Actions" />{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!value.trim()
                ? (rowsPerPage > 0
                    ? fuidAnnotations.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : fuidAnnotations
                  ).map((annotation) => (
                    <AnnotationsFuidBody
                      key={annotation.id}
                      annotation={annotation}
                    />
                  ))
                : data &&
                  (rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((annotation) => (
                    <AnnotationsFuidBody
                      key={annotation.id}
                      annotation={annotation}
                    />
                  ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 13 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {/* --------- Footer para paginación -------- */}
            <CustomizedTableFooter count={count} />
          </Table>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

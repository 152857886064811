import { FC } from "react";
import { TableCell, TableRow } from "@material-ui/core";
import { ITrdDocumentalType } from "../../../../redux/types/types";
import { CustomizedIcons } from "../../../custom/CustomizedIcons";
import { useDispatch } from "react-redux";
import {
  uiOpenModalDelete,
  uiOpenModalEdit,
} from "../../../../redux/actions/uiActions";
import { setActiveDocumentalType } from "../../../../redux/actions/trdActions";

interface props {
  documentalType: ITrdDocumentalType;
  readonly?: boolean;
}
export const DocumentaryTypeBody: FC<props> = ({
  documentalType,
  readonly = false,
}) => {
  const dispatch = useDispatch();
  const onEditDocumentalType = () => {
    dispatch(uiOpenModalEdit());
    dispatch(setActiveDocumentalType(documentalType));
  };
  const onDeleteDocumentalType = () => {
    dispatch(uiOpenModalDelete());
    dispatch(setActiveDocumentalType(documentalType));
  };
  return (
    <TableRow key={documentalType.idDocumentalType}>
      <TableCell component="th" scope="row" align="center">
        {documentalType.codeOp}
      </TableCell>
      <TableCell align="center">{documentalType.serieCode}</TableCell>
      <TableCell align="center">{documentalType.subSerieCode}</TableCell>
      <TableCell align="center">{documentalType.code}</TableCell>
      <TableCell align="center">{documentalType.name}</TableCell>
      {!readonly && (
        <TableCell align="center">
          <CustomizedIcons
            editIcon
            deleteIcon
            onEdit={onEditDocumentalType}
            onDelete={onDeleteDocumentalType}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

import {
  Box,
  createTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { counter } from "../../../helpers/count";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { FormattedMessage } from "react-intl";
import { Colors } from "../../../shared/constants/themes/Colors";
import { CustomizedTableFooter } from "../../../components/custom/CustomizedTableFooter";
import { LoanBody } from "./LoanBody";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});

export const LoanTable = () => {
  const classes = useStyles();
  const { data, value } = useSelector((state: AppState) => state.search);


  // PRESTAMOS REDUX
  const { loans } = useSelector((state: AppState) => state.fileModule);
  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );
  const count = counter(value, loans, data);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, loans.length - page * rowsPerPage);

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        {loans.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            <FormattedMessage id={"NoCreatedLoanDate"} />
          </Box>
        ) : (
          <Table
            className={classes.table}
            size="small"
            style={{ tableLayout: "auto" }}
            aria-label="a dense table"
          >
            <TableHead className={classes.titleTable}>
              <TableRow>
                <TableCell
                  width={"15%"}
                  align="center"
                  className={classes.titleTable}
                >
                  <FormattedMessage id="Name" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Phone" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="FileCode" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="FileDescription" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="LoanDate" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Days" />{" "}
                </TableCell>
                <TableCell
                  width={"20%"}
                  className={classes.titleTable}
                  align="center"
                >
                  <FormattedMessage id="State" />{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!value.trim()
                ? (rowsPerPage > 0
                    ? loans.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : loans
                  ).map((loan) => <LoanBody key={loan.id} loan={loan} />)
                : data &&
                  (rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((loan) => <LoanBody key={loan.id} loan={loan} />)}

              {emptyRows > 0 && (
                <TableRow style={{ height: 13 * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
            {/* --------- Footer para paginación -------- */}
            <CustomizedTableFooter count={count} />
          </Table>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

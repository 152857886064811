import { Entity } from "../../interfaces/Users";
import { IEntitiesAction, IEntitiesState, Types } from "../types/types";

const initialState: IEntitiesState = {
  entities: [],
  activeEntity: null,
  initialSettled: null,
};
export const entitiesReducer = (
  state = initialState,
  action: IEntitiesAction
): IEntitiesState => {
  const { type, payload } = action;
  switch (type) {
    case Types.entitiesLoad:
      return {
        ...state,
        entities: payload as Entity[],
      };
    case Types.addEntity:
      return {
        ...state,
        entities: [...state.entities, payload as Entity],
      };
    case Types.editEntity:
      return {
        ...state,
        entities: state.entities.map((entity) =>
          entity.id === payload.oldId ? (payload.entity as Entity) : entity
        ),
      };

    case Types.deleteEntity:
      return {
        ...state,
        entities: state.entities.filter((el) => el.id !== (payload as string)),
      };
    case Types.purgeEntities:
      return {
        entities: [],
        activeEntity: null,
        initialSettled: null
      };
    case Types.purgeActiveEntity:
      return {
        ...state,
        activeEntity: null,
      };
    case Types.setActiveEntity:
      return {
        ...state,
        activeEntity: payload as any,
      };
      case Types.addInitialSettled:
        return {
          ...state,
          initialSettled: payload as string,
        };
    default:
      return state;
  }
};

import React from "react";
import { Box, Grid } from "@material-ui/core";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { firebase } from "../../config/firebase/firebase-config";
import { AppState } from "../../redux/reducers/rootReducer";

import { CorrespondenceTable } from "../../components/correspondence-module/correspondence/CorrespondenceTable";
import { CustomizedSearch } from "../../components/custom/CustomizedSearch";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import { CustomizedWindow } from "../../components/custom/CustomizedWindow";
import { SettledDetails } from "../../components/document-managment/correspondence/SettledDetails";
import moment from "moment";
import { uiCloseModalSee } from "../../redux/actions/uiActions";
import { setActiveCorrespondence } from "../../redux/actions/correspondenceActions";

export const OthersScreen = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { modalSeeOpen } = useSelector((state: AppState) => state.ui);
  const { activeCorrespondence, correspondence: communicationsManagment  } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const correspondences = communicationsManagment.filter(
    (el) =>  el.communicationType?.option !== "Procedure" &&
    el.communicationType?.option !== "CommunicationOfControlEntities" &&
    el.communicationType?.option !== "SummonsToJudicialProceedings" 
  )
  const date =
    activeCorrespondence?.createAt &&
    moment(
      (activeCorrespondence.createAt as firebase.firestore.Timestamp).toDate()
    ).format("YYYY/MM/DD - h:mm:ss");
  const onDeny = () => {
    dispatch(uiCloseModalSee());
    dispatch(setActiveCorrespondence(null));
  };

  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box>
        <Box mb={3} fontSize={20}>
          <Grid container>
            <Grid item xs={7} />
            <Grid item xs={5}>
              <CustomizedSearch data={correspondences} />
            </Grid>
          </Grid>
        </Box>
        {/* <MilestoneCorrespondence idSettled='03ER2021713000000002' idEntity='987151488422' /> */}
        <CorrespondenceTable
          readOnly
          rows={correspondences}
        />
        <CustomizedWindow
          open={modalSeeOpen}
          content={<SettledDetails component="OTHER" />}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "Settled" })} No. ${
            activeCorrespondence?.id
          } -  ${date}`}
        />
      </Box>
    </AppAnimate>
  );
};

import { Types, IFuidState, FuidAction, IFuid } from "../types/types";

const initialState: IFuidState = {
  activeExpedient: null,
  activeDocument: null,
};

export const fuidReducer = (
  state = initialState,
  action: FuidAction
): IFuidState => {
  const { type, payload } = action;
  switch (type) {
  

    case Types.setActiveFuid:
      return {
        ...state,
        activeExpedient: payload as IFuid,
      };
    case Types.setActiveDocument:
      return {
        ...state,
        activeDocument: payload as any,
      };


    default:
      return state;
  }
};

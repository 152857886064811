import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { AuthRouter } from "./AuthRouter";
import { PublicRoute } from "./PublicRoute";
import { useEffect, useState } from "react";
import { firebase } from "../config/firebase/firebase-config";
import { PrivateRoute } from "./PrivateRoute";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import { useStyles } from "../shared/styles/useStyles";
import { ModulesRouter } from "./ModulesRouter";
import { useDispatch } from "react-redux";
import { getUserData } from "../services/firebase/auth";
import { Login } from "../redux/actions/authActions";
import { TypeUser } from "../enums/enums";
import {
  startLoadingUsers,
  startLoadingUsersAnnotations,
} from "../redux/actions/usersActions";
import {
  startLoadEntities,
  startLoadInitialSettled,
} from "../redux/actions/entitiesActions";
import { ChangeScreen } from "../pages/auth/ChangeScreen";
import { startLoadingOffices } from "../redux/actions/organizationChartActions";
import {
  startLoadContractingOffice,
  startLoadCorrespondences,
  startLoadingExternalSender,
  startLoadingProcedures,
  startLoadingResponseTimesParams,
  startLoadingTenders,
  startLoadLabelSize,
} from "../redux/actions/correspondenceActions";
import {
  startLoadVersions,
  startLoadingProductionOffices,
  startLoadSeries,
  startLoadDocumentalType,
  startLoadingSubseries,
} from "../redux/actions/trdActions";
import { startLoadAnnotations } from "../redux/actions/correspondenceActions";
import { ProcedureStatus } from "../pages/procedureStatus/ProcedureStatus";
import {
  startListenertTransfer,
  startLoadDeposits,
  startLoadSignature,
} from "../redux/actions/documentManagmentActions";
import { startLoadFuid } from "../redux/actions/fuidActions";
import { startLoadDepositsFiles } from "../redux/actions/centralArchiveActions";
import {
  startLoadFileModuleTransfers,
  startLoadLoans,
  startLoadTransferExpedients,
} from "../redux/actions/fileModuleActions";

export const AppRouter = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [checking, setChecking] = useState(true);
  const dispatch = useDispatch();
  const classes = useStyles();
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user && user.email) {
        const userData = await getUserData(user.email);
        if (userData && userData.active) {
          await dispatch(Login(userData));

          if (userData.rol === TypeUser.ADMIN && userData.idEntity) {
            await dispatch(startLoadingUsers(userData.idEntity));
            await dispatch(startLoadVersions(userData.idEntity));
            await dispatch(startLoadingProductionOffices(userData.idEntity));
            await dispatch(startLoadingOffices(userData.idEntity));
            await dispatch(startLoadSeries(userData.idEntity));
            await dispatch(startLoadDocumentalType(userData.idEntity));
            await dispatch(startLoadingSubseries(userData.idEntity));
            // CARGAR INITIAL SETTLED IN ENTITY
            await dispatch(startLoadInitialSettled(userData.idEntity));
          }
          if (userData.rol === TypeUser.SUPER_ADMIN) {
            await dispatch(startLoadEntities());
          }
          if (userData.rol === TypeUser.OFFICER && userData.idEntity) {
            await dispatch(startLoadAnnotations(userData.idEntity));
            await dispatch(startLoadingProcedures(userData.idEntity));
            await dispatch(startLoadingResponseTimesParams(userData.idEntity));
            await dispatch(startLoadingUsersAnnotations(userData.idEntity));
            await dispatch(
              startLoadCorrespondences(userData.id, userData.idEntity)
            );
            await dispatch(startLoadingExternalSender(userData.idEntity));
            await dispatch(startLoadingTenders(userData.idEntity));

            //CARGAR LA TRD
            await dispatch(startLoadVersions(userData.idEntity));
            await dispatch(startLoadingProductionOffices(userData.idEntity));
            await dispatch(startLoadSeries(userData.idEntity));
            await dispatch(startLoadDocumentalType(userData.idEntity));
            await dispatch(startLoadingSubseries(userData.idEntity));

            //CARGAR LAS OFICINAS DE CONTRATACION
            await dispatch(startLoadContractingOffice(userData.idEntity));
            if (userData.offices && userData.offices.length > 0) {
              let idProductionOffice = userData.offices[0].split("_")[0];
              await dispatch(
                startLoadDeposits(userData.idEntity, idProductionOffice)
              );
              // CARGAR DEPOSITOS PARA ARCHIVO CENTRAL
              await dispatch(
                startLoadDepositsFiles(userData.idEntity, idProductionOffice)
              );
            }

            //CARGAR LOS EXPEDIENTES
            await dispatch(startLoadFuid(userData.idEntity));

            // LISTENER TRANSFER (CARGAR TRANSFER ORDEN EN REDUX)
            await dispatch(startListenertTransfer(userData.idEntity));
            await dispatch(startLoadFileModuleTransfers(userData.idEntity));
            await dispatch(startLoadTransferExpedients(userData.idEntity));

            //LISTENER LOANS
            await dispatch(startLoadLoans(userData.idEntity));

            // CARGAR LABEL SIZE CORRESPONDENCE ENTITY
            await dispatch(startLoadLabelSize(userData.idEntity));

            // CARGAR SIGNATURE ENTITY USER
            await dispatch(startLoadSignature(userData.idEntity));
            // CARGAR INITIAL SETTLED IN ENTITY
            await dispatch(startLoadInitialSettled(userData.idEntity));
          }

          setIsLogged(true);
        } else {
          setIsLogged(false);
        }
      } else {
        setIsLogged(false);
      }
      setChecking(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (checking) {
    return (
      <div className={classes.rootLoading}>
        <Grid container className={classes.paperLoading}>
          <Box mx="auto" my="45vh">
            <CircularProgress className={classes.colorLoading} />
          </Box>
        </Grid>
      </div>
    );
  }
  return (
    <Router>
      <div>
        <Switch>
          <PublicRoute
            path="/auth"
            component={AuthRouter}
            isAuthenticated={isLogged}
          />
          <PublicRoute
            path="/procedure-status/:idSettled"
            component={ProcedureStatus}
            isAuthenticated={isLogged}
          />
          <PrivateRoute
            path="/account"
            isAuthenticated={isLogged}
            component={ChangeScreen}
          />
          <PrivateRoute
            path="/"
            component={ModulesRouter}
            isAuthenticated={isLogged}
          />
          <Redirect to="/" />
        </Switch>
      </div>
    </Router>
  );
};

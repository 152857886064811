import { TypeArchive } from "../../enums/enums";
import { FuidScreen } from "./FuidScreen";
import { useSelector } from "react-redux";
import { AppState } from "../../redux/reducers/rootReducer";
import { TransferType } from "../../enums/enums";
export const CentralFilesScreen = () => {
  const { expedients } = useSelector((state: AppState) => state.fileModule);
  const expedientsByTransferType = expedients?.filter(
    (exp) =>
      exp?.transferType! === TransferType.PRIMARY_TRANSFER &&
      exp.deposit !== null
  );
  return <FuidScreen typeExpedients={expedientsByTransferType} fromModule={TypeArchive.CENTRAL_ARCHIVE} />;
};

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { CustomizedSearch } from "../../components/custom/CustomizedSearch";
import { UsersTable } from "../../components/admin/users/UsersTable";
import CustomizedDialog from "../../components/custom/CustomizedDialog";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import {
  uiOpenModalAdd,
  uiCloseModalAdd,
  uiCloseModalEdit,
  uiCloseSuccessChange,
  uiCloseModalAssignModules,
  uiCloseModalAssignOffices,
  uiCloseSuccessAlert,
} from "../../redux/actions/uiActions";
import { AppState } from "../../redux/reducers/rootReducer";
import { useStyles } from "../../shared/styles/useStyles";
import { FormAddUser } from "../../components/admin/users/FormAddUser";
import { FormEditUser } from "../../components/admin/users/FormEditUser";
import {
  setAssignedOffices,
  userCleanActive,
} from "../../redux/actions/usersActions";
import { MyAlert } from "../../components/custom/MyAlert";
import { AssignModules } from "../../components/admin/users/AssignModules";
import { AssignOffices } from "../../components/admin/users/AssignOffices";

export const UsersScreen = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { users } = useSelector((state: AppState) => state.users);
  const {
    modalAddOpen,
    modalAssignModulesOpen,
    modalAssignOfficesOpen,
    modalEditOpen,
    successChange,
    successAlert,
    successMsg,
  } = useSelector((state: AppState) => state.ui);

  const onDeny = () => {
    dispatch(userCleanActive());
    dispatch(uiCloseModalEdit());
    dispatch(uiCloseModalAssignModules());
    dispatch(uiCloseModalAssignOffices());
    dispatch(setAssignedOffices([]));
  };

  const closeAlert = () => {
    dispatch(uiCloseSuccessAlert());
  };

  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box>
        <Box mb={3} fontSize={20}>
          <Grid container>
            <Grid item xs={5}>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                className={classes.btnAdd}
                type="button"
                onClick={() => dispatch(uiOpenModalAdd())}
              >
                <AddOutlinedIcon />
                <FormattedMessage id="CreateNewUser" />
              </Button>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <CustomizedSearch data={users} />
            </Grid>
          </Grid>
        </Box>

        <UsersTable />

        <CustomizedDialog
          open={modalAddOpen}
          cancelBtn={true}
          onDeny={() => dispatch(uiCloseModalAdd())}
          title={`${intl.formatMessage({ id: "CreateNewUser" })}`}
          content={<FormAddUser />}
          textButton="Accept"
        />

        <CustomizedDialog
          open={modalEditOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "EditUser" })}`}
          content={<FormEditUser />}
          width
          textButton="Accept"
        />

        <CustomizedDialog
          open={modalAssignModulesOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "AssignModules" })}`}
          content={<AssignModules />}
          textButton="Accept"
        />

        <CustomizedDialog
          open={modalAssignOfficesOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={`${intl.formatMessage({ id: "AssignOffice" })}`}
          content={<AssignOffices />}
          textButton="Accept"
        />

        <MyAlert
          open={successChange}
          typeAlert="success"
          message="UpdatedUser"
          time={2000}
          handleClose={() => dispatch(uiCloseSuccessChange())}
        />
        {successMsg && (
          <MyAlert
            open={successAlert}
            typeAlert="success"
            message={successMsg}
            time={2000}
            handleClose={closeAlert}
          />
        )}
      </Box>
    </AppAnimate>
  );
};

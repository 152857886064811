import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import { useStyles } from "../../../shared/styles/useStyles";
import clsx from "clsx";
import { Form, Formik } from "formik";

import { AppState } from "../../../redux/reducers/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  uiCloseModalAction,
  uiCloseModalInfo,
} from "../../../redux/actions/uiActions";
import Checkbox from "@material-ui/core/Checkbox";
import { useEffect, useState } from "react";
import { IFilesCorrespondence } from "../../../interfaces/Correspondence";
import { startSetCorrespondence } from "../../../redux/actions/correspondenceActions";
import { setActiveDocument } from "../../../redux/actions/fuidActions";
import {
  startSetAnnotationTrd,
  startSetFuid,
} from "../../../redux/actions/documentManagmentActions";
export interface ICheckFiles extends IFilesCorrespondence {
  checked: boolean;
  annotationId?: string;
}

interface Props {
  component?: "ExpedientManagment" | "ExpedientCentralFile" | "Other";
}
export const ClassifyTrdForm = ({ component = "Other" }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    series: allSeries,
    productionOffices,
    subSeries: allSubseries,
    documentalType: allDocumentalType,
    versions,
  } = useSelector((state: AppState) => state.trd);
  const { corresAnnotations } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const [series, setSeries] = useState(allSeries);
  const [subSeries, setSubSeries] = useState(allSubseries);
  const [documentalType, setDocumentalType] = useState(allDocumentalType);
  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { activeExpedient: FuidDocumentManagement } = useSelector(
    (state: AppState) => state.fuid
  );
  const { activeExpedientFileModule: FuidFilesModule } = useSelector(
    (state: AppState) => state.fileModule
  );

  const expedient =
    component === "ExpedientCentralFile"
      ? FuidFilesModule
      : FuidDocumentManagement;
  const { activeDocument } = useSelector((state: AppState) => state.fuid);
  const [selected, setSelected] = useState({
    productionOffice: activeDocument
      ? (allDocumentalType.find(
          (doc) => doc.idDocumentalType === activeDocument.documentalType
        )?.idProductionOffice as string)
      : "",
    series: activeDocument
      ? (allDocumentalType.find(
          (doc) => doc.idDocumentalType === activeDocument.documentalType
        )?.idSerie as string)
      : "",
    subseries: activeDocument
      ? (allDocumentalType.find(
          (doc) => doc.idDocumentalType === activeDocument.documentalType
        )?.idSubSerie as string)
      : "",
    documentalType:
      activeDocument && activeDocument.documentalType
        ? activeDocument.documentalType
        : "",
  });

  const [files, setFiles] = useState<ICheckFiles[]>([]);

  const initialValues = {
    productionOffice: "",
    series: "",
    subSeries: "",
    documentalType: "",
  };
  useEffect(() => {
    let parseArrFiles: ICheckFiles[] = [];
    const parseCorresFiles: any[] = [];
    corresAnnotations.forEach((annotation) => {
      const { attachedFiles, id } = annotation;
      attachedFiles
        .filter((file) => !file.trdVersion)
        .forEach((file) => {
          parseCorresFiles.push({ ...file, annotationId: id });
        });
    });

    if (activeCorrespondence) {
      parseArrFiles = activeCorrespondence.digitalSupport
        .filter((file: any) => !file.trdVersion)
        .concat(parseCorresFiles)
        .map((obj: any) => ({
          ...obj,
          checked: false,
        }));

      setFiles(parseArrFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    dispatch(setActiveDocument(null));
    dispatch(uiCloseModalAction());
    dispatch(uiCloseModalInfo());
  };
  const handleCheck = (fileName: string) => {
    const filesArrCopy = [...files];
    const indexFile = files.findIndex((file) => file.fileName === fileName);
    if (indexFile >= 0) {
      filesArrCopy[indexFile].checked = !filesArrCopy[indexFile].checked;
    }
    setFiles(filesArrCopy);
  };
  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected({ ...selected, [event.target.name]: event.target.value });
    /*  if(series.length>0){
      setSelected({...selected,series:""})
    }
    if(subSeries.length>0){
      setSelected({...selected,subseries:""})
    }
    if(documentalType.length>0){
      setSelected({...selected,documentalType:""})
    } */
  };
  useEffect(() => {
    //FILTRAR LOS SELECTS
    const seriesFiltered = allSeries.filter(
      (ser) => ser.idProductionOffice === selected.productionOffice
    );
    const subSeriesFiltered = allSubseries.filter(
      (subSer) => subSer.idSerie === selected.series
    );
    const documentalTypeFilter = allDocumentalType.filter(
      (docType) =>
        docType.idProductionOffice === selected.productionOffice &&
        docType.idSerie === selected.series &&
        docType.idSubSerie === selected.subseries
    );
    setSeries(seriesFiltered);
    setSubSeries(subSeriesFiltered);
    setDocumentalType(documentalTypeFilter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);
  const findDocumentalTypeName = (id: string) => {
    const docType = allDocumentalType.find(
      (doc) => doc.idDocumentalType === id
    );
    if (docType) {
      return docType.name;
    }
    return "";
  };
  if (files.length <= 0) {
    return (
      <h3>
        <FormattedMessage id="NoFilesToClassify" />
      </h3>
    );
  }
  return (
    <Formik
      validateOnChange={true}
      initialValues={initialValues}
      //validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        const trdVersion = versions.find((trd) =>
          trd.documentalType?.includes(selected.documentalType)
        );
        const trdFullCode =
          selected.productionOffice.split("_")[0] +
          "-" +
          selected.series.split("_")[0] +
          "-" +
          selected.subseries.split("_")[0];

        if (component === "Other") {
          if (
            trdVersion &&
            activeCorrespondence &&
            selected.documentalType !== ""
          ) {
            const correspondenceCopy = { ...activeCorrespondence };
            const finalFilesSelectedCorres = files.filter(
              (fil) => fil.checked === true && !fil.annotationId
            );
            const finalFilesSelectedAnn = files.filter(
              (fil) => fil.checked === true && fil.annotationId
            );
            if (finalFilesSelectedCorres.length > 0) {
              for (const file of finalFilesSelectedCorres) {
                if (file.checked) {
                  const fileObj: IFilesCorrespondence = {
                    url: file.url,
                    fileName: file.fileName,
                    documentalType: selected.documentalType,
                    trdVersion: trdFullCode,
                  };
                  const indexFile = correspondenceCopy.digitalSupport.findIndex(
                    (el: any) => el.fileName === file.fileName
                  );
                  if (indexFile >= 0) {
                    correspondenceCopy.digitalSupport[indexFile] = fileObj;
                  }
                }
              }

              await dispatch(
                startSetCorrespondence(correspondenceCopy, true, false, true)
              );
            }
            if (finalFilesSelectedAnn.length > 0) {
              await dispatch(
                startSetAnnotationTrd(
                  finalFilesSelectedAnn,
                  selected.documentalType,
                  trdFullCode
                )
              );
            }
            dispatch(uiCloseModalAction());
          }
        } else {
          if (activeDocument && expedient) {
            const expedientCopy = { ...expedient };
            if (expedientCopy.documents) {
              const indexDoc = expedientCopy.documents.findIndex(
                (doc) => doc.url === activeDocument.url
              );
              if (indexDoc >= 0 && trdVersion) {
                expedientCopy.documents[indexDoc].documentalType =
                  selected.documentalType;
                expedientCopy.documents[indexDoc].trdCode = trdFullCode;
                component === "ExpedientCentralFile"
                  ? await dispatch(startSetFuid(expedientCopy, true, true))
                  : await dispatch(startSetFuid(expedientCopy, true));
              }
            }
          }
        }

        setSubmitting(false);
      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Grid container>
            <Grid item xs={6}>
              <FormattedMessage id="ProductionOffice" />:
              <Box width={"90%"}>
                <TextField
                  name="productionOffice"
                  variant="outlined"
                  select
                  size="small"
                  value={selected.productionOffice || ""}
                  className={classes.myTextFieldRoot}
                  onChange={handleSelect}
                  //   disabled={ active ? true : false}
                >
                  {productionOffices.map((productionOffice) => (
                    <MenuItem
                      key={productionOffice.idProductionOffice}
                      value={productionOffice.idProductionOffice}
                    >
                      {productionOffice.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <FormattedMessage id="Series" />:
              <Box width={"90%"}>
                <TextField
                  name="series"
                  variant="outlined"
                  select
                  size="small"
                  value={selected.series || ""}
                  className={classes.myTextFieldRoot}
                  onChange={handleSelect}
                  //   disabled={ active ? true : false}
                >
                  {series.map((serie) => (
                    <MenuItem key={serie.idSerie} value={serie.idSerie}>
                      {serie.seriesName}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>

            <Grid item xs={6} style={{ marginTop: 18 }}>
              <FormattedMessage id="Subseries" />:
              <Box width={"90%"}>
                <TextField
                  name="subseries"
                  variant="outlined"
                  select
                  size="small"
                  value={selected.subseries || ""}
                  className={classes.myTextFieldRoot}
                  onChange={handleSelect}
                  //   disabled={ active ? true : false}
                >
                  {subSeries.map((subSer) => (
                    <MenuItem key={subSer.idSubserie} value={subSer.idSubserie}>
                      {subSer.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>

            <Grid item xs={6} style={{ marginTop: 18 }}>
              <FormattedMessage id="DocumentaryType" />:
              <Box width={"90%"}>
                <TextField
                  name="documentalType"
                  variant="outlined"
                  select
                  size="small"
                  value={selected.documentalType || ""}
                  className={classes.myTextFieldRoot}
                  onChange={handleSelect}
                  //   disabled={ active ? true : false}
                >
                  {documentalType.map((doc) => (
                    <MenuItem
                      key={doc.idDocumentalType}
                      value={doc.idDocumentalType}
                    >
                      {doc.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>

            {files.map(
              ({ fileName, url, checked, trdVersion, documentalType }) => {
                return (
                  <Grid container key={fileName} style={{ marginTop: 30 }}>
                    <Grid item xs={1}>
                      <Checkbox
                        checked={checked}
                        onChange={() => handleCheck(fileName)}
                        //name="checkedB"
                        color="primary"
                      />
                    </Grid>
                    <Grid item xs={5} key={fileName}>
                      <Box style={{ fontSize: 12, color: "blue" }}>
                        <a href={url} target="__blank">
                          {fileName}
                        </a>
                      </Box>
                    </Grid>
                    {trdVersion && documentalType && (
                      <>
                        <Grid item xs={3}>
                          <div style={{ fontSize: "0.7rem" }}>
                            <span> {trdVersion} </span>
                          </div>
                        </Grid>
                        <Grid item xs={3}>
                          <div style={{ fontSize: "0.7rem" }}>
                            <span>
                              {" "}
                              {findDocumentalTypeName(documentalType)}
                            </span>
                          </div>
                        </Grid>
                      </>
                    )}
                  </Grid>
                );
              }
            )}
            <Box mt={2} display="flex" flexDirection="row-reverse" width={1}>
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="Save" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

import { TableRow, TableCell } from "@material-ui/core";
import { FC } from "react";
import { ICorrespondenceStatistics } from "../../../interfaces/Correspondence";
import { useStyles } from "../../../shared/styles/useStyles";
interface props {
  data: ICorrespondenceStatistics;
}

export const CorrespondenceStatisticsBody: FC<props> = ({ data }) => {
  const classes = useStyles();

  return (
    <TableRow key={data.communicationType} className={classes.borderTable}>
      <TableCell align="center" className={classes.titleTable} colSpan={1}>
        {data.communicationType}
      </TableCell>
      <TableCell align="center">{data.numEr ? data.numEr : 0}</TableCell>
      <TableCell align="center">{data.numEp ? data.numEp : 0}</TableCell>
      <TableCell align="center">{data.numIr ? data.numIr : 0}</TableCell>
      <TableCell align="center">{data.numIp ? data.numIp : 0}</TableCell>
      <TableCell align="center">{data.numOr ? data.numOr : 0}</TableCell>
      <TableCell align="center">{data.numOp ? data.numOp : 0}</TableCell>
      <TableCell align="center">
        {data.numAnswered ? data.numAnswered : 0}
      </TableCell>
      <TableCell align="center">
        {data.numWithOutAnswer ? data.numWithOutAnswer : 0}
      </TableCell>
      <TableCell align="center">
        {data.numNoNeedAnswer ? data.numNoNeedAnswer : 0}
      </TableCell>
      <TableCell align="center">
        {data.numResponseOnTime ? data.numResponseOnTime : 0}
      </TableCell>
      <TableCell align="center">
        {data.numResponseOutTime ? data.numResponseOutTime : 0}
      </TableCell>
    </TableRow>
  );
};

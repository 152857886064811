import { Box } from "@material-ui/core";
import { TRDTabsPanel } from "../../components/admin/TRD/TRDTabsPanel";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";

export const TRDScreen = () => {
  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box>
        <TRDTabsPanel />
      </Box>
    </AppAnimate>
  );
};

import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

import { TableRow, TableCell } from "@material-ui/core";

import { CustomizedIcons } from "../../custom/CustomizedIcons";
import { ILoan, Enum_LoanState } from "../../../interfaces/Loans";
import { startReturnLoan } from "../../../redux/actions/fileModuleActions";
import { useStyles } from "../../../shared/styles/useStyles";
import {
  setActiveLoan,
  startDeleteLoan,
} from "../../../redux/actions/fileModuleActions";
import { uiOpenModalEdit } from "../../../redux/actions/uiActions";

import { Colors } from "../../../shared/constants/themes/Colors";
// import { useEffect } from "react";

interface Props {
  loan: ILoan;
  component: "Physical" | "Digital";
}

export const LoansBody = ({ loan, component }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  

  const onReturn = async () => {
    Swal.fire({
      title: `${intl.formatMessage({ id: "ReturnFileQuestion" })}${
        loan.idExpedient
      }?`,
      icon: "question",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: Colors.PRIMARY,
      cancelButtonColor: Colors.DANGER,
      confirmButtonText: `${intl.formatMessage({ id: "Yes" })}`,
      cancelButtonText: `${intl.formatMessage({ id: "No" })}`,
      customClass: {
        container: "swal-container-z-index",
      },
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        await dispatch(startReturnLoan(loan, intl));
      },
    });
  };

  const onEdit = () => {
    dispatch(setActiveLoan(loan));
    dispatch(uiOpenModalEdit());
  };

  const onDelete = () => {
    Swal.fire({
      title: `${intl.formatMessage({ id: "DeleteLoan" })}${loan.description}?`,
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: Colors.PRIMARY,
      cancelButtonColor: Colors.DANGER,
      confirmButtonText: "Si",
      cancelButtonText: "No",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        await dispatch(startDeleteLoan(loan, intl));
      },
    });
  };

  return (
    <TableRow key={loan.id}>
      <TableCell
        component="th"
        scope="row"
        align="center"
        className={classes.capitalize}
      >
        {loan.officer.name}
      </TableCell>
      <TableCell align="center">{loan.phone}</TableCell>
      <TableCell align="center">{loan.idExpedient}</TableCell>
      <TableCell align="center">{loan.description}</TableCell>
      <TableCell align="center">
        {moment(loan.createAt.toDate()).format("YYYY/MM/DD h:mm:ss a")}
      </TableCell>
      {component === "Physical" && (
        <TableCell align="center">
          {" "}
          {moment(loan.returnDate.toDate()).diff(moment(), "days")}{" "}
        </TableCell>
      )}
      <TableCell align="center">
        <FormattedMessage id={loan.state} />
      </TableCell>
      <TableCell align="center">
        <CustomizedIcons
          returnIcon={
            loan.state === Enum_LoanState.WithoutReturning ? true : undefined
          }
          editIcon
          deleteIcon
          onReturn={onReturn}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </TableCell>
    </TableRow>
  );
};

import { db, firebase } from "../../config/firebase/firebase-config";
import { IResponseFirebase } from "../../interfaces/firebase";
import { ILoan } from "../../interfaces/Loans";
import {
  loadLoans,
  loadTransferExpedients,
  loadTransfers,
} from "../../redux/actions/fileModuleActions";
import {
  IFuid,
  IMilestones,
  ITransfer,
  ICorresAnnotations,
} from "../../redux/types/types";

export const startListenerTransfersByEntity = (
  idEntity: string,
  callback: Function
) => {
  db.collection("FileModule")
    .doc(idEntity)
    .collection("Transfers")
    .orderBy("submitDate", "desc")
    .onSnapshot((snap) => {
      let response: ITransfer[] = [];
      snap.forEach((doc) => {
        response.push(doc.data() as ITransfer);
      });
      callback(loadTransfers(response));
    });
};
export const startListenerTransferExpedientsByEntity = (
  idEntity: string,
  callback: Function
) => {
  db.collection("FileModule")
    .doc(idEntity)
    .collection("Expedients")
    .orderBy("createDate", "desc")
    .onSnapshot((snap) => {
      let response: IFuid[] = [];
      snap.forEach((doc) => {
        response.push(doc.data() as IFuid);
      });

      callback(loadTransferExpedients(response));
    });
};
export const addTransferFileModule = async (
  idEntity: string,
  transfer: ITransfer
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection("FileModule")
      .doc(idEntity)
      .collection("Transfers")
      .doc(transfer.idTransfer)
      .set({ ...transfer });
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};
export const setExpedientFileModule = async (
  expedients: IFuid,
  idEntity: string
): Promise<IResponseFirebase> => {
  try {
    //VERIFICAR SI HAY MILESTONE PARA TRANSFERIR TAMBIEN
    const docOriginalExpedient = db
      .collection("Correspondence")
      .doc(idEntity)
      .collection("Expedients")
      .doc(expedients.id);
    const docTransferExpedient = db
      .collection("FileModule")
      .doc(idEntity)
      .collection("Expedients")
      .doc(expedients.id);

    const snap = await docOriginalExpedient
      .collection("Milestones")
      .orderBy("createAt", "desc")
      .get();
    if (!snap.empty) {
      const milestones: IMilestones[] = [];
      snap.forEach((doc) => {
        milestones.push(doc.data() as IMilestones);
      });
      await docTransferExpedient.set(
        {
          ...expedients,
        },
        { merge: true }
      );
      for (const milestone of milestones) {
        await docTransferExpedient.collection("Milestones").add(milestone);
      }
    } else {
      await docTransferExpedient.set(
        {
          ...expedients,
        },
        { merge: true }
      );
    }

    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};
export const startListenerLoansByEntity = (
  idEntity: string,
  callback: Function
) => {
  db.collection("FileModule")
    .doc(idEntity)
    .collection("Loans")
    .orderBy("createAt", "desc")
    .onSnapshot((snap) => {
      let response: ILoan[] = [];
      snap.forEach((doc) => {
        response.push(doc.data() as ILoan);
      });
      callback(loadLoans(response));
    });
};
export const setLoan = async (
  idEntity: string,
  loan: ILoan
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection("FileModule")
      .doc(idEntity)
      .collection("Loans")
      .doc(loan.id)
      .set({ ...loan }, { merge: true });

    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};
export const deleteLoan = async (
  idEntity: string,
  idLoan: string
): Promise<IResponseFirebase> => {
  try {
    await db
      .collection("FileModule")
      .doc(idEntity)
      .collection("Loans")
      .doc(idLoan)
      .delete();

    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const loadFuidAnnotationsDB = async (
  idEntity: string,
  idExpedient: string,
  fromFileModule: boolean
) => {
  const module = fromFileModule ? "FileModule" : "Correspondence";
  const docRef = db
    .collection(module)
    .doc(idEntity)
    .collection("Expedients")
    .doc(idExpedient)
    .collection("FuidAnnotations");

  const fuidAnnotations = await docRef.get();
  const resp: ICorresAnnotations[] = [];

  fuidAnnotations.forEach((doc) => {
    resp.push(doc.data() as ICorresAnnotations);
  });

  return resp;
};

export const deleteFuidAnnotationDB = async (
  idEntity: string,
  idExpedient: string,
  idAnnotation: string,
  fromFileModule: boolean
) => {
  const module = fromFileModule ? "FileModule" : "Correspondence";
  try {
    await db
      .collection(module)
      .doc(idEntity)
      .collection("Expedients")
      .doc(idExpedient)
      .collection("FuidAnnotations")
      .doc(idAnnotation)
      .delete();
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

export const deleteFuidAnnotationDBDoc = async (
  idEntity: string,
  fileName: string
) => {
  try {
    await firebase
      .storage()
      .ref(`/FuidAnnotations/${idEntity}/${fileName}`)
      .delete();
    return {
      ok: true,
    };
  } catch (error) {
    return {
      ok: false,
      data: error,
    };
  }
};

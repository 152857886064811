import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { TableCell, TableRow } from "@material-ui/core";
import { CustomizedIcons } from "../../../components/custom/CustomizedIcons";
import { TypeDays } from "../../../enums/enums";
import { IParamProcedure } from "../../../redux/types/types";
import { useStyles } from "../../../shared/styles/useStyles";
import { setActiveProcedure } from "../../../redux/actions/correspondenceActions";
import {
  uiOpenModalDelete,
  uiOpenModalEdit,
} from "../../../redux/actions/uiActions";

interface props {
  procedure: IParamProcedure;
  readonly?: boolean;
}

export const ProceduresBody: FC<props> = ({ procedure, readonly = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentLocale = navigator.language.split(/[-_]/)[0];

  const onEditProcedure = () => {
    dispatch(setActiveProcedure(procedure));
    dispatch(uiOpenModalEdit());
  };

  const onDeleteProcedure = () => {
    dispatch(setActiveProcedure(procedure));
    dispatch(uiOpenModalDelete());
  };

  return (
    <TableRow key={procedure.id}>
      <TableCell component="th" scope="row" align="center">
        {procedure.id}
      </TableCell>
      <TableCell align="center" className={classes.capitalize}>
        {procedure.procedureName}
      </TableCell>
      <TableCell align="center" style={{ textTransform: "lowercase" }}>
        {procedure.responseTime}{" "}
        {procedure.typeDays === TypeDays.BUSINESSDAYS &&
          currentLocale === "es" && (
            <>
              <FormattedMessage id="Days" />{" "}
              <FormattedMessage id="BusinessDays" />
            </>
          )}
        {procedure.typeDays === TypeDays.BUSINESSDAYS &&
          currentLocale === "en" && <FormattedMessage id="BusinessDays" />}
        {procedure.typeDays === TypeDays.CALENDAR && (
          <>
            <FormattedMessage id="Days" /> <FormattedMessage id="Calendar" />
          </>
        )}
      </TableCell>
      {!readonly && (
        <TableCell align="center">
          <CustomizedIcons
            editIcon
            deleteIcon
            onEdit={onEditProcedure}
            onDelete={onDeleteProcedure}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

import { PDFDocument, PDFImage, PDFPage } from "pdf-lib";
import bwipjs from "bwip-js";

function generateBarcode(correspondenceId: string) {
  const canvas = document.createElement("canvas");
  bwipjs.toCanvas(canvas, {
    bcid: "code128",
    text: correspondenceId,
    scale: 2,
    height: 10,
    includetext: true,
    textxalign: "center",
    textfont: "Inconsolata",
    textsize: 13,
  });
  return canvas.toDataURL("image/png");
}

export const addOneBarcode = async (
  addedFile: any,
  correspondenceId: string
) => {
  const file = addedFile as File;
  const fileName = file.name;
  const type = file.type;

  const existingPdfBytes = await file.arrayBuffer();
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const pages = pdfDoc.getPages();

  const dataURI = generateBarcode(correspondenceId);
  const bytes = base64ToBytes(dataURI);

  const jpgImage = await pdfDoc.embedPng(bytes);

  for (let index = 0; index < pages.length; index++) {
    const page = pages[index];
    addBarcodeToPage(page, jpgImage);
  }

  const pdfBytes = await pdfDoc.save();

  return new File([pdfBytes], fileName, { type: type });
};

export const addTwoBarcode = async (
  addedFile: any,
  correspondenceId1: string,
  correspondenceId2: string
) => {
  const file = addedFile as File;
  const fileName = file.name;
  const type = file.type;

  const existingPdfBytes = await file.arrayBuffer();
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const pages = pdfDoc.getPages();

  const dataURI1 = generateBarcode(correspondenceId1);
  const dataURI2 = generateBarcode(correspondenceId2);

  const bytesImg1 = base64ToBytes(dataURI1);
  const bytesImg2 = base64ToBytes(dataURI2);

  const jpgImage1 = await pdfDoc.embedPng(bytesImg1);
  const jpgImage2 = await pdfDoc.embedPng(bytesImg2);

  for (let index = 0; index < pages.length; index++) {
    const page = pages[index];

    addBarcodeToPage(page, jpgImage1);
    addBarcodeToPage(page, jpgImage2, 170, 88);
  }

  const pdfBytes = await pdfDoc.save();

  return new File([pdfBytes], fileName, { type: type });
};

function addBarcodeToPage(
  page: PDFPage,
  image: PDFImage,
  x: number = 170,
  y: number = 40
) {
  const height = page.getHeight() < 0 ? page.getHeight() * - 1: page.getHeight();
  page.drawImage(image, {
    x: page.getWidth() - x,
    y: height - y,
    height: 30,
    width: 150,
  });
}

function base64ToBytes(dataURI: string) {
  return Uint8Array.from(window.atob(dataURI.replace(/^data[^,]+,/, "")), (v) => v.charCodeAt(0));
}

import clsx from "clsx";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
} from "@material-ui/core";

import { MyTextField } from "../../custom/MyTextField";
import { useStyles } from "../../../shared/styles/useStyles";
import { IFiles } from "../../../redux/types/types";
import {
  uiCloseModalAction,
  uiCloseErrorAlert,
} from "../../../redux/actions/uiActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  setActiveAnswer,
  startIssueVoBo,
} from "../../../redux/actions/documentManagmentActions";
import { MyAlert } from "../../custom/MyAlert";
import { getCorrespondenceIScreeningAnswer } from "../../../services/firebase/correspondence";

export const IssuanceVoBoForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [fileNames, setFileNames] = useState<IFiles[]>([]);
  const { user } = useSelector((state: AppState) => state.auth);
  const { activeCorrespondence: active, activeAnswer } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { errorAlert, errorMsg } = useSelector((state: AppState) => state.ui);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    active ? setFileNames(active.digitalSupport) : setFileNames([]);
  }, [active]);

  useEffect(() => {
    const fetchAnswers = async () => {
      if (user && user.idEntity && active) {
        setLoading(true);
        const response = await getCorrespondenceIScreeningAnswer(
          user.idEntity,
          active.id
        );
        if (response.length > 0) {
          dispatch(setActiveAnswer(response[0]));
        }
        setLoading(false);
      }
    };
    fetchAnswers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if(activeAnswer){

      setFileNames(activeAnswer.attachedDocuments)
    }
  }, [activeAnswer])
  
  

  const initialValues = {
    subject: activeAnswer?.subject ? activeAnswer.subject : "",
    description: activeAnswer?.description ? activeAnswer.description : "",
    concept: "Accepted",
    comment: activeAnswer?.emitComment ? activeAnswer.emitComment : "",
  };

  const validationSchema = yup.object({
    subject: yup.string(),
    description: yup.string(),
    concept: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    comment: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  const onClose = () => {
    dispatch(uiCloseModalAction());
    dispatch(setActiveAnswer(null));
  };

  if (loading) {
    return (
      <Box style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box m={2} mt={-1} mb={2}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          let isOk;
          values.concept === "Accepted" ? (isOk = true) : (isOk = false);
          await dispatch(startIssueVoBo(isOk, values.comment));
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="ResponseVoBoRequiredMessage" />
                </label>
              </Grid>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Subject" />:
                </label>
                <MyTextField
                  name="subject"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  disabled
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Description" />:
                </label>
                <MyTextField
                  name="description"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  multiline
                  rows={4}
                  disabled
                />
              </Grid>

              {fileNames.map(({ fileName, url }) => {
                return (
                  <Grid item xs={8} key={fileName}>
                    <Box style={{ fontSize: 12, color: "blue" }}>
                      <a href={url} target="__blank">
                        {fileName}
                      </a>
                    </Box>
                  </Grid>
                );
              })}

              <Grid item xs={8}>
                <label className="form-text">
                  <FormattedMessage id="ProjectedResponseConcept" />:
                </label>
                <br />
                <Box display="flex">
                  <label
                    className="form-text"
                    style={{ marginTop: "10px", marginRight: "15px" }}
                  >
                    Vo.Bo.
                  </label>
                  <MyTextField
                    name="concept"
                    variant="outlined"
                    className={classes.myTextFieldRoot}
                    select
                  >
                    <MenuItem value={"Accepted"}>
                      <FormattedMessage id="Accepted" />
                    </MenuItem>
                    <MenuItem value={"Denied"}>
                      <FormattedMessage id="Denied" />
                    </MenuItem>
                  </MyTextField>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Comment" />:
                </label>
                <MyTextField
                  name="comment"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>

            <Box mt={2} display="flex" flexDirection="row-reverse" width={1}>
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="Save" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              <Button
                className={clsx(classes.btn, classes.cancel)}
                onClick={onClose}
              >
                <FormattedMessage id="Cancel" />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>

      {errorMsg && (
        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={errorMsg}
          time={3000}
          handleClose={() => dispatch(uiCloseErrorAlert())}
        />
      )}
    </Box>
  );
};

import clsx from "clsx";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from "@material-ui/core";

import { useStyles } from "../../../shared/styles/useStyles";
import {
  setSuccessMsg,
  uiCloseModalAction,
  uiCloseModalSee,
  uiOpenSuccessAlert,
} from "../../../redux/actions/uiActions";
import { FC } from "react";
import { ICorrespondence } from "../../../interfaces/Correspondence";
import { editCorrespondenceReturn } from "../../../redux/actions/correspondenceActions";

interface props {
  correspondence: ICorrespondence;
}

export const ReturnForm: FC<props> = ({ correspondence }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    dispatch(uiCloseModalAction());
  };

  const handleReturn = async () => {
    setLoading(true);
    await dispatch(editCorrespondenceReturn(correspondence));
    // TODO: Disparar acción
    setLoading(false);
    dispatch(uiCloseModalAction());
    dispatch( uiCloseModalSee() )
    dispatch(uiOpenSuccessAlert());
    dispatch(setSuccessMsg("isGiveBack"));
  };

  return (
    <>
      <Box m={2} mt={1} mb={2}>
        <Typography variant="body2" style={{ fontSize: 14 }}>
          <FormattedMessage id="ReturnSettledMessage" />
        </Typography>
      </Box>
      <Divider variant="fullWidth" />
      <Box mt={2} display="flex" flexDirection="row-reverse" width={1}>
        {!loading ? (
          <Button
            className={clsx(classes.btn, classes.save)}
            autoFocus
            type="submit"
            disabled={loading}
            onClick={handleReturn}
          >
            <FormattedMessage id="Return" />
          </Button>
        ) : (
          <Button
            className={clsx(classes.btn, classes.save)}
            autoFocus
            type="button"
            disabled={true}
          >
            <CircularProgress className={classes.btnLoading} />
          </Button>
        )}
        <Button className={clsx(classes.btn, classes.cancel)} onClick={onClose}>
          <FormattedMessage id="Cancel" />
        </Button>
      </Box>
    </>
  );
};

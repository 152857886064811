import { Switch, Route, Redirect } from "react-router-dom";
import { LoginScreen } from '../pages/auth/LoginScreen';
import { ForgotScreen } from '../pages/auth/ForgotScreen';

export const AuthRouter = () => {
    return (
        <div>
            <Switch>
                <Route
                    path="/auth/login"
                    component={LoginScreen} 
                />

                <Route
                    path="/auth/forgot"
                    component={ForgotScreen} 
                />

                <Redirect
                    to="/auth/login"
                />
            </Switch>
        </div>
    )
}

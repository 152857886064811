import { makeStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";

import { Fonts } from "../constants/fonts/Fonts";
import { Colors } from "../constants/themes/Colors";
import authBackground from "../../assets/images/fondo-4.png";
import modulesBackground from "../../assets/images/fondo-4.png";

export const useStyles = makeStyles((theme: Theme) => ({
  input: {
    fontSize: 14,
    "& input::placeholder": {
      fontSize: 16,
      color: theme.palette.background.default,
      fontWeight: Fonts.MEDIUM,
    },
  },
  inputSelect: {
    width: "100%",
    marginTop: 8,
  },
  myTextFieldRoot: {
    width: "100%",
    marginTop: 8,
    "& label": {
      color: `${theme.palette.grey[800]} !important`,
      fontFamily: "Poppins",
      fontSize: 15,
    },
  },
  myTextFieldRootflex: {
    display: "flex",
    width: "100%",
    marginTop: 8,
    "& label": {
      color: `${theme.palette.grey[800]} !important`,
      fontFamily: "Poppins",
      fontSize: 18,
    },
  },
  botton_between: {
    display: "flex",
    gap: "2rem",
    fontSize: 8,
  },
  btn: {
    fontWeight: Fonts.REGULAR,
    textTransform: "capitalize",
    color: theme.palette.common.white,
    fontSize: 14,
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: "4px",
    width: "10vw",
    marginLeft: "5px",
  },
  btnSm: {
    fontWeight: Fonts.REGULAR,
    textTransform: "capitalize",
    color: theme.palette.common.white,
    fontSize: 14,
    borderRadius: "4px",
  },
  btnAdd: {
    background: Colors.ACCENT,
    display: "flex",
    borderRadius: "4px",
    width: "20rem",
    fontWeight: Fonts.REGULAR,
    fontSize: 16,
    textTransform: "none",
    "&:hover": {
      background: Colors.LIGTH_ACCENT,
    },
    [theme.breakpoints.down("sm")]: {
      width: "20%",
      heigth: "200px",
      textShadow: "transparent",
      fontSize: 0,
    },
  },
  btnAction: {
    width: "100%",
    fontWeight: Fonts.REGULAR,
    textTransform: "capitalize",
    color: theme.palette.common.white,
    fontSize: 14,
    borderRadius: "4px",
  },
  cancel: {
    background: Colors.ACCENT,
    "&:hover": {
      background: Colors.LIGTH_ACCENT,
    },
  },
  checkboxLabel: {
    fontSize: 16,
  },
  save: {
    background: Colors.DARK_BUTTON,
    "&:hover": {
      background: Colors.PRIMARY,
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  typography: {
    fontFamily: "Poppins",
    fontSize: 14,
  },
  table: {
    minWidth: 500,
    fontFamily: "Poppins",
  },
  media: {
    width: "100%",
    height: "100%",
  },
  card: {
    height: "247px !important",
    marginTop: "12px",
    maxWidth: 345,
    //pageBreakAfter: "always",
  },
  cardPDF: {
    height: "50vh",
    width: "100%",
    objectFit: "cover",
    objectPosition: "center center",
    pageBreakAfter: "always",
    position: "relative",
  },
  mapPDF: {
    height: "50vh",
    width: "90vw",
    //pageBreakAfter:'always'
  },
  page: {
    pageBreakAfter: "always",
  },
  paper: {
    minHeight: "450px",
    marginBottom: "20px",
    paddingBottom: "20px",
  },
  paperAuto: {
    minHeight: "auto",
    marginBottom: "20px",
    paddingBottom: "20px",
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
    fontFamily: "Poppins",
  },
  title: {
    fontFamily: "Poppins",
    fontSize: 18,
    fontWeight: Fonts.MEDIUM,
    color: theme.palette.grey[800],
  },
  titlePDF: {
    fontSize: 22,
    fontWeight: Fonts.BOLD,
    color: theme.palette.grey[800],
  },
  swal: {
    height: "50hv",
  },
  colorLoading: {
    color: Colors.DARK_BUTTON,
  },
  rootLoading: {
    flexGrow: 1,
  },
  paperLoading: {
    height: "100%",
    width: "100%",
  },
  btnLoading: {
    color: theme.palette.common.white,
    height: "25px !important",
    width: "25px !important",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  helperText: {
    color: "red",
  },
  mapContainer: {
    bottom: 0,
    height: "100%",
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1250,
  },
  infoMap: {
    backgroundColor: "rgba(0,0,0,0.4)",
    borderRadius: "20px",
    color: theme.palette.common.white,
    fontSize: 10,
    padding: "8px",
    position: "fixed",
    zIndex: 1251,
    marginTop: "5px",
    marginLeft: "5px",
  },
  image: {
    height: "800px",
    position: "relative",
  },
  modulesContainer: {
    minWidth: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: `url(${modulesBackground}) no-repeat center center`,
    backgroundSize: "cover",
  },
  imageBackgroundContainer: {
    minWidth: "100%",
    minHeight: "100vh",
    padding: 50,
    background: `url(${modulesBackground}) no-repeat center center`,
  },
  cardsContainer: {
    justifyContent: "center",
    width: "18vw",
    height: "12vh",
    alignItems: "center",
    borderRadius: 10,
    padding: "0.2rem",
    backgroundColor: "rgba(3, 3, 3, 0.57)",
    color: "#fff",
    border: 1,
    borderColor: "#dddddd",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  closeBtn: {
    border: 1,
    backgroundColor: Colors.ACCENT,
    borderRadius: 50,
    position: "relative",
    right: "28vw",
  },
  titleTable: {
    fontSize: 13,
  },
  borderTable: {
    "& > *": {
      border: "1px solid",
      borderColor: theme.palette.action.disabled,
    },
  },
  heightTable: {
    maxHeight: "400px",
    margin: 0,
    padding: 0,
    listStyle: "none",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "0.5em",
      height: "0.5em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  list: {
    overflowY: "auto",
    margin: 0,
    padding: 0,
    listStyle: "none",
    height: "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  titleCenter: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    fontSize: 16,
  },
  centerMediumSize: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    fontSize: "0.8rem",
  },
  circle_green: {
    height: "2rem",
    width: "2rem",
    background: "green",
    borderRadius: "1rem",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  circle_red: {
    height: "2rem",
    width: "2rem",
    background: "red",
    borderRadius: "1rem",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  circle_yellow: {
    height: "2rem",
    width: "2rem",
    background: "yellow",
    borderRadius: "1rem",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  circle_orange: {
    height: "2rem",
    width: "2rem",
    background: "orange",
    borderRadius: "1rem",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  circle_gray: {
    height: "2rem",
    width: "2rem",
    background: "gray",
    borderRadius: "1rem",
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  tableMilestone: {
    // width: '100%',
    margin: "10px",
    overflowY: "scroll",
  },

  fieldsMilestoneTable: {
    paddingTop: "10px",
    paddingRight: "100px",
  },

  itemsMilestoneTable: {
    paddingLeft: "30px",
  },
  profilePic: {
    height: "85px",
    width: "85px",
    marginLeft: "-15px",
    marginRight: "-15px",
  },

  styleRow: {
    cursor: "pointer",
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.05)",
      // color: ,
    },
  },
  styleRowReaded: {
    cursor: "pointer",
    backgroundColor: "rgba(0,0,0,.05)",
  },
  styleRowReaded2: {
    backgroundColor: "rgba(0,0,0,.05)",
  },
  producerUserStyle: {
    cursor: "pointer",
  },
  disabled: {
    pointerEvents: "none",
    color: "#ccc",
  },
  fixedColumn: {
    position: "sticky",
    right: 0,
    backgroundColor: theme.palette.common.white,
  },
  styleAction: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f3f3f3",
      // color: ,
    },
  },
  disableCell: {
    color: Colors.DIVIDER_COLOR,
  },
  tableBorder: {
    border: "0.02px solid black",
    fontSize: 12,
  },
  textTable: {
    display: "block",
    // width: "90px",
    heigth: "auto",
    wordBreak: "break-all",
    // whiteSpace: "pre-wrap",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
  },
  tableStatusEmail: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
  },

  statusEmail: {
    color: "white",
    borderRadius: 14,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 2,
    paddingBottom: 2,
    marginLeft: 10,
  },

  sentBackgroundColor: {
    backgroundColor: Colors.LIGHT_SKY_BLUE,
  },
  openBackgroundColor: {
    backgroundColor: Colors.MEDIUM_SEA_GREEN,
  },
  clickBackgroundColor: {
    backgroundColor: Colors.ROYAL_BLUE,
  },
  bounceBackgroundColor: {
    backgroundColor: Colors.MEDIUM_PURPLE,
  },
  spamBackgroundColor: {
    backgroundColor: Colors.SALMON,
  },
  blockedBackgroundColor: {
    backgroundColor: Colors.DARK_GOLDEN_ROD,
  },
  unsubBackgroundColor: {
    backgroundColor: Colors.INDIANRED,
  },

  mailHistoryTableHeader: {
    "& th": {
      width: "50%",
    },
  },

  statusCircleEmail: {
    "& .statusCircleEmailContent": {
      display: "flex",
      alignItems: "center",
      "& span:first-child": {
        height: "10px",
        width: "10px",
        display: "block",
        borderRadius: "50%",
      },
      "& span:nth-child(2)": {
        marginLeft: "6px",
      },
    },
  },
  mt1: {
    marginTop: "1rem",
  },
  mt2: {
    marginTop: "2rem",
  },
  mt3: {
    marginTop: "3rem",
  },
  ml0: {
    marginLeft: "0rem",
  },
  ml05: {
    marginLeft: "0.5rem",
  },
  pl1: {
    paddingLeft: "1rem",
  },
  pr1: {
    paddingLeft: "1rem",
  },
  steelBlueColor: {
    color: Colors.STEEL_BLUE,
  },
  blackColor: {
    color: Colors.PRIMARY_TEXT,
  },
  redColor: {
    color: Colors.DANGER,
  },
  darkBlueColor: {
    color: Colors.DARK_BUTTON,
  },
  flex1: {
    flex: 1,
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
  emailStatusBody: {
    maxWidth: "300px",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export const useStylesLogin = makeStyles((theme: Theme) => ({
  appAuth: {
    flex: 1,
    display: "flex",
    position: "relative",
    height: "100vh",
    backgroundColor: theme.palette.grey[50],
    background: `url(${authBackground}) no-repeat center center`,
    backgroundSize: "cover",

    "& .scrollbar-container": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    "& .main-content-view": {
      padding: 20,
    },
    "& .footer": {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  imgRoot: {
    cursor: "pointer",
    display: "inline-block",
    width: 300,
  },
  cardRoot: {
    maxWidth: "30rem",
    width: "100%",
    overflow: "hidden",
    boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    position: "relative",
    paddingTop: 20,
    marginBottom: 0,
    borderRadius: 20,
    [theme.breakpoints.up("xl")]: {
      paddingTop: 32,
    },
    /*     "&:before": {
      content: "''",
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      width: 130,
      height: 9,
      borderBottomRightRadius: 80,
      borderBottomLeftRadius: 80,

      marginRight: "auto",
      marginLeft: "auto",
      backgroundColor: Colors.ACCENT,
    }, */
  },
  form: {
    textAlign: "left",
    [theme.breakpoints.up("xl")]: {
      marginBottom: 0,
    },
    marginBottom: 0,
    marginLeft: 14,
    marginRight: 14,
    marginTop: -30,
  },
  textField: {
    width: "100%",
    marginBottom: -15,
    "& label": {
      color: `${theme.palette.grey[800]} !important`,
      fontFamily: "Poppins",
      fontSize: 15,
    },
  },
  btnRoot: {
    background: Colors.ACCENT,
    borderRadius: "4px",
    width: "10rem",
    fontWeight: Fonts.REGULAR,
    fontSize: 16,
    marginTop: -15,
    textTransform: "none",
    "&:hover": {
      background: Colors.LIGTH_ACCENT,
    },
  },
  btnLoading: {
    color: theme.palette.common.white,
    height: "25px !important",
    width: "25px !important",
  },
  underlineNone: {
    textDecoration: "none",
  },
  textGrey: {
    color: theme.palette.action.disabled,
  },
  textSize: {
    fontSize: "14px",
    color: theme.palette.grey[600],
  },
  dividerRoot: {
    marginBottom: 16,
    marginLeft: -48,
    marginRight: -48,
    [theme.breakpoints.up("xl")]: {
      marginBottom: 32,
    },
  },
  pointer: {
    cursor: "pointer",
  },
  checkboxRoot: {
    marginLeft: -12,
  },
}));

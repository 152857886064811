import { FC } from "react";
// import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { TableCell, TableRow, Link } from "@material-ui/core";
import { CustomizedIcons } from "../../../components/custom/CustomizedIcons";
import { useStyles } from "../../../shared/styles/useStyles";
// import { setActiveProcedure } from "../../../redux/actions/correspondenceActions";
import {
  setTitleMsg,
  setQuestionMsg,
  uiOpenModalDelete,
} from "../../../redux/actions/uiActions";
import { ICorresAnnotations } from "../../../redux/types/types";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { setActiveCorresAnnotation } from "../../../redux/actions/documentManagmentActions";
import { donwloadFile } from "../../../services/firebase/documentManagment";
import { AppState } from "../../../redux/reducers/rootReducer";

interface props {
  settle: ICorresAnnotations;
  readOnly?: boolean;
  component?: string;
}

export const CorresAnnotationsBody: FC<props> = ({
  settle,
  readOnly = false,
  component = "OTHER",
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onDeleteAnnotation = async () => {
    await dispatch(setActiveCorresAnnotation(settle));
    dispatch(setTitleMsg("DeleteAnnotation"));
    dispatch(setQuestionMsg("DeleteAnnotationQuestion"));
    dispatch(uiOpenModalDelete());
  };
  const { user } = useSelector((state: AppState) => state.auth);

  const donwloadFunction = async (doc: any) => {
    const folder = "/CorresAnnotations";
    if (doc.fileName) {
      const { ok, data } = await donwloadFile(
        folder,
        user?.idEntity!,
        doc.fileName
      );
      if (ok) {
        window.open(data);
      }
    }
  };

  return (
    <TableRow key={settle.id}>
      {settle.attachedFiles && settle.attachedFiles.length > 0 ? (
        <TableCell
          component="th"
          scope="row"
          align="center"
          style={{ cursor: "pointer" }}
        >
          {settle.attachedFiles.map((doc) => {
            return (
              <Link key={doc.fileName} onClick={() => donwloadFunction(doc)}>
                <option key={doc.fileName}> {doc.fileName} </option>
              </Link>
            );
          })}
        </TableCell>
      ) : (
        <TableCell component="th" scope="row" align="center">
          <FormattedMessage id="WithoutDocument" />
        </TableCell>
      )}
      <TableCell align="center" className={classes.capitalize}>
        {settle.description}
      </TableCell>
      <TableCell align="center" style={{ textTransform: "lowercase" }}>
        {settle.user.name}
      </TableCell>
      <TableCell align="center" style={{ textTransform: "lowercase" }}>
        {moment(settle.createDate.toDate()).format("YYYY/MM/DD")}
      </TableCell>
      {!readOnly && (
        <TableCell align="center">
          {component !== "COPY" ? (
            <CustomizedIcons deleteIcon onDelete={onDeleteAnnotation} />
          ) : (
            <></>
          )}
        </TableCell>
      )}
    </TableRow>
  );
};

import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import clsx from "clsx";
import { useStyles } from "../../../shared/styles/useStyles";
import { MyTextField } from "../../custom/MyTextField";
import { ICheckFiles } from "./ClassifyTrdForm";
import { useDispatch, useSelector } from "react-redux";
import { uiCloseModalAction } from "../../../redux/actions/uiActions";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  startSendEmailEp,
  startSendEmailResponseEp,
} from "../../../redux/actions/documentManagmentActions";
import { IFormSendEmail } from "../../../interfaces/emailNotification";
import * as yup from "yup";

export const SendEmail = () => {
  const classes = useStyles();
  const intl = useIntl();

  const { activeCorrespondence, activeAnswer } = useSelector(
    (state: AppState) => state.documentManagment
  );

  const { externalSenders } = useSelector((state: AppState) => state.users);

  const appenDocs = activeCorrespondence?.appendDocs || [];
  const allFiles = activeCorrespondence?.digitalSupport.concat(appenDocs);
  const correspondenceFiles: any[] = activeAnswer
    ? activeAnswer.emailData.files
    : activeCorrespondence && allFiles
    ? allFiles.map((file: any) => ({
        fileName: file.filename ? file.filename : file.fileName,
        url: file.url,
      }))
    : [];

  const [files, setFiles] = useState<ICheckFiles[]>([
    ...correspondenceFiles.map((file) => ({
      ...file,
      checked: true,
    })),
  ]);
  const dispatch = useDispatch();

  const externalSender = externalSenders.find(
    (user) => user.idDoc === activeCorrespondence?.addressee.id
  );

  const initialValues = {
    addressee: activeAnswer
      ? activeAnswer.emailData.addressee.email
      : externalSender
      ? externalSender.email
      : "",
    copyTo: activeAnswer
      ? activeAnswer.emailData.copyTo.toString().split(",").join(";")
      : "",
  };
  const validationSchema = yup.object({
    addressee: yup
      .string()
      .email(`${intl.formatMessage({ id: "EmailFormat" })}`)
      .required(`${intl.formatMessage({ id: "EmailRequired" })}`),
    copyTo: yup.string(),
  });

  const handleCheck = (fileName: string) => {
    const filesArrCopy = [...files];
    const indexFile = files.findIndex((file) => file.fileName === fileName);
    if (indexFile >= 0) {
      filesArrCopy[indexFile].checked = !filesArrCopy[indexFile].checked;
    }
    setFiles(filesArrCopy);
  };
  const onClose = () => {
    dispatch(uiCloseModalAction());
  };

  return (
    <Box m={2} mt={-1} mb={2}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          if (activeCorrespondence?.addressee.id) {
            const emailsToCopy =
              values.copyTo.length > 0 ? values.copyTo.split(";") : [];
            const formValues: IFormSendEmail = {
              addressee: {
                id: activeCorrespondence.addressee.id,
                email: values.addressee,
              },
              files: files
                .filter((file) => file.checked)
                .map((file) => ({ fileName: file.fileName, url: file.url })),
              copyTo: emailsToCopy,
            };
            if (activeCorrespondence.answer == null) {
              await dispatch(startSendEmailEp(formValues));
            } else {
              await dispatch(
                startSendEmailResponseEp(activeCorrespondence, formValues)
              );
            }
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Recipient" />:
                </label>
                <MyTextField
                  name="addressee"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  disabled={!!activeAnswer}
                />
              </Grid>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="EmailCopyTo" />:
                </label>
                <MyTextField
                  name="copyTo"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  disabled={!!activeAnswer}
                />
              </Grid>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Attachments" />:
                </label>
              </Grid>
              <Box width={"100%"} height={"100px"} overflow="auto">
                {files.map(({ fileName, url, checked }) => (
                  <Grid container key={fileName}>
                    <Grid item xs={1}>
                      <Checkbox
                        checked={checked}
                        onChange={() => handleCheck(fileName)}
                        color="primary"
                        disabled={!!activeAnswer}
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <Box style={{ fontSize: 14, color: "#333" }} mt={1}>
                        <a href={url} target="__blank">
                          {fileName}
                        </a>
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </Box>
              {!activeAnswer && (
                <Box
                  mt={2}
                  display="flex"
                  flexDirection="row-reverse"
                  width={1}
                >
                  {!isSubmitting ? (
                    <Button
                      className={clsx(classes.btn, classes.save)}
                      autoFocus
                      type="submit"
                      disabled={isSubmitting}
                    >
                      <FormattedMessage id="SendBtn" />
                    </Button>
                  ) : (
                    <Button
                      className={clsx(classes.btn, classes.save)}
                      autoFocus
                      type="button"
                      disabled={true}
                    >
                      <CircularProgress className={classes.btnLoading} />
                    </Button>
                  )}
                  <Button
                    className={clsx(classes.btn, classes.cancel)}
                    onClick={onClose}
                  >
                    <FormattedMessage id="Cancel" />
                  </Button>
                </Box>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import {
  createTheme,
  TableContainer,
  Paper,
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { CustomizedTableFooter } from "../../custom/CustomizedTableFooter";
import { counter } from "../../../helpers/count";
import { AppState } from "../../../redux/reducers/rootReducer";
import { Colors } from "../../../shared/constants/themes/Colors";
import { useStyles } from "../../../shared/styles/useStyles";
import { LoansBody } from "./LoansBody";
import { useEffect } from "react";
import moment from "moment";
import { startReturnLoan } from "../../../redux/actions/fileModuleActions";
import { useDispatch } from "react-redux";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});

interface Props {
  component: "Physical" | "Digital";
}

/* const list = [
  {
    officer: { id: "1233536", name: "Karen Cristina Gómez Muñoz" },
    phone: "3217494606",
    idExpedient: "C1-C1-C3-C4",
    description: "Contrato no. 035 del 2020",
    returnDate: "2021-06-08",
    days: "3",
    state: "Sin devolver",
    id: "1",
  },
]; */

export const LoansTable = ({ component }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();

  const { data, value } = useSelector((state: AppState) => state.search);
  const {loans:allLoans} = useSelector((state:AppState) => state.fileModule);
  const list = component === "Physical"? allLoans.filter(loan=>!loan.isDigitalLoan):allLoans.filter(loan=>loan.isDigitalLoan)

  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );

  // DEVOLUCION AUTOMATICA DE PRESTAMOS POR FECHA DE CUMPLIMIENTO
  useEffect(() => {
    const dateNow= new Date().toLocaleDateString("en-CA")
    allLoans.forEach(loan => {
      const dateLoan = moment(loan.returnDate.toDate()).format("YYYY-MM-DD")
      if (dateNow === dateLoan && loan.state === 'WithoutReturning') {
        const isAutomatic = true;
        dispatch(startReturnLoan(loan,intl, isAutomatic));
      } 
    })
  })

  const count = counter(value, list, data);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, list.length - page * rowsPerPage);

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        {list.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            {component === "Physical" ? (
              <FormattedMessage id={"NoCreatedPhysicalLoans"} />
            ) : (
              <FormattedMessage id={"NoCreatedDigitalLoans"} />
            )}
          </Box>
        ) : (
          <Table
            className={classes.table}
            size="small"
            style={{ tableLayout: "auto" }}
            aria-label="a dense table"
          >
            <TableHead className={classes.titleTable}>
              <TableRow>
                <TableCell
                  width={"15%"}
                  align="center"
                  className={classes.titleTable}
                >
                  <FormattedMessage id="Name" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Phone" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="FileCode" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="FileDescription" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="LoanDate" />{" "}
                </TableCell>
                {component === "Physical" && (
                  <TableCell align="center" className={classes.titleTable}>
                    <FormattedMessage id="Days" />{" "}
                  </TableCell>
                )}
                <TableCell className={classes.titleTable} align="center">
                  <FormattedMessage id="State" />{" "}
                </TableCell>
                <TableCell
                  width={"13%"}
                  className={classes.titleTable}
                  align="center"
                >
                  <FormattedMessage id="Actions" />{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!value.trim()
                ? (rowsPerPage > 0
                    ? list.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : list
                  ).map((loan) => (
                    <LoansBody
                      key={loan.id}
                      loan={loan}
                      component={component}
                    />
                  ))
                : data &&
                  (rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((loan) => (
                    <LoansBody
                      key={loan.id}
                      loan={loan}
                      component={component}
                    />
                  ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 13 * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )}
            </TableBody>
            {/* --------- Footer para paginación -------- */}
            <CustomizedTableFooter count={count} />
          </Table>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

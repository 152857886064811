import { useDispatch } from "react-redux";

import { TableCell, TableRow } from "@material-ui/core";
import { CustomizedIcons } from "../../../custom/CustomizedIcons";
import {
  uiOpenModalDelete,
  uiOpenModalEdit,
} from "../../../../redux/actions/uiActions";
import { ITrdProductionOffices } from "../../../../redux/types/types";
import { useStyles } from "../../../../shared/styles/useStyles";
import { setActiveProductionOffice } from "../../../../redux/actions/trdActions";
import { FC } from "react";
interface props {
  office: ITrdProductionOffices;
  readonly?: boolean;
}

export const ProductionOfficeBody: FC<props> = ({
  office,
  readonly = false,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onEditOffice = () => {
    dispatch(setActiveProductionOffice(office));
    dispatch(uiOpenModalEdit());
  };

  const onDeleteOffice = () => {
    dispatch(setActiveProductionOffice(office));
    dispatch(uiOpenModalDelete());
  };

  return (
    <TableRow key={office.code}>
      <TableCell component="th" scope="row" align="center">
        {office.code}
      </TableCell>
      <TableCell align="center" className={classes.capitalize}>
        {office.name}
      </TableCell>
      <TableCell align="center">{office.abbreviation}</TableCell>
      {!readonly && (
        <TableCell align="center">
          <CustomizedIcons
            editIcon
            deleteIcon
            onEdit={onEditOffice}
            onDelete={onDeleteOffice}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

import {
  Box,
  createTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { counter } from "../../helpers/count";
import { AppState } from "../../redux/reducers/rootReducer";
import { IFuid } from "../../redux/types/types";
import { useStyles } from "../../shared/styles/useStyles";
import { CustomizedTableFooter } from "../custom/CustomizedTableFooter";
import { FuidBody } from "./FuidBody";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Colors } from "../../shared/constants/themes/Colors";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});
const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {
      whiteSpace: "nowrap",
    },
    head: {
      height: "40px",
    },
  })
)(TableCell);
interface props {
  expedients: IFuid[];
  fromFilesModule?: boolean;
  readOnly?: boolean;
}

export const FuidTable: FC<props> = ({
  readOnly = false,
  expedients,
  fromFilesModule = false,
}) => {
  const classes = useStyles();
  const { data, value } = useSelector((state: AppState) => state.search);
  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );

  const count = counter(value, expedients, data);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, expedients.length - page * rowsPerPage);

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper} className={classes.heightTable}>
        {expedients.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            <FormattedMessage id={"ThereAreNoRecords"} />
          </Box>
        ) : (
          <Table
            className={classes.table}
            size="small"
            style={{ width: "auto", tableLayout: "auto" }}
            aria-label="a dense table"
          >
            <TableHead className={classes.titleTable}>
              <TableRow>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="ExpedientNumber" />{" "}
                </StyledTableCell>
                <StyledTableCell
                  width={150}
                  align="center"
                  className={classes.titleTable}
                >
                  <FormattedMessage id="ExpedientName" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Description" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="ExtremeDates" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="NoVolume" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="TotalVolumes" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="N°Folder" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  {"TRD"}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Deposit" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Shelf" />{" "}
                </StyledTableCell>
                <StyledTableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Box" />{" "}
                </StyledTableCell>

                {!readOnly && (
                  <StyledTableCell
                    align="center"
                    className={classes.titleTable}
                  >
                    <FormattedMessage id="Actions" />{" "}
                  </StyledTableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!value.trim()
                ? (rowsPerPage > 0
                    ? expedients.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : expedients
                  ).map((row) => (
                    <FuidBody
                      fuid={row}
                      fromFilesModule={fromFilesModule}
                      key={row.id}
                      readOnly={readOnly}
                    />
                  ))
                : data &&
                  (rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((row) => (
                    <FuidBody
                      fuid={row}
                      key={row.id}
                      readOnly={readOnly}
                    />
                  ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 13 * emptyRows }}>
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
            {/* --------- Footer para paginación -------- */}
            <CustomizedTableFooter count={count} />
          </Table>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

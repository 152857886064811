import { Types, IPagination, PaginationAction } from "../types/types";

const initialState: IPagination = {
  page: 0,
  rowsPerPage: 9,
};

export const paginationReducer = (
  state = initialState,
  action: PaginationAction
) => {
  switch (action.type) {
    case Types.setPage:
      return {
        ...state,
        page: action.payload,
      };
    case Types.setRowsPerPage:
      return {
        ...state,
        rowsPerPage: action.payload,
      };

    default:
      return state;
  }
};

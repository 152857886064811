import React from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import { useStyles } from "../../../shared/styles/useStyles";
import { uiCloseModalInfo } from "../../../redux/actions/uiActions";
import { setActiveTransfer } from "../../../redux/actions/documentManagmentActions";

export const TransfersComments = () => {
  const { activeTransfer } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(uiCloseModalInfo());
    dispatch(setActiveTransfer(null));
  };
  return (
    <Box m={1} mt={-1} mb={2}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6">
            <FormattedMessage id={"ReasonReject"} />:
          </Typography>
        </Grid>

        <Typography variant="body1">{activeTransfer?.rejectComment}</Typography>
      </Grid>
      <Box mt={2} display="flex" flexDirection="row-reverse">
        <Button className={clsx(classes.btn, classes.cancel)} onClick={onClose}>
          <FormattedMessage id="Ok" />
        </Button>
      </Box>
    </Box>
  );
};

import { Form, Formik } from "formik";
import { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import * as yup from "yup";

import { Box, Button, Card, Typography } from "@material-ui/core";

import { MyTextField } from "../custom/MyTextField";
import { Fonts } from "../../shared/constants/fonts/Fonts";
import { useStylesLogin } from "../../shared/styles/useStyles";
import logo from "../../assets/images/logo-1-01.png";

export const ForgotForm: FC = () => {
  const intl = useIntl();
  const classes = useStylesLogin();

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(`${intl.formatMessage({ id: "EmailFormat" })}`)
      .required(`${intl.formatMessage({ id: "EmailRequired" })}`),
  });

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      className={classes.appAuth}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Card className={classes.cardRoot}>
          <Box m={3} mt={0}>
            <Box textAlign="center">
              <img className={classes.imgRoot} src={logo} alt="crema-logo" />
            </Box>
            <Box
              mt={1}
              component="h2"
              fontWeight={Fonts.REGULAR}
              fontSize={{ xs: 24, xl: 26 }}
              color={"#495047"}
            >
              <FormattedMessage id="ForgetPassword" />
            </Box>
            <Box mt={1} mb={{ xs: 6, xl: 12 }}>
              <Typography id="font" className={classes.textSize}>
                <FormattedMessage id="ForgetPasswordTextOne" /> <br />
                <FormattedMessage id="ForgetPasswordTextTwo" />
              </Typography>
            </Box>

            <Formik
              validateOnChange={true}
              initialValues={{
                email: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting }) => {
                setSubmitting(true);
                // dispatch(startPasswordRecovery(data.email));
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form className={classes.form}>
                  <Box>
                    <MyTextField
                      placeholder={intl.formatMessage({ id: "EmailAddress" })}
                      name="email"
                      InputLabelProps={{ shrink: false }}
                      className={classes.textField}
                      variant="outlined"
                      autoComplete="off"
                    />
                  </Box>
                  <Box mt={6} mb={3}>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={isSubmitting}
                      className={classes.btnRoot}
                      type="submit"
                    >
                      <FormattedMessage id="SendEmail" />
                    </Button>
                  </Box>

                  <Box
                    textAlign="center"
                    fontSize={15}
                    className={classes.textGrey}
                  >
                    <FormattedMessage id="AlreadyHavePassword" /> &nbsp;
                    <Link to="/auth/login" className={classes.underlineNone}>
                      <FormattedMessage id="SignIn" />
                    </Link>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { makeStyles } from "@material-ui/core/styles";
import { Paper, IconButton, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { valueSearched, arraySearch } from "../../redux/actions/searchActions";
import { AppState } from "../../redux/reducers/rootReducer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

interface PropsSearch {
  data?: any[];
  isInModal?: boolean;
  onInputChange?(event: React.ChangeEvent<HTMLInputElement>): void;
}

export const CustomizedSearch = (props: PropsSearch) => {
  const classes = useStyles();
  const intl = useIntl();
  const { productionOffices } = useSelector((state: AppState) => state.trd);
  const dispatch = useDispatch();
  const { data, isInModal = false, onInputChange } = props;
  let array = data;

  useEffect(() => {
    dispatch(valueSearched(""));
    dispatch(arraySearch([]));
  }, [dispatch]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    dispatch(valueSearched(searchText));

    if (searchText.length > 0) {
      if (array) {
        const newData = array.filter((data) => {
          let search = "";
          //ENTITIES
          if (data.nit && data.businessName) {
            const businessName = data.businessName.toUpperCase();
            //const departamento = data.departamento.toUpperCase();
            search = data.nit + " " + businessName;
          }
          // Users
          if (data.username && data.document) {
            search = data.username.toUpperCase() + "  " + data.document;
          }
          //TRD
          if (data.code) {
            search = data.code;
          }
          if (data.serieCode && data.seriesName) {
            search = data.serieCode + " " + data.seriesName.toUpperCase();
          }
          // Offices
          if (data.name && data.idOffice) {
            search =
              data.name.toUpperCase() + "  " + data.version.toUpperCase();
          }
          // Production Offices
          if (data.code && data.name && data.abbreviation) {
            search =
              data.code.toUpperCase() +
              "  " +
              data.name.toUpperCase() +
              "  " +
              data.abbreviation.toUpperCase();
          }
          // Documental Type
          if (data.idDocumentalType) {
            search = data.code + " " + data.name.toUpperCase();
          }
          // Subseries
          if (data.idSubserie && data.centralFile && data.managementFile) {
            search = data.subSerieCode + " " + data.name.toUpperCase();
          }
          if (data.annotation) {
            search = data.id;
          }
          // Trámites
          if (data.procedureName) {
            search = data.procedureName.toUpperCase();
          }
          // Correspondence
          if (data.id && data.communicationType) {
            const dependency = productionOffices.find(
              (prod) =>
                prod.idProductionOffice === data.receivingDependency ||
                prod.idProductionOffice === data.producerDependency
            );

            search =
              data.id.toUpperCase() +
              " " +
              data.subject.toUpperCase() +
              " " +
              dependency?.name.toUpperCase() +
              " " +
              data.externalSender?.name.toUpperCase() +
              " " +
              data.addressee?.name.toUpperCase();
          }

          // EXPEDIENTE
          if (data.fileName && data.fileNumber) {
            search = data.fileNumber + " " + data.fileName.toUpperCase();
          }
          //TRANSFERENCIAS
          if (data.object) {
            search = data.object.toUpperCase();
          }
          const value = searchText.toUpperCase();
          return search.indexOf(value) > -1;
        });
        dispatch(arraySearch(newData));
      }
    } else {
      dispatch(arraySearch([]));
    }
  };
  return (
    <Paper className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={`${intl.formatMessage({ id: "Search" })} ...`}
        inputProps={{ "aria-label": "search" }}
        onChange={
          isInModal && onInputChange ? onInputChange : handleInputChange
        }
      />
      <IconButton className={classes.iconButton} aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

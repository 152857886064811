import {
  Box,
  CircularProgress,
  createTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { counter } from "../../../helpers/count";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { FormattedMessage } from "react-intl";
import { Colors } from "../../../shared/constants/themes/Colors";
import { CustomizedTableFooter } from "../../custom/CustomizedTableFooter";
import { RecordBody } from "./RecordBody";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { startLoadExpedientsMilestones, startLoadMilestones } from "../../../redux/actions/correspondenceActions";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});
interface props {
  id: string;
  idEntity: string;
  isFuid?:boolean,
  fromFileModule?:boolean
}
export const RecordTab: FC<props> = ({ id, idEntity,fromFileModule,isFuid }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, value } = useSelector((state: AppState) => state.search);
  const [loading, setLoading] = useState(false);

  // CARGAR LOS MILESTONES
  useEffect(() => {
    const dispatchMilestones = async () => {
      setLoading(true);
      if(!isFuid){
        await dispatch(startLoadMilestones(idEntity, id));
      }else{
        await dispatch( startLoadExpedientsMilestones(idEntity,id,fromFileModule?true:false) )

      }
      setLoading(false);
    };
    if (id) {
      dispatchMilestones();
    }
  }, [idEntity, id, dispatch, isFuid, fromFileModule]);

  const { milestones } = useSelector((state: AppState) => state.correspondence);

  // Paginación
  const { page, rowsPerPage } = useSelector(
    (state: AppState) => state.pagination
  );
  const count = counter(value, milestones, data);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, milestones.length - page * rowsPerPage);

  if (loading) {
    return (
      <Box style={{ display: "flex", justifyContent: "center" }} mt={3}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper} style={{ paddingTop: "10px" }}>
        {milestones.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 200 }}
            className={classes.title}
          >
            <InfoOutlinedIcon style={{ fontSize: 30, color: Colors.ACCENT }} />
            <FormattedMessage id={"NoMilestone"} />
          </Box>
        ) : (
          <Table
            className={classes.table}
            size="small"
            style={{ tableLayout: "auto" }}
            aria-label="a dense table"
          >
            <TableHead className={classes.titleTable}>
              <TableRow>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Milestone" />{" "}
                </TableCell>
                <TableCell align="center" className={classes.titleTable}>
                  <FormattedMessage id="Date" />{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!value.trim()
                ? (rowsPerPage > 0
                    ? milestones.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : milestones
                  ).map((procedure, index) => (
                    <RecordBody key={index} settle={procedure} />
                  ))
                : data &&
                  (rowsPerPage > 0
                    ? data.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : data
                  ).map((procedure) => (
                    <RecordBody key={procedure.id} settle={procedure} />
                  ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 13 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {/* --------- Footer para paginación -------- */}
            <CustomizedTableFooter count={count} />
          </Table>
        )}
      </TableContainer>
    </ThemeProvider>
  );
};

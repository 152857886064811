import { Redirect, Route, Switch } from "react-router";
import { UsersScreen } from "../pages/admin/UsersScreen";
import { TRDScreen } from "../pages/admin/TRDScreen";
import { OrganizationChartScreen } from "../pages/admin/OrganizationChartScreen";
import { ConsecutiveSettled } from "../pages/admin/ConsecutiveSettled";

export const AdminDashboarRouter = () => {
  return (
    <div>
      <Switch>
        <Route path="/admin/dashboard/users" component={UsersScreen} />
        <Route path="/admin/dashboard/trd" component={TRDScreen} />
        <Route
          path="/admin/dashboard/organizationchart"
          component={OrganizationChartScreen}
        />
        <Route
          path="/admin/dashboard/parameterization"
          component={ConsecutiveSettled}
        />
        <Redirect to="/admin/dashboard/users" />
      </Switch>
    </div>
  );
};

import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { CustomizedSearch } from "../../components/custom/CustomizedSearch";
import CustomizedDialog from "../../components/custom/CustomizedDialog";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import { useStyles } from "../../shared/styles/useStyles";
import {
  uiOpenModalAdd,
  uiCloseModalAdd,
  uiCloseModalSee,
  uiCloseSuccessAlert,
  uiCloseErrorAlert,
} from "../../redux/actions/uiActions";
import { AppState } from "../../redux/reducers/rootReducer";
import { TendersForm } from "../../components/correspondence-module/tenders/TendersForm";
import { TendersTable } from "../../components/correspondence-module/tenders/TendersTable";
import { ProponentForm } from "../../components/correspondence-module/tenders/ProponentForm";
import { MyAlert } from "../../components/custom/MyAlert";
import { setActiveTender } from "../../redux/actions/correspondenceActions";
import { uiCloseModalEdit } from "../../redux/actions/uiActions";

export const TendersScreen = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { activeTender } = useSelector(
    (state: AppState) => state.correspondence
  );
  const {
    modalAddOpen,
    modalEditOpen,
    modalSeeOpen,
    successAlert,
    successMsg,
    errorAlert,
  } = useSelector((state: AppState) => state.ui);

  const onClose = () => {
    dispatch(uiCloseModalSee());
    dispatch(uiCloseModalEdit());
    dispatch(setActiveTender(null));
  };

  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box>
        <Box mb={3} fontSize={20}>

          <Grid container>
            <Grid item xs={5}>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                className={classes.btnAdd}
                type="button"
                onClick={() => dispatch(uiOpenModalAdd())}
              >
                <AddOutlinedIcon />
                <FormattedMessage id="AddNewProcess" />
              </Button>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={5}>
              <CustomizedSearch />
            </Grid>
          </Grid>
        </Box>

        <TendersTable />

        <CustomizedDialog
          open={modalAddOpen}
          cancelBtn={true}
          onDeny={() => dispatch(uiCloseModalAdd())}
          title={`${intl.formatMessage({ id: "NewProcess" })}`}
          content={<TendersForm />}
          textButton="Settle"
        />
        <CustomizedDialog
          open={modalEditOpen}
          cancelBtn={true}
          onDeny={onClose}
          title={`${intl.formatMessage({ id: "EditProcess" })}`}
          content={<TendersForm />}
          textButton="Accept"
        />
        <CustomizedDialog
          open={modalSeeOpen}
          cancelBtn={false}
          onDeny={onClose}
          title={`${intl.formatMessage({ id: "ProcessNo." })} ${
            activeTender?.id
          }`}
          content={<ProponentForm />}
          textButton="Accept"
        />
        {successMsg && (
          <MyAlert
            open={successAlert}
            typeAlert="success"
            message={successMsg}
            time={2000}
            handleClose={() => dispatch(uiCloseSuccessAlert())}
          />
        )}

        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={"SavingDataError"}
          time={2000}
          handleClose={() => dispatch(uiCloseErrorAlert())}
        />
      </Box>
    </AppAnimate>
  );
};

import { FC } from "react";

import { TableRow, TableCell } from "@material-ui/core";
import { CustomizedIcons } from "../../custom/CustomizedIcons";
import { useDispatch } from "react-redux";
import {
  uiOpenModalSee,
  uiOpenModalEdit,
} from "../../../redux/actions/uiActions";
import { ITenders } from "../../../interfaces/Correspondence";
import { firebase } from "../../../config/firebase/firebase-config";
import moment from "moment";
import { setActiveTender } from "../../../redux/actions/correspondenceActions";

interface props {
  process: ITenders;
  readOnly?: boolean;
}

export const TendersBody: FC<props> = ({ process, readOnly = false }) => {
  const dispatch = useDispatch();

  const onEdit = () => {
    dispatch(setActiveTender(process));
    dispatch(uiOpenModalEdit());
  };

  const onDelete = () => {};

  const onAddProponents = () => {
    dispatch(setActiveTender(process));
    dispatch(uiOpenModalSee());
  };

  const date = moment(
    (process.closingDate as firebase.firestore.Timestamp).toDate()
  ).format("YYYY/MM/DD hh:mm a");

  return (
    <TableRow key={process.id}>
      <TableCell component="th" scope="row" align="center">
        {process.id}
      </TableCell>
      <TableCell align="center">{date}</TableCell>
      <TableCell align="center">{process.object}</TableCell>
      {!readOnly && (
        <TableCell align="center">
          <CustomizedIcons
            editIcon
            proponentsIcon
            deleteIcon
            onEdit={onEdit}
            onAddProponents={onAddProponents}
            onDelete={onDelete}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

import clsx from "clsx";
import memoize from "fast-memoize";
import { Form, Formik } from "formik";
import { useState, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";

import { MyTextField } from "../../custom/MyTextField";
import { AppState } from "../../../redux/reducers/rootReducer";
import { uiCloseModalAction } from "../../../redux/actions/uiActions";
import { useStyles } from "../../../shared/styles/useStyles";
import { Autocomplete } from "@material-ui/lab";
import {
  IRequestProjection,
  IUserCorrespondence,
} from "../../../interfaces/Correspondence";

import { User } from "../../../interfaces/Users";
import { startScreeningRequest } from "../../../redux/actions/documentManagmentActions";

export const RequestProjectionForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { productionOffices } = useSelector((state: AppState) => state.trd);
  const { users } = useSelector((state: AppState) => state.users);
  const [addresseeSelected, setAddresseeSelected] =
    useState<IUserCorrespondence | null>(null);
  const [errorAddressee, setErrorAddressee] = useState(false);

  const initialValues = {
    receivingDependency: activeCorrespondence
      ? activeCorrespondence.receivingDependency
      : "",
    addressee: { id: "", name: "" },
    comment: "",
  };

  const validationSchema = yup.object({
    receivingDependency: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
    addressee: yup.object(),
    comment: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });

  const onClose = () => {
    dispatch(uiCloseModalAction());
  };

  const handleSelectAddressees = (value: any) => {
    !value ? setErrorAddressee(true) : setErrorAddressee(false);

    value ? setAddresseeSelected(value) : setAddresseeSelected(null);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUsers = useCallback(
    memoize((dependency: string) => {
      const filteredUsers: User[] = (users as User[]).filter((el) =>
        el.offices.toString().includes(dependency)
      );
      const arrayUsers: IUserCorrespondence[] = [];

      filteredUsers.forEach((user: User) => {
        arrayUsers.push({
          id: user.id.toString(),
          name:
          user.firstName +
          " " +
          user.secondName +
          " " +
          user.firstLastName +
          " " +
          user.secondLastName,
        });
      });

      return arrayUsers;
    }),
    []
  );
  const handleChangeDependency = (value: string, setValue: Function) => {
    setValue("receivingDependency", value);
    setAddresseeSelected(null);
  };

  return (
    <Box m={2} mt={-1} mb={2}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (!errorAddressee) {
            values.addressee = addresseeSelected as IUserCorrespondence;
            setSubmitting(true);
            if (activeCorrespondence) {
              const objRequest: IRequestProjection = {
                comment: values.comment,
                dependency: values.receivingDependency,
                officer: values.addressee.name,
                officerId: values.addressee.id,
              };
              // TODO: Disparar acción
              await dispatch(
                startScreeningRequest(activeCorrespondence.id, objRequest)
              );
            }
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="SelectDependency" />:
                </label>
                <TextField
                  name="receivingDependency"
                  variant="outlined"
                  select
                  value={values.receivingDependency}
                  className={classes.myTextFieldRoot}
                  onChange={(e) =>
                    handleChangeDependency(e.target.value as any, setFieldValue)
                  }
                  size="small"
                >
                  {productionOffices.map((productionOffice) => (
                    <MenuItem
                      key={productionOffice.idProductionOffice}
                      value={productionOffice.idProductionOffice}
                    >
                      {productionOffice.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="SelectOfficerToProjection" />:
                </label>
                <Autocomplete
                  id="addressee"
                  value={addresseeSelected}
                  options={getUsers(values.receivingDependency)}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderOption={(option) => <>{option.name}</>}
                  onChange={(event: any, value: IUserCorrespondence | null) =>
                    handleSelectAddressees(value)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      name="addressee"
                      className={classes.myTextFieldRoot}
                      helperText={
                        errorAddressee &&
                        `${intl.formatMessage({ id: "RequiredFile" })}`
                      }
                      error={errorAddressee}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="Comment" />*
                </label>
                <MyTextField
                  name="comment"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                  multiline
                  rows={3}
                  // disabled={details}
                />
              </Grid>

              <Box mt={2} display="flex" flexDirection="row-reverse" width={1}>
                {!isSubmitting ? (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id="Save" />
                  </Button>
                ) : (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="button"
                    disabled={true}
                  >
                    <CircularProgress className={classes.btnLoading} />
                  </Button>
                )}
                <Button
                  className={clsx(classes.btn, classes.cancel)}
                  onClick={onClose}
                >
                  <FormattedMessage id="Cancel" />
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  Divider,
  Paper,
  Box,
  Grid,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { useStyles } from "../../../../shared/styles/useStyles";
import {
  uiOpenModalAdd,
  uiCloseModalAdd,
  uiCloseSuccessAlert,
  uiCloseModalEdit,
  uiCloseModalDelete,
  uiCloseErrorAlert,
  setSuccessMsg,
  setErrorMsg,
} from "../../../../redux/actions/uiActions";
import { CustomizedSearch } from "../../../custom/CustomizedSearch";
import CustomizedDialog from "../../../custom/CustomizedDialog";
import { AppState } from "../../../../redux/reducers/rootReducer";
import { SubseriesTable } from "./SubseriesTable";
import { SubseriesForm } from "./SubseriesForm";
import { MyAlert } from "../../../custom/MyAlert";
import { setActiveSubserie } from "../../../../redux/actions/trdActions";
import { ConfirmDelete } from "../../../ui/shared/ConfirmDelete";
import { ITrdSubSerie } from "../../../../redux/types/types";

export const SubseriesTab = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const {
    modalAddOpen,
    modalEditOpen,
    successAlert,
    successMsg,
    errorMsg,
    errorAlert,
    modalDeleteOpen,
  } = useSelector((state: AppState) => state.ui);

  const { series, productionOffices, versions, subSeries } = useSelector(
    (state: AppState) => state.trd
  );

  const [selected, setSelected] = useState({
    version: "0",
    productionOffice: "0",
    series: "0",
  });

  const [subseriesFiltered, setSubseriesFiltered] = useState<ITrdSubSerie[]>(
    []
  );

  const verifySelectedSubserie = (subSerieId: string, option: string) => {
    if (option !== "0") {
      return subSerieId === option;
    } else {
      return true;
    }
  };

  useEffect(() => {
    //HACER FILTROS
    const allSubseries = [...subSeries];
    const filteredResp = allSubseries.filter(
      (subserie) =>
        verifySelectedSubserie(subserie.idVersion, selected.version) &&
        verifySelectedSubserie(
          subserie.idProductionOffice,
          selected.productionOffice
        ) &&
        verifySelectedSubserie(subserie.idSerie, selected.series)
    );
    setSubseriesFiltered(filteredResp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const onDenySetSubSerie = () => {
    dispatch(uiCloseModalAdd());
    dispatch(uiCloseModalEdit());
    dispatch(uiCloseModalDelete());
    dispatch(setActiveSubserie(null));
  };

  const closeAlert = () => {
    dispatch(uiCloseSuccessAlert());
    dispatch(uiCloseErrorAlert());
    dispatch(setSuccessMsg(null));
    dispatch(setErrorMsg(null));
  };

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected({ ...selected, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Divider variant="fullWidth" />
      <Paper square className={classes.paper}>
        <Box mb={3}>
          <Box style={{ height: "5px" }} />
          <Box m={2}>
            <Grid container style={{ marginBottom: "20px" }}>
              <Grid item xs={5}>
                <Button
                  style={{ fontSize: 13 }}
                  size="large"
                  variant="contained"
                  color="secondary"
                  className={classes.btnAdd}
                  type="button"
                  onClick={() => dispatch(uiOpenModalAdd())}
                >
                  <AddOutlinedIcon />
                  <FormattedMessage id="AddNewSubseries" />
                </Button>
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={5}>
                <CustomizedSearch
                  data={
                    selected.version === "0" &&
                    selected.productionOffice === "0" &&
                    selected.series === "0"
                      ? subSeries
                      : subseriesFiltered
                  }
                />
              </Grid>

              <Box width={1} display="flex" mt={1}>
                <Box width={"14%"} mr={2} mt={2} style={{ fontSize: 14 }}>
                  <FormattedMessage id="Version" />:
                </Box>
                <Box width={"70%"}>
                  <TextField
                    name="version"
                    variant="outlined"
                    select
                    size="small"
                    value={selected.version}
                    className={classes.myTextFieldRoot}
                    onChange={handleSelect}
                    //   disabled={ active ? true : false}
                  >
                    <MenuItem value={"0"}>
                      <FormattedMessage id={"All"} />
                    </MenuItem>
                    {versions.map((version) => (
                      <MenuItem
                        key={version.idVersion}
                        value={version.idVersion}
                      >
                        {version.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>

              <Box width={1} display="flex" mt={1}>
                <Box width={"14%"} mr={2} mt={2} style={{ fontSize: 14 }}>
                  <FormattedMessage id="ProductionOffice" />:
                </Box>
                <Box width={"70%"}>
                  <TextField
                    name="productionOffice"
                    variant="outlined"
                    select
                    size="small"
                    value={selected.productionOffice}
                    className={classes.myTextFieldRoot}
                    onChange={handleSelect}
                    //   disabled={ active ? true : false}
                  >
                    <MenuItem value={"0"}>
                      <FormattedMessage id={"All"} />
                    </MenuItem>
                    {productionOffices.map((productionOffice) => (
                      <MenuItem
                        key={productionOffice.idProductionOffice}
                        value={productionOffice.idProductionOffice}
                      >
                        {productionOffice.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>

              <Box width={1} display="flex" mt={1}>
                <Box width={"14%"} mr={2} mt={2} style={{ fontSize: 14 }}>
                  <FormattedMessage id="Series" />:
                </Box>
                <Box width={"70%"}>
                  <TextField
                    name="series"
                    variant="outlined"
                    select
                    size="small"
                    value={selected.series}
                    className={classes.myTextFieldRoot}
                    onChange={handleSelect}
                    //   disabled={ active ? true : false}
                  >
                    <MenuItem value={"0"}>
                      <FormattedMessage id={"All"} />
                    </MenuItem>
                    {series.map((serie) => (
                      <MenuItem key={serie.idSerie} value={serie.idSerie}>
                        {serie.seriesName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Box>
            </Grid>
            <SubseriesTable
              subseries={
                selected.version === "0" &&
                selected.productionOffice === "0" &&
                selected.series === "0"
                  ? subSeries
                  : subseriesFiltered
              }
            />

            <CustomizedDialog
              open={modalAddOpen}
              cancelBtn={true}
              onDeny={onDenySetSubSerie}
              title={`${intl.formatMessage({ id: "NewSubseries" })}`}
              content={<SubseriesForm />}
              textButton="Accept"
            />

            <CustomizedDialog
              open={modalEditOpen}
              cancelBtn={true}
              onDeny={onDenySetSubSerie}
              title={`${intl.formatMessage({ id: "EditSubserie" })}`}
              content={<SubseriesForm />}
              textButton="Accept"
            />

            <CustomizedDialog
              open={modalDeleteOpen}
              cancelBtn={true}
              onDeny={onDenySetSubSerie}
              title={`${intl.formatMessage({ id: "DeleteSubserie" })}`}
              content={
                <ConfirmDelete
                  title={`${intl.formatMessage({
                    id: "QuestionDeleteSubserie",
                  })}`}
                />
              }
              textButton="Accept"
            />

            <Box style={{ height: "20px" }} />
          </Box>
          {successMsg && (
            <>
              <MyAlert
                open={successAlert}
                typeAlert="success"
                message={successMsg}
                time={2000}
                handleClose={closeAlert}
              />
            </>
          )}

          {errorMsg && (
            <>
              <MyAlert
                open={errorAlert}
                typeAlert="error"
                message={errorMsg}
                time={3000}
                handleClose={closeAlert}
              />
            </>
          )}
        </Box>
      </Paper>
    </>
  );
};

import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Box, Grid, MenuItem, TextField, Paper } from "@material-ui/core";

import { CustomizedSearch } from "../../components/custom/CustomizedSearch";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import { useStyles } from "../../shared/styles/useStyles";
import { PqrsTable } from "../../components/correspondence-module/pqrs/PqrsTable";
import { InternalCorrespondenceStatus } from "../../enums/enums";
import { CustomizedWindow } from "../../components/custom/CustomizedWindow";
import { AppState } from "../../redux/reducers/rootReducer";
import { uiCloseModalAdd } from "../../redux/actions/uiActions";
import { setActiveCorrespondence } from "../../redux/actions/correspondenceActions";
import { PqrsDetails } from "../../components/correspondence-module/pqrs/PqrsDetails";
import { arraySearch } from "../../redux/actions/searchActions";
import { ICorrespondence } from "../../interfaces/Correspondence";
import { getPercentageDiff } from "../../helpers/getPercentageDiff";

export const PqrsScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [values, setValues] = useState({ trafficLight: 0, state: 0 });
  const { modalAddOpen } = useSelector((state: AppState) => state.ui);
  const { data } = useSelector((state: AppState) => state.search);
  const { correspondence, paramResponseTime } = useSelector(
    (state: AppState) => state.correspondence
  );
  const pqrs = correspondence.filter((el) => el.answeredDate === null);
  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.correspondence
  );

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  const verifyFilterValues = (
    correspondence: ICorrespondence,
    option: any,
    isDiff = false
  ) => {
    const { communicationType, createAt, internalState } = correspondence;
    if (option !== 0) {
      if (isDiff) {
        const paramNameDays = communicationType?.option + "Days";
        //@ts-ignore
        const daysAvailableDocument = paramResponseTime[paramNameDays];
        const { percentage, diff } = getPercentageDiff(
          new Date(),
          createAt.toDate(),
          daysAvailableDocument
        );

        switch (option) {
          case 1:
            return percentage <= 60;
          case 2:
            return percentage === 70;
          case 3:
            return diff === 3;
          case 4:
            return percentage >= 100;
        }
      }

      switch (option) {
        case InternalCorrespondenceStatus.RESPONSE_ISSUED_ONTIME:
          return (
            internalState ===
            InternalCorrespondenceStatus.RESPONSE_ISSUED_ONTIME
          );
        case InternalCorrespondenceStatus.EXTEMPORANEOUS_RESPONSE:
          return (
            internalState ===
            InternalCorrespondenceStatus.EXTEMPORANEOUS_RESPONSE
          );
        case InternalCorrespondenceStatus.NO_ANSWER_REQUIRED:
          return (
            internalState === InternalCorrespondenceStatus.NO_ANSWER_REQUIRED
          );
        case InternalCorrespondenceStatus.UNANSWERED:
          return internalState === InternalCorrespondenceStatus.UNANSWERED;
      }
    } else {
      return true;
    }
  };
  useEffect(() => {
    const { state, trafficLight } = values;
    if (state === 0 && trafficLight === 0) {
      dispatch(arraySearch([]));
    }
    const filteredPqrs = pqrs.filter(
      (pqr) =>
        verifyFilterValues(pqr, trafficLight, true) &&
        verifyFilterValues(pqr, state)
    );
    dispatch(arraySearch(filteredPqrs));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, values]);

  const onDeny = () => {
    dispatch(uiCloseModalAdd());
    dispatch(setActiveCorrespondence(null));
  };
  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Paper square className={classes.paper}>
        <Box mb={3} p={3} fontSize={20}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <label className={classes.typography}>
                <FormattedMessage id="TrafficLight" />:
              </label>
              <TextField
                name="trafficLight"
                variant="outlined"
                value={values.trafficLight}
                onChange={handleChange}
                select
                className={classes.myTextFieldRoot}
                size="small"
              >
                <MenuItem value={0}>
                  <FormattedMessage id="All" />
                </MenuItem>
                <MenuItem value={1}>
                  <FormattedMessage id="TrafficLightOpt1" />
                </MenuItem>
                <MenuItem value={2}>
                  <FormattedMessage id="TrafficLightOpt2" />
                </MenuItem>
                <MenuItem value={3}>
                  <FormattedMessage id="TrafficLightOpt3" />
                </MenuItem>
                <MenuItem value={4}>
                  <FormattedMessage id="TrafficLightOpt4" />
                </MenuItem>
                <MenuItem value={5}>
                  <FormattedMessage id="TrafficLightOpt5" />
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <label className={classes.typography}>
                <FormattedMessage id="State" />:
              </label>
              <TextField
                name="state"
                variant="outlined"
                value={values.state}
                onChange={handleChange}
                select
                className={classes.myTextFieldRoot}
                size="small"
              >
                <MenuItem value={0}>
                  <FormattedMessage id="All" />
                </MenuItem>
                <MenuItem
                  value={InternalCorrespondenceStatus.RESPONSE_ISSUED_ONTIME}
                >
                  <FormattedMessage id="ResponseIssuedOnTime" />
                </MenuItem>
                <MenuItem
                  value={InternalCorrespondenceStatus.EXTEMPORANEOUS_RESPONSE}
                >
                  <FormattedMessage id="ExtemporaneousResponse" />
                </MenuItem>
                <MenuItem value={InternalCorrespondenceStatus.UNANSWERED}>
                  <FormattedMessage id="Unanswered" />
                </MenuItem>
                <MenuItem
                  value={InternalCorrespondenceStatus.NO_ANSWER_REQUIRED}
                >
                  <FormattedMessage id="NoAnswerRequired" />
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={5}>
              <Box sx={{ marginTop: "30px" }}>
                <CustomizedSearch
                  data={
                    values.state === 0 && values.trafficLight === 0
                      ? pqrs
                      : data
                  }
                />
              </Box>
            </Grid>
          </Grid>

          <Box mt={2}>
            <PqrsTable />
          </Box>

          <CustomizedWindow
            open={modalAddOpen}
            content={<PqrsDetails />}
            onDeny={onDeny}
            title={`${intl.formatMessage({ id: "Settled" })} No. ${
              activeCorrespondence?.id
            }`}
          />
        </Box>
      </Paper>
    </AppAnimate>
  );
};

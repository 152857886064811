import clsx from "clsx";
import { Form, Formik } from "formik";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

import { ICommunicationType } from "../../../interfaces/Correspondence";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useStyles } from "../../../shared/styles/useStyles";
import {
  uiCloseModalAction,
  uiCloseErrorAlert,
} from "../../../redux/actions/uiActions";
import { startReclassifySettled } from "../../../redux/actions/documentManagmentActions";
import { MyAlert } from "../../custom/MyAlert";

export const ReclassifyForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const { paramsProcedure } = useSelector(
    (state: AppState) => state.correspondence
  );
  const [communicationTypeSelected, setCommunicationTypeSelected] =
    useState<ICommunicationType | null>(
      activeCorrespondence ? activeCorrespondence.communicationType : null
    );
  const { errorAlert, errorMsg } = useSelector((state: AppState) => state.ui);
  const [errorCommunicationType, setErrorCommunicationType] = useState(false);

  const initialValues = {
    communicationType: { isParam: false, option: "" },
  };

  const validationSchema = yup.object({ communicationType: yup.object() });

  const onClose = () => {
    dispatch(uiCloseModalAction());
  };

  //Vienen desde bd en la parte de parametrizacion de tiempos de respueta
  const optionsCommunicationType: ICommunicationType[] = [
    { isParam: true, option: "RightOfPetition" },
    { isParam: true, option: "Petition" },
    { isParam: true, option: "Complaint" },
    { isParam: true, option: "Claim" },
    { isParam: true, option: "Suggestion" },
    {
      isParam: true,
      option: "RequestForInformation",
    },
    { isParam: true, option: "Guardianship" },
    {
      isParam: false,
      option: "CommunicationOfControlEntities",
    },
    {
      isParam: false,
      option: "SummonsToJudicialProceedings",
    },
    {
      isParam: false,
      option: "Answer",
    },
    {
      isParam: false,
      option: "InternalProcedure",
    },
    {
      isParam: false,
      option: "AdministrativeActs",
    },
    {
      isParam: false,
      option: "CommunicationTypeReports",
    },
    {
      isParam: false,
      option: "CollectionAccounts",
    },
    {
      isParam: false,
      option: "Notification",
    },
    {
      isParam: false,
      option: "CommunicationTypeAnnotation",
    },
    {
      isParam: false,
      option: "Invitation",
    },
    { isParam: false, option: "Consultation" },
    { isParam: false, option: "Circular" },
    { isParam: false, option: "Memorandum" },
    { isParam: false, option: "Letter" },
    { isParam: false, option: "InternalNote" },
  ];
  paramsProcedure.map((item: any) => {
    return optionsCommunicationType.push({
      isParam: true,
      option: item.procedureName,
      responseTime: item.responseTime,
    });
  });

  const handleSelectCommunicationType = (value: ICommunicationType | null) => {
    value ? setErrorCommunicationType(false) : setErrorCommunicationType(true);

    setCommunicationTypeSelected(value);
  };

  return (
    <Box m={2} mt={-1} mb={2}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          if (!errorCommunicationType) {
            communicationTypeSelected &&
              (values.communicationType = communicationTypeSelected);
            setSubmitting(true);
            await dispatch(
              startReclassifySettled(values.communicationType, intl)
            );
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <label className="form-text">
                  <FormattedMessage id="SelectCorrectTypeCommunication" />:
                </label>

                <Autocomplete
                  id="communicationType"
                  options={optionsCommunicationType}
                  disableCloseOnSelect
                  value={communicationTypeSelected}
                  getOptionLabel={(option) =>
                    intl.formatMessage({ id: `${option.option}` })
                  }
                  getOptionSelected={(option, value) =>
                    option.option === value.option
                  }
                  // @ts-ignore
                  onChange={(event: any, value: ICommunicationType | null) =>
                    handleSelectCommunicationType(value)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      name="communicationType"
                      className={classes.myTextFieldRoot}
                      helperText={
                        errorCommunicationType &&
                        `${intl.formatMessage({ id: "RequiredFile" })}`
                      }
                      error={errorCommunicationType}
                      FormHelperTextProps={{
                        className: classes.helperText,
                      }}
                    />
                  )}
                />
              </Grid>

              <Box mt={2} display="flex" flexDirection="row-reverse" width={1}>
                {!isSubmitting ? (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="submit"
                    disabled={isSubmitting}
                  >
                    <FormattedMessage id="Save" />
                  </Button>
                ) : (
                  <Button
                    className={clsx(classes.btn, classes.save)}
                    autoFocus
                    type="button"
                    disabled={true}
                  >
                    <CircularProgress className={classes.btnLoading} />
                  </Button>
                )}
                <Button
                  className={clsx(classes.btn, classes.cancel)}
                  onClick={onClose}
                >
                  <FormattedMessage id="Cancel" />
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>

      {errorMsg && (
        <MyAlert
          open={errorAlert}
          typeAlert="error"
          message={errorMsg}
          time={2000}
          handleClose={() => dispatch(uiCloseErrorAlert())}
        />
      )}
    </Box>
  );
};

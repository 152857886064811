import moment from "moment";
import { IUserCorrespondence } from "../interfaces/Correspondence";
import { createTheme } from "@material-ui/core";

export const getCopyArrayOrObject = (arrayOrObject: any) => {
  return JSON.parse(JSON.stringify(arrayOrObject));
};
export const joinUserNames = (users: IUserCorrespondence[]) => {
  let finalNames = "";
  users.forEach((user, index) => {
    const str = users.length - 1 === index ? user.name : user.name + ",";
    finalNames += str;
  });
  return finalNames;
};

export function validateEmail(email: string): boolean {
  // Expresión regular para validar correo electrónico
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return email.match(emailRegex) ? true : false;
}

export const formatDate = (createAt: any) => {
  return moment(createAt.toDate()).format("YYYY/MM/DD hh:mm a");
};

export const checkEmailStatus = (status: string) => {
  if (
    status === "sent" ||
    status === "open" ||
    status === "click" ||
    status === "bounce" ||
    status === "spam" ||
    status === "blocked" ||
    status === "unsub"
  ) {
    return true;
  }
  return false;
};

export const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 11,
  },
});

export const getCurrentDateAndHourColombia = () => {
  return new Date().toLocaleString("es-CO", {
    hour12: false,
    timeZone: "America/Bogota",
  });
};

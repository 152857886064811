import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Box, Grid, Button } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

import { CustomizedSearch } from "../../components/custom/CustomizedSearch";
import CustomizedDialog from "../../components/custom/CustomizedDialog";
import { LoansForm } from "../../components/file-module/loans/LoansForm";
import { LoansTable } from "../../components/file-module/loans/LoansTable";
import AppAnimate from "../../components/ui/AppAnimate/AppAnimate";
import { uiOpenModalAdd, uiCloseModalAdd, uiCloseModalEdit } from "../../redux/actions/uiActions";
import { AppState } from "../../redux/reducers/rootReducer";
import { useStyles } from "../../shared/styles/useStyles";
import { setActiveLoan } from "../../redux/actions/fileModuleActions";

interface Props {
  component: "Physical" | "Digital";
}

export const LoansScreen = ({ component }: Props) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { modalAddOpen, modalEditOpen } = useSelector(
    (state: AppState) => state.ui
  );
  const onDeny = () => {
    dispatch( setActiveLoan(null) )
    dispatch(uiCloseModalAdd());
    dispatch( uiCloseModalEdit() )
  };
  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <Box>
        <Box mb={3} fontSize={20}>
          <Grid container>
            <Grid item xs={6}>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                className={classes.btnAdd}
                type="button"
                onClick={() => dispatch(uiOpenModalAdd())}
                style={{ fontSize: 14, width: "22rem" }}
              >
                <AddOutlinedIcon />
                {component === "Physical" ? (
                  <FormattedMessage id="NewPhysicalLoan" />
                ) : (
                  <FormattedMessage id="NewDigitalLoan" />
                )}
              </Button>
            </Grid>

            <Grid item xs={1} />
            <Grid item xs={5}>
              <CustomizedSearch />
            </Grid>
          </Grid>
        </Box>

        <LoansTable component={component} />

        <CustomizedDialog
          open={modalAddOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={
            component === "Physical"
              ? `${intl.formatMessage({ id: "PhysicalFileLoan" })}`
              : `${intl.formatMessage({ id: "DigitalFileLoan" })}`
          }
          content={<LoansForm component={component} />}
          textButton="Settle"
        />
        <CustomizedDialog
          open={modalEditOpen}
          cancelBtn={true}
          onDeny={onDeny}
          title={
            component === "Physical"
              ? `${intl.formatMessage({ id: "PhysicalFileLoan" })}`
              : `${intl.formatMessage({ id: "DigitalFileLoan" })}`
          }
          content={<LoansForm component={component} edit />}
          textButton="Settle"
        />
      </Box>
    </AppAnimate>
  );
};

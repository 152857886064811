import { useStyles } from "../../../shared/styles/useStyles";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import {
  AnswerTimeCorrespondence,
  ICommunicationType,
  ICorrespondence,
} from "../../../interfaces/Correspondence";
import { FC, useEffect, useState } from "react";
import moment from "moment-business-days";
interface props {
  // documentDate: Date;
  paramResponse?: ICommunicationType | null;
  answer?: AnswerTimeCorrespondence | null;
  correspondence: ICorrespondence;
}

export const TrafficLight: FC<props> = ({
  paramResponse,
  answer,
  correspondence,
}) => {
  const classes = useStyles();
  const documentDate = correspondence.createAt.toDate();
  const { paramResponseTime, paramsProcedure }: any = useSelector(
    (state: AppState) => state.correspondence
  );

  let procedureFilter = paramsProcedure.filter(
    (item: any) =>
      item.procedureName === correspondence.communicationType?.option
  );

  const [color, setColor] = useState("");
  const paramNameDays = paramResponse?.option + "Days";
  const paramNameTypeDays = paramResponse?.option + "TypeDays";
  const extensionDays = correspondence.finalExtensionDate
    ? moment(correspondence.finalExtensionDate.toDate()).diff(
        documentDate,
        "days"
      )
    : 0;

  /**
   * Días disponibles para responder
   */
  let daysAvailableDocument =
    correspondence && correspondence.responseTimeDays
      ? correspondence.responseTimeDays
      : paramResponseTime[paramNameDays];

  daysAvailableDocument = daysAvailableDocument + extensionDays;

  let typeDays = paramResponseTime[paramNameTypeDays];
  typeDays =
    procedureFilter.length === 0 ? typeDays : procedureFilter[0].typeDays;

  typeDays =
    correspondence.communicationType?.option ===
    ("CommunicationOfControlEntities" || "SummonsToJudicialProceedings")
      ? "BUSINESSDAYS"
      : typeDays;

  const dateDocument = moment(documentDate);
  const dateCurrent = moment();

  const minute = 1000 * 60;

  const hour = minute * 60;
  const day = hour * 24;
  let createDateDocument: Date = new Date();
  let answerDateDocument: Date = new Date();

  if (answer && answer.answerDate) {
    createDateDocument = answer.createDate ? answer.createDate.toDate() : "";
    answerDateDocument = answer.answerDate ? answer.answerDate.toDate() : "";
  }

  /**
   * Obtener diferencia en días
   */
  const differenceBusinessDays = answer?.answer
    ? moment(createDateDocument).businessDiff(moment(answerDateDocument)) // Si tiene respuesta diferencia día que se creo vs día que se respondió
    : dateCurrent.businessDiff(dateDocument); // Else fecha actual, con la fecha que se creo el documento

  let responseTimeDays: any = correspondence.responseTimeDays;
  const percentageBusinessDaysProcedure = responseTimeDays
    ? (((100 * differenceBusinessDays) / responseTimeDays) as number)
    : 0;

  const percentageBusinessDays =
    correspondence.communicationType?.option ===
    ("CommunicationOfControlEntities" || "SummonsToJudicialProceedings")
      ? percentageBusinessDaysProcedure
      : (100 * differenceBusinessDays) / daysAvailableDocument;

  const differenceCalendarDays = answer?.answer
    ? Math.floor(
        moment(answerDateDocument).diff(moment(createDateDocument)) / day
      )
    : Math.floor(dateCurrent.diff(dateDocument) / day);
  const percentageCalendarDays =
    (100 * differenceCalendarDays) / daysAvailableDocument;

  useEffect(() => {
    const colorSetter = (percentage: number) => {
      if (percentage <= 60) {
        setColor(classes.circle_green);
      } else if (percentage > 60 && percentage <= 70) {
        setColor(classes.circle_yellow);
      } else if (percentage > 70 && percentage < 100) {
        setColor(classes.circle_orange);
      } else if (percentage >= 100) {
        setColor(classes.circle_red);
      }
    };
    if (typeDays === "BUSINESSDAYS") {
      colorSetter(percentageBusinessDays);
    } else if (typeDays === "CALENDAR") {
      colorSetter(percentageCalendarDays);
    } else {
      setColor(classes.circle_green);
    }
  }, [
    setColor,
    percentageCalendarDays,
    percentageBusinessDays,
    classes.circle_green,
    classes.circle_red,
    classes.circle_orange,
    classes.circle_yellow,
    classes.circle_gray,
    differenceBusinessDays,
    paramNameTypeDays,
    typeDays,
  ]);

  return <div className={color}></div>;
};

import React from "react";
import { Box, Button, CircularProgress, Grid } from "@material-ui/core";
import { Form, Formik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useStyles } from "../../../shared/styles/useStyles";
import clsx from "clsx";
import { MyTextField } from "../../custom/MyTextField";
import { IDeposits } from "../../../redux/types/types";
import { firebase } from "../../../config/firebase/firebase-config";
import { startAddNewDeposits } from "../../../redux/actions/documentManagmentActions";
import { AppState } from "../../../redux/reducers/rootReducer";

export const DepositForm = () => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const {user} = useSelector((state:AppState) => state.auth)

  let initialValues = {
    name: "",
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(`${intl.formatMessage({ id: "RequiredFile" })}`),
  });
 
  return (
    <Box m={1}>
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          if(user && user.idEntity && user.offices && user.offices.length>0 && user.username){
            const productionOfId = user.offices[0].split("_")[0]

            const depositObj:IDeposits = {
              id:firebase.firestore.Timestamp.now().toDate().getTime().toString(),
              createDate:firebase.firestore.Timestamp.now(),
              name:data.name,
              productionOffice:productionOfId,
              owner:{
                id:user.id.toString(),
                name:user.username
              }

            }
            await dispatch(startAddNewDeposits(user.idEntity,depositObj));
          }
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, handleChange, values }) => (
          <Form className={classes.input}>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <label className="form-text">
                  <FormattedMessage id="DepositName" />*
                </label>
                <MyTextField
                  name="name"
                  variant="outlined"
                  className={classes.myTextFieldRoot}
                />
              </Grid>
              
            </Grid>

            <Box mt={2} display="flex" flexDirection="row-reverse">
              {!isSubmitting ? (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="Add" />
                </Button>
              ) : (
                <Button
                  className={clsx(classes.btn, classes.save)}
                  autoFocus
                  type="button"
                  disabled={true}
                >
                  <CircularProgress className={classes.btnLoading} />
                </Button>
              )}
              
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

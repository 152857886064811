import { AppBar, Tab, Tabs } from "@material-ui/core";
import React, { FC, useRef } from "react";
import { useIntl } from "react-intl";
import { TabPanel, useStyles, a11yProps } from "../../custom/Tabs";
import { AnswersTab } from "../tabs/AnswersTab";
import { DocumentsTab } from "../tabs/DocumentsTab";
import { RecordTab } from "../tabs/RecordTab";
import { AnnotationsTab } from "../tabs/AnnotationsTab";
//import { VoBoTab } from "../tabs/VoBoTab";
import { FileTab } from "../tabs/FileTab";
import { ICorrespondence } from "../../../interfaces/Correspondence";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers/rootReducer";
import { useEffect } from "react";
import {
  itWasRead,
  loadCorresAnnotationsDB,
} from "../../../services/firebase/documentManagment";
import { IUserLoggedIn } from "../../../redux/types/types";
import { firebase } from "../../../config/firebase/firebase-config";

interface props {
  correspondence: ICorrespondence;
  documentDate: Date;
  paramResponse: any;
  readonly?: boolean;
  component?: string;
}

export const SettleDetailsTabs: FC<props> = ({
  correspondence,
  documentDate,
  paramResponse,
  readonly = false,
  component = "OTHER",
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const [value, setValue] = React.useState(0);

  const { user } = useSelector((state: AppState) => state.auth);
  const { activeCorrespondence } = useSelector(
    (state: AppState) => state.documentManagment
  );
  const unSubRef = useRef<any>(null);

  const dispatch = useDispatch();

  const isAnswer = {
    answer: correspondence.answer,
    answerDate: correspondence.answeredDate,
    createDate: correspondence.createAt,
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  useEffect(() => {
    const fetchCorresAnnotations = async () => {
      if (user && user.idEntity && activeCorrespondence) {
        //setLoading(true);

        unSubRef.current = await loadCorresAnnotationsDB(
          user.idEntity,
          activeCorrespondence.id,
          dispatch
        );
      }
    };
    fetchCorresAnnotations();
    return () => {
      //CANCELAR SNAPSHOT
      unSubRef.current();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const documentReaded = async (
      correspondence: ICorrespondence,
      user: IUserLoggedIn
    ) => {
      if (correspondence.id && user.idEntity) {
        correspondence.isReaded = true;
        correspondence.readedDate = firebase.firestore.Timestamp.now();
        await itWasRead(correspondence, user);
      }
    };

    documentReaded(correspondence, user!);
  }, [correspondence, user]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          centered
          TabIndicatorProps={{
            style: {
              backgroundColor: "#0A8FDC",
            },
          }}
        >
          <Tab
            className={classes.tab}
            label={`${intl.formatMessage({ id: "Answers" }).toUpperCase()}`}
            {...a11yProps(0)}
          />
          <Tab
            className={classes.tab}
            label={`${intl.formatMessage({ id: "Documents" }).toUpperCase()}`}
            {...a11yProps(1)}
          />
          <Tab
            className={classes.tab}
            label={`${intl.formatMessage({ id: "Record" }).toUpperCase()}`}
            {...a11yProps(2)}
          />
          <Tab
            className={classes.tab}
            label={`${intl.formatMessage({ id: "Annotations" }).toUpperCase()}`}
            {...a11yProps(3)}
          />
          {/*  <Tab
            className={classes.tab}
            label={`${intl.formatMessage({ id: "VoBo" }).toUpperCase()}`}
            {...a11yProps(4)}
          /> */}
          <Tab
            className={classes.tab}
            label={`${intl.formatMessage({ id: "File" }).toUpperCase()}`}
            {...a11yProps(5)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <AnswersTab
          // date={documentDate}
          paramResponse={paramResponse}
          answer={isAnswer}
          component={component}
          correspondence={correspondence}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DocumentsTab
          documents={
            correspondence.communicationType?.option === "Answer" &&
            correspondence.answer
              ? correspondence.appendDocs
                ? correspondence.answer.attachedDocuments.concat(
                    correspondence.appendDocs
                  )
                : correspondence.answer.attachedDocuments
              : correspondence.appendDocs
              ? correspondence.digitalSupport.concat(correspondence.appendDocs)
              : correspondence.digitalSupport
          }
          readonly={
            readonly || correspondence.communicationType?.option === "Answer"
          }
          component={component}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {correspondence.id && user && user.idEntity && (
          <RecordTab id={correspondence.id} idEntity={user.idEntity} />
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AnnotationsTab
          readonly={
            readonly || correspondence.communicationType?.option === "Answer"
          }
          component={component}
        />
      </TabPanel>
      {/* <TabPanel value={value} index={4}>
        <VoBoTab />
      </TabPanel> */}
      <TabPanel value={value} index={4}>
        <FileTab
          expedients={
            correspondence.expedients ? correspondence.expedients : []
          }
          readonly={
            readonly || correspondence.communicationType?.option === "Answer"
          }
          component={component}
        />
      </TabPanel>
    </div>
  );
};
